<section class="eft-info-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      EFT Information
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="eftInfoForm" id="eftInfoForm" method="post" *ngIf="eftInfoData && eftInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of eftInfoData; let ind = index;">
          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes != 'ITES_FORMAT_AS_ZIPCODE'">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid && !eftInfoForm.get(data.fieldId).errors.notUnique" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              <lfg-error *ngIf="eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid && eftInfoForm.get(data.fieldId).errors.notUnique && eftInfoForm.get(data.fieldId).errors.routingAccountError" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for zipcode  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_ZIPCODE'">
            <lfg-input-wrap>
              <input lfgInput mask='00000-0000' placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithHyphen" maxlength="10" [required]="data.required == 'true'">
              <lfg-error *ngIf="eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid && !eftInfoForm.get(data.fieldId).errors.notUnique" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              <lfg-error *ngIf="eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid && eftInfoForm.get(data.fieldId).errors.notUnique && eftInfoForm.get(data.fieldId).errors.routingAccountError" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid) }">
            </lfg-dropdown>
            <lfg-error *ngIf="eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information' && isBusinessEnhanceRiderSelected ">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Form element for email  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">
              <lfg-error *ngIf="eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ addCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="eftOnRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="eftOnRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form element for phone number -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
              <lfg-error *ngIf="(eftInfoForm.get(data.fieldId).touched && eftInfoForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

            <!-- Nestion question Level 1 -->
            <div *ngIf="data.hasReflexive == 'true' && eftInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
              <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
                <ng-container *ngIf="nestedQuestionCheckEFTInfo(data, primaryChildL1, parentAnswer)">
                  <!-- Questions headers -->
                  <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText !== 'Information'">
                  <h2>{{ primaryChildL1.questionText }}</h2>
                  </div>

                  <!-- Question Info -->
                  <div class="question-info" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText == 'Information'">
                    <lfg-alert-message type="info">{{ primaryChildL1.questionText }}</lfg-alert-message>
                  </div>

                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                  <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL1, 'display')">
                    {{ addCorrectPlaceHolder(primaryChildL1, 'question')  }}
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                      <lfg-error *ngIf="eftInfoForm.get(primaryChildL1.fieldId).touched && eftInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>
                </ng-container>
              </ng-container>
            </div>

        </ng-container>
      </form>
    </section>
  </div>
</section>
