import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LfgLoaderService } from '@ng/lfg-loader';
import { ModalSize } from '@ng/lfg-modal-popup';
import { Question, Recipient } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ExcludeTabsFromLock, ExcludeTabsFromValidation, Message, TABS_AFTER_LOCK_SCREEN, ValidateAndLockMessage } from 'src/config/constants';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';
import { ProductCodes, TAB, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-validate-and-lock',
  templateUrl: './validate-and-lock.component.html',
  styleUrls: ['./validate-and-lock.component.scss'],
})
export class ValidateAndLockComponent implements OnInit {
  questionsData;

  @Input() disableForm: boolean;
  @Input() userAccessDetails: FunctionalityConfig;
  @Output() lockStatusUpdated: EventEmitter<string> = new EventEmitter();

  isAllTabsValid = true;
  isAppLocked: boolean;
  modalConfig: any;
  showLoader: boolean;
  MESSAGES = ValidateAndLockMessage;
  errorOccurred = false;
  errorMessage = Message.GENERIC_ERROR;
  validateLockPageData = [];
  formValid = true;
  formHasNoErrors = true;
  isTabVisited: boolean;
  tabStatusOnInit: TabStatus;
  displayProgressModal = false;
  validateLockForm: FormGroup;
  CONST = Message;

  disableCheckBox: boolean;

  progressPercentArray = [1, 1, 5, 5, 5, 10, 10, 10, 15, 15, 15, 20, 20, 20,
    25, 31, 31, 31, 31, 36, 36, 42, 42, 54, 62, 62, 62, 67, 78,
    78, 85, 85, 85, 85, 90, 90, 90, 98];

  formAvailable;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  isFormRequired = false;

  @Output() proceedToSigning: EventEmitter<any> = new EventEmitter();
  @Output() deleteTabs: EventEmitter<string[]> = new EventEmitter();
  @Output() recipientList: EventEmitter<Recipient[]> = new EventEmitter();
  @Output() caseUnlocked: EventEmitter<string> = new EventEmitter();
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  constructor(
    private tabDetailsService: TabDetailsService,
    private lfgLoaderService: LfgLoaderService,
    private pageLoadService: DefaultPageload,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private formgeneratorService: FormGeneratorService,
    public appConfig: ApplicationConfig,
    private productService: ProductDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.pageLoadService.getActiveTabStatus();
      this.isFormRequired = this.userAccessDetails?.caseRelatedAccess?.showAgentConfCheckbox;
      this.buildFormData();
      this.checkForAllTabsValidity();
      this.disableCheckBox = this.tabDetailsService.isTabActive(TAB.SIGNATURE_METHOD);
      this.pageLoadService.disableFormIfLocked(this.validateLockForm, this.disableCheckBox);
      this.pageLoadService.logPageLoad();
    }
    this.modalConfig = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.medium,
    };
  }

  buildFormData(): any {
    this.validateLockPageData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.validateLockPageData
    );
    this.validateLockForm = this.fb.group(form);
    this.valueChanges();
  }

  valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.validateLockForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.validateLockForm,
      data: null,
    };
    for (const data of this.validateLockPageData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.formValid = !this.isFormRequired || (this.isFormRequired && (!this.isAppLocked || this.formValid));
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'validateLockForm',
      isFormChange: this.validateLockForm.dirty || !this.isTabVisited
        || this.pageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.validateLockForm)
    };
    this.formStatus.emit(obj);
  }

  checkForAllTabsValidity(): void {
    this.isAppLocked = this.tabDetailsService.getTabsLockStatus(ExcludeTabsFromLock);
    this.isAllTabsValid = this.tabDetailsService.isAllTabsValid(ExcludeTabsFromValidation);
    this.tabDetailsService.updateTabStatusMap(TAB.VALIDATE_AND_LOCK, true);
  }

  reviewForm(): void {
    this.errorOccurred = false;
    this.pageLoadService.logButtonClick('review forms');
    this.displayProgressModal = true;
    if (this.utilsService.getPdfData(this.pageLoadService.getCaseId())) {
      this.formAvailable = true;
    } else {
      this.pageLoadService.generateForm().subscribe(response => {
        if (response.data && response.responseStatus === 'SUCCESS') {
          this.utilsService.savePdfData(this.pageLoadService.getCaseId(), response.data);
          this.formAvailable = true;
        } else {
          this.errorOccurred = true;
          this.displayProgressModal = false;
        }
      }, (_err) => {
        this.errorOccurred = true;
        this.displayProgressModal = false;
      });
    }
  }

  openPdfForm(event: any): void {
    if (event === 'yes') {
      this.formAvailable = false;
      this.displayProgressModal = false;
      this.utilsService.getPdf(this.utilsService.getPdfData(this.pageLoadService.getCaseId()), this.pageLoadService.getCaseId(), false);
    }
  }

  closeProgressModal(event: any): void {
    this.formAvailable = false;
    this.displayProgressModal = false;
  }

  openModal(_event): void {
    this.modalConfig.state = true;
    this.pageLoadService.logButtonClick('unlock application data');
  }

  closeModalPopup(): any {
    this.pageLoadService.logButtonClick('unlock application data cancel');
    this.modalConfig.state = false;
  }

  lockAllTabs(): void {
    this.errorOccurred = false;
    this.showLoader = true;
    this.utilsService.clearPdfData();
    this.lfgLoaderService.show();
    this.pageLoadService.lockCase().subscribe((res) => {
      if (res.data?.successList && res.data.successList?.length > 0) {
        this.tabDetailsService.lockAllTabs(true, ExcludeTabsFromLock);
        this.isAppLocked = true;
        this.updateDelegationQuesFlag();
        if (res.data.recipients?.length > 0) {
          this.recipientList.emit(res.data.recipients);
        }
        this.pageLoadService.logButtonClick('lock application');
      } else {
        this.errorOccurred = true;
      }
    }).add(() => {
      this.lfgLoaderService.hide();
      this.showLoader = false;
    });
  }

  private updateDelegationQuesFlag(): void {
    this.lockStatusUpdated.emit('yes');
    this.isFormRequired = false;
    this.checkFormStatus();
  }

  unlockAllTabs(): void {
    this.closeModalPopup();
    this.utilsService.clearPdfData();
    this.errorOccurred = false;
    this.showLoader = true;
    this.lfgLoaderService.show();
    this.updateFormIfAvailable();
    this.pageLoadService.unlockCase().subscribe((res) => {
      if (res.data?.successList && res.data.successList.length > 0) {
        this.tabDetailsService.lockAllTabs(false, []);
        this.deleteTabsAfterLockScreen();
        this.isAppLocked = false;
        this.checkFormStatus();
        this.lockStatusUpdated.emit('yes');
        this.caseUnlocked.emit('yes');
        this.pageLoadService.logButtonClick('unlock application data confirm');
        // if VULONE or SVULONE is selected for existing case then redirct to case information page
        if (this.checkForVULOrSVULProduct()) {
          this.tabDetailsService.selectTab(this.tabDetailsService.getTabInfoByTabName(TAB.CASE_INFO))
        }
      } else {
        this.errorOccurred = true;
      }
    }).add(() => {
      this.lfgLoaderService.hide();
      this.showLoader = false;
    });
  }

  private updateFormIfAvailable(): void {
    Object.keys(this.validateLockForm.controls)?.forEach(contrl => {
      this.validateLockForm.get(contrl)?.enable();
      this.validateLockForm.get(contrl)?.setValue('');
    });
  }

  proceedToSign(): void {
    this.pageLoadService.logButtonClick('proceed to signing process');
    this.proceedToSigning.emit(true);
  }

  private deleteTabsAfterLockScreen(): void {
    const tabListToDelete = [];
    TABS_AFTER_LOCK_SCREEN.forEach(tab => {
      if (this.tabDetailsService.getTabInfoByTabName(tab).isEnable) {
        tabListToDelete.push(tab);
      }
    });
    if (tabListToDelete.length > 0) {
      this.deleteTabs.emit(tabListToDelete);
    }
  }

  checkForVULOrSVULProduct()  {
    return  this.productService.getProductName()?.toLowerCase() === ProductCodes.VULONE2021 || this.productService.getProductName()?.toLowerCase() === ProductCodes.SVULONE2021

  }
}

