import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ApplicationConfig } from 'src/config/app.config';
import { AgentInfoConstant, AgentInfoContConstant, Message, QuestionTextIncludes } from 'src/config/constants';
import { Question, QuestionOption, Company } from '../models/casePage.model';
import { WindowRefService } from '@ng/lfg-common-utilities';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  answerTypeMap = {
    'Numeric only': 'numbersOnly',
    Alphanumeric: 'alphaNumeric',
  };

  questionIdAndTagMap: Map<string, string> = new Map<string, string>();
  formData: Map<string, string> = new Map<string, string>();
  constructor(
    private appConfig: ApplicationConfig,
    private windowRef: WindowRefService
  ) { }

  formatNamesforSpace(val): any {
    if (!val) {
      return val;
    }
    val.trim();
    let formattedName = '';
    const keys = val.split(' ');
    keys.forEach((key) => {
      if (key.trim() !== '') {
        formattedName = formattedName + ' ' + key;
      }
    });
    formattedName = formattedName.replace(/%/g, '');
    return formattedName.trim();
  }

  formatDate(val): any {
    if (val) {
      val = val.replace(/\//g, '');
    }
    if (val && val.length === 8) {
      return val.slice(0, 2) + '/' + val.slice(2, 4) + '/' + val.slice(4);
    }
    return val;
  }

  getCorrectContent(data, type): any {
    const ft = this.appConfig.fieldTypes;
    const nonCheckControls = [ft.HDR, ft.NONEDITABLE, ft.USAADDRESS];
    if (nonCheckControls.indexOf(data.controlTypeDesc) >= 0) {
      return false;
    }
    const subtext = data.subText ? ' ' + data.subText : '';
    let questionText = data.questionText + subtext;
    const separate = false;
    questionText =
      questionText + (data.required === 'true' ? '' : ' (optional)');
    return this.returnContentBasedOnType(type, data, separate, questionText);
  }

  // pass format = true if phone to be formmated as (111) 111-1234 or 1 (987) 654-1234
  phoneNumberFormat(value, format): any {
    if (value !== null && value !== undefined) {
      value = value.split(' ').join('');
      value = value.split('(').join('');
      value = value.split(')').join('');
      value = value.split('-').join('');
      if (!format) {
        return value;
      }
      if (value.length === 10) {
        value =
          value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
      } else if (value.length === 11) {
        value =
          value.slice(0, 1) +
          ' (' +
          value.slice(1, 4) +
          ') ' +
          value.slice(4, 7) +
          '-' +
          value.slice(7);
      }
    }
    return value;
  }

  formatOptions(options): any {
    const newOptions = [];
    for (const option of options) {
      const obj = {
        label: option.description,
        value: option.value,
        checked: false,
      };
      newOptions.push(obj);
    }
    return newOptions;
  }

  getInlineErrorMessage(data: Question, formControl?: FormControl): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.CURRENCY
      || (data.controlTypeDesc === this.appConfig.fieldTypes.TEXT && data.notes &&
        data.notes.indexOf('ITES_CURRENCY_USD') > -1)) {
      return this.getErrorMessageForCurrency(data, formControl);
    } else if (
      data.controlTypeDesc === this.appConfig.fieldTypes.SELECT ||
      data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX
    ) {
      return this.getErrorMessageForDropdown(data, formControl);
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.RADIO) {
      return this.getErrorMessageForRadio(formControl, data);
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.HDR) {
      return '';
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.TEXT) {
      return this.getErrorMessageForText(data, formControl);
    } else {
      const questionText = data.questionText?.toLowerCase()?.replace(/[:?]/gi, '')?.replace('what is the', '')?.trim();
      return 'Please enter a valid ' + questionText + '.';
    }
  }

  private getErrorMessageForRadio(formControl: FormControl, data: Question): any {
    const errorMsg = 'Please select a valid option.';
    if (formControl?.errors?.attestError) {
      if (data.xmlTag === AgentInfoConstant.doYouattestTag ||
        data.xmlTag === AgentInfoContConstant.caseManagerDoYouAttestQuesId) {
        return this.appConfig.errorMessages.I_ATTEST_ERROR_MESSAGE;
      } else if (data.xmlTag === AgentInfoContConstant.officeStaffDoYouAttestQuesId) {
        return this.appConfig.errorMessages.I_ATTEST_ALL_STATE_ERROR_MESSAGE;
      } else if (data.xmlTag === AgentInfoContConstant.knowEnglishQuesId) {
        return this.appConfig.errorMessages.KNOW_ENGLISH_ERROR_MESSAGE;
      }
    } else if (formControl?.errors?.dcaError) {
      return formControl.errors.dcaError.errorMessage;
    }
    return errorMsg;
  }

  private getErrorMessageForText(data: Question, formControl: FormControl): any {
    const questionText = data.questionText?.toLowerCase()?.replace(/[:?]/gi, '')?.replace('what is the', '')?.trim();
    const errorMsg = 'Please enter a valid ' + questionText + '.';
    if (new RegExp(QuestionTextIncludes.join('|')).test(data.questionText.toLowerCase())) {
      return 'Please enter a valid input.';
    }
    if (formControl?.errors?.noOfDigitError) {
      return this.appConfig.errorMessages.ALL_STATE_AGENT_ERROR_MESSAGE;
    }
    return errorMsg;
  }

  getErrorMessageForCurrency(
    data: Question,
    formControl?: FormControl
  ): string {
    const errorMsg =
      'Please enter a valid ' + data.questionText.toLowerCase()?.replace(/[$?]/gi, '')?.trim() + '.';
    if (formControl?.errors?.maxAmountError || formControl?.errors?.faceAmountError) {
      return this.appConfig.errorMessages.COVERAGE_AMOUNT_ERROR;
    } else if (formControl?.errors?.minAmountError) {
      return this.appConfig.errorMessages.MIN_AMOUNT_ERROR;
    } else if (formControl?.errors?.riderAmountError) {
      return formControl.errors.riderAmountError.errorMessage;
    } else if (formControl?.errors?.advancePremiumAmountError) {
      return Message.ADVANCE_PREMIUM_AMOUNT_ERROR;
    }
    return errorMsg;
  }

  getErrorMessageForDropdown(
    _data: Question,
    formControl?: FormControl
  ): string {
    const errorMsg = 'Please select a valid option.';
    if (formControl?.errors?.dboError) {
      return this.appConfig.errorMessages.DBO_ERROR;
    } else if (formControl?.errors?.dboPremiumError) {
      return this.appConfig.errorMessages.DOB_AND_DBQ_MESSAGE;
    } else if (formControl?.errors?.stateError) {
      return this.appConfig.errorMessages.STATE_ERROR;
    } else if (formControl?.errors?.termAgeError) {
      return this.appConfig.errorMessages.TERM_AGE_ERROR;
    } else if (formControl?.errors?.nonTermAgeError) {
      return this.appConfig.errorMessages.NON_TERM_AGE_ERROR;
    } else if (formControl?.errors?.ageRiderError) {
      return formControl.errors.ageRiderError.errorMessage;
    } else if (formControl?.errors?.ownerTypeError) {
      return formControl.errors.ownerTypeError.errorMessage;
    }
    return errorMsg;
  }

  getDropDownCheckboxValue(answerString, questionOption): any {
    const answerToreturn = [];
    // if answerString in null or blank then set all option checked false
    if (!answerString || answerString === '') {
      questionOption.forEach((element) => {
        const obj = {
          label: element.description,
          value: element.value,
          checked: false,
        };
        answerToreturn.push(obj);
      });
      return [answerToreturn];
    }
    // if answers already available then upate all the option based on answer, answered one will be marked checked
    const answerArray = answerString.split(';');
    questionOption.forEach((element) => {
      const option = answerArray.filter((el) => el === element.value);
      if (option.length > 0) {
        const obj = {
          label: element.description,
          value: element.value,
          checked: true,
        };
        answerToreturn.push(obj);
      } else {
        const obj = {
          label: element.description,
          value: element.value,
          checked: false,
        };
        answerToreturn.push(obj);
      }
    });
    return [answerToreturn];
  }

  getFieldId(data, key): any {
    let field;
    data.find((element1) => {
      if (element1.fieldId === key + '_~_' + element1.name) {
        field = element1;
        return field;
      }
      if (element1.hasReflexive === 'true') {
        const childData = element1.reflexiveQuestionAL;
        childData.find((element2) => {
          if (element2.fieldId === key + '_~_' + element2.name) {
            field = element2;
            return field;
          }
        });
      }
    });
    return field ? field.fieldId : field;
  }

  getNameByXmlTag(data: Question[], xmlTag: string): string {
    let quesObj;
    quesObj = this.getQuestionByXmlTag(data, xmlTag, quesObj);
    return quesObj ? quesObj.name : null;
  }

  getFieldByXmlTag(data: Question[], xmlTag: string): string {
    let quesObj;
    quesObj = this.getQuestionByXmlTag(data, xmlTag, quesObj);
    return quesObj ? quesObj.fieldId : null;
  }

  getQuestionIdByQuestionText(quesText: string, questionData: Question[]): string {
    return questionData.filter(ques => {
      return ques.questionText === quesText;
    })[0]?.fieldId;
  }

  getQuestionByXmlTag(data: any[], xmlTag: string, quesObj: Question): Question {
    data.forEach(ques => {
      if (!quesObj && ques.xmlTag === xmlTag) {
        quesObj = ques;
      } else if (!quesObj && ques.hasReflexive) {
        quesObj = this.getQuestionByXmlTag(ques.reflexiveQuestionAL, xmlTag, quesObj);
      }
    });
    return quesObj;
  }

  getNameFieldByXmlTag(questions, xmlTag: string, type?: string): string {
    if (type) {
      return questions.filter(ques => {
        return (ques.xmlTag === xmlTag && (ques.optionChoice.toLowerCase().indexOf(type) > -1));
      })[0]?.name;
    } else {
      return questions.filter(ques => {
        return ques.xmlTag === xmlTag;
      })[0]?.name;
    }
  }

  getOtherAddressFieldByXmlTag(questions, xmlTag: string, type?: string): any {
    return questions.filter(ques => {
      return (ques.xmlTag === xmlTag && (ques.optionChoice.toLowerCase().indexOf('other{47}other') > -1));
    })[0]?.name;
  }

  getInsuredAddressFieldByXmlTag(questions, xmlTag: string, type?: string): any {
    return questions.filter(ques => {
      return (ques.xmlTag === xmlTag && (ques.optionChoice.toLowerCase().indexOf('insured') > -1));
    })[0]?.name;
  }

  getFieldIdForAll(data, key): any {
    const fieldData = [];
    data.filter((element1) => {
      this.updateFieldData(element1, key, fieldData);
      if (element1.hasReflexive === 'true') {
        const childData = element1.reflexiveQuestionAL;
        childData.filter((element2) => {
          this.updateFieldData(element2, key, fieldData);
          if (element2.hasReflexive === 'true') {
            const childData1 = element2.reflexiveQuestionAL;
            childData1.filter((element3) => {
              this.updateFieldData(element3, key, fieldData);
            });
          }
        });
      }
    });
    return fieldData && fieldData.length === 1
      ? fieldData[0].fieldId
      : fieldData;
  }

  clearAllRootQuestionAnswer(data): any {
    for (const item of data) {
      if (item.question_answer && item.question_answer !== null) {
        item.question_answer = null;
      }
      if (item.hasReflexive === 'true') {
        this.clearAllRootQuestionAnswer(item.reflexiveQuestionAL);
      }
    }
  }

  createObjforDropdown(val): any {
    if (val) {
      return {
        label: val,
        value: val,
      };
    } else {
      return null;
    }
  }

  /**
   * common method to show error onload
   * @param componentForm input form
   */
  showErrorIfAnyOnLoad(componentForm): any {
    if (componentForm && componentForm.invalid) {
      Object.keys(componentForm.controls).forEach((ele) => {
        if (
          componentForm.controls[ele].value &&
          componentForm.controls[ele].invalid
        ) {
          componentForm.controls[ele]?.markAsTouched();
        }
      });
    }
  }

  getCodeFromValue(value: string): string {
    return value?.split('{')[0];
  }

  getAnswerMatchingWithDropDownOption(answerString, questionOption): any {
    if (!answerString || answerString === '') {
      return '';
    }
    let matchingAns = '';
    const option = questionOption.find(
      (el) =>
        el.value.indexOf(answerString) >= 0 ||
        el.description.indexOf(answerString) >= 0
    );
    if (option) {
      matchingAns = option.value;
    }
    return matchingAns;
  }

  private updateFieldData(element: any, key: any, fieldData: any[]): void {
    if (element.fieldId === key + '_~_' + element.name) {
      fieldData.push(element);
    }
  }

  private returnContentBasedOnType(type, data, separate, questionText): any {
    if (type === 'placeHolder') {
      return this.returnContentBasedOnControlType(data, separate, questionText);
    } else if (type === 'question') {
      return questionText;
    } else {
      return separate;
    }
  }

  private returnContentBasedOnControlType(
    data: any,
    separate: any,
    questionText: any
  ): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return separate ? 'Select' : questionText;
    } else {
      const questionTemp = questionText
        ? questionText.replace(/<I>/g, '').replace(/<\/I>/g, '')
        : questionText;
      return separate ? 'Please provide details' : questionTemp;
    }
  }

  public getAnswerType(key: string): string {
    return this.answerTypeMap[key] ? this.answerTypeMap[key] : 'textOnly';
  }

  public getCamelCase(val: string): string {
    if (val) {
      return val.charAt(0)?.toUpperCase() + val.substring(1)?.toLowerCase();
    }
    return '';
  }

  public updateQuestionMap(quesList: any[], pageGroupName: string): void {
    quesList?.forEach(ques => {
      if (ques.xmlTag && ques.xmlTag !== '') {
        this.questionIdAndTagMap.set(pageGroupName + ':' + ques.xmlTag, ques.fieldId);
      }
      if (ques.reflexiveQuestionAL && ques.reflexiveQuestionAL.length > 0) {
        this.updateQuestionMap(ques.reflexiveQuestionAL, pageGroupName);
      }
    });
  }

  public clearQuestionMap(): void {
    this.questionIdAndTagMap.clear();
  }

  public getFieldIdByTag(xmlTag: string, pageGroupName: string): string {
    return this.questionIdAndTagMap.get(pageGroupName + ':' + xmlTag);
  }

  public toBase64(file: File): Promise<any> {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<any>((resolve) => {
      reader.onload = () => {
        let generated64 = reader.result.toString();
        generated64 = generated64.split(',').pop();
        resolve(generated64);
      };
    });
  }

  public createPdfFromBase64(baseData: string): any {
    const binary = atob(baseData.replace(/\s/g, ''));
    const view = new Uint8Array(new ArrayBuffer(binary.length));
    for (let i = 0; i < binary.length; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], {
      type: 'application/pdf',
    });
    return window.URL.createObjectURL(blob);
  }

  public openPdf(basedata: string): void {
    try {
      const data = this.createPdfFromBase64(basedata);
      window.open(data, '_blank');
    } catch (error) {
      console.log(error);
    }
  }

  createDropDownFromList(list: Company[], quesOptions: QuestionOption[]): QuestionOption[] {
    list?.forEach((val) => {
      const quesOpt: QuestionOption = {
        value: val.companyId?.toString() + '{47}' + val.companyName,
        description: val.companyName,
        label: val.companyName,
      };
      quesOptions.push(quesOpt);
    });
    return quesOptions;
  }

  // below methods are for pdf generation and showing base64 as pdf
  async getPdf(basedata: string, name: string, downloadDoc: boolean): Promise<void> {
    try {
      const data = this.createPdfFromBase64(basedata);
      let link;
      if (downloadDoc) {
        link = this.createDownloadLink(name, data);
      }
      const newWindow = this.windowRef.getWindow();
      newWindow.open('', name);
      newWindow.open(data, name);
      this.closeResources(link, data, newWindow);
    } catch (error) {
      console.log(error);
    }
  }

  savePdfData(caseId: string, basedata: string): void {
    this.formData.set(caseId, basedata);
  }

  clearPdfData(): void {
    this.formData.clear();
  }

  getPdfData(caseId: string): string {
    return this.formData.get(caseId);
  }

  private createDownloadLink(name: string, data: any): any {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = name;
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    return link;
  }

  private closeResources(link: any, data: any, newWindow: any): void {
    setTimeout(() => {
      newWindow.URL.revokeObjectURL(data);
      window.URL.revokeObjectURL(data);
      link?.remove();
    }, 900000);
  }

  public isSafariBrowser(): boolean {
    const userAgent = navigator.userAgent?.toLowerCase();
    return (!(/(edg)/.test(userAgent)) && !(/(firefox)/.test(userAgent))
      && !(/(chrome)/.test(userAgent)) && /(safari)/.test(userAgent) && /(mac)/.test(userAgent));
  }

  public calculateAge(dob: string): number {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
    return age;
  }
}
