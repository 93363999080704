<section class="attestation-info-page page-content-container">
    <div class="delegation-info" *ngIf="userAccessDetails?.caseRelatedAccess.onBehalfOfMessage">
        <h1 class="lfg-color">
            On Behalf of Disclaimer:
        </h1>
        <lfg-alert-message type="info">{{delegateMsg}}</lfg-alert-message>
    </div>
    <div class="questions--container">
        <h1 class="lfg-color">
          Product Attestation
        </h1>
    </div>
    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <div class="questions-header">
      <h2>I understand :</h2>
    </div>

    <form class="form-group" [formGroup]="attestationInfoForm" id="attestationInfoForm" method="post" *ngIf="attestationInfoData && attestationInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of attestationInfoData; let ind = index;" >
            <!-- Form element for check box  -->
            <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
                <div class="lfg-checkbox">
                <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}"
                    formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
                <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
                </div>
            </div>

        </ng-container>
    </form>
</section>
