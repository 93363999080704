import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {

  constructor() {
    // can be used later
  }

  // insured related info
  private insuredFirstName: string;
  private insuredLastName: string;
  private insuredMiddleName: string;
  private insuredSuffix: string;
  private insuredDob: Date;
  private insuredAge: number;
  private insuredGender: string;
  private insuredState: string;
  private insuredAddressType: string;
  private insuredPhoneNumberType: any;
  private insuredEmailAddress: string;
  private insuredAddress: any;
  private insuredPrimaryPhone: any;

  // rider related info
  private isChildRiderSelected: boolean;
  private isCCABRRiderSelected: boolean;
  private isBusinessEnhanceRider: boolean;
  private isOtherInsuredRider: boolean;
  private isIndividualEnhanceRider: boolean;
  private enhanceSurrenderValueRider: boolean;
  private execEnhanceSurrenderValueRider: boolean;
  private sveeRider: boolean;
  private levRider: boolean;

  // agent info cont info/ agent info
  private isTeleAppSelected: boolean;
  private isAttestedForSameEmail: string;

  // coverage info
  private faceAmount: any = 0;

  // fund related Values
  private fixedAccountFund: number;
  private govtFund: number;
  private isFixedOrGovtFundUpdated: boolean;
  private selectedFundList: string[] = [];

  // owner related info
  private selectedOwners: string[];
  private ownerDetails: any;
  private ownerDetailsWithAdd: any;
  private selectedAccountHolder: any;
  private trustDetails: any;
  private trustDetailsWithAdd: any;

  // eft info
  private eftDetails: {} = {};
  private checkPaymentProvider: any;
  private eftPaymentProvider: any;

  // existing insurance
  private hasExistingInsurance = false;
  private consideringAnyDiscontinuation = false;

  // existing insurance B
  private hasExistingInsuranceB = false;
  private consideringAnyDiscontinuationB = false;

  // proposed B info
  private proposedBName: string;
  private proposedBAge: string;

  // tia related info
  private isTIASubmitted: boolean;

  setInsuredName(
    firstName: string,
    middleName: string,
    lastName: string,
    suffix: string
  ): void {
    this.insuredFirstName = firstName;
    this.insuredMiddleName = middleName ? middleName : '';
    this.insuredLastName = lastName;
    this.insuredSuffix = suffix ? suffix : '';
  }

  setInsuredAddressType(addressType): void {
    this.insuredAddressType = addressType;
  }

  getInsuredAddressType(): any {
    return this.insuredAddressType;
  }

  setEFTPaymentProvider(value): void {
    this.eftPaymentProvider = value;
  }

  getEFTPaymentProvider(): any {
    return this.eftPaymentProvider;
  }

  setCheckPaymentProvider(value): void {
    this.checkPaymentProvider = value;
  }

  getCheckPaymentProvider(): any {
    return this.checkPaymentProvider;
  }

  setInsuredDOBInfo(dob: Date, age: number): void {
    this.insuredAge = age;
    this.insuredDob = dob;
  }

  getInsuredName(): string {
    return this.insuredFirstName + ' ' + this.insuredLastName;
  }

  getInsuredFullName(): string {
    const fullName =
      this.insuredFirstName +
      ' ' +
      this.insuredMiddleName +
      ' ' +
      this.insuredLastName +
      ' ' +
      this.insuredSuffix;
    return fullName.trim().replace('  ', ' ');
  }

  getInsuredFirstName(): string {
    return this.insuredFirstName;
  }

  getInsuredLastName(): string {
    return this.insuredLastName;
  }

  getInsuredMiddleName(): string {
    return this.insuredMiddleName;
  }

  setInsuredAddress(
    address1: string, address2: string, address3: string, city: string, state: string, country: string, zipCode: string): void {
    this.insuredAddress = {
      address1, address2, address3, city, state, country, zipCode
    };
  }

  getInsuredAddress(): any {
    return this.insuredAddress;
  }

  setEFTBasicDetails(
    eftTemporaryInsurancePaymentProviderData: string, eftFirstName: string, eftLastName: string
  ): void {
    const temporaryInsurancePaymentProvider = 'temporaryInsurancePaymentProvider';
    const firstName = 'firstName';
    const lastName = 'lastName';
    this.eftDetails[temporaryInsurancePaymentProvider] = eftTemporaryInsurancePaymentProviderData;
    this.eftDetails[firstName] = eftFirstName;
    this.eftDetails[lastName] = eftLastName;
  }


  setEFTAddressDetails(
    eftAddress: string, eftCity: string, eftState: string, eftZipcode: any,
    eftEmailAddress: string, eftPrimaryPhoneNumber: any, eftCellPhoneNumber: any
  ): void {
    const city = 'city';
    const address = 'address';
    const state = 'state';
    const zipcode = 'zipcode';
    const emailAddress = 'emailAddress';
    const primaryPhoneNumber = 'primaryPhoneNumber';
    const cellPhoneNumber = 'cellPhoneNumber';
    this.eftDetails[city] = eftCity;
    this.eftDetails[address] = eftAddress;
    this.eftDetails[state] = eftState;
    this.eftDetails[zipcode] = eftZipcode;
    this.eftDetails[emailAddress] = eftEmailAddress;
    this.eftDetails[primaryPhoneNumber] = eftPrimaryPhoneNumber;
    this.eftDetails[cellPhoneNumber] = eftCellPhoneNumber;
  }

  setEFTBankDetails(
    eftBankOrCreditUnionName: string, eftRoutingNumber: any, eftAccountNumber: any, eftAccountType: any
  ): void {
    const bankOrCreditUnionName = 'bankOrCreditUnionName';
    const routingNumber = 'routingNumber';
    const accountNumber = 'accountNumber';
    const accountType = 'accountType';
    this.eftDetails[bankOrCreditUnionName] = eftBankOrCreditUnionName;
    this.eftDetails[routingNumber] = eftRoutingNumber;
    this.eftDetails[accountNumber] = eftAccountNumber;
    this.eftDetails[accountType] = eftAccountType;
  }

  getEFTDetails(): any {
    return this.eftDetails;
  }

  setInsuredPhoneNo(value): any {
    this.insuredPrimaryPhone = value;
  }

  getInsuredPhoneNo(): string {
    return this.insuredPrimaryPhone;
  }

  setInsuredEmail(value): any {
    this.insuredEmailAddress = value;
  }

  getInsuredEmail(): string {
    return this.insuredEmailAddress;
  }

  setInsuredPhoneNoType(value): any {
    this.insuredPhoneNumberType = value;
  }

  getInsuredPhoneNoType(): string {
    return this.insuredPhoneNumberType;
  }

  setSelectedAccHolder(value): void {
    this.selectedAccountHolder = value;
  }

  getSelectedAccHolder(): any {
    return this.selectedAccountHolder;
  }

  getInsuredSuffix(): string {
    return this.insuredSuffix;
  }

  getInsuredAge(): number {
    return this.insuredAge;
  }

  getInsuredDOB(): Date {
    return this.insuredDob;
  }

  getInsuredGender(): string {
    return this.insuredGender;
  }

  setInsuredGender(gender: string): void {
    this.insuredGender = gender;
  }

  setInsuredState(state: string): void {
    this.insuredState = state;
  }

  getInsuredState(): string {
    return this.insuredState;
  }

  getIsChildRiderSelected(): boolean {
    return this.isChildRiderSelected;
  }

  setIsChildRiderSelected(isChildRider: boolean): void {
    this.isChildRiderSelected = isChildRider;
  }

  getIsCCABRiderSelected(): boolean {
    return this.isCCABRRiderSelected;
  }

  setIsCCABRiderSelected(isCCABRRiderSelected: boolean): void {
    this.isCCABRRiderSelected = isCCABRRiderSelected;
  }

  getIsTeleAppSelected(): boolean {
    return this.isTeleAppSelected;
  }

  setIsTeleAppSelected(isTeleAppSelected: boolean): void {
    this.isTeleAppSelected = isTeleAppSelected;
  }

  setFaceAmount(faceAmount: string): void {
    this.faceAmount = faceAmount;
  }

  getFaceAmount(): any {
    return this.faceAmount;
  }

  setEnhanceSurrenderValueRider(value): void {
    this.enhanceSurrenderValueRider = value;
  }

  getEnhanceSurrenderValueRider(): boolean {
    return this.enhanceSurrenderValueRider;
  }

  setExecEnhanceSurrenderValueRider(value): void {
    this.execEnhanceSurrenderValueRider = value;
  }

  getExecEnhanceSurrenderValueRider(): boolean {
    return this.execEnhanceSurrenderValueRider;
  }

  setSveeRider(value): void {
    this.sveeRider = value;
  }

  getSveeRider(): boolean {
    return this.sveeRider;
  }

  setLEVRider(value): void {
    this.levRider = value;
  }

  getLEVRider(): boolean {
    return this.levRider;
  }

  setBusinessEnhanceRiderSeleted(value): void {
    this.isBusinessEnhanceRider = value;
  }

  getBusinessEnhanceRiderSeleted(): boolean {
    return this.isBusinessEnhanceRider;
  }

  setIsOtherInsuredRider(value: boolean): void {
    this.isOtherInsuredRider = value;
  }

  getIsOtherInsuredRider(): boolean {
    return this.isOtherInsuredRider;
  }

  setIndividualEnhanceRiderSeleted(value): void {
    this.isIndividualEnhanceRider = value;
  }

  getIndividualEnhanceRiderSeleted(): boolean {
    return this.isIndividualEnhanceRider;
  }

  setTrustDetails(trustDetail: any, trustDetailsWithAddr: any): void {
    this.trustDetails = trustDetail;
    this.trustDetailsWithAdd = trustDetailsWithAddr;
  }

  getTrustDetails(): void {
    return this.trustDetails;
  }

  getTrustDetailsWithAddress(): void {
    return this.trustDetailsWithAdd;
  }

  setOwnerDetails(owner, ownerDrpdwnDetails): void {
    this.ownerDetails = owner;
    this.ownerDetailsWithAdd = ownerDrpdwnDetails;
  }

  getOwnerDetailsWithAddress(): void {
    return this.ownerDetailsWithAdd;
  }

  getOwnerDetails(): void {
    return this.ownerDetails;
  }
  getSelectedOwners(): string[] {
    return this.selectedOwners;
  }

  setSelectedOwners(ownersSelected: string[]): void {
    this.selectedOwners = ownersSelected;
  }

  setFixedAccountAndGovtFund(fixedAccountFund: number, govtFund: number): void {
    this.fixedAccountFund = fixedAccountFund;
    this.govtFund = govtFund;
  }

  getFixedAccountFund(): number {
    return this.fixedAccountFund;
  }

  getGovtFund(): number {
    return this.govtFund;
  }

  getSelectedFundList(): string[] {
    return this.selectedFundList;
  }

  setSelectedFundList(selectedfundList: string[]): void {
    this.selectedFundList = selectedfundList;
  }

  getHasExistingInsurance(): boolean {
    return this.hasExistingInsurance;
  }

  setHasExistingInsurance(hasExistingInsurance: boolean): void {
    this.hasExistingInsurance = hasExistingInsurance;
  }

  getConsideringAnyDiscontinuation(): boolean {
    return this.consideringAnyDiscontinuation;
  }

  setConsideringAnyDiscontinuation(consideringAnyDiscontinuation: boolean): void {
    this.consideringAnyDiscontinuation = consideringAnyDiscontinuation;
  }

  getHasExistingInsuranceProposedB(): boolean {
    return this.hasExistingInsuranceB;
  }

  setHasExistingInsuranceProposedB(hasExistingInsurance: boolean): void {
    this.hasExistingInsuranceB = hasExistingInsurance;
  }

  getConsideringAnyDiscontinuationProposedB(): boolean {
    return this.consideringAnyDiscontinuationB;
  }

  setConsideringAnyDiscontinuationProposedB(consideringAnyDiscontinuation: boolean): void {
    this.consideringAnyDiscontinuationB = consideringAnyDiscontinuation;
  }

  getIsFixedOrGovtFundUpdated(): boolean {
    return this.isFixedOrGovtFundUpdated;
  }

  setIsFixedOrGovtFundUpdated(isFixedOrGovtFundUpdated: boolean): void {
    this.isFixedOrGovtFundUpdated = isFixedOrGovtFundUpdated;
  }

  getIsAttestedForSameEmail(): string {
    return this.isAttestedForSameEmail;
  }

  setIsAttestedForSameEmail(isAttested: string): void {
    this.isAttestedForSameEmail = isAttested;
  }

  getProposedBName(): string {
    return this.proposedBName;
  }

  setProposedBName(name: string): void {
    this.proposedBName = name;
  }

  getProposedBAge(): string {
    return this.proposedBAge;
  }

  setProposedBAge(age: string): void {
    this.proposedBAge = age;
  }

  getIsTIASubmitted(): boolean {
    return this.isTIASubmitted;
  }

  setIsTIASubmitted(isTIASubmitted: boolean): void {
    this.isTIASubmitted = isTIASubmitted;
  }

  clearEftData(): void {
    this.eftPaymentProvider = undefined;
    this.eftDetails = {};
    this.selectedAccountHolder = undefined;
    this.checkPaymentProvider = '';
  }

  clearUserDetails(): void {
    this.setInsuredName(undefined, undefined, undefined, undefined);
    this.setInsuredDOBInfo(undefined, undefined);
    this.insuredGender = this.insuredState = undefined;
    this.clearUserDetailsExceptCaseInfo();
  }

  clearUserDetailsExceptCaseInfo(): void {
    this.setFixedAccountAndGovtFund(undefined, undefined);
    this.isChildRiderSelected = undefined;
    this.selectedOwners = [];
    this.isTeleAppSelected = this.isBusinessEnhanceRider = this.isIndividualEnhanceRider = this.isOtherInsuredRider = undefined;
    this.ownerDetails = this.ownerDetailsWithAdd = undefined;
    this.faceAmount = 0;
    this.selectedAccountHolder = this.insuredAddress = undefined;
    this.isFixedOrGovtFundUpdated = false;
  }

}
