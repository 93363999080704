import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { EFTConstant, ErrorMessage } from 'src/config/constants';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { TAB, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-temporary-life-insurance',
  templateUrl: './temporary-life-insurance.component.html',
  styleUrls: ['./temporary-life-insurance.component.scss']
})
export class TemporaryLifeInsuranceComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  formValid = true;
  formHasNoErrors = true;
  temporaryLifeInsuranceData: Question[] = [];
  temporaryLifeInsuranceForm: FormGroup;
  placeholders = {};
  alldropDownOptions = {};
  ownerDetails: any;
  isTabVisited: boolean;
  insuredFirstName: string;
  insuredLastName: string;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;

  hasEftScreen: boolean;
  tabStatusOnInit: TabStatus;

  constructor(
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private commonService: CommonService,
    private defaultPageLoadService: DefaultPageload,
    private userService: UserDetailsService,
    private tabDetailsServ: TabDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.hasEftScreen = this.tabDetailsServ.isTabActive(TAB.ELECTRONIC_FUND_TRANSFER);
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.temporaryLifeInsuranceForm);
      this.defaultPageLoadService.disableFormIfLocked(this.temporaryLifeInsuranceForm, this.disableForm);
      this.updateSelectedAccountHolder();
      this.updateValuesInForm();
      this.defaultPageLoadService.logPageLoad();
    }
  }

  public updateValuesInForm(): void {
    if (this.hasEftScreen) {
      this.updateEFTData(this.temporaryLifeInsuranceForm, this.userService.getEFTDetails());
    } else {
      this.updateEFTData(this.temporaryLifeInsuranceForm, this.getPrimaryData());
    }
  }

  private updateSelectedAccountHolder(): void {
    const eftPaymentProvider = this.userService.getEFTPaymentProvider();
    if (eftPaymentProvider) {
      this.temporaryLifeInsuranceForm.get(this.appConfig.temporaryInsuredFieldId.eftPaymentProvider)?.setValue(eftPaymentProvider);
    }
    const checkPaymentProvider = this.userService.getCheckPaymentProvider();
    if (checkPaymentProvider) {
      this.temporaryLifeInsuranceForm.get(this.appConfig.temporaryInsuredFieldId.checkPaymentProvider)?.setValue(checkPaymentProvider);
    }
  }

  private populateDataOnDropdownSelection(): void {
    this.temporaryLifeInsuranceForm
      .get(this.appConfig.temporaryInsuredFieldId.eftPaymentProvider)
      ?.valueChanges.subscribe((value) => {
        this.userService.setEFTPaymentProvider(value);
      });
    this.temporaryLifeInsuranceForm
      .get(this.appConfig.temporaryInsuredFieldId.checkPaymentProvider)
      ?.valueChanges.subscribe((value) => {
        this.userService.setCheckPaymentProvider(value);
      });
  }

  private updateDropdownListByOwners(allDropdowns): any {
    const dropDownObj = this.getDefaultDropDownValue();
    let updatedDropDownArray;
    if (this.ownerDetails) {
      updatedDropDownArray = [...dropDownObj, ...this.ownerDetails];
    } else {
      updatedDropDownArray = [...dropDownObj];
    }

    for (const item of Object.keys(allDropdowns)) { // Updating dropdown
      if ((item === this.appConfig.temporaryInsuredFieldId.checkPaymentProvider) ||
        (item === this.appConfig.temporaryInsuredFieldId.eftPaymentProvider)) {
        allDropdowns[item] = updatedDropDownArray;
      }
    }
  }

  private getDefaultDropDownValue(): any[] {
    return [
      {
        checked: false,
        label: 'Other - Individual',
        value: 'Other-Individual{47}Other-Individual'
      },
      {
        checked: false,
        label: 'Other - Entity',
        value: 'Other-Entity{47}Other-Entity'
      },
      {
        checked: false,
        label: this.insuredFirstName + ' ' + this.insuredLastName,
        value: this.insuredFirstName + '{ insuredName }' + this.insuredLastName
      }
    ];
  }
  private buildFormData(): any {
    this.insuredFirstName = this.userService.getInsuredFirstName();
    this.insuredLastName = this.userService.getInsuredLastName();
    this.temporaryLifeInsuranceData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.temporaryLifeInsuranceData
    );
    const form = this.formgeneratorService.createFormControls(
      this.temporaryLifeInsuranceData
    );
    this.temporaryLifeInsuranceForm = this.fb.group(form);
    this.ownerDetails = this.userService.getOwnerDetails();
    this.valueChanges();
    this.updateDropdownListByOwners(this.alldropDownOptions);
    setTimeout(() => {
      this.disableEFTFields();
    }, 100);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.temporaryLifeInsuranceForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckPrimaryInsuredInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  onRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.temporaryLifeInsuranceForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.temporaryLifeInsuranceForm.get(key)?.markAsUntouched();
      }
      this.temporaryLifeInsuranceForm.patchValue(updatedval);
    }
  }

  handleSelectionChange(data, ix?): any {
    const formotivId = this.displayIdCreation(data);
    this.commonService.triggerChangeCustom(
      'dropdown',
      ix,
      formotivId,
      'insuredInfoForm',
      data
    );
    this.updatedAnswersOnHidden(data);
  }

  getErrorMsg(data, formControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.temporaryLifeInsuranceForm,
      data: null,
    };
    for (const data of this.temporaryLifeInsuranceData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const isAnyAlertExists = this.isAlertExists();
    const obj = {
      formValid: this.formValid && !isAnyAlertExists,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'temporaryLifeInsuranceForm',
      isFormChange: this.temporaryLifeInsuranceForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.temporaryLifeInsuranceForm),
    };
    this.formStatus.emit(obj);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.temporaryLifeInsuranceForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
        this.populateDataOnDropdownSelection();
      }, 200);
    });
  }

  private isAlertExists(): boolean {
    const htmlAttAlert = Array.from(document.getElementsByTagName('lfg-alert-message'));
    let alertExist = false;
    if (htmlAttAlert?.length > 0) {
      for (const htmlElement of htmlAttAlert) {
        if (htmlElement.getAttribute('type') === 'error'
          && htmlElement.innerHTML?.toLowerCase().indexOf('temporary insurance agreement is not available') > -1) {
          alertExist = true;
          break;
        }
      }
    }
    return alertExist;
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  updateEFTData(temporaryLifeInsuranceForm: FormGroup, eftData: any): void {
    let isEFTSelected = '';
    Object.keys(this.temporaryLifeInsuranceForm.controls).forEach((contrl) => {
      if (contrl.indexOf('PaymentMethod') > -1) {
        isEFTSelected = this.temporaryLifeInsuranceForm.get(contrl)?.value?.value;
      }
    });
    if (isEFTSelected && isEFTSelected.indexOf('EFT') > -1) {
      Object.keys(temporaryLifeInsuranceForm.controls).forEach((control) => {
        if (control?.indexOf(this.appConfig.temporaryInsuredFieldId.eftPaymentProvider) > -1) {
          this.alldropDownOptions[this.appConfig.temporaryInsuredFieldId.eftPaymentProvider].find(option => {
            if (option.value === eftData?.temporaryInsurancePaymentProvider) {
              temporaryLifeInsuranceForm.get(control).setValue(option);
            }
          });
        } else if (control?.indexOf('/EftAccountholderFirstName') > -1) {
          temporaryLifeInsuranceForm.get(control).setValue(eftData?.firstName);
        } else if (control?.indexOf('/EftAccountholderLastName') > -1) {
          temporaryLifeInsuranceForm.get(control).setValue(eftData?.lastName);
        } else {
          this.updateEFTAddressAccountDetails(temporaryLifeInsuranceForm, control, eftData);
        }
      });
      this.disableEFTFields();
    }
  }

  private updateEFTAddressAccountDetails(temporaryLifeInsuranceForm, control, eftData): void {
    if (control?.indexOf('/EftStreetAddress') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.address);
    } else if (control?.indexOf('/EftCityAddress') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.city);
    } else if (control?.indexOf('/EftStateAddress') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.state);
    } else if (control?.indexOf('/EftZipCode') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.zipcode);
    } else if (control?.indexOf('/EftPayorEmailAddress') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.emailAddress);
    } else if (control?.indexOf('/EftPrimaryPhoneNumber') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.primaryPhoneNumber);
    } else if (control?.indexOf('/EftCellPhoneNumber') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.cellPhoneNumber);
    } else if (control?.indexOf('/EftBankUnionName') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.bankOrCreditUnionName);
    } else if (control?.indexOf('/EftRoutingNumber') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.routingNumber);
    } else if (control?.indexOf('/EftAccountNumber') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.accountNumber);
    } else if (control?.indexOf('/EftAccountType') > -1) {
      temporaryLifeInsuranceForm.get(control).setValue(eftData?.accountType);
    }
  }

  private disableEFTFields(): void {
    setTimeout(() => {
      this.disableFields(this.appConfig.disabledEFTFields);
      if (this.hasEftScreen) {
        this.disableFields(this.appConfig.disableAccountFields);
      }
    }, 100);
  }

  private disableFields(listOfFields: any[]): void {
    listOfFields.forEach((field) => {
      Object.keys(this.temporaryLifeInsuranceForm.controls).forEach(contrl => {
        if (contrl.indexOf(field) > -1) {
          this.temporaryLifeInsuranceForm.get(contrl).disable();
        }
      });
    });
  }

  private getStateValue(stateValue: string): any {
    let selectedStateOption;
    selectedStateOption = this.fetchStateValueFromQuestionData(stateValue, this.temporaryLifeInsuranceData, selectedStateOption);
    return {
      checked: true,
      label: selectedStateOption?.description,
      value: selectedStateOption?.value,
    };
  }

  private fetchStateValueFromQuestionData(stateValue: string, temporaryLifeInsuranceData: any[], selectedStateOption: any): any {
    let valueFetched = false;
    if (!selectedStateOption) {
      temporaryLifeInsuranceData.forEach(ques => {
        if (ques.xmlTag === EFTConstant.stateXML) {
          valueFetched = true;
          selectedStateOption = this.getStateOption(stateValue, ques.questionOption);
        } else if (ques.hasReflexive === 'true' && !valueFetched) {
          selectedStateOption = this.fetchStateValueFromQuestionData(stateValue, ques.reflexiveQuestionAL, selectedStateOption);
        }
      });
    }
    return selectedStateOption;
  }

  private getStateOption(stateValue: string, optionChoice: any[]): any {
    return optionChoice.filter((state) => {
      return state.value === stateValue;
    })[0];
  }

  private getPrimaryData(): any {
    const insuredAddress = this.userService.getInsuredAddress();
    const address1 = 'address1';
    const state = 'state';
    const zipCode = 'zipCode';
    const city = 'city';
    return {
      temporaryInsurancePaymentProvider: this.insuredFirstName + '{ insuredName }' + this.insuredLastName,
      firstName: this.insuredFirstName,
      lastName: this.insuredLastName,
      address: insuredAddress ? insuredAddress[address1] : '',
      city: insuredAddress ? insuredAddress[city] : '',
      state: insuredAddress ? this.getStateValue(insuredAddress[state]) : '',
      zipcode: insuredAddress ? insuredAddress[zipCode] : '',
      emailAddress: this.userService.getInsuredEmail(),
      primaryPhoneNumber: this.userService.getInsuredPhoneNo(),
    };
  }
}
