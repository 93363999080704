<section class="agent-instr-page page-content-container">
  <div class="questions--container">

    <h1 class="lfg-color">
      Agent Instructions
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <div *ngIf="envelopeCreated"><lfg-alert-message type="success">{{ ELECTRONIC_SIGN_CONST.Packet_Success_Msg }}</lfg-alert-message></div>
    <div *ngIf="errorOccuredWhileCreateEnvelope || errorOccuredToGetTransactionId"><lfg-alert-message type="error">{{ Message.GENERIC_ERROR }}</lfg-alert-message></div>

    <ng-container class="form-control-parent" *ngFor="let data of agentInstructionData; let ind = index;">

      <!-- Element for Alert Text -->
      <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
        <lfg-alert-message type="warning">{{ data.questionText }}</lfg-alert-message>
      </div>

      <!-- Element for Alert Text -->
      <div class="signing-instruction" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Instruction'">
        <div [innerHTML]="data.questionText"></div>
      </div>

      <!-- Element for showing text -->
      <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Instruction' && data.subText != 'Alert'">
        <h2>{{ data.questionText.replace('(Agent Name)', agentName) }}</h2>
      </div>
    </ng-container>

    <form [formGroup]="mainFormGroup" id="mainFormGroup" method="post" *ngIf="mainFormGroup" class="form-container">
      <ng-container *ngFor="let grid of gridSequences; let i = index;" [formArrayName]="grid.gridId">
        <div [formGroupName]="grid.gridIndex" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
          <ng-container class="form-control-parent" *ngFor="let data of gridQuestionsObj[grid.gridId].gridQuestions; let ind = index;">
            <!-- Form element for text  -->

            <!-- Questions headers -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText === '{signer}'">
              <h2 class="grid-ques">{{ gridAnswers[i][data.name]}}</h2>
            </div>

            <div class="form--control form-control-medium display-block" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes !== 'Hidden'">
              <lfg-input-wrap>
                <input lfgInput [placeholder]="data.required != 'true' ? data.questionText + '(optional)': data.questionText" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="alphaNumeric" [maxlength]="data.maxLength" [required]="data.required == 'true'">

                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line' && i !== gridSequences?.length - 1">
            </div>

            <!-- Form element for Drop Down -->
            <div class="form--control form-dropDown form-control-medium" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" [placeholder]="data.required != 'true' ? data.questionText + '(optional)': data.questionText" (selectionChanged)="handleSelectionChange(data, grid.gridId ,grid.gridIndex)" [required]="data.required == 'true'">
              </lfg-dropdown>
              <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
            </div>

            <!-- Nestion question Level 1 -->
            <ng-container *ngIf="data.hasReflexive == 'true' && checkIfQuestionDisplayed(gridForm, data) && gridForm.get(data.fieldId).value as parentAnswer">
              <ng-container *ngFor="let gridInfoChild1 of data.reflexiveQuestionAL; index as gridInfoChild1ix">
                <ng-container *ngIf="nestedQuestionCheckgridInfo(data, gridInfoChild1, parentAnswer)">

                  <!-- Form element for text  -->
                  <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (!gridInfoChild1.notes || gridInfoChild1.notes.indexOf('ITES_CURRENCY_USD') == -1)">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" [placeholder]="gridInfoChild1.required != 'true' ? gridInfoChild1.questionText + '(optional)': gridInfoChild1.questionText" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">
                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for Drop Down -->
                  <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.SELECT ">
                    <lfg-dropdown formControlName="{{ gridInfoChild1.fieldId }}" id="{{displayIdCreation(gridInfoChild1)}}_{{gridInfoChild1ix}}" [options]="alldropDownOptions[gridInfoChild1.fieldId]" [placeholder]="gridInfoChild1.required != 'true' ? gridInfoChild1.questionText + '(optional)': gridInfoChild1.questionText" (selectionChanged)="handleSelectionChange(gridInfoChild1, grid.gridId ,grid.gridIndex)" [required]="gridInfoChild1.required == 'true'" [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="Please select a valid option."></lfg-error>
                  </div>

                </ng-container>
              </ng-container>
            </ng-container>

          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>
</section>
