<section>
    <div class="eticket-search-body">
        <form [formGroup]="eticketSearchForm" (submit)="searchPolicies()">
            <ul class="eticket-search-container">
                <li class="eticket-search--input">
                    <lfg-input-wrap>
                        <input id="clientLastName" lfgInput appNoSpecialSymbols customAttr="alphaNumeric" placeholder="Search by lastname" type="text" formControlName="userInputField" name='' value='' lfgValidateOnBlur aria-label="Search Text" autocomplete="off"
                             autocorrect="off" maxlength="50" />
                            <div aria-live="polite">
                                <lfg-error [visible]="eticketSerchFormControls['userInputField'].touched && eticketSerchFormControls['userInputField'].invalid && eticketSerchFormControls['userInputField'].errors?.minlength" message="Please enter a minimum of 3 characters when searching."></lfg-error>
                                <lfg-error *ngIf="eticketSerchFormControls['userInputField'].value.length >= 3" [visible]="eticketSerchFormControls['userInputField'].touched && eticketSerchFormControls['userInputField'].errors?.textError" message="Please enter a valid client name."></lfg-error>
                            </div>
                    </lfg-input-wrap>
                </li>
                <li class="eticket-search--button">
                    <button class="btn btn--eticket-search" type="button" [disabled]='eticketSearchForm.invalid || !isButtonDisabled' (click)="searchPolicies();" #searchBtn> SEARCH</button>
                </li>
                <li>
                    <a class="btn-link" type="button" (click)="clearSearch()"> Clear </a>
                </li>
            </ul>
        </form>
    </div>
</section>
