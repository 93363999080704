import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LfgLoaderService } from '@ng/lfg-loader';
import { ModalSize } from '@ng/lfg-modal-popup';
import { PackageInfo, Recipient } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ConsentConstant, ErrorMessage } from 'src/config/constants';
import { ACCESS_TYPE, AccessibilityConfig, FunctionalityConfig } from 'src/config/delegationFunctionality.config';
import { ProductCodes } from 'src/config/sideNav.config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consent-page',
  templateUrl: './consent-page.component.html',
  styleUrls: ['./consent-page.component.scss']
})
export class ConsentPageComponent implements OnInit {

  questionsData;
  @Input() disableForm: boolean;
  @Input() userAccessDetails: FunctionalityConfig;
  @Input() userFunctionalityConfig: AccessibilityConfig;
  consent: string;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  @Input() signer: Recipient;
  @Input() activePacket: PackageInfo;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() signingStatusUpdated: EventEmitter<string> = new EventEmitter();
  @Output() consentDeclined: EventEmitter<string> = new EventEmitter();

  consentConstant = ConsentConstant;
  consentForm: FormGroup;
  consentPageData: any;

  showDocusignPage = false;
  docusignUrl: string;
  productSelected: string;

  formValid = true;
  formHasNoErrors = true;

  welcomeMsg: string;
  declineConsentModal: any;
  isAgent = false;
  showLoader = false;
  consentStatus = '';
  docuSignState = '';
  baseURL = environment.uiBaseUrl;
  signerGuid: string;
  envelopeId: string;
  signingStatus: string;

  isTabVisited = false;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;

  docuSignUrltemp: string;
  isUpdateRequired = false;

  isSafariBrowser = false;

  accessType = ACCESS_TYPE;

  constructor(
    private fb: FormBuilder,
    private loaderService: LfgLoaderService,
    public appConfig: ApplicationConfig,
    private defaultPageLoadService: DefaultPageload,
    private formgeneratorService: FormGeneratorService,
    private tabService: TabDetailsService,
    public productService: ProductDetailsService,
    private utilsService: UtilsService) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.productSelected = this.productService.getProductName()?.toLowerCase();
      this.getSignerInfo();
      this.buildFormData();
      this.defaultPageLoadService.logPageLoad();
      this.initializeModal();
      this.updateWelcomeMsg();
      this.initializePage();
      this.isSafariBrowser = this.utilsService.isSafariBrowser();
      this.isTabVisited = this.tabService.activeTab?.status?.visited;
      this.defaultPageLoadService.disableFormIfLocked(this.consentForm, this.disableForm);
    }
  }

  private buildFormData(): any {
    this.consentPageData = this.questionsData.pages[0].questions;
    this.checkIfSigningCompleted();
    const form = this.formgeneratorService.createFormControls(
      this.consentPageData
    );
    this.consentForm = this.fb.group(form);
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.updateRequired();
      this.checkFormStatus();
    }, 200);
    this.consentForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private initializeModal(): void {
    this.declineConsentModal = {
      header: true,
      state: false,
      footer: true,
      size: ModalSize.medium
    };
  }

  agreeToContent(): void {
    this.defaultPageLoadService.logButtonClick('i agree');
    this.saveConsent(true);
  }

  declineConsent(): void {
    this.saveConsent(false);
    this.defaultPageLoadService.logButtonClick('i decline');
    this.declineConsentModal.state = false;
  }

  private updateWelcomeMsg(): void {
    this.welcomeMsg = ConsentConstant.WELCOME_MSG
      .replace('{name}', this.signer?.fullName)
      .replace('{email}', this.signer?.email?.toLowerCase());
    this.isAgent = this.signer?.roleName === 'Agent';
  }

  private saveConsent(isAgree: boolean, isReadyToSign?: boolean): void {
    this.loaderService.show();
    this.showLoader = true;
    this.defaultPageLoadService.saveConsent(this.signerGuid, isAgree, this.envelopeId).subscribe((res) => {
      if (res.responseStatus === 'SUCCESS' && res.data) {
        if (res.data.isConsented?.toLowerCase() === 'true') {
          this.consentStatus = isReadyToSign ? 'READY_TO_SIGN' : 'APPROVED';
          this.docuSignUrltemp = res.data.docusignUrl;
          this.checkFormStatus();
          this.redirectToDocuSign(isReadyToSign);
          // saving page is not neccessary when user declined as it eventually be deleted
          if (isAgree) {
            this.signingStatusUpdated.emit('yes');
          }
        } else {
          this.consentStatus = 'DECLINED';
          this.docuSignUrltemp = undefined;
          this.checkFormStatus();
          this.consentDeclined.emit('yes');
          // call void envelope api
        }
      }
    },
      (error) => {
        console.log(error);
      },
      () => {
        this.showLoader = false;
        this.loaderService.hide();
      });
  }

  private redirectToDocuSign(isReadyToSign: boolean): void {
    if (isReadyToSign) {
      this.openDocuSignInIFrame(this.docuSignUrltemp);
    }
  }

  startESignature(): void {
    this.consentStatus = 'READY_TO_SIGN';
    this.defaultPageLoadService.logButtonClick('start esignature');
    this.openDocuSignInIFrame(this.docuSignUrltemp);
    this.checkFormStatus();
  }

  viewContent(doc: string): void {
    this.defaultPageLoadService.logLinkClick('print document');
    if (doc === 'term') {
      window.open(this.baseURL + 'assets/documents/term-and-condition.pdf');
    } else if (doc === 'consent') {
      if ((this.productSelected === ProductCodes.WEALTHACCELERATE2022)
        || (this.productSelected?.indexOf(ProductCodes.TERMACCEL) > -1)) {
        window.open(this.baseURL + 'assets/documents/LFF11620A-Electronic-Transmission-Consent.pdf');
      } else {
        window.open(this.baseURL + 'assets/documents/LFF11620-Electronic-Transmssions.pdf');
      }
    }
  }

  openConfirmationModal(): void {
    this.declineConsentModal.state = true;
  }

  closeDeclineModalPopup(): void {
    this.declineConsentModal.state = false;
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.consentForm,
      data: null,
    };
    this.setConsentAndDocuSignStatus();
    for (const data of this.consentPageData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid && this.consentStatus === 'READY_TO_SIGN' && this.docuSignState === 'ACCEPTED',
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'consentform' + this.signer?.displayRole,
      isFormChange: this.consentForm.dirty,
      // when docuSign status is declined no need to save the page as page will be deleted
      // when consent status is Ready to Sign and docuSign status is Accepted the form is already saved so no need to save
      saveRequired: !((this.formValid && this.consentStatus === 'READY_TO_SIGN' && this.docuSignState === 'ACCEPTED')
        || (this.docuSignState === 'DECLINED' || this.consentStatus === 'DECLINED'))
    };
    this.formStatus.emit(obj);
  }

  private initializePage(): void {
    this.getConsentAndDocuSignStatus();
    this.getUpdatedDocuSignUrl();
    this.getConsentContent();
  }

  private getConsentAndDocuSignStatus(): void {
    this.consentPageData.forEach((ques) => {
      if (ques.notes === 'CONSENT_STATUS') {
        this.consentStatus = ques.question_answer;
      } else if (ques.notes === 'DOCUSIGN_STATUS') {
        this.docuSignState = ques.question_answer;
      }
    });
  }

  private getConsentContent(): void {
    if ((this.productSelected === ProductCodes.WEALTHACCELERATE2022)
      || (this.productSelected?.indexOf(ProductCodes.TERMACCEL) > -1)) {
      this.consent = this.consentConstant.CONSENT_FOR_TERM_WEALTH;
    } else {
      this.consent = this.consentConstant.CONSENT_EXCEPT_TERM_WEALTH;
    }
  }

  private getUpdatedDocuSignUrl(): void {
    if ((this.consentStatus === 'READY_TO_SIGN' || this.consentStatus === 'APPROVED') && this.docuSignState?.length === 0
      && this.userAccessDetails?.editCase
      && this.userFunctionalityConfig?.accessType === ACCESS_TYPE.SUPERACCESS) {
      this.saveConsent(true, this.consentStatus === 'READY_TO_SIGN');
    }
  }

  private setConsentAndDocuSignStatus(): void {
    this.consentPageData.forEach((ques) => {
      if (ques.notes === 'CONSENT_STATUS') {
        ques.question_answer = this.consentStatus;
      } else if (ques.notes === 'DOCUSIGN_STATUS') {
        ques.question_answer = this.docuSignState;
      }
    });
  }

  private getSignerInfo(): void {
    this.envelopeId = this.activePacket?.envelopeId;
    const signerInfo = this.activePacket?.recipients?.filter(signer => {
      return signer.id === this.signer?.id;
    })[0];
    this.signerGuid = signerInfo?.signerGuid;
    this.signingStatus = signerInfo?.docusignStatus;
  }

  private openDocuSignInIFrame(docuSignUrl: string): void {
    this.docusignUrl = docuSignUrl;
    this.showDocusignPage = true;
  }

  docuSignStatus(event: any): void {
    if (event === 'ACCEPTED' || event === 'DECLINED') {
      this.docuSignState = event;
      this.checkFormStatus();
      if (event === 'DECLINED') {
        this.consentDeclined.emit('yes');
      } else {
        this.signingStatusUpdated.emit('yes');
      }
    }
  }

  private checkIfSigningCompleted(): void {
    // this is for the use case when user has signed the document in docuSign but on click of finish eticket got logged off
    if (this.signingStatus?.toLowerCase() === 'completed' && !this.disableForm) {
      this.consentPageData.forEach((ques) => {
        if (ques.notes === 'DOCUSIGN_STATUS' && !ques.question_answer) {
          ques.question_answer = 'ACCEPTED';
          this.isUpdateRequired = true;
        } else if (ques.notes === 'CONSENT_STATUS' && ques.question_answer !== 'READY_TO_SIGN') {
          ques.question_answer = 'READY_TO_SIGN';
          this.isUpdateRequired = true;
        }
      });
    }
  }

  private updateRequired(): void {
    if (this.isUpdateRequired) {
      this.checkFormStatus();
      this.signingStatusUpdated.emit('yes');
      this.isUpdateRequired = false;
    }
  }
}
