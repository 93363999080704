import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridInstance } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, Message, OwnerInfoConstant } from 'src/config/constants';

@Component({
  selector: 'app-owner-info',
  templateUrl: './owner-info.component.html',
  styleUrls: ['./owner-info.component.scss']
})
export class OwnerInfoComponent implements OnInit {

  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() ownerInfoUpdated: EventEmitter<string> = new EventEmitter();

  questionsData;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  gridHasNoErrors = false;
  formHasNoErrors = true;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  alldropDownOptions = {};
  setTimeFocus = null;
  gridQuestionsObj;
  isTabVisited: boolean;

  noOfTrustSelected = 0;
  maxNoOfOwner: number;
  ownerTypeName;
  totalNoOfOwnerAdded = 0;

  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  multipleOwnerError = ErrorMessage.TRUST_OWNER_ERROR;
  individualExeRiderError = false;
  businessExeRiderError = false;
  Message = Message;

  constructor(
    public appConfig: ApplicationConfig,
    private tabDetailsService: TabDetailsService,
    private userDetailsService: UserDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private productService: ProductDetailsService,
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.maxNoOfOwner = +(this.gridInstance[0]?.maxGridIndex);
      this.isTabVisited = this.tabDetailsService.getActiveTab()?.status?.visited;
      this.ownerTypeName = this.getNameFromXmlTag(OwnerInfoConstant.ownerType);
      this.updateErrorBasedOnRider();
      this.defaultPageLoadService.logPageLoad();
    }
  }

  gridformStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.checkForOwnerType();
    this.updateListOfOwnersSelected();
    this.isGridFormChange = event.isGridFormChange;
    this.emitStatus();
  }

  private checkForOwnerType(): void {
    this.totalNoOfOwnerAdded = this.gridAnswers?.length;
    this.noOfTrustSelected = 0;
    this.gridAnswers.forEach(gridVal => {
      if (gridVal[this.ownerTypeName]?.toLowerCase().indexOf('trust') > -1) {
        this.noOfTrustSelected++;
      }
    });
  }

  private updateListOfOwnersSelected(): void {
    const ownerTypeList = [];
    this.gridAnswers.forEach(gridVal => {
      if (gridVal[this.ownerTypeName] && gridVal[this.ownerTypeName].trim() !== '') {
        ownerTypeList.push(gridVal[this.ownerTypeName].trim().toLowerCase().substring(gridVal[this.ownerTypeName].indexOf('}') + 1));
      }
    });
    this.userDetailsService.setSelectedOwners(ownerTypeList);
  }

  private updateOwnerInfo(): void {
    if (this.noOfTrustSelected > 0) {
      this.ownerInfoUpdated.emit('yes');
    } else {
      this.ownerInfoUpdated.emit('no');
    }
  }

  emitStatus(): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    this.updateErrorBasedOnRider();
    const riderErrorExists = this.businessExeRiderError || this.individualExeRiderError;
    const existingInsuranceInfoObj = {
      formValid: this.formValid && this.isGridFormValid && ((this.noOfTrustSelected > 0 && this.totalNoOfOwnerAdded === 1)
        || this.noOfTrustSelected === 0) && !riderErrorExists,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'ownerInfoForm',
      gridForm: true,
      isFormChange: this.isGridFormChange || !this.isTabVisited,
    };
    this.formStatus.emit(existingInsuranceInfoObj);
    this.updateOwnerInfo();
  }

  private getNameFromXmlTag(xmlTag: string): string {
    return this.gridInstance[0].gridQuestions.filter(ques => {
      return ques.xmlTag === xmlTag;
    })[0]?.name;
  }

  private updateErrorBasedOnRider(): void {
    if (this.gridAnswers?.length === 4) {
      this.businessExeRiderError = this.userDetailsService.getBusinessEnhanceRiderSeleted() && !this.isCorporateSelected();
      this.individualExeRiderError = this.userDetailsService.getIndividualEnhanceRiderSeleted() && this.isCorporateSelected();
    }
  }

  private isCorporateSelected(): boolean {
    let isCorporateSelected = false;
    this.gridAnswers.forEach(gridVal => {
      if (gridVal[this.ownerTypeName]?.toLowerCase().indexOf('entity') > -1) {
        isCorporateSelected = true;
      }
    });
    return isCorporateSelected;
  }
}
