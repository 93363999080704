import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DelegationFunctionalityConfig {
    accessFunctionalityList: AccessibilityConfig[] = [
        {
            accessType: ACCESS_TYPE.READONLY,
            functionalityAccess: {
                submit: false,
                delete: false,
                duplicate: false,
                editCase: false,
                agentSign: false,
                newCase: false,
                readOnlyPostLock: true,
                caseRelatedAccess: {
                    onBehalfOfMessage: false,
                    redirectToLastAccessedPage: true,
                    showAgentConfCheckbox: false,
                    unlockCase: false,
                }
            }
        },
        {
            accessType: ACCESS_TYPE.TRANSACT,
            functionalityAccess: {
                submit: false,
                delete: false,
                duplicate: true,
                editCase: true,
                agentSign: false,
                newCase: true,
                readOnlyPostLock:  true,
                caseRelatedAccess: {
                    onBehalfOfMessage: true,
                    redirectToLastAccessedPage: true,
                    showAgentConfCheckbox: false,
                    unlockCase: true,
                }
            }
        },
        {
            accessType: ACCESS_TYPE.SUPERACCESS,
            functionalityAccess: {
                submit: true,
                delete: true,
                duplicate: true,
                editCase: true,
                agentSign: true,
                newCase: true,
                readOnlyPostLock:  false,
                caseRelatedAccess: {
                    onBehalfOfMessage: false,
                    redirectToLastAccessedPage: true,
                    showAgentConfCheckbox: false,
                    unlockCase: true,
                }
            }
        }
    ];
}

export class AccessibilityConfig {
    accessType: string;
    functionalityAccess: FunctionalityConfig;
}

export class FunctionalityConfig {
    submit: boolean;
    delete: boolean;
    duplicate: boolean;
    editCase: boolean;
    agentSign: boolean;
    newCase: boolean;
    readOnlyPostLock?: boolean;
    caseRelatedAccess?: CaseAccessConfig;
}

export class CaseAccessConfig {
    onBehalfOfMessage: boolean;
    redirectToLastAccessedPage: boolean;
    showAgentConfCheckbox: boolean;
    unlockCase: boolean;
}

export const ACCESS_TYPE = {
    READONLY: 'readonly',
    TRANSACT: 'transact',
    SUPERACCESS: 'admin'
};
