import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { AttestationMsg, ErrorMessage } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';

@Component({
  selector: 'app-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss'],
})
export class AttestationComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;

  @Input() userAccessDetails: FunctionalityConfig;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  attestationInfoData = [];
  attestationInfoForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  placeholders = {};
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  tabStatusOnInit: TabStatus;
  delegateMsg = AttestationMsg.DELEGATE_INFO;
  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.attestationInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.attestationInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  buildFormData(): any {
    this.attestationInfoData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.attestationInfoData
    );
    this.attestationInfoForm = this.fb.group(form);
    this.valueChanges();
    if (this.disableForm && this.attestationInfoForm) {
      this.attestationInfoForm.disable();
    }
  }

  valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.attestationInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.attestationInfoForm,
      data: null,
    };
    for (const data of this.attestationInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'attestationInfoForm',
      isFormChange: this.attestationInfoForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.attestationInfoForm)
    };
    this.formStatus.emit(obj);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  onCheckboxChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.attestationInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.attestationInfoForm.get(key)?.markAsUntouched();
      }
      this.attestationInfoForm.patchValue(updatedval);
    }
  }
}
