import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ServiceInvoker } from '../../services/service-invoker';

@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss']
})
export class SystemMessageComponent implements OnInit {

  messages = [];

  @Output() hasError: EventEmitter<any> = new EventEmitter();

  messageTypeMap = new Map([
    ['outage', 'warning'],
    ['info', 'info'],
    ['wish', 'success'],
    ['error', 'error'],
    ['warning', 'warning'],
  ]);

  constructor(private serviceInvoker: ServiceInvoker) { }

  ngOnInit(): void {
    this.loadMessage();
  }

  private loadMessage(): void {
    const payload = {
      app_id: [
        'eticket'
      ]
    };
    this.serviceInvoker.invokeService('GET_SYSTEM_MESSAGE', null, payload).subscribe((response) => {
      if (response.outages?.length > 0) {
        response.outages.forEach((outage) => {
          this.messages.push({
            messageText: outage.message,
            messageType: this.messageTypeMap.get(outage.message_type?.toLowerCase()) ?? 'info'
          });
        });
      }
    }).add(() => {
      this.checkIfErrorExists() ? this.hasError.emit('yes') : this.hasError.emit('no');
    });
  }

  private checkIfErrorExists(): boolean {
    return this.messages.filter((message) => {
      return message.messageType === 'error';
    }).length > 0;
  }
}
