<section class="existing-insurance-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      {{forProposedInsured ? 'Existing Insurance for Proposed Insured B' : 'Existing Insurance'}}
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid || this.hasError)" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="existingInsuranceInfoForm" id="existingInsuranceInfoForm" method="post" *ngIf="existingInsuranceInfoData && existingInsuranceInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of existingInsuranceInfoData; let ind = index;">

          <ng-container *ngIf="(!data.notes || data.notes.indexOf('IF_EXISTING_INSURANCE') == -1)">

            <!-- Questions labels -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.questionText !== 'ACCORDION' && data.questionText !== 'ACCORDION_MASSACHUSETTS' &&
            data.questionText != 'ACCORDION_INDIANA' && data.questionText != 'ACCORDION_INDIANA2' && data.subText != 'Information' && data.subText != 'Alert' && data.subText != 'Header' && data.subText != 'Button'
            && ((data.fieldId !== EI_CONSTANT.existingInsInfoFieldId && data.fieldId !== EI_CONSTANT.infoOnPresentPolicyFieldId) || (data.fieldId === EI_CONSTANT.existingInsInfoFieldId && data.display) || (data.fieldId === EI_CONSTANT.infoOnPresentPolicyFieldId && hasExistingInsurance))">
              <p>{{ data.questionText }}</p>
            </div>

            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Header'">
              <h2>{{ data.questionText }}</h2>
            </div>

            <div class="form--control validate-btn form--control__full-width bottom-container" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Button'">
              <button id="view-notice-btn" (click)="viewNotice($event); focusOff($event);" class="btn focusOffBtn" aria-label="View Notice" [disabled]="disableForm">{{data.questionText}}</button>
            </div>

            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

            <!-- Form element for text  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1)">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required == 'true'">

                <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <div class="section-divider" *ngIf="data.xmlTag === EI_CONSTANT.comparativeInfo && data.display">
            </div>

            <!-- Form element for radio question -->
            <div class="radio-btn-custom lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && (data.xmlTag !== EI_CONSTANT.comparativeInfo
            || (data.xmlTag === EI_CONSTANT.comparativeInfo && data.display)) && data.questionOption[0].description?.length <= 20" [ngClass]="{'radio--err': (existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid)}">
              <span class="lfg-radio-section">
                <p class="question-radio_text">{{ data.questionText }} <lfg-tooltip *ngIf="data.tooltip" class="tooltip-container">{{data.tooltip}}</lfg-tooltip></p>
                <div class="custom-radio-button radio-btn-rect">
                  <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                  <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                  <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                  <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                </div>
              </span>
              <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
              <lfg-error *ngIf="hasError && data.xmlTag === EI_CONSTANT.existingFundsQuesId" [message]="existingInsError"></lfg-error>
            </div>

            <!-- Form element for currency  -->
            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1)" class="form--control" [ngClass]="{'inline-ques': data.questionText.length >= 27 }">
              <ng-container *ngIf="data.questionText.length >= 27">
                <lfg-input-wrap>
                  <div class="inline-ques-container">
                    <span  class="inline-ques-text">{{ addCorrectPlaceHolder(data, 'placeHolder') }}</span>
                    <label for="{{data.fieldId}}">
                      <span class="visually-hidden">{{data.questionText}}</span>
                      <input lfgInput [id]="data.fieldId" aria-live="off" class="inline-ques-input" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="data.required == 'true'">
                      <div class="inline-error">
                        <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                      </div>
                    </label>
                  </div>
                </lfg-input-wrap>
              </ng-container>
              <ng-container *ngIf="data.questionText.length < 27">
                <lfg-input-wrap>
                  <input lfgInput placeholder="{{addCorrectPlaceHolder(data, 'placeHolder')}}" type='text' formControlName='{{ data.fieldId }}' aria-live="off" autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="data.required == 'true'">
                  <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, existingInsuranceInfoForm.get(data.fieldId)) }}"></lfg-error>
                </lfg-input-wrap>
              </ng-container>
            </div>

            <!-- Form element for radio question having longer desc -->
            <div aria-live="assertive" class="lfg-customRadio-field-long" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.questionOption[0].description?.length > 20" [ngClass]="{'radio--err': (existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid)}">
              <span class="radio-question-container">
                <p class="question-radio_text">{{ addCorrectPlaceHolder(data, 'placeHolder') }}</p>
                <div class="radio-button-container">
                  <label class="radio-input-label" [ngClass]="{'radio-button-checked': existingInsuranceInfoForm.get(data.fieldId).value === data.questionOption[0].value}">
                    <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="existingInsuranceInfoForm.get(data.fieldId).value === data.questionOption[0].value"></i>
                    <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="existingInsuranceInfoForm.get(data.fieldId).value !== data.questionOption[0].value"></i>
                    <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                    <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                  </label>
                  <label class="radio-input-label" [ngClass]="{'radio-button-checked': existingInsuranceInfoForm.get(data.fieldId).value === data.questionOption[1].value}">
                    <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="existingInsuranceInfoForm.get(data.fieldId).value === data.questionOption[1].value"></i>
                    <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="existingInsuranceInfoForm.get(data.fieldId).value !== data.questionOption[1].value"></i>
                    <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                    <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                  </label>
                </div>
              </span>
              <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, existingInsuranceInfoForm.get(data.fieldId)) }}"></lfg-error>
            </div>

            <!-- Form element for alphanumeric  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

                <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for Drop Down -->
            <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid) }">
              </lfg-dropdown>
              <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
            </div>

            <!-- Question Info -->
            <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
              <lfg-alert-message type="warning">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Accordian for All states -->
            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'ACCORDION' && gridInstance?.length > 0 && hasExistingInsurance && insuredState !== 'mn' && insuredState !== 'in'">
              <div class="questions-header">
                <p *ngIf="insuredState === 'de'" class="grid-info">{{ MSG.EXISTING_INS_INFO_DE }}</p>
                <p *ngIf="insuredState !== 'de' && insuredState !== 'mn'" class="grid-info">{{ MSG.EXISTING_INS_INFO_NON_DE }}</p>
              </div>
              <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="maxNoOfInsurance" [accordionHeader]="'Existing Insurance'" [type]="'POLICY'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-structure>
            </div>

             <!-- Accordian for indiana state -->
             <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'ACCORDION' && gridInstance?.length > 0 && (hasExistingInsurance && (existingInsAnswers[EI_CONSTANT.quitExistingPolicyQuesId] || existingInsAnswers[EI_CONSTANT.existingFundsQuesId])) && insuredState === 'in'">
              <div class="questions-header">
                <lfg-alert-message type="info">{{ MSG.EXISTING_INSURANCE_IN_YES }}</lfg-alert-message>
              </div>
              <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="maxNoOfInsurance" [accordionHeader]="'Existing Insurance'" [type]="'POLICY'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-structure>
            </div>

            <!-- Accordian for MICHIGAN -->
            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'ACCORDION' && gridInstance2?.length > 0 && hasExistingInsurance && hasReplacement && insuredState === 'mi'">
              <div class="questions-header">
                <p class="grid-info">{{ MSG.REPLACEMENT_INS_INFO_MI }}</p>
              </div>
              <app-grid-structure [gridInstances]="gridInstance2" [gridAnswers]="gridAnswers2" [subType]="'POLICY_GRID_2'" (gridStatus)="gridformStatus($event, 2)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="3" [accordionHeader]="'Replaced Policy(ies) or Annuity Contract(s)'" [type]="'POLICY'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-structure>
            </div>

            <!-- Accordian for MINNESOTA -->
            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'ACCORDION' && gridInstance?.length > 0 && hasExistingInsurance && insuredState === 'mn'">
              <app-grid-structure [gridInstances]="gridInstance2" [gridAnswers]="gridAnswers2" [subType]="'POLICY_GRID_2'" (gridStatus)="gridformStatus($event, 2)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="4" [accordionHeader]="'Existing Insurance Details'" [type]="'POLICY'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-structure>
            </div>

            <!-- Accordian for MASSACHUSETTS -->
            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'ACCORDION_MASSACHUSETTS' && gridInstance2?.length > 0 && hasCheckBoxReq && insuredState === 'ma'">
              <app-grid-structure [gridInstances]="gridInstance2" [gridAnswers]="gridAnswers2" [subType]="'POLICY_GRID_2'" (gridStatus)="gridformStatus($event, 2)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="3" [accordionHeader]="'Details'" [type]="'POLICY'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-structure>
            </div>

            <!-- Accordian for INDIANA GRid2 -->
            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'ACCORDION_INDIANA' && gridInstance2?.length > 0 && hasExistingInsurance && !(existingInsAnswers[EI_CONSTANT.quitExistingPolicyQuesId] || existingInsAnswers[EI_CONSTANT.existingFundsQuesId]) && insuredState === 'in'">
              <div class="question-info">
                <lfg-alert-message type="info">{{ MSG.EXISTING_INSURANCE_IN }}</lfg-alert-message>
              </div>
              <app-grid-structure [gridInstances]="gridInstance2" [gridAnswers]="gridAnswers2" [subType]="'POLICY_GRID_2'" (gridStatus)="gridformStatus($event, 2)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="3" [accordionHeader]="'Existing Insurance Details'" [type]="'POLICY'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-structure>
            </div>

            <!-- Accordian for INDIANA GRid3 -->
            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'ACCORDION_INDIANA2' && gridInstance3?.length > 0 && hasExistingInsurance && insuredState === 'in'">
              <div class="question-info">
                <lfg-alert-message type="info">{{ MSG.PROPOSOSED_INSURANCE_IN }}</lfg-alert-message>
              </div>
              <app-grid-structure [gridInstances]="gridInstance3" [gridAnswers]="gridAnswers3" [subType]="'POLICY_GRID_3'" (gridStatus)="gridformStatus($event, 3)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="3" [accordionHeader]="'Proposed Policy Information'" [type]="'POLICY'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-structure>
            </div>

          </ng-container>
          <ng-container *ngIf="(data.notes && data.notes.indexOf('IF_EXISTING_INSURANCE') > -1) && hasExistingInsurance">

            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Header'">
              <h2>{{ data.questionText }}</h2>
            </div>

             <!-- Form element for label  -->
             <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Header' && data.subText != 'Information' && data.questionText != 'ACCORDION_INDIANA' && data.questionText != 'ACCORDION_INDIANA2'">
              <p>{{ data.questionText }}</p>
            </div>

            <!-- Element for Information Condition  -->
            <ng-container *ngIf="!hasAreYouConsiderReq1 && !hasAreYouConsiderReq2">
              <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
                <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
              </div>
            </ng-container>

            <!-- Form element for radio question -->
            <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid)}">
              <span class="lfg-radio-section">
                <p class="question-radio_text">{{addCorrectPlaceHolder(data, 'placeHolder')}}</p>
                <div class="radio-btn-rect custom-radio-button">
                  <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                  <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                  <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                  <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                </div>
              </span>
              <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
              <lfg-error *ngIf="hasError && data.xmlTag === EI_CONSTANT.existingFundsQuesId" [message]="existingInsError"></lfg-error>
            </div>

            <!-- Element for Information Condition  -->
            <ng-container *ngIf="hasAreYouConsiderReq1 && hasAreYouConsiderReq2">
              <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
                <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
              </div>
            </ng-container>

            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

            <!-- Form element for text  -->
            <div class="form--control form-control-medium" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.subText !== 'COMMON-TEXT') && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1)">
              <lfg-input-wrap>
                <label for="{{data.fieldId}}">
                  <span class="visually-hidden">{{data.questionText}}</span>
                  <input [id]="data.fieldId" lfgInput name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required == 'true'" (input)="updateCheckboxValidation(data)">
                </label>
                <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for currency  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1)" [ngClass]="{'inline-ques': data.questionText.length >= 27 }">
              <ng-container *ngIf="data.questionText.length >= 27">
                <lfg-input-wrap>
                  <div class="inline-ques-container">
                    <span  class="inline-ques-text">{{ addCorrectPlaceHolder(data, 'placeHolder') }}</span>
                    <label for="{{data.fieldId}}">
                      <span class="visually-hidden">{{data.questionText}}</span>
                      <input lfgInput aria-live="off" [id]="data.fieldId" class="inline-ques-input" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="data.required == 'true'">
                      <div class="inline-error">
                        <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                      </div>
                    </label>
                  </div>
                </lfg-input-wrap>
              </ng-container>
              <ng-container *ngIf="data.questionText.length < 27">
                <lfg-input-wrap>
                  <input lfgInput aria-live="off" placeholder="{{addCorrectPlaceHolder(data, 'placeHolder')}}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="data.required == 'true'">
                  <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, existingInsuranceInfoForm.get(data.fieldId)) }}"></lfg-error>
                </lfg-input-wrap>
              </ng-container>
            </div>

            <!-- Form element for text  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.subText == 'COMMON-TEXT') && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1)">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required == 'true'">

                <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for checkbox  -->
            <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
              <div class="lfg-checkbox">
                <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
                <label class="wrap wrap-check" for="{{ data.fieldId }}">{{addCorrectPlaceHolder(data, 'placeHolder')}}</label>
                <lfg-error *ngIf="existingInsuranceInfoForm.get(data.fieldId).touched && existingInsuranceInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
              </div>
            </div>

          </ng-container>
        </ng-container>

      </form>
    </section>
  </div>
</section>
