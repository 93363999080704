import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DisableStyle } from '@ng/lfg-calendar';
import { LfgLoaderService } from '@ng/lfg-loader';
import { GridInstance, Question, QuestionOption, ReflexiveQuestionAL } from 'src/app/shared/models/casePage.model';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import {
  AgentInfoConstant,
  ExistingInsuranceConstant,
  ExistingInsuranceMessage,
  ExistingInsuranceProposedBConstant,
  Message,
  OwnerInfoConstant,
  PrimaryInsuredConstant,
  TrustInfoConstant,
} from 'src/config/constants';
import { ProductCodes } from 'src/config/sideNav.config';

@Component({
  selector: 'app-grid-structure',
  templateUrl: './grid-structure.component.html',
  styleUrls: ['./grid-structure.component.scss'],
})
export class GridStructureComponent implements OnInit, OnChanges {
  @Input() disableForm: boolean;
  gridList: number[] = [];

  @Input() gridInstances: GridInstance[];
  @Input() gridAnswers: any[];
  @Input() maxGridAvailable = 10;
  @Input() accordionHeader: string;
  @Input() addAccordionText: string;
  @Input() type: string;
  @Input() subType: string;
  @Input() pageName: string;
  @Output() gridStatus: EventEmitter<any> = new EventEmitter();
  @Input() isRevisit = false;
  @Input() existingInsAnswers: {};
  @Input() forProposedInsured: boolean;

  mainFormGroup: FormGroup;
  gridQuestionsObj = {};
  formValid: boolean;
  formHasNoErrors: boolean;
  openAccordian = {};
  gridSequences: any = [];
  gridStatusVal = {};
  placeholders = {};
  alldropDownOptions = {};
  setTimeFocus = null;
  agentFirmSearchValid = {};
  enableFirmDropDown = {};
  policyBeingDropdown = [];
  policyOptional = false;
  showLoader = false;
  firmOptions = {};
  agentNPN = {};
  selectedFirm = {};
  selectedOtherFirmName = {};
  index = 0;
  isInternalCompany = {};
  isReplacedSelected = {};

  isCompanyInfoRequired = false;

  // for owner grid
  ownerStateInfo = {};

  CONST = ExistingInsuranceConstant;
  readonly AGENTCONST = AgentInfoConstant;
  readonly OWNERCONST = OwnerInfoConstant;
  readonly Message = ExistingInsuranceMessage;
  readonly phoneInfo = Message.PHONE_INFO;
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Issue Date (optional)',
    disableStyle: DisableStyle.locked,
  };
  dateOfBirthConfig = { ...this.dateConfig };
  dateOfTrustConfig = { ...this.dateConfig };
  dateOfAmendConfig = { ...this.dateConfig };
  dateOfBirthOwnerConfig = { ...this.dateConfig };
  dateOfExistingConfig = { ...this.dateConfig };
  defaultDateConfig = { ...this.dateConfig };
  dateOfIssueConfig = { ...this.dateConfig };
  issueDateConfig = { ...this.dateConfig };
  issueDateConfigOpt = { ...this.dateConfig };
  basicDateConfig = { ...this.dateConfig };
  futureDateConfig = { ...this.dateConfig };
  futureDateConfig2 = { ...this.dateConfig };
  stateList = [];

  trustInfoConstant = TrustInfoConstant;
  ErrorMsgs;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private agentService: AgentAndFirmService,
    private userService: UserDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private loaderService: LfgLoaderService,
    private productService: ProductDetailsService
  ) {
    this.initDateConfig();
  }

  ngOnInit(): void {
    this.CONST = this.forProposedInsured ? ExistingInsuranceProposedBConstant : ExistingInsuranceConstant;
    if (!this.gridInstances) {
      return;
    }
    this.initializeGridInstance();
    this.markAllFieldAsTouched();
    this.checkForCompanyInfo();
    this.defaultPageLoadService.disableFormIfLocked(this.mainFormGroup, this.disableForm);
    this.ErrorMsgs = this.appConfig.errorMessages;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.existingInsAnswers && (changes.existingInsAnswers.firstChange ||
      JSON.stringify(changes.existingInsAnswers.currentValue) !== JSON.stringify(changes.existingInsAnswers.previousValue))) {
      this.CONST = this.forProposedInsured ? ExistingInsuranceProposedBConstant : ExistingInsuranceConstant;
      this.updatePolicyBeingDropdown(changes.existingInsAnswers.firstChange);
    }
  }

  handleInsurerSelectionChange(data: Question, _gridId: string, _gridIndex: number, gridUniqueId?: string, gridForm?: FormGroup): void {
    if (data.xmlTag === this.CONST.existingCompanyNameXml && data.notes === 'INSURER-2') {
      const selectedInsurer = gridForm.get(data.fieldId)?.value?.value;
      this.updateInternalCompanyRelatedQues(selectedInsurer, gridForm, gridUniqueId);
    }
  }

  private updateInternalCompanyRelatedQuesForSavedAns(savedAns: any, newControls: FormGroup, gridId: string, gridUniqueId: string): void {
    if (this.type === 'POLICY') {
      const insurerName = this.utilsService.getNameByXmlTag(this.gridQuestionsObj[gridId].gridQuestions,
        this.CONST.existingCompanyNameXml);
      this.updateInternalCompanyRelatedQues(savedAns[insurerName], newControls, gridUniqueId);
    }
  }

  private updateInternalCompanyRelatedQues(insurerSelected: string, newControls: FormGroup, gridUniqueId: string): void {
    const isInternalCompany = this.defaultPageLoadService.getExistingInsuranceCompanyList().filter(val => {
      return val.companyName?.toString().toLowerCase() === insurerSelected?.toLowerCase().substring(insurerSelected.indexOf('}') + 1);
    })[0];
    if (isInternalCompany?.internFlag) {
      this.isInternalCompany[gridUniqueId] = true;
    } else {
      this.isInternalCompany[gridUniqueId] = false;
      this.gridInstances[0].gridQuestions.forEach(ques => {
        if ((ques.notes && ques.notes.indexOf('INTERNAL_COMPANY_QUESTION') !== -1)) {
          newControls.get(ques.fieldId)?.reset();
          newControls.get(ques.fieldId)?.setErrors(null);
          if (ques.controlTypeDesc === this.appConfig.fieldTypes.DOB) {
            newControls.get(ques.fieldId)?.clearValidators();
            newControls.get(ques.fieldId)?.updateValueAndValidity();
          }
        }
      });
    }
    this.disableProposedPolicyRadioButton(newControls);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  onRadioChange(data: Question, gridId: string, gridIndex: number): any {
    this.updatedAnswersOnHidden(data, gridId, gridIndex);
  }

  addCorrectPlaceHolder(data, type): any {
    this.updatePolicyRelatedData(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  getErrorMsg(data): any {
    return this.utilsService.getInlineErrorMessage(data);
  }

  handleSelectionChange(data: Question, gridId: string, gridIndex: number, gridUniqueId?: string, gridForm?: FormGroup): void {
    if (data.xmlTag === PrimaryInsuredConstant.addressState || data.xmlTag === OwnerInfoConstant.ownerType) {
      this.isOwnerStateSameAsContractState(gridForm, gridUniqueId, data);
    }
    this.updatedAnswersOnHidden(data, gridId, gridIndex);
  }

  removeGrid(event: any): void {
    if (event?.deletedSequence) {
      this.gridSequences.splice(event?.index, 1);
    }
    const control = this.mainFormGroup.get(event?.obj?.gridId) as FormArray;
    control.removeAt(event?.obj?.gridIndex);
    this.updateGridSequence(event?.obj);
  }

  createNewGrid(isButtonClick?: boolean): void {
    const id = this.gridInstances[0].id;
    this.addGrid(id);
    const formArray = this.mainFormGroup.get(id) as FormArray;
    const gridUniqueId = new Date().toString() + this.index.toString();
    this.index++;
    const obj = {
      gridIndex: formArray.length - 1,
      gridId: id,
      formLoadCompleted: false,
      gridUniqueId,
    };
    this.gridSequences.push(obj);
    if (isButtonClick) {
      this.defaultPageLoadService.logButtonClick(this.addAccordionText);
    }
  }

  private checkIfDataAvailable(): void {
    if (this.gridAnswers && this.gridAnswers.length > 0) {
      this.prepopulateGrids(this.gridAnswers);
    } else {
      this.createNewGrid();
      this.updateGridSequenceValue();
    }
  }

  private valueChanges(): void {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.mainFormGroup.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.updateGridSequenceValue();
        this.checkFormStatus(true);
      }, 200);
    });
  }

  private checkFormStatus(formUpdated?: boolean): void {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.mainFormGroup,
      data: {},
      gridData: {},
      gridDataWithNameTag: {},
    };
    for (const data of this.gridSequences) {
      if (!componentData.gridData.hasOwnProperty(data.gridId)) {
        componentData.form = this.mainFormGroup;
        componentData = this.formgeneratorService.checkGridStatusAndGetGridData(
          componentData,
          data.gridId
        );
        this.formValid = componentData.formValid;
        this.formHasNoErrors = componentData.formHasNoErrors;
      }
    }
    const gridAnswers = [];
    for (const data of this.gridSequences) {
      gridAnswers.push(
        componentData.gridDataWithNameTag[data.gridId][data.gridIndex]
      );
      this.checkIfFormIsValid(data);
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      gridAnswers,
      formId: 'mainFormGroup',
      isGridFormChange: this.mainFormGroup.dirty || formUpdated,
    };
    this.gridStatus.emit(obj);
  }

  validateOnFocus(
    gridStructureData,
    gridStructureType,
    gridStructureFormGroup?
  ): void {
    clearTimeout(this.setTimeFocus);
    this.setTimeFocus = setTimeout(() => {
      const form = gridStructureFormGroup || this.mainFormGroup;
      if (gridStructureType === 'in') {
        form.get(gridStructureData.fieldId)?.markAsUntouched();
      } else {
        form.get(gridStructureData.fieldId)?.markAsTouched();
      }
    }, 10);
  }

  private addGrid(gridId: string, gridInd?): void {
    const gridFormGroup = this.formgeneratorService.createGrid(
      gridId,
      this.gridQuestionsObj[gridId].gridQuestions
    );
    const control = this.mainFormGroup.get(gridId) as FormArray;
    const newControls = this.fb.group(gridFormGroup);
    control.push(newControls);
    let i = this.gridSequences.length;
    if (gridInd && gridInd !== null) {
      i = gridInd;
    }
    this.updateAgentNpn(false, newControls);
    this.openLastGrid(i);
  }

  private openLastGrid(i: number): void {
    this.openAccordian = {};
    this.openAccordian[i] = true;
  }

  private updateGridSequence(deletedObj): void {
    for (const obj of this.gridSequences) {
      if (
        obj.gridId === deletedObj.gridId &&
        obj.gridIndex > deletedObj.gridIndex
      ) {
        obj.gridIndex -= 1;
      }
    }
  }

  private prepopulateGrids(savedAnswers: any[]): void {
    for (const savedAnswer of savedAnswers) {
      const id = this.gridInstances[0].id;
      const gridUniqueId = new Date().toString() + this.index.toString();
      this.index++;
      this.setSelectedFirm(savedAnswer, gridUniqueId);
      const updatedAnswerObject =
        this.formgeneratorService.updateGridAnswerArray(
          id,
          this.gridQuestionsObj,
          savedAnswer
        );
      const gridFormGroup = this.formgeneratorService.createGrid(
        id,
        this.gridQuestionsObj[id].gridQuestions,
        updatedAnswerObject
      );
      const formArray = this.mainFormGroup.get(id) as FormArray;
      const newControls = this.fb.group(gridFormGroup);

      this.updateReplacementQuestionForSavedAns(savedAnswer, newControls, id, gridUniqueId);
      this.updateFirmRelatedDetails(newControls, gridUniqueId);
      this.isOwnerStateSameAsContractState(newControls, gridUniqueId);
      this.updateInternalCompanyRelatedQuesForSavedAns(savedAnswer, newControls, id, gridUniqueId);
      this.updateExistingInsuranceCheckbox(savedAnswer, newControls);
      formArray.push(newControls);
      const obj = {
        gridIndex: formArray.length - 1,
        gridId: id,
        formLoadCompleted: true,
        gridUniqueId,
      };
      this.gridSequences.push(obj);
    }
    this.openLastGrid(savedAnswers?.length - 1);
  }

  private updateGridSequenceValue(): void {
    const obj = this.gridSequences[this.gridSequences.length - 1];
    if (obj) {
      obj.formLoadCompleted = true;
      this.gridSequences[this.gridSequences.length - 1] = obj;
    }
  }

  private initializeGridInstance(): void {
    const gridData = this.gridInstances[0];
    this.gridQuestionsObj[gridData.id] = gridData;
    this.updatePrimaryInsuredNameInExistingInsurance(gridData.gridQuestions);
    const gridDropDownOption = this.formgeneratorService.getdropdownOptions(
      gridData.gridQuestions
    );
    this.alldropDownOptions = {
      ...this.alldropDownOptions,
      ...gridDropDownOption,
    };
    this.updateStateList(gridDropDownOption);
    this.formgeneratorService.setGridQuestionsData(this.gridQuestionsObj);
    const form = {};
    form[this.gridInstances[0].id] = this.fb.array([]);
    this.mainFormGroup = this.fb.group(form);
    this.checkIfDataAvailable();
    this.valueChanges();
  }

  private checkForCompanyInfo(): void {
    const selectedProduct = this.productService.getProductName()?.toLowerCase();
    if (this.type === 'POLICY'
      && (selectedProduct === ProductCodes.ASSETEDGEVUL2022 || selectedProduct === ProductCodes.ASSETEDGEVUL2025)
      && this.userService.getBusinessEnhanceRiderSeleted()) {
      this.isCompanyInfoRequired = true;
    } else {
      this.isCompanyInfoRequired = false;
    }
  }

  private updatePolicyBeingDropdown(isFirstChange: boolean): void {
    if (this.type === 'POLICY' && this.existingInsAnswers) {
      this.policyBeingDropdown = [];
      if (this.existingInsAnswers[this.CONST.quitExistingPolicyQuesId]) {
        this.policyBeingDropdown.push({ checked: false, label: 'Replaced', value: 'Replaced{47}Replaced' });
      }
      if (this.existingInsAnswers[this.CONST.existingFundsQuesId]) {
        this.policyBeingDropdown.push({ checked: false, label: 'Financed', value: 'Financed{47}Financed' });
      }
      if (this.userService.getInsuredState()?.toLowerCase() !== 'in') {
        this.policyBeingDropdown.push({ checked: false, label: 'Inforce Only', value: 'Inforce Only{47}Inforce Only' });
      }
      if (!isFirstChange) {
        const id = this.gridInstances[0]?.id;
        const control = 'controls';
        this.mainFormGroup.controls[id]?.[control].forEach((form) => {
          form.get(this.getFieldIdByXmlTag(this.CONST.policyBeingQuesId))?.setValue('');
          form.get(this.getFieldIdByXmlTag(this.CONST.replacementQuesFieldId))?.setValue('');
          form.get(this.getFieldIdByXmlTag(this.CONST.replacementQuesFieldId))?.enable();
        });
      }
    }
  }

  checkIfFirmSearchIsValid(gridForm: FormGroup, gridUniqueId: string, xmlTag?: string): void {
    if (this.type === 'AGENT') {
      if (xmlTag === AgentInfoConstant.secAgentLastName) {
        this.resetFirmList(gridForm, gridUniqueId);
      }
      const secAgentSSNFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.secAgentSSNQuesId);
      const secAgentLastNameFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.secAgentLastName);
      if (gridForm.get(secAgentSSNFieldId)?.status === 'VALID'
        && gridForm.get(secAgentSSNFieldId)?.value !== '' &&
        gridForm.get(secAgentLastNameFieldId)?.status === 'VALID') {
        this.agentFirmSearchValid[gridUniqueId] = true;
      } else {
        this.resetFirmList(gridForm, gridUniqueId);
      }
    }
  }

  onCheckboxChange(data: Question, gridId: string, gridIndex: number, gridForm: FormGroup): any {
    this.checkForExistingInsuranceCheckbox(data, gridForm);
    this.updatedAnswersOnHidden(data, gridId, gridIndex);
  }

  updatedAnswersOnHidden(data: Question, gridId: string, gridIndex: number): any {
    const value = this.mainFormGroup.getRawValue();
    const control = 'controls';
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.mainFormGroup.get(gridId)[control][gridIndex]?.get(key)?.enable();
        this.mainFormGroup.get(gridId)[control][gridIndex]?.get(key)?.markAsUntouched();
      }
      this.mainFormGroup.get(gridId)[control][gridIndex]?.patchValue(updatedval);
      this.updateCheckboxErrorsAsNull(data, gridId, gridIndex);
    }
  }

  private updateCheckboxErrorsAsNull(data: Question | ReflexiveQuestionAL, gridId: string, gridIndex: number): void {
    const control = 'controls';
    data.reflexiveQuestionAL?.forEach(reflexQ => {
      this.mainFormGroup.get(gridId)[control][gridIndex]?.get(reflexQ.fieldId)?.setErrors(null);
      this.updateCheckboxErrorsAsNull(reflexQ, gridId, gridIndex);
    });
  }


  loadFirmDetails(gridForm: FormGroup, gridUniqueId: string): void {
    this.getFirmDropdownOptions(gridForm, gridUniqueId);
    this.defaultPageLoadService.logButtonClick('search');
  }


  private getFirmDropdownOptions(gridForm: FormGroup, gridUniqueId: string): void {
    this.showLoader = true;
    this.loaderService.show();
    const value = 'OTH';
    const description = 'Other';
    const label = 'Other';
    const quesOptions: QuestionOption[] = [];
    let agentNpn: string;
    const secAgentSSNFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.secAgentSSNQuesId);
    const secAgentLNFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.secAgentLastName);
    this.agentService
      .getFirmListBySsn(
        gridForm.get(secAgentSSNFieldId)?.value,
        gridForm.get(secAgentLNFieldId)?.value
      )
      .subscribe(
        (response) => {
          this.agentService.createDropDownFromFirmList(response, quesOptions);
          agentNpn = response?.data?.agentNpn;
        },
        (_error) => {
          console.log('error occured');
          agentNpn = '';
        }
      )
      .add(() => {
        quesOptions.push({ value, description, label });
        this.firmOptions[gridUniqueId] =
          this.utilsService.formatOptions(quesOptions);
        this.agentNPN[gridUniqueId] = agentNpn;
        this.agentService.saveFirmResponse(gridForm.get(secAgentSSNFieldId)?.value, this.firmOptions[gridUniqueId]);
        this.agentService.saveAgentNpnResp(gridForm.get(secAgentSSNFieldId)?.value, this.agentNPN[gridUniqueId]);
        this.enableFirmDropDown[gridUniqueId] = true;
        this.loaderService.hide();
        this.showLoader = false;
      });
  }

  private resetFirmList(gridForm: FormGroup, gridUniqueId: string): void {
    gridForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.secAgentFirmId))?.reset();
    this.agentFirmSearchValid[gridUniqueId] = false;
    this.firmOptions[gridUniqueId] = [];
    this.agentNPN[gridUniqueId] = [];
    this.updateAgentNpn(true, gridForm, gridUniqueId);
    this.enableFirmDropDown[gridUniqueId] = false;
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  nestedQuestionCheckgridInfo(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes{47}Yes') {
        return parent === true;
      } else if (childData.optionChoice === 'No{47}No') {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  checkIfQuestionDisplayed(gridForm: FormGroup, data): any {
    const answer = gridForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  private updateFirmRelatedDetails(formControl: FormGroup, gridUniqueId: string): void {
    this.checkIfFirmSearchIsValid(formControl, gridUniqueId);
    this.firmOptions[gridUniqueId] = this.agentService.getFirmResponse(formControl.get(
      this.getFieldIdByXmlTag(AgentInfoConstant.secAgentSSNQuesId))?.value + ':' +
      formControl.get(this.getFieldIdByXmlTag(AgentInfoConstant.secAgentLastName))?.value);
    this.agentNPN[gridUniqueId] = this.agentService.getAgentNpnResp(formControl.get(
      this.getFieldIdByXmlTag(AgentInfoConstant.secAgentSSNQuesId))?.value);
    if (this.firmOptions[gridUniqueId]?.length > 0) {
      this.enableFirmDropDown[gridUniqueId] = true;
      formControl.get(this.getFieldIdByXmlTag(AgentInfoConstant.secAgentFirmId))?.setValue(
        this.updateFirmDropdownSelectedAnswer(gridUniqueId));
      formControl.get(AgentInfoConstant.secOtherFirmNameQuesId)?.setValue(this.selectedOtherFirmName[gridUniqueId]);
    }
    this.updateAgentNpn(true, formControl, gridUniqueId);
  }

  changeAgentNpn(data: Question, gridForm: FormGroup, gridUniqueId: any): void {
    if (data.xmlTag === AgentInfoConstant.secAgentFirmId) {
      this.updateAgentNpn(true, gridForm, gridUniqueId);
    }
  }

  private updateAgentNpn(updateNpnValue: boolean, formControl: FormGroup, gridUniqueId?: any): void {
    const agentNpn = this.getFieldIdByXmlTag(AgentInfoConstant.secAgentNPN);
    const firmId = this.getFieldIdByXmlTag(AgentInfoConstant.secAgentFirmId);
    if (updateNpnValue) {
      if (formControl.get(firmId)?.value && formControl.get(firmId)?.value?.label !== 'Other') {
        formControl.get(agentNpn)?.setValue(this.agentNPN[gridUniqueId]);
      } else {
        formControl.get(agentNpn)?.reset();
      }
    }
  }

  private setSelectedFirm(savedAnswer: any, gridUniqueId: string): void {
    if (this.type === 'AGENT' && savedAnswer[AgentInfoConstant.secAgentFirmQuesName]) {
      this.selectedFirm[gridUniqueId] = savedAnswer[AgentInfoConstant.secAgentFirmQuesName];
      if (savedAnswer[AgentInfoConstant.secOtherFirmNameQuesName]) {
        this.selectedOtherFirmName[gridUniqueId] = savedAnswer[AgentInfoConstant.secOtherFirmNameQuesName];
      }
    }
  }

  private updateFirmDropdownSelectedAnswer(gridUniqueId: string): any {
    let selectedOption;
    this.firmOptions[gridUniqueId].forEach((option) => {
      if (this.selectedFirm[gridUniqueId] === option.value) {
        selectedOption = option;
      }
    });
    return selectedOption;
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  markAllFieldAsTouched(): void {
    if (this.isRevisit) {
      this.mainFormGroup.markAllAsTouched();
    }
  }

  policyBeingUpdated(policyBeingFormControl: FormControl, gridForm: FormGroup, gridUniqueId: any): void {
    this.updateReplacementQuestion(policyBeingFormControl?.value?.value, gridForm, false, gridUniqueId);
  }

  private updateReplacementQuestionForSavedAns(savedAns: any, newControls: FormGroup, gridId: string, gridUniqueId: any): void {
    if (this.type === 'POLICY') {
      const policyBeingName = this.utilsService.getNameByXmlTag(this.gridQuestionsObj[gridId].gridQuestions,
        this.CONST.policyBeingQuesId);
      this.updateReplacementQuestion(savedAns[policyBeingName], newControls, true, gridUniqueId);
    }
  }

  private updateReplacementQuestion(policyBeingValue: string, gridForm: FormGroup, isRevisit: boolean, gridUniqueId: any): void {
    const replacementQuesId = this.getFieldIdByXmlTag(this.CONST.replacementQuesFieldId);
    if (policyBeingValue === 'Inforce Only{47}Inforce Only') {
      this.policyOptional = true;
      gridForm.get(replacementQuesId)?.setValue('No{47}No');
      gridForm.get(replacementQuesId)?.disable();
    } else if (policyBeingValue && policyBeingValue !== 'Inforce Only{47}Inforce Only') {
      this.policyOptional = false;
      gridForm.get(replacementQuesId)?.setValue('Yes{47}Yes');
      gridForm.get(replacementQuesId)?.disable();
    } else {
      this.policyOptional = false;
      gridForm.get(replacementQuesId)?.setValue('');
      gridForm.get(replacementQuesId)?.enable();
    }
    if (policyBeingValue === 'Replaced{47}Replaced') {
      this.isReplacedSelected[gridUniqueId] = true;
    } else {
      this.isReplacedSelected[gridUniqueId] = false;
    }
  }

  private updatePolicyRelatedData(data: Question): void {
    if (data.fieldId === this.CONST.policyNumberFieldId ||
      data.fieldId === this.CONST.contractNumberFieldId) {
      data.required = this.policyOptional ? 'false' : 'true';
    }
  }

  private checkIfFormIsValid(gridData): any {
    const formGrid = ((this.mainFormGroup.get(gridData.gridId) as FormArray)?.at(gridData.gridIndex)) as FormGroup;
    const questData = this.gridInstances[0]?.gridQuestions;
    const componentData = {
      formValid: true
    };
    questData.forEach(ques => {
      this.updateFormStatus(componentData, ques, formGrid);
    });
    this.gridStatusVal[gridData.gridUniqueId] = componentData.formValid;
  }

  private updateFormStatus(componentData: any, data: any, form: FormGroup, parentAnswer?: any): any {
    let inputVisible = true;
    if (typeof parentAnswer !== 'undefined') {
      const optionChoice = data.optionChoice || '';
      inputVisible = optionChoice.indexOf(parentAnswer) >= 0;
    }
    if (inputVisible && form.controls[data.fieldId]?.status === 'INVALID') {
      componentData.formValid = false;
    }
    if (data.hasReflexive === 'true') {
      for (const childData of data.reflexiveQuestionAL) {
        const answer = this.getAnswer(form, data);
        componentData = this.updateFormStatus(componentData, childData, form, answer);
      }
    }
    return componentData;
  }

  private getAnswer(form: FormGroup, data: Question): string {
    let answer = form.get(data.fieldId).value;
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT && answer) {
      answer = answer.value;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      answer = answer === true ? 'Yes{47}Yes' : 'No{47}No';
    }
    return answer;
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private getFieldIdByXmlTagAndGridQues(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldByXmlTagAndQuesList(xmlTag, this.gridInstances[0]?.gridQuestions);
  }

  private getNameByXmlTag(xmlTag: string): string {
    return this.utilsService.getNameByXmlTag(this.gridInstances[0].gridQuestions, xmlTag);
  }

  private isOwnerStateSameAsContractState(gridForm: FormGroup, gridUniqueId: string, data?: Question): void {
    if (this.type === 'OWNER') {
      if (data?.xmlTag === PrimaryInsuredConstant.addressState) {
        const ownerStateSelected = gridForm?.get(data?.fieldId)?.value?.value?.substr(-2)?.toLowerCase();
        this.updateStateInfo(ownerStateSelected, gridUniqueId);
      } else if (data?.xmlTag === OwnerInfoConstant.ownerType) {
        this.ownerStateInfo[gridUniqueId] = '';
      } else {
        this.updateOwnerStateInfoForSavedAnswer(gridForm, gridUniqueId);
      }
    }
  }

  private updateOwnerStateInfoForSavedAnswer(gridForm: FormGroup, gridUniqueId: string): void {
    Object.keys(gridForm.controls).forEach(key => {
      if (key.indexOf(PrimaryInsuredConstant.addressState) > -1 && gridForm.get(key).value?.value) {
        const ownerStateSelected = gridForm.get(key).value?.value?.substr(-2)?.toLowerCase();
        this.updateStateInfo(ownerStateSelected, gridUniqueId);
      }
    });
  }

  private updateStateInfo(ownerStateSelected: string, gridUniqueId: string): void {
    const contractState = this.userService.getInsuredState()?.toLowerCase();
    if (ownerStateSelected && contractState !== ownerStateSelected && gridUniqueId) {
      this.ownerStateInfo[gridUniqueId] = contractState === 'wa' || contractState === 'ut' ? Message.DIFF_OWNER_STATE_INFO_WA :
        Message.DIFF_OWNER_STATE_INFO;
    } else {
      this.ownerStateInfo[gridUniqueId] = '';
    }
  }

  updateAddressSameAsOwner(gridForm: FormGroup, isAddressSame: FormControl, data: Question, gridUniqueId: string): void {
    const address = this.userService.getInsuredAddress();
    if (address && isAddressSame?.value?.value?.indexOf('Insured') > -1) {
      Object.keys(gridForm.controls).forEach((contrl) => {
        if (contrl.indexOf('/Address1') > -1) {
          gridForm.get(contrl).setValue(address.address1);
        } else if (contrl.indexOf('/Address2') > -1) {
          gridForm.get(contrl).setValue(address.address2);
        } else if (contrl.indexOf('/Address3') > -1) {
          gridForm.get(contrl).setValue(address.address3);
        } else if (contrl.indexOf('/City') > -1) {
          gridForm.get(contrl).setValue(address.city);
        } else if (contrl.indexOf('/State') > -1) {
          gridForm.get(contrl).setValue(this.getStateValueByCode(address.state));
        } else if (contrl.indexOf('/AddressType') > -1) {
          gridForm.get(contrl).setValue({ label: 'Home', value: '1{47}1' });
        } else if (contrl.indexOf('/PostalCode') > -1) {
          gridForm.get(contrl).setValue(address.zipCode);
        }
      });
      this.isOwnerStateSameAsContractState(gridForm, gridUniqueId, data);
    }
  }

  private updateStateList(gridDropDownOption): void {
    if (this.stateList.length === 0) {
      Object.keys(gridDropDownOption).forEach((contrl) => {
        if (contrl.indexOf('State') > -1 && this.stateList.length === 0) {
          this.stateList = gridDropDownOption[contrl];
        }
      });
    }
  }

  private getStateValueByCode(stateCode: string): any {
    if (stateCode) {
      return this.stateList.filter(state => {
        return state.value.indexOf(stateCode) > -1;
      })?.[0];
    }
  }

  private disableProposedPolicyRadioButton(gridForm: FormGroup): void {
    if (this.type === 'POLICY') {
      const proposedPolicyInfo = this.getFieldIdByXmlTag(this.CONST.proposedPolicyType);
      gridForm.get(proposedPolicyInfo)?.setValue('Life{47}Life');
      gridForm.get(proposedPolicyInfo)?.disable();
    }
  }

  private checkForExistingInsuranceCheckbox(data: Question, gridForm: FormGroup): void {
    if (this.type === 'POLICY') {
      const contractState = this.userService.getInsuredState()?.toLowerCase();
      if (data.xmlTag === this.CONST.outstandingLoanInd || data.xmlTag === this.CONST.mnOutstandingLoanInd) {
        const certificateIssueIndField = contractState !== 'mn' ? this.getFieldIdByXmlTag(this.CONST.certificateIssueInd)
          : this.getFieldIdByXmlTagAndGridQues(this.CONST.mnCertificateIssueInd);
        this.updateDisableStatusForField(gridForm, certificateIssueIndField, gridForm.get(data.fieldId)?.value);
      } else if (data.xmlTag === this.CONST.certificateIssueInd || data.xmlTag === this.CONST.mnCertificateIssueInd) {
        const outstandingLoanIndField = contractState !== 'mn' ? this.getFieldIdByXmlTag(this.CONST.outstandingLoanInd)
          : this.getFieldIdByXmlTagAndGridQues(this.CONST.mnOutstandingLoanInd);
        this.updateDisableStatusForField(gridForm, outstandingLoanIndField, gridForm.get(data.fieldId)?.value);
      }
    }
  }

  private updateDisableStatusForField(gridForm: FormGroup, updateFieldId: string, isDisable: boolean): void {
    if (isDisable) {
      gridForm.get(updateFieldId)?.reset();
      gridForm.get(updateFieldId)?.disable();
    } else {
      gridForm.get(updateFieldId)?.enable();
    }
  }

  private updateExistingInsuranceCheckbox(savedAns: any, gridForm: FormGroup): void {
    if (this.type === 'POLICY') {
      const contractState = this.userService.getInsuredState()?.toLowerCase();
      const outstandingLoanIndName = contractState === 'mn' ? this.getNameByXmlTag(this.CONST.mnOutstandingLoanInd)
        : this.getNameByXmlTag(this.CONST.outstandingLoanInd);
      const certificateIssueIndName = contractState === 'mn' ? this.getNameByXmlTag(this.CONST.mnCertificateIssueInd)
        : this.getNameByXmlTag(this.CONST.certificateIssueInd);
      if (savedAns[outstandingLoanIndName]?.length > 0) {
        const certificateIssueIndFieldId = contractState === 'mn' ? this.getFieldIdByXmlTagAndGridQues(this.CONST.mnCertificateIssueInd)
          : this.getFieldIdByXmlTag(this.CONST.certificateIssueInd);
        this.updateDisableStatusForField(gridForm, certificateIssueIndFieldId, true);
      } else if (savedAns[certificateIssueIndName]?.length > 0) {
        const outstandingLoanIndFieldId = contractState === 'mn' ? this.getFieldIdByXmlTagAndGridQues(this.CONST.mnOutstandingLoanInd)
          : this.getFieldIdByXmlTag(this.CONST.outstandingLoanInd);
        this.updateDisableStatusForField(gridForm, outstandingLoanIndFieldId, true);
      }
    }
  }

  private updatePrimaryInsuredNameInExistingInsurance(
    questions: Question[] | ReflexiveQuestionAL[],
    optionPIUpdated?: boolean, optionAIUpdated?: boolean): void {
    if (this.type === 'POLICY' && !optionPIUpdated && !optionAIUpdated) {
      questions.forEach((ques) => {
        if (ques.xmlTag === this.CONST.replacingPolicyXmlTag || ques.xmlTag === this.CONST.mnReplacingPolicyXmlTag) {
          [optionPIUpdated, optionAIUpdated] = this.updateOptionDescription(ques, optionPIUpdated, optionAIUpdated);
        } else if (ques.hasReflexive === 'true' && (!optionPIUpdated && !optionAIUpdated)) {
          this.updatePrimaryInsuredNameInExistingInsurance(ques.reflexiveQuestionAL, optionPIUpdated, optionAIUpdated);
        }
      });
    }
  }

  private updateOptionDescription(ques: Question, optionPIUpdated: boolean, optionAIUpdated: boolean): any {
    ques.questionOption?.forEach(option => {
      if (option.value === 'PI{47}PI') {
        option.description = this.userService.getInsuredFirstName() + ' ' + this.userService.getInsuredLastName();
        optionPIUpdated = true;
      } else if (option.value === 'AI{47}AI') {
        option.description = !this.userService.getProposedBName() || this.userService.getProposedBName()?.trim() === ''
          ? 'Additional Insured' : this.userService.getProposedBName();
        optionAIUpdated = true;
      }
    });
    return [optionPIUpdated, optionAIUpdated];
  }

  private initDateConfig(): void {
    [this.dateOfBirthConfig.placeholder, this.dateOfBirthConfig.id] = ['Date of birth', 'dobId'];
    [this.dateOfTrustConfig.placeholder, this.dateOfTrustConfig.id] = ['What is the date of the trust?', 'dateOfTrust'];
    [this.dateOfAmendConfig.placeholder, this.dateOfAmendConfig.id] = ['Latest Amendment Date (optional)', 'dateOfAmend'];
    [this.defaultDateConfig.placeholder, this.defaultDateConfig.id] = ['Date', 'Date'];
    [this.dateOfBirthOwnerConfig.placeholder, this.dateOfBirthOwnerConfig.id] = ['What is the owner\'s date of birth?', 'ownerDob'];
    [this.dateOfExistingConfig.placeholder, this.dateOfExistingConfig.id, this.dateOfExistingConfig.maxDate] = ['Proposed Effective Date', 'dateOfProposed', new Date('06/06/2079')];
    [this.basicDateConfig.placeholder, this.basicDateConfig.id] = ['Date', 'basicDate'];
    [this.futureDateConfig.placeholder, this.futureDateConfig.id, this.futureDateConfig.maxDate] = ['Date', 'futureDate', new Date('06/06/2079')];
    [this.futureDateConfig2.placeholder, this.futureDateConfig2.id, this.futureDateConfig2.maxDate] = ['Date', 'futureDate', new Date('06/06/2079')];
    [this.dateOfIssueConfig.placeholder, this.dateOfIssueConfig.id] = ['Date of Issue', 'dateOfIssue'];
    [this.issueDateConfig.placeholder, this.issueDateConfig.id] = ['Issue Date', 'issueDate'];
    [this.issueDateConfigOpt.placeholder, this.issueDateConfigOpt.id] = ['Issue Date (optional)', 'issueDateOpt'];
  }

  private checkAndUpdateValue(gridForm: FormGroup, fieldId: string, value: string): void {
    setTimeout(() => {
      if (gridForm.get(fieldId) && !gridForm.get(fieldId).value) {
        gridForm.get(fieldId).setValue(value);
      }
    }, 0);
  }
}
