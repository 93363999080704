import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Question, Recipient } from 'src/app/shared/models/casePage.model';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CaseUtilsService } from 'src/app/shared/services/case-utils.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { confirmEmailValidator } from 'src/app/shared/validators/business-validator';
import { ApplicationConfig } from 'src/config/app.config';
import { ElectronicSignatures, ErrorMessage, eSignatureInstructionConst, ESignatureInstructions, Message } from 'src/config/constants';

@Component({
  selector: 'app-esignature-instruction',
  templateUrl: './esignature-instruction.component.html',
  styleUrls: ['./esignature-instruction.component.scss']
})
export class EsignatureInstructionComponent implements OnInit, OnChanges {
  @Input() disableForm: boolean;
  @Input() signerList: any[];
  questionsData;
  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Input() envelopeCreated: boolean;
  @Input() errorOccuredWhileCreateEnvelope: boolean;
  @Input() errorOccuredToGetTransactionId: boolean;

  eSignatureInstructionData: Question[] = [];
  eSignatureInstructionForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;

  emailFieldId;
  confirmEmailFieldId;
  ssnFieldId;
  remoteSignerList: Recipient[];

  ELECTRONIC_SIGN_CONST = ElectronicSignatures;
  Message = Message;
  SignerMessage = ESignatureInstructions;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload,
    private commonService: CommonService,
    private agentService: AgentAndFirmService,
    private caseUtils: CaseUtilsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(
        this.eSignatureInstructionForm
      );
      this.filterSigners();
      this.defaultPageLoadService.disableFormIfLocked(this.eSignatureInstructionForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableForm?.previousValue === true && changes.disableForm?.currentValue === false) {
      this.enableForms();
    }
  }

  private filterSigners(): void {
    this.remoteSignerList = this.caseUtils.getRemoteSigners(this.signerList);
  }

  private buildFormData(): any {
    this.eSignatureInstructionData = this.questionsData.pages[0].questions;
    this.updateAgentDetails();
    const form = this.formgeneratorService.createFormControls(
      this.eSignatureInstructionData
    );
    this.eSignatureInstructionForm = this.fb.group(form);
    this.disableFields();
    this.addValidators();
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.eSignatureInstructionForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.eSignatureInstructionForm,
      data: null,
    };
    for (const data of this.eSignatureInstructionData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'eSignatureInstructionForm',
      isFormChange: this.eSignatureInstructionForm.dirty || !this.isTabVisited,
    };
    this.formStatus.emit(obj);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private updateAgentDetails(): void {
    this.eSignatureInstructionData.forEach(ques => {
      if (ques.questionText.indexOf('(agent name)') > -1) {
        ques.questionText = ques.questionText.replace('(agent name)',
          this.agentService?.getAgentFirstName() + ' ' + this.agentService.getAgentLastName());
      } else if (ques.questionText.toLowerCase() === 'email address') {
        this.emailFieldId = ques.fieldId;
        ques.question_answer = this.agentService.getAgentPrimaryEmail();
      } else if (ques.questionText.toLowerCase() === 'confirm email address') {
        this.confirmEmailFieldId = ques.fieldId;
      } else if (ques.questionText.toLowerCase() === 'agent ssn') {
        ques.question_answer = this.agentService.getAgentLast4DigitSSN();
      }
    });
  }

  private addValidators(): void {
    this.eSignatureInstructionForm.get(this.confirmEmailFieldId).setValidators(
      [Validators.required, confirmEmailValidator(this.eSignatureInstructionForm.get(this.emailFieldId)?.value)]);
  }

  private enableForms(): void {
    setTimeout(() => {
      this.eSignatureInstructionForm.enable();
      this.disableFields();
    }, 300);
  }

  private disableFields(): void {
    if (this.eSignatureInstructionForm) {
      Object.keys(this.eSignatureInstructionForm?.controls)?.forEach(key => {
        if (key.indexOf(eSignatureInstructionConst.confirmEmailTag) === -1) {
          this.eSignatureInstructionForm.get(key)?.disable();
        }
      });
    }
  }
}
