<section class="primary-insuredInfo-page page-content-container">

    <div class="questions--container">
      <h1 class="lfg-color">
          Primary Insured Information
      </h1>

      <div class="invalidpage-alert">
        <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
      </div>
      <section>
        <form class="form-group" [formGroup]="primaryInsuredInfoForm" id="primaryInsuredInfoForm" method="post" *ngIf="primaryInsuredInfoData && primaryInsuredInfoData.length > 0">
          <ng-container class="form-control-parent" *ngFor="let data of primaryInsuredInfoData; let ind = index;" >

            <!-- Questions headers -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information'">
              <h2>{{ data.questionText }}
                <lfg-tooltip *ngIf="data.tooltip && data.questionText?.toLowerCase() === 'contact information'" class="tooltip-container">{{phoneInfo}}</lfg-tooltip>
                <lfg-tooltip *ngIf="data.tooltip && data.questionText?.toLowerCase() !== 'contact information'" class="tooltip-container">{{data.tooltip}}</lfg-tooltip>
              </h2>
            </div>

            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

            <!-- USA address -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
              <h2>{{ data.questionText }}</h2>
            </div>

            <!-- Form element for text  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes != 'ITES_FORMAT_AS_ZIPCODE' ">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                <lfg-error *ngIf="primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>
            
            <!-- Form element for zipcode  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_ZIPCODE'">
              <lfg-input-wrap>
                <input lfgInput mask='00000-0000' placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="number" maxlength="10" [required]="data.required == 'true'">
                <lfg-error *ngIf="primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for SSN -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='primaryInsuredInfoForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur [required]="data.required == 'true'">

                <lfg-error *ngIf="(primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid) && ( primaryInsuredInfoForm.get(data.fieldId).errors == null || (primaryInsuredInfoForm.get(data.fieldId).errors != null && !primaryInsuredInfoForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>

                <lfg-error *ngIf="(primaryInsuredInfoForm.get(data.fieldId).invalid && primaryInsuredInfoForm.get(data.fieldId).touched) &&(primaryInsuredInfoForm.get(data.fieldId).errors != null && primaryInsuredInfoForm.get(data.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO"
          [ngClass]="{'radio--err': (primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid),
          'question-radio--small': data.xmlTag == PrimaryInsuredInsuredXMLTags.genderId, 'question-radio--medium': data.xmlTag == PrimaryInsuredInsuredXMLTags.primaryPhoneTypeXmlTag
          || data.xmlTag == PrimaryInsuredInsuredXMLTags.secondaryPhoneTypeXmlTag, 'radio-header': data.xmlTag == PrimaryInsuredInsuredXMLTags.drivingLicenseCheckXmlTag ||
          data.xmlTag == PrimaryInsuredInsuredXMLTags.USCitizenIndXmlTag}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ addCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag == PrimaryInsuredInsuredXMLTags.isInsuredOwner && showStateInfo">
            <lfg-alert-message type="info">{{ stateInfo }}</lfg-alert-message>
          </div>

            <!-- Form Element for date  -->
            <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
              <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="primaryInsuredInfoForm.get(data.fieldId)" [config]="dateConfig" [class.calendar--err]="(primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
                <lfg-error *ngIf="primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid " message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-calendar>
            </div>

            <!-- Form element for Drop Down -->
            <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'"
              [ngClass]="{'dropdown--err': (primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid)}">
              </lfg-dropdown>
              <lfg-error *ngIf="primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, primaryInsuredInfoForm.get(data.fieldId)) }}"></lfg-error>

            </div>

            <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE && data.xmlTag == PrimaryInsuredInsuredXMLTags.phoneNoXmlTag">
              <lfg-alert-message type="info">{{ phoneInfo }}</lfg-alert-message>
            </div>

            <!-- Form element for phone number -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
                <lfg-error *ngIf="(primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for email  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">

                <lfg-error *ngIf="primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for alphanumeric  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

                <lfg-error *ngIf="primaryInsuredInfoForm.get(data.fieldId).touched && primaryInsuredInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Question Info -->
            <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Nestion question Level 1 -->
            <div *ngIf="data.hasReflexive == 'true' && primaryInsuredInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
              <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
                <ng-container *ngIf="nestedQuestionCheckPrimaryInsuredInfo(data, primaryChildL1, parentAnswer)">
                  <!-- Questions headers -->
                  <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText !== 'Information'">
                    <h2>{{ primaryChildL1.questionText }}</h2>
                  </div>

                  <div class="questions-info" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText === 'Information'">
                    <lfg-alert-message type="info">{{ primaryChildL1.questionText }}</lfg-alert-message>
                  </div>

                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                  <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL1, 'display')">
                    {{ addCorrectPlaceHolder(primaryChildL1, 'question')  }}
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL1.xmlTag == PrimaryInsuredInsuredXMLTags.drivingLicenseNumberXmlTag">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="alphaNumericWithAsterisk" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">
                      <lfg-error *ngIf="primaryInsuredInfoForm.get(primaryChildL1.fieldId).touched && primaryInsuredInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for radio question -->
                  <div class="lfg-customRadio-field radio-btn-custom" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (primaryInsuredInfoForm.get(primaryChildL1.fieldId).touched && primaryInsuredInfoForm.get(primaryChildL1.fieldId).invalid)}">
                    <span class="lfg-radio-section">
                      <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL1, 'question') }}</p>
                      <div class="custom-radio-button">
                        <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)"/>
                        <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                        <input [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                        <label for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="primaryInsuredInfoForm.get(primaryChildL1.fieldId).touched && primaryInsuredInfoForm.get(primaryChildL1.fieldId).invalid " message="Please select a valid option."></lfg-error>
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL1.xmlTag != PrimaryInsuredInsuredXMLTags.drivingLicenseNumberXmlTag">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">
                      <lfg-error *ngIf="primaryInsuredInfoForm.get(primaryChildL1.fieldId).touched && primaryInsuredInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for alphanumeric  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                      <lfg-error *ngIf="primaryInsuredInfoForm.get(primaryChildL1.fieldId).touched && primaryInsuredInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for Drop Down -->
                  <div class="form--control form-dropDown" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL1.questionText.length > 32}">
                    <span class="screen-reader-only" *ngIf="primaryChildL1.xmlTag === PrimaryInsuredInsuredXMLTags.stateOfBirthQuesId">{{ primaryChildL1.questionText }}</span>
                    <lfg-dropdown formControlName="{{ primaryChildL1.fieldId }}" id="{{displayIdCreation(primaryChildL1)}}_{{primaryChildL1ix}}" [options]="alldropDownOptions[primaryChildL1.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL1, primaryChildL1ix)" [required]="primaryChildL1.required == 'true'"
                    [ngClass]="{'dropdown--err': (primaryInsuredInfoForm.get(primaryChildL1.fieldId).touched && primaryInsuredInfoForm.get(primaryChildL1.fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error *ngIf="primaryInsuredInfoForm.get(primaryChildL1.fieldId).touched && primaryInsuredInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </div>

                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </form>
      </section>
    </div>
  </section>
