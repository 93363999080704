import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { DisableStyle } from '@ng/lfg-calendar';
import { PrimaryInsuredConstant, ErrorMessage, Message } from 'src/config/constants';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { DatePipe } from '@angular/common';
import { Question } from 'src/app/shared/models/casePage.model';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { ProductCodes, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-primary-insured',
  templateUrl: './primary-insured.component.html',
  styleUrls: ['./primary-insured.component.scss'],
})
export class PrimaryInsuredComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() ownerInfoNeeded: EventEmitter<string> = new EventEmitter();

  primaryInsuredInfoData: Question[] = [];
  primaryInsuredInfoForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  placeholders = {};
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date of Birth',
    disableStyle: DisableStyle.locked,
  };
  calculatedAge: number;
  alldropDownOptions = {};
  setTimeFocus = null;
  isTabVisited: boolean;

  showStateInfo = false;
  stateInfo: string;
  tabStatusOnInit: TabStatus;

  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  phoneInfo = Message.PHONE_INFO;
  readonly PrimaryInsuredInsuredXMLTags = PrimaryInsuredConstant;
  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private userService: UserDetailsService,
    private datePipe: DatePipe,
    private productService: ProductDetailsService,
    private defaultPageLoadService: DefaultPageload
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.updateFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.primaryInsuredInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.primaryInsuredInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.checkForOptionalTab();
    }
  }

  private buildFormData(): any {
    this.primaryInsuredInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.primaryInsuredInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.primaryInsuredInfoData
    );
    this.primaryInsuredInfoForm = this.fb.group(form);
    this.checkForStateInfo();
    this.valueChanges();
    setTimeout(() => {
      this.makeFieldsNonEditable();
    }, 100);
  }

  private makeFieldsNonEditable(): any {
    const fieldsToBeNonEditable =
      this.appConfig.primaryInsuredInfoDisableFieldKey;
    for (const key of fieldsToBeNonEditable) {
      const formKey = this.utilsService.getFieldId(
        this.primaryInsuredInfoData,
        key
      );
      if (formKey) {
        this.primaryInsuredInfoForm.get(formKey).disable();
      }
    }
  }

  private updateDataBasedOnConditions(data: Question): void {
    if (data.xmlTag === PrimaryInsuredConstant.countryOfBirthQuesId || data.xmlTag === PrimaryInsuredConstant.stateOfBirthQuesId) {
      data.required = this.productService.getProductName()?.toLowerCase().indexOf(ProductCodes.TERMACCEL) !== -1 ? 'true' : 'false';
    }
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.primaryInsuredInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.primaryInsuredInfoForm,
      data: null,
    };
    for (const data of this.primaryInsuredInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'primaryInsuredInfoForm',
      isFormChange: this.primaryInsuredInfoForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.primaryInsuredInfoForm),
    };
    this.formStatus.emit(obj);
  }

  private updateOwnerInfo(data: Question): void {
    if (data.xmlTag === PrimaryInsuredConstant.isInsuredOwner) {
      this.emitOwnerInfo(data.fieldId);
    }
  }

  private checkForStateInfo(): void {
    const ownerFieldQuesId = this.getFieldIdByXmlTag(PrimaryInsuredConstant.isInsuredOwner);
    const stateFieldQuesId = this.getFieldIdByXmlTag(PrimaryInsuredConstant.addressState);
    const stateSelected = this.primaryInsuredInfoForm.get(stateFieldQuesId)?.value?.value;
    if (this.primaryInsuredInfoForm.get(ownerFieldQuesId)?.value === 'yes{47}yes' &&
      stateSelected?.indexOf(this.userService.getInsuredState()) === -1) {
      if (this.userService.getInsuredState()?.toLowerCase() === 'ut' || this.userService.getInsuredState()?.toLowerCase() === 'wa'
        || stateSelected?.toLowerCase().indexOf('ut') > -1 || stateSelected?.toLowerCase().indexOf('wa') > -1) {
        this.stateInfo = Message.DIFF_OWNER_STATE_INFO_WA;
      } else {
        this.stateInfo = Message.DIFF_OWNER_STATE_INFO;
      }
      this.showStateInfo = true;
    } else {
      this.showStateInfo = false;
    }
  }

  onRadioChange(data: Question): any {
    this.updatedAnswersOnHidden(data);
    this.checkForStateInfo();
    this.updateOwnerInfo(data);
  }

  handleSelectionChange(data, _ix?): any {
    this.updatedAnswersOnHidden(data);
    this.checkForStateInfo();
  }

  addCorrectPlaceHolder(data, type): any {
    this.updateDataBasedOnConditions(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.primaryInsuredInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.primaryInsuredInfoForm.get(key)?.markAsUntouched();
      }
      this.primaryInsuredInfoForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.primaryInsuredInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckPrimaryInsuredInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  updateFormData(): void {
    this.primaryInsuredInfoForm.patchValue({
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.firstNameId)]: this.userService.getInsuredFirstName(),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.middleNameId)]: this.userService.getInsuredMiddleName(),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.lastNameId)]: this.userService.getInsuredLastName(),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.suffixId)]: this.userService.getInsuredSuffix(),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.dobQuestionId)]: this.datePipe.transform(this.userService.getInsuredDOB(), 'MM/dd/yyyy'),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.ageQuestId)]: this.userService.getInsuredAge(),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.genderId)]: this.userService.getInsuredGender(),
    });
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private checkForOptionalTab(): void {
    const ownerIndQues = this.getFieldIdByXmlTag(PrimaryInsuredConstant.isInsuredOwner);
    if (ownerIndQues) {
      this.emitOwnerInfo(ownerIndQues);
    }
  }

  private emitOwnerInfo(ownerIndQuesFieldId: string): void {
    const isOwnerRequired = this.primaryInsuredInfoForm.get(ownerIndQuesFieldId)?.value === 'no{47}no' ? 'yes' : 'no';
    this.ownerInfoNeeded.emit(isOwnerRequired);
  }
}
