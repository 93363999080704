import { ValidatorFn, AbstractControl } from '@angular/forms';
import moment from 'moment';
import { formatCurrency } from '@angular/common';

/**
 * @returns ValidatorFn
 */

// Special Character Validator
export function specialCharValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const text = control ? control.value : null;
    if (!text) {
      return null;
    }
    const textRegex: RegExp = new RegExp(/^[a-zA-Z'/-]*$/);
    return textRegex.test(text) ? null : { specialCharError: true };
  };
}

export function dobValidator(format?): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const dateFormat = format ? format : 'MMDDYYYY';
    let dob: string = control ? control.value : null;
    if (!dob) {
      return null;
    }
    const dateLength = format === 'MMDDYY' ? 6 : 8;
    dob = dob.replace(/\//g, '');
    if (dob.length < dateLength) {
      // || moment(dob, dateFormat) >= moment()
      return { dobError: true };
    }

    if (
      format !== 'MMDDYY' &&
      moment(dob, dateFormat) < moment('01011900', dateFormat)
    ) {
      return { dobError: true };
    }

    if (!moment(dob, dateFormat).isValid()) {
      return { dobError: true };
    }
    const regexpPattern =
      format === 'MMDDYY'
        ? '^(0?[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0,1])([0-9]{2})$'
        : '^(0?[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0,1])([0-9]{4})$';
    const dobPatt = new RegExp(regexpPattern);
    return dobPatt.test(dob) ? null : { dobError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let phone = control ? control.value : null;
    if (!phone) {
      return null;
    }
    phone = phone.split(' ').join('');
    phone = phone.split('(').join('');
    phone = phone.split(')').join('');
    phone = phone.split('-').join('');
    const phoneNumberPatt1 = new RegExp(/^(1[1-9]\d{9})$/);
    const phoneNumberPatt2 = new RegExp(/^([1-9]\d{9})$/);
    if (phone.length === 11 && phoneNumberPatt1.test(phone)) {
      return null;
    } else if (phone.length === 10 && phoneNumberPatt2.test(phone)) {
      return null;
    } else {
      return { phoneError: true };
    }
  };
}

/**
 * @returns ValidatorFn
 */
export function ssnValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const ssn: string = control ? control.value : null;
    if (!ssn) {
      return null;
    }
    if (ssn != null && ssn.length < 9) {
      return { ssnError: true };
    }
    if (ssn === '000000000' || ssn === '123456789') {
      return { ssnError: true };
    }
    if (ssn.startsWith('X') && ssn.indexOf('-') > -1) {
      const ssnPattern = new RegExp(/^(X{5}\d{4}-.+)$/);
      if (!ssnPattern.test(ssn)) {
        return { ssnError: true };
      }
    } else {
      const ssn1 = ssn.substring(0, 3);
      const ssn2 = ssn.substring(3, 5);
      const ssn3 = ssn.substring(5, 9);
      const ssnPattern = new RegExp(/^\d{9}$/);
      if (checkFor9DigitSSN(ssn1, ssn2, ssn3)) {
        return { ssnError: true };
      }
      if (!ssnPattern.test(ssn1 + ssn2 + ssn3)) {
        return { ssnError: true };
      }
    }
  };
}

export function ssnLastFourValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const ssn: string = control ? control.value : null;
    if (!ssn) {
      return null;
    }
    if (ssn != null && ssn.length < 4) {
      return { ssnError: true };
    }
    if (ssn === '0000') {
      return { ssnError: true };
    }
  };
}

export function routingAccountNoValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value: string = control ? control.value : null;
    if (!value) {
      return null;
    }
    if (value === '000000000' || value === '123456789') {
      return { routingAccountError: true };
    }
    const reg = new RegExp(/^(\d)(?!\1+$)\d*$/);
    if (!reg.test(value)) {
      return { routingAccountError: true };
    }
  };
}

function checkFor9DigitSSN(ssn1: string, ssn2: string, ssn3: string): boolean {
  let hasError = false;
  if (
    (ssn1 && (ssn1 === '000' || ssn1 === '666')) ||
    ssn2 === '00' ||
    ssn3 === '0000'
  ) {
    hasError = true;
  }
  return hasError;
}

/**
 * @returns ValidatorFn
 */
export function zipValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const zip = control ? control.value : null;
    if (!zip) {
      return null;
    }
    if (zip.length !== 5 && zip.length !== 9) {
      return { zipError: true };
    }
    const zipPatt = new RegExp(/^([0-9\-]{5,10})$/);
    return zipPatt.test(zip) ? null : { zipError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function emailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const email = control ? control.value : null;
    if (!email) {
      return null;
    }
    if (email.indexOf('@') > 0) {
      const text = email.split('@');
      const char = '_-.';
      if (char.indexOf(text[0].substring(0, 1)) > -1) {
        return { emailError: true };
      }
    }
    // used global email validation pattern
    const emailPatt = new RegExp(
      /^[A-Za-z0-9-_]+(\.[A-Za-z0-9_-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,4})$/
    );
    return emailPatt.test(email) ? null : { emailError: true };
  };
}
/**
 * @returns ValidatorFn
 */
export function percentValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const percent = control ? control.value : null;
    if (!percent) {
      return null;
    }
    return percent > 0 && percent <= 100 ? null : { percentError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function textValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let text = control ? control.value : null;
    if (!text) {
      return null;
    }
    text = text.replace(/\n/g, '');
    const textPatt = new RegExp(/^(?! )(?!.* {2})([A-Za-z0-9-\/.\'_, ‘’]*)$/);
    return textPatt.test(text.trim()) ? null : { textError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function textAreaValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let textarea = control ? control.value : null;
    if (!textarea) {
      return null;
    }
    textarea = textarea.replace(/\n/g, '');
    const textPatt = new RegExp(/^[a-zA-Z0-9,-.\' @$?%()_‘’]*$/);
    return textPatt.test(textarea.trim()) ? null : { textareaError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function numericValidator(onlyNumbers?): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const text = control ? control.value : null;
    if (!text) {
      return null;
    }
    const pattern = onlyNumbers ? '^[0-9]*$' : /(^\d*\.?\d{1,2}$)/g;
    const textPatt = new RegExp(pattern);
    return textPatt.test(text) ? null : { textError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function currencyValidator(type?): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let text = control ? control.value : null;
    if (!text) {
      return null;
    }
    text = text.split('');
    while (text.indexOf('0') === 0) {
      text.shift();
    }
    text = text.join('');
    if (!type && (Number(text) < 0 || Number(text) >= 100000000)) {
      return { maxAmountError: true };
    }
    if (!type && (Number(text) < 1)) {
      return { minAmountError: true };
    }
    // regex for currency pattern $256,726.78
    const currPatt1 = new RegExp(/^(\$)?([1-9]\d{0,2})(\,\d{3})*(\.\d{2})?$/gm);
    // regex for currency pattern $434434.87
    const currPatt2 = new RegExp(/^(\$)?([1-9]\d{0,2})(\d{3})*(\.\d{2})?$/gm);
    // regex for currency pattern 0.56
    const currPatt3 = new RegExp(/^(0)?(\.\d{2})?$/gm);
    // regex for currency pattern $0.56
    const currPatt4 = new RegExp(/^(\$0)?(\.\d{2})?$/gm);
    // regex for currency pattern $.56
    const currPatt5 = new RegExp(/^(\$\.)(\d{2})?$/gm);
    text = formatCurrency(text, 'en-US', '$').substring(1);
    return currPatt1.test(text) ||
      currPatt2.test(text) ||
      currPatt3.test(text) ||
      currPatt4.test(text) ||
      currPatt5.test(text)
      ? null
      : { textError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function alphaNumericValidator(withSpace?: boolean): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const textVal = control ? control.value : null;
    if (!textVal) {
      return null;
    }
    let textPatt = new RegExp(/^[A-Za-z0-9]+$/);
    if (withSpace) {
      textPatt = new RegExp(/^[A-Za-z0-9\s]+$/);
    }
    return textPatt.test(textVal) ? null : { textError: true };
  };
}

/**
 * @returns ValidatorFn
 */
export function licenseNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const fieldVal = control ? control.value : null;
    if (!fieldVal) {
      return null;
    }
    const textPatt = new RegExp(/^[A-Za-z0-9*-]+$/);
    if (textPatt.test(fieldVal)) {
      return null;
    } else {
      return { textError: true };
    }
  };
}

/**
 * @returns ValidatorFn
 */
export function pastYearValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const dateFormat = 'YYYY';
    let year: string = control ? control.value : null;
    if (!year) {
      return null;
    }
    year = year.replace('/', '');
    if (year.length < 4 || moment(year, dateFormat) >= moment()) {
      return { yearError: true };
    }
    if (moment(year, dateFormat) < moment('1900', dateFormat)) {
      return { yearError: true };
    }
    return null;
  };
}

/**
 * Validate Weight
 * @returns ValidatorFn
 */
export function invalidWeight(): ValidatorFn {
  return (_control: AbstractControl): { [key: string]: any } => {
    return { invalidWeight: true };
  };
}

/**
 * Validate File
 * @returns ValidatorFn
 */
export function fileValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const filePath: string = control ? control.value : null;
    if (!filePath) {
      return null;
    } else {
      const file = filePath.replace(/^.*[\\\/]/, '');
      const fileName = file.substring(0, file.lastIndexOf('.'));
      if (!file.toLowerCase().endsWith('.pdf')) {
        return { fileError: true };
      }
      if (fileName.length > 30) {
        return { fileError: true };
      }
      if (containsSpecialCharacters(fileName)) {
        return { fileError: true };
      }
    }
  };

  function containsSpecialCharacters(filename): boolean {
    const regex = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/g;
    return regex.test(filename);
  }
}

