import { TAB } from './sideNav.config';

export const CaseInfoConstant = {
  stateQuesid: '/XML/ApplicationDetail/ContractState',
  productTypeQuestId:
    '/XML/ApplicationDetail/ProductType',
  ageQuestId:
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
  dobQuestionId: '/XML/Client/CRMDetails/DOB',
  productCodeQuesId: '/XML/Product/ProductID',
  firstNameId: '/XML/Client/CRMDetails/FirstName',
  lastNameId: '/XML/Client/CRMDetails/LastName',
  middleNameId: '/XML/Client/CRMDetails/MiddleName',
  suffixId: '/XML/Client/CRMDetails/Suffix',
  genderId: '/XML/Client/CRMDetails/Gender',
  stateId: '/XML/ApplicationDetail/ContractState',
  addressId: '/XML/Client/CRMDetails/Address/Address1',
};

export const PrimaryInsuredConstant = {
  isInsuredOwner: '/XML/CoverageInformation/InsuredOwnerInd',
  ageQuestId:
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
  dobQuestionId: '/XML/Client/CRMDetails/DOB',
  firstNameId: '/XML/Client/CRMDetails/FirstName',
  lastNameId: '/XML/Client/CRMDetails/LastName',
  middleNameId: '/XML/Client/CRMDetails/MiddleName',
  suffixId: '/XML/Client/CRMDetails/Suffix',
  genderId: '/XML/Client/CRMDetails/Gender',
  addressState: '/XML/Client/CRMDetails/Address/State',
  countryOfBirthQuesId: '/XML/Client/CRMDetails/PlaceOfBirth',
  stateOfBirthQuesId: '/XML/Client/CRMDetails/StateOfBirth',
  addrTypeXmlTag: '/XML/Client/CRMDetails/Address/AddressType',
  addr1XmlTag: '/XML/Client/CRMDetails/Address/Address1',
  addr2XmlTag: '/XML/Client/CRMDetails/Address/Address2',
  addr3XmlTag: '/XML/Client/CRMDetails/Address/Address3',
  cityXmlTag: '/XML/Client/CRMDetails/Address/City',
  stateXmlTag: '/XML/Client/CRMDetails/Address/State',
  zipXmlTag: '/XML/Client/CRMDetails/Address/PostalCode',
  countryXmlTag: '/XML/Client/CRMDetails/Address/Country',
  phoneNoXmlTag: '/XML/Client/CRMDetails/HomePhone',
  primaryPhoneTypeXmlTag: '/XML/Client/CRMDetails/PrimaryPhoneType',
  secondaryPhoneTypeXmlTag: '/XML/Client/CRMDetails/SecondaryPhoneType',
  drivingLicenseCheckXmlTag: '/XML/Client/AppDetails/DriverLicenseNoneChecked',
  USCitizenIndXmlTag: '/XML/Client/CRMDetails/USCitizenInd',
  drivingLicenseNumberXmlTag: '/XML/Client/AppDetails/DriverLicenseNum',
  emailXmlTag: '/XML/Client/AppDetails/Email'
};

export const ProposedInsuredBConstant = {
  firstName: '/XML/Client/CRMDetails/FirstName',
  lastName: '/XML/Client/CRMDetails/LastName',
  dobQuestionId: '/XML/Client/CRMDetails/DOB',
  ageQuestId: '/XML/AnswerSet/Party/PrimaryInsuredB/TempAge',
  genderQuestionId: '/XML/Client/CRMDetails/Gender',
  primaryPhoneTypeQuestionId: '/XML/Client/CRMDetails/PrimaryPhoneType',
  secondaryPhoneTypeQuestionId: '/XML/Client/CRMDetails/SecondaryPhoneType',
  drivingLicenseNoneCheckedQuestionId: '/XML/Client/AppDetails/DriverLicenseNoneChecked',
  USCitizenIndQuestionId: '/XML/Client/CRMDetails/USCitizenInd',
  driverLicenseNumberQuestionId: '/XML/Client/AppDetails/DriverLicenseNum',
  stateOfBirthQuestionId: '/XML/Client/CRMDetails/StateOfBirth',
  addressSameAsInsured: '/XML/Client/AppDetails/OwnerAddressSameAs',
  rateClass: '/XML/Client/AppDetails/DesiredRateClass'
};

export const CoverageQuestionConstant = {
  eftQuestionId: '/XML/CoverageInformation/EFTFomInd',
  premiumModeQuestionId: '/XML/ApplicationDetail/PaymentFrequencyType',
  specialDatingQues: '/XML/CoverageInformation/SpecialDating',
  rateClassQues: '/XML/NYReg187/ProposedRateAnswer',
  durationQues: '/XML/CoverageInformation/Duration',
  faceAmountQues: '/XML/ApplicationDetail/FaceAmount',
  modalPremiumAmountQues: '/XML/CoverageInformation/ModalPremiumAmount',
  illustrationQues: '/XML/CoverageInformation/SignedIllustrationInd',
};

export const WAReplacementConst = {
  seqNumber: '/XML/ReplacementFormInfo/ReplacementForm/SeqNumber',
  companyDropdown: '/XML/ReplacementFormInfo/ReplacementForm/OtherInsuranceCompanyLapseCompanyName',
  companyDropDropName: '/XML/ReplacementFormInfo/ReplacementForm/OtherInsuranceCompanyLapseCompanyName_~_25742#2_~_5_~_STR_~_1',
  otherCompanyName: '/XML/ReplacementFormInfo/ReplacementForm/OtherInsuranceCompanyLapseCompanyName_~_119766#_~_7_~_STR_~_1',
  contractName: '/XML/ReplacementFormInfo/ReplacementForm/OtherInsuranceCompanyPolicyNumber_~_25742#3_~_7_~_STR_~_1'
};

export const WAReplacementProposedBConst = {
  seqNumber: '/XML/ReplacementFormInfo/ReplacementFormInsB/SeqNumber',
  companyDropdown: '/XML/ReplacementFormInfo/ReplacementFormInsB/OtherInsuranceCompanyLapseCompanyName',
  companyDropDropName: '/XML/ReplacementFormInfo/ReplacementFormInsB/OtherInsuranceCompanyLapseCompanyName_~_27245#2_~_5_~_STR_~_1',
  otherCompanyName: '/XML/ReplacementFormInfo/ReplacementFormInsB/OtherInsuranceCompanyLapseCompanyName_~_124233#_~_7_~_STR_~_1',
  contractName: '/XML/ReplacementFormInfo/ReplacementFormInsB/OtherInsuranceCompanyPolicyNumber_~_27245#3_~_7_~_STR_~_1',
};

export const LTCExistingInsConst = {
  companyDropdown: '/XML/CCABR/Insurance/LTCCompany',
  companyDropDropName: '/XML/CCABR/Insurance/LTCCompany_~_24733#2_~_5_~_STR_~_1',
  otherCompanyName: '/XML/CCABR/Insurance/LTCCompanyTextTemp_~_119862#_~_7_~_STR_~_1',
  contractName: '/XML/CCABR/Insurance/PolicyNumberExisting_~_24733#3_~_7_~_STR_~_1',
  LTCReplacedInd: '/XML/CCABR/LTCReplacedInd',
  seqNumber: '/XML/CCABR/Insurance/SeqNumber'
};

export const ExistingInsuranceConstant = {
  seqNumber: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/SeqNumber',
  seqNumber2: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/SeqNumber',
  seqNumber3: '/XML/ApplicationDetail/EticketEI/ExistingInsurancePP/SeqNumber',
  proposedPolicyType: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/ProposedInsuranceType',
  hasExistingInsuranceQuesId: '/XML/ApplicationDetail/EticketEI/CurrentInsuranceIndicator',
  quitExistingPolicyQuesId: '/XML/ApplicationDetail/EticketEI/QuitExistingPolicy',
  notifyCompanyQuesId: '/XML/ApplicationDetail/EticketEI/NotifyCompany',
  existingInsurerList: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/CompanyNameList',
  existingInsurerListIn: '/XML/ApplicationDetail/EticketEI/ExistingInsurancePP/CompanyNameList',
  IndicesForCashValue: '/XML/ApplicationDetail/EticketEI/IndicesForCashValue',
  replacingPolicyXmlTag: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/InsuredReplacingPolicy',
  mnReplacingPolicyXmlTag: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/InsuredReplacingPolicy',
  existingFundsQuesId: '/XML/ApplicationDetail/EticketEI/UsingExistingFunds',
  policyBeingQuesId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/ExistingPolicyBeing',
  replacementQuesFieldId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/ReplacementChangePolicyInd',
  comparativeInfo: '/XML/ApplicationDetail/EticketEI/WantComparativeInformation',
  existingCompanyNameXml: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CompanyNameList',
  existingInsuranceProposedEffectiveDate: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/ProposedEffectiveDate',
  currentPolicyTerminateDate: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CurrentPolicyTerminationDate',
  saleMaterialInd: '/XML/ApplicationDetail/EticketEI/SalesMaterialInd',
  replacementTransactionInd: '/XML/ApplicationDetail/EticketEI/ReplacementTransactionInd',
  outstandingLoanInd: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/OutstandingLoanInd',
  mnOutstandingLoanInd: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/OutstandingLoanInd',
  certificateIssueInd: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CertificateIssueInd',
  mnCertificateIssueInd: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/CertificateIssueInd',
  // used for grid headers
  insurerQuesId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CompanyNameList_~_22326#6_~_5_~_TCD_~_1',
  otherInsurerNameId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CompanyName_~_867434#_~_7_~_STR_~_1',
  typeQuesId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CoverageType_~_22326#18_~_5_~_TCD_~_1',
  companyNameQuesId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CompanyNameList_~_22326#8_~_5_~_TCD_~_1',
  otherCompanyNameQuesId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/CompanyName_~_867437#_~_7_~_STR_~_1',
  insuredNameQuesId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/NameOfInsured_~_22326#25_~_7_~_STR_~_1',
  contractNumberMaId: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/PolicyNumber_~_19904#9_~_7_~_STR_~_3',
  indianaTypeOfPolicy1: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/PolicyType_~_19904#10_~_7_~_STR_~_3',
  indianaPolicyNo1: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/PolicyNumber_~_19904#11_~_7_~_STR_~_3',
  indianaDateOfIssue1: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/IssueDate_~_19904#12_~_11_~_STR_~_3',
  companyNameIN2: '/XML/ApplicationDetail/EticketEI/ExistingInsurancePP/CompanyNameList_~_20004#3_~_5_~_TCD_~_4',
  otherCompanyNameIN2: '/XML/ApplicationDetail/EticketEI/ExistingInsurancePP/CompanyName_~_121160#_~_7_~_STR_~_4',
  typeOfPolicyIN2: '/XML/ApplicationDetail/EticketEI/ExistingInsurancePP/PolicyType_~_20004#5_~_7_~_STR_~_4',
  faceAmountIN2: '/XML/ApplicationDetail/EticketEI/ExistingInsurancePP/FaceAmount_~_20004#6_~_7_~_STR_~_4',
  faceAmountIN1: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/FaceAmount_~_19904#13_~_7_~_STR_~_3',
  policyNumberFieldId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/PolicyNumber_~_22326#16_~_7_~_STR_~_1',
  contractNumberFieldId: '/XML/ApplicationDetail/EticketEI/ExistingInsurance/PolicyNumber_~_22326#15_~_7_~_STR_~_1',
  dateOfIssueMNStateId: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/IssueDate_~_19904#12_~_11_~_STR_~_3',
  insurerNameMIStateId: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/CompanyNameList_~_19904#5_~_5_~_TCD_~_3',
  companyNameMI: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/CompanyName_~_120946#_~_7_~_STR_~_3',
  policyOrContractNoId: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/PolicyNumber_~_19904#7_~_7_~_STR_~_3',
  policyNumberMI: '/XML/ApplicationDetail/EticketEI/ExistingInsuranceMI/PolicyNumber_~_19904#8_~_7_~_STR_~_3',
};

export const ExistingInsuranceProposedBConstant = {
  seqNumber: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/SeqNumber',
  seqNumber2: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/SeqNumber',
  seqNumber3: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurancePP/SeqNumber',
  proposedPolicyType: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/ProposedInsuranceType',
  hasExistingInsuranceQuesId: '/XML/ApplicationDetail/EticketEIpib/CurrentInsuranceIndicator',
  quitExistingPolicyQuesId: '/XML/ApplicationDetail/EticketEIpib/QuitExistingPolicy',
  notifyCompanyQuesId: '/XML/ApplicationDetail/EticketEIpib/NotifyCompany',
  existingFundsQuesId: '/XML/ApplicationDetail/EticketEIpib/UsingExistingFunds',
  policyBeingQuesId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/ExistingPolicyBeing',
  replacementQuesFieldId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/ReplacementChangePolicyInd',
  comparativeInfo: '/XML/ApplicationDetail/EticketEIpib/WantComparativeInformation',
  existingCompanyNameXml: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CompanyNameList',
  existingInsuranceProposedEffectiveDate: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/ProposedEffectiveDate',
  currentPolicyTerminateDate: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CurrentPolicyTerminationDate',
  saleMaterialInd: '/XML/ApplicationDetail/EticketEIpib/SalesMaterialInd',
  replacementTransactionInd: '/XML/ApplicationDetail/EticketEIpib/ReplacementTransactionInd',
  outstandingLoanInd: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/OutstandingLoanInd',
  mnOutstandingLoanInd: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/OutstandingLoanInd',
  certificateIssueInd: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CertificateIssueInd',
  mnCertificateIssueInd: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/CertificateIssueInd',
  existingInsurerList: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/CompanyNameList',
  existingInsurerListIn: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurancePP/CompanyNameList',
  IndicesForCashValue: '/XML/ApplicationDetail/EticketEIpib/IndicesForCashValue',
  replacingPolicyXmlTag: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/InsuredReplacingPolicy',
  mnReplacingPolicyXmlTag: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/InsuredReplacingPolicy',

  // used for grid headers
  insurerQuesId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CompanyNameList_~_26446#6_~_5_~_TCD_~_2',
  otherInsurerNameId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CompanyName_~_122842#_~_7_~_STR_~_2',
  typeQuesId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CoverageType_~_26446#18_~_5_~_TCD_~_2',
  companyNameQuesId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CompanyNameList_~_26446#8_~_5_~_TCD_~_2',
  otherCompanyNameQuesId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/CompanyName_~_122845#_~_7_~_STR_~_2',
  insuredNameQuesId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/NameOfInsured_~_26446#25_~_7_~_STR_~_2',
  policyNumberFieldId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/PolicyNumber_~_26446#16_~_7_~_STR_~_2',
  contractNumberFieldId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurance/PolicyNumber_~_26446#15_~_7_~_STR_~_2',
  dateOfIssueMNStateId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/IssueDate_~_26445#12_~_11_~_STR_~_3',
  insurerNameMIStateId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/CompanyNameList_~_26445#5_~_5_~_TCD_~_3',
  companyNameMI: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/CompanyName_~_122824#_~_7_~_STR_~_3',
  policyOrContractNoId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/PolicyNumber_~_26445#7_~_7_~_STR_~_3',
  policyNumberMI: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/PolicyNumber_~_26445#8_~_7_~_STR_~_3',
  contractNumberMaId: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/PolicyNumber_~_26445#9_~_7_~_STR_~_3',
  indianaTypeOfPolicy1: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/PolicyType_~_26445#10_~_7_~_STR_~_3',
  indianaPolicyNo1: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/PolicyNumber_~_26445#11_~_7_~_STR_~_3',
  indianaDateOfIssue1: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/IssueDate_~_26445#12_~_11_~_STR_~_3',
  companyNameIN2: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurancePP/CompanyNameList_~_26448#3_~_5_~_TCD_~_4',
  otherCompanyNameIN2: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurancePP/CompanyName_~_122940#_~_7_~_STR_~_4',
  typeOfPolicyIN2: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurancePP/PolicyType_~_26448#5_~_7_~_STR_~_4',
  faceAmountIN2: '/XML/ApplicationDetail/EticketEIpib/ExistingInsurancePP/FaceAmount_~_26448#6_~_7_~_STR_~_4',
  faceAmountIN1: '/XML/ApplicationDetail/EticketEIpib/ExistingInsuranceMI/FaceAmount_~_26445#13_~_7_~_STR_~_3',
};

export const OwnerInfoConstant = {
  // used for grid header
  ownerFirstName: '/XML/Client/CRMDetails/FirstName_~_106082#_~_7_~_STR_~_1',
  ownerLastName: '/XML/Client/CRMDetails/LastName_~_106084#_~_7_~_STR_~_1',
  entityName: '/XML/Client/CRMDetails/LastName_~_106156#_~_7_~_STR_~_1',
  trustName: '/XML/Client/CRMDetails/LastName_~_106101#_~_7_~_STR_~_1',
  ownerType: '/XML/Client/CRMDetails/ClientType',
  seqNumber: '/XML/Client/AppDetails/SeqNumber',
  OWNER_FIRST_NAME: '/XML/Client/CRMDetails/FirstName',
  OWNER_LAST_NAME: '/XML/Client/CRMDetails/LastName',
  OWNER_ADDRESS: '/XML/Client/CRMDetails/Address/Address1',
  OWNER_CITY: '/XML/Client/CRMDetails/Address/City',
  OWNER_STATE: '/XML/Client/CRMDetails/Address/State',
  EFT_STATE: '/XML/Client/CRMDetails/EftStateAddress',
  OWNER_ZIP: '/XML/Client/CRMDetails/Address/PostalCode',
  OWNER_ADDRESS_SAME_AS: '/XML/Client/AppDetails/OwnerAddressSameAs',
  ENTITY_BUISNESS_FIRSTNAMR: '/XML/Client/AppDetails/TrusteeBusinessFirstName1',
  ENTITY_BUISNESS_LASTNAME: '/XML/Client/AppDetails/TrusteeBusinessLastName1',
  primaryPhoneQues: 'What is the owner\'s primary phone number?',
  ENTITY_BUISNESS_PHONE: '/XML/Client/AppDetails/TrusteeBusinessHomePhone1',
  entityPhoneText: 'Primary Phone Number',
  ENTITY_PHONE_TYPE: '/XML/Client/AppDetails/PrimaryPhoneType1',
  ENTITY_EMAIL: '/XML/Client/AppDetails/TrusteeBusinessEmailAddress1',
  phoneNumber: '/XML/Client/CRMDetails/HomePhone',
  personPhoneType: '/XML/Client/CRMDetails/PrimaryPhoneType'
};

export const FundAllocationConst = {
  automaticRebalancing: '/XML/ApplicationDetail/AutoRebalancingInd',
  fundQuesXmlTag: '/XML/FundSelect/FundAllocation',
  // dca fund related tags
  dcaFundQuesXmlTag: '/XML/DCAFundSelect/FundAllocation',
  dollarCostAverage: '/XML/ApplicationDetail/DCAIndicator',
  accountToDCA: '/XML/ApplicationDetail/DCAFromFund',
  dcaPeriod: '/XML/ApplicationDetail/DCAPeriod',
  dcaTransferOption: '/XML/ApplicationDetail/DCATransferOption',
  // maturing index fund related tags
  maturingIndexFundXmlTag: '/XML/MaturingFund/FundAllocation',
  MaturingIndexIndQues: '/XML/ApplicationDetail/MaturingIndexAllocationInd',
  // transfer allocation charges
  fundCodeQuesId: '/XML/ClientDirectedMonthlyDeductions/FundSelect/FundCode',
  deductFundCheckboxXml: '/XML/ClientDirectedMonthlyDeductionsInd',
  // model portfolio selection
  portfolioSelection: '/XML/ApplicationDetail/ActivePortfolioInd',
  passivePortfolioSelection: '/XML/ApplicationDetail/AssetEdgeModelPortfolio'
};

export const AgentInfoConstant = {
  // for primary agent
  seqNumber: '/XML/Client/AgentDetails/AdditionalAgent/SeqNumber',
  isMoreAgentQuesId: '/XML/Client/AgentDetails/MoreThanOneAgent',
  splitMainAgentQuesId: '/XML/Client/AgentDetails/Split',
  agentSSNQuesId: '/XML/Client/AgentDetails/AgentTaxID',
  firmNameQuesId: '/XML/ApplicationDetail/Agent/CompanyName',
  agentNPNQuesId: '/XML/Client/AgentDetails/AgentNPN',
  agentFirstNameQuesId: '/XML/Client/AgentDetails/AgentFirstName',
  agentLastNameQuesId: '/XML/Client/AgentDetails/AgentLastName',
  agentEmailQuesId: '/XML/ApplicationDetail/PrimaryAgentEmail',
  agentNumberQuesId: '/XML/Client/AgentDetails/AgentNumber',
  allStateAgentIdXmlTag: '/XML/Client/AgentDetails/AllStateAgentID',
  emailInfoFieldId: '_~_22115#18_~_0_~_TDT_VNF_~_2',
  doYouattestTag: '/XML/Client/AgentDetails/DoYouAttestInd',
  clientAccountNoXmlTag: '/XML/Client/AgentDetails/BrokerDealerOwnerAccountNo',
  agentPhoneNumber: '/XML/ApplicationDetail/Agent/PhoneNumber',
  contactPhoneTypeXmlTag: '/XML/Client/AgentDetails/ContactPhoneType',
  agentOtherFirmName: '/XML/Client/AgentDetails/OtherFirm',

  // for additional agents
  secAgentSSNQuesId: '/XML/Client/AgentDetails/AdditionalAgent/SSN',
  secAgentFirmId: '/XML/Client/AgentDetails/AdditionalAgent/Firm',
  secAgentFirstName: '/XML/Client/AgentDetails/AdditionalAgent/AgentFirstName',
  secAgentLastName: '/XML/Client/AgentDetails/AdditionalAgent/AgentLastName',
  secAgentNPN: '/XML/Client/AgentDetails/AdditionalAgent/AgentNPN',

  // for grid header
  secAgentFirstNameQuesId: '/XML/Client/AgentDetails/AdditionalAgent/AgentFirstName_~_22117#2_~_7_~_STR_~_1',
  secAgentLastNameQuesId: '/XML/Client/AgentDetails/AdditionalAgent/AgentLastName_~_22117#3_~_7_~_STR_~_1',
  secAgentNoQuesId: '/XML/Client/AgentDetails/AdditionalAgent/AgentNumber_~_22117#5_~_7_~_STR_~_1',
  secAgentNoQuesIdNotReq: '/XML/Client/AgentDetails/AdditionalAgent/AgentNumber_~_22117#4_~_7_~_STR_~_1',
  secSplitQuesId: '/XML/Client/AgentDetails/AdditionalAgent/PercentageSplit_~_22117#9_~_7_~_STR_~_1',

  // to get grid answer
  secAgentFirmQuesName: '22117#14_~_5_~_STR_~_1',
  secAgentSSNQuesName: '22117#10_~_12_~_STR_~_1',
  secAgentLNQuesName: '22117#3_~_7_~_STR_~_1',
  secOtherFirmNameQuesName: '116451#_~_7_~_STR_~_1',
  secOtherFirmNameQuesId: '/XML/Client/AgentDetails/AdditionalAgent/OtherFirm_~_116451#_~_7_~_STR_~_1',
  secSplitQuesName: '22117#9_~_7_~_STR_~_1',
};

// update these whenever agent info page updated
export const SummaryConstant = {
  agentFirstName: '22117#2_~_7_~_STR_~_1',
  agentLastName: '22117#3_~_7_~_STR_~_1',
  agentEmail: '22117#19_~_30_~_STR_~_1',
  agentPhone: '22117#18_~_13_~_STR_~_1'
};

export const AgentInfoContConstant = {
  seqNumber: '/XML/Client/AgentDetails/AgentInfoCont/BusinessInsurance/SeqNumber',
  knowEnglishQuesId: '/XML/ApplicationDetail/AgentReportKnowEnglishInd',
  caseManagerEmailId: '/XML/Client/AgentDetails/AgentInfoCont/CaseManagerEmail',
  caseManagerDoYouAttestQuesId: '/XML/Client/AgentDetails/AgentInfoCont/DoYouAttestInd',
  caseManagerInfoQuesId: '_~_22418#5_~_0_~_TDT_VNF_~_RB_NVF',
  officeStaffEmailId: '/XML/Client/AgentDetails/AgentInfoCont/CaseManagerEmail',
  officeStaffDoYouAttestQuesId: '/XML/Client/AgentDetails/AgentInfoCont/DoYouAttestInd',
  officeStaffInfoQuesId: '_~_22418#7_~_0_~_TDT_VNF_~_RB_NVF',
  existingPolicyQues: '/XML/Client/AgentDetails/AgentInfoCont/ExistingPolicyInd',
  reasonToBelieveQues: '/XML/Client/AgentDetails/AgentInfoCont/ReplacementInd',
  nameOFBusinessQuesId: '/XML/Client/AgentDetails/AgentInfoCont/BusinessInsurance/PartnerName_~_22419#2_~_7_~_STR_~_1',
  eInterviewQuesId: '/XML/Client/AgentDetails/AgentInfoCont/InterviewType',
  childRiderInfoId: '_~_22418#12_~_0_~_TDT_VNF_~_RB_NVF',
  existingInsuranceInfo: '/XML/Client/AgentDetails/AgentInfoCont/ReplacementInd',
  existingPolicyProposedB: '/XML/Client/AgentDetails/AgentInfoCont/ExistingPolicyIndB',
  replacementInfo: '/XML/Client/AgentDetails/AgentInfoCont/ReplacementIndB',
  purposeOfInsuranceMA: '/XML/Client/AgentDetails/AgentInfoCont/PurposeOfInsuranceMA',
  purposeOfInsuranceNonMA: '/XML/Client/AgentDetails/AgentInfoCont/PurposeOfInsurance',
  businessLineFieldId: '_~_22418#60_~_0_~_TDT_VNF_~_RB_NVF',
  businessInsuranceTitleId: '/XML/Client/AgentDetails/AgentInfoCont/BusinessInsurance/PartnerTitle_~_22419#3_~_7_~_STR_~_1',
  commissionSchedule: '/XML/Client/AgentDetails/AgentInfoCont/CommissionSchedule',
  commisionOnRider: '/XML/Client/AgentDetails/AgentInfoCont/CommissionOnRider'
};

// Agent Info cont const
export const CCBRQuestionsTags = [
  '_~_22418#50_~_0_~_TDT_VNF_~_RB_NVF',
  '_~_22418#51_~_0_~_TDT_VNF_~_RB_NVF',
  '/XML/Client/AgentDetails/AgentInfoCont/AdditionalBenefit',
  '/XML/Client/AgentDetails/AgentInfoCont/NoChangeBenefit',
  '/XML/Client/AgentDetails/AgentInfoCont/FewerBenefit',
  '/XML/Client/AgentDetails/AgentInfoCont/OtherBenefit'
];

export const AgentInstructionConst = {
  seqNumber: '/XML/Client/AgentDetails/AgentInstructions/SeqNumber'
};

export const TrustInfoConstant = {
  seqNumber: '/XML/Client/AppDetails/TrusteeInfo/SeqNumber',
  state: '/XML/Client/AppDetails/TrusteeInfo/Address/State',
  phoneType: '/XML/Client/AppDetails/TrusteeInfo/PhoneType',
  TRUST_EMAIL: '/XML/Client/AppDetails/TrusteeInfo/EmailID',
  trustLastName: '/XML/Client/AppDetails/TrusteeInfo/LastName_~_22928#3_~_7_~_STR_~_1',
  trustFirstName: '/XML/Client/AppDetails/TrusteeInfo/FirstName_~_22928#2_~_7_~_STR_~_1',
  TRUST_FIRST_NAME: '/XML/Client/AppDetails/TrusteeInfo/FirstName',
  TRUST_LAST_NAME: '/XML/Client/AppDetails/TrusteeInfo/LastName',
  TRUST_CITY: '/XML/Client/AppDetails/TrusteeInfo/Address/City',
  TRUST_STREET: '/XML/Client/AppDetails/TrusteeInfo/Address/Street',
  TRUST_STATE: '/XML/Client/AppDetails/TrusteeInfo/Address/State',
  TRUST_ZIP: '/XML/Client/AppDetails/TrusteeInfo/Address/Zip',
  TRUST_PRIMARY_PHONE: '/XML/Client/AppDetails/TrusteeInfo/PrimaryPhoneNumber',
  TRUST_CELL_NUMBER: '/XML/Client/AppDetails/TrusteeInfo/CellNumber',
};

export const EFTConstant = {
  AccountHolderName: '/XML/Client/CRMDetails/TempInsurancePaymentProvider',
  firstNameXML: '/XML/Client/CRMDetails/EftAccountholderFirstName',
  lastNameXML: '/XML/Client/CRMDetails/EftAccountholderLastName',
  addressXML: '/XML/Client/CRMDetails/Address/Address1',
  EFTEntityNameXML: '/XML/Client/CRMDetails/EftAccountholderEntityName',
  cityXML: '/XML/Client/CRMDetails/Address/City',
  stateXML: '/XML/Client/CRMDetails/EftStateAddress',
  zipcodeXML: '/XML/Client/CRMDetails/Address/PostalCode',
  emailAddressXML: '/XML/Client/AppDetails/Email',
  primaryPhoneNumberXML: '/XML/Client/CRMDetails/HomePhone',
  primaryPhoneTypeXML: '/XML/Client/CRMDetails/PrimaryPhoneType',
  bankOrCreditUnionNameXML: '/XML/Client/Banking/EftBankUnionName',
  routingNumberXML: '/XML/Client/Banking/EftRoutingNumber',
  accountNumberXML: '/XML/Client/Banking/EftAccountNumber',
  accountTypeXML: '/XML/Client/Banking/EftAccountType'
};

export const TIAInfoConstant = {
  advancedPremiumAmount: '/XML/Client/CRMDetails/AdvancePremiumAmount',
  tiaSubmitted: '/XML/ApplicationDetail/TIASubmitted'
};

export const AdditionalInfoConstant = {
  voidedCheck: '/XML/Client/CRMDetails/AdditionalInfo/AttachVoidedCheckInd'
};

export const RiderQuestionConstant = {
  DBOptionQuesId: '/XML/ApplicationDetail/DBOption',
  GuaranteePremium: '/XML/ApplicationDetail/GMDBOption',
  CCABRQuesId: '/XML/Rider/Details/CCABRInd',
  CriticalIllnessRider: '/XML/Rider/Details/CRIRInd',
  EstateProtectionRider: '/XML/Rider/Details/EPRInd',
  LEABRQuesId: '/XML/Rider/Details/LEABRInd',
  LAABRQues: '/XML/Rider/Details/LAABRInd',
  OtherRiderDuration: '/XML/CoverageInformation/Duration',
  OtherRiderAmount: '/XML/Rider/Details/Amount',
  PrimaryRiderDuration: '/XML/CoverageInformation/PITRDuration',
  ChildRiderQues: '/XML/Rider/Details/CTIRInd',
  AcceleratedBenefitRider: '/XML/Rider/Details/ACBRInd',
  DeathBenefitQualification: '/XML/AnswerSet/DBQualTest',
  BusinessExecRider: '/XML/Rider/Details/BEESVRInd',
  WaiverMDProposedInsured: '/XML/Rider/Details/WMDPIInd',
  CareCoverageLTCSpecAmount: '/XML/Rider/Details/LTCSpecAmount',
  LongTermLTCSpecAmount: '/XML/Rider/Details/LTCSpecAmount',
  IndividualExecRider: '/XML/Rider/Details/IEESVRInd',
  LTCPercentage: '/XML/AnswerSet/RiderDetail/LTCRider2/MaxMonthLTCPercentage',
  primaryLTCAmt: '/XML/Rider/Details/PITRAmount',
  LTCPercentageLongTerm: '/XML/AnswerSet/RiderDetail/LTCRider/MaxMonthLTCPercentage',
  EESVRider: '/XML/Rider/Details/EESVRInd',
  SVEERider: '/XML/Rider/Details/SVEEInd',
  ESVRider: '/XML/Rider/Details/ESVRInd',
  SupplementalRider: '/XML/Rider/Details/SIRInd',
  ExtendedNoLapseRider: '/XML/Rider/Details/ENLMPRInd',
  ChangeOfRider: '/XML/Rider/Details/CIRInd',
  LTCRider: '/XML/Rider/Details/LTCRInd',
  OverLoanProtection: '/XML/Rider/Details/OPRInd',
  PrimaryInsuredRider: '/XML/Rider/Details/PITRInd',
  OtherInsuredRider: '/XML/Rider/Details/OITRInd',
  LAABRIIRider: '/XML/Rider/Details/LLAABRIIInd',
  ProspectiveInsuredQues: '/XML/ApplicationDetail/PolicyDeliveryInd',
  WOPRider: '/XML/Rider/Details/WOPInd',
  SSTIRider: '/XML/Rider/Details/SSTIR',
  SSTIRAmount: '/XML/AnswerSet/RiderDetail/SupplementalSurvivorship/Amount',
  SLAABRider: '/XML/Rider/Details/SLAABR',
  TIABRider: '/XML/Rider/Details/TIABR',
  LEVRider: '/XML/Rider/Details/LEVR'
};

export const TransAllocationConst = {
  TransferAuthQues: '/XML/ApplicationDetail/TelephoneInternetTransferAuth'
};

export const SigningMethodConst = {
  SigningState: '/XML/ApplicationDetail/EticketSM/SignedInState'
};

export const eSignatureInstructionConst = {
  confirmEmailTag: '/XML/ApplicationDetail/EticketES/ConfirmEmailID'
};

export const AppConstant = {
  space: 'Space',
};

export const PageStatusConstant = {
  VALID: 'valid',
  INVALID: 'invalid',
  LOCKED: 'locked',
};

export const CaseStatus = {
  STARTED: 'Started',
  LOCKED_READY_TO_SIGN: 'Locked - Ready to Sign',
  AWAITING_CUSTOMER_ESIGN: 'Awaiting Consumer e-Signature',
  CUSTOMER_DECLINE_TO_ESIGN: 'Consumer Declined to e-Sign',
  AWAITING_AGENT_ESIGN: 'Awaiting Agent e-Signature',
  APPLICATION_ESUBMITTED: 'Application e-Submitted',
  PRINCIPAL_AGENT_DECLINED: 'Declined by Principal Agent',
  READY_TO_SUBMIT: 'Ready to Submit',
};

export const StatusHelpConst = [
  {
    status: CaseStatus.STARTED,
    description:
      'An application has been started. Additional required information must be collected before the application can be signed and submitted to the carrier.',
  },
  {
    status: CaseStatus.LOCKED_READY_TO_SIGN,
    description:
      'All required information has been collected. The application has been locked and it is ready to be signed. A signature method must now be selected.',
  },
  {
    status: CaseStatus.AWAITING_CUSTOMER_ESIGN,
    description:
      'One or more consumer e-Signatures are outstanding and required.',
  },
  {
    status: CaseStatus.CUSTOMER_DECLINE_TO_ESIGN,
    description:
      'One or more consumers have declined to e-Sign. The client should be contacted to determine why the e-Signature process was terminated.',
  },
  {
    status: CaseStatus.AWAITING_AGENT_ESIGN,
    description:
      'All client e-Signatures have been collected, the application is now ready for the agent to sign.',
  },
  {
    status: CaseStatus.PRINCIPAL_AGENT_DECLINED,
    description:
      'The Principal Approver has declined to approve and e-Submit this case. For more information, please contact your administrator directly.',
  },
  {
    status: CaseStatus.READY_TO_SUBMIT,
    description:
      'All e-Signatures have been collected, the application is now ready to submit.',
  },
  {
    status: CaseStatus.APPLICATION_ESUBMITTED,
    description:
      'The e-Submission process is complete and the application has been electronically submitted for processing. For additional information,',
  },
];

export const Message = {
  EINTERVIEW_INFO_ENABLED: 'Your client will receive an email link to complete their interview online. Should you be aware of any substantial medical history, a phone interview may be the best option.',
  EINTERVIEW_INFO_DISABLED: 'Your client will receive an email link to complete their interview online.',
  APPLICATION_DOWN: 'The Lincoln eSubmission application is currently unavailable. We are working to resolve this issue and apologize for any inconvenience. Please try again later.',
  UNAUTHORIZED_USER_ERROR: 'You currently do not have permission to access the Lincoln eSubmission application on behalf of your Producer. Please contact your registered Producer and have them provide you with access through their Access & Permissions settings.',
  ONLY_5_ALLOWED: 'You have selected the max of 5. Please deselect to continue.',
  ACTION_REQ_CASES_LEAVING_SOON: 'Action required: {$noOfCases} incomplete cases are leaving soon.',
  NO_OF_RECORD_MATCHING: 'Number of records matching criteria ',
  POLICY_SUBMISSION_HEADER: 'We\'re currently processing your request.',
  DO_NOT_CLOSE_MESSAGE: 'Please do not close this window or click the back button until the process is completed or the case has been submitted.',
  ENVELOPE_CREATION_LOADER_MESSAGE: 'Once the process is completed an email will be sent to the signers. The agent will be notified when it\'s their turn to complete the signing process.',
  BOTH_ENVELOPE_CREATION_LOADER_MESSAGE: 'Once the process is completed, in-person signers will have the ability to review all forms for accuracy and apply their eSignature. Remote signers will be notified when it\'s their turn to sign the document.',
  FORM_LOADER_MESSAGE: 'Once the process is completed you will be able to review and download the forms.',
  POLICY_SUBMISSION_LOADER_MESSAGE: 'Once the request is completed a confirmation will display.',
  GENERIC_ERROR: 'We are unable to process your request at this time, please try again later.',
  WELCOME_MSG: 'Welcome to Lincoln\'s eSubmission Portal for life insurance and MoneyGuard® solutions. You can save your progress and return at any time. If you have questions during the eSubmission process, please select the help link to access frequently asked questions.',
  REQUIRED_QUES_MSG: 'All questions are required unless marked optional. Please answer all required questions on each page in order to proceed.',
  FILE_INFO: 'PDF format only. Upload file size limit: 5MB.',
  FILE_FORMAT_ERR: 'The file is invalid. Only PDF files are accepted. Upload file size limit: 5MB',
  FILE_UPLOAD_ERR: 'File upload failed. Please try again.',
  DELETE_CASE_WARNING_MESSAGE: 'You are about to delete the case(s). Cases once deleted cannot be retrieved.',
  DELETE_ONE_CASE_WARNING_MESSAGE: 'You are about to delete the case. Case once deleted cannot be retrieved.',
  DELETE_CASE_CONFIRMATION_MESSAGE: 'Do you want to delete this case?',
  DELETE_CASE_DIALOG_HEADER: 'Delete Case',
  DELETE_CASE_STATUS_DIALOG_HEADER: 'Delete Case Status',
  DELETE_CASE_ERROR: 'Error while deleting cases. Please contact support team',
  FAILED_TO_DELETE_CASE_MESSAGE: '{noOfCase} case(s) failed to delete.',
  SUCCESSFULLY_DELETE_CASE_MESSAGE: '{noOfCase} case(s) deleted successfully. ',
  MODAL_PREMIUM_ERROR: 'The Minimum allowable amount for the Modal Premium Amount is ${minimum} if the Premium Mode is {mode}.',
  RIDER_MIN_AMOUNT_ERROR: 'Minimum amount is $100,000.',
  OTHER_RIDER_MAX_AMOUNT_ERROR: 'Maximum amount is the lesser of ${{minAmount}} (Face Amount + Primary Insured Term Rider amount) and $500,000.',
  PRIMARY_RIDER_MAX_AMOUNT_ERROR: 'Maximum amount is the lesser of (face amount) and $1,000,000.',
  OTHER_RIDER_DURATION_ERROR: 'Primary Insured age plus rider duration cannot exceed {{age}}.',
  PRIMARY_RIDER_DURATION_ERROR: 'Primary Insured age plus rider duration cannot exceed 80 years old.',
  PRIMARY_RIDER_DURATION_ERROR_WA: 'Primary Insured age cannot exceed {{age}} years old for {{duration}} year duration.',
  MIN_AMOUNT_ERROR: 'Minimum amount is ${{minAmount}}.',
  MAX_AMOUNT_ERROR: 'Maximum amount is ${{maxAmount}}.',
  LTC_AMOUNT_ERROR: 'LTC Specified Amount is outside rider guidelines.',
  WEIVER_AMOUNT_ERROR: 'Amount must be between ${{minAmount}} and ${{maxAmount}}.',
  DCA_RELATED_INFO: 'DCA from and to the same Account is not allowed. Please remove an initial Premium Payment from the money market Sub-Account or Fixed Account.',
  BUSINESS_SURRENDER_RIDER_ERROR: 'The Business EXEC Enhanced Surrender Value Rider requires that one of the Owners be a Corporation.',
  INDIVIDUAL_SURRENDER_RIDER_ERROR: 'The Individual EXEC Enhanced Surrender Value Rider requires that one of the Owners be an Individual or Trust and that no Owners are a Corporation.',
  DOB_AND_DBQ_MESSAGE: 'The Death Benefit Option, Increase by Premium, is not allowed with the Death Benefit Qualification of CVAT.',
  DIFF_OWNER_STATE_INFO: 'State of Solicitation does not match Owner state. A Lincoln representative will contact you for clarification prior to beginning the TeleApp interview.',
  DIFF_OWNER_STATE_INFO_WA: 'State of Solicitation does not match Owner state. Cross border sales are not allowed in UT and WA. If owner/applicant resides in UT or WA, the policy must be sitused there. A Lincoln representative will contact you for clarification.',
  VUL_DCA_ERROR: 'DCA from and to the same Account is not allowed.',
  ASSETEDGE_FIXED_ACCOUNT_ERROR: 'An initial premium must be made to the Fixed Account.',
  ASSETEDGE_GOVT_ERROR: 'An initial premium must be made to the Government Money Market Sub-Account.',
  MAX_FUND_ERROR: 'Only 5 funds can be selected.',
  ADVANCE_PREMIUM_AMOUNT_ERROR: 'Please enter an advance premium payment amount greater than $0.',
  FUND_MISSING_ERROR: 'Select one or more funds on the Fund Allocation page.',
  REPLACEMENT_QUES_ERROR: 'Answers must match previous replacement questions.',
  DIFF_PROPOSED_INSURED_STATE_INFO: 'State of Solicitation does not match Insured information. A Lincoln representative will contact you for clarification prior to beginning the TeleApp Interview.',
  EXISTING_INS_INFO_DE: 'Information on Present Policies',
  REPLACEMENT_INS_INFO_MI: 'The following Policy(ies) may be replaced as a result of this transaction:',
  EXISTING_INS_INFO_NON_DE: 'List each existing policy or contract you are contemplating replacing (include the name of the insurer, the insured or annuitant, and the policy or contract number if available) and whether each policy will be replaced or used as a source of financing:',
  DCA_INFO_MESSAGE: 'Automatic rebalancing is not available if Dollar Cost Averaging is selected.',
  SIGNER_STATE_MT_MESSAGE: 'The state of situs selected is not MT but the proposed insured or owner resides in MT, or the entity-owner has a location in MT; no policy activity including signing of forms can be done in MT.',
  EXISTING_INSURANCE_IN: 'Please list Existing Policy Information on Insured.',
  PROPOSOSED_INSURANCE_IN: 'Please list Proposed Policy Information on Insured.',
  EXISTING_INSURANCE_IN_YES: 'List each existing policy or contract you are contemplating replacing (include the name of the insurer, the insured or annuitant, and the policy or contract number if available) and whether each policy will be replaced or used as a source of financing:',
  MGMA_IPIPELINE_REDIRECT_MSG: 'By selecting Continue you will be taken to iPipeline to complete your electronic application.',
  PHONE_INFO: 'The phone number will be used to send a pin number via text or an automated voice call during the forms signing process.'
};

export const ErrorMessage = {
  VUL_SVUL_21_UNAVAILABLE: 'As of August 9, 2024, the Lincoln VULone and Lincoln SVULone products have been discontinued, and this eTicket/eApp case can no longer be accessed or submitted. Please begin a new eTicket/eApp and select a different product for submission.',
  UNANSWERED_QUES_ERROR: 'You have unanswered questions. All questions are required before completing the Application.',
  TRUST_OWNER_ERROR: 'Multiple owners are not allowed when a trust is selected as an owner.',
  FUND_PERCENTAGE_ERROR: 'Total should be equal to 100%.',
  VUL_FUND_ERROR: 'If any allocation is made to Tier 3, then at least 25% must be allocated to Tier 1',
  DCA_FUND_ERROR: 'An allocation is needed in the Fixed Account and/or the Government Money Market Fund if Dollar Cost Averaging is selected.',
};

export const AgentValidationMessage = {
  DUPLICATE_SSN_ERROR: 'Multiple agents exist with the same SSN. Each Agent should have a different SSN.',
  ERROR_OCCURRED: 'We are unable to process your request at this time, please try again later.',
  VALIDATION_PENDING: 'Agent validation is not completed.',
  ALL_AGENTS_VALID: 'Agent(s) validated successfully.',
  TERM_AGENTS_FAILED_MESSAGE: 'We apologize. PRODUCER NOT APPOINTED in selected state (${selectedState}). Lincoln Financial Group will contract your licensing representative to complete the appointment process.  Any delays in the appointment process may delay the issuance of this policy and require additional paperwork or resubmission.',
  NON_TERM_AGENT_FAILED_MESSAGE: 'We apologize. PRODUCER(s) NOT APPOINTED in selected state (${selectedState}). Lincoln Financial Group will contact your licensing representative to complete the appointment process. Any delays in the appointment process could result in a return of premium received under Temporary Insurance Agreement, may delay the issuance of this policy and could require additional paperwork or resubmission.',
  AGENT_PROCEED_MESSAGE: 'You may continue with the ticket by selecting the "OK" button.'
};

export const ExistingInsuranceMessage = {
  INSURANCE_ERROR_MESSAGE: 'Please review the answer for the existing (inforce) life insurance or annuity contracts question due to a discrepancy with the answer(s) provided for replacing or borrowing funds questions.',
  INTERNAL_COMPANY_INFO: 'Internal Replacements are not allowed with the Business EXEC Enhanced Surrender Value Rider.'
};

export const ValidateAndLockMessage = {
  VALID_TABS_SCREEN_HEADING: 'Your application is in Good Order!',
  INVALID_TABS_SCREEN_HEADING: 'One or more screens are not in good order.',
  UNLOCK_SCREEN_HEADING: 'Your application has been locked!',
  LOCK_SCREEN_MESSAGE: `<div class='message-head'>Please follow next steps below to complete your submission.</div><ol><li>To <strong>edit</strong> the application before locking, click on the screen name to the left of the navigation tree. Then return to the Validation and Lock Data screen.</li><li>To <strong>Lock</strong> and proceed with the electronic signature process, select the blue Lock Application button below.</li></ol><div class='message-foot'><strong>Note:</strong> No changes can be made without unlocking the application. Any signatures collected prior to unlocking this case will be lost and will need to be recollected from all signing parties again.</div>`,
  LOCK_SCREEN_NOTES: `<strong>Note:</strong> If you need to edit the application after it is locked, you may do so by coming back to this <strong>Validate and Lock Data</strong> screen located on the left navigation tree.`,
  ERROR_SCREEN_WARNING_MESSAGE: 'Your application is incomplete and cannot be locked at this time. Select the sections that have exclamation points to make the appropriate corrections before locking the application.',
  UNLOCK_SCREEN_MESSAGE: `<div class='message-head'>Please follow next steps below to complete your submission.</div><ol><li>To <strong>review</strong> forms before signing, select the View Forms button at the bottom of this page.</li><li>To proceed with the electronic signature process, select the blue Proceed to Electronic Signature Process button below.</li></ol><div class='message-foot'></div>Then select Next.<div class='message-foot'><strong>Note:</strong> No changes can be made without unlocking the application. Any signatures collected prior to unlocking this case will be lost and will need to be recollected from all signing parties again.</div>`,
  UNLOCK_MESSAGE: 'Unlock Application Data and Cancel Signature Process',
  UNLOCK_ARE_YOU_SURE_MSG: 'Unlocking the case will void all active envelopes that have been sent for eSignature and you will be required to restart the eSignature process.',
  ARE_YOU_SURE: 'Are you sure?',
  DELEGATE_INFO: `You have completed the question-and-answer portion of the On Behalf Of functionality. Please notify the license life insurance producer for review and completion of the electronic application.`,
};

export const voiceConfirmationMessage = {
  YOUR_SIGNATURE_MSG: 'Your signature will be included on all presale forms as part of the Producer Acknowledgement process.',
  ESIGN_APP_MSG: 'You will be provided with a link to eSign the application prior to final policy delivery.',
  THANK_YOU: 'Thank you!',
  SUCCESS_MSG: 'Your request was submitted successfully.'
};

export const VoiceTermOfUse = {
  TERM_OF_USE: `<span class='term-heading'>CONDITIONS OF USE</span><br/>
  By using this Web site in relation to an application for insurance with The Lincoln National Life Insurance Company, hereinafter collectively referred to as "the Company", you agree with the following Terms and Conditions Of Use ("Terms") without limitation or qualification. Please read these Conditions carefully before using this Web site. If you do not agree with these Terms, you are not granted permission to use this Web site and must exit this Web site immediately. The Company may revise these Terms at any time by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the current Terms governing this Web site.<br/><br/>
  <span class='term-heading'>DISCLAIMER</span><br/>
  TO THE FULLEST EXTENT PERMISSIBLE, THE MATERIALS ON THIS WEB SITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, AND THE COMPANY, AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (WHICH SHALL INCLUDE CAREER AGENTS AND BROKERS) (COLLECTIVELY "REPRESENTATIVES") AND SUPPLIERS DISCLAIM ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEB SITE, OR THE SERVER THAT MAKES IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THIS WEB SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. THE INFORMATION AND DESCRIPTIONS CONTAINED HEREIN ARE NOT NECESSARILY INTENDED TO BE COMPLETE DESCRIPTIONS OF ALL TERMS, EXCLUSIONS AND CONDITIONS APPLICABLE TO THE PRODUCTS AND SERVICES, BUT ARE PROVIDED SOLELY FOR GENERAL INFORMATIONAL PURPOSES; PLEASE REFER TO THE ACTUAL POLICY OR THE RELEVANT PRODUCT OR SERVICE AGREEMENT; THIS WEB SITE MAY BE LINKED TO OTHER WEB SITES WHICH ARE NOT MAINTAINED BY THE COMPANY. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, ARE RESPONSIBLE FOR THE CONTENT OF THOSE WEB SITES. THE INCLUSION OF ANY LINK TO SUCH WEB SITES DOES NOT IMPLY APPROVAL OF, OR ENDORSEMENT BY, THE COMPANY, OR ANY OF ITS REPRESENTATIVES OR SUPPLIERS, OF THE WEB SITES OR THE CONTENT THEREOF.<br/><br/>
  <span class='term-heading'>LIMITATION OF LIABILITY</span><br/>
  While the Company and its Representatives or suppliers use reasonable efforts to include accurate and up-to-date information on this Web site, errors or omissions may occur. Under no circumstances shall the Company, or its Representatives or suppliers, be liable to you for any direct, incidental, consequential, indirect, or punitive damages that result from the use of, or the inability to use, the materials on this Web site, even if advised of the possibility of such damages.<br/><br/>
  <span class='term-heading'>JURISDICTION</span><br/>
  Unless otherwise expressly set forth herein, the Company and its Representatives make no representation that materials on this Web site are appropriate or available for use in any location. Those who choose to access this Web site do so at their own initiative. The offer to sell or buy a product is specifically limited to the jurisdiction(s) in which the Company and its Representatives have the authority to offer the insurance products described in this Web site. Some products and services may not be available in all jurisdictions.<br/><br/>
  <span class='term-heading'>TRADEMARKS AND COPYRIGHTS</span><br/>
  All trademarks, service marks, trade names, logos, icons and images are proprietary to the Company or its Representatives or suppliers. Nothing contained on this Web site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the foregoing displayed on this Web site without the written permission of the Company, its Representatives or suppliers, as applicable. Your use of any of the foregoing displayed on this Web site, or any other content on this Web site, except as provided herein, is strictly prohibited and may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.<br/><br/>
  <span class='term-heading'>SOFTWARE LICENSES</span><br/>
  You acknowledge that any software which may be available or provided to you on this Web site may contain technology that is subject to strict controls pursuant to export control laws and regulations of the United States of America and other countries and jurisdictions. You hereby agree that you will not transfer or export such software in violation of such applicable export laws and regulations. Neither the Company, nor its Representatives or suppliers, authorize the downloading or exportation of any software or technical data from this Web site to any jurisdiction prohibited by such export controls laws and regulations.<br/><br/>`,

  PRODUCER_ACKNOWLEDGEMENT: `By clicking the Producer Acknowledgement button below, I state the following: (1) I am (or I am an authorized delegate of) a duly licensed and appointed life insurance producer in the state in which the Application was solicited and in the state in which the policy, if one is issued, will be delivered, (2) the plan and amount of insurance identified is suitable in view of the owner ́s insurance needs and financial objectives, (3) the information provided is complete, accurate and correctly recorded, and (4) all forms required to be delivered at the time of solicitation have been delivered and all other required forms have been or will be provided to the Applicant.<br/><br/>
  I authorize The Lincoln National Life Insurance Company (Lincoln), to obtain such administrative information as may be necessary to complete any life insurance application resulting from this lead submission, provided, however, that any item of information or question from the Applicant requiring the act or advice of a licensed life insurance producer will be referred to me for action before the application can be completed. I further authorize Lincoln to affix my signature to any life insurance application resulting from this form and to any other forms related to such application requiring my signature.<br/><br/>
  I will personally review the application created from this data and administrative information provided by the Applicant and contact him or her concerning any incomplete or inconsistent information and I will not deliver the policy unless I have completed my review and am satisfied that the policy, application and any other forms related to such application, if any, are complete and accurate.<br/><br/>
  I hereby agree, for myself, my heirs, assigns, administrators, executors and successors in interest, to completely release and fully indemnify and hold harmless Lincoln, its officers and directors, agents, successors, heirs and assigns, from any losses, damages, liabilities, or expenses which may arise from Lincoln ́s acceptance and use of my signature on a life insurance application or related form.<br/><br/>
  1. How long have you known the Proposed Insured?<br/>
  2. Are you related to the Proposed Insured? • Y • N<br/>
  3. Does the Proposed Insured and Owner(s) read and understand the English language? • Y • N<br/>
  If "No," how was the application completed?<br/>
  4. Purpose of Insurance:_________________<br/>
  5. Is the Proposed Insured using income from their spouse/domestic partner to financially justify the coverage applied? • Y • N<br/>
  If "Yes", provide the following information for the spouse/domestic partner:<br/>
  (a) Income: _________________<br/>
  (b) Life Insurance (In-force plus any additional to be placed): _________________<br/>
  6. Answer only if the Proposed Insured participates in aviation activities. If underwriting results in a higher premium, indicate which of the following is preferred (check one):<br/>
  • Pay the extra premium for coverage if death results from a covered aviation activity<br/>
  • Aviation Exclusion Rider (not available in all states, and subject to underwriter discretion)<br/><br/>
  I further certify that:<br/><br/>
  I have reviewed with the Proposed Insured each question on the application. For those questions asked by me, the answers have been recorded exactly as stated. For any answers provided by the Proposed Insured during a telephone or online interview and recorded by a third party, I have confirmed with the Proposed Insured that those answers as contained on the application were accurately recorded. I know of nothing affecting the insurability of the Proposed Insured which is not fully recorded in this application;<br/><br/>
  If I become aware of a change in the health or habits of the Proposed Insured occurring after the date of the application but before policy delivery, I will inform The Lincoln National Life Insurance Company (Lincoln) of the change and agree to withhold the policy delivery until instructed by Lincoln;<br/><br/>
  For application states other than MA, I have provided the Applicant and the Proposed Insured with a current copy of Lincoln ́s Important Notice as well as Lincoln ́s Privacy Practices. If the application state is MA, I have provided the Important Notice of Insurance Information Practices as well as Lincoln ́s Privacy Practices Notice;<br/><br/>
  I have verified all life insurance coverage in force, or in the process of being applied for, on the Proposed Insured, including any coverage that has been sold or is in the process of being sold to a life settlement, viatical or other secondary market provider;<br/><br/>
  I have not been involved in any recommendation regarding the possible sale or assignment of this policy to a life settlement, viatical or other secondary market provider;<br/><br/>
  To the best of my knowledge, the source of funding for this policy does not include (1) a non-recourse premium financing loan; or (2) any arrangement, other than a premium financing loan, which involves any person or entity with an interest in the potential earnings based on the provision of funding for the policy;<br/><br/>
  I have asked my client if there is any intention to replace, surrender, borrow against, sell or use any portion of any existing life insurance policy or annuity to finance any portion of the policy being applied for and know of no other replacement than that indicated above. If a replacement is intended, I have given the appropriate replacement forms to the client at the time of solicitation/application;<br/><br/>
  I have obtained sufficient information about the Applicant and the Proposed Insured to mitigate risks associated with money laundering, terrorist activity/funding, and to avoid doing business with a sanctioned individual or resident of a sanctioned country;<br/><br/>
  I have reviewed and I understand Lincoln Financial Group ́s Position Regarding Marijuana-Related Businesses as published in form GB10877;<br/><br/>
  All of the above statements and answers to questions provided in the Producer ́s Attestation, in the connection with this application, are true and accurate.<br/><br/>
  I acknowledge that clicking the Signature Submit button below constitutes my signature on this form and has the same effect as if I personally signed the form.<br/><br/>`
};

export const ESignature = {
  EmailTemplate: `<div class='email-example'>
  <b>From:</b><br/>
  <b>Subject: </b>Action required on your application for a policy with The Lincoln National Life Insurance Company - DO NOT REPLY<br/>
  <div class='divider'></div>
  <div class='email-body'>
  <p>Hello <b>Signing Party</b> (Signature Role),</p>
  <p>Your Application is ready for your review. [You should have received a PIN code in a text message referencing Lincoln Financial Group. This PIN will be needed to access your ticket.] Please click the button below to be directed to your online application.</p>
  <p>Once you have reviewed all forms for accuracy, you may apply your eSignature by following the instructions on the screens.</p>
  <p>If you have any questions, please do not hesitate to contact me at test@lfg.com.</p>
  <p>Thank you for allowing me to handle your financial needs.</p>
  <p>[Optional wording based on the remote signature method selected.]</p>
  </div>
  <div class='divider'></div>
  <div class='consent-container'>
  <p><b>Consent for Electronic Transmissions</b></p>
  <p>If you consent, the Lincoln National Life Insurance Company (the "Company") at LincolnFinancial.com, will transmit documents to you related to your application and policy by electronic means, to the extent that electronic transmission is consistent with applicable state and federal law. Any document that we send by electronic means, which complies with applicable law, will have the same force and effect as if that document was sent in paper format.</p>
  <p>This Consent covers all electronic documents and communications as related to an application for life insurance coverage through the Company, which includes, but is not limited to, applications, supplements, administrative forms and any policy-related correspondence. This Consent also covers all electronic documents and communications as related to a life insurance policy issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.</p>
  <p>In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (For Mac And iOS), or Mozilla Firefox (Windows Or Mac)); a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic copies or print a copy of your documents.</p>
  <p>The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not to opt-in to receive your documents electronically, please contact your agent or the Company by any of the methods shown below.</p>
  <p>If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent electronically upon request. The Company will not charge a fee for this service.</p>
  <p>If you decide that you do not want to receive documents electronically you are unable to apply for the Lincoln TermAccel® product, which is available through an electronic process only.</p>
  <p>If your email account changes, we suggest that you contact your agent or the Company so that the Company's contact information for you remains current and accurate. You may contact us via the Company web page as shown above, or by our toll free telephone number 1-800-487-1485, or by submitting a written request via paper mail with sufficient postage to the Company at 100 N. Green Street, Greensboro, NC 27401.</p>
  <p>If you consent to the terms outlined above for electronic transmissions, click on the Button marked "I consent."</p></div></div>`,
  SignatureListHeading: 'Please specify the signature location of all signing parties.',
  POLICY_OWNER_TRUST_MESSAGE: 'The policy is owned by a Trust.'
};

export const ESignatureEmailTemplate = {
  EmailTemplate: `<p class="font-bold email-head">From:</p>
  <p>Action required on your application for a policy with The Lincoln National Life Insurance Company - DO NOT REPLY</p>
  <p class="font-bold email-head">Email Message:</p>
  <p>Hello Signing Party (Signature Role),<br/>
  Your Application is ready for your review. [You should have received a PIN code in a text message referencing Lincoln Financial Group. This PIN will be needed to access your ticket.] Please click the button below to be directed to your online application.
  Once you have reviewed all forms for accuracy, you may apply your eSignature by following the instructions on the screens.</p>
  <p>If you have any questions, please do not hesitate to contact me at {agentEmail}.</p>
  <p>Thank you for allowing me to handle your financial needs.</p>
  <p>[Optional wording based on the remote signature method selected.]</p>
  <div class="section-divider"></div>
  <p class="font-bold email-head">Consent for Electronic Transmissions</p>
  <p>If you consent, the Lincoln National Life Insurance Company (the "Company") at LincolnFinancial.com, will transmit documents to you related to your application and policy by electronic means, to the extent that electronic transmission is consistent with applicable state and federal law. Any document that we send by electronic means, which complies with applicable law, will have the same force and effect as if that document was sent in paper format.</p>
  <p>This Consent covers all electronic documents and communications as related to an application for life insurance coverage through the Company, which includes, but is not limited to, applications, supplements, administrative forms, 1099's, in-force illustrations, sales ledgers, prospectuses, prospectus supplements, annual and semiannual reports, confirmation statements, annual or quarterly statements, Statement of Additional Information, billing notices, billing-push notifications, and any policyrelated correspondence. This Consent also covers all electronic documents and communications as related to a life insurance policy issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.</p>
  <p>The Company may communicate your document's availability by email, text or other electronic means or may attach your documents within the communication itself.</p>
  <p>In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (For Mac And iOS), or Mozilla Firefox (Windows Or Mac)); a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic copies or print a copy of your documents.</p>
  <p>The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not to opt-in to receive your documents electronically, please contact your agent or the Company by any of the methods shown below.</p>
  <p>If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent electronically upon request. The Company will not charge a fee for this service.</p>
  <p>If you decide that you do not want to receive documents electronically you are unable to apply for this product, which is available through an electronic process only.</p>
  <p>If your email account, or phone number for text messaging changes, we suggest that you contact your agent or the Company so that the Company's contact information for you remains current and accurate. You may contact us via the Company web page as shown above, or by our toll free telephone number 1-800-487-1485, or by submitting a written request via paper mail with sufficient postage to the Company at 100 N. Greene Street, Greensboro, NC 27401.</p>
  <p>If you consent to the terms outlined above for electronic transmissions, click on the button marked "I consent."</p>
  <p>For purposes of receiving electronically transmitted documents from The Lincoln National Life Insurance Company, your email address is set forth below.</p>`
};

export const AttestationMsg = {
  DELEGATE_INFO: `Your agency has opted to use Lincoln's On Behalf Of functionality. You agree that you will provide the answers to the online questions to complete the required electronic ticket on behalf of the licensed life insurance producer. You also agree that any item of information or question from the Applicant which requires the act or advice of a licensed life insurance producer will be referred to the licensed life insurance producer for action before the electronic ticket is completed. You agree that any form requiring a licensed life insurance producer's signature will be routed to the licensed life insurance producer for their signature.`,
};

export const SignatureMethodOptions = [
  {
    description: 'In-Person',
    value: 'inperson'
  },
  {
    description: 'Remote using 4-digit SSN/PIN',
    value: 'remote'
  }
];

export const ElectronicSignatures = {
  Mail_Sent: 'Your request was completed successfully. An email notification will be sent to the appropriate signers.',
  Instructional_Info_Text: 'The "Send Emails" option will be enabled when a singer declines to sign, or the agent cancels the packet. When the "Send Email" option is selected, a new email with electric signature instructions will be sent to each signer. The agent email will be sent when all additional signers complete the signing process.',
  Required_Message: 'To update the Email address and/or Cell Phone Number below, you will need to unlock the application and make the appropriate change and then relock the application to send the Emails or send Emails/text PIN codes.',
  View_Invitation_Link: 'View eSignature invitation',
  Packet_Success_Msg: 'Packet sent to all signers successfully.',
  Packet_Success_Msg_2: 'Your request was submitted successfully.',
  Packet_Exist_Message: 'The packet submission history section allows you to track when forms were submitted and completed by each signer. You can resend an email or cancel a packet.',
  No_Packet_Exist: 'No packet history to show.',
  Next_Button_Click_Message: 'Please select the "Next" button to continue.',
  Resend_packet_heading: 'Resend packet',
  Resend_packet_info_text: 'Select recipient(s) below and click resend. If a recipient\'s email has changed, enter the new email address in the space provided before resending the email.',
  Cancel_modal_heading: 'Are you sure?',
  Cancel_modal_info_1: 'Are you sure you want to cancel the packet?',
  Cancel_modal_info_2: 'By canceling the packet, the signing process will end and the remaining signers will not be allowed to sign the forms that are in the packet.'
};

export const ESignatureInstructions = {
  Signers_List_Message: 'eSignature emails will be sent to the signing parties below:',
  EmailError: 'Email address doesn\'t match',
  ESignature_Instructions_Message: 'The eSignature process requires each eSigner to review the application online and agree to the series of disclosure and disclaimer statements, insert the city, where he/she is located and apply this as his/her signature.<br/><br/>Upon careful review of all information, each eSigner will be instructed to click a of "I Agree" statements.<br/><br/>This will serve as his/her electronic signature. A secure process has been put in place to ensure his/her personal information and the signature process is confidential and secure.'
};

export const OPTIONAL_TABS = [
  TAB.OWNER_INFO,
  TAB.TRUST_INFO,
  TAB.ELECTRONIC_FUND_TRANSFER,
  TAB.LTC_INSURANCE,
  TAB.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION,
  TAB.ILLUSTRATION_INFORMATION,
  TAB.DOLLAR_COST_AVERAGING,
  TAB.WA_REPLACEMENT,
  TAB.WA_REPLACEMENT_PROPOSED_B,
  TAB.PROPOSED_INSURED_B,
  TAB.EXISTING_INSURANCE_FOR_PROPOSED_B
];

export const TABS_RELATED_TO_VOICE_SIGNATURE = [
  TAB.VOICE_TERM_OF_USE
];

export const TABS_ALWAYS_IN_GOOD_ORDER = [
  TAB.ELECTRONIC_SIGNATURE,
  TAB.REVIEW_AND_SUBMIT,
  TAB.VOICE_CONFIRM_THANK_YOU
];

export const TABS_EXCLUDED_FROM_GET_QUESTIONS = [
  TAB.ELECTRONIC_SIGNATURE,
  TAB.REVIEW_AND_SUBMIT,
  TAB.VOICE_CONFIRM_THANK_YOU
];

export const TABS_AFTER_LOCK_SCREEN = [
  TAB.SIGNATURE_METHOD,
  TAB.VOICE_TERM_OF_USE,
  TAB.VOICE_CONFIRM_THANK_YOU,
  TAB.E_SIGNATURE_INSTRUCTION,
  TAB.AGENT_INSTRUCTION,
  TAB.ELECTRONIC_SIGNATURE,
  TAB.CONSENT_PAGE_AGENT,
  TAB.CONSENT_PAGE_PI,
  TAB.CONSENT_PAGE_INSURED_OWNER,
  TAB.CONSENT_PAGE_INSURED_B,
  TAB.CONSENT_PAGE_ADDITIONAL_INSURED,
  TAB.CONSENT_PAGE_COOWNER,
  TAB.CONSENT_PAGE_COOWNER2,
  TAB.CONSENT_PAGE_COOWNER3,
  TAB.CONSENT_PAGE_OWNER,
  TAB.REVIEW_AND_SUBMIT
];

export const ExcludeTabsFromLock = [
  ...TABS_AFTER_LOCK_SCREEN,
  TAB.VALIDATE_AND_LOCK
];

export const ExcludeTabsFromValidation = [
  ...ExcludeTabsFromLock,
  TAB.APPLICATION_SUMMARY,
];

export const QuestionTextIncludes = [
  'because', 'how'
];

export const AgentThankYou = {
  AGENT_HEADER: 'Thank you',
  INFO_TEXT_1:
    'Thank you for completing the signing process. The signing process is completed for all signers.',
  INFO_TEXT_2: 'Select NEXT to review and submit your case.',
  THANK_YOU: 'Thank you!',
};

export const CaseCompletionThankYou = {
  AGENT_HEADER: 'Thank you',
  INFO_TEXT_1:
    'Your request was submitted successfully.',
  INFO_TEXT_2: 'An email notification will be sent to the applicant to start the next step.',
  THANK_YOU: 'Thank you!',
};

export const ConsumerThankYou = {
  CONSUMER_HEADER: 'Thank you',
  INFO_TEXT_1:
    'Your signing process was completed successfully.',
  INFO_TEXT_2: 'Once the signing process is completed for all signers, the insured will be sent an email to start the next step.',
  THANK_YOU: 'Thank you!',
};

export const ReviewAndSubmit = {
  PAGE_HEADER: 'Signing Process Completed',
  INFO_TEXT_1: 'Thank you for completing the signing process. The signing process is completed for all signers.',
  INFO_TEXT_2: 'Select SUBMIT to submit your case to Lincoln Financial. Once the case has been submitted an email will be sent to the insured to start the next step.',
  THANK_YOU: 'Thank you!'
};

export const DeclineToSign = {
  DECLINE_TO_SIGN_HEADER: 'Decline e-Signature Confirmation',
  INFO_TEXT_1: 'The eSignature process for this application has been declined and canceled.',
  INFO_TEXT_2: 'Please contact your representative with any questions or concerns regarding your application.',
  INFO_TEXT_3: 'Please close the window/browser.'
};


export const ConsentConstant = {
  SAFARI_USER_SETTING_MSG_1: 'Before selecting the "Start eSignature" option please verify the setting instructions below.',
  SAFARI_USER_SETTING_MSG_2: 'From the Safari menu, choose browser settings, and select privacy.',
  SAFARI_USER_SETTING_MSG_3: 'Ensure the Prevent Cross-site Tracking option is not selected.',
  START_SIGNATURE_INFO: 'When ready to proceed and electronically sign your forms, click the "Start eSignature" button below.',
  TERM_OF_USE: `<strong>TERMS OF USE</strong><br/><br/>
    <span class='term-heading'>CONDITIONS OF USE</span><br/>
    By using this Web site in relation to an application for insurance with The Lincoln National Life Insurance Company, hereinafter collectively referred to as "the Company", you agree with the following Terms and Conditions Of Use ("Terms") without limitation or qualification. Please read these Conditions carefully before using this Web site. If you do not agree with these Terms, you are not granted permission to use this Web site and must exit this Web site immediately. The Company may revise these Terms at any time by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the current Terms governing this Web site.<br/><br/>
    <span class='term-heading'>DISCLAIMER</span><br/>
    TO THE FULLEST EXTENT PERMISSIBLE, THE MATERIALS ON THIS WEB SITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, AND THE COMPANY, AND ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (WHICH SHALL INCLUDE CAREER AGENTS AND BROKERS) (COLLECTIVELY "REPRESENTATIVES") AND SUPPLIERS DISCLAIM ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT THAT THE FUNCTIONS CONTAINED IN THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS WEB SITE, OR THE SERVER THAT MAKES IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THIS WEB SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION. THE INFORMATION AND DESCRIPTIONS CONTAINED HEREIN ARE NOT NECESSARILY INTENDED TO BE COMPLETE DESCRIPTIONS OF ALL TERMS, EXCLUSIONS AND CONDITIONS APPLICABLE TO THE PRODUCTS AND SERVICES, BUT ARE PROVIDED SOLELY FOR GENERAL INFORMATIONAL PURPOSES; PLEASE REFER TO THE ACTUAL POLICY OR THE RELEVANT PRODUCT OR SERVICE AGREEMENT; THIS WEB SITE MAY BE LINKED TO OTHER WEB SITES WHICH ARE NOT MAINTAINED BY THE COMPANY. NEITHER THE COMPANY, NOR ITS REPRESENTATIVES OR SUPPLIERS, ARE RESPONSIBLE FOR THE CONTENT OF THOSE WEB SITES. THE INCLUSION OF ANY LINK TO SUCH WEB SITES DOES NOT IMPLY APPROVAL OF, OR ENDORSEMENT BY, THE COMPANY, OR ANY OF ITS REPRESENTATIVES OR SUPPLIERS, OF THE WEB SITES OR THE CONTENT THEREOF.<br/><br/>
    <span class='term-heading'>LIMITATION OF LIABILITY</span><br/>
    While the Company and its Representatives or suppliers use reasonable efforts to include accurate and up-to-date information on this Web site, errors or omissions may occur. Under no circumstances shall the Company, or its Representatives or suppliers, be liable to you for any direct, incidental, consequential, indirect, or punitive damages that result from the use of, or the inability to use, the materials on this Web site, even if advised of the possibility of such damages.<br/><br/>
    <span class='term-heading'>JURISDICTION</span><br/>
    Unless otherwise expressly set forth herein, the Company and its Representatives make no representation that materials on this Web site are appropriate or available for use in any location. Those who choose to access this Web site do so at their own initiative. The offer to sell or buy a product is specifically limited to the jurisdiction(s) in which the Company and its Representatives have the authority to offer the insurance products described in this Web site. Some products and services may not be available in all jurisdictions.<br/><br/>
    <span class='term-heading'>TRADEMARKS AND COPYRIGHTS</span><br/>
    All trademarks, service marks, trade names, logos, icons and images are proprietary to the Company or its Representatives or suppliers. Nothing contained on this Web site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of the foregoing displayed on this Web site without the written permission of the Company, its Representatives or suppliers, as applicable. Your use of any of the foregoing displayed on this Web site, or any other content on this Web site, except as provided herein, is strictly prohibited and may violate copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes.<br/><br/>
    <span class='term-heading'>SOFTWARE LICENSES</span><br/>
    You acknowledge that any software which may be available or provided to you on this Web site may contain technology that is subject to strict controls pursuant to export control laws and regulations of the United States of America and other countries and jurisdictions. You hereby agree that you will not transfer or export such software in violation of such applicable export laws and regulations. Neither the Company, nor its Representatives or suppliers, authorize the downloading or exportation of any software or technical data from this Web site to any jurisdiction prohibited by such export controls laws and regulations.<br/><br/>`,
  CONSENT_EXCEPT_TERM_WEALTH: `If you consent, The Lincoln National Life Insurance Company (the "Company") at www.LFG.com, will transmit documents to you
    related to your application and policy by electronic means, to the extent that electronic transmission is consistent with applicable
    state and federal law. Any document that we send by electronic means, which complies with applicable law, will have the same
    force and effect as if that document was sent in paper format.<br><br>
    This Consent covers all electronic documents and communications as related to an application for life insurance coverage
    through the Company, which includes, but is not limited to, applications, supplements, administrative forms and any policy-related
    correspondence. This Consent also covers all electronic documents and communications as related to a life insurance policy
    issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.<br><br>
    In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device
    supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for
    Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (for Mac and iOS), or Mozilla Firefox (Windows or Mac);
    a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic
    copies or print a copy of your documents.<br><br>
    The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required
    to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic
    transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not
    to consent to receive your documents electronically, please contact your agent or the Company.<br><br>
    If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent
    electronically upon request. The Company will not charge a fee for this service.<br><br>
    If you want to revoke the consent to receive the documents noted above electronically and want to receive all future documents
    via paper mail, you may notify the Company by any one of the methods shown below; otherwise the consent will continue until
    revoked.<br><br>
    If your email account changes, we suggest that you contact your agent or the Company so that the Company's contact information
    for you remains current and accurate. You may contact us via the Company web page as shown above, or by our toll free
    telephone number 1-800-487-1485, or by submitting a written request via paper mail with sufficient postage to the Company at
    100 N. Green Street, Greensboro, NC 27401.<br><br>
    If you consent to the terms outlined above for electronic transmissions, click on the button marked "I consent." <br><br>`,

  CONSENT_FOR_TERM_WEALTH: `If you consent, The Lincoln National Life Insurance Company (the "Company") at LincolnFinancial.com, will transmit documents
    to you related to your application and policy by electronic means, to the extent that electronic transmission is consistent with
    applicable state and federal law. Any document that we send by electronic means, which complies with applicable law, will have
    the same force and effect as if that document was sent in paper format.<br><br>
    This Consent covers all electronic documents and communications as related to an application for life insurance coverage
    through the Company, which includes, but is not limited to, applications, supplements, administrative forms, 1099's, in-force
    illustrations, sales ledgers, prospectuses, prospectus supplements, annual and semiannual reports, confirmation statements,
    annual or quarterly statements, Statement of Additional Information, billing notices, billing-push notifications, and any policy-related correspondence. This Consent also covers all electronic documents and communications as related to a life insurance
    policy issued to you by the Company, which includes, but is not limited to the policy and policy delivery notices.<br><br>
    The Company may communicate your document\'s availability by email, text or other electronic means or may attach your
    documents within the communication itself.<br><br>
    In order to successfully receive electronic transmissions using this web site, it is recommended that your electronic device
    supports Windows® 8 or above, or Macintosh OSX; Adobe Acrobat Reader; has browser settings such as Microsoft Edge (for
    Windows, Mac and iOS), Google® Chrome® (Windows only), Apple Safari (for Mac and iOS), or Mozilla Firefox (Windows or Mac);
    a valid email address and security settings that allow per session cookies. The Company recommends that you keep electronic
    copies or print a copy of your documents.<br><br>
    The Company will only transmit documents to you electronically if you consent. Such consent is voluntary. You are not required
    to consent to electronic transmissions if you prefer not to do so. If the policyholder or insured or owner has permitted electronic
    transmissions in the past, that authorization does not obligate the same procedure regarding this policy as well. If you choose not
    to opt-in to receive your documents electronically, please contact your agent or the Company by any of the methods shown below.<br><br>
    If you consent to receive documents electronically, the Company will provide you with a paper copy of any document sent
    electronically upon request. The Company will not charge a fee for this service.
    <br><br>
    If you decide that you do not want to receive documents electronically you are unable to apply for this product, which is available
    through an electronic process only.<br><br>
    If your email account, or phone number for text messaging changes, we suggest that you contact your agent or the Company so
    that the Company's contact information for you remains current and accurate. You may contact us via the Company web page
    as shown above, or by our toll free telephone number 1-800-487-1485, or by submitting a written request via paper mail with
    sufficient postage to the Company at 100 N. Greene Street, Greensboro, NC 27401.<br><br>
    If you consent to the terms outlined above for electronic transmissions, click on the button marked "I consent."<br>`,

  CONSENT_INSTRUCTION: `<ol><li>Please review your forms in their entirety for accuracy and to make sure you completely understand and agree with the information provided.</li><li>If you need to change or update any information or if you have questions, please contact your producer before proceeding.</li>
    <li>The Company will only transmit documents to you electronically if you consent. The receipt of your electronically signed documents by the Company will demonstrate that you can access this application in the electronic form provided to you by the Company website.</li>
    <li>I will read all documents that are to be transmitted and electronically signed. I will select the 'I will review' checkbox and 'I Agree' button if I consent to this process. Otherwise I will select the 'I Decline' button.</li></ol>`,

  CONSENT_INFO: `To begin the electronic process, please read the <strong>Consent for Electronic Transmissions</strong> and indicate below if you agree or decline to the terms. We recommend you print and retain a copy of these documents for future reference.`,
  CONSENT_INFO_2: `After reading the document by scrolling to the bottom, please check the corresponding box indicating you have read the document. Then select either "I Agree" or "I Decline". Please scroll to the bottom of each document to access the check boxes.`,
  TERM_OF_USE_TITLE: 'TERMS AND CONDITIONS:',
  CONSENT_ESIGN_TITLE: 'CONSENT FOR ELECTRONIC TRANSMISSIONS:',
  WELCOME_MSG: 'Welcome, <strong>{name}</strong>, {email},',
  INFO_TEXT_1: 'Please do not close this window or click the back button on your browser until the eSignature process is complete or case has been submitted. After you have completed the eSignature process you will be able to view your completed application and save/print a copy.',
  DECLINE_MODAL_HEADING: 'Signature Process Declined',
  DECLINE_INFO_LABEL: 'If you wish to apply electronically please indicate that you have read the Terms of Use and Consent to do Business Electronically.',
  DECLINE_INFO: `By clicking the "Yes, Cancel eSignature Process" button below, you will exit out of the eSignature process. Your agent will be able to address the concerns you may have.`,
  DECLINE_INFO2: `By clicking the "No, Resume eSignature Process" button below, you will be returned to the eSignature process.`,
  DECLINE_CONFIRMATION_MSG: 'Do you wish to decline and cancel the electronic signature process?',
  CONFIRM_BUTTON: 'Yes, Cancel eSignature Process',
  DECLINE_BUTTON: 'No, Resume eSignature Process'
};

export const RedirectConfirmationMessages = {
  MODAL_HEADING: 'Are you sure?',
  INFO_TEXT_1: 'You have unsaved information.',
  INFO_TEXT_2: 'You will lose any changes you\'ve made on this page.',
  INFO_TEXT_3: 'To save updated information, select the Cancel option. Select the Save & Exit option to access the dashboard.'
};

export const SignatureMethodConfirmationModalMessages = {
  MODAL_HEADING: 'Review Signer Information',
  INFO_TEXT_1: `The signer's information will be used during the forms signing process.`,
  INFO_TEXT_2: `Email address will be used to send the link to start the eSigning process.`,
  INFO_TEXT_3: `The last four digits of the SSN will be used to authenticate each signer.`,
  INFO_TEXT_4: `The phone number will be used to send pin code via text or automated voice call.`
};

export const AllowedStatesForMGMA22 = ['IN', 'MT'];
