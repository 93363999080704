<div class="grid-structure-page">
  <form [formGroup]="mainFormGroup" id="mainFormGroup" method="post" *ngIf="mainFormGroup">
    <lfg-accordion [autoScroll]="true">
      <lfg-panel [title]="accordionHeader" name="accordion" *ngFor="let grid of gridSequences; let i = index;" [formArrayName]="grid.gridId" class="{{grid.gridId + '-' + i}}" [opened]="openAccordian[i]">
        <span class="accordion__sub-title-hook policy-grid-container" *ngIf="type == 'POLICY'">
          <span class="desktop-acdn-header desktop-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [grid]="grid" [gridSequences]="gridSequences" [index]="i" [type]="'POLICY_DESKTOP_ICON'" [subType]="subType" (removeGrid)="removeGrid($event)" [disableForm]="disableForm" [existingInsAnswers]="existingInsAnswers" [forProposedInsured]="forProposedInsured"></app-grid-header>
          </span>
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [grid]="grid" [gridSequences]="gridSequences" [index]="i" [type]="'POLICY_DESKTOP'" [subType]="subType" (removeGrid)="removeGrid($event)" [disableForm]="disableForm" [existingInsAnswers]="existingInsAnswers" [forProposedInsured]="forProposedInsured"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [grid]="grid" [gridSequences]="gridSequences" [index]="i" [type]="'POLICY_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm" [forProposedInsured]="forProposedInsured"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [grid]="grid" [gridSequences]="gridSequences" [index]="i" [type]="'POLICY_MOBILE_INFO'" [subType]="subType" [disableForm]="disableForm" [existingInsAnswers]="existingInsAnswers" [forProposedInsured]="forProposedInsured"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'AGENT'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'AGENT_DESKTOP'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'AGENT_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'AGENT_MOBILE_INFO'" [disableForm]="disableForm"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'INSURANCE'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'INSURANCE_DESKTOP'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'INSURANCE_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'INSURANCE_MOBILE_INFO'" [disableForm]="disableForm"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'EXISTING_INS'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'EXISTING_INS_DESKTOP'" [pageName]="pageName" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'EXISTING_INS_MOBILE_ICON'" [pageName]="pageName" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'EXISTING_INS_MOBILE_INFO'" [pageName]="pageName" [disableForm]="disableForm"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'OWNER'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'OWNER_DESKTOP'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'OWNER_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'OWNER_MOBILE_INFO'" [disableForm]="disableForm"></app-grid-header>
          </div>
        </span>

        <span class="accordion__sub-title-hook" *ngIf="type == 'TRUST'">
          <span class="desktop-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'TRUST_DESKTOP'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <span class="mobile-acdn-header mobile-icon-container" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'TRUST_MOBILE_ICON'" (removeGrid)="removeGrid($event)" [disableForm]="disableForm"></app-grid-header>
          </span>
          <div class="mobile-acdn-header" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">
            <app-grid-header [gridForm]="gridForm" [grid]="grid" [isGridValid]="gridStatusVal[grid.gridUniqueId]" [gridSequences]="gridSequences" [index]="i" [type]="'TRUST_MOBILE_INFO'" [disableForm]="disableForm"></app-grid-header>
          </div>
        </span>

        <div [formGroupName]="grid.gridIndex" *ngIf="mainFormGroup.get(grid.gridId)['controls'][grid.gridIndex] as gridForm">

          <div class="loader-container" *ngIf="showLoader">
            <lfg-loader></lfg-loader>
          </div>

          <ng-container class="form-control-parent" *ngFor="let data of gridQuestionsObj[grid.gridId].gridQuestions; let ind = index;">

            <ng-container *ngIf="!data.notes || (data.notes.indexOf('INTERNAL_COMPANY_QUESTION') === -1)">
              <!-- Questions headers -->
              <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.display && data.questionText != 'Line' && data.subText != 'Information' && data.notes !== 'Hidden' && data.questionText != 'Search'">
                <h2 class="grid-ques" [ngClass]="{'remove-margin-bottom' : data.subText }">{{ data.questionText }}</h2>
                <p class="ques-content" *ngIf="data.subText">{{ data.subText }}</p>
              </div>

              <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line' && data.display">
              </div>

              <!-- Form element for zip  -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_ZIPCODE'">
                <lfg-input-wrap>
                  <input lfgInput mask='00000-0000' aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="number" maxlength="10" [required]="data.required == 'true'">
                  <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                </lfg-input-wrap>
              </div>

              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1) && data.notes !== 'Hidden' && data.notes != 'ITES_FORMAT_AS_ZIPCODE'" [ngClass]="{'inline-ques': data.questionText.length >= 27 }">
                <ng-container *ngIf="data.questionText.length >= 27">
                  <lfg-input-wrap *ngIf="data.notes === 'ITES_PERCENTAGE'">
                    <div class="inline-ques-container">
                      <span class="inline-ques-text">{{ addCorrectPlaceHolder(data, 'placeHolder') }}</span>
                      <label for="{{data.fieldId}}">
                        <span class="visually-hidden">Label for the input</span>
                        <input lfgInput class="inline-ques-input" appPercentageFormat aria-live="off" [id]="data.fieldId" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                        <div class="inline-error">
                          <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                        </div>
                      </label>
                    </div>
                  </lfg-input-wrap>
                  <lfg-input-wrap *ngIf="data.notes !== 'ITES_PERCENTAGE'">
                    <div class="inline-ques-container">
                      <span class="inline-ques-text">{{ addCorrectPlaceHolder(data, 'placeHolder') }}</span>
                      <label for="{{data.fieldId}}">
                        <span class="visually-hidden">Label for the input</span>
                        <input lfgInput class="inline-ques-input" aria-live="off" [id]="data.fieldId" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                        <div class="inline-error">
                          <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                        </div>
                      </label>
                    </div>
                  </lfg-input-wrap>
                </ng-container>
                <ng-container *ngIf="data.questionText.length < 27">
                  <lfg-input-wrap *ngIf="data.notes !== 'ITES_PERCENTAGE'">
                    <input lfgInput placeholder="{{addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'" (input)="checkIfFirmSearchIsValid(gridForm, grid.gridUniqueId, data.xmlTag)">
                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                  <lfg-input-wrap *ngIf="data.notes == 'ITES_PERCENTAGE'">
                    <input lfgInput appPercentageFormat placeholder="{{addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'" (input)="checkIfFirmSearchIsValid(gridForm, grid.gridUniqueId, data.xmlTag)">
                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </ng-container>
              </div>

              <!-- Form element for Drop Down -->
              <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag != AGENTCONST.secAgentFirmId && data.xmlTag != CONST.policyBeingQuesId && data.display" [ngClass]="{'display-full': data.xmlTag !== trustInfoConstant.state}">
                <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, grid.gridId ,grid.gridIndex, grid.gridUniqueId, gridForm); handleInsurerSelectionChange(data, grid.gridId ,grid.gridIndex, grid.gridUniqueId, gridForm)" [required]="data.required == 'true'">
                </lfg-dropdown>
                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
              </div>

              <!-- Form element for Company info (Existing insurance ) -->
              <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag === CONST.existingCompanyNameXml && data.gridId === 'ETICKET-ExistInsu-Grid' && isCompanyInfoRequired">
                <lfg-alert-message type="info">{{ Message.INTERNAL_COMPANY_INFO }}</lfg-alert-message>
              </div>

              <!-- Form element for Policy being Drop Down -->
              <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag == CONST.policyBeingQuesId && data.display">
                <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="policyBeingDropdown" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, grid.gridId ,grid.gridIndex); policyBeingUpdated(gridForm.get(data.fieldId), gridForm, grid.gridUniqueId)" [required]="data.required == 'true'">
                </lfg-dropdown>
                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
              </div>

              <!-- Form element for currency  -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1)" [ngClass]="{'inline-ques': data.questionText.length >= 28 }">
                <ng-container *ngIf="data.questionText.length >= 28">
                  <lfg-input-wrap>
                    <div class="inline-ques-container">
                      <span class="inline-ques-text">{{ addCorrectPlaceHolder(data, 'placeHolder') }}</span>
                      <label for="{{data.fieldId}}">
                        <span class="visually-hidden">Label for the input</span>
                        <input lfgInput aria-live="off" [id]="data.fieldId" class="inline-ques-input" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="data.maxLength == '-1' ? '9': data.maxLength" [required]="data.required == 'true'">
                        <div class="inline-error">
                          <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                        </div>
                      </label>
                    </div>
                  </lfg-input-wrap>
                </ng-container>
                <ng-container *ngIf="data.questionText.length < 28">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{addCorrectPlaceHolder(data, 'placeHolder')}}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="data.maxLength == '-1' ? '9': data.maxLength" [required]="data.required == 'true'">
                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </ng-container>
              </div>

              <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE && (data.xmlTag == trustInfoConstant.TRUST_PRIMARY_PHONE ||  data.xmlTag == trustInfoConstant.TRUST_CELL_NUMBER)">
                <lfg-alert-message type="info">{{ phoneInfo }}</lfg-alert-message>
              </div>

              <!-- Form element for phone number -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE && data.display">
                <lfg-input-wrap>
                  <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
                  <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Form element for radio question -->
              <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.display" [ngClass]="{'radio--err': (gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid), 'question-radio--medium-inline': data.xmlTag == trustInfoConstant.phoneType}">
                <span class="lfg-radio-section">
                  <p class="question-radio_text">{{ data.questionText }}</p>
                  <div class="radio-btn-rect radio-button-reflexive">
                    <input [attr.aria-label]="data.questionOption[0]?.description" tabindex="0" id="yes_{{ data.fieldId }}_{{i}}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0]?.value }}" required="{{data.required}}" (change)="onRadioChange(data, grid.gridId, grid.gridIndex)" />
                    <label for="yes_{{ data.fieldId }}_{{i}}">{{ data.questionOption[0]?.description }}</label>
                    <input [attr.aria-label]="data.questionOption[1]?.description" tabindex="0" id="no_{{ data.fieldId }}_{{i}}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1]?.value }}" required="{{data.required}}" (change)="onRadioChange(data, grid.gridId, grid.gridIndex)" />
                    <label for="no_{{ data.fieldId }}_{{i}}">{{ data.questionOption[1]?.description }}</label>
                  </div>
                </span>
                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
              </div>

              <!-- Form element for firm Drop Down -->
              <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag == AGENTCONST.secAgentFirmId && data.display">
                <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="firmOptions[grid.gridUniqueId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, grid.gridId ,grid.gridIndex); changeAgentNpn(data, gridForm, grid.gridUniqueId)" [required]="data.required == 'true'" [ngClass]="!enableFirmDropDown[grid.gridUniqueId] ? 'lfg-dropdown--disabled' : ''">
                </lfg-dropdown>
                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid && enableFirmDropDown[grid.gridUniqueId]" message="Please select a valid option."></lfg-error>
              </div>

              <!-- Form Element for date  -->
              <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB && data.display">
                <lfg-calendar (focusin)="validateOnFocus(data, 'in', gridForm)" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out', gridForm)" lfgCheckValidDate [formControl]="gridForm.get(data.fieldId)" [config]="data.questionText.indexOf('Issue Date') > -1 ? ( data.required === 'true' ? issueDateConfig : issueDateConfigOpt) : data.questionText.indexOf('Issue') > -1 ? dateOfIssueConfig : dateConfig" [class.calendar--err]="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
                  <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
                </lfg-calendar>
              </div>

              <!-- Form element for search Button -->
              <div class="form--control form--length-btn search-btn" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.display && data.questionText === 'Search'">
                <button id="{{displayIdCreation(data)}}_{{ind}}" (click)="loadFirmDetails(gridForm, grid.gridUniqueId); focusOff($event);" class="btn focusOffBtn" [disabled]="!agentFirmSearchValid[grid.gridUniqueId] || disableForm">{{data.questionText}}</button>
              </div>

              <!-- Form element for alphanumeric  -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC && data.display">
                <lfg-input-wrap>
                  <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

                  <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Form element for SSN -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN && data.display && data.subText !== 'lastFourDigitSSN' && data.xmlTag !== AGENTCONST.secAgentNPN">
                <lfg-input-wrap>
                  <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='gridForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur [required]="data.required == 'true'" (input)="checkIfFirmSearchIsValid(gridForm, grid.gridUniqueId)">
                  <lfg-error *ngIf="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid) && ( gridForm.get(data.fieldId).errors == null || (gridForm.get(data.fieldId).errors != null && !gridForm.get(data.fieldId).errors.notUnique))" message="{{ getErrorMsg(data) }}"></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Form element for SSN -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN && data.display && data.subText == 'lastFourDigitSSN'">
                <lfg-input-wrap>
                  <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" [formControl]='gridForm.get(data.fieldId)' autocomplete="off" appNoSpecialSymbols customAttr="numbersWithHyphen" lfgStopPaste name='pwd' value="" type='password' appValidateOnBlur minlength="4" maxlength='4' [required]="data.required == 'true'">
                  <lfg-error *ngIf="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid) && ( gridForm.get(data.fieldId).errors == null || (gridForm.get(data.fieldId).errors != null && !gridForm.get(data.fieldId).errors.notUnique))" message="{{ getErrorMsg(data) }}"></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Form element for email  -->
              <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL && data.display && data.display">
                <lfg-input-wrap>
                  <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">
                  <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
                </lfg-input-wrap>
              </div>

              <!-- Question Info -->
              <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information' && data.display">
                <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
              </div>

              <!-- Nestion question Level 1 -->
              <div *ngIf="data.hasReflexive == 'true' && checkIfQuestionDisplayed(gridForm, data) && gridForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(gridForm, data) }">
                <ng-container *ngFor="let gridInfoChild1 of data.reflexiveQuestionAL; index as gridInfoChild1ix">
                  <ng-container *ngIf="nestedQuestionCheckgridInfo(data, gridInfoChild1, parentAnswer)">

                    <div class="question-info" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild1.subText == 'Information'">
                      <lfg-alert-message type="info">{{ gridInfoChild1.questionText }}</lfg-alert-message>
                    </div>

                    <!-- Form element for checkbox  -->
                    <div class="form--control form--control__full-width" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
                      <div class="lfg-checkbox">
                        <input id="{{ gridInfoChild1.fieldId }}" type="checkbox" value="checked" name="{{ gridInfoChild1.fieldId }}" formControlName='{{ gridInfoChild1.fieldId }}' [required]="gridInfoChild1.required == 'true'" (change)="onCheckboxChange(gridInfoChild1, grid.gridId, grid.gridIndex, gridForm)">
                        <label class="wrap wrap-check" for="{{ gridInfoChild1.fieldId }}">{{gridInfoChild1.questionText}}</label>
                      </div>
                    </div>

                    <!-- Questions headers -->
                    <div class="questions-header" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && (gridInfoChild1.questionText != 'Line' && gridInfoChild1.subText != 'Information')">
                      <h2 class="grid-ques">{{ gridInfoChild1.questionText }}</h2>
                    </div>

                    <div class="section-divider" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild1.questionText == 'Line'">
                    </div>

                    <!-- Form element for text  -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (!gridInfoChild1.notes || gridInfoChild1.notes.indexOf('ITES_CURRENCY_USD') == -1)">
                      <lfg-input-wrap>
                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(gridInfoChild1.answerTextType)" [maxlength]="gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">
                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <!-- Form element for alphanumeric  -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                      <lfg-input-wrap>
                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">

                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <!-- Form element for radio question -->
                    <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)}">
                      <span class="lfg-radio-section" [ngClass]="{'radio-med': gridInfoChild1.questionText.indexOf('landline') > -1}">
                        <p class="question-radio_text">{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}</p>
                        <div class="radio-btn-rect radio-button-reflexive">
                          <input [attr.aria-label]="gridInfoChild1.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild1.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild1.fieldId }}" name="{{ gridInfoChild1.fieldId }}" value="{{ gridInfoChild1.questionOption[0].value }}" required="{{gridInfoChild1.required}}" (change)="onRadioChange(gridInfoChild1, grid.gridId, grid.gridIndex)" />
                          <label for="yes_{{ gridInfoChild1.fieldId }}_{{i}}">{{ gridInfoChild1.questionOption[0].description }}</label>
                          <input [attr.aria-label]="gridInfoChild1.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild1.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild1.fieldId }}" name="{{ gridInfoChild1.fieldId }}" value="{{ gridInfoChild1.questionOption[1].value }}" required="{{gridInfoChild1.required}}" (change)="onRadioChange(gridInfoChild1, grid.gridId, grid.gridIndex)" />
                          <label for="no_{{ gridInfoChild1.fieldId }}_{{i}}">{{ gridInfoChild1.questionOption[1].description }}</label>
                        </div>
                      </span>
                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild1, gridForm.get(gridInfoChild1.fieldId)) }}"></lfg-error>
                    </div>

                    <!-- Form element for Drop Down -->
                    <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.SELECT ">
                      <lfg-dropdown formControlName="{{ gridInfoChild1.fieldId }}" id="{{displayIdCreation(gridInfoChild1)}}_{{gridInfoChild1ix}}" [options]="alldropDownOptions[gridInfoChild1.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild1, grid.gridId ,grid.gridIndex); updateAddressSameAsOwner(gridForm, gridForm.get(gridInfoChild1.fieldId), gridInfoChild1, grid.gridUniqueId)" [required]="gridInfoChild1.required == 'true'" [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)}">
                      </lfg-dropdown>
                      <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="Please select a valid option."></lfg-error>
                    </div>

                    <!-- Form element for SSN -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.SSN">
                      <lfg-input-wrap>
                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" [mask]="gridInfoChild1.notes === 'ITES_TIN_FORMAT' ? 'AA-AAA0000' : 'AAA-AA-0000'" [formControl]='gridForm.get(gridInfoChild1.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur [required]="gridInfoChild1.required == 'true'">
                        <lfg-error *ngIf="(gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid) && ( gridForm.get(gridInfoChild1.fieldId).errors == null || (gridForm.get(gridInfoChild1.fieldId).errors != null && !gridForm.get(gridInfoChild1.fieldId).errors.notUnique))" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <div *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.PHONE && (gridInfoChild1.xmlTag == OWNERCONST.phoneNumber ||  gridInfoChild1.xmlTag == OWNERCONST.ENTITY_BUISNESS_PHONE)">
                      <lfg-alert-message type="info">{{ phoneInfo }}</lfg-alert-message>
                    </div>

                    <!-- Form element for phone number -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.PHONE">
                      <lfg-input-wrap>
                        <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild1.required == 'true'">
                        <lfg-error *ngIf="(gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <!-- Form element for email  -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                      <lfg-input-wrap>
                        <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild1) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild1.required == 'true'">

                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <!-- Form element for currency  -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (gridInfoChild1.notes && gridInfoChild1.notes.indexOf('ITES_CURRENCY_USD') > -1)">
                      <lfg-input-wrap>
                        <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild1)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="gridInfoChild1.maxLength == '-1' ? '9': gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">

                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1, gridForm.get(gridInfoChild1.fieldId)) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <!-- Form Element for date  -->
                    <div class="form--control lfg-cal form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.DOB">
                      <lfg-calendar (focusin)="validateOnFocus(gridInfoChild1, 'in', gridForm)" id="{{gridInfoChild1.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(gridInfoChild1, 'out', gridForm)" lfgCheckValidDate [formControl]="gridForm.get(gridInfoChild1.fieldId)" [config]="gridInfoChild1.questionText.indexOf('trust') > -1 ? dateOfTrustConfig : (gridInfoChild1.questionText.indexOf('owner') > -1 ? dateOfBirthOwnerConfig : (gridInfoChild1.questionText.indexOf('Amendment') > -1 ? dateOfAmendConfig : dateOfBirthConfig))" [class.calendar--err]="(gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid)" [required]="gridInfoChild1.required == 'true'">
                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid " message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-calendar>
                    </div>

                    <!-- Nestion question Level 2 -->
                    <div *ngIf="gridInfoChild1.hasReflexive == 'true' && gridForm.get(gridInfoChild1.fieldId).value as parentAnswer">
                      <ng-container *ngFor="let gridInfoChild2 of gridInfoChild1.reflexiveQuestionAL; index as gridInfoChild2ix">
                        <ng-container *ngIf="nestedQuestionCheckgridInfo(gridInfoChild1, gridInfoChild2, parentAnswer)">

                          <!-- Question Info -->
                          <div class="question-info" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild2.subText == 'Information'">
                            <lfg-alert-message type="info">{{ gridInfoChild2.questionText }}</lfg-alert-message>
                          </div>

                          <!-- Form element for Drop Down -->
                          <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.SELECT && (gridInfoChild2.questionText !== 'Country')">
                            <lfg-dropdown formControlName="{{ gridInfoChild2.fieldId }}" id="{{displayIdCreation(gridInfoChild2)}}_{{gridInfoChild2ix}}" [options]="alldropDownOptions[gridInfoChild2.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild2, grid.gridId ,grid.gridIndex, grid.gridUniqueId, gridForm)" [required]="gridInfoChild2.required == 'true'" [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid)}">
                            </lfg-dropdown>
                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="Please select a valid option."></lfg-error>
                          </div>

                          <!-- Form element for text  -->
                          <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TEXT && (!gridInfoChild2.notes || gridInfoChild2.notes.indexOf('ITES_CURRENCY_USD') == -1) && gridInfoChild2.notes != 'ITES_FORMAT_AS_ZIPCODE'">
                            <lfg-input-wrap>
                              <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild2) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(gridInfoChild2.answerTextType)" [maxlength]="gridInfoChild2.maxLength" [required]="gridInfoChild2.required == 'true'">
                              <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                            </lfg-input-wrap>
                          </div>
                          <!-- Form element for zip  -->
                          <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TEXT && (!gridInfoChild2.notes || gridInfoChild2.notes.indexOf('ITES_CURRENCY_USD') == -1) && gridInfoChild2.notes == 'ITES_FORMAT_AS_ZIPCODE'">
                            <lfg-input-wrap>
                              <input lfgInput mask='00000-0000' aria-live="off" name="{{ displayTextLabel(gridInfoChild2) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithHyphen" maxlength="10" [required]="gridInfoChild2.required == 'true'">
                              <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                            </lfg-input-wrap>
                          </div>

                          <!-- owner state info -->
                          <div class="question-info" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TEXT && gridInfoChild2.xmlTag === OWNERCONST.OWNER_ZIP && ownerStateInfo[grid.gridUniqueId]">
                            <lfg-alert-message type="info">{{ ownerStateInfo[grid.gridUniqueId] }}</lfg-alert-message>
                          </div>

                          <!-- Questions headers -->
                          <div class="questions-header" *ngIf="(gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.HDR || gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.USAADDRESS) && (gridInfoChild2.questionText != 'Line' && gridInfoChild2.subText != 'Information')">
                            <h2 class="grid-ques">{{ gridInfoChild2.questionText }}</h2>
                          </div>

                          <div class="section-divider" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild2.questionText == 'Line'">
                          </div>

                          <!-- Form element for currency  -->
                          <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.TEXT && (gridInfoChild2.notes && gridInfoChild2.notes.indexOf('ITES_CURRENCY_USD') > -1)">
                            <lfg-input-wrap>
                              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild2)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]='gridInfoChild2.maxLength' [required]="gridInfoChild2.required == 'true'">

                              <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2, gridForm.get(gridInfoChild2.fieldId)) }}"></lfg-error>
                            </lfg-input-wrap>
                          </div>


                          <!-- Form element for checkbox  -->
                          <div class="form--control form--control__full-width" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
                            <div class="lfg-checkbox">
                              <input id="{{ gridInfoChild2.fieldId }}" type="checkbox" value="checked" name="{{ gridInfoChild2.fieldId }}" formControlName='{{ gridInfoChild2.fieldId }}' [required]="gridInfoChild2.required == 'true'" (change)="onCheckboxChange(gridInfoChild2, grid.gridId, grid.gridIndex, gridForm)">
                              <label class="wrap wrap-check" for="{{ gridInfoChild2.fieldId }}">{{gridInfoChild2.questionText}}</label>
                            </div>
                          </div>


                          <!-- Form element for alphanumeric  -->
                          <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                            <lfg-input-wrap>
                              <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild2) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild2.maxLength" [required]="gridInfoChild2.required == 'true'">

                              <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                            </lfg-input-wrap>
                          </div>

                          <!-- Form element for radio question -->
                          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid)}">
                            <span class="lfg-radio-section lfg-radio-section-col">
                              <p class="question-radio_text">{{ gridInfoChild2.questionText }}</p>
                              <div class="radio-btn-rect radio-button-reflexive">
                                <input [attr.aria-label]="gridInfoChild2.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild2.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild2.fieldId }}" name="{{ gridInfoChild2.fieldId }}" value="{{ gridInfoChild2.questionOption[0].value }}" required="{{gridInfoChild2.required}}" (change)="onRadioChange(gridInfoChild2, grid.gridId, grid.gridIndex)" />
                                <label for="yes_{{ gridInfoChild2.fieldId }}_{{i}}">{{ gridInfoChild2.questionOption[0].description }}</label>
                                <input [attr.aria-label]="gridInfoChild2.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild2.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild2.fieldId }}" name="{{ gridInfoChild2.fieldId }}" value="{{ gridInfoChild2.questionOption[1].value }}" required="{{gridInfoChild2.required}}" (change)="onRadioChange(gridInfoChild2, grid.gridId, grid.gridIndex)" />
                                <label for="no_{{ gridInfoChild2.fieldId }}_{{i}}">{{ gridInfoChild2.questionOption[1].description }}</label>
                              </div>
                            </span>
                            <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid " message="Please select a valid option."></lfg-error>
                          </div>

                          <!-- Form element for phone number -->
                          <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.PHONE">
                            <lfg-input-wrap>
                              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild2.required == 'true'">
                              <lfg-error *ngIf="(gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                            </lfg-input-wrap>
                          </div>

                          <!-- Form element for email  -->
                          <div class="form--control form-control-medium" *ngIf="gridInfoChild2.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                            <lfg-input-wrap>
                              <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild2, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild2) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild2.required == 'true'">

                              <lfg-error *ngIf="gridForm.get(gridInfoChild2.fieldId).touched && gridForm.get(gridInfoChild2.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild2) }}"></lfg-error>
                            </lfg-input-wrap>
                          </div>

                          <!-- Nestion question Level 3 -->
                          <div *ngIf="gridInfoChild2.hasReflexive == 'true' && gridForm.get(gridInfoChild2.fieldId).value as parentAnswer">
                            <ng-container *ngFor="let gridInfoChild3 of gridInfoChild2.reflexiveQuestionAL; index as gridInfoChild3ix">
                              <ng-container *ngIf="nestedQuestionCheckgridInfo(gridInfoChild2, gridInfoChild3, parentAnswer)">

                                <!-- Form element for text  -->
                                <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.TEXT && (!gridInfoChild3.notes || gridInfoChild3.notes.indexOf('ITES_CURRENCY_USD') == -1)">
                                  <lfg-input-wrap>
                                    <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild3) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(gridInfoChild3.answerTextType)" [maxlength]="gridInfoChild3.maxLength" [required]="gridInfoChild3.required == 'true'">
                                    <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                  </lfg-input-wrap>
                                </div>

                                <!-- Form element for alphanumeric  -->
                                <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                  <lfg-input-wrap>
                                    <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild3) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild3.maxLength" [required]="gridInfoChild3.required == 'true'">

                                    <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                  </lfg-input-wrap>
                                </div>


                                <!-- Form element for currency  -->
                                <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.TEXT && (gridInfoChild3.notes && gridInfoChild3.notes.indexOf('ITES_CURRENCY_USD') > -1)">
                                  <lfg-input-wrap>
                                    <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild3)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="gridInfoChild3.maxLength == '-1' ? '9': gridInfoChild3.maxLength" [required]="gridInfoChild3.required == 'true'">

                                    <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3, gridForm.get(gridInfoChild3.fieldId)) }}"></lfg-error>
                                  </lfg-input-wrap>
                                </div>

                                <!-- Form element for radio question -->
                                <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid)}">
                                  <span class="lfg-radio-section lfg-radio-section-col">
                                    <p class="question-radio_text">{{ gridInfoChild3.questionText }}</p>
                                    <div class="radio-btn-rect radio-button-reflexive">
                                      <input [attr.aria-label]="gridInfoChild3.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild3.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild3.fieldId }}" name="{{ gridInfoChild3.fieldId }}" value="{{ gridInfoChild3.questionOption[0].value }}" required="{{gridInfoChild3.required}}" (change)="onRadioChange(gridInfoChild3, grid.gridId, grid.gridIndex)" />
                                      <label for="yes_{{ gridInfoChild3.fieldId }}_{{i}}">{{ gridInfoChild3.questionOption[0].description }}</label>
                                      <input [attr.aria-label]="gridInfoChild3.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild3.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild3.fieldId }}" name="{{ gridInfoChild3.fieldId }}" value="{{ gridInfoChild3.questionOption[1].value }}" required="{{gridInfoChild3.required}}" (change)="onRadioChange(gridInfoChild3, grid.gridId, grid.gridIndex)" />
                                      <label for="no_{{ gridInfoChild3.fieldId }}_{{i}}">{{ gridInfoChild3.questionOption[1].description }}</label>
                                    </div>
                                  </span>
                                  <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid " message="Please select a valid option."></lfg-error>
                                </div>

                                <!-- Form element for phone number -->
                                <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                  <lfg-input-wrap>
                                    <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild3.required == 'true'">
                                    <lfg-error *ngIf="(gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                  </lfg-input-wrap>
                                </div>

                                <!-- Form element for email  -->
                                <div class="form--control form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                  <lfg-input-wrap>
                                    <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild3) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild3.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild3.required == 'true'">

                                    <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild3) }}"></lfg-error>
                                  </lfg-input-wrap>
                                </div>

                                <!-- Questions headers -->
                                <div class="questions-header" *ngIf="(gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.HDR || gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.USAADDRESS) && (gridInfoChild3.questionText != 'Line' && gridInfoChild3.subText != 'Information')">
                                  <h2 class="grid-ques">{{ gridInfoChild3.questionText }}</h2>
                                </div>

                                <div class="section-divider" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild3.questionText == 'Line'">
                                </div>

                                <!-- Form element for Drop Down -->
                                <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild3.controlTypeDesc == appConfig.fieldTypes.SELECT && (gridInfoChild3.questionText !== 'Country')">
                                  <lfg-dropdown formControlName="{{ gridInfoChild3.fieldId }}" id="{{displayIdCreation(gridInfoChild3)}}_{{gridInfoChild3ix}}" [options]="alldropDownOptions[gridInfoChild3.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild3, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild3, grid.gridId ,grid.gridIndex)" [required]="gridInfoChild3.required == 'true'" [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid)}">
                                  </lfg-dropdown>
                                  <lfg-error *ngIf="gridForm.get(gridInfoChild3.fieldId).touched && gridForm.get(gridInfoChild3.fieldId).invalid" message="Please select a valid option."></lfg-error>
                                </div>

                                <!-- Nestion question Level 4 -->
                                <div *ngIf="gridInfoChild3.hasReflexive == 'true' && gridForm.get(gridInfoChild3.fieldId).value as parentAnswer">
                                  <ng-container *ngFor="let gridInfoChild4 of gridInfoChild3.reflexiveQuestionAL; index as gridInfoChild4ix">
                                    <ng-container *ngIf="nestedQuestionCheckgridInfo(gridInfoChild3, gridInfoChild4, parentAnswer)">

                                      <!-- Form element for text  -->
                                      <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.TEXT && (!gridInfoChild4.notes || gridInfoChild4.notes.indexOf('ITES_CURRENCY_USD') == -1)">
                                        <lfg-input-wrap>
                                          <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild4) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(gridInfoChild4.answerTextType)" [maxlength]="gridInfoChild4.maxLength" [required]="gridInfoChild4.required == 'true'">
                                          <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                        </lfg-input-wrap>
                                      </div>

                                      <!-- Form element for alphanumeric  -->
                                      <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                        <lfg-input-wrap>
                                          <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild4) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="gridInfoChild4.maxLength" [required]="gridInfoChild4.required == 'true'">

                                          <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                        </lfg-input-wrap>
                                      </div>


                                      <!-- Form element for currency  -->
                                      <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.TEXT && (gridInfoChild4.notes && gridInfoChild4.notes.indexOf('ITES_CURRENCY_USD') > -1)">
                                        <lfg-input-wrap>
                                          <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild4)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="gridInfoChild4.maxLength == '-1' ? '9': gridInfoChild4.maxLength" [required]="gridInfoChild4.required == 'true'">

                                          <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4, gridForm.get(gridInfoChild4.fieldId)) }}"></lfg-error>
                                        </lfg-input-wrap>
                                      </div>

                                      <!-- Form element for radio question -->
                                      <div class="lfg-customRadio-field radio-btn-custom" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid)}">
                                        <span class="lfg-radio-section lfg-radio-section-col">
                                          <p class="question-radio_text">{{ gridInfoChild4.questionText }}</p>
                                          <div class="radio-btn-rect radio-button-reflexive">
                                            <input [attr.aria-label]="gridInfoChild4.questionOption[0].description" tabindex="0" id="yes_{{ gridInfoChild4.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild4.fieldId }}" name="{{ gridInfoChild4.fieldId }}" value="{{ gridInfoChild4.questionOption[0].value }}" required="{{gridInfoChild4.required}}" (change)="onRadioChange(gridInfoChild4, grid.gridId, grid.gridIndex)" />
                                            <label for="yes_{{ gridInfoChild4.fieldId }}_{{i}}">{{ gridInfoChild4.questionOption[0].description }}</label>
                                            <input [attr.aria-label]="gridInfoChild4.questionOption[1].description" tabindex="0" id="no_{{ gridInfoChild4.fieldId }}_{{i}}" type="radio" formControlName="{{ gridInfoChild4.fieldId }}" name="{{ gridInfoChild4.fieldId }}" value="{{ gridInfoChild4.questionOption[1].value }}" required="{{gridInfoChild4.required}}" (change)="onRadioChange(gridInfoChild4, grid.gridId, grid.gridIndex)" />
                                            <label for="no_{{ gridInfoChild4.fieldId }}_{{i}}">{{ gridInfoChild4.questionOption[1].description }}</label>
                                          </div>
                                        </span>
                                        <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid " message="Please select a valid option."></lfg-error>
                                      </div>

                                      <!-- Form element for phone number -->
                                      <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                        <lfg-input-wrap>
                                          <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="gridInfoChild4.required == 'true'">
                                          <lfg-error *ngIf="(gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid)" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                        </lfg-input-wrap>
                                      </div>

                                      <!-- Form element for email  -->
                                      <div class="form--control form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                        <lfg-input-wrap>
                                          <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild4) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ gridInfoChild4.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="gridInfoChild4.required == 'true'">

                                          <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild4) }}"></lfg-error>
                                        </lfg-input-wrap>
                                      </div>

                                      <!-- Questions headers -->
                                      <div class="questions-header" *ngIf="(gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.HDR || gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.USAADDRESS) && (gridInfoChild4.questionText != 'Line' && gridInfoChild4.subText != 'Information')">
                                        <h2 class="grid-ques">{{ gridInfoChild4.questionText }}</h2>
                                      </div>

                                      <div class="section-divider" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild4.questionText == 'Line'">
                                      </div>

                                      <!-- Form element for Drop Down -->
                                      <div class="form--control form-dropDown form-control-medium" *ngIf="gridInfoChild4.controlTypeDesc == appConfig.fieldTypes.SELECT && (gridInfoChild4.questionText !== 'Country')">
                                        <lfg-dropdown formControlName="{{ gridInfoChild4.fieldId }}" id="{{displayIdCreation(gridInfoChild4)}}_{{gridInfoChild4ix}}" [options]="alldropDownOptions[gridInfoChild4.fieldId]" placeholder="{{ addCorrectPlaceHolder(gridInfoChild4, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(gridInfoChild4, grid.gridId ,grid.gridIndex)" [required]="gridInfoChild4.required == 'true'" [ngClass]="{'dropdown--err': (gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid)}">
                                        </lfg-dropdown>
                                        <lfg-error *ngIf="gridForm.get(gridInfoChild4.fieldId).touched && gridForm.get(gridInfoChild4.fieldId).invalid" message="Please select a valid option."></lfg-error>
                                      </div>

                                    </ng-container>
                                  </ng-container>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>

                        </ng-container>
                      </ng-container>
                    </div>

                  </ng-container>
                </ng-container>
              </div>
            </ng-container>

            <!-- Internal company block starts -->
            <ng-container *ngIf="(data.notes && data.notes.indexOf('INTERNAL_COMPANY_QUESTION') > -1) && isInternalCompany[grid.gridUniqueId]">

              <!-- Questions headers -->
              <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.display && data.questionText != 'Line' && data.subText != 'Information' && data.questionText != 'Search'">
                <h2 class="grid-ques" [ngClass]="{'remove-margin-bottom' : data.subText }">{{ data.questionText }}</h2>
                <p class="ques-content" *ngIf="data.subText">{{ data.subText }}</p>
              </div>

              <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line' && data.display">
              </div>

              <!-- Form element for radio question -->
              <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.display" [ngClass]="{'radio--err': (gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid), 'question-radio--medium-inline': data.xmlTag == trustInfoConstant.phoneType}">
                <span class="lfg-radio-section">
                  <p class="question-radio_text">{{ data.questionText }}</p>
                  <div class="radio-btn-rect radio-button-reflexive">
                    <input [attr.aria-label]="data.questionOption[0]?.description" tabindex="0" id="yes_{{ data.fieldId }}_{{i}}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0]?.value }}" required="{{data.required}}" (change)="onRadioChange(data, grid.gridId, grid.gridIndex)" />
                    <label for="yes_{{ data.fieldId }}_{{i}}">{{ data.questionOption[0]?.description }}</label>
                    <input [attr.aria-label]="data.questionOption[1]?.description" tabindex="0" id="no_{{ data.fieldId }}_{{i}}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1]?.value }}" required="{{data.required}}" (change)="onRadioChange(data, grid.gridId, grid.gridIndex)" />
                    <label for="no_{{ data.fieldId }}_{{i}}">{{ data.questionOption[1]?.description }}</label>
                  </div>
                </span>
                <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
              </div>

              <!-- Form element for currency  -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1)" [ngClass]="{'inline-ques': data.subText == 'ITES_INLINE_FIELD'}">
                <lfg-input-wrap>
                  <div class="inline-ques-container">
                    <span *ngIf="data.subText == 'ITES_INLINE_FIELD'" class="inline-ques-text">{{ data.questionText }}</span>
                    <label for="{{data.fieldId}}" *ngIf="data.subText == 'ITES_INLINE_FIELD'">
                      <span class="visually-hidden">{{data.questionText}}</span>
                      <input lfgInput aria-live="off" [id]="data.fieldId" class="inline-ques-input" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="data.maxLength == '-1' ? '9': data.maxLength" [required]="data.required == 'true'">
                      <div class="inline-error">
                        <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid && data.subText == 'ITES_INLINE_FIELD'" message="{{ ErrorMsgs.ERROR_MSG }}"></lfg-error>
                      </div>
                    </label>
                    <input *ngIf="data.subText != 'ITES_INLINE_FIELD'" lfgInput aria-live="off" placeholder="{{addCorrectPlaceHolder(data, 'placeHolder')}}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="data.maxLength == '-1' ? '9': data.maxLength" [required]="data.required == 'true'">
                  </div>
                  <ng-container *ngIf="data.subText != 'ITES_INLINE_FIELD'">
                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                  </ng-container>
                </lfg-input-wrap>
              </div>

              <!-- Form element for text  -->
              <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1)" [ngClass]="{'inline-ques': data.subText == 'ITES_INLINE_FIELD'}">
                <lfg-input-wrap>
                  <div class="inline-ques-container" *ngIf="data.subText == 'ITES_INLINE_FIELD'">
                    <span class="inline-ques-text">{{ data.questionText }}</span>
                    <label for="{{data.fieldId}}">
                      <span class="visually-hidden">{{data.questionText}}</span>
                      <ng-container *ngIf="!data.notes || data.notes.indexOf('ITES_PERCENTAGE') === -1">
                        <input lfgInput class="inline-ques-input" [id]="data.fieldId" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                      </ng-container>
                      <ng-container *ngIf="data.notes && data.notes.indexOf('ITES_PERCENTAGE') > -1">
                        <input lfgInput appPercentageFormat class="inline-ques-input" [id]="data.fieldId" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                      </ng-container>
                      <div class="inline-error">
                        <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid && data.subText == 'ITES_INLINE_FIELD'" message="{{ ErrorMsgs.ERROR_MSG }}"></lfg-error>
                      </div>
                    </label>
                  </div>
                  <ng-container *ngIf="data.subText != 'ITES_INLINE_FIELD'">
                    <ng-container *ngIf="(!data.notes || data.notes.indexOf('ITES_PERCENTAGE') === -1)">
                      <input lfgInput placeholder="{{addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                    </ng-container>
                    <ng-container *ngIf="(data.notes && data.notes.indexOf('ITES_PERCENTAGE') > -1)">
                      <input appPercentageFormat lfgInput placeholder="{{addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                    </ng-container>
                    <ng-container>
                      <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, gridForm.get(data.fieldId)) }}"></lfg-error>
                    </ng-container>
                  </ng-container>
                </lfg-input-wrap>
              </div>

              <!-- Form Element for Inline date  -->
              <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB && data.subText == 'ITES_INLINE_FIELD'">
                <label for="{{data.fieldId}}" class="inline-date-block">
                  <span>{{data.questionText}}</span>
                </label>
                <div class="form--control lfg-cal">
                  <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="gridForm.get(data.fieldId)" [config]="(data.questionText?.toLowerCase()?.indexOf('proposed policy') > -1) ? futureDateConfig2 : defaultDateConfig" [class.calendar--err]="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)" [required]="isReplacedSelected[grid.gridUniqueId]">
                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="{{ ErrorMsgs.ERROR_MSG }}"></lfg-error>
                  </lfg-calendar>
                </div>
              </div>

              <!-- Form Element for date  -->
              <ng-container *ngIf="data.xmlTag === CONST.currentPolicyTerminateDate">
                <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
                  <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="gridForm.get(data.fieldId)" [config]="futureDateConfig" [class.calendar--err]="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)" [required]="isReplacedSelected[grid.gridUniqueId]">
                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
                  </lfg-calendar>
                </div>
              </ng-container>

              <!-- Form Element for date  -->
              <ng-container *ngIf="data.xmlTag !== CONST.currentPolicyTerminateDate && data.subText !== 'ITES_INLINE_FIELD'">
                <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
                  <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="gridForm.get(data.fieldId)" [config]="(data.questionText?.toLowerCase()?.indexOf('proposed') > -1) ? dateOfExistingConfig : defaultDateConfig" [class.calendar--err]="(gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
                    <lfg-error *ngIf="gridForm.get(data.fieldId).touched && gridForm.get(data.fieldId).invalid " message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
                  </lfg-calendar>
                </div>
              </ng-container>

              <!-- Nestion question Level 1 -->
              <div *ngIf="data.hasReflexive == 'true' && checkIfQuestionDisplayed(gridForm, data) && gridForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(gridForm, data) }">
                <ng-container *ngFor="let gridInfoChild1 of data.reflexiveQuestionAL; index as gridInfoChild1ix">
                  <ng-container *ngIf="nestedQuestionCheckgridInfo(data, gridInfoChild1, parentAnswer)">
                    <div class="question-info" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild1.subText == 'Information'">
                      <lfg-alert-message type="info">{{ gridInfoChild1.questionText }}</lfg-alert-message>
                    </div>

                    <!-- Form element for checkbox  -->
                    <div class="form--control form--control__full-width" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
                      <div class="lfg-checkbox">
                        <input id="{{ gridInfoChild1.fieldId }}" type="checkbox" value="checked" name="{{ gridInfoChild1.fieldId }}" formControlName='{{ gridInfoChild1.fieldId }}' [required]="gridInfoChild1.required == 'true'" (change)="onCheckboxChange(gridInfoChild1, grid.gridId, grid.gridIndex, gridForm)">
                        <label class="wrap wrap-check" for="{{ gridInfoChild1.fieldId }}">{{gridInfoChild1.questionText}}</label>
                      </div>
                    </div>

                    <!-- Form element for text area -->
                    <div class="form--control form--control__full-width" aria-live="assertive" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
                      <lfg-input-wrap>
                        <input lfgInput placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" name="{{ displayTextLabel(gridInfoChild1) }}" aria-live="off" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="gridInfoChild1.maxLength" [required]="gridInfoChild1.required">
                        <span class="text-area-count">{{gridForm.get(gridInfoChild1.fieldId).value.length}}/{{gridInfoChild1.maxLength}}</span>
                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="Please enter a valid input."></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <!-- Form element for currency  -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (gridInfoChild1.notes && gridInfoChild1.notes.indexOf('ITES_CURRENCY_USD') > -1)">
                      <lfg-input-wrap>
                        <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="{{displayTextLabel(gridInfoChild1)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="gridInfoChild1.maxLength == '-1' ? '9': gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">

                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1, gridForm.get(gridInfoChild1.fieldId)) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                    <!-- Questions headers -->
                    <div class="questions-header" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && (gridInfoChild1.questionText != 'Line' && gridInfoChild1.subText != 'Information' && gridInfoChild1.subText != 'Label')">
                      <h2 class="grid-ques">{{ gridInfoChild1.questionText }}</h2>
                    </div>

                    <!-- Questions label -->
                    <div class="questions-header" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild1.subText == 'Label'">
                      <p>{{ gridInfoChild1.questionText }}</p>
                    </div>

                    <div class="section-divider" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.HDR && gridInfoChild1.questionText == 'Line'">
                    </div>

                    <!-- Form element for text  -->
                    <div class="form--control form-control-medium" *ngIf="gridInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && (!gridInfoChild1.notes || gridInfoChild1.notes.indexOf('ITES_CURRENCY_USD') == -1)">
                      <lfg-input-wrap *ngIf="gridInfoChild1.notes && gridInfoChild1.notes.indexOf('ITES_PERCENTAGE') > -1">
                        <input lfgInput aria-live="off" appPercentageFormat name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(gridInfoChild1.answerTextType)" [maxlength]="gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">
                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-input-wrap>
                      <lfg-input-wrap *ngIf="!gridInfoChild1.notes || gridInfoChild1.notes.indexOf('ITES_PERCENTAGE') === -1">
                        <input lfgInput aria-live="off" name="{{ displayTextLabel(gridInfoChild1) }}" placeholder="{{ addCorrectPlaceHolder(gridInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ gridInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(gridInfoChild1.answerTextType)" [maxlength]="gridInfoChild1.maxLength" [required]="gridInfoChild1.required == 'true'">
                        <lfg-error *ngIf="gridForm.get(gridInfoChild1.fieldId).touched && gridForm.get(gridInfoChild1.fieldId).invalid" message="{{ getErrorMsg(gridInfoChild1) }}"></lfg-error>
                      </lfg-input-wrap>
                    </div>

                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <!--Internal company block end-->
          </ng-container>
        </div>
      </lfg-panel>
    </lfg-accordion>
  </form>

  <div class="add-grid" *ngIf="addAccordionText && maxGridAvailable > gridSequences.length">
    <a (click)="createNewGrid(true)" [ngClass]="{'btn--link-disabled': disableForm}">
      <i class="fas fa-plus-circle add-icon" aria-hidden="true"></i>{{addAccordionText}}</a>
  </div>
</div>