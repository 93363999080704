<section class="ridersInfo-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Riders Information
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="ridersInfoForm" id="ridersInfoForm" method="post" *ngIf="ridersInfoData && ridersInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of ridersInfoData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText !== 'Information'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes?.indexOf('ITES_CURRENCY_USD') > 1">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required">

              <lfg-error *ngIf="ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for check box  -->
          <div class="form--control form--control__full-width multi-checkbox checkbox-rider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.display">
            <div class="lfg-checkbox">
              <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [checked]="data.value == 'true'" [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
              <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
            </div>
            <lfg-alert-message *ngIf="ridersInfoForm.get(data.fieldId).invalid" type="error">{{ getErrorMsg(data, ridersInfoForm.get(data.fieldId)) }}</lfg-alert-message>
          </div>

          <!-- Form element for text area -->
          <div class="form--control form--control__full-width text-area" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required">
              <span class="text-area-count">{{ridersInfoForm.get(data.fieldId)?.value?.length ? ridersInfoForm.get(data.fieldId).value.length : '0' }}/{{data.maxLength}}</span>
              <lfg-error *ngIf="ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid" message="Please enter a valid input."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field lfg-customRadio-field-single" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag != RiderQuestionConstants.ProspectiveInsuredQues" [ngClass]="{'radio--err': (ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid), 'radio-button-mobile': data.xmlTag === RiderQuestionConstants.DeathBenefitQualification}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ addCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field inbuild-radio" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag == RiderQuestionConstants.ProspectiveInsuredQues" [ngClass]="{'radio--err': (ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <span>
                <p class="question-radio_text">{{ data.questionText.substring(0, data.questionText.indexOf('_')) }}</p>
              </span>
              <span class="instyle-radio custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </span>
              <span>
                <p class="question-radio_text">{{ data.questionText.substring(data.questionText.indexOf('requested')) }}</p>
              </span>
            </span>
            <lfg-error *ngIf="ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown question-dropdown--medium" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid)}">
            </lfg-dropdown>
            <lfg-error *ngIf="ridersInfoForm.get(data.fieldId).touched && (ridersInfoForm.get(data.fieldId).errors?.dboError || ridersInfoForm.get(data.fieldId).errors?.dboPremiumError || ridersInfoForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data, ridersInfoForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <lfg-alert-message *ngIf="updateDeathBenefitOptionInformation(data)" type="info">{{updateDeathBenefitOptionInformation(data)}}</lfg-alert-message>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

              <lfg-error *ngIf="ridersInfoForm.get(data.fieldId).touched && ridersInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && ridersInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckridersInfo(data, primaryChildL1, parentAnswer)">
                <!-- Questions headers -->
                <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText !== 'Information'">
                  <h2>{{ primaryChildL1.questionText }}</h2>
                </div>

                <!-- Question Info -->
                <div class="question-info" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText == 'Information'">
                  <lfg-alert-message type="info">{{ primaryChildL1.questionText }}</lfg-alert-message>
                </div>

                <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL1, 'display')">
                  {{ addCorrectPlaceHolder(primaryChildL1, 'question') }}
                </div>

                <!-- Form element for text  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && (!primaryChildL1.notes || (primaryChildL1.notes?.indexOf('ITES_CURRENCY_USD') == -1))">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                    <lfg-error *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for currency  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && ((primaryChildL1.notes && primaryChildL1.notes.indexOf('ITES_CURRENCY_USD') > -1))">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'" (input)="onCurrencyUpdate(primaryChildL1)">

                    <lfg-error *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1, ridersInfoForm.get(primaryChildL1.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for alphanumeric  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                    <lfg-error *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT">
                  <span class="screen-reader-only" *ngIf="primaryChildL1.xmlTag === '/XML/Client/CRMDetails/StateOfBirth'">{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}</span>
                  <lfg-dropdown formControlName="{{ primaryChildL1.fieldId }}" id="{{displayIdCreation(primaryChildL1)}}_{{primaryChildL1ix}}" [options]="alldropDownOptions[primaryChildL1.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL1, primaryChildL1ix)" [required]="primaryChildL1.required == 'true'" [ngClass]="{'dropdown--err': (ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid)}">
                  </lfg-dropdown>

                  <lfg-error *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1, ridersInfoForm.get(primaryChildL1.fieldId)) }}"></lfg-error>
                </div>

                <!-- Form element for Radio button -->
                <div class="lfg-customRadio-field" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO && primaryChildL1.questionOption[0].description?.length <= 20" [ngClass]="{'radio--err': (ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid)}">
                  <span class="lfg-radio-section">
                    <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL1, 'question') }}</p>
                    <div class="custom-radio-button">
                      <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)" />
                      <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                      <input [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                      <label for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                    </div>
                  </span>
                  <lfg-error *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid " message="Please select a valid option."></lfg-error>
                </div>

                <!-- Form element for radio question having longer desc -->
                <div aria-live="assertive" class="lfg-customRadio-field-long" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO && primaryChildL1.questionOption[0].description?.length > 20" [ngClass]="{'radio--err': (ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid)}">
                  <span class="radio-question-container">
                    <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}</p>
                    <div class="radio-button-container">
                      <label class="radio-input-label radio-input-label-child" [ngClass]="{'radio-button-checked': ridersInfoForm.get(primaryChildL1.fieldId).value === primaryChildL1.questionOption[0].value}">
                        <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).value === primaryChildL1.questionOption[0].value"></i>
                        <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).value !== primaryChildL1.questionOption[0].value"></i>
                        <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)" />
                        <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                      </label>
                      <label class="radio-input-label radio-input-label-child" [ngClass]="{'radio-button-checked': ridersInfoForm.get(primaryChildL1.fieldId).value === primaryChildL1.questionOption[1].value}">
                        <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).value === primaryChildL1.questionOption[1].value"></i>
                        <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).value !== primaryChildL1.questionOption[1].value"></i>
                        <input [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                        <label for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                      </label>
                      <label class="radio-input-label radio-input-label-child" [ngClass]="{'radio-button-checked': ridersInfoForm.get(primaryChildL1.fieldId).value === primaryChildL1.questionOption[2]?.value}" *ngIf="primaryChildL1.questionOption[2]">
                        <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).value === primaryChildL1.questionOption[2].value"></i>
                        <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).value !== primaryChildL1.questionOption[2].value"></i>
                        <input [attr.aria-label]="primaryChildL1.questionOption[2].description" tabindex="0" id="none_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[2].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                        <label for="none_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[2].description }}</label>
                      </label>
                    </div>
                  </span>
                  <lfg-error *ngIf="ridersInfoForm.get(primaryChildL1.fieldId).touched && ridersInfoForm.get(primaryChildL1.fieldId).invalid " message="{{ getErrorMsg(primaryChildL1, ridersInfoForm.get(primaryChildL1.fieldId)) }}"></lfg-error>
                </div>

              </ng-container>
            </ng-container>
          </div>

          <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.display">
            <lfg-alert-message *ngIf="showExecRiderMessage(data)" type="info">{{showExecRiderMessage(data)}}</lfg-alert-message>
          </div>

        </ng-container>
      </form>
    </section>
  </div>
</section>
