<section class="owner-info-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      Owner Information
    </h1>
    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid)" type="error">{{errorMessage}}</lfg-alert-message>
    <lfg-alert-message *ngIf="noOfTrustSelected > 0 && totalNoOfOwnerAdded > 1 && !disableForm" type="error">{{multipleOwnerError}}</lfg-alert-message>
    <lfg-alert-message *ngIf="individualExeRiderError && !disableForm" type="error">{{Message.INDIVIDUAL_SURRENDER_RIDER_ERROR}}</lfg-alert-message>
    <lfg-alert-message *ngIf="businessExeRiderError && !disableForm" type="error">{{Message.BUSINESS_SURRENDER_RIDER_ERROR}}</lfg-alert-message>
    <section>
      <div *ngIf="gridInstance?.length > 0">
        <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [isRevisit]="isTabVisited" [addAccordionText]="'Add Owner'" [maxGridAvailable]="maxNoOfOwner" [accordionHeader]="'Owner'" [type]="'OWNER'" [disableForm]="disableForm"></app-grid-structure>
      </div>
    </section>
  </div>
</section>
