<section class="trust-info-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      Trust Information
    </h1>
    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid)" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="trustInfoForm" id="trustInfoForm" method="post" *ngIf="trustInfoData && trustInfoData.length > 0">

        <div class="loader-container" *ngIf="showLoader">
          <lfg-loader></lfg-loader>
        </div>

        <ng-container class="form-control-parent" *ngFor="let data of trustInfoData; let ind = index;">
          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Button' && data.subText != 'Frame'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="questions-header frame" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Frame'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-divider add-margin-bottom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Message'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- USA address -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
            <h2>{{ data.questionText }}</h2>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addTrustCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="trustInfoForm.get(data.fieldId).touched && trustInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, trustInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for SSN -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addTrustCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='trustInfoForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="data.required == 'true'" (input)="checkIfSSNIsValidAndResetValues()">

              <lfg-error *ngIf="(trustInfoForm.get(data.fieldId).touched && trustInfoForm.get(data.fieldId).invalid) && ( trustInfoForm.get(data.fieldId).errors == null || (trustInfoForm.get(data.fieldId).errors != null && !trustInfoForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (trustInfoForm.get(data.fieldId).touched && trustInfoForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ addTrustCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="trustRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="trustRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="trustInfoForm.get(data.fieldId).touched && trustInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" aria-live="polite" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addTrustCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleTrustSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err':(trustInfoForm.get(data.fieldId).touched && trustInfoForm.get(data.fieldId).invalid)}">
            </lfg-dropdown>
            <div aria-live="assertive">
                <lfg-error *ngIf="trustInfoForm.get(data.fieldId).touched && trustInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
            </div>
          </div>

          <!-- Form element for accordian -->
          <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.notes == 'trustAccordian'">
            <div *ngIf="gridInstance?.length > 0">
              <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [addAccordionText]="'Add a Trustee Information'" [maxGridAvailable]="maxNoOfTrust" [accordionHeader]="'Trustee Information'" [type]="'TRUST'" [isRevisit]="isTabVisited" [disableForm]="disableForm"></app-grid-structure>
            </div>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && trustInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let trustChildL1 of data.reflexiveQuestionAL; index as trustChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckTrustInfoInfo(data, trustChildL1, parentAnswer)">
                <!-- Questions headers -->
                <div class="questions-header" *ngIf="trustChildL1.controlTypeDesc == appConfig.fieldTypes.HDR">
                  <h2>{{ trustChildL1.questionText }}</h2>
                </div>

                <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                <div class="questionText" *ngIf="addTrustCorrectPlaceHolder(trustChildL1, 'display')">
                  {{ addTrustCorrectPlaceHolder(trustChildL1, 'question') }}
                </div>

                <!-- Form element for text  -->
                <div class="form--control" *ngIf="trustChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(trustChildL1) }}" placeholder="{{ addTrustCorrectPlaceHolder(trustChildL1, 'placeHolder') }}" type='text' formControlName='{{ trustChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols lfgStopPaste [attr.customAttr]="getAnswerType(trustChildL1.answerTextType)" [maxlength]="trustChildL1.maxLength" [required]="trustChildL1.required == 'true'">
                    <div aria-live="assertive">
                        <lfg-error *ngIf="trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid" message="{{ getErrorMsg(trustChildL1) }}"></lfg-error>
                    </div>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for alphanumeric  -->
                <div class="form--control" *ngIf="trustChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(trustChildL1) }}" placeholder="{{ addTrustCorrectPlaceHolder(trustChildL1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ trustChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="trustChildL1.maxLength" [required]="trustChildL1.required == 'true'">
                    <div aria-live="assertive">
                        <lfg-error *ngIf="trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid" message="{{ getErrorMsg(trustChildL1) }}"></lfg-error>
                    </div>
                </lfg-input-wrap>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" *ngIf="trustChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': trustChildL1.questionText.length > 32}">
                  <span class="screen-reader-only" *ngIf="trustChildL1.xmlTag === '/XML/Client/CRMDetails/StateOfBirth'">{{ trustChildL1.questionText }}</span>
                  <lfg-dropdown formControlName="{{ trustChildL1.fieldId }}" id="{{displayIdCreation(trustChildL1)}}_{{trustChildL1ix}}" [options]="alldropDownOptions[trustChildL1.fieldId]" placeholder="{{ addTrustCorrectPlaceHolder(trustChildL1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(trustChildL1, trustChildL1ix)" [required]="trustChildL1.required == 'true'" [ngClass]="{'dropdown--err': (trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid" message="{{ getErrorMsg(trustChildL1) }}"></lfg-error>
                </div>

                <!-- Form element for phone number -->
                <div class="form--control" aria-live="assertive" *ngIf="trustChildL1.controlTypeDesc == appConfig.fieldTypes.PHONE">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{ addTrustCorrectPlaceHolder(trustChildL1, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ trustChildL1.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="trustChildL1.required == 'true'">
                    <lfg-error *ngIf="(trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid)" message="{{ getErrorMsg(trustChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for SSN -->
                <div class="form--control" *ngIf="trustChildL1.controlTypeDesc == appConfig.fieldTypes.SSN">
                  <lfg-input-wrap>
                    <input lfgInput role="textbox" aria-live="off" name="{{ displayTextLabel(trustChildL1) }}" placeholder="{{ addTrustCorrectPlaceHolder(trustChildL1, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='trustInfoForm.get(trustChildL1.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur  [required]="trustChildL1.required == 'true'">
                    <div aria-live="assertive">
                        <lfg-error *ngIf="(trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid) && ( trustInfoForm.get(trustChildL1.fieldId).errors == null || (trustInfoForm.get(trustChildL1.fieldId).errors != null && !trustInfoForm.get(trustChildL1.fieldId).errors.notUnique))" message="Please enter a valid {{trustChildL1.questionText | lowercase}}."></lfg-error>
                        <lfg-error *ngIf="(trustInfoForm.get(trustChildL1.fieldId).invalid && trustInfoForm.get(trustChildL1.fieldId).touched) &&(trustInfoForm.get(trustChildL1.fieldId).errors != null && trustInfoForm.get(trustChildL1.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
                    </div>
                </lfg-input-wrap>
                </div>

                <!-- Form Element for date  -->
                <div class="form--control lfg-cal" aria-live="assertive" *ngIf="trustChildL1.controlTypeDesc == appConfig.fieldTypes.DOB">
                    <lfg-calendar (focusin)="validateOnFocus(trustChildL1, 'in')" id="{{trustChildL1.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(trustChildL1, 'out')" lfgCheckValidDate [formControl]="trustInfoForm.get(trustChildL1.fieldId)" [config]="trustChildL1.required == 'true' ? dateConfig : dateConfigOpt" [class.calendar--err]="(trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid)" [required]="trustChildL1.required == 'true'">
                    <lfg-error *ngIf="trustInfoForm.get(trustChildL1.fieldId).touched && trustInfoForm.get(trustChildL1.fieldId).invalid " message="Please enter a valid {{trustChildL1.questionText | lowercase}}."></lfg-error>
                    </lfg-calendar>
                </div>

              </ng-container>
            </ng-container>
          </div>

        </ng-container>
      </form>
    </section>
  </div>
</section>
