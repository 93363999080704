import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Question } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, VoiceTermOfUse } from 'src/config/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-term-of-use',
  templateUrl: './term-of-use.component.html',
  styleUrls: ['./term-of-use.component.scss'],
})
export class TermOfUseComponent implements OnInit {
  @Input() disableForm: boolean;
  @Input() policyCreationError: string;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  termOfUseData: Question[] = [];
  termOfUseForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  VoiceConst = VoiceTermOfUse;
  baseURL = environment.uiBaseUrl;
  voiceInstruction = '';

  constructor(
    private fb: FormBuilder,
    private appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(
        this.termOfUseForm
      );
      this.getInstruction();
      this.defaultPageLoadService.disableFormIfLocked(this.termOfUseForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  viewContent(doc: string): void {
    this.defaultPageLoadService.logLinkClick('view or print ' + doc + ' document');
    if (doc === 'term') {
      window.open(this.baseURL + 'assets/documents/term-and-condition.pdf');
    } else if (doc === 'acknowledgement') {
      window.open(this.baseURL + 'assets/documents/producer-acknowledgement.pdf');
    }
  }

  private buildFormData(): any {
    this.termOfUseData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.termOfUseData
    );
    this.termOfUseForm = this.fb.group(form);
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.termOfUseForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  onCheckboxChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  private updatedAnswersOnHidden(data): any {
    const value = this.termOfUseForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.termOfUseForm.get(key)?.markAsUntouched();
      }
      this.termOfUseForm.patchValue(updatedval);
    }
  }

  nestedQuestionCheckTermOfUse(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes{47}Yes') {
        return parent === true;
      } else if (childData.optionChoice === 'No{47}No') {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  private getInstruction(): void {
    this.termOfUseData.forEach(ques => {
      if (ques.notes === 'Instruction') {
        this.voiceInstruction = this.voiceInstruction + ques.questionText;
      }
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.termOfUseForm,
      data: null,
    };
    for (const data of this.termOfUseData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'termOfUseForm',
      isFormChange: this.termOfUseForm.dirty,
    };
    this.formStatus.emit(obj);
  }

}
