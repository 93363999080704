import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-illustration-info',
  templateUrl: './illustration-info.component.html',
  styleUrls: ['./illustration-info.component.scss']
})
export class IllustrationInfoComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  illustrationInfoData = [];
  illustrationInfoForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  isStoppingPremium = false;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private userService: UserDetailsService
  ) {

  }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.illustrationInfoForm);
      this.clearQuestionsRelatedToExistingIns();
      this.defaultPageLoadService.disableFormIfLocked(this.illustrationInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.illustrationInfoData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.illustrationInfoData
    );
    this.illustrationInfoForm = this.fb.group(form);
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.illustrationInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.illustrationInfoForm,
      data: null,
    };
    for (const data of this.illustrationInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'illustrationInfoForm',
      isFormChange: this.illustrationInfoForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.illustrationInfoForm),
    };
    this.formStatus.emit(obj);
  }

  onRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  private updatedAnswersOnHidden(data): any {
    const value = this.illustrationInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.illustrationInfoForm.get(key)?.markAsUntouched();
      }
      this.illustrationInfoForm.patchValue(updatedval);
    }
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private clearQuestionsRelatedToExistingIns(): void {
    this.isStoppingPremium = this.userService.getConsideringAnyDiscontinuation();
    if (!this.isStoppingPremium) {
      this.illustrationInfoData.forEach((ques) => {
        if ((ques.notes === 'EXISTING_INS_RELATED_QUES')) {
          this.illustrationInfoForm.get(ques.fieldId)?.reset();
          this.illustrationInfoForm.get(ques.fieldId)?.clearValidators();
          this.illustrationInfoForm.get(ques.fieldId)?.setErrors(null);
        }
      });
    }
  }
}
