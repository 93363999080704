<section class="suitability-review-page page-content-container">

    <div class="questions--container">
        <h1 class="lfg-color">
            Suitability Review
        </h1>
        <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>

        <section>
            <form class="form-group" [formGroup]="suitabilityReviewForm" id="suitabilityReviewForm" method="post" *ngIf="suitabilityReviewData && suitabilityReviewData.length > 0">
                
                <ng-container class="form-control-parent" *ngFor="let data of suitabilityReviewData; let ind = index;" >
                    
                    <!-- Form element for radio question -->
                    <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO"
                    [ngClass]="{'radio--err': (suitabilityReviewForm.get(data.fieldId).touched && suitabilityReviewForm.get(data.fieldId).invalid)}">
                        <span class="lfg-radio-section">
                            <p class="question-radio_text">{{ data.questionText }}</p>
                            <div class="custom-radio-button radio-rect">
                                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                            </div>
                        </span>
                        <lfg-error *ngIf="suitabilityReviewForm.get(data.fieldId).touched && suitabilityReviewForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
                    </div>

                    <!-- element for line -->
                    <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
                    </div>

                </ng-container>
            </form>
        </section>

    </div>
</section>