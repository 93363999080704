import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import {
  RiderQuestionConstant,
  ErrorMessage,
  Message
} from 'src/config/constants';
import { Question } from 'src/app/shared/models/casePage.model';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import {
  dboValidator, faceAmountAndOtherRiderAmountValidator, faceAmountAndPrimaryRiderAmountValidator,
  minMaxAmountValidator, riderAgeAndDurationValidator, typeOfOwnerValidator
} from 'src/app/shared/validators/business-validator';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { ProductCodes, TAB, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-riders-info',
  templateUrl: './riders-info.component.html',
  styleUrls: ['./riders-info.component.scss'],
})
export class RidersInfoComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() isLTCSelected: EventEmitter<string> = new EventEmitter();
  @Output() otherRiderSelected: EventEmitter<string> = new EventEmitter();

  ridersInfoData: Question[] = [];
  ridersInfoForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  placeholders = {};
  alldropDownOptions = {};
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  RiderQuestionConstants = RiderQuestionConstant;
  isFaceAmountSelected = false;

  tabStatusOnInit: TabStatus;

  selectedProductCode: string;
  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private productService: ProductDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private userDetailsService: UserDetailsService,
    private tabService: TabDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.selectedProductCode = this.productService.getProductName()?.toLowerCase();
      this.isFaceAmountSelected = this.userDetailsService.getFaceAmount() > 0;
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.ridersInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.ridersInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.checkForOptionalTab();
      this.checkFormStatus();
    }
  }

  private buildFormData(): any {
    this.ridersInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.ridersInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.ridersInfoData
    );
    this.ridersInfoForm = this.fb.group(form);
    !this.isFaceAmountSelected ? this.disableAllRidersIfFaceAmountIsBlank() : this.initializeRiderForm();
    this.valueChanges(!this.isFaceAmountSelected);
  }

  onCheckboxChange(data: Question): any {
    this.addBusinessValidationsToReflexiveQues();
    this.disableRiders();
    this.isLTCRiderSelected(data);
    this.isOtherRiderSelected(data);
    this.updatedAnswersOnHidden(data);
  }

  validateOnFocus(data, type): any {
    setTimeout(() => {
      if (type === 'in') {
        this.ridersInfoForm.get(data.fieldId)?.markAsUntouched();
      } else {
        this.ridersInfoForm.get(data.fieldId)?.markAsTouched();
      }
    }, 10);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(isFormDisabled: boolean): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus(isFormDisabled);
    }, 200);
    this.ridersInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus(isFormDisabled);
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(isFormDisabled?: boolean): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.ridersInfoForm,
      data: null,
    };
    for (const data of this.ridersInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'ridersInfoForm',
      // added isTabRevisit in condition as this tab is valid even without entering any answer
      isFormChange: this.ridersInfoForm.dirty || !this.isTabVisited || isFormDisabled
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.ridersInfoForm),
    };
    this.formStatus.emit(obj);
  }

  onCurrencyUpdate(data: Question): void {
    if (data.xmlTag === RiderQuestionConstant.primaryLTCAmt) {
      this.addOtherRiderAmountValidation();
      this.disableLEABR();
    }
    if (data.xmlTag === RiderQuestionConstant.primaryLTCAmt || data.xmlTag === RiderQuestionConstant.OtherRiderAmount) {
      this.disableRiderBasedOnCurrency();
    }
    if (data.xmlTag === RiderQuestionConstant.CareCoverageLTCSpecAmount || data.xmlTag === RiderQuestionConstant.LongTermLTCSpecAmount) {
      this.disableLTCPercentageRadioButton();
    }
  }

  private initializeRiderForm(): void {
    this.disableDeathBenefitOption();
    this.addBusinessValidationsToReflexiveQues();
    this.addBusinessValidationsToMainQues();
    this.disableLTCPercentageRadioButton();
    this.disableRiderBasedOnCurrency();
    this.disableRiderBasedOnDBQ();
  }

  handleSelectionChange(data, _ix?): any {
    if (data.xmlTag === RiderQuestionConstant.DBOptionQuesId) {
      if (this.isFaceAmountSelected) {
        this.disableRiderBasedOnDBO();
      }
      this.addDboValidations();
    }
    this.updatedAnswersOnHidden(data);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.ridersInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.ridersInfoForm.get(key)?.enable();
        this.ridersInfoForm.get(key)?.markAsUntouched();
      }
      this.ridersInfoForm.patchValue(updatedval);
    }
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.ridersInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckridersInfo(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      return parent === true;
    } else {
      return parent === childData.optionChoice;
    }
  }

  getErrorMsg(data: Question, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  onRadioChange(data): any {
    if (data.xmlTag === RiderQuestionConstant.DeathBenefitQualification) {
      if (this.isFaceAmountSelected) {
        this.disableRiderBasedOnDBQ();
      }
      this.addDboValidations();
    }
    this.updatedAnswersOnHidden(data);
  }

  private addBusinessValidationsToMainQues(): void {
    setTimeout(() => {
      this.addOwnerTypeValidation();
      this.disableRiders();
    }, 50);
  }

  private addBusinessValidationsToReflexiveQues(): void {
    this.addDboValidations();
    this.addOtherRiderAgeDurationValidation();
    this.addOtherRiderAmountValidation();
    this.addPrimaryRiderAmountValidation();
    this.addPrimaryRiderAgeDurationValidation();
    this.addMinMaxAmountValidations();
  }

  private addDboValidations(): void {
    const dboQuesId = this.getFieldIdByXmlTag(RiderQuestionConstant.DBOptionQuesId);
    const dbQValue = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DeathBenefitQualification))?.value?.toLowerCase();
    this.ridersInfoForm.get(dboQuesId)?.setValidators([dboValidator(this.ridersInfoForm.get(dboQuesId),
      this.selectedProductCode, dbQValue), Validators.required]);
    this.ridersInfoForm.get(dboQuesId)?.updateValueAndValidity();
  }

  private addOtherRiderAgeDurationValidation(): void {
    const riderDurationQues = this.getFieldIdByXmlTag(RiderQuestionConstant.OtherRiderDuration);
    this.ridersInfoForm.get(riderDurationQues)?.setValidators([riderAgeAndDurationValidator(this.userDetailsService.getInsuredAge(),
      this.selectedProductCode, RiderQuestionConstant.OtherRiderDuration)]);
  }

  private addOtherRiderAmountValidation(): void {
    const riderAmountQues = this.getFieldIdByXmlTag(RiderQuestionConstant.OtherRiderAmount);
    const primaryRiderAmount = this.getFieldIdByXmlTag(RiderQuestionConstant.primaryLTCAmt);
    this.ridersInfoForm.get(riderAmountQues)?.setValidators([faceAmountAndOtherRiderAmountValidator(this.userDetailsService.getFaceAmount(),
      this.ridersInfoForm.get(primaryRiderAmount)?.value)]);
    this.ridersInfoForm.get(riderAmountQues)?.updateValueAndValidity();
  }

  private addPrimaryRiderAgeDurationValidation(): void {
    const riderDurationQues = this.getFieldIdByXmlTag(RiderQuestionConstant.PrimaryRiderDuration);
    this.ridersInfoForm.get(riderDurationQues)?.setValidators([riderAgeAndDurationValidator(this.userDetailsService.getInsuredAge(),
      this.selectedProductCode, RiderQuestionConstant.PrimaryRiderDuration)]);
  }

  private addPrimaryRiderAmountValidation(): void {
    const riderAmountQues = this.getFieldIdByXmlTag(RiderQuestionConstant.primaryLTCAmt);
    this.ridersInfoForm.get(riderAmountQues)?.setValidators([faceAmountAndPrimaryRiderAmountValidator(
      this.userDetailsService.getFaceAmount(), this.selectedProductCode)]);
  }

  private addMinMaxAmountValidations(): void {
    const minMaxAmountValidationFields = [
      { rider: RiderQuestionConstant.LongTermLTCSpecAmount, minAmount: 50000, maxAmount: 2500000 },
      { rider: RiderQuestionConstant.CareCoverageLTCSpecAmount, minAmount: 50000, maxAmount: 2500000 }];
    minMaxAmountValidationFields.forEach((riderField) => {
      this.ridersInfoForm.get(this.getFieldIdByXmlTag(riderField.rider))?.setValidators([minMaxAmountValidator
        (riderField.minAmount, riderField.maxAmount, riderField.rider)]);
    });
  }

  private addOwnerTypeValidation(): void {
    this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.BusinessExecRider))?.setValidators([typeOfOwnerValidator(
      this.userDetailsService.getSelectedOwners(), ['entity'], RiderQuestionConstant.BusinessExecRider,
      !this.tabService.getTabInfoByTabName(TAB.OWNER_INFO)?.isEnable
    )]);
    this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.IndividualExecRider))?.setValidators([typeOfOwnerValidator(
      this.userDetailsService.getSelectedOwners(), ['person', 'trust'], RiderQuestionConstant.IndividualExecRider,
      !this.tabService.getTabInfoByTabName(TAB.OWNER_INFO)?.isEnable, ['entity']
    )]);
    this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.BusinessExecRider))?.updateValueAndValidity();
    this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.IndividualExecRider))?.updateValueAndValidity();
  }

  showExecRiderMessage(data: Question): string {
    const dataFormControl = this.ridersInfoForm.get(this.getFieldIdByXmlTag(data.xmlTag));
    if (!dataFormControl?.hasError('ownerTypeError')) {
      if (data.xmlTag === RiderQuestionConstant.BusinessExecRider) {
        return Message.BUSINESS_SURRENDER_RIDER_ERROR;
      } else if (data.xmlTag === RiderQuestionConstant.IndividualExecRider) {
        return Message.INDIVIDUAL_SURRENDER_RIDER_ERROR;
      }
    }
  }

  updateDeathBenefitOptionInformation(data: Question): string {
    if (data.xmlTag === RiderQuestionConstant.DBOptionQuesId) {
      const dbQFieldId = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
        RiderQuestionConstant.DeathBenefitQualification))?.value?.toLowerCase();
      const dbOFieldId = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
        RiderQuestionConstant.DBOptionQuesId))?.value?.label?.toLowerCase();
      if (this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022) {
        if (dbOFieldId && dbQFieldId && (dbQFieldId.indexOf('cvat') > -1
          && dbOFieldId === 'increase by premium')) {
          return Message.DOB_AND_DBQ_MESSAGE;
        }
      } else if (this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025) {
        if (dbOFieldId && dbQFieldId && (dbQFieldId.indexOf('gpt') > -1
          && dbOFieldId === 'increase by premium')) {
          return Message.DOB_AND_DBQ_MESSAGE;
        }
      }
    }
    return null;
  }

  private disableRiders(): void {
    this.disableRidersBasedOnProduct();
  }

  private disableLTCPercentageRadioButton(): void {
    const LTCPercentageCareCoverageQues = this.getFieldIdByXmlTag(RiderQuestionConstant.LTCPercentage);
    const LTCAmountValue = this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.CareCoverageLTCSpecAmount))?.value;
    const LTCPercentageLongTermQues = this.getFieldIdByXmlTag(RiderQuestionConstant.LTCPercentageLongTerm);
    const LTCAmountValueLongTerm = this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.LongTermLTCSpecAmount))?.value;
    this.updatePercentageValue(LTCAmountValue, LTCPercentageCareCoverageQues, '4{47}4');
    this.updatePercentageValue(LTCAmountValueLongTerm, LTCPercentageLongTermQues, '2{47}2');
  }

  private updatePercentageValue(amount: string, percentageQuesId: string, valueToSet: string): void {
    if (amount && +amount > 1250000) {
      this.ridersInfoForm.get(percentageQuesId)?.setValue(valueToSet);
      this.ridersInfoForm.get(percentageQuesId)?.disable();
    } else {
      this.ridersInfoForm.get(percentageQuesId)?.enable();
    }
  }

  private disableDeathBenefitOption(): void {
    if (this.selectedProductCode === ProductCodes.SVULONE2021 ||
      this.selectedProductCode === ProductCodes.VULONE2021) {
      setTimeout(() => {
        this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.DBOptionQuesId))?.setValue({ value: 'B', label: 'Level' });
        this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.DBOptionQuesId))?.disable();
      }, 0);
    }
  }

  private disableRidersBasedOnProduct(): void {
    this.disableAcceleratedBenefitRider();
    this.disableLEABR();
    this.disableLAABR();
    this.disableCCABR();
    this.disableLTCRider();
    this.disableChangeOfRider();
    this.disableOtherInsuredRider();
    this.disableEstateProtectionRider();
    this.disableENLR();
    this.disableSupplementalRider();
    this.disableOverLoanRider();
    this.disableWaiverMonthlyDeduction();
    this.disableWOPRider();
  }

  private disableRiderBasedOnDBQ(): void {
    if (this.selectedProductCode === ProductCodes.WEALTHPRESERVESIUL2022) {
      const overloanProtection = this.getFieldIdByXmlTag(RiderQuestionConstant.OverLoanProtection);
      if (this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.DeathBenefitQualification))
        ?.value?.toLowerCase() === 'cvat') {
        this.ridersInfoForm.get(overloanProtection)?.reset();
        this.ridersInfoForm.get(overloanProtection)?.disable();
      } else {
        this.ridersInfoForm.get(overloanProtection)?.enable();
      }
    }
  }

  private disableRiderBasedOnDBO(): void {
    this.disableEstateProtectionRider();
    this.disableAcceleratedBenefitRider();
    this.disableLEABR();
    this.disableSupplementalRider();
    this.disableENLR();
    this.disableLTCRider();
  }

  private disableRiderBasedOnCurrency(): void {
    const waiverMonthlyD = this.getFieldIdByXmlTag(RiderQuestionConstant.WaiverMDProposedInsured);
    if (this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020) {
      if (+(this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.primaryLTCAmt))?.value) +
        +(this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.OtherRiderAmount))?.value) > 5000000) {
        this.ridersInfoForm.get(waiverMonthlyD)?.reset();
        this.ridersInfoForm.get(waiverMonthlyD)?.disable();
      } else {
        this.ridersInfoForm.get(waiverMonthlyD)?.enable();
      }
    }
  }

  // below method are to disable riders based on product and other selected rider

  private disableAcceleratedBenefitRider(): void {
    const dbOFieldId = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DBOptionQuesId))?.value?.label?.toLowerCase();
    const isDisable = ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020
      || this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
      || this.selectedProductCode === ProductCodes.VULONE2021)
      && this.isRiderSelected(RiderQuestionConstant.LEABRQuesId)) ||
      this.isRiderSelected(RiderQuestionConstant.LAABRQues) ||
      (this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
        && this.isRiderSelected(RiderQuestionConstant.CCABRQuesId))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
        && (!dbOFieldId || dbOFieldId?.indexOf('level') === -1 || +this.userDetailsService?.getFaceAmount() < 100000));
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.AcceleratedBenefitRider);
  }

  private disableLEABR(): void {
    const isDisable = ((this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
      || this.selectedProductCode === ProductCodes.VULONE2021
      || this.selectedProductCode === ProductCodes.WEALTHPRESERVESIUL2022
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025) &&
      this.isRiderSelected(RiderQuestionConstant.AcceleratedBenefitRider))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020
        || this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
        || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
        || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
        || this.selectedProductCode === ProductCodes.VULONE2021)
        && this.isRiderSelected(RiderQuestionConstant.LAABRQues))
      || ((this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
        || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
        || this.selectedProductCode === ProductCodes.VULONE2021)
        && this.isRiderSelected(RiderQuestionConstant.LTCRider))
      || ((this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
        || this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
        && this.isRiderSelected(RiderQuestionConstant.CCABRQuesId))
      || this.checkForLEABRBasedOnWealthAccumaulate()
      || this.checkForLEABRBasedFaceAmountAndAge();
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.LEABRQuesId);
  }

  private checkForLEABRBasedFaceAmountAndAge(): boolean {
    let primaryRiderAmount = this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.primaryLTCAmt))?.value;
    primaryRiderAmount = primaryRiderAmount ? +primaryRiderAmount : 0;
    return ((this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022 ||
      this.selectedProductCode === ProductCodes.VULONE2021 || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025) &&
      ((this.userDetailsService.getInsuredAge() < 70 && this.userDetailsService.getFaceAmount() > 5000000)
        || (this.userDetailsService.getInsuredAge() >= 70 && this.userDetailsService.getFaceAmount() > 2000000)))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020) &&
        ((this.userDetailsService.getInsuredAge() < 70 && (+this.userDetailsService.getFaceAmount() + primaryRiderAmount) > 5000000)
          || (this.userDetailsService.getInsuredAge() >= 70 && (+this.userDetailsService.getFaceAmount() + primaryRiderAmount) > 2000000)));
  }

  private checkForLEABRBasedOnWealthAccumaulate(): boolean {
    const dbOFieldId = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DBOptionQuesId))?.value?.label?.toLowerCase();
    return ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
      && ((dbOFieldId && dbOFieldId.indexOf('increase by premium') > -1)
        || this.userDetailsService.getFaceAmount() < 50000
        || this.isRiderSelected(RiderQuestionConstant.AcceleratedBenefitRider)));
  }

  private disableCCABR(): void {
    const isDisable = ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020
      || this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025)
      && this.isRiderSelected(RiderQuestionConstant.LEABRQuesId))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020
        || this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020)
        && this.isRiderSelected(RiderQuestionConstant.LAABRQues))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
        && this.isRiderSelected(RiderQuestionConstant.OtherInsuredRider))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020
        || this.selectedProductCode === ProductCodes.VULONE2021)
        && this.isRiderSelected(RiderQuestionConstant.ChangeOfRider))
      || ((this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020)
        && this.isRiderSelected(RiderQuestionConstant.AcceleratedBenefitRider))
      || ((this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
        || this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
        && (this.userDetailsService.getFaceAmount() < 50000
          || this.userDetailsService.getFaceAmount() > 2500000));
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.CCABRQuesId);
  }

  private disableWOPRider(): void {
    const isDisable = (this.selectedProductCode === ProductCodes.LIFEELEMENT2024
      && (this.userDetailsService.getFaceAmount() > 5000000));
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.WOPRider);
  }

  private disableLAABR(): void {
    const isDisable = ((this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
      || this.selectedProductCode === ProductCodes.VULONE2021
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2020
      || this.selectedProductCode === ProductCodes.ASSETEDGEEXEC2020)
      && this.isRiderSelected(RiderQuestionConstant.AcceleratedBenefitRider))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020
        || this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
        || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
        || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
        || this.selectedProductCode === ProductCodes.VULONE2021)
        && this.isRiderSelected(RiderQuestionConstant.LEABRQuesId))
      || ((this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
        || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
        || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2020
        || this.selectedProductCode === ProductCodes.ASSETEDGEEXEC2020
        || this.selectedProductCode === ProductCodes.VULONE2021)
        && this.isRiderSelected(RiderQuestionConstant.LTCRider)) ||
      ((this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
        || this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020) &&
        this.isRiderSelected(RiderQuestionConstant.CCABRQuesId))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
        && (this.userDetailsService.getFaceAmount() < 100000))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
        && (this.userDetailsService.getFaceAmount() > 100000)
        && this.isRiderSelected(RiderQuestionConstant.AcceleratedBenefitRider));
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.LAABRQues);
  }

  private disableLTCRider(): void {
    const dbOFieldVal = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DBOptionQuesId))?.value?.label?.toLowerCase();
    const isDisable = ((this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
      || this.selectedProductCode === ProductCodes.VULONE2021)
      && this.isRiderSelected(RiderQuestionConstant.LEABRQuesId))
      || this.isRiderSelected(RiderQuestionConstant.LAABRQues)
      || this.isRiderSelected(RiderQuestionConstant.ChangeOfRider)
      || ((this.selectedProductCode === ProductCodes.ASSETEDGEVUL2020
        || this.selectedProductCode === ProductCodes.ASSETEDGEEXEC2020)
        && (this.userDetailsService.getFaceAmount() < 50000)
        || (this.userDetailsService.getFaceAmount() > 2500000))
      || (this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
        && (dbOFieldVal && dbOFieldVal?.indexOf('increase by premium') > -1));
    if (isDisable) {
      this.isLTCSelected.emit('no');
    }
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.LTCRider);
  }

  private disableChangeOfRider(): void {
    const isDisable = ((this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2020
      || this.selectedProductCode === ProductCodes.ASSETEDGEEXEC2020
      || this.selectedProductCode === ProductCodes.VULONE2021)
      && this.isRiderSelected(RiderQuestionConstant.LTCRider))
      || ((this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
        && this.isRiderSelected(RiderQuestionConstant.CCABRQuesId));
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.ChangeOfRider);
  }

  private disableOtherInsuredRider(): void {
    const isDisable = ((this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
      || this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020)
      && this.isRiderSelected(RiderQuestionConstant.CCABRQuesId))
      || (this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020
        && this.userDetailsService?.getFaceAmount() < 100000);
    if (isDisable) {
      this.emitOtherRiderSelected(false);
    }
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.OtherInsuredRider);
  }

  private disableEstateProtectionRider(): void {
    const dbOFieldVal = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DBOptionQuesId))?.value?.label?.toLowerCase();
    const isDisable = (this.selectedProductCode === ProductCodes.WEALTHPRESERVESIUL2022
      && (this.userDetailsService?.getFaceAmount() < 250000
        || !dbOFieldVal || dbOFieldVal?.indexOf('level') === -1))
      || (this.selectedProductCode === ProductCodes.SVULONE2021
        && (!dbOFieldVal || dbOFieldVal?.indexOf('level') === -1));
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.EstateProtectionRider);
  }

  private disableENLR(): void {
    const dbOFieldVal = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DBOptionQuesId))?.value?.label?.toLowerCase();
    const isDisable = (this.selectedProductCode === ProductCodes.WEALTHPRESERVESIUL2022
      && (!dbOFieldVal || dbOFieldVal?.indexOf('level') === -1))
      || this.isRiderSelected(RiderQuestionConstant.SupplementalRider);
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.ExtendedNoLapseRider);
  }

  private disableSupplementalRider(): void {
    const dbOFieldVal = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DBOptionQuesId))?.value?.label?.toLowerCase();
    const isDisable = this.isRiderSelected(RiderQuestionConstant.ExtendedNoLapseRider) ||
      !dbOFieldVal || dbOFieldVal?.indexOf('level') === -1;
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.SupplementalRider);
  }

  private disableOverLoanRider(): void {
    const dbQFieldId = this.ridersInfoForm.get(this.getFieldIdByXmlTag(
      RiderQuestionConstant.DeathBenefitQualification))?.value?.toLowerCase();
    const isDisable = dbQFieldId?.indexOf('cvat') > -1;
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.OverLoanProtection);
  }

  private disableWaiverMonthlyDeduction(): void {
    const isDisable = ((this.selectedProductCode === ProductCodes.VULONE2021
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2022
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2025
      || this.selectedProductCode === ProductCodes.ASSETEDGEVUL2020
      || this.selectedProductCode === ProductCodes.ASSETEDGEEXEC2020)
      && (this.userDetailsService?.getFaceAmount() > 3500000));
    this.disableRiderBasedOnCondition(isDisable, RiderQuestionConstant.WaiverMDProposedInsured);
  }

  private disableRiderBasedOnCondition(isConditionSatisfies: boolean, riderToDisable: string, noReset?: boolean): void {
    if (isConditionSatisfies) {
      if (!noReset) {
        this.ridersInfoForm.get(this.getFieldIdByXmlTag(riderToDisable))?.reset();
      }
      this.ridersInfoForm.get(this.getFieldIdByXmlTag(riderToDisable))?.disable();
    } else {
      this.ridersInfoForm.get(this.getFieldIdByXmlTag(riderToDisable))?.enable();
    }
  }

  private isRiderSelected(xmlTag: string): boolean {
    return this.ridersInfoForm.get(this.getFieldIdByXmlTag(xmlTag))?.value ? true : false;
  }

  private disableAllRidersIfFaceAmountIsBlank(): void {
    this.isLTCSelected.emit('no');
    this.emitOtherRiderSelected(false);
    setTimeout(() => {
      const excludeFields = [this.getFieldIdByXmlTag(RiderQuestionConstant.DBOptionQuesId),
      this.getFieldIdByXmlTag(RiderQuestionConstant.DeathBenefitQualification),
      this.getFieldIdByXmlTag(RiderQuestionConstant.GuaranteePremium)];
      Object.keys(this.ridersInfoForm.controls).forEach(fieldId => {
        if (!excludeFields.includes(fieldId)) {
          this.ridersInfoForm.get(fieldId).reset();
          this.ridersInfoForm.get(fieldId).disable();
        }
      });
    }, 10);
  }

  private isOtherRiderSelected(data: Question): void {
    if (data.xmlTag === RiderQuestionConstant.OtherInsuredRider) {
      this.emitOtherRiderSelected(this.ridersInfoForm.get(data.fieldId)?.value);
    }
  }

  private isLTCRiderSelected(data: Question): void {
    if (data.xmlTag === RiderQuestionConstant.LTCRider) {
      this.emitLTCRiderSelectedVal(data.fieldId);
    }
  }

  private emitLTCRiderSelectedVal(fieldId: string): void {
    if (this.ridersInfoForm.get(fieldId)?.value) {
      this.isLTCSelected.emit('yes');
    } else {
      this.isLTCSelected.emit('no');
    }
  }

  private emitOtherRiderSelected(isSelected: boolean): void {
    if (this.selectedProductCode === ProductCodes.WEALTHPRESERVE2IUL2020
      || this.selectedProductCode === ProductCodes.WEALTHACCUMULATEIUL2020) {
      isSelected ? this.otherRiderSelected.emit('yes') : this.otherRiderSelected.emit('no');
    } else if (this.selectedProductCode === ProductCodes.SVULONE2021
      || this.selectedProductCode === ProductCodes.WEALTHPRESERVESIUL2022) {
      // do nothing
    } else {
      this.otherRiderSelected.emit('no');
    }
  }

  private checkForOptionalTab(): void {
    this.emitLTCRiderSelectedVal(this.getFieldIdByXmlTag(RiderQuestionConstant.LTCRider));
    this.emitOtherRiderSelected(this.ridersInfoForm.get(this.getFieldIdByXmlTag(RiderQuestionConstant.OtherInsuredRider))?.value);
  }
}
