<section class="electronic-signature-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color"> Review and Send Email </h1>
  </div>

  <div class="instruction-msg">
    <p class="mobile-info">
      <span *ngIf="readMore">
        <lfg-alert-message type="info">
          {{ELECTRONIC_SIGN_CONST.Instructional_Info_Text | truncate : 108}}
          <span class="read-link">
            <a *ngIf="readMore" (click)="linkClicked()">Read more
              <i class="fas fa-angle-down" aria-hidden="true"></i>
            </a>
          </span>
        </lfg-alert-message>
      </span>
      <span *ngIf="!readMore">
        <lfg-alert-message type="info">
          {{ELECTRONIC_SIGN_CONST.Instructional_Info_Text}}
          <span class="read-link">
            <a *ngIf="!readMore" (click)="linkClicked()">Read less
              <i class="fas fa-angle-up" aria-hidden="true"></i>
            </a>
          </span>
        </lfg-alert-message>
      </span>
    </p>
    <p class="desktop-info">
      <span><lfg-alert-message type="info">{{ ELECTRONIC_SIGN_CONST.Instructional_Info_Text }}</lfg-alert-message></span>
    </p>
  </div>

  <ng-container *ngIf="displayProgressModal">
    <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="Message.ENVELOPE_CREATION_LOADER_MESSAGE" [hasProgressPercentage]="true" [progressPercent]="progressPercentage"></app-progress-overlay>
  </ng-container>

  <div *ngIf="mailSentSuccessfully"><lfg-alert-message type="success">{{ ELECTRONIC_SIGN_CONST.Mail_Sent }}</lfg-alert-message></div>
  <div *ngIf="envelopeCreated || showCancelPackageMessage"><lfg-alert-message type="success">{{ ELECTRONIC_SIGN_CONST.Packet_Success_Msg_2 }}</lfg-alert-message></div>
  <div *ngIf="errorOccured"><lfg-alert-message type="error">{{ Message.GENERIC_ERROR }}</lfg-alert-message></div>
  <div class="required-message">{{ ELECTRONIC_SIGN_CONST.Required_Message }} </div>

  <!-- element for link -->
  <div class="signature-invitation-link">
    <a class="btn-link" type="button" (click)="openEmailExampleModal()">{{ ELECTRONIC_SIGN_CONST.View_Invitation_Link }}</a>
  </div>

  <!-- Element for Line -->
  <div class="section-divider">
  </div>

  <ng-container>
    <!-- element for question-header -->

    <div *ngIf="showLoader > 0">
      <lfg-loader></lfg-loader>
    </div>

    <div class="signer-info-container" *ngIf="tableDataLoaded">
      <div class="questions-header section-header header-section">
        <h2>{{ 'SIGNERS INFORMATION' | uppercase}}</h2>
        <span class="refresh-btn-container">
          <a class="btn-link btn-refresh" [ngClass]="{'btn--link-disabled': disableForm}" id="refreshBtn" type="button" (click)="refreshStatus()"><i class="fas fa-redo refresh-icon" aria-hidden="true"></i> Refresh </a>
        </span>
      </div>

      <!-- primary table -->
      <lfg-primary-table [tableData]="tableData" id="case-info-dbtable">
        <ng-template #tableCelContentTemplate let-column="column" let-row="row">
          <div tabindex="0" *ngIf="column.columnFlag === 'name'">{{ row.fullName | titlecase }} </div>
          <div tabindex="0" *ngIf="column.columnFlag === 'type'">{{ row.displayRole | titlecase}}<span *ngIf="isTrustAvailable && row.displayRole?.toLowerCase().indexOf('owner') > -1">{{" (Trustee)"}}</span></div>
          <div tabindex="0" *ngIf="column.columnFlag === 'phone'">{{ row.sMSAuthentication?.senderProvidedNumbers?.senderProvidedNumber[0] | phoneNumber}}</div>
          <div tabindex="0" *ngIf="column.columnFlag === 'email'">{{ row.email | lowercase}}</div>
          <div tabindex="0" *ngIf="column.columnFlag === 'status'">
            <span *ngIf="!row.docusignStatus"><i aria-hidden="true" class="fas fa-minus-circle not-started-icon"></i><span class="bold-font">Email not sent</span></span>
            <span *ngIf="row.docusignStatus"><i aria-hidden="true" [attr.class]="row.displayIconClass"></i><span class="bold-font">{{ row.displayDocuSignStatus | titlecase }}</span></span>
          </div>
          <div tabindex="0" *ngIf="column.columnFlag === 'eSignMethod'">{{ row.eSignatureMethod | titlecase }} </div>
        </ng-template>
      </lfg-primary-table>

      <!-- button -->
      <div class="send-button-container">
        <lfg-left-button-tray><button lfgDefaultButton type="button" (click)="sendEmail()" [disabled]="activeEnvelopeStatus.includes(activePacketStatus?.toLowerCase()) || disableForm || (!this.tableData.data || this.tableData.data?.length == 0 )">SEND EMAILS</button></lfg-left-button-tray>
      </div>
    </div>

    <div class="package-history-container">
      <!-- packets - data -->
      <ng-conatiner *ngIf="packets && packets.length > 0">

        <div class="questions-header section-header">
          <h2>{{ 'PACKET SUBMISSION HISTORY' | uppercase }}</h2>
        </div>
        <p>{{ELECTRONIC_SIGN_CONST.Packet_Exist_Message}}</p>

        <!-- Packet - accordion -->
        <ng-container>
          <div class="lfg-accordion">
            <lfg-accordion [autoScroll]="true">
              <lfg-panel title="{{'Packet Details (' + (packet.envelopeCreatedDate | date:'MM/dd/yyyy hh:mm a') + ')'}}" *ngFor="let packet of packets">
                <span class="accordion__sub-title-hook" *ngIf="packet.envelopeStatus?.toLowerCase() === 'voided' || packet.envelopeStatus?.toLowerCase() === 'declined' || packet.envelopeStatus?.toLowerCase() === 'cancelled'"><span class="packet-status-subheader">Cancelled</span></span>
                <!-- Element for Line -->
                <div class="section-divider"></div>
                <!-- Element  to display signer's data -->
                <div class="signers-data">
                  <div class="row" *ngFor="let signer of packet.recipients">
                    <div class="column">{{signer.displayRole | titlecase}}<span *ngIf="isTrustAvailable && signer.displayRole?.toLowerCase().indexOf('owner') > -1">{{" (Trustee)"}}</span><span>: </span><span class="bold-font">{{signer.fullName | uppercase}}</span></div>
                    <div class="column">{{'Email: '}}<span class="bold-font">{{signer.email}}</span></div>
                    <div class="column">{{'Status: '}}<span class="bold-font">{{(signer.displayDocuSignStatus | titlecase)}}</span></div>
                  </div>
                </div>

                <!-- Element for Button -->
                <lfg-left-button-tray *ngIf="packet.envelopeStatus?.toLowerCase() === 'sent' || packet.envelopeStatus?.toLowerCase() === 'created' || packet.envelopeStatus?.toLowerCase() === 'delivered'">
                  <button lfgDefaultButton type="button" (click)="resendPacket()" [disabled]="disableForm">RESEND THIS PACKET</button>
                  <button lfgLinkButton (click)="updateCancelModalStatus(true)" [ngClass]="{'btn--link-disabled': disableForm}">CANCEL PACKET</button>
                </lfg-left-button-tray>
              </lfg-panel>
            </lfg-accordion>
          </div>
        </ng-container>
      </ng-conatiner>
    </div>


  </ng-container>
</section>

<lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeModalPopup(modalConfig)" class="resend-modal-container">
  <div modal-header>
    <div>
      <h2 class="popup-header">{{ELECTRONIC_SIGN_CONST.Resend_packet_heading}}</h2>
    </div>
  </div>
  <div modal-body>
    <div>
      <p class="popup-info">{{ELECTRONIC_SIGN_CONST.Resend_packet_info_text}}</p>
    </div>
    <ng-container>
      <form [formGroup]="resendEmailForm" id="resendEmailForm" method="post" *ngIf="resendEmailForm && resendModalFormCreated">
        <div class="information-content">
          <div class="signers-heading font-bold">Signers</div>

          <div class="signers-list">
            <ng-container *ngFor="let signer of filteredRecipients">
              <div class="recipient-container">
                <div class="lfg-checkbox">
                  <input type="checkbox" value="checked" name="{{signer.signerGuid + '_role'}}" id="{{signer.signerGuid + '_role'}}" (change)="onCheckboxChange(signer.signerGuid)" [formControlName]="signer.signerGuid + '_role'">
                  <label class="wrap wrap-check checkbox-label-container" for="{{signer.signerGuid + '_role'}}">
                    <span class="signer-info">{{"Role: "}}<span class="font-bold">{{signer.displayRole | titlecase}}</span></span>
                    <span class="signer-info">{{"Name: "}}<span class="font-bold">{{signer.fullName | titlecase}}</span></span>
                    <span class="signer-info">{{"Email: "}}<span class="font-bold break-word">{{signer.email | lowercase}}</span></span>
                  </label>
                </div>

                <ng-container *ngIf="resendEmailForm.get(signer.signerGuid + '_role')?.value">
                  <div class="text-box">
                    <lfg-input-wrap>
                      <input lfgInput id="{{signer.signerGuid + '_email'}}" [formControlName]="signer.signerGuid + '_email'" placeholder="{{'Enter email address'}}" name="{{signer.signerGuid + '_email'}}" aria-live="off" type='text' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="email" maxlength='50' required (input)="validateConfirmEmail(signer.signerGuid)">
                      <div aria-live="assertive" *ngIf="resendEmailForm.get(signer.signerGuid + '_email').touched && resendEmailForm.get(signer.signerGuid + '_email').invalid">
                        <lfg-error message="Please enter a valid email address."></lfg-error>
                      </div>
                    </lfg-input-wrap>
                  </div>

                  <div class="text-box">
                    <lfg-input-wrap>
                      <input lfgInput id="{{signer.signerGuid + '_confirmEmail'}}" [formControlName]="signer.signerGuid + '_confirmEmail'" placeholder="{{'Confirm email address'}}" name="{{signer.signerGuid + '_confirmEmail'}}" aria-live="off" type='text' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="email" maxlength='50' (input)="validateConfirmEmail(signer.signerGuid)" required>
                      <lfg-error *ngIf="resendEmailForm.get(signer.signerGuid + '_confirmEmail').touched && resendEmailForm.get(signer.signerGuid + '_confirmEmail').invalid && !resendEmailForm.get(signer.signerGuid + '_confirmEmail').errors?.confirmEmailError" message="Please enter a valid email address."></lfg-error>
                      <lfg-error *ngIf="resendEmailForm.get(signer.signerGuid + '_confirmEmail').touched && resendEmailForm.get(signer.signerGuid + '_confirmEmail').invalid && resendEmailForm.get(signer.signerGuid + '_confirmEmail').errors?.confirmEmailError" message="{{resendEmailForm.get(signer.signerGuid + '_confirmEmail').errors.confirmEmailError}}"></lfg-error>
                    </lfg-input-wrap>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="filteredRecipients?.length === 0">
              <div class="margin-top-20">No Signer available</div>
            </ng-container>
          </div>
        </div>
      </form>

    </ng-container>
  </div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button class='btn' lfgDefaultButton [disabled]="disableResendEmailButton()" (click)="resendEmails()">RESEND</button>
      <button class='btn' lfgLinkButton (click)="closeModalPopup(modalConfig)">Cancel</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>

<lfg-modal-popup *ngIf="emailExampleModalConfig" [modalConfig]="emailExampleModalConfig" (closeModalPopup)="closeModalPopup(emailExampleModalConfig)" class="email-example-container">
  <div modal-header>
    <h1>Electronic signature example</h1>
  </div>
  <div modal-body [innerHtml]="ESignatureEmailTemplate.EmailTemplate?.replace('{agentEmail}', agentEmail)">
  </div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button lfgDefaultButton (click)="closeModalPopup(emailExampleModalConfig)" aria-label="Ok button">OK</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>

<lfg-modal-popup *ngIf="cancelModal" [modalConfig]="cancelModal" (closeModalPopup)="updateCancelModalStatus(false)" class="cancel-modal-container">
  <div modal-header>
    <h1>{{ELECTRONIC_SIGN_CONST.Cancel_modal_heading}}</h1>
  </div>
  <div modal-body>
    <p class="font-bold pad-10">{{ELECTRONIC_SIGN_CONST.Cancel_modal_info_1}}</p>
    <p>{{ELECTRONIC_SIGN_CONST.Cancel_modal_info_2}}</p>
  </div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button lfgDefaultButton (click)="cancelPacket()" aria-label="Yes I am sure button">YES, I'M SURE</button>
      <button lfgDefaultButton (click)="updateCancelModalStatus(false)" aria-label="No button">NO</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>
