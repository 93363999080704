<section class="maturing-indexed-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Maturing Indexed Account Segment Allocation
    </h1>

    <ng-container *ngIf="!disableForm">
      <lfg-alert-message *ngIf="isTabVisited && (!formValid || (isFundsRequired && totalFundPercentage != 100))" type="error">{{errorMessage}}</lfg-alert-message>
      <lfg-alert-message *ngIf="(totalFundPercentage != 100 && totalFundPercentage != 0) && isFundsRequired" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
    </ng-container>

    <section>
      <form class="form-group" [formGroup]="maturingFundsForm" id="maturingFundsForm" method="post" *ngIf="maturingFundsData && maturingFundsData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of maturingFundsData; let ind = index;">
          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText != 'Information' && data.subText != 'Header' && data.subText != 'table-header' && data.subText != 'table-subheader' && data.subText != 'table-label' ">
            <h2>{{ data.questionText }}</h2>
          </div>



          <!-- Form element for radio question having longer desc -->
          <div aria-live="assertive" class="lfg-customRadio-field-long" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.questionOption[0].description?.length > 20" [ngClass]="{'radio--err': (maturingFundsForm.get(data.fieldId).touched && maturingFundsForm.get(data.fieldId).invalid)}">
            <span class="radio-question-container">
              <p class="question-radio_text">{{ data.questionText }}</p>
              <div class="radio-button-container">
                <label class="radio-input-label" [ngClass]="{'radio-button-checked': maturingFundsForm.get(data.fieldId).value === data.questionOption[0].value}">
                  <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="maturingFundsForm.get(data.fieldId).value === data.questionOption[0].value"></i>
                  <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="maturingFundsForm.get(data.fieldId).value !== data.questionOption[0].value"></i>
                  <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onMIRadioChange(data)" />
                  <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                </label>
                <label class="radio-input-label" [ngClass]="{'radio-button-checked': maturingFundsForm.get(data.fieldId).value === data.questionOption[1].value}">
                  <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="maturingFundsForm.get(data.fieldId).value === data.questionOption[1].value"></i>
                  <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="maturingFundsForm.get(data.fieldId).value !== data.questionOption[1].value"></i>
                  <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onMIRadioChange(data)">
                  <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                </label>
              </div>
            </span>
            <lfg-error *ngIf="maturingFundsForm.get(data.fieldId).touched && maturingFundsForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, maturingFundsForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <ng-container *ngIf="isFundsRequired">

            <div class="section-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Header'">
              <div>
                <p><i class="fas fa-list-alt" aria-hidden="true"></i><span>{{ data.questionText | uppercase}}</span></p>
              </div>
              <div class="btn-reset-container"> <a class="btn-link btn-reset" [ngClass]="{'btn--link-disabled': disableForm}" id="resetBtn" type="button" (click)="resetForm()">
                  <i class="fas fa-undo reset-icon" aria-hidden="true"></i> Reset all selections </a></div>
            </div>

            <!-- Funds table -->
            <div class="funds-table-container" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-header'">
              <div class="total-percent">
                <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
              </div>
              <div class="funds-table-header funds-table">
                <div class="table-column col-1">{{data.questionText | uppercase}} </div>
                <div class="table-column col-2">INPUT %</div>
              </div>
            </div>
            <div class="funds-table funds-table-subheader" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-subheader'">
              <div class="table-column">{{data.questionText | uppercase}} </div>
            </div>
            <div class="funds-table fund-table-label funds-table-subheader" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-label'">
              <div class="table-column">{{data.questionText}} </div>
            </div>
            <div class="funds-table funds-table-row" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes && data.notes.indexOf('FundSelect') > -1">
              <div class="table-column col-1">{{data.questionText}} </div>
              <div class="table-column col-2">
                <label for="{{data.fieldId}}" id="fund-field-label-{{data.fieldId}}">
                  <span class="visually-hidden">Label for the fund input</span>
                  <input lfgInput id="{{data.fieldId}}" name="{{ data.questionText }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data.required == 'true'">
                </label>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isFundsRequired">

          <div class="additional-funds-section">
            <span class="accrd-header">
              Expand to display additional funds
            </span>
            <span class="icon-container">
              <a (click)="toggleAccordionStatus()"><i class="fa arrow-button" [ngClass]="{'fa-chevron-down' : !openAccordion , 'fa-chevron-up' : openAccordion}" aria-hidden="true"></i></a>
            </span>
          </div>

          <div *ngIf="openAccordion" class="accordion-content">
            <ng-container class="form-control-parent" *ngFor="let data2 of maturingFundsData2; let ind = index;">
              <!-- Funds table -->
              <div class="funds-table funds-table-header" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-header'">
                <div class="table-column col-1">{{data2.questionText | uppercase}} </div>
                <div class="table-column col-2">INPUT %</div>
              </div>
              <div class="funds-table funds-table-subheader fund-table-label" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-label'">
                <div class="table-column">{{data2.questionText}} </div>
              </div>
              <div class="funds-table funds-table-subheader" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-subheader'">
                <div class="table-column">{{data2.questionText | uppercase}} </div>
              </div>
              <div class="funds-table funds-table-row mature-index-row" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.TEXT && data2.notes && data2.notes.indexOf('FundSelect') > -1">
                <div class="table-column col-1">{{data2.questionText}}
                  <div *ngIf="isMoneyMarketIsSelected && data2.questionText?.indexOf('Government Money Market') > -1" class="fund-info"><lfg-alert-message type="info">{{ Message.VUL_DCA_ERROR }}</lfg-alert-message></div>
                </div>
                <div class="table-column col-2">
                  <label for="{{data2.fieldId}}" id="fund-field-label-{{data2.fieldId}}">
                    <span class="visually-hidden">Label for the fund input</span>
                    <input lfgInput name="{{ data2.questionText }}" aria-live="off" id="{{data2.fieldId}}" type='text' formControlName='{{ data2.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data2.required == 'true'">
                  </label>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="total-percent percent-bottom">
            <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
          </div>

        </ng-container>
      </form>
      <lfg-alert-message *ngIf="(totalFundPercentage != 100 && totalFundPercentage != 0) && isFundsRequired" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
    </section>
  </div>
</section>
