<section class="additional-info-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      Additional Information
    </h1>

    <div class="loader-container" *ngIf="showLoader">
      <lfg-loader></lfg-loader>
    </div>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="additionalInfoForm" id="additionalInfoForm" method="post" *ngIf="additionalInfoData && additionalInfoData.length> 0">
        <ng-container class="form-control-parent" *ngFor="let data of additionalInfoData;">

          <!-- element for header-->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText != 'Information'">
            {{ data.questionText }}
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Form element for check box  -->
          <div class="form--control form--control__full-width checkbox-accrd" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX" [ngClass]="{'checkbox-control' : (additionalInfoForm.get(data.fieldId).value && openAccordion[data.xmlTag])}">
            <div class="lfg-checkbox custom-checkbox">
              <span class="accrd-header">
                <span class="checkbox-input-label-icon">
                  <span class="checkbox-input-label">
                    <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)" (click)="onCheckBoxClick($event, data)">
                    <label class="wrap wrap-check" for="{{ data.fieldId }}" [innerHTML]="data.questionText" [ngClass]="{'highlight-text': additionalInfoForm.get(data.fieldId).value}"></label>
                  </span>
                  <span class="file-status-icon desktop-icon" *ngIf="!isQuestionValid[data.xmlTag] && additionalInfoForm.get(data.fieldId).value && !openAccordion[data.xmlTag]"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
                </span>
                <span class="filename" *ngIf="additionalInfoForm.get(data.fieldId).value && isQuestionValid[data.xmlTag]">{{fileName[data.xmlTag]}}</span>
              </span>
              <span class="icon-container" *ngIf="(additionalInfoForm.get(data.fieldId).touched && additionalInfoForm.get(data.fieldId).value) || additionalInfoForm.get(data.fieldId).value ">
                <span class="file-status-icon mobile-icon" *ngIf="!isQuestionValid[data.xmlTag] && additionalInfoForm.get(data.fieldId).value && !openAccordion[data.xmlTag]"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
                <a (click)="toggleAccordionStatus(data.xmlTag)"><i class="fa arrow-button" [ngClass]="{'fa-chevron-down' : !openAccordion[data.xmlTag] , 'fa-chevron-up' : openAccordion[data.xmlTag]}" aria-hidden="true"></i></a>
              </span>
            </div>

            <!-- Form element for nested ques  -->
            <div *ngIf="data.hasReflexive == 'true' && additionalInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'checkbox-nested-questions': checkIfQuestionDisplayed(data), 'hide-ques': !openAccordion[data.xmlTag]}">
              <ng-container *ngFor="let additionalInfoChild of data.reflexiveQuestionAL; index as addChildIndx">
                <ng-container *ngIf="nestedQuestionCheckAdditionalInfo(data, additionalInfoChild, parentAnswer)">

                  <!-- Question Text -->
                  <div class="questions-header" *ngIf="additionalInfoChild.controlTypeDesc == appConfig.fieldTypes.HDR && additionalInfoChild.subText != 'Line' && additionalInfoChild.subText != 'Button'">
                    {{additionalInfoChild.questionText}}
                  </div>

                  <!-- Form element for Text  -->
                  <div class="form--control" *ngIf="additionalInfoChild.controlTypeDesc == appConfig.fieldTypes.TEXT && additionalInfoChild.subText != 'File'">
                    <lfg-input-wrap>
                      <input lfgInput id="{{additionalInfoChild.fieldId}}" placeholder="{{ addCorrectPlaceHolder(additionalInfoChild, 'placeHolder') }}" name="{{ additionalInfoChild.fieldId }}" aria-live="off" type='text' formControlName='{{ additionalInfoChild.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="additionalInfoChild.maxLength" [required]="additionalInfoChild.required == 'true'">
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for input type file  -->
                  <div class="form--control" *ngIf="additionalInfoChild.controlTypeDesc == appConfig.fieldTypes.TEXT && additionalInfoChild.subText == 'File'">
                    <label id="fileLabel" for="{{additionalInfoChild.fieldId}}">
                      <span class="visually-hidden">Label for the input</span>
                      <input id="{{additionalInfoChild.fieldId}}" type="file" accept=".pdf" (change)="onFileSelected($event, additionalInfoForm.get(additionalInfoChild.fieldId) , data.xmlTag, data)" name="{{ additionalInfoChild.fieldId }}" formControlName='{{ additionalInfoChild.fieldId }}' required="data.required == 'true'" [ngClass]="{'file-err': hasFileError[data.xmlTag]}">
                    </label>
                    <div class="file-info" *ngIf="!(hasFileError[data.xmlTag])">{{fileInfoMessage}}</div>
                    <!-- Get error message from service-->
                    <lfg-error *ngIf="hasFileError[data.xmlTag]" [message]="hasFileError[data.xmlTag]"></lfg-error>

                  </div>

                  <!-- view file button -->
                  <div class="form--control viewfile-button" *ngIf="additionalInfoChild.subText == 'Button' && additionalInfoChild.questionText == 'View File' " (click)="viewFile()">
                    <a [ngClass]="{disableLink: !fileName[data.xmlTag]}" *ngIf="!fileName[data.xmlTag]" >{{additionalInfoChild.questionText}}</a>
                    <a *ngIf="fileName[data.xmlTag]">{{fileName[data.xmlTag]}}</a>
                  </div>

                  <!-- element for line-->
                  <div class="section-divider" *ngIf="additionalInfoChild.controlTypeDesc == appConfig.fieldTypes.HDR && additionalInfoChild.subText == 'Line'">
                  </div>

                  <!-- element for remove button -->
                  <div class="form--control remove-button" *ngIf="additionalInfoChild.controlTypeDesc == appConfig.fieldTypes.HDR && additionalInfoChild.subText == 'Button' && additionalInfoChild.questionText !== 'View File'">

                    <a [ngClass]="{disableLink: !fileName[data.xmlTag] || disableForm}" (click)="openModal($event, data, true)"><i class="remove-icon fa fa-times-circle" [ngClass]="{disableIcon: !fileName[data.xmlTag]}" aria-hidden="true"></i>{{additionalInfoChild.questionText}}</a>
                  </div>
                </ng-container>

              </ng-container>
            </div>

          </div>




        </ng-container>
      </form>
    </section>
  </div>

</section>

<lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeModalPopup(modalConfig)">
  <div modal-header>
    <div>
      <h2 class="lfg-color"><strong>{{"Are you sure?"}}</strong></h2>
    </div>
  </div>
  <div modal-body>
    <div> You are about to remove this attachment and clear all input data.</div>
  </div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button class='btn' lfgDefaultButton (click)="removeAttachment()" aria-label="Yes">Yes</button>
      <button class='btn' lfgBorderButton (click)="closeModalPopup(modalConfig)" aria-label="No">No</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>
