import packageInfo from '../config/envConfig.json';//ocal, dev, test, preprod, prod, local, dev, test, preprod, prod, 
let envConfig = packageInfo.prod;

const windowHostName = `${window.location.hostname}`;

if (windowHostName === 'localhost') {
  envConfig = packageInfo.local;
} else if (windowHostName === 'eticket-dev.web.lfg.com' || windowHostName === 'lincolnfinancial-test.lfg.com') {
  envConfig = packageInfo.dev;
} else if (windowHostName === 'eticket-test.web.lfg.com' || windowHostName === 'lincolnfinancial-uat.lfg.com') {
  envConfig = packageInfo.test;
} else if (windowHostName === 'lincolnfinancial-preprod.lfg.com') {
  envConfig = packageInfo.preprod;
}

export const environment = {
  production: true,
  baseUrl: envConfig.baseUrl,
  baseUrlETicket: envConfig.baseUrlEticket,
  baseUrlETicketDocs: envConfig.baseUrlEticketDocs,
  baseUrlETicketInterface: envConfig.baseUrlEticketInterface,
  uiBaseUrl: envConfig.uiUrl,
  ipipelineUrl: envConfig.ipipelineUrl,
  config: {
    isEnabledisplayTxt: false,
  },

  auth: {
    domain: envConfig.auth.domain,
    clientId: envConfig.auth.clientId,
    redirectUri: window.location.href,
    audience: envConfig.auth.audience,
  },

  serviceUrls: {
    apiUrl: envConfig.apiUrl,
  },
};
