import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DelegationService } from 'src/app/shared/services/delegation.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import {
  AgentInfoConstant, AgentInfoContConstant, ExistingInsuranceConstant,
  ExistingInsuranceProposedBConstant,
  LTCExistingInsConst,
  OwnerInfoConstant, TrustInfoConstant, WAReplacementConst, WAReplacementProposedBConst
} from 'src/config/constants';
import { ACCESS_TYPE } from 'src/config/delegationFunctionality.config';

@Component({
  selector: 'app-grid-header',
  templateUrl: './grid-header.component.html',
  styleUrls: ['./grid-header.component.scss'],
})
export class GridHeaderComponent implements OnInit {
  @Input() disableForm: boolean;
  @Input() gridForm: FormGroup;
  @Input() grid;
  @Input() gridSequences: any[];
  @Input() index: number;
  @Input() type: string;
  @Input() pageName: string;
  @Input() subType: string;
  @Input() isGridValid = false;
  @Output() removeGrid: EventEmitter<any> = new EventEmitter();
  @Input() existingInsAnswers: {}; // variation in existing insurance header based on answers
  @Input() forProposedInsured: boolean;

  isStateDE = false;
  isStateMN = false;
  isStateMA = false;
  isStateIN = false;
  isStateMI = false;
  insuredDOB: Date;
  insuredName: string;
  readAccessOnly = false;

  EI_CONST = ExistingInsuranceConstant;
  readonly AI_CONST = AgentInfoConstant;
  readonly AIC_CONST = AgentInfoContConstant;
  readonly OWNER_CONST = OwnerInfoConstant;
  readonly TRUST_CONST = TrustInfoConstant;
  POLICY_CONST: any;

  constructor(private userService: UserDetailsService, private delegateService: DelegationService) {
    // write code here
  }

  ngOnInit(): void {
    this.EI_CONST = this.forProposedInsured ? ExistingInsuranceProposedBConstant : ExistingInsuranceConstant;
    const policyConst = this.pageName === 'WA-REPLACEMENT-PROPOSED-B' ? WAReplacementProposedBConst : LTCExistingInsConst;
    this.POLICY_CONST = this.pageName === 'WA-REPLACEMENT' ? WAReplacementConst : policyConst;
    this.isStateDE = this.userService.getInsuredState()?.toLowerCase() === 'de';
    this.isStateMN = this.userService.getInsuredState()?.toLowerCase() === 'mn';
    this.isStateMA = this.userService.getInsuredState()?.toLowerCase() === 'ma';
    this.isStateIN = this.userService.getInsuredState()?.toLowerCase() === 'in';
    this.isStateMI = this.userService.getInsuredState()?.toLowerCase() === 'mi';
    this.insuredDOB = this.userService.getInsuredDOB();
    this.insuredName = this.userService.getInsuredFirstName() + ' ' + this.userService.getInsuredLastName();
    this.readAccessOnly = this.delegateService.getUserAccessibilityConfig()?.accessType === ACCESS_TYPE.READONLY;
  }

  removeGridInstance(obj: any, index?: number, deletedSequence?: boolean): void {
    const removeGridEvent = { obj, index, deletedSequence };
    this.removeGrid.emit(removeGridEvent);
  }

  getValue(value: string): string {
    if (value.indexOf('}') > -1) {
      return value?.substring(value.indexOf('}') + 1);
    }
    return value;
  }
}
