<lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" class="agentInfoModal" (closeModalPopup)="closeModalPopup(modalConfig)">
  <div modal-header>
    <h1 *ngIf="invalidAgents.length > 0">Agent Failed Validation</h1>
    <h1 *ngIf="invalidAgents.length === 0">Agent Validation</h1>
  </div>
  <div modal-body class="modal-popup__body--scroll">
    <div class="result-modalTable-section">
      <lfg-alert-message type="error" *ngIf="invalidAgents.length > 0">{{message[0]}}</lfg-alert-message>

      <div *ngIf="invalidAgents.length > 0" class="agents-list">
        <div *ngFor="let agent of invalidAgents">
          <p><strong>{{agent.agentFirstName + " " + agent.agentLastName}}</strong></p>
        </div>
      </div>

      <div *ngIf="invalidAgents.length === 0">
        <lfg-alert-message type="info">{{agentSuccessMessage}}</lfg-alert-message>
      </div>
    </div>
  </div>
  <div modal-footer class="modal-btn-footer">
    <div class="info-text-btn" *ngIf="invalidAgents.length > 0 && message[1]">
      <lfg-alert-message type="info">{{message[1]}}</lfg-alert-message>
    </div>
    <lfg-left-button-tray>
      <button class='btn' lfgDefaultButton aria-label="Ok" (click)="closeModalPopup(modalConfig)">Ok</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>
