<section class="dollar-cost-averaging-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Dollar Cost Averaging
    </h1>

    <ng-container *ngIf="!disableForm">
      <lfg-alert-message *ngIf="isTabVisited && (!formValid || (!isVULOneProduct && totalFundPercentage != 100))" type="error">{{errorMessage}}</lfg-alert-message>
      <lfg-alert-message *ngIf="totalFundPercentage != 100 && totalFundPercentage != 0" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
    </ng-container>
    <section>
      <form class="form-group" [formGroup]="dollarCostAveragingForm" id="dollarCostAveragingForm" method="post" *ngIf="dollarCostAveragingData && dollarCostAveragingData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of dollarCostAveragingData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText != 'Information' && data.subText != 'Header' && data.subText != 'table-header' && data.subText != 'table-subheader' && data.subText != 'table-label' ">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>


          <!-- Question Info -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>


          <!-- Form element for radio question -->
          <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (dollarCostAveragingForm.get(data.fieldId).touched && dollarCostAveragingForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText }}</p>
              <div class="radio-btn-rect custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="dollarCostAveragingForm.get(data.fieldId).touched && dollarCostAveragingForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, dollarCostAveragingForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <ng-container *ngIf="!isVULOneProduct">

            <!-- Funds table -->
            <div class="funds-table-container" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-header'">
              <div class="total-percent">
                <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
              </div>
              <div class="funds-table funds-table-header">
                <div class="table-column col-1">{{data.questionText | uppercase}} </div>
                <div class="table-column col-2">INPUT %</div>
              </div>
            </div>
            <div class="funds-table funds-table-subheader fund-table-label" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-label'">
              <div class="table-column">{{data.questionText}} </div>
            </div>
            <div class="funds-table funds-table-subheader" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-subheader'">
              <div class="table-column">{{data.questionText | uppercase}} </div>
            </div>
            <div class="funds-table funds-table-row" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes && data.notes.indexOf('FundSelect') > -1">
              <div class="table-column col-1">{{data.questionText}} </div>
              <div class="table-column col-2">
                <label for="{{data.fieldId}}" id="fund-field-label-{{data.fieldId}}">
                  <span class="visually-hidden">Label for the fund input</span>
                  <input lfgInput name="{{ data.questionText }}" id="{{data.fieldId}}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data.required == 'true'">
                </label>
              </div>
            </div>

            <div class="section-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Header'">
              <div>
                <p><i class="fas fa-list-alt" aria-hidden="true"></i><span>{{ data.questionText | uppercase}}</span></p>
              </div>
              <div class="btn-reset-container"> <a class="btn-link btn-reset" [ngClass]="{'btn--link-disabled': disableForm}" id="resetBtn" type="button" (click)="resetForm()">
                  <i class="fas fa-undo reset-icon" aria-hidden="true"></i> Reset all selections </a></div>
            </div>

          </ng-container>

          <!-- Nestion question Level 1 -->
          <ng-container *ngIf="data.reflexiveQuestionAL.length > 0">
            <div *ngIf="data.hasReflexive == 'true' && dollarCostAveragingForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
              <ng-container *ngFor="let dcaChildL1 of data.reflexiveQuestionAL; index as dcaChildL1ix">
                <ng-container *ngIf="nestedQuestionCheckDCAInfo(data, dcaChildL1, parentAnswer)">

                  <!-- Question Info for DCA info-->
                  <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag === FundConst.dollarCostAverage && showDollarCostFundInfo && dcaChildL1.xmlTag === FundConst.accountToDCA">
                    <lfg-alert-message type="info">{{ Message.DCA_RELATED_INFO }}</lfg-alert-message>
                  </div>

                  <div class="question-info" aria-live="assertive" *ngIf="dcaChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && dcaChildL1.subText == 'Information'">
                    <lfg-alert-message type="info">{{ dcaChildL1.questionText }}</lfg-alert-message>
                  </div>

                  <!-- Questions headers -->
                  <div class="questions-header" *ngIf="dcaChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && dcaChildL1.subText !== 'Information'">
                    <h2>{{ dcaChildL1.questionText }}</h2>
                  </div>

                  <!-- Form element for radio question -->
                  <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom" *ngIf="dcaChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': ((dollarCostAveragingForm.get(dcaChildL1.fieldId).touched || dollarCostAveragingForm.get(dcaChildL1.fieldId).errors?.dcaError) && dollarCostAveragingForm.get(dcaChildL1.fieldId).invalid), 'radio-button-mobile': dcaChildL1.xmlTag === FundConst.accountToDCA}">
                    <span class="lfg-radio-section">
                      <p class="question-radio_text">{{ dcaChildL1.questionText }}</p>
                      <div class="radio-btn-rect custom-radio-button">
                        <input [attr.aria-label]="dcaChildL1.questionOption[0].description" tabindex="0" id="yes_{{ dcaChildL1.fieldId }}" type="radio" formControlName="{{ dcaChildL1.fieldId }}" name="{{ dcaChildL1.fieldId }}" value="{{ dcaChildL1.questionOption[0].value }}" required="{{dcaChildL1.required}}" (change)="onRadioChange(dcaChildL1)" />
                        <label for="yes_{{ dcaChildL1.fieldId }}">{{ dcaChildL1.questionOption[0].description }}</label>
                        <input [attr.aria-label]="dcaChildL1.questionOption[1].description" tabindex="0" id="no_{{ dcaChildL1.fieldId }}" type="radio" formControlName="{{ dcaChildL1.fieldId }}" name="{{ dcaChildL1.fieldId }}" value="{{ dcaChildL1.questionOption[1].value }}" required="{{dcaChildL1.required}}" (change)="onRadioChange(dcaChildL1)">
                        <label for="no_{{ dcaChildL1.fieldId }}">{{ dcaChildL1.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="(dollarCostAveragingForm.get(dcaChildL1.fieldId).touched || dollarCostAveragingForm.get(dcaChildL1.fieldId).errors?.dcaError)  && dollarCostAveragingForm.get(dcaChildL1.fieldId).invalid " message="{{ getErrorMsg(dcaChildL1, dollarCostAveragingForm.get(dcaChildL1.fieldId)) }}"></lfg-error>
                  </div>

                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!isVULOneProduct">

          <div class="additional-funds-section">
            <span class="accrd-header">
              Expand to display additional funds
            </span>
            <span class="icon-container">
              <a (click)="toggleAccordionStatus()"><i class="fa arrow-button" [ngClass]="{'fa-chevron-down' : !openAccordion , 'fa-chevron-up' : openAccordion}" aria-hidden="true"></i></a>
            </span>
          </div>

          <div *ngIf="openAccordion" class="accordion-content">
            <ng-container class="form-control-parent" *ngFor="let data2 of dollarCostAveragingData2; let ind = index;">
              <!-- Funds table -->
              <div class="funds-table funds-table-header" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-header'">
                <div class="table-column col-1">{{data2.questionText | uppercase}} </div>
                <div class="table-column col-2">INPUT %</div>
              </div>
              <div class="funds-table funds-table-subheader fund-table-label" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-label'">
                <div class="table-column">{{data2.questionText}} </div>
              </div>
              <div class="funds-table funds-table-subheader" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-subheader'">
                <div class="table-column">{{data2.questionText | uppercase}} </div>
              </div>
              <div class="funds-table-row funds-table" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.TEXT && data2.notes && data2.notes.indexOf('FundSelect') > -1">
                <div class="col-1 table-column">{{data2.questionText}}
                  <div *ngIf="isMoneyMarketIsSelected && data2.questionText?.indexOf('Government Money Market') > -1" class="fund-info"><lfg-alert-message type="info">{{ Message.VUL_DCA_ERROR }}</lfg-alert-message></div>
                </div>
                <div class="col-2 table-column">
                  <label for="{{data2.fieldId}}" id="fund-field-label-{{data2.fieldId}}">
                    <span class="visually-hidden">Label for the fund input</span>
                    <input lfgInput aria-live="off" name="{{ data2.questionText }}" id="{{data2.fieldId}}" type='text' formControlName='{{ data2.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data2.required == 'true'">
                  </label>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="percent-bottom total-percent">
            <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
          </div>

        </ng-container>
      </form>
    </section>
    <lfg-alert-message *ngIf="totalFundPercentage != 100 && totalFundPercentage != 0" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
  </div>
</section>
