import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'combine'
})
export class CombinePipe implements PipeTransform {

  transform(value: string, ...args: string[]): string {
    let combinedStr = value + ', ';

    args.forEach((str) => {
      combinedStr = combinedStr + str + ' ';
    });
    return combinedStr.trim().slice(0, combinedStr. length - 1) ;
  }

}
