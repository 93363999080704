import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageJsonObject, Question } from 'src/app/shared/models/casePage.model';
import { SummaryConstant } from 'src/config/constants';
import { SummaryTableConfig } from './summary-table-config';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { PAGE_GROUP_NAME } from 'src/config/page.config';

@Component({
  selector: 'app-application-summary',
  templateUrl: './application-summary.component.html',
  styleUrls: ['./application-summary.component.scss'],
})
export class ApplicationSummaryComponent implements OnInit {

  @Input() questions: PageJsonObject;

  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  tableData;
  tableDataLoaded = false;
  summaryData: Question[] = [];
  dataMap: Map<string, Question[]> = new Map<string, Question[]>();

  constructor(private defaultPageLoadService: DefaultPageload) {
    // write code here
  }

  ngOnInit(): void {
    this.tableData = new SummaryTableConfig().tableData;
    this.dataMap.clear();
    this.updateFormStatus();
    this.reArrangeData();
    this.getAgentInformation();
    this.defaultPageLoadService.logPageLoad();
  }

  private reArrangeData(): void {
    this.summaryData = this.questions?.pages[0]?.questions;
    this.summaryData?.forEach((ques) => {
      if (ques.notes && ques.notes.indexOf('col') > -1) {
        if (this.dataMap.get(ques.notes)) {
          if (!this.checkIfQuestionPresent(ques.notes, ques.subText)) {
            ques.question_answer = this.getAnswer(ques.subText, ques.question_answer);
            this.dataMap.get(ques.notes).push(ques);
          }
        } else {
          ques.question_answer = this.getAnswer(ques.subText, ques.question_answer);
          this.dataMap.set(ques.notes, [ques]);
        }
      }

    });
  }

  private getAnswer(type: string, quesAns: string): string {
    if (type?.toLowerCase() === 'name') {
      return this.getInsuredName();
    } else if (type?.toLowerCase() === 'address2') {
      return this.getInsuredAddress();
    } else if (type?.toLowerCase() === 'age') {
      return this.getInsuredAgeDetails();
    } else {
      return quesAns;
    }
  }

  private checkIfQuestionPresent(colNo: string, type: string): boolean {
    const questionAdded = this.dataMap.get(colNo).filter((ques) => {
      return ques.subText?.toLowerCase() === type.toLowerCase() &&
        ques.subText?.toLowerCase() !== 'info';
    });
    return questionAdded.length > 0;
  }

  private getInsuredName(): string {
    let name = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'name') {
        name = name + ques.question_answer + ' ';
      }
    });
    return name;
  }

  private getInsuredAddress(): string {
    let address = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'address2') {
        address = address + ques.question_answer + ' ';
        if (ques.questionText?.toLowerCase() === 'city' && ques.question_answer) {
          address = address.trim() + ', ';
        }
      }
    });
    return address;
  }

  private getInsuredAgeDetails(): string {
    let ageDetails = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'age') {
        ageDetails = ageDetails + ques.questionText + ' ' + '<strong>' + ques.question_answer + '</strong>' + ' | ';
      }
    });
    return ageDetails.substring(0, ageDetails.lastIndexOf(' | '));
  }

  private updateFormStatus(): void {
    const summaryInfoObj = {
      formValid: true,
      formHasNoErrors: true,
      questions: { page_group_name: PAGE_GROUP_NAME.SUMMARY_INFO },
      formId: 'summaryInfo',
      gridForm: false,
      isFormChange: true,
    };
    this.formStatus.emit(summaryInfoObj);
  }

  private getAgentInformation(): void {
    const primaryAgentInfo = this.getPrimaryAgentInfo();
    const secondaryAgentInfo = this.getSecAgentInfo();

    if (primaryAgentInfo) {
      this.tableData?.data?.push(primaryAgentInfo);
    }
    if (secondaryAgentInfo && secondaryAgentInfo.length > 0) {
      this.tableData?.data?.push(...secondaryAgentInfo);
    }
    this.tableDataLoaded = true;
  }

  private getSecAgentInfo(): any[] {
    const secAgentList = [];
    const gridAnswer = this.questions?.pages[0]?.gridAnswers;
    gridAnswer?.forEach((ans) => {
      const agentName = (ans[SummaryConstant.agentFirstName] + ' ' + ans[SummaryConstant.agentLastName])?.trim();
      const agentPhone = (ans[SummaryConstant.agentPhone]);
      const agentEmail = (ans[SummaryConstant.agentEmail]);
      if (agentName) {
        secAgentList.push({ agentName, agentPhone, agentEmail });
      }
    });
    return secAgentList;
  }

  private getPrimaryAgentInfo(): any {
    let agentName = '';
    let agentEmail = '';
    let agentPhone = '';
    this.summaryData?.forEach((ques) => {
      if (ques.subText?.toLowerCase() === 'agent') {
        if (ques.questionText?.toLowerCase().indexOf('name') > -1) {
          agentName = agentName + ques.question_answer + ' ';
        } else if (ques.questionText?.toLowerCase().indexOf('email') > -1) {
          agentEmail = ques.question_answer;
        } else if (ques.questionText?.toLowerCase().indexOf('phone') > -1) {
          agentPhone = ques.question_answer;
        }
      }
    });

    if (agentName.trim().length > 0 || agentPhone.trim().length > 0 || agentEmail.trim().length > 0) {
      return { agentName, agentEmail, agentPhone };
    }
    return undefined;
  }
}
