import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DisableStyle } from '@ng/lfg-calendar';
import { GridInstance, Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, TrustInfoConstant } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-trust-info',
  templateUrl: './trust-info.component.html',
  styleUrls: ['./trust-info.component.scss']
})
export class TrustInfoComponent implements OnInit {

  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  questionsData;
  hasError: any;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  trustInfoData: Question[] = [];
  trustInfoForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  alldropDownOptions = {};
  formValid = true;
  formHasNoErrors = true;
  gridHasNoErrors = false;
  isGridFormValid = true;
  isGridFormChange = false;

  maxNoOfTrust: number;
  trustInfoConstant = TrustInfoConstant;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  isTabVisited: boolean;

  tabStatusOnInit: TabStatus;

  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date of Birth',
    disableStyle: DisableStyle.locked,
  };

  dateConfigOpt = { ...this.dateConfig };

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
  ) {
    [this.dateConfigOpt.placeholder, this.dateConfigOpt.id] = ['Date of birth (optional)', 'dobIdOpt'];
  }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.maxNoOfTrust = +(this.gridInstance[0]?.maxGridIndex);
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.trustInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.trustInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.trustInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.trustInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.trustInfoData
    );
    this.trustInfoForm = this.fb.group(form);
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.trustInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.trustInfoForm,
      data: null,
    };
    for (const data of this.trustInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  addTrustCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.trustInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  validateOnFocus(data, type): any {
    setTimeout(() => {
      if (type === 'in') {
        this.trustInfoForm.get(data.fieldId)?.markAsUntouched();
      } else {
        this.trustInfoForm.get(data.fieldId)?.markAsTouched();
      }
    }, 10);
  }

  nestedQuestionCheckTrustInfoInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  handleSelectionChange(data, ix?): any {
    const formotivId = this.displayIdCreation(data);
    this.commonService.triggerChangeCustom(
      'dropdown',
      ix,
      formotivId,
      'insuredInfoForm',
      data
    );
    this.updatedAnswersOnHidden(data);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.trustInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.trustInfoForm.get(key)?.markAsUntouched();
      }
      this.trustInfoForm.patchValue(updatedval);
    }
  }

  trustRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  gridformStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    this.emitStatus();
  }

  emitStatus(): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    const trustInfoObj = {
      formValid: this.formValid && this.isGridFormValid && !this.hasError,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'existingInsuranceInfoForm',
      gridForm: true,
      isFormChange: this.trustInfoForm.dirty || this.isGridFormChange || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.trustInfoForm),
    };
    this.formStatus.emit(trustInfoObj);
  }

  handleTrustSelectionChange(data, _ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }
}
