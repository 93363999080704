import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-suitability-review',
  templateUrl: './suitability-review.component.html',
  styleUrls: ['./suitability-review.component.scss']
})
export class SuitabilityReviewComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  suitabilityReviewData = [];
  suitabilityReviewForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload,
  ) {

  }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.suitabilityReviewForm);
      this.defaultPageLoadService.disableFormIfLocked(this.suitabilityReviewForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.suitabilityReviewData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.suitabilityReviewData
    );
    this.suitabilityReviewForm = this.fb.group(form);
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.suitabilityReviewForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.suitabilityReviewForm,
      data: null,
    };
    for (const data of this.suitabilityReviewData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'suitabilityReviewForm',
      isFormChange: this.suitabilityReviewForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.suitabilityReviewForm)
    };
    this.formStatus.emit(obj);
  }

  onRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  private updatedAnswersOnHidden(data): any {
    const value = this.suitabilityReviewForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.suitabilityReviewForm.get(key)?.markAsUntouched();
      }
      this.suitabilityReviewForm.patchValue(updatedval);
    }
  }
}
