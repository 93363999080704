import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  CaseStatus, PageStatusConstant, CaseInfoConstant, RiderQuestionConstant,
  AgentInfoContConstant, AgentInfoConstant, ExcludeTabsFromLock,
  ExcludeTabsFromValidation, OwnerInfoConstant, TrustInfoConstant, ExistingInsuranceConstant,
  CoverageQuestionConstant, PrimaryInsuredConstant, EFTConstant, TABS_ALWAYS_IN_GOOD_ORDER,
  AgentInstructionConst, ExistingInsuranceProposedBConstant, ProposedInsuredBConstant, TIAInfoConstant,
  WAReplacementConst, WAReplacementProposedBConst, LTCExistingInsConst
} from 'src/config/constants';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { ProductType, TAB, TabInformation, TabStatus } from 'src/config/sideNav.config';
import {
  Case, GetCaseDataRequestObject, ResponseObject,
  UpdateCaseDataRequestObject, PageJsonObject, Company, PageStatus,
  PageStatusValue, Question, CaseAndPageStatus, AgentProfile, Recipient, CaseStatus as CaseStatusVal
} from '../models/casePage.model';
import { AgentAndFirmService } from './agent-and-firm.service';
import { PageService } from './page.service';
import { ProductDetailsService } from './product-details.service';
import { ServiceInvoker } from './service-invoker';
import { TabDetailsService } from './tab-details.service';
import { UserDetailsService } from './user-details.service';
import { UtilsService } from './utils.service';
import { AppAnalyticsService } from './app-analytics.service';
import { UpdateCaseService } from './update-case.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultPageload {
  personAddressFieldName: string;
  personCityFieldName: string;
  personStateFieldName: string;
  personZipFieldName: string;
  trustAddressFieldName: string;
  trustCityFieldName: string;
  trustStateFieldName: string;
  trustZipFieldName: string;
  entityAddressFieldName: string;
  entityCityFieldName: string;
  entityStateFieldName: string;
  entityZipFieldName: string;
  personSameAddressName: string;
  trustSameAddressName: string;
  entitySameAddressName: string;
  ownerAddress: any;
  ownerCity: any;
  ownerState: any;
  ownerZip: any;
  ownerPhone: any;
  ownerPhoneType: any;
  ownerEmail: string;
  labelFirstName: any;
  labelLastName: any;
  isSameInsuredAddress: any;
  personPhoneFieldName: string;
  personPhoneTypeFieldName: string;
  personEmailFieldName: string;
  entityPhoneFieldName: any;
  entityPhoneTypeFieldName: any;
  entityEmailFieldName: any;
  trustPhoneFieldName: any;
  personInsAddressFieldName: any;
  personInsCityFieldName: any;
  personInsStateFieldName: any;
  personInsZipFieldName: any;
  entityInsCityFieldName: any;
  entityInsAddressFieldName: any;
  entityInsStateFieldName: any;
  entityInsZipFieldName: any;
  isInsuredProfileUpdated: boolean;
  constructor(
    private serviceInvoker: ServiceInvoker,
    private pageService: PageService,
    private productService: ProductDetailsService,
    private tabDetailsService: TabDetailsService,
    private utilService: UtilsService,
    private userService: UserDetailsService,
    private agentService: AgentAndFirmService,
    private appAnalyticsService: AppAnalyticsService,
    private updateCaseService: UpdateCaseService
  ) { }

  private caseId: string;
  private caseStatus: string;

  // store existing insurance company List
  private existingInsuranceCompany: Company[] = [];

  createPolicy(isVoiceSignature?: boolean): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('CREATE_NEW_POLICY', null, { caseId: this.caseId, voiceSignature: isVoiceSignature });
  }

  getPackageDetails(caseId?: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_PACKAGE_HISTORY', null, { caseId: caseId ?? this.caseId });
  }

  createEnvelope(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('CREATE_ENVELOPE', null, { caseId: this.caseId });
  }

  getTransactionStatus(transactionId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_TRANSACTION_STATUS', { transactionId }, { caseId: this.caseId });
  }

  updatePageStatus(pageGroupNames: string[]): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('UPDATE_PAGE_STATUS', null, { caseId: this.caseId, pageGroupNames, pageStatus: 'notVisited' });
  }

  saveConsent(signerGuid: string, isConsented: boolean, envelopeId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('SAVE_CONSENT', null, { signerGuid, isConsented, caseId: this.caseId, envelopeId });
  }

  getCaseInfoPageQuestions(requestBody: GetCaseDataRequestObject): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService(
      'GET_QUESTIONS',
      null,
      requestBody
    );
  }

  generateForm(caseId?: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GENERATE_FORM', null, { caseId: caseId ?? this.caseId });
  }

  getAvailableProductsCaseInfo(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_AVAILABLE_PRODUCTS');
  }

  voidEnvelope(envelopeId: string, reason: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('VOID_ENVELOPE', null, { caseId: this.caseId, envelopeId, reason });
  }

  resendEmail(envelopeId: string, signerListToUpdate: any[]): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('RESEND_EMAIL', null, { caseId: this.caseId, envelopeId, signers: signerListToUpdate });
  }

  updateCaseDetails(pageData: PageJsonObject, isPageValid: boolean, currentTab?: TabInformation, isEmptyPage?: boolean): Observable<any> {
    const caseInfo: Case = {
      nextPageItem: isEmptyPage ? this.pageService.getPageGroupByTab(currentTab) : pageData.page_group_name,
      caseId: this.caseId,
      additionalInfo: this.updateCaseService.getSavedData()
    };

    if (pageData?.page_group_name === PAGE_GROUP_NAME.CASE_INFO_PAGE) {
      caseInfo.insuredFirstName = this.userService.getInsuredFirstName();
      caseInfo.insuredLastName = this.userService.getInsuredLastName();
    }

    const pageStatus = isPageValid ? PageStatusValue.VALID : PageStatusValue.INVALID;
    const payload: UpdateCaseDataRequestObject = {
      caseInfo,
      pageGroup: pageData,
      pageStatus: isEmptyPage && !TABS_ALWAYS_IN_GOOD_ORDER.includes(
        this.pageService.getTabByPageGroupName(pageData.page_group_name)) ? PageStatusValue.NOTVISITED : pageStatus
    };
    payload.isProfileUpdated = this.getIsInsuredProfileUpdated();
    if (this.caseId) {
      return this.serviceInvoker.invokeService(
        'UPDATE_CASE_DETAILS',
        null,
        payload
      );
    } else {
      return this.serviceInvoker.invokeService(
        'SAVE_CASE_DETAILS',
        null,
        payload
      );
    }
  }

  saveCaseDetails(pageData: PageJsonObject, isPageValid: boolean, isCaseInfoUpdated: boolean): Observable<ResponseObject> {
    let insuredFirstName;
    let insuredLastName;
    let productName;
    this.caseStatus = CaseStatus.STARTED;
    pageData?.pages[0]?.questions.forEach((question) => {
      if (question.xmlTag === CaseInfoConstant.firstNameId) {
        insuredFirstName = question.question_answer;
      } else if (question.xmlTag === CaseInfoConstant.lastNameId) {
        insuredLastName = question.question_answer;
      } else if (question.questionText.toLowerCase() === 'display product name') {
        productName = question.question_answer;
        this.productService.setProductName(productName);
        this.productService.setProductCondition();
      }
    });
    const productType = this.productService.getProductType();
    const caseId = this.caseId;
    const caseInfo: Case = {
      insuredFirstName,
      insuredLastName,
      status: !this.caseId ? this.caseStatus : undefined,
      productName,
      nextPageItem: productType === ProductType.LONG_TERM_CARE ? PAGE_GROUP_NAME.POLICY_INFO_MG : PAGE_GROUP_NAME.CASE_INFO_PAGE,
      caseId,
      additionalInfo: this.updateCaseService.getSavedData()
    };

    const payload: UpdateCaseDataRequestObject = {
      caseInfo,
      pageGroup: pageData,
      pageStatus: isPageValid ? PageStatusValue.VALID : PageStatusValue.INVALID
    };
    // in case of product change
    if (isCaseInfoUpdated && caseId) {
      // Set flag when name, gender changed
      this.setIsInsuredProfileUpdated(false);
      payload.isProductUpdated = true;
      payload.stateCode = this.userService.getInsuredState();
      payload.productType = this.productService.getProductType();
      payload.insuredAge = this.userService.getInsuredAge()?.toString();
    }

    payload.isProfileUpdated = this.getIsInsuredProfileUpdated();

    if (caseId) {
      return this.serviceInvoker.invokeService(
        'UPDATE_CASE_DETAILS',
        null,
        payload
      );
    } else {
      return this.serviceInvoker.invokeService(
        'SAVE_CASE_DETAILS',
        null,
        payload
      );
    }
  }

  addEmptyPage(pageName: string): Observable<ResponseObject> {
    let pageStatus = false;
    const pageData = { page_group_name: pageName };
    if (TABS_ALWAYS_IN_GOOD_ORDER.includes(this.pageService.getTabByPageGroupName(pageData.page_group_name))) {
      pageStatus = true;
    }
    return this.updateCaseDetails(pageData, pageStatus, this.tabDetailsService.activeTab, true);
  }

  deletePages(listOfPages: string[]): Observable<ResponseObject> {
    if (listOfPages?.length > 0) {
      const payload = {
        caseId: this.caseId,
        pagesToDelete: listOfPages
      };
      return this.serviceInvoker.invokeService('DELETE_PAGE_GROUP', null, payload);
    }
  }

  getRecipientsList(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_RECIPIENT', { caseId: this.caseId });
  }

  validateAgent(payload: any): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_AGENT_VALIDITY', null, payload);
  }

  saveRecipientInfo(recipientList: Recipient[], agentSignerInfo: Recipient): Observable<ResponseObject> {
    const signerSaveList = [];
    recipientList.forEach((recipient) => {
      signerSaveList.push({ id: recipient.id, eSignatureMethod: recipient.eSignatureMethod });
    });
    if (agentSignerInfo) {
      signerSaveList.push({ id: agentSignerInfo.id, eSignatureMethod: agentSignerInfo.eSignatureMethod });
    }
    return this.serviceInvoker.invokeService('SAVE_RECIPIENT', { caseId: this.caseId }, signerSaveList);
  }

  getCaseId(): string {
    return this.caseId;
  }

  setCaseId(caseId: string): void {
    this.caseId = caseId;
  }


  lockCase(): Observable<ResponseObject> {
    return this.updateCaseStatus(true);
  }

  unlockCase(): Observable<ResponseObject> {
    return this.updateCaseStatus(false);
  }

  private updateCaseStatus(isLocked: boolean): Observable<ResponseObject> {
    let caseDetails;
    if (isLocked) {
      caseDetails = {
        caseId: this.caseId,
        mktName: this.productService.getMarketName()
      };
    }

    return this.serviceInvoker.invokeService('UPDATE_CASE_STATUS', null, { caseIds: [this.caseId], lockFlag: isLocked, caseDetails });
  }

  getPageStatus(caseId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_PAGE_STATUS', null, { caseId });
  }

  getActivityLog(caseId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_ACTIVITY_LOG', { caseId });
  }

  updateTabInfoForExistingCase(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED;
    pageAndCaseStatus.pageStatusList?.forEach(pageStatus => {
      const pageStatusVal = this.getTabStatusByPageStatus(pageStatus, isCaseLocked, isPolicyCreated);
      if (this.pageService.getTabByPageGroupName(pageStatus.pageGroupName) === TAB.CASE_INFO) {
        this.tabDetailsService.updateTabList(TAB.CASE_INFO, true, pageStatusVal);
      }
      if (this.pageService.getTabByPageGroupName(pageStatus.pageGroupName) === TAB.ATTESTATION) {
        this.tabDetailsService.updateTabList(TAB.ATTESTATION, true, pageStatusVal);
      }
    });
  }

  updateTabStatusBasedOnValueFetchedFromService(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED;
    pageAndCaseStatus.pageStatusList?.forEach(pageStatus => {
      const pageStatusVal = this.getTabStatusByPageStatus(pageStatus, isCaseLocked, isPolicyCreated);
      this.updateTabLockStatus(pageStatus, pageStatusVal, isCaseLocked, isPolicyCreated);
      const tab = this.pageService.getTabByPageGroupName(pageStatus.pageGroupName);
      if (tab !== TAB.CASE_INFO && tab !== TAB.ATTESTATION) {
        this.tabDetailsService.updateTabList(tab, true, pageStatusVal);
      }
    });
  }

  addAdditionalTabsIfPageLockedOrPolicyCreated(casePageStatusRes: CaseAndPageStatus): void {
    if (casePageStatusRes.caseStatus.isLocked || this.isAllPageValid()) {
      this.lockAdditionalTabs(casePageStatusRes);
    }
  }

  goToLastAccessedTab(casePageStatus: CaseAndPageStatus): void {
    const lastVisitedPage = casePageStatus.caseStatus?.lastVisitedPage;
    const lastVisitedTabByCaseStatus = this.getLastVisistedPageBasedOnCaseStatus(casePageStatus.caseStatus);
    if (lastVisitedPage || lastVisitedTabByCaseStatus) {
      const tabSelected = lastVisitedTabByCaseStatus ?? this.pageService.getTabByPageGroupName(lastVisitedPage);
      if (this.tabDetailsService.isTabActive(tabSelected)) {
        this.tabDetailsService.selectTab(this.tabDetailsService.getTabByTabName(tabSelected));
      }
    }
  }

  /**
   * this method is added to get the last visited page from case id as Validate and lock,
   * thank you page are not getting saved as other pages
   * @param caseStatus casestatus
   * @returns the last visited tab name if applicable
   */

  private getLastVisistedPageBasedOnCaseStatus(caseStatus: CaseStatusVal): string {
    let lastVisitedTab;
    if (caseStatus.status === CaseStatus.LOCKED_READY_TO_SIGN &&
      this.tabDetailsService.getTabsAvailableAfter(TAB.VALIDATE_AND_LOCK)?.length === 0) {
      lastVisitedTab = TAB.VALIDATE_AND_LOCK;
    } else if (caseStatus.status === CaseStatus.READY_TO_SUBMIT) {
      lastVisitedTab = TAB.REVIEW_AND_SUBMIT;
    } else if (caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED) {
      if (this.tabDetailsService.isTabActive(TAB.AGENT_INSTRUCTION)) {
        lastVisitedTab = TAB.REVIEW_AND_SUBMIT;
      } else {
        lastVisitedTab = TAB.VOICE_CONFIRM_THANK_YOU;
      }
    } else if (this.tabDetailsService.isTabActive(TAB.ELECTRONIC_SIGNATURE)) {
      lastVisitedTab = TAB.ELECTRONIC_SIGNATURE;
    }
    return lastVisitedTab;
  }

  updatePageStatusForESignature(casePageStatusRes: CaseAndPageStatus): void {
    // case statuses after envelope created
    const envelopeCreatedStatuses = [CaseStatus.AWAITING_CUSTOMER_ESIGN, CaseStatus.AWAITING_AGENT_ESIGN,
    CaseStatus.APPLICATION_ESUBMITTED, CaseStatus.READY_TO_SUBMIT];
    if (casePageStatusRes.caseStatus?.status && envelopeCreatedStatuses.includes(casePageStatusRes.caseStatus.status)) {
      this.tabDetailsService.updateTabLockStatus(TAB.SIGNATURE_METHOD, true);
      if (!this.isVoiceSignatureCase(casePageStatusRes)) {
        this.tabDetailsService.updateTabLockStatus(TAB.AGENT_INSTRUCTION, true);
      }
      // if e signature exists and envelope is created then lock e signature instruction as well
      if (this.tabDetailsService.isTabActive(TAB.E_SIGNATURE_INSTRUCTION)) {
        this.tabDetailsService.updateTabLockStatus(TAB.E_SIGNATURE_INSTRUCTION, true);
      }
    }
    if (this.tabDetailsService.isTabActive(TAB.ELECTRONIC_SIGNATURE)) {
      this.tabDetailsService.updateTabStatusMap(TAB.ELECTRONIC_SIGNATURE, true);
    }
  }

  private isVoiceSignatureCase(casePageStatusRes: CaseAndPageStatus): boolean {
    if (casePageStatusRes.caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED) {
      return this.tabDetailsService.isTabActive(TAB.VOICE_TERM_OF_USE) || this.tabDetailsService.isTabActive(TAB.VOICE_CONFIRM_THANK_YOU);
    }
    return false;
  }

  private lockAdditionalTabs(pageAndCaseStatus: CaseAndPageStatus): void {
    const isCaseLocked = pageAndCaseStatus.caseStatus.isLocked;
    const isPolicyCreated = pageAndCaseStatus.caseStatus.status === CaseStatus.APPLICATION_ESUBMITTED;
    this.tabDetailsService.updateTabList(TAB.APPLICATION_SUMMARY, true,
      { isLocked: isCaseLocked || isPolicyCreated, errorExists: false, visited: true });
    this.tabDetailsService.updateTabList(TAB.VALIDATE_AND_LOCK, true, { isLocked: isPolicyCreated, errorExists: false, visited: true });
  }

  private updateTabLockStatus(
    pageStatus: PageStatus, pageStatusVal: TabStatus, isCaseLocked: boolean,
    isPolicyCreated: boolean): void {
    const excludeFromLock = [TAB.REVIEW_AND_SUBMIT, TAB.VOICE_CONFIRM_THANK_YOU];
    if (isPolicyCreated) {
      pageStatusVal.isLocked = true;
    } else if (isCaseLocked && ExcludeTabsFromLock.includes(this.pageService.getTabByPageGroupName(pageStatus.pageGroupName))) {
      pageStatusVal.isLocked = false;
    }
    if (excludeFromLock.includes(this.pageService.getTabByPageGroupName(pageStatus.pageGroupName))) {
      pageStatusVal.isLocked = false;
    }
  }

  isAllPageValid(): boolean {
    return this.tabDetailsService.isAllTabsValid(ExcludeTabsFromValidation);
  }

  private getTabStatusByPageStatus(pageStatus: PageStatus, isCaseLocked: boolean, isPolicyCreated: boolean): TabStatus {
    const pageStatusVal: TabStatus = {
      visited: this.isTabVisited(pageStatus),
      errorExists: pageStatus?.pageStatus?.toLowerCase() === PageStatusConstant.INVALID,
      isLocked: isCaseLocked || isPolicyCreated || pageStatus?.pageStatus?.toLowerCase() === PageStatusConstant.LOCKED,
    };
    return pageStatusVal;
  }

  updateFormErrors(form: FormGroup): boolean {
    if (this.tabDetailsService.getActiveTab()?.status?.visited) {
      form.markAllAsTouched();
      return true;
    }
    return false;
  }

  markFormFieldsTouched(form: FormGroup, isTabVisited: boolean): void {
    if (!isTabVisited) {
      Object.keys(form.controls).forEach(formControlkey => {
        if (form.get(formControlkey)?.value) {
          form.get(formControlkey)?.markAsTouched();
        }
      });
    }
  }

  disableFormIfLocked(form: FormGroup, isLocked: boolean): void {
    setTimeout(() => {
      if (isLocked && form) {
        form.disable();
      }
    }, 100);
  }

  getFieldIdByTag(xmlTag: string): string {
    return this.utilService.getFieldIdByTag(xmlTag, this.pageService.getPageGroupByTab(this.tabDetailsService.activeTab));
  }

  getFieldByXmlTagAndQuesList(xmlTag: string, ques: Question[]): string {
    return this.utilService.getFieldByXmlTag(ques, xmlTag);
  }

  updateOtherInformation(pageGroupData: PageJsonObject): void {
    this.updateRiderPageInfo(pageGroupData);
    this.updateAgentInfoContinuePageInfo(pageGroupData);
    this.updateCoveragePageInfo(pageGroupData);
    this.updateExistingInsurance(pageGroupData);
    this.updateExistingInsuranceInsuredB(pageGroupData);
    this.updateOwnerInfoPage(pageGroupData);
    this.updateTIAInfoPage(pageGroupData);
    this.updateTrustInfo(pageGroupData);
    this.updatePrimaryInfo(pageGroupData);
    this.updateProposedB(pageGroupData);
    this.updateFundInfo(pageGroupData);
    this.updateEFTInfo(pageGroupData);
  }

  getAgentProfile(): AgentProfile {
    return this.agentService.getAgentProfile();
  }

  filterResultsBasedOnBusinessRules(pageGroupName: string, pageGroup: PageJsonObject): void {
    if ((pageGroupName === PAGE_GROUP_NAME.AGENT_INFORMATION || pageGroupName === PAGE_GROUP_NAME.AGENT_INFO_CONTINUED)
      && !this.agentService.getAgentProfile().allStateFlag) {
      const filteredQuesList = this.removeAllStateQuestions(pageGroup);
      pageGroup.pages[0].questions = filteredQuesList;
    } else if (pageGroupName === PAGE_GROUP_NAME.AGENT_INFO_CONTINUED && this.agentService.getAgentProfile().allStateFlag) {
      const filteredQuesList = this.removeNonAllStateQuestions(pageGroup);
      pageGroup.pages[0].questions = filteredQuesList;
    }
  }

  updateQuestionMap(pageGroupJson: PageJsonObject): void {
    this.utilService.updateQuestionMap(pageGroupJson?.pages[0]?.questions, pageGroupJson.page_group_name);
    if (pageGroupJson?.pages[0]?.gridInstances && pageGroupJson?.pages[0]?.gridInstances?.length > 0) {
      this.utilService.updateQuestionMap(pageGroupJson.pages[0].gridInstances[0]?.gridQuestions, pageGroupJson.page_group_name);
    }
  }

  updateAgentInformation(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.AGENT_INFORMATION) {
      pageGroupData.pages[0].questions.forEach((question) => {
        if (question.xmlTag === AgentInfoConstant.agentEmailQuesId && question.question_answer) {
          this.agentService.setAgentPrimaryEmail(question.question_answer);
        } else if (question.xmlTag === AgentInfoConstant.agentSSNQuesId && question.question_answer) {
          this.agentService.setAgentLast4DigitSSN(question.question_answer);
        } else if (question.xmlTag === AgentInfoConstant.agentFirstNameQuesId) {
          this.agentService.setAgentFirstName(question.question_answer);
        } else if (question.xmlTag === AgentInfoConstant.agentLastNameQuesId) {
          this.agentService.setAgentLastName(question.question_answer);
        } else if (question.xmlTag === AgentInfoConstant.doYouattestTag) {
          this.userService.setIsAttestedForSameEmail(question.question_answer);
        }
      });
    }
  }

  updateGridSequenceForGridData(pageGroup: any): void {
    pageGroup?.pages?.forEach(page => {
      let ind = pageGroup.page_group_name === PAGE_GROUP_NAME.AGENT_INFORMATION ? 2 : 1;
      if (page.gridInstances?.length > 0) {
        const seqQuesName = page.gridInstances[0].gridQuestions.filter(ques => {
          return ques.xmlTag === OwnerInfoConstant.seqNumber || ques.xmlTag === AgentInfoConstant.seqNumber
            || ques.xmlTag === AgentInfoContConstant.seqNumber || ques.xmlTag === ExistingInsuranceConstant.seqNumber
            || ques.xmlTag === ExistingInsuranceConstant.seqNumber2 || ques.xmlTag === ExistingInsuranceConstant.seqNumber3
            || ques.xmlTag === ExistingInsuranceProposedBConstant.seqNumber
            || ques.xmlTag === ExistingInsuranceProposedBConstant.seqNumber2
            || ques.xmlTag === ExistingInsuranceProposedBConstant.seqNumber3
            || ques.xmlTag === AgentInstructionConst.seqNumber || ques.xmlTag === TrustInfoConstant.seqNumber
            || ques.xmlTag === WAReplacementConst.seqNumber || ques.xmlTag === WAReplacementProposedBConst.seqNumber
            || ques.xmlTag === LTCExistingInsConst.seqNumber;
        })[0]?.name;
        if (seqQuesName) {
          page?.gridAnswers.forEach(ansObj => {
            ansObj[seqQuesName] = (ind++).toString();
          });
        }
      }
    });
  }

  updateFundDetailsIfExist(pageGroup: PageJsonObject, fundList: any[], allocationType: string): void {
    if ((pageGroup.page_group_name === PAGE_GROUP_NAME.VUL_FUND_ALLOCATION
      || pageGroup.page_group_name === PAGE_GROUP_NAME.FUND_ALLOCATION) && fundList?.length > 0
      && allocationType) {
      const fundMap = pageGroup.page_group_name === PAGE_GROUP_NAME.VUL_FUND_ALLOCATION
        ? this.getFundMap(fundList, allocationType) : this.getFundMapForAssetEdgeFunds(fundList, allocationType);
      pageGroup.pages.forEach(page => {
        page.questions.forEach((question) => {
          const fundCode = question.notes?.replace('FundSelect-', '');
          if (fundCode && fundMap.has(fundCode)) {
            question.question_answer = fundMap.get(fundCode);
          }
        });
      });
    }
  }

  private removeAllStateQuestions(pageGroup: PageJsonObject): Question[] {
    return pageGroup.pages[0]?.questions.filter(ques => {
      return ques.notes !== 'ITES_FILTER_ALL_STATE';
    });
  }

  private removeNonAllStateQuestions(pageGroup: PageJsonObject): Question[] {
    return pageGroup.pages[0]?.questions.filter(ques => {
      return ques.notes !== 'ITES_FILTER_NON_ALL_STATE';
    });
  }

  private updateRiderPageInfo(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.RIDERS_INFO_PAGE) {
      this.updateRiderDataInservice(pageGroupData);
    }
  }

  private updateFundInfo(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.FUND_ALLOCATION) {
      const fundPageQuesList = pageGroupData.pages[0]?.questions?.concat(pageGroupData.pages[1]?.questions);
      const selectedfund = fundPageQuesList.filter((ques) => {
        return ques.notes?.toLowerCase().indexOf('fundselect') > -1 && +(ques.question_answer) > 0;
      })?.map(fundSelected => fundSelected.notes?.replace('FundSelect-', ''));
      this.userService.setSelectedFundList(selectedfund);
    }
  }

  private updateRiderDataInservice(pageGroupData: PageJsonObject): void {
    pageGroupData.pages[0].questions.forEach((question) => {
      if (question.xmlTag === RiderQuestionConstant.ChildRiderQues) {
        this.userService.setIsChildRiderSelected(!!question.question_answer);
      } else if (question.xmlTag === RiderQuestionConstant.CCABRQuesId) {
        this.userService.setIsCCABRiderSelected(!!question.question_answer);
      } else if (question.xmlTag === RiderQuestionConstant.BusinessExecRider) {
        this.userService.setBusinessEnhanceRiderSeleted(!!question.question_answer);
      } else if (question.xmlTag === RiderQuestionConstant.IndividualExecRider) {
        this.userService.setIndividualEnhanceRiderSeleted(!!question.question_answer);
      } else if (question.xmlTag === RiderQuestionConstant.ESVRider) {
        this.userService.setEnhanceSurrenderValueRider(!!question.question_answer);
      } else if (question.xmlTag === RiderQuestionConstant.EESVRider) {
        this.userService.setExecEnhanceSurrenderValueRider(!!question.question_answer);
      }
    });
  }

  private updateCoveragePageInfo(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.COVERAGE_INFO_PAGE) {
      pageGroupData.pages[0].questions.forEach((question) => {
        if (question.xmlTag === CoverageQuestionConstant.faceAmountQues) {
          question.question_answer ? this.userService.setFaceAmount(question.question_answer) : this.userService.setFaceAmount('0');
        } else if (question.xmlTag === CoverageQuestionConstant.durationQues && question.question_answer) {
          this.productService.updateProductDetailsForTermProducts(this.utilService.getCodeFromValue(question.question_answer));
        }
      });
    }
  }

  private updateAgentInfoContinuePageInfo(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.AGENT_INFO_CONTINUED) {
      pageGroupData.pages[0].questions.forEach((question) => {
        if (question.xmlTag === AgentInfoContConstant.eInterviewQuesId) {
          question.question_answer && question.question_answer.toLowerCase().indexOf('tele') > -1
            ? this.userService.setIsTeleAppSelected(true) : this.userService.setIsTeleAppSelected(false);
        } else if (question.xmlTag === AgentInfoContConstant.caseManagerDoYouAttestQuesId) {
          this.userService.setIsAttestedForSameEmail(question.question_answer);
        } else if (question.xmlTag === AgentInfoContConstant.caseManagerEmailId && question.question_answer) {
          this.agentService.setCaseManagerEmail(question.question_answer);
        }
      });
    }
  }

  private updateExistingInsurance(pageGroupData: PageJsonObject): void {
    let quitingExistingInsurance;
    let usingAnyFundsFromInsurance;
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.EXISTING_INSURANCE) {
      pageGroupData.pages[0].questions.forEach((question) => {
        if (question.xmlTag === ExistingInsuranceConstant.hasExistingInsuranceQuesId) {
          question.question_answer === 'Yes{47}Yes'
            ? this.userService.setHasExistingInsurance(true) : this.userService.setHasExistingInsurance(false);
        } else if (question.xmlTag === ExistingInsuranceConstant.quitExistingPolicyQuesId) {
          quitingExistingInsurance = question.question_answer === 'Yes{47}Yes';
        } else if (question.xmlTag === ExistingInsuranceConstant.existingFundsQuesId) {
          usingAnyFundsFromInsurance = question.question_answer === 'Yes{47}Yes';
        }
      });
      this.userService.setConsideringAnyDiscontinuation(quitingExistingInsurance || usingAnyFundsFromInsurance);
    }
  }

  private updateExistingInsuranceInsuredB(pageGroupData: PageJsonObject): void {
    let quitingExistingInsuranceB;
    let usingAnyFundsFromInsuranceB;
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.EXISTING_INSURANCE_FOR_PROPOSED_B) {
      pageGroupData.pages[0].questions.forEach((question) => {
        if (question.xmlTag === ExistingInsuranceProposedBConstant.hasExistingInsuranceQuesId) {
          question.question_answer === 'Yes{47}Yes'
            ? this.userService.setHasExistingInsuranceProposedB(true) : this.userService.setHasExistingInsuranceProposedB(false);
        } else if (question.xmlTag === ExistingInsuranceProposedBConstant.quitExistingPolicyQuesId) {
          quitingExistingInsuranceB = question.question_answer === 'Yes{47}Yes';
        } else if (question.xmlTag === ExistingInsuranceProposedBConstant.existingFundsQuesId) {
          usingAnyFundsFromInsuranceB = question.question_answer === 'Yes{47}Yes';
        }
      });
      this.userService.setConsideringAnyDiscontinuationProposedB(quitingExistingInsuranceB || usingAnyFundsFromInsuranceB);
    }
  }

  private updateOwnerInfoPage(pageGroupData: PageJsonObject): void {
    const ownerDetailObj = [];
    const ownerDetailObjWithAddr = [];
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.OWNER_INFO_PAGE) {
      const ownerGridQuestions = pageGroupData?.pages[0].gridInstances[0].gridQuestions[2].reflexiveQuestionAL;
      const firstNameField = this.utilService.getNameFieldByXmlTag(ownerGridQuestions, OwnerInfoConstant.OWNER_FIRST_NAME);
      const lastNameField = this.utilService.getNameFieldByXmlTag(ownerGridQuestions, OwnerInfoConstant.OWNER_LAST_NAME);
      const trustNameField = this.utilService.getNameFieldByXmlTag(ownerGridQuestions, OwnerInfoConstant.OWNER_LAST_NAME, 'trust');
      const entityBusinessFirstNameField = this.utilService.getNameFieldByXmlTag(ownerGridQuestions,
        OwnerInfoConstant.ENTITY_BUISNESS_FIRSTNAMR, 'entity');
      const entityBusinessLastNameField = this.utilService.getNameFieldByXmlTag(ownerGridQuestions,
        OwnerInfoConstant.ENTITY_BUISNESS_LASTNAME, 'entity');
      const entityNameField = this.utilService.getNameFieldByXmlTag(ownerGridQuestions,
        OwnerInfoConstant.OWNER_LAST_NAME, 'entity');
      const ownerTypeNameField = this.utilService.getNameFieldByXmlTag(pageGroupData?.pages[0].gridInstances[0].gridQuestions,
        OwnerInfoConstant.ownerType);

      this.getOwnerDetails(ownerGridQuestions);
      pageGroupData?.pages[0]?.gridAnswers.forEach((ans, i) => {
        // GET Owner Address DeTails
        if (ans[ownerTypeNameField].toLowerCase().indexOf('person') > -1) {
          this.addAddressFields(ans);
          this.ownerPhone = ans[this.personPhoneFieldName];
          this.ownerPhoneType = ans[this.personPhoneTypeFieldName];
          this.ownerEmail = ans[this.personEmailFieldName];
          // Get first last name for dropdown label
          this.labelFirstName = ans[firstNameField];
          this.labelLastName = ans[lastNameField];
        }
        else if (ans[ownerTypeNameField].toLowerCase().indexOf('trust') > -1) {
          return null;
        } else {
          this.addAddressFieldsForEntity(ans);
          this.ownerPhone = ans[this.entityPhoneFieldName];
          this.ownerPhoneType = ans[this.entityPhoneTypeFieldName];
          this.ownerEmail = ans[this.entityEmailFieldName];
          // Get first last name for dropdown label
          this.labelFirstName = ans[entityBusinessFirstNameField];
          this.labelLastName = ans[entityBusinessLastNameField];
        }

        // Get Same as Insured Address value
        this.checkSameAsInsAddress(ans, this.personSameAddressName, this.trustSameAddressName, this.entitySameAddressName);
        if (this.checkFirstLastNamePresent()) {
          const indexId = new Date().getTime();
          const objInDetails = {
            OwnerIndex: i + 1,
            ownerType: ans[ownerTypeNameField],
            firstName: ans[firstNameField],
            lastName: ans[lastNameField],
            trustName: ans[trustNameField],
            entityName: ans[entityNameField],
            entityFirstName: this.getEntityName(ans, entityBusinessFirstNameField),
            entityLastName: this.getEntityName(ans, entityBusinessLastNameField),
            address: {
              streetAddress: this.ownerAddress,
              city: this.ownerCity,
              state: this.ownerState,
              zip: this.ownerZip,
            },
            phone: this.ownerPhone,
            phoneType: this.ownerPhoneType,
            email: this.ownerEmail,
            isSameAsInsuredAddress: this.isSameInsuredAddress,
            value: 'Owner-' + indexId + ans[ownerTypeNameField],
            label: this.labelFirstName + ' ' + this.labelLastName,
          };
          const obj = {
            checked: false,
            label: this.labelFirstName + ' ' + this.labelLastName,
            value: 'Owner-' + indexId + ans[ownerTypeNameField],
          };
          ownerDetailObj.push(obj);
          ownerDetailObjWithAddr.push(objInDetails);

        }
      });
      this.userService.setOwnerDetails(ownerDetailObj, ownerDetailObjWithAddr);
      this.tabDetailsService.updateTabList(TAB.ELECTRONIC_FUND_TRANSFER, undefined,
        { visited: false, errorExists: undefined, isLocked: false });
    }
  }

  private addAddressFields(ans: any): void {
    if (ans[this.personSameAddressName]?.toLowerCase().indexOf('insured') > -1) {
      this.ownerAddress = ans[this.personInsAddressFieldName];
      this.ownerCity = ans[this.personInsCityFieldName];
      this.ownerState = ans[this.personInsStateFieldName];
      this.ownerZip = ans[this.personInsZipFieldName];
    } else {
      this.ownerAddress = ans[this.personAddressFieldName];
      this.ownerCity = ans[this.personCityFieldName];
      this.ownerState = ans[this.personStateFieldName];
      this.ownerZip = ans[this.personZipFieldName];
    }
  }

  private addAddressFieldsForEntity(ans): void {
    if (ans[this.entitySameAddressName]?.toLowerCase().indexOf('insured') > -1) {
      this.ownerAddress = ans[this.entityInsAddressFieldName];
      this.ownerCity = ans[this.entityInsCityFieldName];
      this.ownerState = ans[this.entityInsStateFieldName];
      this.ownerZip = ans[this.entityInsZipFieldName];
    } else {
      this.ownerAddress = ans[this.entityAddressFieldName];
      this.ownerCity = ans[this.entityCityFieldName];
      this.ownerState = ans[this.entityStateFieldName];
      this.ownerZip = ans[this.entityZipFieldName];
    }
  }

  private updateTrustInfo(pageGroupData: PageJsonObject): void {
    const trustDetailObj = [];
    const trustDetailObjWithAddr = [];
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.TRUST_INFO_PAGE) {
      const trustGridQuestions = pageGroupData?.pages[0].gridInstances[0].gridQuestions;
      const firstNameField = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_FIRST_NAME);
      const lastNameField = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_LAST_NAME);
      const cityField = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_CITY);
      const stateField = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_STATE);
      const streetField = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_STREET);
      const zipField = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_ZIP);
      const primaryPhone = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_PRIMARY_PHONE);
      const email = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.TRUST_EMAIL);
      const phoneType = this.utilService.getNameFieldByXmlTag(trustGridQuestions, TrustInfoConstant.phoneType);

      pageGroupData?.pages[0]?.gridAnswers.forEach((ans, i) => {
        const indexId = new Date().getTime();
        if ((ans[firstNameField] !== '') || (ans[lastNameField] !== '')) {
          const objInDetails = {
            firstName: ans[firstNameField],
            lastName: ans[lastNameField],
            address: {
              streetAddress: ans[streetField],
              city: ans[cityField],
              state: ans[stateField],
              zip: ans[zipField],
            },
            phone: ans[primaryPhone],
            phoneType: ans[phoneType],
            email: ans[email],
            value: 'trustinfo -' + indexId,
            label: ans[firstNameField] + ' ' + ans[lastNameField],
          };
          const obj = {
            checked: false,
            label: ans[firstNameField] + ' ' + ans[lastNameField],
            value: 'trustinfo -' + indexId,
          };
          trustDetailObj.push(obj);
          trustDetailObjWithAddr.push(objInDetails);
        }
      });
      this.userService.setTrustDetails(trustDetailObj, trustDetailObjWithAddr);
      this.tabDetailsService.updateTabList(TAB.ELECTRONIC_FUND_TRANSFER, undefined,
        { visited: false, errorExists: undefined, isLocked: false });
    }
  }

  private getOwnerDetails(ownerGridQuestions): void {
    ownerGridQuestions.forEach(ques => {
      this.getPhoneNumberFieldIdName(ques);
      if (this.checkOwnerAddressSameAs(ques)) {
        this.getOwnerAddrWhenAddressIsSameAs(ques);
      }
      // GET Owners Address same As Field name
      if ((ques.xmlTag === OwnerInfoConstant.OWNER_ADDRESS_SAME_AS)) {
        this.getOwnerAddressSameAsFieldName(ques);
      }
    });
  }

  private getPhoneNumberFieldIdName(question): void {
    if (this.getOptionChoiceType(question, 'person')) {
      if (question.questionText === OwnerInfoConstant.primaryPhoneQues) {
        this.personPhoneFieldName = question.name;
      }
      if (question.xmlTag === EFTConstant.emailAddressXML) {
        this.personEmailFieldName = question.name;
      }
      if (question.xmlTag === EFTConstant.primaryPhoneTypeXML) {
        this.personPhoneTypeFieldName = question.name;
      }
    } else if (this.getOptionChoiceType(question, 'entity')) {
      if (question.xmlTag === OwnerInfoConstant.ENTITY_BUISNESS_PHONE &&
        question.questionText === OwnerInfoConstant.entityPhoneText) {
        this.entityPhoneFieldName = question.name;
      }
      if (question.xmlTag === OwnerInfoConstant.ENTITY_EMAIL) {
        this.entityEmailFieldName = question.name;
      }
      if (question.xmlTag === OwnerInfoConstant.ENTITY_PHONE_TYPE) {
        this.entityPhoneTypeFieldName = question.name;
      }
    }
  }

  private getLastName(trustName): void {
    return ((trustName?.length) > 0 ? trustName[trustName.length - 1] : '');
  }

  private checkFirstLastNamePresent(): boolean {
    return (this.labelFirstName !== '' || this.labelLastName !== '');
  }

  private updateEFTInfo(pageGroupData: PageJsonObject): void {
    let temporaryInsurancePaymentProvider;
    let firstName;
    let lastName;
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.ELECTRONIC_FUND_TRANSFER) {
      pageGroupData.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === EFTConstant.AccountHolderName) {
          temporaryInsurancePaymentProvider = this.questionAnswer(question);
        } else if (question.xmlTag === EFTConstant.firstNameXML) {
          firstName = this.questionAnswer(question);
        } else if (question.xmlTag === EFTConstant.lastNameXML) {
          lastName = this.questionAnswer(question);
        }
      });
      this.userService.setEFTBasicDetails(temporaryInsurancePaymentProvider, firstName, lastName);
      this.setEFTAddressDetails(pageGroupData);
      this.setEFTBankDetails(pageGroupData);
    }
  }

  private updateTIAInfoPage(pageGroupData: PageJsonObject): void {
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE) {
      pageGroupData.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === TIAInfoConstant.tiaSubmitted) {
          this.userService.setIsTIASubmitted(this.questionAnswer(question)?.toLowerCase() === 'yes{47}yes');
        }
      });
    }
  }

  private setEFTAddressDetails(pageGroupData: PageJsonObject): void {
    let address;
    let city;
    let state;
    let zipcode;
    let emailAddress;
    let cellNumber = '';
    const landLineNumber = '';
    pageGroupData.pages[0]?.questions.forEach((question) => {
      if (question.xmlTag === EFTConstant.addressXML) {
        address = this.questionAnswer(question);
      } else if (question.xmlTag === EFTConstant.cityXML) {
        city = this.questionAnswer(question);
      } else if (question.xmlTag === EFTConstant.stateXML) {
        const stateOption = this.questionAnswer(question);
        question?.questionOption.find(option => {
          if (option.value === stateOption) {
            state = { checked: false, label: option?.description, value: option?.value };
          }
        });
      } else if (question.xmlTag === EFTConstant.zipcodeXML) {
        zipcode = this.questionAnswer(question);
      } else if (question.xmlTag === EFTConstant.emailAddressXML) {
        emailAddress = this.questionAnswer(question);
      } else if (question.xmlTag === EFTConstant.primaryPhoneNumberXML) {
        cellNumber = this.questionAnswer(question);
      }
    });

    this.userService.setEFTAddressDetails(address, city, state, zipcode, emailAddress,
      cellNumber, landLineNumber);
  }

  private setEFTBankDetails(pageGroupData: PageJsonObject): void {
    let bankOrCreditUnionName;
    let routingNumber;
    let accountNumber;
    let accountType;
    pageGroupData.pages[0]?.questions.forEach((question) => {
      if (question.xmlTag === EFTConstant.bankOrCreditUnionNameXML) {
        bankOrCreditUnionName = this.questionAnswer(question);
      } else if (question.xmlTag === EFTConstant.routingNumberXML) {
        routingNumber = this.questionAnswer(question);
      } else if (question.xmlTag === EFTConstant.accountNumberXML) {
        accountNumber = this.questionAnswer(question);
      } else if (question.xmlTag === EFTConstant.accountTypeXML) {
        accountType = this.questionAnswer(question);
      }
    });
    this.userService.setEFTBankDetails(bankOrCreditUnionName,
      routingNumber, accountNumber, accountType);
  }

  private updatePrimaryInfo(pageGroupData: PageJsonObject): void {
    let streetAddress1;
    let streetAddress2;
    let streetAddress3;
    let city;
    let state;
    let zip;
    let country;
    let phoneNo;
    let addressType;
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE) {
      pageGroupData.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === PrimaryInsuredConstant.addr1XmlTag) {
          streetAddress1 = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.addr2XmlTag) {
          streetAddress2 = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.addr3XmlTag) {
          streetAddress3 = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.cityXmlTag) {
          city = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.stateXmlTag) {
          state = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.zipXmlTag) {
          zip = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.countryXmlTag) {
          country = this.questionAnswer(question);
        } else if (question.xmlTag === PrimaryInsuredConstant.phoneNoXmlTag) {
          phoneNo = this.questionAnswer(question);
          this.userService.setInsuredPhoneNo(phoneNo);
        } else if (question.xmlTag === PrimaryInsuredConstant.primaryPhoneTypeXmlTag) {
          this.userService.setInsuredPhoneNoType(this.questionAnswer(question));
        } else if (question.xmlTag === PrimaryInsuredConstant.emailXmlTag) {
          this.userService.setInsuredEmail(this.questionAnswer(question));
        } else if (question.xmlTag === PrimaryInsuredConstant.addrTypeXmlTag) {
          addressType = this.questionAnswer(question);
        }
      });
      this.userService.setInsuredAddressType(addressType);
      this.userService.setInsuredAddress(streetAddress1, streetAddress2, streetAddress3, city, state, country, zip);
    }
  }

  private updateProposedB(pageGroupData: PageJsonObject): void {
    let firstName;
    let lastName;
    if (pageGroupData.page_group_name === PAGE_GROUP_NAME.PROPOSED_INSURED_B) {
      pageGroupData.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === ProposedInsuredBConstant.firstName) {
          firstName = this.questionAnswer(question);
        } else if (question.xmlTag === ProposedInsuredBConstant.lastName) {
          lastName = this.questionAnswer(question);
        }
      });
      this.userService.setProposedBName((firstName ?? '') + ' ' + (lastName ?? ''));
    }
  }

  private questionAnswer(question): any {
    return (question.question_answer !== '') ? question.question_answer : null;
  }

  getOwnerAddressSameAsFieldName(ques): void {
    // GET Owners Address same As Field name
    if (this.getOptionChoiceType(ques, 'person')) {
      this.personSameAddressName = ques.name;
    } else if (this.getOptionChoiceType(ques, 'trust')) {
      this.trustSameAddressName = ques.name;
    } else if (this.getOptionChoiceType(ques, 'entity')) {
      this.entitySameAddressName = ques.name;
    }
  }

  private checkSameAsInsAddress(ans, personSameAddressName, trustSameAddressName, entitySameAddressName): void {
    if (ans[personSameAddressName]) {
      this.isSameInsuredAddress = this.getIsSameInsuredAddress(ans, personSameAddressName, 'insured');
    } else if (ans[trustSameAddressName]) {
      this.isSameInsuredAddress = this.getIsSameInsuredAddress(ans, trustSameAddressName, 'insured');
    } else if (ans[entitySameAddressName]) {
      this.isSameInsuredAddress = this.getIsSameInsuredAddress(ans, entitySameAddressName, 'insured');
    } else {
      this.isSameInsuredAddress = null;
    }
  }

  private getTrustName(ans, trustNameField): any {
    return (ans[trustNameField] !== '') ? ans[trustNameField].split(' ') : null;
  }

  private getIsSameInsuredAddress(ans, typeSameAddressName, type): any {
    return (ans[typeSameAddressName]?.toLowerCase().indexOf(type) > -1) ?
      ans[typeSameAddressName] : null;
  }

  private getEntityName(ans, entityBusinessNameField): any {
    return ans[entityBusinessNameField] ? ans[entityBusinessNameField] : '';
  }

  getOwnerAddrWhenAddressIsSameAs(ques): void {
    if (this.getOptionChoiceType(ques, 'person')) {
      this.personAddressFieldName = this.utilService
        .getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS);
      this.personCityFieldName = this.utilService.getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.personStateFieldName = this.utilService.getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.personZipFieldName = this.utilService.getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);

      // Entity fields for address same as insured
      this.personInsAddressFieldName = this.utilService
        .getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS);
      this.personInsCityFieldName = this.utilService
        .getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.personInsStateFieldName = this.utilService
        .getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.personInsZipFieldName = this.utilService.getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);
    } else if (this.getOptionChoiceType(ques, 'trust')) {
      this.trustAddressFieldName = this.utilService
        .getNameFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS);
      this.trustCityFieldName = this.utilService.getNameFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.trustStateFieldName = this.utilService.getNameFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.trustZipFieldName = this.utilService.getNameFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);
    } else if (this.getOptionChoiceType(ques, 'entity')) {
      this.entityAddressFieldName = this.utilService
        .getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS);
      this.entityCityFieldName = this.utilService.getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.entityStateFieldName = this.utilService.getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.entityZipFieldName = this.utilService.getOtherAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);

      // Entity fields for address same as insured
      this.entityInsAddressFieldName = this.utilService
        .getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ADDRESS);
      this.entityInsCityFieldName = this.utilService.getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_CITY);
      this.entityInsStateFieldName = this.utilService
        .getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_STATE);
      this.entityInsZipFieldName = this.utilService.getInsuredAddressFieldByXmlTag(ques.reflexiveQuestionAL, OwnerInfoConstant.OWNER_ZIP);
    }
  }

  private checkOwnerAddressSameAs(ques): boolean {
    return (ques.hasReflexive && (ques.xmlTag === OwnerInfoConstant.OWNER_ADDRESS_SAME_AS));
  }

  private getOptionChoiceType(ques, type): boolean {
    return (ques.optionChoice.toLowerCase().indexOf(type) > -1);
  }

  private isTabVisited(pageStatus: PageStatus): boolean {
    return pageStatus && (pageStatus.pageStatus !== PageStatusValue.NOTVISITED);
  }

  private getCompanyList(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_COMPANY_LIST', null);
  }

  fetchCompanyNames(): void {
    if (this.existingInsuranceCompany?.length === 0) {
      this.getCompanyList().subscribe(res => {
        if (res.data.length > 0) {
          this.existingInsuranceCompany = res.data;
        }
      });
    }
  }

  getExistingInsuranceCompanyList(): Company[] {
    return this.existingInsuranceCompany;
  }

  private getFundMap(fundList: any[], allocationType: string): Map<string, string> {
    const fundMap = new Map<string, string>();
    fundList.forEach(fund => {
      let val;
      if (allocationType.indexOf('50') > -1) {
        val = fund.fifty;
      } else if (allocationType.indexOf('60') > -1) {
        val = fund.sixty;
      } else if (allocationType.indexOf('70') > -1) {
        val = fund.seventy;
      }
      fundMap.set(fund.fundCode, val);
    });
    return fundMap;
  }

  private getFundMapForAssetEdgeFunds(fundList: any[], allocationType: string): Map<string, string> {
    const fundMap = new Map<string, string>();
    fundList.forEach(fund => {
      let val;
      if (allocationType?.toLowerCase().indexOf('conservative') > -1) {
        val = fund.conservative;
      } else if (allocationType?.toLowerCase().indexOf('moderate') > -1) {
        val = fund.moderate;
      } else if (allocationType?.toLowerCase().indexOf('agggrowth') > -1) {
        val = fund.aggressiveGrowth;
      } else if (allocationType?.toLowerCase().indexOf('growth') > -1) {
        val = fund.growth;
      }
      fundMap.set(fund.fundCode, val);
    });
    return fundMap;
  }

  isTabStatusUpdated(tabStatusOnInit: TabStatus, form: FormGroup): boolean {
    return (tabStatusOnInit.errorExists && form.status === 'VALID')
      || (!tabStatusOnInit.errorExists && form.status === 'INVALID');
  }

  getActiveTabStatus(): TabStatus {
    return this.tabDetailsService.activeTab?.status;
  }

  setIsInsuredProfileUpdated(isInsuredProfileUpdated: boolean): void {
    this.isInsuredProfileUpdated = isInsuredProfileUpdated;
  }

  getIsInsuredProfileUpdated(): boolean {
    return this.isInsuredProfileUpdated;
  }

  logPageLoad(): void {
    this.appAnalyticsService.logPageView(this.tabDetailsService.activeTab?.name);
  }

  logButtonClick(eventName: string): void {
    this.appAnalyticsService.logButtonEvents(eventName, this.tabDetailsService.activeTab?.analyticsPageName);
  }

  logLinkClick(eventName: string): void {
    this.appAnalyticsService.logLinkEvents(eventName, this.tabDetailsService.activeTab?.analyticsPageName);
  }
}
