import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { environment } from '../../../environments/environment';
declare let displayTxt: any;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  window: any;
  incCounter = 0;
  public isEnabledisplayTxt = environment?.config?.isEnabledisplayTxt;
  displayTxt = typeof displayTxt === 'undefined' ? {} : displayTxt;
  constructor(public utilsService: UtilsService) {}

  displayIdCreation(data: any, isQuestTxt = false): any {
    let displayDDwnId = '';
    if (!isQuestTxt) {
      displayDDwnId =
        data && data.fieldId
          ? data.fieldId.toString().replace(/[\s\(\)#&\-\/\\!@$*~]/gi, '_')
          : this.utilsService
              .getCorrectContent(data, 'question')
              .toString()
              .replace(/[\s\(\)#&\-\/\\!@$*]/gi, '_') + this.incCounter;
    } else {
      displayDDwnId = data.toString().replace(/[\s\(\)#&\-\/\\!@$*~\?]/gi, '_');
    }
    this.incCounter++;
    return displayDDwnId;
  }

  displayTextLabel(data: any): any {
    let txtLabel = '';
    if (data && data.fieldId) {
      txtLabel = data.fieldId;
    } else if (data) {
      txtLabel = data.toString().replace(/[\s\(\)#&\-\/\\!@$*~.\?]/gi, '_');
    }
    return txtLabel;
  }

  addCorrectPlaceHolder(data, type): any {
    const placeholders = {};
    if (!placeholders[data.fieldId]) {
      placeholders[data.fieldId] = {};
      placeholders[data.fieldId].display =
        this.utilsService.getCorrectContent(data, 'display');
      placeholders[data.fieldId].question =
        this.utilsService.getCorrectContent(data, 'question');
      placeholders[data.fieldId].placeHolder =
        this.utilsService.getCorrectContent(data, 'placeHolder');
    }
    return placeholders[data.fieldId][type];
  }

  triggerChangeCustom(
    _type: string,
    id: string,
    nameOfField: string,
    formName?: string,
    _data?: any
  ): any {
    if (this.isEnabledisplayTxt) {
      const elId = nameOfField + '_' + id;
      if (this.window.displayTxt) {
      this.updateFormName(elId, formName, id);
      }
    }
  }

  private updateFormName(elId: string, formName: string, id: string): string {
    let frmCntl = document.getElementById(elId);
    frmCntl = frmCntl ? frmCntl : document.getElementById(id);
    const tempFormName = frmCntl && frmCntl.closest('form') ? frmCntl.closest('form').getAttribute('id') : null;
    return formName ? formName : tempFormName;
  }
}
