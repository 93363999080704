import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Question, GridInstance, QuestionOption } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, LTCExistingInsConst } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-ltc-existing-insurance',
  templateUrl: './ltc-existing-insurance.component.html',
  styleUrls: ['./ltc-existing-insurance.component.scss']
})
export class LtcExistingInsuranceComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  isShowAccordian = false;
  isLtcFormValid = false;
  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  ltcInsuranceData: Question[] = [];
  ltcInsuranceForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;

  isGridFormValid = true;
  isGridFormChange = false;

  formHasNoErrors = true;
  gridHasNoErrors = false;

  maxNoOfPolicy: number;
  insurerOptions: any;

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    public appConfig: ApplicationConfig,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
      this.maxNoOfPolicy = +(this.gridInstance[0]?.maxGridIndex);
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.createLTCExistingInsuranceDropDown();
      this.buildFormData();
      this.displayAccordianOnRadioChange();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.ltcInsuranceForm);
      this.defaultPageLoadService.disableFormIfLocked(this.ltcInsuranceForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.ltcInsuranceData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.ltcInsuranceData
    );
    this.ltcInsuranceForm = this.fb.group(form);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.ltcInsuranceForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.displayAccordianOnRadioChange();
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.ltcInsuranceForm,
      data: null,
    };
    for (const data of this.ltcInsuranceData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  ltcInsuranceOnRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  ltcInsuranceHandleSelectionChange(data, _ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  ltcInsuranceAddCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.ltcInsuranceForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.ltcInsuranceForm.get(key)?.markAsUntouched();
      }
      this.ltcInsuranceForm.patchValue(updatedval);
    }
  }

  getErrorMsg(data, formControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  gridformStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    this.emitStatus();
  }

  emitStatus(): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    this.isLtcFormValid = this.formValid && ((this.isShowAccordian && this.isGridFormValid) || !this.isShowAccordian);
    const existingInsuranceInfoObj = {
      formValid: this.isLtcFormValid,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'ltcInsuranceForm',
      gridForm: true,
      isFormChange: this.ltcInsuranceForm.dirty || this.isGridFormChange || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.ltcInsuranceForm),
    };
    this.formStatus.emit(existingInsuranceInfoObj);
  }

  private createLTCExistingInsuranceDropDown(): void {
    const value = 'Company Not Listed';
    const description = 'Company Not Listed';
    const label = 'Company Not Listed';
    const quesOptions: QuestionOption[] = [];
    const companyList = this.defaultPageLoadService.getExistingInsuranceCompanyList();
    if (companyList?.length > 0) {
      this.utilsService.createDropDownFromList(companyList, quesOptions);
    }
    quesOptions.push({ value, description, label });
    this.insurerOptions = this.utilsService.formatOptions(quesOptions);
    this.updateLTCQuestionDataWithFirm(quesOptions);
  }

  private updateLTCQuestionDataWithFirm(quesOptions: QuestionOption[]): void {
    this.gridInstance[0].gridQuestions.forEach((question) => {
      if (question.xmlTag === LTCExistingInsConst.companyDropdown) {
        question.questionOption = quesOptions;
      }
    });
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private displayAccordianOnRadioChange(): void {
    const isMoreCompanyQuesId = this.getFieldIdByXmlTag(LTCExistingInsConst.LTCReplacedInd);
    this.isShowAccordian =
      this.ltcInsuranceForm.get(isMoreCompanyQuesId)?.value.toLowerCase() === 'yes{47}yes';
    if (this.ltcInsuranceForm.get(isMoreCompanyQuesId)?.value.toLowerCase() === 'no{47}no') {
      this.gridAnswers = [];
    }
  }

}
