import { Injectable } from '@angular/core';
import { RestClientService } from '@ng/lfg-common-utilities';
import { Observable, throwError } from 'rxjs';
import { ServiceConfig, SERVICE_CONFIGURATION } from 'src/config/service-config';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ServiceInvoker {

  lastApiCalledTime: Date;
  constructor(
    private restClient: RestClientService, private serviceConfig: ServiceConfig,
    private httpClient: HttpClient, private router: Router
    ) { }

  invokeService(
    serviceName: keyof typeof SERVICE_CONFIGURATION, queryParam?: any,
    payload?: any, responseType?: any, httpHeader?: { [name: string]: string }): Observable<any> {

    const serviceConfiguration = this.serviceConfig.getServiceConfig(serviceName);
    this.lastApiCalledTime = new Date();

    if (!serviceConfiguration) {
      return throwError('Configuration is not available');
    }
    let url = serviceConfiguration.URL;

    if (serviceConfiguration.HAS_PATH_PARAMS) {
      url = this.formUrl(url, queryParam);
    }
    switch (serviceConfiguration.CALL_TYPE) {
      case 'GET':
        return this.httpGetInvoker(url).pipe(catchError(error => this.handleError(error)));
      case 'POST':
        return this.httpPostInvoker(url, responseType, payload).pipe(catchError(error => this.handleError(error)));
      case 'PUT':
        return this.httpPutInvoker(url, payload).pipe(catchError(error => this.handleError(error)));
      case 'PATCH':
        return this.httpPatchInvoker(url, responseType, payload).pipe(catchError(error => this.handleError(error)));
      case 'DELETE':
        return this.httpDeleteInvoker(url, payload).pipe(catchError(error => this.handleError(error)));
      case 'GETRESOURCE':
        return this.httpGetResourceInvoker(url).pipe(catchError(error => this.handleError(error)));
      case 'POSTWITHHEADER':
        return this.httpMakeCallInvoker(url, payload, responseType, httpHeader).pipe(catchError(error => this.handleError(error)));
      default:
        return throwError('Method not supported');
    }
  }

  formUrl(url: string, pathparams: any): string {
    if (pathparams) {
      const pathKeys: string[] = Object.keys(pathparams);
      pathKeys.forEach((pathKey) => {
        url = url.replace('${' + pathKey + '}', pathparams[pathKey]);
      });
    }
    return url;
  }

  httpGetInvoker(url: string): Observable<any> {
    return this.restClient.get(url);
  }

  httpMakeCallInvoker(url: string, payload?: any, responseType?: any, httpHeader?: { [name: string]: string }): Observable<any> {
    return this.restClient.makeCall(url, payload, responseType, httpHeader);
  }

  httpPostInvoker(url: string, responseType?: any, payload?: any): Observable<any> {
    return this.restClient.post(url, payload, responseType);
  }

  httpGetResourceInvoker(url: string): Observable<any> {
    return this.restClient.getResourceCall(url);
  }

  httpPutInvoker(url: string, payload?: any): Observable<any> {
    return this.httpClient.put(url, payload);
  }

  httpDeleteInvoker(url: string, payload?: any): Observable<any> {
    const options = { headers: new HttpHeaders(), body: payload };
    return this.httpClient.delete(url, options);
  }

  httpPatchInvoker(url: string, responseType?: any, payload?: any): Observable<any> {
    return this.httpClient.patch(url, payload, responseType);
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.status === 401) {
      this.router.navigate(['/unauthorized']);
    }
    return throwError(error);
  }

  pingServer(): void {
    if ((new Date().getTime() - this.lastApiCalledTime?.getTime()) > (10 * 60 * 1000)) {
      this.invokeService('PING_SERVER').subscribe();
    }
  }
}
