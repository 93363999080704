<lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeModalPopup(modalConfig)">
  <div modal-header>
    <h1>{{Message.MODAL_HEADING}}</h1>
  </div>
  <div modal-body>
    <div class="modal-message">
      <p>{{Message.INFO_TEXT_1}}</p>
      <p>{{Message.INFO_TEXT_2}}</p>
      <p>{{Message.INFO_TEXT_3}}</p>
    </div>
  </div>
  <div modal-footer>
    <lfg-left-button-tray>
      <button class='btn' lfgDefaultButton (click)=modalResponseHandler(true)>Ok</button>
      <button class='btn' lfgLinkButton (click)=modalResponseHandler(false)>Cancel</button>
    </lfg-left-button-tray>
  </div>
</lfg-modal-popup>
