<section class="waReplacement-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      {{forProposedInsured ? 'WA Replacements - Insured B' : 'WA Replacements'}}
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isGridFormValid)" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="waReplacementForm" id="waReplacementForm" method="post" *ngIf="waReplacementData && waReplacementData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of waReplacementData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Heading'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="questions-header ques-heading" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Heading'">
            <p class="ques-heading-para">{{ data.questionText | uppercase }}</p>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || (data.notes.indexOf('ITES_CURRENCY_USD') == -1 && data.notes.indexOf('ITES_PERCENTAGE') == -1))" [ngClass]="{'inline-ques': data.subText == 'ITES_INLINE_FIELD'}">
            <lfg-input-wrap>
              <div class="inline-ques-container">
                <span *ngIf="data.subText == 'ITES_INLINE_FIELD'" class="inline-ques-text">{{ data.questionText }}</span>
                <label for="{{data.fieldId}}" *ngIf="data.subText == 'ITES_INLINE_FIELD'">
                  <span class="visually-hidden">{{data.questionText}}</span>
                  <input lfgInput class="inline-ques-input" [id]="data.fieldId" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                  <div class="inline-error">
                    <lfg-error *ngIf="waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid" message="Please enter a valid input."></lfg-error>
                  </div>
                </label>
                <input *ngIf="data.subText != 'ITES_INLINE_FIELD'" lfgInput placeholder="{{waReplacementAddCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              </div>
              <lfg-error *ngIf="data.subText != 'ITES_INLINE_FIELD' && waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, waReplacementForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

           <!-- Form element for percentage  -->
           <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes && data.notes.indexOf('ITES_PERCENTAGE') > -1)" [ngClass]="{'inline-ques': data.subText == 'ITES_INLINE_FIELD'}">
            <lfg-input-wrap>
              <div class="inline-ques-container">
                <span *ngIf="data.subText == 'ITES_INLINE_FIELD'" class="inline-ques-text">{{ data.questionText }}</span>
                <label for="{{data.fieldId}}" *ngIf="data.subText == 'ITES_INLINE_FIELD'">
                  <span class="visually-hidden">{{data.questionText}}</span>
                  <input lfgInput class="inline-ques-input" [id]="data.fieldId" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols appPercentageFormat [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                  <div class="inline-error">
                    <lfg-error *ngIf="waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid" message="Please enter a valid input."></lfg-error>
                  </div>
                </label>
                <input *ngIf="data.subText != 'ITES_INLINE_FIELD'" lfgInput placeholder="{{waReplacementAddCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              </div>
              <lfg-error *ngIf="data.subText != 'ITES_INLINE_FIELD' && waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, waReplacementForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for currency  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1)" [ngClass]="{'inline-ques': data.subText == 'ITES_INLINE_FIELD'}">
            <lfg-input-wrap>
              <div class="inline-ques-container">
                <span *ngIf="data.subText == 'ITES_INLINE_FIELD'" class="inline-ques-text">{{ data.questionText }}</span>
                <label for="{{data.fieldId}}" *ngIf="data.subText == 'ITES_INLINE_FIELD'">
                  <span class="visually-hidden">{{data.questionText}}</span>
                  <input lfgInput aria-live="off" [id]="data.fieldId" class="inline-ques-input" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="data.required == 'true'">
                  <div class="inline-error">
                    <lfg-error *ngIf="waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid" message="Please enter a valid input."></lfg-error>
                  </div>
                </label>
                <input *ngIf="data.subText != 'ITES_INLINE_FIELD'" lfgInput aria-live="off" placeholder="{{waReplacementAddCorrectPlaceHolder(data, 'placeHolder')}}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="data.required == 'true'">
              </div>
              <lfg-error *ngIf="data.subText != 'ITES_INLINE_FIELD' && waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, waReplacementForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid), 'radio-single': data.questionText?.toLowerCase().indexOf('insured be the owner') > -1}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ waReplacementAddCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="waReplacementOnRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="waReplacementOnRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="waReplacementForm.get(data.fieldId).touched && waReplacementForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && data.reflexiveQuestionAL.length > 0 && waReplacementForm.get(data.fieldId).value as parentAnswer">
            <ng-container *ngFor="let waReplacementChild1 of data.reflexiveQuestionAL; index as waReplacementChild1ix">
              <ng-container *ngIf="nestedQuestionCheckwaReplacement(data, waReplacementChild1, parentAnswer)">
                <!-- Questions headers -->
                <div class="questions-header" *ngIf="waReplacementChild1.controlTypeDesc == appConfig.fieldTypes.HDR && waReplacementChild1.subText == 'Label'">
                  <span>{{ waReplacementChild1.questionText }}</span>
                </div>

                <div class="question-info" aria-live="assertive" *ngIf="waReplacementChild1.controlTypeDesc == appConfig.fieldTypes.HDR && waReplacementChild1.subText == 'Information'">
                  <lfg-alert-message type="info">{{ waReplacementChild1.questionText }}</lfg-alert-message>
                </div>

                <!-- Form element for text  Area-->
                <div class="form--control form--control__full-width reflexive-text-area" aria-live="assertive" *ngIf="waReplacementChild1.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
                  <lfg-input-wrap>
                    <input lfgInput placeholder="{{ waReplacementAddCorrectPlaceHolder(waReplacementChild1, 'placeHolder') }}" name="{{ displayTextLabel(waReplacementChild1) }}" aria-live="off" type='text' formControlName='{{ waReplacementChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="waReplacementChild1.maxLength" [required]="waReplacementChild1.required">
                    <span class="text-area-count">{{waReplacementForm.get(waReplacementChild1.fieldId).value.length}}/{{waReplacementChild1.maxLength}}</span>
                    <lfg-error *ngIf="waReplacementForm.get(waReplacementChild1.fieldId).touched && waReplacementForm.get(waReplacementChild1.fieldId).invalid" message="{{ getErrorMsg(waReplacementChild1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for text  -->
                <div class="form--control form-control-medium" *ngIf="waReplacementChild1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(waReplacementChild1) }}" placeholder="{{ waReplacementAddCorrectPlaceHolder(waReplacementChild1, 'placeHolder') }}" type='text' formControlName='{{ waReplacementChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="waReplacementChild1.maxLength" [required]="waReplacementChild1.required == 'true'">

                    <lfg-error *ngIf="waReplacementForm.get(waReplacementChild1.fieldId).touched && waReplacementForm.get(waReplacementChild1.fieldId).invalid" message="{{ getErrorMsg(waReplacementChild1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for alphanumeric  -->
                <div class="form--control" *ngIf="waReplacementChild1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(waReplacementChild1) }}" placeholder="{{ waReplacementAddCorrectPlaceHolder(waReplacementChild1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ waReplacementChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="waReplacementChild1.maxLength" [required]="waReplacementChild1.required == 'true'">

                    <lfg-error *ngIf="waReplacementForm.get(waReplacementChild1.fieldId).touched && waReplacementForm.get(waReplacementChild1.fieldId).invalid" message="{{ getErrorMsg(waReplacementChild1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <div *ngIf="gridInstance?.length > 0" class="padtop10">
          <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="maxNoOfPolicy" [accordionHeader]="'Existing Insurance Details'" [type]="'EXISTING_INS'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm" [pageName]="forProposedInsured ? 'WA-REPLACEMENT-PROPOSED-B' : 'WA-REPLACEMENT'"></app-grid-structure>
        </div>
      </form>
    </section>
  </div>
</section>
