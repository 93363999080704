<section class="ltcInsurance-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      LTC - Existing/Pending Insurance
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && (!isLtcFormValid)" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="ltcInsuranceForm" id="ltcInsuranceForm" method="post" *ngIf="ltcInsuranceData && ltcInsuranceData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of ltcInsuranceData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1)" [ngClass]="{'inline-ques': data.subText == 'ITES_INLINE_FIELD'}">
            <lfg-input-wrap>
              <div class="inline-ques-container">
                <span *ngIf="data.subText == 'ITES_INLINE_FIELD'" class="inline-ques-text">{{ data.questionText }}</span>
                <label for="{{data.fieldId}}" *ngIf="data.subText == 'ITES_INLINE_FIELD'">
                  <span class="visually-hidden">{{data.questionText}}</span>
                  <input lfgInput class="inline-ques-input" [id]="data.fieldId" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                </label>
                <input *ngIf="data.subText != 'ITES_INLINE_FIELD'" lfgInput placeholder="{{ltcInsuranceAddCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              </div>
              <lfg-error *ngIf="ltcInsuranceForm.get(data.fieldId).touched && ltcInsuranceForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, ltcInsuranceForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (ltcInsuranceForm.get(data.fieldId).touched && ltcInsuranceForm.get(data.fieldId).invalid), 'radio-single': data.questionText?.toLowerCase().indexOf('insured be the owner') > -1}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ ltcInsuranceAddCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="ltcInsuranceOnRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="ltcInsuranceOnRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="ltcInsuranceForm.get(data.fieldId).touched && ltcInsuranceForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>
        </ng-container>

        <div *ngIf="(gridInstance?.length > 0) && isShowAccordian" class="padtop10">
          <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [isRevisit]="isTabVisited" [addAccordionText]="'Add a policy'" [maxGridAvailable]="maxNoOfPolicy" [accordionHeader]="'Existing Insurance Details'" [type]="'EXISTING_INS'" [existingInsAnswers]="existingInsAnswers" [disableForm]="disableForm"></app-grid-structure>
        </div>
      </form>
    </section>
  </div>
</section>
