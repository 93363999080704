<section class="agent-Info-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Agent Information
      <span class="split-percent">
        <strong>Total Split: <span *ngIf="!showAgentAccordion" class="success-color">100%</span>
          <span *ngIf="showAgentAccordion" [ngClass]="totalSplitPercentage === 100 ? 'success-color' : 'error-color'">{{totalSplitPercentage}}%</span></strong>
      </span>
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>

    <lfg-alert-message *ngIf="!disableForm && ((isTabVisited && formValid && !getAgentValidityStatus()) && !agentValidationErrorOccurred)" type="error">{{AgentValMsg.VALIDATION_PENDING}}</lfg-alert-message>
    <lfg-alert-message *ngIf="agentValidationErrorOccurred" type="error">{{AgentValMsg.ERROR_OCCURRED}}</lfg-alert-message>
    <lfg-alert-message *ngIf="duplicateSSNExists" type="error">{{AgentValMsg.DUPLICATE_SSN_ERROR}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="agentInfoForm" id="agentInfoForm" method="post" *ngIf="agentInfoData && agentInfoData.length > 0">

        <div class="loader-container" *ngIf="showLoader">
          <lfg-loader></lfg-loader>
        </div>

        <ng-container class="form-control-parent" *ngFor="let data of agentInfoData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Button'" [ngClass]="{'margin-top-20' : data.notes === 'INLINE_HEADER'}">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- USA address -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
            <h2>{{ data.questionText }}</h2>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes != 'ITES_FORMAT_AS_ZIPCODE'">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'" (input)="checkIfSSNIsValidAndResetValues(data.xmlTag)">
              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for zipcode  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_ZIPCODE'">
            <lfg-input-wrap>
              <input lfgInput mask='00000-0000' placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithHyphen" maxlength="10" [required]="data.required == 'true'">
              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, agentInfoForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for SSN -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN && data.xmlTag !== AgentConst.agentNPNQuesId">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='agentInfoForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur [required]="data.required == 'true'" (input)="checkIfSSNIsValidAndResetValues(data.xmlTag)">

              <lfg-error *ngIf="(agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid) && ( agentInfoForm.get(data.fieldId).errors == null || (agentInfoForm.get(data.fieldId).errors != null && !agentInfoForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO &&
            ((data.xmlTag === AgentConst.doYouattestTag && showAttestRadioButton) || data.xmlTag != AgentConst.doYouattestTag)" [ngClass]="{'radio--err': (agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid), 'question-radio--medium': data.xmlTag == AgentConst.contactPhoneTypeXmlTag}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText }}</p>
              <div class="radio-btn-rect custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onAgentRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onAgentRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <!-- As the error message contains few words in bold so adding custom error message -->
            <div *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" aria-live="polite">
              <span class="cust-form-field-err" [innerHTML]="getErrorMsg(data, agentInfoForm.get(data.fieldId))"></span>
            </div>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag != AgentConst.firmNameQuesId">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleAgentSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err':(agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid)}">
            </lfg-dropdown>
            <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form element for Firmlist Drop Down -->
          <div class="form--control form-dropDown" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.xmlTag == AgentConst.firmNameQuesId">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="firmOptions" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleAgentSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="disableFirmDropDown ? 'lfg-dropdown--disabled' : ''">
            </lfg-dropdown>
            <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid && !disableFirmDropDown" message="Please select a valid option."></lfg-error>
          </div>

          <div *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE && data.xmlTag == AgentConst.agentPhoneNumber">
            <lfg-alert-message type="info">{{ phoneInfo }}</lfg-alert-message>
          </div>

          <!-- Form element for phone number -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
              <lfg-error *ngIf="(agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for email  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'" (input)="checkToShowAttestRadioButton(data)">

              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addAgentCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

              <lfg-error *ngIf="agentInfoForm.get(data.fieldId).touched && agentInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Question Info -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information' &&
            ((data.fieldId === AgentConst.emailInfoFieldId && showAttestRadioButton) || data.fieldId != AgentConst.emailInfoFieldId)">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Form element for search Button -->
          <div class="form--control form--length-btn search-btn" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText === 'Search'">
            <button id="{{displayIdCreation(data)}}_{{ind}}" (click)="loadFirmDetails(); focusOff($event);" class="btn focusOffBtn" [disabled]="!agentSSNValid || !agentLastNameValid || disableForm">{{data.questionText}}</button>

          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && checkIfQuestionDisplayed(data) && agentInfoForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let agentInfoChild1 of data.reflexiveQuestionAL; index as agentInfoChild1ix">
              <ng-container *ngIf="nestedQuestionCheckAgentInfo(data, agentInfoChild1, parentAnswer)">

                <!-- Form element for text  -->
                <div class="form--control" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && agentInfoChild1.xmlTag == AgentConst.splitMainAgentQuesId">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(agentInfoChild1) }}" placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ agentInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="agentInfoChild1.maxLength" [required]="agentInfoChild1.required == 'true'">
                    <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).errors?.percentError" message="Split must be equal to 100%"></lfg-error>
                    <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).errors?.textError" message="Please enter a valid {{agentInfoChild1.questionText | lowercase}}."></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for text  -->
                <div class="form--control" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXT && agentInfoChild1.xmlTag != AgentConst.splitMainAgentQuesId">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(agentInfoChild1) }}" placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" type='text' formControlName='{{ agentInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="agentInfoChild1.maxLength" [required]="agentInfoChild1.required == 'true'">
                    <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid" message="Please enter a valid {{agentInfoChild1.questionText | lowercase}}."></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for text area -->
                <div class="form--control form--control__full-width" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
                  <lfg-input-wrap>
                    <input lfgInput placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" name="{{ displayTextLabel(agentInfoChild1) }}" aria-live="off" type='text' formControlName='{{ agentInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="agentInfoChild1.maxLength" [required]="agentInfoChild1.required">
                    <span class="text-area-count">{{agentInfoForm.get(agentInfoChild1.fieldId).value.length}}/{{agentInfoChild1.maxLength}}</span>
                    <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid" message="Please enter a valid {{agentInfoChild1.questionText | lowercase}}."></lfg-error>
                  </lfg-input-wrap>
                </div>


                <!-- Form element for alphanumeric  -->
                <div class="form--control" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(agentInfoChild1) }}" placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ agentInfoChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="agentInfoChild1.maxLength" [required]="agentInfoChild1.required == 'true'">

                    <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid" message="{{ getErrorMsg(agentInfoChild1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" aria-live="assertive" *ngIf="agentInfoChild1.controlTypeDesc == appConfig.fieldTypes.SELECT " [ngClass]="{'drop-down-medium': agentInfoChild1.questionText.length > 24}">
                  <lfg-dropdown formControlName="{{ agentInfoChild1.fieldId }}" id="{{displayIdCreation(agentInfoChild1)}}_{{agentInfoChild1ix}}" [options]="alldropDownOptions[agentInfoChild1.fieldId]" placeholder="{{ addAgentCorrectPlaceHolder(agentInfoChild1, 'placeHolder') }}" (selectionChanged)="handleAgentSelectionChange(agentInfoChild1, agentInfoChild1ix)" [required]="agentInfoChild1.required == 'true'" [ngClass]="{'dropdown--err':(agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="agentInfoForm.get(agentInfoChild1.fieldId).touched && agentInfoForm.get(agentInfoChild1.fieldId).invalid" message="Please select a valid option."></lfg-error>
                </div>

              </ng-container>
            </ng-container>
          </div>

        </ng-container>

        <div class="horizontal-line"></div>
        <span class="split-percent-bottom">
          <strong>Total Split: <span *ngIf="!showAgentAccordion" class="success-color">100%</span>
            <span *ngIf="showAgentAccordion" [ngClass]="totalSplitPercentage === 100 ? 'success-color' : 'error-color'">{{totalSplitPercentage}}%</span></strong>
        </span>

        <div *ngIf="gridInstance?.length > 0 && showAgentAccordion && firmUpdatedForSSN.length === ssnListFromGridAns.length">

          <app-grid-structure [gridInstances]="gridInstance" [gridAnswers]="gridAnswers" (gridStatus)="gridformStatus($event)" [addAccordionText]="'Add Agent'" [maxGridAvailable]="maxNoOfAgent" [accordionHeader]="'Agent'" [type]="'AGENT'" [isRevisit]="isTabVisited" [disableForm]="disableForm"></app-grid-structure>
        </div>

        <!-- Form element for validate Button -->
        <div class="form--control validate-btn form--control__full-width bottom-container">
          <button id="validate-agent-btn" (click)="validateAgents($event); focusOff($event);" class="btn focusOffBtn" aria-label="Validate License and Appointment" [disabled]="!isAgentFormValid || disableForm">Validate License and Appointment</button>
        </div>

      </form>
    </section>
  </div>
</section>

<app-agent-validation-popup *ngIf="action == 'openModal'" [message]="messageForDisplay" (updateModalAction)="updateModalAction($event)" [invalidAgents]="invalidAgents"></app-agent-validation-popup>