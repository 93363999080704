<section class="fund-allocation-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Fund Allocation
    </h1>
    <ng-container *ngIf="!disableForm">
      <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
      <lfg-alert-message *ngIf="totalFundPercentage != 100 && totalFundPercentage != 0 && isfundsValid" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
      <lfg-alert-message *ngIf="!isfundsValid" type="error">{{ErrMessage.VUL_FUND_ERROR}}</lfg-alert-message>
    </ng-container>

    <section>
      <form class="form-group" [formGroup]="vulOneFundAllocationForm" id="vulOneFundAllocationForm" method="post" *ngIf="vulOneFundAllocationData && vulOneFundAllocationData.length > 0">

        <ng-container class="form-control-parent" *ngFor="let data of vulOneFundAllocationData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && !data.subText">
            <p [innerHTML]="data.questionText"></p>
          </div>

          <div class="section-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Header'" [ngClass]="{'header-container': data.subText == 'Header'}">
            <div>
              <p><i class="fas fa-list-alt" aria-hidden="true"></i><span>{{ data.questionText | uppercase}}</span></p>
            </div>
            <div class="btn-reset-container"> <a class="btn-link btn-reset" [ngClass]="{'btn--link-disabled': disableForm}" id="resetBtn" type="button" (click)="resetForm(data.questionText)">
                <i class="fas fa-undo reset-icon" aria-hidden="true"></i> Reset all selections </a></div>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Element for portfolio selection desktop-->
          <div class="portfolio-selection-container display-in-desktop" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.subText == 'PORTFOLIO_SELECTION' && data.questionText === 'Active Portfolio'">
            <div class="portfolio-sect-holder">
              <div class="portfolio-section portfolio-section-a" [ngClass]="{'selected-section' : selectedPortfolio === 'active'}">
                <div class="portfolio-header-container">
                  <p class="portfolio-header">Active portfolio</p><a class="portfolio-link" (click)="onPortFolioSelection('active')" *ngIf="selectedPortfolio !== 'active'" [ngClass]="{'btn--link-disabled': disableForm}">View</a>
                </div>
                <label class="radio-input-label lfg-radio" *ngFor="let option of data.questionOption" [ngClass]="{'radio-button-checked': selectedAllocation === option.value}">
                  <input [attr.aria-label]="option.value+'-active'" tabindex="0" [id]="option.value+'-active'" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" [value]="option.value+'-active'" (change)="onAllocationRadioChange(option.value)" [attr.disabled]="selectedPortfolio !== 'active' ?  true: null" />
                  <label [attr.for]="option.value+'-active'" [ngClass]="{'not-selected' : selectedPortfolio !== 'active'}">{{option.description}}</label>
                </label>
              </div>
              <div [ngClass]="{'selection-down-arrow' : selectedPortfolio === 'active', 'arrowDiv': selectedPortfolio !== 'active'}"></div>
            </div>

            <div class="portfolio-sect-holder">
              <div class="portfolio-section" [ngClass]="{'selected-section' : selectedPortfolio === 'hybrid'}">
                <div class="portfolio-header-container">
                  <p class="portfolio-header">Hybrid portfolio</p><a class="portfolio-link" (click)="onPortFolioSelection('hybrid')" *ngIf="selectedPortfolio !== 'hybrid'" [ngClass]="{'btn--link-disabled': disableForm}">View</a>
                </div>
                <label class="radio-input-label lfg-radio" *ngFor="let option of data.questionOption" [ngClass]="{'radio-button-checked': selectedAllocation === option.value}">
                  <input [attr.aria-label]="option.value+'-hybrid'" tabindex="0" [id]="option.value+'-hybrid'" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" [value]="option.value+'-hybrid'" (change)="onAllocationRadioChange(option.value)" [attr.disabled]="selectedPortfolio !== 'hybrid' ?  true: null" />
                  <label [attr.for]="option.value+'-hybrid'" [ngClass]="{'not-selected' : selectedPortfolio !== 'hybrid'}">{{option.description}}</label>
                </label>
              </div>
              <div [ngClass]="{'selection-down-arrow' : selectedPortfolio === 'hybrid'}"></div>
            </div>
          </div>

          <!-- Element for portfolio selection mobile -->
          <div class="portfolio-selection-container-mobile display-in-mobile" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.subText == 'PORTFOLIO_SELECTION' && data.questionText === 'Active Portfolio'">
            <lfg-tabs [selectedIndex]="tabIndexSelected" (selectionChange)="handleTabChange($event)">
              <tab>
                <tab-title [ngClass]="{'disable-tab': disableForm}">Active</tab-title>
                <tab-content>
                  <label class="radio-input-label lfg-radio" *ngFor="let option of data.questionOption" [ngClass]="{'radio-button-checked': selectedAllocation === option.value}">
                    <input [attr.aria-label]="option.value+'-active'" tabindex="0" [id]="option.value+'-active-mobile'" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" [value]="option.value+'-active'" (change)="onAllocationRadioChangeMobile(option.value)" />
                    <label [attr.for]="option.value+'-active-mobile'">{{option.description}}</label>
                  </label>
                </tab-content>
              </tab>
              <tab>
                <tab-title [ngClass]="{'disable-tab': disableForm}">Hybrid</tab-title>
                <tab-content>
                  <label class="radio-input-label lfg-radio" *ngFor="let option of data.questionOption" [ngClass]="{'radio-button-checked': selectedAllocation === option.value}">
                    <input [attr.aria-label]="option.value+'-hybrid'" tabindex="0" [id]="option.value+'-hybrid-mobile'" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" [value]="option.value+'-hybrid'" (change)="onAllocationRadioChangeMobile(option.value)" />
                    <label [attr.for]="option.value+'-hybrid-mobile'">{{option.description}}</label>
                  </label>
                </tab-content>
              </tab>
            </lfg-tabs>
          </div>

          <!-- Table for portfolio selection -->
          <div class="portfolio-table-container" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'PORTFOLIO_TABLE'">
            <p id="portfolio_table"></p>
            <table aria-describedby="portfolio_table">
              <caption class="visually-hidden"></caption>
              <tr class="portfolio-table-head display-in-desktop">
                <th [ngClass]="{'col-selected-prev': optionIndex - 1 === 0 }" id="col1">{{(selectedPortfolio ? selectedPortfolio : 'active') | uppercase}} {{' Portfolios fund' | uppercase}}</th>
                <th class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 1, 'col-selected col-head': optionIndex === 1 }" id="col2">50/50 ALLOCATION</th>
                <th class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 2, 'col-selected col-head': optionIndex === 2 }" id="col3">60/40 ALLOCATION</th>
                <th class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 3, 'col-selected col-head': optionIndex === 3 }" id="col4">70/30 ALLOCATION</th>
              </tr>
              <tr class="portfolio-table-head-header display-in-mobile">
                <td>{{(selectedPortfolio ? selectedPortfolio : 'active') | uppercase}}</td>
                <td class="align-center" colspan="3">ALLOCATION</td>
              </tr>
              <tr class="portfolio-table-head display-in-mobile">
                <th [ngClass]="{'col-selected-prev': optionIndex - 1 === 0 }" id="col1mob">FUND</th>
                <th [ngClass]="{'col-selected-prev': optionIndex - 1 === 1, 'col-selected col-head': optionIndex === 1 }" id="col2mob">50/50</th>
                <th [ngClass]="{'col-selected-prev': optionIndex - 1 === 2, 'col-selected col-head': optionIndex === 2 }" id="col3mob">60/40</th>
                <th [ngClass]="{'col-selected-prev': optionIndex - 1 === 3, 'col-selected col-head': optionIndex === 3 }" id="col4mob">70/30</th>
              </tr>
              <tr *ngFor="let portfolio of currentlySelectedPortFolioDataList; let ind=index" class="portfolio-table-row">
                <td [ngClass]="{'col-selected-prev': optionIndex - 1 === 0 }">{{portfolio.fundLabel}}</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 1, 'col-selected': optionIndex === 1 }">{{portfolio.fifty}}%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 2, 'col-selected': optionIndex === 2 }">{{portfolio.sixty}}%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 3, 'col-selected': optionIndex === 3 }">{{portfolio.seventy}}%</td>
              </tr>
              <tr>
                <td [ngClass]="{'col-selected-prev': optionIndex - 1 === 0}">Total</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 1, 'col-selected col-last': optionIndex === 1 }">100%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 2, 'col-selected col-last': optionIndex === 2 }">100%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 3, 'col-selected col-last': optionIndex === 3 }">100%</td>
              </tr>
            </table>

          </div>

          <!-- Funds table -->
          <div class="funds-table-container" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-header'">
            <div class="total-percent">
              <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
            </div>
            <div class="funds-table-header funds-table">
              <div class="table-column col-1">{{data.questionText | uppercase}} </div>
              <div class="col-2 table-column">INPUT %</div>
            </div>
          </div>
          <div class="funds-table funds-table-subheader fund-table-label" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-label'">
            <div class="table-column">{{data.questionText}} </div>
          </div>
          <div class="funds-table funds-table-subheader" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-subheader'">
            <div class="table-column">{{data.questionText | uppercase}} </div>
          </div>
          <div *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes && data.notes.indexOf('FundSelect') > -1" class="funds-table funds-table-row">
            <div class="table-column col-1">{{data.questionText}} </div>
            <div class="table-column col-2">
              <label for="{{data.fieldId}}" id="fund-field-label-{{data.fieldId}}">
                <span class="visually-hidden">Label for the fund input</span>
                <input aria-live="off" lfgInput name="{{ data.questionText }}" id="{{data.fieldId}}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data.required == 'true'">
              </label>
            </div>
          </div>

          <!-- Question Info -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('FundSelect') == -1)">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addFundCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="vulOneFundAllocationForm.get(data.fieldId).touched && vulOneFundAllocationForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, vulOneFundAllocationForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Question Alert -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
            <lfg-alert-message type="warning">{{ data.questionText }}</lfg-alert-message>
          </div>

        </ng-container>

        <ng-container *ngFor="let vulOneFundAlloData of fundAllocationData; let tier = index;">
          <div class="additional-funds-section" (click)="toggleAccordionStatus(tier)">
            <div class="accrd-header">
              TIER {{tier + 1}}
              <span *ngIf="selectedPortfolio" class="disabled-pill-tag">DISABLED</span>
              <span *ngIf="!selectedPortfolio" class="total-acrdn-header"> - {{getTotalForFunds(vulOneFundAlloData)}}%</span>
              <div *ngIf="!openAccordion[tier] && !selectedPortfolio" class="accrd-header-subText">Expand to display additional funds.</div>
            </div>
            <span class="icon-container">
              <i class="fa arrow-button" [ngClass]="{'fa-chevron-down' : !openAccordion[tier] , 'fa-chevron-up' : openAccordion[tier]}" tabindex="0"></i>
            </span>
          </div>

          <div *ngIf="openAccordion[tier]" class="accordion-content">
            <ng-container class="form-control-parent" *ngFor="let data2 of vulOneFundAlloData; let ind = index;">
              <!-- Funds table -->
              <div class="funds-table funds-table-header" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-header'">
                <div class="table-column col-1">{{data2.questionText | uppercase}} </div>
                <div class="table-column col-2">INPUT %</div>
              </div>
              <div class="funds-table funds-table-subheader" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-subheader'">
                <div class="table-column">{{data2.questionText | uppercase}} </div>
              </div>
              <div class="funds-table funds-table-subheader fund-table-label" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-label'">
                <div class="table-column">{{data2.questionText}} </div>
              </div>
              <div class="funds-table funds-table-row" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.TEXT && data2.notes && data2.notes.indexOf('FundSelect') > -1">
                <div class="table-column col-1">{{data2.questionText}} </div>
                <div class="table-column col-2">
                  <label for="{{data2.fieldId}}" id="fund-field-label-{{data2.fieldId}}">
                    <span class="visually-hidden">Label for the fund input</span>
                    <input lfgInput name="{{ data2.questionText }}" aria-live="off" id="{{data2.fieldId}}" type='text' formControlName='{{ data2.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data2.required == 'true'" (input)="fundUpdated()">
                  </label>
                </div>
              </div>
            </ng-container>

            <div class="total-percent percent-bottom">
              <span>TOTAL: <span><strong>{{getTotalForFunds(vulOneFundAlloData)}}%</strong></span></span>
            </div>

          </div>
        </ng-container>

        <div class="total-percent percent-bottom">
          <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
        </div>

      </form>
    </section>
    <lfg-alert-message *ngIf="totalFundPercentage != 100 && totalFundPercentage != 0 && isfundsValid" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
    <lfg-alert-message *ngIf="!isfundsValid" type="error">{{ErrMessage.VUL_FUND_ERROR}}</lfg-alert-message>
  </div>
</section>
