import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridInstance, Question } from 'src/app/shared/models/casePage.model';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { attestValidator, equalAnswerValidator } from 'src/app/shared/validators/business-validator';
import { ApplicationConfig } from 'src/config/app.config';
import { AgentInfoContConstant, CCBRQuestionsTags, ErrorMessage } from 'src/config/constants';
import { ProductCodes, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-agent-info-cont',
  templateUrl: './agent-info-cont.component.html',
  styleUrls: ['./agent-info-cont.component.scss']
})
export class AgentInfoContComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  formValid = true;
  alldropDownOptions = {};
  agentInfoContForm: FormGroup;
  agentInfoContData: Question[] = [];
  gridInstance: GridInstance[];
  gridAnswers: any[];

  gridHasNoErrors = false;
  formHasNoErrors = true;
  isGridFormValid = true;
  isGridFormChange = false;
  isAgentFormValid = false;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  AgentContConst = AgentInfoContConstant;

  showCaseAttestRadioButton = false;
  isAllStateAgent = false;
  showChildRiderInfo = false;

  maxNoOfBusiness: number;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private agentService: AgentAndFirmService,
    private userService: UserDetailsService,
    private productService: ProductDetailsService) {
  }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.filterQuestionData();
      this.buildFormData();
      this.isAllStateAgent = this.agentService.agentProfile?.allStateFlag;
      this.checkToShowAttestRadioButton();
      this.updateInterviewType();
      this.checkIfSVRRiderSelected();
      this.checkIfReasonSelectedErrorExists();
      this.updateQuestionRelatedToPurposeOfInsurance();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.agentInfoContForm);
      this.defaultPageLoadService.disableFormIfLocked(this.agentInfoContForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  buildFormData(): void {
    this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
    this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
    this.maxNoOfBusiness = +(this.gridInstance[0]?.maxGridIndex);
    this.agentInfoContData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.agentInfoContData
    );
    const form = this.formgeneratorService.createFormControls(
      this.agentInfoContData
    );
    this.agentInfoContForm = this.fb.group(form);
    this.updateBusinessValidators();
    this.updateBusinessValidatorsForProposedB();
    this.valueChanges();
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.agentInfoContForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.agentInfoContForm,
      data: null,
    };
    for (const data of this.agentInfoContData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  emitStatus(): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    this.isAgentFormValid = this.formValid && ((this.gridAnswers?.length > 0 && this.isGridFormValid) || this.gridAnswers?.length === 0);
    const agentInfoObj = {
      formValid: this.isAgentFormValid,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'agentInfoContForm',
      gridForm: true,
      isFormChange: this.agentInfoContForm.dirty || this.isGridFormChange || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.agentInfoContForm),
    };
    this.formStatus.emit(agentInfoObj);
  }

  handleAgentSelectionChange(data: Question, isPurposeOfInsuranceDropDown?: boolean): any {
    if (isPurposeOfInsuranceDropDown) {
      this.updateQuestionRelatedToPurposeOfInsurance();
    }
    this.updatedAgentAnswersOnHidden(data);
  }

  updatedAgentAnswersOnHidden(data): any {
    const value = this.agentInfoContForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.agentInfoContForm.get(key)?.markAsUntouched();
      }
      this.agentInfoContForm.patchValue(updatedval);
    }
  }

  addAgentContCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  gridformStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event?.formHasNoErrors;
    this.gridAnswers = event?.gridAnswers;
    this.isGridFormChange = event?.isGridFormChange;
    this.emitStatus();
  }

  onAgentContRadioChange(data): void {
    this.agentInfoContForm.get(data.fieldId)?.markAsTouched();
    this.updatedAgentAnswersOnHidden(data);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.agentInfoContForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  onCheckboxChange(data): void {
    this.updatedAgentAnswersOnHidden(data);
  }

  nestedQuestionAgentContCheck(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes{47}Yes') {
        return parent === true;
      } else if (childData.optionChoice === 'No{47}No') {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  checkToShowAttestRadioButton(data?: Question): void {
    let doYouAttestQuesId = AgentInfoContConstant.caseManagerDoYouAttestQuesId;
    let caseManagerOrOfficStaffEmailId = AgentInfoContConstant.caseManagerEmailId;
    if (this.isAllStateAgent) {
      doYouAttestQuesId = AgentInfoContConstant.officeStaffDoYouAttestQuesId;
      caseManagerOrOfficStaffEmailId = AgentInfoContConstant.officeStaffEmailId;
    }
    const primaryAgentEmailIdSame =
      this.agentService.getAgentPrimaryEmail()?.toLowerCase() === this.agentInfoContForm
        .get(this.getFieldIdByXmlTag(caseManagerOrOfficStaffEmailId))?.value?.toLowerCase();
    if (!data || (data.xmlTag === caseManagerOrOfficStaffEmailId)) {
      if (primaryAgentEmailIdSame) {
        this.updateRequiredField(doYouAttestQuesId, 'true', false, true);
        this.showCaseAttestRadioButton = true;
      } else {
        this.updateRequiredField(doYouAttestQuesId, 'false', false, false);
        this.showCaseAttestRadioButton = false;
        this.agentInfoContForm.get(this.getFieldIdByXmlTag(doYouAttestQuesId))?.reset();
      }
    }
  }

  private updateInterviewType(): void {
    const eInterviewFieldId = this.getFieldIdByXmlTag(AgentInfoContConstant.eInterviewQuesId);
    if (this.userService.getIsChildRiderSelected() || this.isSurvivorshipProduct()) {
      this.agentInfoContForm.get(eInterviewFieldId)?.setValue('Tele-Interview{47}Tele-Interview');
      this.agentInfoContForm.get(eInterviewFieldId)?.disable();
    } else {
      this.agentInfoContForm.get(eInterviewFieldId)?.enable();
    }
    this.showChildRiderInfo = this.userService.getIsChildRiderSelected();
  }

  private checkIfSVRRiderSelected(): void {
    const selectedProduct = this.productService.getProductName()?.toLowerCase();
    if ((selectedProduct === ProductCodes.ASSETEDGEEXEC2020 && !this.userService.getExecEnhanceSurrenderValueRider()) ||
      ((selectedProduct === ProductCodes.ASSETEDGEVUL2022 || selectedProduct === ProductCodes.ASSETEDGEVUL2025)
        && !this.userService.getIndividualEnhanceRiderSeleted() && !this.userService.getBusinessEnhanceRiderSeleted()) ||
      ((selectedProduct !== ProductCodes.ASSETEDGEEXEC2020 && selectedProduct !== ProductCodes.ASSETEDGEVUL2022 &&
        selectedProduct !== ProductCodes.ASSETEDGEVUL2025) && !this.userService.getEnhanceSurrenderValueRider())) {
      this.updateRequiredField(AgentInfoContConstant.commissionSchedule, 'true', true, true);
    } else {
      this.updateRequiredField(AgentInfoContConstant.commissionSchedule, 'false', true, false);
    }

    if ((selectedProduct === ProductCodes.ASSETEDGEEXEC2020 && this.userService.getExecEnhanceSurrenderValueRider()) ||
      ((selectedProduct === ProductCodes.ASSETEDGEVUL2022 || selectedProduct === ProductCodes.ASSETEDGEVUL2025)
        && (this.userService.getIndividualEnhanceRiderSeleted() || this.userService.getBusinessEnhanceRiderSeleted()))
      || ((selectedProduct !== ProductCodes.ASSETEDGEEXEC2020 && selectedProduct !== ProductCodes.ASSETEDGEVUL2022 &&
        selectedProduct !== ProductCodes.ASSETEDGEVUL2025) && this.userService.getEnhanceSurrenderValueRider())) {
      this.updateRequiredField(AgentInfoContConstant.commisionOnRider, 'false', true, true);
    } else {
      this.updateRequiredField(AgentInfoContConstant.commisionOnRider, 'false', true, false);
    }
    this.updateDropdownValueIfAssetEdge2025(selectedProduct);
  }

  private updateDropdownValueIfAssetEdge2025(selectedProduct: string): void {
    if (selectedProduct === ProductCodes.ASSETEDGEVUL2025) {
      const commissionOnRiderField = this.getFieldIdByXmlTag(AgentInfoContConstant.commisionOnRider);
      const commissionScheduleField = this.getFieldIdByXmlTag(AgentInfoContConstant.commissionSchedule);
      this.checkAndUpdateValue(commissionOnRiderField, this.getDropDownValue(AgentInfoContConstant.commisionOnRider, 'Level'));
      this.checkAndUpdateValue(commissionScheduleField, this.getDropDownValue(AgentInfoContConstant.commissionSchedule, 'A - Heaped'));
    }
  }

  private checkAndUpdateValue(fieldId: string, value: any): void {
    if (this.agentInfoContForm.get(fieldId) && !this.agentInfoContForm.get(fieldId)?.value) {
      this.agentInfoContForm.get(fieldId)?.setValue({ checked: false, label: value?.description, value: value?.value });
    }
  }

  private updateRequiredField(xmlTag: string, isRequired: string, updateDisplay: boolean, display: boolean): void {
    this.agentInfoContData.forEach(question => {
      if (question.xmlTag === xmlTag) {
        question.required = isRequired;
        if (updateDisplay) {
          question.display = display;
        }
        if (!question.display) {
          question.question_answer = '';
        }
      }
      // update isattest Ans
      this.updateDoYouAttestValue(question, xmlTag, display);
    });
  }

  private updateDoYouAttestValue(question: Question, xmlTag: string, display: boolean): void {
    if (question.xmlTag === AgentInfoContConstant.caseManagerDoYouAttestQuesId &&
      xmlTag === AgentInfoContConstant.caseManagerDoYouAttestQuesId) {
      if (display) {
        this.agentInfoContForm.get(question.fieldId)?.setValue(this.userService.getIsAttestedForSameEmail());
        this.agentInfoContForm.get(question.fieldId)?.setValidators([attestValidator(), Validators.required]);
      } else {
        this.agentInfoContForm.get(question.fieldId)?.reset();
        this.agentInfoContForm.get(question.fieldId)?.setValidators([]);
      }
      this.agentInfoContForm.get(question.fieldId)?.updateValueAndValidity();
    }
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private filterQuestionData(): void {
    if (!this.userService.getIsCCABRiderSelected()) {
      this.filterQuestionIfCCABRiderNotSelected(false);
    } else {
      this.filterQuestionIfCCABRiderNotSelected(true);
    }
  }

  private filterQuestionIfCCABRiderNotSelected(display: boolean): void {
    this.questionsData.pages[0]?.questions.forEach(ques => {
      if (CCBRQuestionsTags.includes(ques.xmlTag) || CCBRQuestionsTags.includes(ques.fieldId)) {
        ques.display = display;
        if (!ques.display) {
          ques.question_answer = '';
        }
      }
    });
  }

  checkIfReasonSelectedErrorExists(): void {
    if (!this.userService.getIsChildRiderSelected()) {
      this.updateRequiredFieldForCheckBoxes('false');
    } else {
      let reasonSelected = false;
      for (const tag of CCBRQuestionsTags) {
        if (this.agentInfoContForm.get(this.getFieldIdByXmlTag(tag))?.value) {
          reasonSelected = true;
          break;
        }
      }
      reasonSelected ? this.updateRequiredFieldForCheckBoxes('false') : this.updateRequiredFieldForCheckBoxes('true');
    }
  }

  private updateRequiredFieldForCheckBoxes(isRequired: string): void {
    this.questionsData.pages[0]?.questions.forEach(ques => {
      if (CCBRQuestionsTags.includes(ques.xmlTag)) {
        ques.required = isRequired;
      }
    });
  }

  private updateQuestionRelatedToPurposeOfInsurance(): void {
    const insPurposeMA = this.agentInfoContForm.get(this.getFieldIdByXmlTag(AgentInfoContConstant.purposeOfInsuranceMA))?.value?.value;
    const insPurposeNonMA =
      this.agentInfoContForm.get(this.getFieldIdByXmlTag(AgentInfoContConstant.purposeOfInsuranceNonMA))?.value?.value;
    const quesList = this.questionsData.pages[0]?.questions;
    if (insPurposeMA) {
      if (insPurposeMA.indexOf('Key Person') !== -1 || insPurposeMA.indexOf('Buy/Sell') !== -1) {
        this.updateQuestionBasedOnDropDownSelection(true, quesList, false);
      } else {
        this.updateQuestionBasedOnDropDownSelection(false, quesList, false);
      }
    } else if (insPurposeNonMA) {
      if (insPurposeNonMA.indexOf('Keyperson') !== -1 || insPurposeNonMA.indexOf('Buy-sell') !== -1) {
        this.updateQuestionBasedOnDropDownSelection(true, quesList, false);
      } else {
        this.updateQuestionBasedOnDropDownSelection(false, quesList, false);
      }
    } else {
      this.updateQuestionBasedOnDropDownSelection(false, quesList, false);
    }
  }

  private updateQuestionBasedOnDropDownSelection(addQuestion: boolean, questionList: any[], isReflexive: boolean): void {
    questionList.forEach(ques => {
      if ((ques.notes && ques.notes.indexOf('PurposeOfInsurance') !== -1) || isReflexive) {
        ques.required = addQuestion ? 'true' : 'false';
        ques.display = addQuestion;
        if (!addQuestion) {
          this.agentInfoContForm.get(ques.fieldId)?.reset();
          this.gridAnswers = [];
        }
        if (ques.hasReflexive === 'true') {
          this.updateQuestionBasedOnDropDownSelection(addQuestion, ques.reflexiveQuestionAL, true);
        }
      }
    });
  }

  private updateBusinessValidators(): void {
    const existingInsFieldId = this.getFieldIdByXmlTag(AgentInfoContConstant.existingPolicyQues);
    const reasonToBelieveFieldId = this.getFieldIdByXmlTag(AgentInfoContConstant.reasonToBelieveQues);
    this.agentInfoContForm.get(existingInsFieldId)?.setValidators(
      [Validators.required, equalAnswerValidator(this.userService.getHasExistingInsurance())]);
    this.agentInfoContForm.get(reasonToBelieveFieldId)?.setValidators(
      [Validators.required, equalAnswerValidator(this.userService.getConsideringAnyDiscontinuation())]);
    this.markAsTouchedIfValueAvailable(existingInsFieldId);
    this.markAsTouchedIfValueAvailable(reasonToBelieveFieldId);
  }

  private updateBusinessValidatorsForProposedB(): void {
    const existingInsFieldId = this.getFieldIdByXmlTag(AgentInfoContConstant.existingPolicyProposedB);
    const reasonToBelieveFieldId = this.getFieldIdByXmlTag(AgentInfoContConstant.replacementInfo);
    this.agentInfoContForm.get(existingInsFieldId)?.setValidators(
      [Validators.required, equalAnswerValidator(this.userService.getHasExistingInsuranceProposedB())]);
    this.agentInfoContForm.get(reasonToBelieveFieldId)?.setValidators(
      [Validators.required, equalAnswerValidator(this.userService.getConsideringAnyDiscontinuationProposedB())]);
    this.markAsTouchedIfValueAvailable(existingInsFieldId);
    this.markAsTouchedIfValueAvailable(reasonToBelieveFieldId);
  }

  // this logic is added only for 4 fields related to existing insurance
  private markAsTouchedIfValueAvailable(fieldId: string): void {
    if (this.agentInfoContForm.get(fieldId)?.value) {
      this.agentInfoContForm.get(fieldId).markAsTouched();
    }
  }

  private isSurvivorshipProduct(): boolean {
    const productSelected = this.productService.getProductName().toLowerCase();
    return productSelected === ProductCodes.SVULONE2021 || productSelected === ProductCodes.WEALTHPRESERVESIUL2022;
  }

  private getDropDownValue(xmlTag: string, value: string): any {
    const ques = this.agentInfoContData.filter(question => {
      return question.xmlTag === xmlTag;
    })[0];
    return ques?.questionOption?.filter(option => {
      return option.value?.indexOf(value) > -1;
    })[0];
  }
}
