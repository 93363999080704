<section class="validate-lock-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color"> Validate and Lock </h1>
  </div>

  <div class="loader-container" *ngIf="showLoader">
    <lfg-loader></lfg-loader>
  </div>

  <ng-container *ngIf="displayProgressModal">
    <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="CONST.FORM_LOADER_MESSAGE" [progressPercentArray]="progressPercentArray" [showActionButton]="true" (openPdfForm)="openPdfForm($event)" [formAvailable]="formAvailable" (closeProgressModal)="closeProgressModal($event)"></app-progress-overlay>
  </ng-container>

  <lfg-alert-message *ngIf="errorOccurred" type="error">{{errorMessage}}</lfg-alert-message>

  <div class="validate-lock-section">

    <!-- show when all tab is valid and not locked -->
    <span *ngIf="isAllTabsValid && !isAppLocked">
      <div class="lock-screen-success-icon"></div>
      <h2 class="validate-lock-heading valid-heading">{{MESSAGES.VALID_TABS_SCREEN_HEADING}}</h2>
      <div class="intro-text" [innerHTML]="MESSAGES.LOCK_SCREEN_MESSAGE"></div>
    </span>

    <!-- show when all tab is valid and locked -->
    <span *ngIf="isAllTabsValid && isAppLocked">
      <i class="fa fa-solid fa-lock status-icon" aria-hidden="true"></i>
      <h2 class="validate-lock-heading valid-heading">{{MESSAGES.UNLOCK_SCREEN_HEADING}}</h2>
      <p class="intro-text" [innerHTML]="MESSAGES.UNLOCK_SCREEN_MESSAGE"></p>

      <div class="unlock-button">
        <button type="button" lfgDefaultButton (click)="openModal($event)" [disabled]="disableForm || !userAccessDetails.caseRelatedAccess.unlockCase">
          <span><i class="fa fa-solid fa-unlock button-icon-left" aria-hidden="true"></i></span>
          UNLOCK APPLICATION DATA
        </button>
      </div>
      <div class="unlock-message">{{MESSAGES.UNLOCK_MESSAGE}}</div>
    </span>

    <!-- show when all tabs are not valid -->
    <span *ngIf="!isAllTabsValid">
      <i class="fa fa-exclamation-triangle status-icon big-warn-icon" aria-hidden="true"></i>
      <h2 class="validate-lock-heading">{{MESSAGES.INVALID_TABS_SCREEN_HEADING}}</h2>
      <p class="intro-text" [innerHTML]="MESSAGES.LOCK_SCREEN_MESSAGE"></p>
    </span>

  </div>

  <!-- show warning message if tabs are not valid -->
  <div class="warning--message" *ngIf="!isAllTabsValid">
    <lfg-alert-message type="warning">{{MESSAGES.ERROR_SCREEN_WARNING_MESSAGE}}</lfg-alert-message>
  </div>

  <!-- Agent checkbox to confirm delegation -->
  <ng-container *ngIf="isAppLocked && isAllTabsValid && userAccessDetails?.caseRelatedAccess?.showAgentConfCheckbox">
    <div class="questions--container">
      <h1 class="lfg-color">Agent On Behalf Of Attestation:</h1>
    </div>

    <form class="form-group" [formGroup]="validateLockForm" id="validateLockForm" method="post" *ngIf="validateLockPageData && validateLockPageData.length > 0">
      <ng-container class="form-control-parent" *ngFor="let data of validateLockPageData; let ind = index;">
        <!-- Form element for check box  -->
        <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
          <div class="lfg-checkbox validate-lock-checkbox">
            <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'">
            <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
          </div>
        </div>

      </ng-container>
    </form>
  </ng-container>

  <div class="button-container btn-cntr">

    <!-- lock button for locking the case -->
    <div *ngIf="!isAppLocked || !isAllTabsValid ">
      <button type="button" lfgDefaultButton (click)="lockAllTabs()" [disabled]="!isAllTabsValid || !userAccessDetails?.editCase">
        <span><i class="fa fa-solid fa-lock button-icon-left" aria-hidden="true"></i></span>LOCK APPLICATION
      </button>
    </div>

    <!-- Proceed to Signing process button -->
    <div *ngIf="isAppLocked && isAllTabsValid">
      <button class="btn" type="button" class="btn btn--whitebg-border review-form-btn" id="reviewForm" tabindex="0" aria-label="Review Forms" (click)="reviewForm();">REVIEW FORMS</button>
      <button type="button" lfgDefaultButton (click)="proceedToSign()" [disabled]="!formValid || disableForm || userAccessDetails?.readOnlyPostLock"> PROCEED TO SIGNING PROCESS
        <span><i class="fa fa-light fa-chevron-right button-icon-right" aria-hidden="true"></i></span>
      </button>
    </div>
  </div>

  <!-- show notes when tab is not locked -->
  <div class='notes-container'>
    <ng-container *ngIf="isAllTabsValid && !isAppLocked">
      <div [innerHTML]="MESSAGES.LOCK_SCREEN_NOTES"></div>
    </ng-container>
  </div>

  <!-- Delegate info msg -->
  <ng-container *ngIf="isAppLocked && isAllTabsValid && userAccessDetails.caseRelatedAccess.onBehalfOfMessage">
    <lfg-alert-message type="info">{{MESSAGES.DELEGATE_INFO}}</lfg-alert-message>
  </ng-container>

  <lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeModalPopup(modalConfig)">
    <div modal-header>
      <h2 class="lfg-color">Unlock application</h2>
    </div>
    <div modal-body>
      <p class="popup-message">
        {{MESSAGES.UNLOCK_ARE_YOU_SURE_MSG}}
      </p>
      <div class="strong-text"><strong>{{MESSAGES.ARE_YOU_SURE}}</strong></div>
    </div>
    <div modal-footer>
      <lfg-left-button-tray>
        <button class='btn' lfgDefaultButton (click)="unlockAllTabs()">YES, UNLOCK THE CASE</button>
        <button class='btn' lfgBorderButton (click)="closeModalPopup(modalConfig)">NO, DON'T UNLOCK THE CASE</button>
      </lfg-left-button-tray>
    </div>
  </lfg-modal-popup>
</section>