import { Injectable } from '@angular/core';
import { UserDetailsService } from './user-details.service';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { AgentAndFirmService } from './agent-and-firm.service';
import { ProductDetailsService } from './product-details.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateCaseService {

  constructor(
    private userService: UserDetailsService,
    private agentService: AgentAndFirmService,
    private productService: ProductDetailsService
  ) { }

  getSavedData(): any {
    const savedDataMap = {};
    savedDataMap[PAGE_GROUP_NAME.CASE_INFO_PAGE] = this.getCaseInfoData();
    savedDataMap[PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE] = this.getPrimaryInsuredInfoData();
    savedDataMap[PAGE_GROUP_NAME.RIDERS_INFO_PAGE] = this.getRiderInfoData();
    savedDataMap[PAGE_GROUP_NAME.AGENT_INFORMATION] = this.getAgentInfoData();
    savedDataMap[PAGE_GROUP_NAME.AGENT_INFO_CONTINUED] = this.getAgentInfoContData();
    savedDataMap[PAGE_GROUP_NAME.EXISTING_INSURANCE] = this.getExistingInsuranceData();
    savedDataMap[PAGE_GROUP_NAME.EXISTING_INSURANCE_FOR_PROPOSED_B] = this.getExistingInsuranceDataB();
    savedDataMap[PAGE_GROUP_NAME.FUND_ALLOCATION] = this.getFundAllocationData();
    savedDataMap[PAGE_GROUP_NAME.OWNER_INFO_PAGE] = this.getOwnerInfoData();
    savedDataMap[PAGE_GROUP_NAME.COVERAGE_INFO_PAGE] = this.getCoverageInfoData();
    savedDataMap[PAGE_GROUP_NAME.ELECTRONIC_FUND_TRANSFER] = this.getEftInfoData();
    savedDataMap[PAGE_GROUP_NAME.TRUST_INFO_PAGE] = this.getTrustInfoData();
    savedDataMap[PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE] = this.getTIAInfoData();
    return savedDataMap;
  }

  setSavedData(savedDataMap: any): void {
    this.setCaseInfoData(savedDataMap[PAGE_GROUP_NAME.CASE_INFO_PAGE]);
    this.setPrimaryInsuredInfoData(savedDataMap[PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE]);
    this.setRiderInfoData(savedDataMap[PAGE_GROUP_NAME.RIDERS_INFO_PAGE]);
    this.setAgentInfoData(savedDataMap[PAGE_GROUP_NAME.AGENT_INFORMATION]);
    this.setAgentInfoContData(savedDataMap[PAGE_GROUP_NAME.AGENT_INFO_CONTINUED]);
    this.setExistingInsuranceData(savedDataMap[PAGE_GROUP_NAME.EXISTING_INSURANCE]);
    this.setExistingInsuranceDataB(savedDataMap[PAGE_GROUP_NAME.EXISTING_INSURANCE_FOR_PROPOSED_B]);
    this.setFundAllocationData(savedDataMap[PAGE_GROUP_NAME.FUND_ALLOCATION]);
    this.setOwnerInfoData(savedDataMap[PAGE_GROUP_NAME.OWNER_INFO_PAGE]);
    this.setCoverageInfoData(savedDataMap[PAGE_GROUP_NAME.COVERAGE_INFO_PAGE]);
    this.setEftInfoData(savedDataMap[PAGE_GROUP_NAME.ELECTRONIC_FUND_TRANSFER]);
    this.setTrustInfoData(savedDataMap[PAGE_GROUP_NAME.TRUST_INFO_PAGE]);
    this.setTIAInfoData(savedDataMap[PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE]);
  }

  private setCaseInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (convDataMap) {
      this.userService.setInsuredName(convDataMap.get('firstName'), convDataMap.get('middleName'),
        convDataMap.get('lastName'), convDataMap.get('suffix'));
      this.userService.setInsuredDOBInfo(convDataMap.get('dob'), convDataMap.get('age'));
      this.userService.setInsuredGender(convDataMap.get('gender'));
      this.userService.setInsuredState(convDataMap.get('state'));
    }
  }

  /* tslint:disable:no-string-literal */
  private setPrimaryInsuredInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      const insuredAddress = convDataMap.get('insuredAddress');
      if (insuredAddress) {
        this.userService.setInsuredAddress(insuredAddress['address1'], insuredAddress['address2'], insuredAddress['address3'],
          insuredAddress['city'], insuredAddress['state'], insuredAddress['country'], insuredAddress['zipCode']);
      }
      this.userService.setInsuredAddressType(convDataMap.get('insuredAddressType'));
      this.userService.setInsuredPhoneNo(convDataMap.get('insuredPhoneNumber'));
      this.userService.setInsuredPhoneNoType(convDataMap.get('insuredPhoneNumberType'));
      this.userService.setInsuredEmail(convDataMap.get('insuredEmail'));
    }
  }

  private setRiderInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setIsChildRiderSelected(convDataMap.get('childRider'));
      this.userService.setIsCCABRiderSelected(convDataMap.get('CCABRider'));
      this.userService.setBusinessEnhanceRiderSeleted(convDataMap.get('businessEnhRider'));
      this.userService.setIndividualEnhanceRiderSeleted(convDataMap.get('individualEnhRider'));
      this.userService.setEnhanceSurrenderValueRider(convDataMap.get('enhanceSVRider'));
      this.userService.setExecEnhanceSurrenderValueRider(convDataMap.get('execEnhanceSVRider'));
    }
  }

  private setAgentInfoContData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setIsTeleAppSelected(convDataMap.get('isTeleAppSelected'));
      this.agentService.setCaseManagerEmail(convDataMap.get('caseManagerEmail'));
    }
  }

  private setAgentInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.agentService.setAgentFirstName(convDataMap.get('agentFirstName'));
      this.agentService.setAgentLastName(convDataMap.get('agentLastName'));
      this.agentService.setAgentPrimaryEmail(convDataMap.get('agentEmail'));
      this.agentService.setAgentLast4DigitSSN(convDataMap.get('last4Digit'), true);
      this.userService.setIsAttestedForSameEmail(convDataMap.get('isEmailAttested'));
    }
  }

  private setOwnerInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setSelectedOwners(convDataMap.get('selectedOwner'));
      this.userService.setOwnerDetails(convDataMap.get('ownerDetails'), convDataMap.get('ownerDetailsWithAdd'));
    }
  }

  private setFundAllocationData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setFixedAccountAndGovtFund(convDataMap.get('fixedAccountFund'), convDataMap.get('govtFund'));
      this.userService.setIsFixedOrGovtFundUpdated(convDataMap.get('isFixedOrGovtFundUpdated'));
      this.userService.setSelectedFundList(convDataMap.get('selectedFundList'));
    }
  }

  private setExistingInsuranceData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setHasExistingInsurance(convDataMap.get('hasExistingInsurance'));
      this.userService.setConsideringAnyDiscontinuation(convDataMap.get('consideringAnyDiscontinuation'));
    }
  }

  private setExistingInsuranceDataB(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setProposedBName(convDataMap.get('proposedBName'));
      this.userService.setHasExistingInsuranceProposedB(convDataMap.get('hasExistingInsuranceB'));
      this.userService.setConsideringAnyDiscontinuationProposedB(convDataMap.get('consideringAnyDiscontinuationB'));
    }
  }

  private setCoverageInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setFaceAmount(convDataMap.get('faceAmount'));
      this.productService.setMarketName(convDataMap.get('marketName'));
      this.productService.setDuration(convDataMap.get('duration'));
    }
  }

  /* tslint:disable:no-string-literal */
  private setEftInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setSelectedAccHolder(convDataMap.get('selectedAccountHolder'));
      const eftDetails = convDataMap.get('eftDetails');
      if (eftDetails) {
        this.userService.setEFTBasicDetails(eftDetails['temporaryInsurancePaymentProvider'],
          eftDetails['firstName'], eftDetails['lastName']);
        this.userService.setEFTAddressDetails(eftDetails['address'], eftDetails['city'],
          eftDetails['state'], eftDetails['zipcode'], eftDetails['emailAddress'], eftDetails['primaryPhoneNumber'],
          eftDetails['cellPhoneNumber']);
        this.userService.setEFTBankDetails(eftDetails['bankOrCreditUnionName'], eftDetails['routingNumber'],
          eftDetails['accountNumber'], eftDetails['accountType']);
      }
      this.userService.setCheckPaymentProvider(convDataMap.get('checkPaymentProvider'));
      this.userService.setEFTPaymentProvider(convDataMap.get('eftPaymentProvider'));
    }
  }

  private setTIAInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setIsTIASubmitted(convDataMap.get('isTIASubmitted'));
    }
  }

  private setTrustInfoData(dataMap: any): void {
    const convDataMap = this.convertToMap(dataMap);
    if (this.convertToMap(convDataMap)) {
      this.userService.setTrustDetails(convDataMap.get('trustDetails'), convDataMap.get('trustDetailsWithAddress'));
    }
  }

  private getCaseInfoData(): any {
    const caseInfoDataMap = new Map<string, any>();
    caseInfoDataMap.set('firstName', this.userService.getInsuredFirstName());
    caseInfoDataMap.set('middleName', this.userService.getInsuredMiddleName());
    caseInfoDataMap.set('lastName', this.userService.getInsuredLastName());
    caseInfoDataMap.set('suffix', this.userService.getInsuredSuffix());
    caseInfoDataMap.set('dob', this.userService.getInsuredDOB());
    caseInfoDataMap.set('age', this.userService.getInsuredAge());
    caseInfoDataMap.set('state', this.userService.getInsuredState());
    caseInfoDataMap.set('gender', this.userService.getInsuredGender());
    return this.convertToArray(caseInfoDataMap);
  }

  private getPrimaryInsuredInfoData(): any {
    const insuredDataMap = new Map<string, any>();
    insuredDataMap.set('insuredAddress', this.userService.getInsuredAddress());
    insuredDataMap.set('insuredAddressType', this.userService.getInsuredAddressType());
    insuredDataMap.set('insuredPhoneNumber', this.userService.getInsuredPhoneNo());
    insuredDataMap.set('insuredPhoneNumberType', this.userService.getInsuredPhoneNoType());
    insuredDataMap.set('insuredEmail', this.userService.getInsuredEmail());
    return this.convertToArray(insuredDataMap);
  }

  private getRiderInfoData(): any {
    const riderDataMap = new Map<string, any>();
    riderDataMap.set('childRider', this.userService.getIsChildRiderSelected());
    riderDataMap.set('CCABRider', this.userService.getIsCCABRiderSelected());
    riderDataMap.set('businessEnhRider', this.userService.getBusinessEnhanceRiderSeleted());
    riderDataMap.set('individualEnhRider', this.userService.getIndividualEnhanceRiderSeleted());
    riderDataMap.set('enhanceSVRider', this.userService.getEnhanceSurrenderValueRider());
    riderDataMap.set('execEnhanceSVRider', this.userService.getExecEnhanceSurrenderValueRider());
    return this.convertToArray(riderDataMap);
  }

  private getAgentInfoContData(): any {
    const agentInfoContMap = new Map<string, any>();
    agentInfoContMap.set('isTeleAppSelected', this.userService.getIsTeleAppSelected());
    agentInfoContMap.set('caseManagerEmail', this.agentService.getCaseManagerEmail());
    return this.convertToArray(agentInfoContMap);
  }

  private getAgentInfoData(): any {
    const agentInfoMap = new Map<string, any>();
    agentInfoMap.set('agentFirstName', this.agentService.getAgentFirstName());
    agentInfoMap.set('agentLastName', this.agentService.getAgentLastName());
    agentInfoMap.set('agentEmail', this.agentService.getAgentPrimaryEmail());
    agentInfoMap.set('last4Digit', this.agentService.getAgentLast4DigitSSN());
    agentInfoMap.set('isEmailAttested', this.userService.getIsAttestedForSameEmail());
    return this.convertToArray(agentInfoMap);
  }

  private getOwnerInfoData(): any {
    const ownerInfoMap = new Map<string, any>();
    ownerInfoMap.set('selectedOwner', this.userService.getSelectedOwners());
    ownerInfoMap.set('ownerDetails', this.userService.getOwnerDetails());
    ownerInfoMap.set('ownerDetailsWithAdd', this.userService.getOwnerDetailsWithAddress());
    return this.convertToArray(ownerInfoMap);
  }

  private getFundAllocationData(): any {
    const fundAllocationDataMap = new Map<string, any>();
    fundAllocationDataMap.set('fixedAccountFund', this.userService.getFixedAccountFund());
    fundAllocationDataMap.set('govtFund', this.userService.getGovtFund());
    fundAllocationDataMap.set('isFixedOrGovtFundUpdated', this.userService.getIsFixedOrGovtFundUpdated());
    fundAllocationDataMap.set('selectedFundList', this.userService.getSelectedFundList());
    return this.convertToArray(fundAllocationDataMap);
  }

  private getExistingInsuranceData(): any {
    const existingInsDataMap = new Map<string, any>();
    existingInsDataMap.set('hasExistingInsurance', this.userService.getHasExistingInsurance());
    existingInsDataMap.set('consideringAnyDiscontinuation', this.userService.getConsideringAnyDiscontinuation());
    return this.convertToArray(existingInsDataMap);
  }

  private getExistingInsuranceDataB(): any {
    const existingInsDataMap = new Map<string, any>();
    existingInsDataMap.set('proposedBName', this.userService.getProposedBName());
    existingInsDataMap.set('hasExistingInsuranceB', this.userService.getHasExistingInsuranceProposedB());
    existingInsDataMap.set('consideringAnyDiscontinuationB', this.userService.getConsideringAnyDiscontinuationProposedB());
    return this.convertToArray(existingInsDataMap);
  }

  private getCoverageInfoData(): any {
    const coverageInfoDataMap = new Map<string, any>();
    coverageInfoDataMap.set('faceAmount', this.userService.getFaceAmount());
    coverageInfoDataMap.set('marketName', this.productService.getMarketName());
    coverageInfoDataMap.set('duration', this.productService.getDuration());
    return this.convertToArray(coverageInfoDataMap);
  }

  private getTIAInfoData(): any {
    const tiaInfoDataMap = new Map<string, any>();
    tiaInfoDataMap.set('isTIASubmitted', this.userService.getIsTIASubmitted());
    return this.convertToArray(tiaInfoDataMap);
  }

  private getEftInfoData(): any {
    const eftInfoDataMap = new Map<string, any>();
    eftInfoDataMap.set('selectedAccountHolder', this.userService.getSelectedAccHolder());
    eftInfoDataMap.set('eftDetails', this.userService.getEFTDetails());
    eftInfoDataMap.set('checkPaymentProvider', this.userService.getCheckPaymentProvider());
    eftInfoDataMap.set('eftPaymentProvider', this.userService.getEFTPaymentProvider());
    return this.convertToArray(eftInfoDataMap);
  }

  private getTrustInfoData(): any {
    const trustInfoDataMap = new Map<string, any>();
    trustInfoDataMap.set('trustDetails', this.userService.getTrustDetails());
    trustInfoDataMap.set('trustDetailsWithAddress', this.userService.getTrustDetailsWithAddress());
    return this.convertToArray(trustInfoDataMap);
  }

  private convertToArray(mapObject: Map<string, any>): any {
    const convMap = {};
    mapObject.forEach((val: any, key: string) => {
      convMap[key] = val;
    });
    return convMap;
  }

  private convertToMap(arrayObject: any): Map<string, any> {
    const convMap = new Map<string, any>();
    if (arrayObject) {
      Object.keys(arrayObject).forEach(obj => {
        convMap.set(obj, arrayObject[obj]);
      });
    }
    return convMap;
  }
}
