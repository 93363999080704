<section class="temporary-life-page page-content-container">
    <div class="questions--container">
        <h1 class="lfg-color">
            Temporary Life Insurance Agreement
        </h1>

        <lfg-alert-message *ngIf="!formValid && isTabVisited" type="error">{{errorMessage}}</lfg-alert-message>
        <section>
            <ng-container class="form-control-parent" *ngFor="let data of temporaryLifeInsuranceData; let ind = index;" >
                <form [formGroup]="temporaryLifeInsuranceForm" id="temporaryLifeInsuranceForm" *ngIf="temporaryLifeInsuranceForm && temporaryLifeInsuranceData.length > 0" method="post">

                  <!-- Form element for radio question -->
                  <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO"
                  [ngClass]="{'radio--err': (temporaryLifeInsuranceForm.get(data.fieldId).touched && temporaryLifeInsuranceForm.get(data.fieldId).invalid)}">
                    <span class="lfg-radio-section">
                      <p class="question-radio_text">{{ addCorrectPlaceHolder(data, 'question')  }}</p>
                      <div class="custom-radio-button radio-rect">
                        <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                        <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                        <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                        <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="temporaryLifeInsuranceForm.get(data.fieldId).touched && temporaryLifeInsuranceForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
                  </div>

                  <div class="section-divider radio-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO"></div>
                  <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'"></div>

                <!-- Nestion question Level 1 -->
                <div *ngIf="data.hasReflexive == 'true' && temporaryLifeInsuranceForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
                    <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
                        <ng-container *ngIf="nestedQuestionCheckPrimaryInsuredInfo(data, primaryChildL1, parentAnswer)">
                            <!-- Questions headers -->
                            <div class="questions-header alert-info-message" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.questionText !== 'Line'">
                            <lfg-alert-message type="error">{{ primaryChildL1.questionText }}</lfg-alert-message>
                            </div>
                            <div class="section-divider radio-divider" *ngIf="appConfig.fieldTypes.HDR && (primaryChildL1.subText== 'Alert' || primaryChildL1.subText == 'Information')"></div>

                              <!-- Form element for radio question -->
                            <div class="lfg-customRadio-field" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO"
                            [ngClass]="{'radio--err': (temporaryLifeInsuranceForm.get(primaryChildL1.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL1.fieldId).invalid)}">
                              <span class="lfg-radio-section">
                                <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL1, 'question')  }}</p>
                                <div class="custom-radio-button radio-rect">
                                    <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)"/>
                                    <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                                    <input [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                                    <label for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                                </div>
                              </span>
                              <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL1.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL1.fieldId).invalid " message="Please select a valid option."></lfg-error>
                            </div>
                            <div class="section-divider radio-divider" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO"></div>

                            <!-- Nestion question Level 2 -->
                            <div *ngIf="primaryChildL1.hasReflexive == 'true' && temporaryLifeInsuranceForm.get(primaryChildL1.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(primaryChildL1) }">
                                <ng-container *ngFor="let primaryChildL2 of primaryChildL1.reflexiveQuestionAL; index as primaryChildL2ix">
                                    <ng-container *ngIf="nestedQuestionCheckPrimaryInsuredInfo(primaryChildL1, primaryChildL2, parentAnswer)">
                                        <!-- Questions headers -->
                                        <div class="questions-header alert-info-message" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL2.questionText !== 'Line' && primaryChildL2.subText == 'Alert'">
                                        <lfg-alert-message type="error">{{ primaryChildL2.questionText }}</lfg-alert-message>
                                        </div>

                                        <!-- Question Info -->
                                        <div class="question-info alert-info-message" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL2.subText == 'Information'">
                                          <lfg-alert-message type="info">{{ primaryChildL2.questionText }}</lfg-alert-message>
                                        </div>
                                        <div class="section-divider radio-divider" *ngIf="appConfig.fieldTypes.HDR && (primaryChildL2.subText == 'Information' || primaryChildL2.subText == 'Alert')"></div>


                                        <!-- Form element for radio question -->
                                        <div class="lfg-customRadio-field" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                        [ngClass]="{'radio--err': (temporaryLifeInsuranceForm.get(primaryChildL2.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL2.fieldId).invalid)}">
                                          <span class="lfg-radio-section">
                                            <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL2, 'question')  }}</p>
                                            <div class="custom-radio-button radio-rect">
                                              <input [attr.aria-label]="primaryChildL2.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL2.fieldId }}" type="radio" formControlName="{{ primaryChildL2.fieldId }}" name="{{ primaryChildL2.fieldId }}" value="{{ primaryChildL2.questionOption[0].value }}" required="{{primaryChildL2.required}}" (change)="onRadioChange(primaryChildL2)"/>
                                              <label for="yes_{{ primaryChildL2.fieldId }}">{{ primaryChildL2.questionOption[0].description }}</label>
                                              <input [attr.aria-label]="primaryChildL2.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL2.fieldId }}" type="radio" formControlName="{{ primaryChildL2.fieldId }}" name="{{ primaryChildL2.fieldId }}" value="{{ primaryChildL2.questionOption[1].value }}" required="{{primaryChildL2.required}}" (change)="onRadioChange(primaryChildL2)">
                                              <label for="no_{{ primaryChildL2.fieldId }}">{{ primaryChildL2.questionOption[1].description }}</label>
                                            </div>
                                          </span>
                                          <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL2.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL2.fieldId).invalid " message="Please select a valid option."></lfg-error>
                                        </div>
                                        <div class="section-divider radio-divider" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.RADIO"></div>

                                        <!-- Nestion question Level 3 -->
                                        <div *ngIf="primaryChildL2.hasReflexive == 'true' && temporaryLifeInsuranceForm.get(primaryChildL2.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(primaryChildL2) }">
                                            <ng-container *ngFor="let primaryChildL3 of primaryChildL2.reflexiveQuestionAL; index as primaryChildL3ix">
                                                <ng-container *ngIf="nestedQuestionCheckPrimaryInsuredInfo(primaryChildL2, primaryChildL3, parentAnswer)">
                                                    <!-- Questions headers -->
                                                    <div class="questions-header alert-info-message" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL3.questionText !== 'Line' && primaryChildL3.subText == 'Alert'">
                                                    <lfg-alert-message type="error">{{ primaryChildL3.questionText }}</lfg-alert-message>
                                                    </div>

                                                    <!-- Question Info -->
                                                    <div class="question-info alert-info-message" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL3.subText == 'Information'">
                                                      <lfg-alert-message type="info">{{ primaryChildL3.questionText }}</lfg-alert-message>
                                                    </div>

                                                    <div class="section-divider radio-divider" *ngIf="appConfig.fieldTypes.HDR && (primaryChildL3.subText == 'Information' || primaryChildL3.subText == 'Alert')"></div>

                                                    <!-- Form element for radio question -->
                                                    <div class="lfg-customRadio-field" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                                    [ngClass]="{'radio--err': (temporaryLifeInsuranceForm.get(primaryChildL3.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL3.fieldId).invalid)}">
                                                      <span class="lfg-radio-section">
                                                        <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL3, 'question')  }}</p>
                                                        <div class="custom-radio-button radio-rect">
                                                          <input [attr.aria-label]="primaryChildL3.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL3.fieldId }}" type="radio" formControlName="{{ primaryChildL3.fieldId }}" name="{{ primaryChildL3.fieldId }}" value="{{ primaryChildL3.questionOption[0].value }}" required="{{primaryChildL3.required}}" (change)="onRadioChange(primaryChildL3)"/>
                                                          <label for="yes_{{ primaryChildL3.fieldId }}">{{ primaryChildL3.questionOption[0].description }}</label>
                                                          <input [attr.aria-label]="primaryChildL3.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL3.fieldId }}" type="radio" formControlName="{{ primaryChildL3.fieldId }}" name="{{ primaryChildL3.fieldId }}" value="{{ primaryChildL3.questionOption[1].value }}" required="{{primaryChildL3.required}}" (change)="onRadioChange(primaryChildL3)">
                                                          <label for="no_{{ primaryChildL3.fieldId }}">{{ primaryChildL3.questionOption[1].description }}</label>
                                                        </div>
                                                      </span>
                                                      <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL3.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL3.fieldId).invalid " message="Please select a valid option."></lfg-error>
                                                    </div>
                                                    <div class="section-divider radio-divider" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.RADIO"></div>

                                                    <!-- Nestion question Level 4 -->
                                                    <div *ngIf="primaryChildL3.hasReflexive == 'true' && temporaryLifeInsuranceForm.get(primaryChildL3.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(primaryChildL3) }">
                                                        <ng-container *ngFor="let primaryChildL4 of primaryChildL3.reflexiveQuestionAL; index as primaryChildL4ix">
                                                            <ng-container *ngIf="nestedQuestionCheckPrimaryInsuredInfo(primaryChildL3, primaryChildL4, parentAnswer)">
                                                                <!-- Questions headers -->
                                                                <div class="questions-header alert-info-message" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL4.questionText !== 'Line' && primaryChildL4.subText == 'Alert'">
                                                                <lfg-alert-message type="error">{{ primaryChildL4.questionText }}</lfg-alert-message>
                                                                </div>

                                                                <!-- Question Info -->
                                                                <div class="question-info alert-info-message" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL4.subText == 'Information'">
                                                                    <lfg-alert-message type="info">{{ primaryChildL4.questionText }}</lfg-alert-message>
                                                                </div>

                                                                <div class="section-divider radio-divider" *ngIf="appConfig.fieldTypes.HDR && (primaryChildL4.subText == 'Information' || primaryChildL4.subText == 'Alert')"></div>

                                                                <!-- Form element for radio question -->
                                                                <div class="lfg-customRadio-field" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                                                [ngClass]="{'radio--err': (temporaryLifeInsuranceForm.get(primaryChildL4.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL4.fieldId).invalid)}">
                                                                  <span class="lfg-radio-section">
                                                                    <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL4, 'question')  }}</p>
                                                                    <div class="custom-radio-button radio-rect">
                                                                      <input [attr.aria-label]="primaryChildL4.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL4.fieldId }}" type="radio" formControlName="{{ primaryChildL4.fieldId }}" name="{{ primaryChildL4.fieldId }}" value="{{ primaryChildL4.questionOption[0].value }}" required="{{primaryChildL4.required}}" (change)="onRadioChange(primaryChildL4)"/>
                                                                      <label for="yes_{{ primaryChildL4.fieldId }}">{{ primaryChildL4.questionOption[0].description }}</label>
                                                                      <input [attr.aria-label]="primaryChildL4.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL4.fieldId }}" type="radio" formControlName="{{ primaryChildL4.fieldId }}" name="{{ primaryChildL4.fieldId }}" value="{{ primaryChildL4.questionOption[1].value }}" required="{{primaryChildL4.required}}" (change)="onRadioChange(primaryChildL4)">
                                                                      <label for="no_{{ primaryChildL4.fieldId }}">{{ primaryChildL4.questionOption[1].description }}</label>
                                                                    </div>
                                                                  </span>
                                                                  <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL4.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL4.fieldId).invalid " message="Please select a valid option."></lfg-error>
                                                                </div>
                                                                <div class="section-divider radio-divider" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.RADIO"></div>

                                                                <!-- Nested question Level 5 -->

                                                                <div class="nested4" *ngIf="primaryChildL4.hasReflexive == 'true' && temporaryLifeInsuranceForm.get(primaryChildL4.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(primaryChildL4) }">
                                                                    <ng-container *ngFor="let primaryChildL5 of primaryChildL4.reflexiveQuestionAL; index as primaryChildL5ix">
                                                                        <ng-container *ngIf="nestedQuestionCheckPrimaryInsuredInfo(primaryChildL4, primaryChildL5, parentAnswer)">
                                                                            <!-- Questions headers -->
                                                                            <div class="questions-header alert-info-message" *ngIf="primaryChildL5.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL5.questionText !== 'Line'
                                                                            && primaryChildL5.subText == 'Alert'">
                                                                            <lfg-alert-message type="error">{{ primaryChildL5.questionText }}</lfg-alert-message>
                                                                            </div>

                                                                            <!-- Question Info -->
                                                                            <div class="question-info alert-info-message" *ngIf="primaryChildL5.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL5.subText == 'Information'">
                                                                                <lfg-alert-message type="info">{{ primaryChildL5.questionText }}</lfg-alert-message>
                                                                            </div>

                                                                            <div class="section-divider radio-divider" *ngIf="appConfig.fieldTypes.HDR && (primaryChildL5.subText == 'Information' || primaryChildL5.subText == 'Alert')"></div>
                                                                            <!-- Form element for radio question -->
                                                                            <div class="lfg-customRadio-field" *ngIf="primaryChildL5.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                                                            [ngClass]="{'radio--err': (temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).invalid)}">
                                                                              <span class="lfg-radio-section">

                                                                                <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL5, 'question')  }}</p>
                                                                                <div class="custom-radio-button radio-rect">
                                                                                  <input [attr.aria-label]="primaryChildL5.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL5.fieldId }}" type="radio" formControlName="{{ primaryChildL5.fieldId }}" name="{{ primaryChildL5.fieldId }}" value="{{ primaryChildL5.questionOption[0].value }}" required="{{primaryChildL5.required}}" (change)="onRadioChange(primaryChildL5)"/>
                                                                                  <label for="yes_{{ primaryChildL5.fieldId }}">{{ primaryChildL5.questionOption[0].description }}</label>
                                                                                  <input [attr.aria-label]="primaryChildL5.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL5.fieldId }}" type="radio" formControlName="{{ primaryChildL5.fieldId }}" name="{{ primaryChildL5.fieldId }}" value="{{ primaryChildL5.questionOption[1].value }}" required="{{primaryChildL5.required}}" (change)="onRadioChange(primaryChildL5)">
                                                                                  <label for="no_{{ primaryChildL5.fieldId }}">{{ primaryChildL5.questionOption[1].description }}</label>
                                                                                </div>
                                                                              </span>
                                                                              <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).invalid " message="Please select a valid option."></lfg-error>
                                                                            </div>
                                                                            <div class="section-divider radio-divider" *ngIf="primaryChildL5.controlTypeDesc == appConfig.fieldTypes.RADIO"></div>

                                                                            <!-- Form element for Drop Down -->
                                                                            <div class="form--control form-dropDown" *ngIf="primaryChildL5.controlTypeDesc == appConfig.fieldTypes.SELECT">
                                                                                <lfg-dropdown formControlName="{{ primaryChildL5.fieldId }}" id="{{displayIdCreation(primaryChildL5)}}_{{primaryChildL5ix}}" [options]="alldropDownOptions[primaryChildL5.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL5, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL5, primaryChildL5ix); updateValuesInForm()" [required]="primaryChildL5.required == 'true'"
                                                                                [ngClass]="{'dropdown--err': (temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).invalid)}">
                                                                                </lfg-dropdown>

                                                                                <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).invalid"  message="{{ getErrorMsg(primaryChildL5) }}"></lfg-error>
                                                                            </div>

                                                                            <!-- Nested Level 6 -->
                                                                            <div *ngIf="primaryChildL5.hasReflexive == 'true' && temporaryLifeInsuranceForm.get(primaryChildL5.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(primaryChildL5) }">
                                                                                <ng-container *ngFor="let primaryChildL6 of primaryChildL5.reflexiveQuestionAL; index as primaryChildL6ix">
                                                                                    <ng-container *ngIf="nestedQuestionCheckPrimaryInsuredInfo(primaryChildL5, primaryChildL6, parentAnswer)">

                                                                                        <!-- Questions headers -->
                                                                                        <div class="questions-header" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL6.questionText != 'Line'
                                                                                        && primaryChildL6.subText != 'Information' && primaryChildL6.subText != 'Alert'">
                                                                                            <h2>{{ primaryChildL6.questionText }}</h2>
                                                                                        </div>

                                                                                        <!-- Question Info -->
                                                                                        <div class="question-info alert-info-message" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL6.subText == 'Information'">
                                                                                            <lfg-alert-message type="info">{{ primaryChildL6.questionText }}</lfg-alert-message>
                                                                                        </div>

                                                                                        <!-- Form element for email  -->
                                                                                        <div class="form--control" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                                                                          <lfg-input-wrap>
                                                                                            <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL6, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL6) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL6.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL6.required == 'true'">
                                                                                            <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid" message="{{ getErrorMsg(primaryChildL6) }}"></lfg-error>
                                                                                          </lfg-input-wrap>
                                                                                       </div>

                                                                                       <!-- Form Element for Inline Dropdown  -->
                                                                                       <div class="form--control " *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'inline-ques': primaryChildL6.subText == 'ITES_INLINE_FIELD'}">
                                                                                          <div class="inline-ques-container">
                                                                                            <span *ngIf="primaryChildL6.questionText.length > 27" class="inline-ques-text">{{ primaryChildL6.questionText }}</span>
                                                                                            <ng-container *ngIf="primaryChildL6.questionText.length > 27">
                                                                                              <div class="form-dropDown inline-dropdown">
                                                                                                <lfg-dropdown formControlName="{{ primaryChildL6.fieldId }}" id="{{displayIdCreation(primaryChildL6)}}_{{primaryChildL6ix}}" [options]="alldropDownOptions[primaryChildL6.fieldId]" (selectionChanged)="handleSelectionChange(primaryChildL6, primaryChildL6ix)" [required]="primaryChildL6.required == 'true'"
                                                                                                [ngClass]="{'dropdown--err': (temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid)}">
                                                                                                </lfg-dropdown>
                                                                                              </div>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="primaryChildL6.questionText.length < 27">
                                                                                              <lfg-dropdown formControlName="{{ primaryChildL6.fieldId }}" id="{{displayIdCreation(primaryChildL6)}}_{{primaryChildL6ix}}" [options]="alldropDownOptions[primaryChildL6.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL6, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL6, primaryChildL6ix)" [required]="primaryChildL6.required == 'true'"
                                                                                              [ngClass]="{'dropdown--err': (temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid)}">
                                                                                              </lfg-dropdown>
                                                                                            </ng-container>
                                                                                            <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid"  message="{{ getErrorMsg(primaryChildL6) }}"></lfg-error>
                                                                                          </div>
                                                                                      </div>

                                                                                       <!-- Form element for currency  -->
                                                                                      <div class="form--control" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL6.notes && primaryChildL6.notes.indexOf('ITES_CURRENCY_USD') > -1)" [ngClass]="{'inline-ques': primaryChildL6.questionText.length >= 25}">
                                                                                        <lfg-input-wrap>
                                                                                          <div class="inline-ques-container">
                                                                                            <span *ngIf="primaryChildL6.questionText.length > 27" class="inline-ques-text">{{ primaryChildL6.questionText }}</span>
                                                                                            <label for="{{primaryChildL6.fieldId}}" *ngIf="primaryChildL6.questionText.length >= 25">
                                                                                              <span class="visually-hidden">{{primaryChildL6.questionText}}</span>
                                                                                              <input lfgInput aria-live="off" [id]="primaryChildL6.fieldId" class="inline-ques-input" type='text' formControlName='{{ primaryChildL6.fieldId }}' autocomplete="off" name="{{displayTextLabel(primaryChildL6)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="primaryChildL6.required == 'true'">
                                                                                            </label>
                                                                                            <input *ngIf="primaryChildL6.questionText.length < 25" lfgInput aria-live="off" placeholder="{{addCorrectPlaceHolder(primaryChildL6, 'placeHolder')}}" type='text' formControlName='{{ primaryChildL6.fieldId }}' autocomplete="off" name="{{displayTextLabel(primaryChildL6)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='9' [required]="primaryChildL6.required == 'true'">
                                                                                          </div>
                                                                                          <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid" message="{{ getErrorMsg(primaryChildL6, temporaryLifeInsuranceForm.get(primaryChildL6.fieldId)) }}"></lfg-error>
                                                                                        </lfg-input-wrap>
                                                                                      </div>

                                                                                        <!-- Form element for radio question -->
                                                                                        <div class="lfg-customRadio-field" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                                                                        [ngClass]="{'radio--err': (temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid)}">
                                                                                          <span class="lfg-radio-section">
                                                                                            <p class="question-radio_text">{{ addCorrectPlaceHolder(primaryChildL6, 'question')  }}</p>
                                                                                            <div class="custom-radio-button radio-rect">
                                                                                              <input [attr.aria-label]="primaryChildL6.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL6.fieldId }}" type="radio" formControlName="{{ primaryChildL6.fieldId }}" name="{{ primaryChildL6.fieldId }}" value="{{ primaryChildL6.questionOption[0].value }}" required="{{primaryChildL6.required}}" (change)="onRadioChange(primaryChildL6)"/>
                                                                                              <label for="yes_{{ primaryChildL6.fieldId }}">{{ primaryChildL6.questionOption[0].description }}</label>
                                                                                              <input [attr.aria-label]="primaryChildL6.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL6.fieldId }}" type="radio" formControlName="{{ primaryChildL6.fieldId }}" name="{{ primaryChildL6.fieldId }}" value="{{ primaryChildL6.questionOption[1].value }}" required="{{primaryChildL6.required}}" (change)="onRadioChange(primaryChildL6)">
                                                                                              <label for="no_{{ primaryChildL6.fieldId }}">{{ primaryChildL6.questionOption[1].description }}</label>

                                                                                            </div>
                                                                                          </span>
                                                                                          <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid " message="Please select a valid option."></lfg-error>
                                                                                        </div>
                                                                                        <div class="section-divider radio-divider" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.RADIO"></div>
                                                                                         <!-- USA address -->
                                                                                        <div class="questions-header" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                                                                                            <h2>{{ primaryChildL6.questionText }}</h2>
                                                                                        </div>

                                                                                        <!-- Form element for text  -->
                                                                                        <div class="form--control" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.TEXT  && (!primaryChildL6.notes || primaryChildL6.notes.indexOf('ITES_CURRENCY_USD') == -1) && primaryChildL6.notes !== 'ITES_FORMAT_AS_ZIPCODE'">
                                                                                          <lfg-input-wrap>
                                                                                          <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL6, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL6) }}" aria-live="off" type='text' formControlName='{{ primaryChildL6.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(primaryChildL6.answerTextType)" [maxlength]="primaryChildL6.maxLength" [required]="primaryChildL6.required == 'true'">

                                                                                          <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid" message="Please enter a valid {{primaryChildL6.questionText | lowercase}}."></lfg-error>
                                                                                          </lfg-input-wrap>
                                                                                        </div>

                                                                                        <!-- Form element for zipCode  -->
                                                                                        <div class="form--control" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL6.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                                                                                          <lfg-input-wrap>
                                                                                          <input lfgInput mask='00000-0000' placeholder="{{ addCorrectPlaceHolder(primaryChildL6, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL6) }}" aria-live="off" type='text' formControlName='{{ primaryChildL6.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols customAttr="number" maxlength="10" [required]="primaryChildL6.required == 'true'">

                                                                                          <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid" message="Please enter a valid {{primaryChildL6.questionText | lowercase}}."></lfg-error>
                                                                                          </lfg-input-wrap>
                                                                                        </div>

                                                                                        <!-- Form element for phone number -->
                                                                                        <div class="form--control" *ngIf="primaryChildL6.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                                                                          <lfg-input-wrap>
                                                                                            <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL6, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL6.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL6.required == 'true'">
                                                                                            <lfg-error *ngIf="temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).touched && temporaryLifeInsuranceForm.get(primaryChildL6.fieldId).invalid" message="Please enter a valid {{primaryChildL6.questionText | lowercase}}."></lfg-error>
                                                                                         </lfg-input-wrap>
                                                                                        </div>


                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </div>
                                                                            <!-- End Nested Level 6-->

                                                                        </ng-container>
                                                                    </ng-container>
                                                                </div>

                                                                <!--End Nested 5-->

                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>

                                    </ng-container>
                                </ng-container>
                            </div>

                        </ng-container>
                    </ng-container>
                </div>


              </form>
            </ng-container>
        </section>
    </div>
</section>
