import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  // Base page variables to show save and exit message
  private isSavedAndExitSubj$ = new BehaviorSubject(null);


  constructor() {
    // write code here
  }

  setSaveConfirmationMsgFlag(flag: boolean): void {
    this.isSavedAndExitSubj$.next(flag);
  }

  getSaveConfirmationMsgFlag(): Observable<any> {
    return this.isSavedAndExitSubj$.asObservable();
  }
}
