// eTicket UI Application Confirguration Details will follow here
import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationConfig {
  readonly fieldTypes = {
    HDR: 'None',
    TEXT: 'Text',
    CURRENCY: 'Currency',
    SSN: 'SSN',
    RADIO: 'Radio Button',
    DOB: 'Date',
    CHECKBOX: 'Checkbox',
    SELECT: 'Drop-down',
    NONEDITABLE: 'Noneditable',
    SEARCH: 'Search',
    EMAIL: 'Email',
    ZIP: 'Zip',
    ALPHANUMERIC: 'Alphanumeric',
    PHONE: 'Phone',
    USAADDRESS: 'USA Address',
    GRID: 'Grid',
    TIN: 'Text - Formatted Box',
    YESORNO: 'Yes/No',
    TEXTAREA: 'Text Area',
    HEIGHT: 'Height',
  };

  readonly errorMessages = {
    ERROR_MSG: 'Please enter a valid input',
    COVERAGE_AMOUNT_ERROR: 'Amount is not within limits for coverage selected',
    MIN_AMOUNT_ERROR: 'Minimum amount is $1.00',
    BASIC_DOB_ERROR: 'Age is not within limits for coverage',
    TERM_AGE_ERROR: 'Age is not within limits for selected duration and rate class.',
    NON_TERM_AGE_ERROR: 'Age is not within limits for selected rate class.',
    DBO_ERROR: 'Increase by Premium is not allowed with selected Riders.',
    DOB_AND_DBQ_MESSAGE: 'The Death Benefit Option, Increase by Premium, is not allowed with the Death Benefit Qualification of CVAT.',
    STATE_ERROR: 'State must be same as contract state.',
    ALL_STATE_AGENT_ERROR_MESSAGE: 'The Allstate Agent ID must be between 5 and 10 characters.',
    I_ATTEST_ERROR_MESSAGE: 'The Agent and Case Manager emails can only be the same if you attest that you do not have a case manager by selecting the <span class="bold-font">I Attest</span> button.',
    KNOW_ENGLISH_ERROR_MESSAGE: 'Important: If the client does not read or speak English, an electronic ticket cannot be completed and submitted to Lincoln. Please work with your wholesaling team for an alternative solution.',
    I_ATTEST_ALL_STATE_ERROR_MESSAGE: 'The Agent and In Office Support/Staff emails can only be the same if you attest that you do not have an In Office Support/Staff by selecting the <span class="bold-font">I Attest</span> button.',
  };

  readonly customAttr = {
    Text: 'text',
    Currency: 'currency',
    Alphanumeric: 'alphaNumeric',
    Email: 'email',
    Numeric: 'numbersOnly',
  };

  readonly caseInfoDisableFieldKey = [
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
  ];

  readonly primaryInsuredInfoDisableFieldKey = [
    '/XML/AnswerSet/Party/PrimaryInsured/TempAge',
    '/XML/Client/CRMDetails/FirstName',
    '/XML/Client/CRMDetails/MiddleName',
    '/XML/Client/CRMDetails/LastName',
    '/XML/Client/CRMDetails/Suffix',
    '/XML/Client/CRMDetails/DOB',
    '/XML/Client/CRMDetails/Gender'
  ];

  readonly proposedInsuredBDisableFieldKey = [
    '/XML/AnswerSet/Party/PrimaryInsuredB/TempAge'
  ];

  readonly temporaryInsuredInfoDisableFieldKey = [
    '/XML/Client/CRMDetails/TempInsurancePaymentProvider',
    '/XML/Client/CRMDetails/EftAccountholderFirstName',
    '/XML/Client/CRMDetails/EftAccountholderLastName',
    '/XML/Client/CRMDetails/EftStreetAddress',
    '/XML/Client/CRMDetails/EftCityAddress',
    '/XML/Client/CRMDetails/EftStateAddress',
    '/XML/Client/CRMDetails/EftZipCode',
    '/XML/Client/CRMDetails/EftPayorEmailAddress',
    '/XML/Client/CRMDetails/EftPrimaryPhoneNumber',
    '/XML/Client/CRMDetails/EftCellPhoneNumber',
    '/XML/Client/Banking/EftBankUnionName',
    '/XML/Client/Banking/EftRoutingNumber',
    '/XML/Client/Banking/EftAccountNumber',
    '/XML/Client/Banking/EftAccountType'
  ];

  readonly temporaryInsuredFieldId = {
    checkPaymentProvider: '/XML/Client/CRMDetails/TempInsurancePaymentProvider_~_109032#_~_5_~_TCD_~_RB_NVF',
    eftPaymentProvider: '/XML/Client/CRMDetails/TempInsurancePaymentProvider_~_109519#_~_5_~_TCD_~_RB_NVF',
    paymentMethod: '/XML/Client/Banking/PaymentMethod_~_108887#_~_5_~_TCD_~_RB_NVF'
  };

  readonly disabledEFTFields = [
    '/XML/Client/CRMDetails/TempInsurancePaymentProvider_~_109519#_~_5_~_TCD_~_RB_NVF',
    '/XML/Client/CRMDetails/EftAccountholderFirstName',
    '/XML/Client/CRMDetails/EftAccountholderLastName',
    '/XML/Client/CRMDetails/EftStreetAddress',
    '/XML/Client/CRMDetails/EftCityAddress',
    '/XML/Client/CRMDetails/EftStateAddress',
    '/XML/Client/CRMDetails/EftZipCode',
    '/XML/Client/CRMDetails/EftPayorEmailAddress',
    '/XML/Client/CRMDetails/EftPrimaryPhoneNumber',
    '/XML/Client/CRMDetails/EftCellPhoneNumber',
  ];

  readonly disableAccountFields = [
    '/XML/Client/Banking/EftBankUnionName',
    '/XML/Client/Banking/EftRoutingNumber',
    '/XML/Client/Banking/EftAccountNumber',
    '/XML/Client/Banking/EftAccountType'
  ];
}
