import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApplicationConfig } from 'src/config/app.config';
import { AgentValidationMessage } from 'src/config/constants';

@Component({
  selector: 'app-agent-validation-popup',
  templateUrl: './agent-validation-popup.component.html',
  styleUrls: ['./agent-validation-popup.component.scss'],
})
export class AgentValidationPopupComponent implements OnInit {
  @Input() invalidAgents: any[];

  @Output() updateModalAction = new EventEmitter<any>();
  @Input() message: string[] = [];

  agentSuccessMessage = AgentValidationMessage.ALL_AGENTS_VALID;
  agentValidationError = AgentValidationMessage.ERROR_OCCURRED;

  modalConfig: any;

  constructor(
    public appConfig: ApplicationConfig,
  ) { }

  ngOnInit(): void {
    this.modalConfig = {
      header: true,
      state: true,
      footer: true,
    };
  }

  closeModalPopup(): any {
    this.modalConfig.state = false;
    this.updateModalAction.emit(true);
  }
}
