import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { BasePageComponent } from 'src/app/case/base-page/base-page.component';
@Injectable({
  providedIn: 'root'
})
export class RedirectConfirmationGuard implements CanDeactivate<BasePageComponent> {

  constructor() {
    // write code here
   }

  canDeactivate(
    baseComponent: BasePageComponent): Observable<boolean> | boolean {
    if ((baseComponent.isFormChanged || baseComponent.isSignatureFormChange) && !baseComponent.isSaveAndExitClicked) {
      baseComponent.openRedirectConfirmationModal();
      return baseComponent.redirectConfirmationSubj.asObservable();
    } else {
      return true;
    }
  }
}
