import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  GridInstance,
  PageJsonObject,
  Question,
  QuestionOption,
} from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { saleMaterialValidator } from 'src/app/shared/validators/business-validator';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, ExistingInsuranceConstant, ExistingInsuranceMessage, ExistingInsuranceProposedBConstant, Message } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-existing-insurance',
  templateUrl: './existing-insurance.component.html',
  styleUrls: ['./existing-insurance.component.scss'],
})
export class ExistingInsuranceComponent implements OnInit {
  @Input() disableForm: boolean;
  @Input() forProposedInsured: boolean;
  questionsData: PageJsonObject;
  insurerOptions: any;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() replacementPolicyAdded: EventEmitter<string> = new EventEmitter();
  @Output() existingInsMainQuesValueUpdate: EventEmitter<string> = new EventEmitter();

  // variable to save ques answer for checking other conditions
  inforceInsQues: string;
  financeInsQues: string;
  floridaComparativeInfo: string;
  replacementInsQues1: string;
  replacementInsQues2: string;
  checkBoxInsQues: string;

  RADIO_ANS = {
    YES: 'yes{47}yes',
    NO: 'no{47}no'
  };

  existingInsuranceInfoData: Question[] = [];
  existingInsuranceInfoForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  gridHasNoErrors = false;
  formHasNoErrors = true;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  alldropDownOptions = {};
  setTimeFocus = null;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  existingInsError = ExistingInsuranceMessage.INSURANCE_ERROR_MESSAGE;
  hasExistingInsurance = false;
  hasCheckBoxReq = false;
  hasReplacement = false;
  hasError = false;
  existingInsAnswers = {};
  insuredState: string;
  maxNoOfInsurance: number;
  MSG = Message;
  baseURL = environment.uiBaseUrl;
  replacementPolicyQuesName: string;

  // variable when more than one accordion available

  gridInstance2: GridInstance[];
  gridAnswers2: any[];
  gridInstance3: GridInstance[];
  gridAnswers3: any[];
  gridHasNoErrors2 = false;
  gridHasNoErrors3 = false;
  isGridFormValid2 = true;
  isGridFormChange2 = false;
  isGridFormValid3 = true;
  isGridFormChange3 = false;

  EI_CONSTANT;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private userService: UserDetailsService
  ) { }

  ngOnInit(): void {
    this.EI_CONSTANT = this.forProposedInsured ? ExistingInsuranceProposedBConstant : ExistingInsuranceConstant;
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.insuredState = this.userService.getInsuredState()?.toLowerCase();
      this.initializeGridValues();
      this.createExistingInsuranceDropDown();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.existingInsuranceInfoForm);
      this.defaultPageLoadService.disableFormIfLocked(this.existingInsuranceInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.replacementPolicyQuesName = this.getNameFromXmlTag(this.EI_CONSTANT.policyBeingQuesId);
      this.checkIfWAReplacementNeeded();
    }
  }

  private buildFormData(): any {
    this.existingInsuranceInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.existingInsuranceInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.existingInsuranceInfoData
    );
    this.existingInsuranceInfoForm = this.fb.group(form);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private createExistingInsuranceDropDown(): void {
    const value = 'Company Not Listed{47}Company Not Listed';
    const description = 'Company Not Listed';
    const label = 'Company Not Listed';
    const quesOptions: QuestionOption[] = [];
    const companyList = this.defaultPageLoadService.getExistingInsuranceCompanyList();
    if (companyList?.length > 0) {
      this.utilsService.createDropDownFromList(companyList, quesOptions);
    }
    quesOptions.push({ value, description, label });
    this.insurerOptions = this.utilsService.formatOptions(quesOptions);
    this.updateQuestionDataWithFirm(quesOptions);
  }

  onCheckboxChange(): void {
    this.checkForSalesMaterial();
  }

  private updateQuestionDataWithFirm(quesOptions: QuestionOption[]): void {
    this.updateFirmInGridInstance(this.gridInstance, quesOptions);
    this.updateFirmInGridInstance(this.gridInstance2, quesOptions);
    this.updateFirmInGridInstance(this.gridInstance3, quesOptions);
  }

  private updateFirmInGridInstance(gridInstance: GridInstance[], quesOptions: QuestionOption[]): void {
    gridInstance[0].gridQuestions.forEach((question) => {
      if (this.isCompanyListQues(question)) {
        question.questionOption = quesOptions;
      }
    });
  }

  private isCompanyListQues(question: Question): boolean {
    return question.xmlTag === this.EI_CONSTANT.existingCompanyNameXml
      || question.xmlTag === this.EI_CONSTANT.existingInsurerList
      || question.xmlTag === this.EI_CONSTANT.existingInsurerListIn;
  }

  private checkForSalesMaterial(): void {
    if (this.insuredState === 'ut') {
      let isReplacementTransactionAvailable = false;
      this.existingInsuranceInfoData.forEach(ques => {
        if (ques.xmlTag && ques.xmlTag.indexOf(this.EI_CONSTANT.replacementTransactionInd) > -1 &&
          this.existingInsuranceInfoForm.get(ques.fieldId)?.value) {
          isReplacementTransactionAvailable = true;
        }
      });
      this.updateValidationForQues(isReplacementTransactionAvailable, this.EI_CONSTANT.saleMaterialInd);
    }
  }

  updateCheckboxValidation(data: Question): void {
    if (this.existingInsuranceInfoForm.get(data.fieldId)?.value?.length > 0) {
      this.updateValidationForQues(true, this.EI_CONSTANT.saleMaterialInd);
    } else {
      this.checkForSalesMaterial();
    }
  }

  private updateValidationForQues(isSalesAdded: boolean, xmlTag: string): void {
    const fieldId = this.getFieldIdFromXmlTag(xmlTag);
    this.updateQuesDataFor(xmlTag, !isSalesAdded);
    this.existingInsuranceInfoForm.get(fieldId)?.setValidators([saleMaterialValidator(isSalesAdded)]);
    this.existingInsuranceInfoForm.get(fieldId)?.updateValueAndValidity();
  }

  private updateQuesDataFor(xmlTag: string, isRequired: boolean): void {
    this.existingInsuranceInfoData.forEach(ques => {
      if (xmlTag === ques.xmlTag) {
        ques.required = isRequired ? 'true' : 'false';
      }
    });
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.updateBusinessRule();
      this.updateUTQuestionsIfExisingIns();
      this.checkFormStatus();
    }, 200);
    const initialValue = this.existingInsuranceInfoForm.value;
    this.existingInsuranceInfoForm.valueChanges.subscribe((_val) => {
      this.emitValueIfChangeInMainRadioQues(initialValue);
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.updateBusinessRule();
        this.checkFormStatus();
      }, 200);
    });
  }

  private updateBusinessRule(): void {
    this.updateAnswerValues();
    this.checkForExistingInsurance();
    this.checkForError();
    this.checkIfComparativeQuestReq();
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.existingInsuranceInfoForm,
      data: null,
    };
    for (const data of this.existingInsuranceInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  onRadioChange(data): any {
    this.updateUTQuestionsIfExisingIns();
    this.updatedAnswersOnHidden(data);
  }

  handleSelectionChange(data, _ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.existingInsuranceInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.existingInsuranceInfoForm.get(key)?.markAsUntouched();
      }
      this.existingInsuranceInfoForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.existingInsuranceInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckPrimaryInsuredInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data): any {
    return this.utilsService.getInlineErrorMessage(data);
  }

  gridformStatus(event, gridSeq?: number): void {
    if (gridSeq === 2) {
      this.isGridFormValid2 = event?.formValid;
      this.gridHasNoErrors2 = event.formHasNoErrors;
      this.gridAnswers2 = event.gridAnswers;
      this.isGridFormChange2 = event.isGridFormChange;
    } else if (gridSeq === 3) {
      this.isGridFormValid3 = event?.formValid;
      this.gridHasNoErrors3 = event.formHasNoErrors;
      this.gridAnswers3 = event.gridAnswers;
      this.isGridFormChange3 = event.isGridFormChange;
    } else {
      this.isGridFormValid = event?.formValid;
      this.gridHasNoErrors = event.formHasNoErrors;
      this.gridAnswers = event.gridAnswers;
      this.isGridFormChange = event.isGridFormChange;
      this.checkIfWAReplacementNeeded();
    }
    this.emitStatus();
  }

  emitStatus(): void {
    this.isGridFormValid = this.isGridForm1Valid();
    this.isGridFormValid2 = this.isGridForm2Valid();
    this.isGridFormValid3 = this.isGridForm3Valid();
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    this.questions.pages[1].gridAnswers = this.gridAnswers2;
    this.questions.pages[2].gridAnswers = this.gridAnswers3;
    const existingInsuranceInfoObj = {
      formValid: this.formValid && this.isGridFormValid && this.isGridFormValid2 && this.isGridFormValid3 && !this.hasError,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors && this.gridHasNoErrors2 && this.gridHasNoErrors3,
      questions: this.questions,
      formId: this.forProposedInsured ? 'existingInsuranceProposedBInfoForm' : 'existingInsuranceInfoForm',
      gridForm: true,
      isFormChange: this.existingInsuranceInfoForm.dirty || this.isGridFormChange
        || this.isGridFormChange2 || this.isGridFormChange3 || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.existingInsuranceInfoForm),
    };
    this.formStatus.emit(existingInsuranceInfoObj);
  }

  private checkForExistingInsurance(): void {
    this.hasExistingInsurance = this.inforceInsQues === this.RADIO_ANS.YES;
    this.hasCheckBoxReq = this.checkBoxInsQues === this.RADIO_ANS.YES;
    // clear answers as grids depends on these values
    if (!this.hasExistingInsurance) {
      this.gridAnswers = [];
      this.gridAnswers3 = [];
      if (this.insuredState !== 'ma') {
        this.gridAnswers2 = [];
      }
    }
    if (!this.hasCheckBoxReq && this.insuredState === 'ma') {
      this.gridAnswers2 = [];
    }
  }

  private updateUTQuestionsIfExisingIns(): void {
    if (this.getValueById(this.EI_CONSTANT.hasExistingInsuranceQuesId) === this.RADIO_ANS.YES && this.insuredState === 'ut') {
      this.checkForSalesMaterial();
    } else if (this.getValueById(this.EI_CONSTANT.hasExistingInsuranceQuesId) === this.RADIO_ANS.NO) {
      this.existingInsuranceInfoData.forEach(ques => {
        if (ques.notes?.indexOf('IF_EXISTING_INSURANCE') > -1) {
          this.existingInsuranceInfoForm.get(ques.fieldId)?.reset();
          this.existingInsuranceInfoForm.get(ques.fieldId)?.setErrors(null);
        }
      });
    }
  }

  private checkForError(): void {
    this.hasError = this.inforceInsQues === this.RADIO_ANS.NO &&
      (this.replacementInsQues1 === this.RADIO_ANS.YES || this.replacementInsQues2 === this.RADIO_ANS.YES ||
        this.financeInsQues === this.RADIO_ANS.YES);
  }

  private checkIfComparativeQuestReq(): void {
    if (this.existingInsuranceInfoForm.get(this.defaultPageLoadService.getFieldIdByTag(this.EI_CONSTANT.comparativeInfo)) &&
      (this.inforceInsQues === this.RADIO_ANS.YES) &&
      (this.replacementInsQues1 === this.RADIO_ANS.YES || this.replacementInsQues2 === this.RADIO_ANS.YES ||
        this.financeInsQues === this.RADIO_ANS.YES)) {
      this.updateDisplayForQues(true, this.EI_CONSTANT.comparativeInfo);
    } else {
      this.updateDisplayForQues(false, this.EI_CONSTANT.comparativeInfo);
    }
  }

  private getValueById(id: string): string {
    return this.existingInsuranceInfoForm.get(this.defaultPageLoadService.getFieldIdByTag(id))?.value?.toLowerCase();
  }

  private updateDisplayForQues(shouldDisplay: boolean, xmlTag?: string, fieldId?: string): void {
    this.existingInsuranceInfoData.forEach(ques => {
      if (ques.xmlTag === xmlTag || ques.fieldId === fieldId) {
        ques.required = shouldDisplay ? 'true' : 'false';
        ques.display = shouldDisplay;
        if (!ques.display) {
          ques.question_answer = '';
        }
      }
    });
  }

  private updateAnswerValues(): void {
    this.inforceInsQues = this.getValueById(this.EI_CONSTANT.hasExistingInsuranceQuesId);
    this.checkBoxInsQues = this.getValueById(this.EI_CONSTANT.IndicesForCashValue);
    this.financeInsQues = this.getValueById(this.EI_CONSTANT.existingFundsQuesId);
    this.floridaComparativeInfo = this.getValueById(this.EI_CONSTANT.comparativeInfo);
    this.replacementInsQues1 = this.getValueById(this.EI_CONSTANT.notifyCompanyQuesId);
    this.replacementInsQues2 = this.getValueById(this.EI_CONSTANT.quitExistingPolicyQuesId);
    this.existingInsAnswers = {
      [this.EI_CONSTANT.quitExistingPolicyQuesId]: this.replacementInsQues2 === 'yes{47}yes' || this.replacementInsQues1 === 'yes{47}yes',
      [this.EI_CONSTANT.existingFundsQuesId]: this.financeInsQues === 'yes{47}yes',
      [this.EI_CONSTANT.comparativeInfo]: this.floridaComparativeInfo === 'yes{47}yes'
    };
    // clear indiana grid answer as it dependes on these values
    if (!(this.existingInsAnswers[this.EI_CONSTANT.quitExistingPolicyQuesId]
      || this.existingInsAnswers[this.EI_CONSTANT.existingFundsQuesId]) && this.insuredState === 'in') {
      this.gridAnswers = [];
    }
  }

  private checkIfWAReplacementNeeded(): void {
    let hasReplacement = false;
    this.gridAnswers.forEach(gridVal => {
      if (gridVal[this.replacementPolicyQuesName]?.toLowerCase().indexOf('replaced') > -1) {
        hasReplacement = true;
      }
    });
    this.hasReplacement = hasReplacement;
    if (!this.hasReplacement && this.insuredState === 'mi') {
      this.gridAnswers2 = [];
    }
    if (this.insuredState === 'wa') {
      hasReplacement ? this.replacementPolicyAdded.emit('yes') : this.replacementPolicyAdded.emit('no');
    }
  }

  private getNameFromXmlTag(xmlTag: string): string {
    return this.gridInstance[0].gridQuestions.filter(ques => {
      return ques.xmlTag === xmlTag;
    })[0]?.name;
  }

  private getFieldIdFromXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  viewNotice(): void {
    this.defaultPageLoadService.logLinkClick('view notice');
    window.open(this.baseURL + 'assets/documents/LF10087-Replacement-of-life-insurance-or-Annuity.pdf');
  }

  private initializeGridValues(): void {
    this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
    this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
    this.gridInstance2 = this.questionsData?.pages[1]?.gridInstances;
    this.gridAnswers2 = this.questionsData?.pages[1]?.gridAnswers;
    this.gridInstance3 = this.questionsData?.pages[2]?.gridInstances;
    this.gridAnswers3 = this.questionsData?.pages[2]?.gridAnswers;
    this.maxNoOfInsurance = +(this.gridInstance[0]?.maxGridIndex);
  }

  private isGridForm1Valid(): boolean {
    if (this.hasExistingInsurance && this.insuredState !== 'mn' && this.insuredState !== 'in') {
      return this.isGridFormValid;
    } else if (this.hasExistingInsurance && (this.existingInsAnswers[this.EI_CONSTANT.quitExistingPolicyQuesId]
      || this.existingInsAnswers[this.EI_CONSTANT.existingFundsQuesId]) && this.insuredState === 'in') {
      return this.isGridFormValid;
    } else {
      return true;
    }
  }

  private isGridForm2Valid(): boolean {
    if (this.hasExistingInsurance && this.hasReplacement && this.insuredState === 'mi') {
      return this.isGridFormValid2;
    } else if (this.hasCheckBoxReq && this.insuredState === 'ma') {
      return this.isGridFormValid2;
    } else if (this.hasExistingInsurance && this.insuredState === 'in'
      && !(this.existingInsAnswers[this.EI_CONSTANT.quitExistingPolicyQuesId]
        || this.existingInsAnswers[this.EI_CONSTANT.existingFundsQuesId])) {
      return this.isGridFormValid2;
    } else {
      return true;
    }
  }

  private isGridForm3Valid(): boolean {
    return this.hasExistingInsurance && this.insuredState === 'in' ? this.isGridFormValid3 : true;
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  private emitValueIfChangeInMainRadioQues(initialValue: any): void {
    let isValueUpdated = false;
    const listOfTagForValueChange = [this.EI_CONSTANT.hasExistingInsuranceQuesId,
    this.EI_CONSTANT.quitExistingPolicyQuesId, this.EI_CONSTANT.existingFundsQuesId,
    this.EI_CONSTANT.notifyCompanyQuesId];
    listOfTagForValueChange.forEach(tag => {
      Object.keys(initialValue).forEach(formcontrol => {
        if (formcontrol.indexOf(tag) > -1 && this.existingInsuranceInfoForm.value[formcontrol] !== initialValue[formcontrol]) {
          isValueUpdated = true;
        }
      });
    });
    if (isValueUpdated) {
      this.existingInsMainQuesValueUpdate.emit();
    }
  }
}
