import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { GridInstance, Question, Recipient } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ElectronicSignatures, ErrorMessage, Message } from 'src/config/constants';

@Component({
  selector: 'app-agent-instruction',
  templateUrl: './agent-instruction.component.html',
  styleUrls: ['./agent-instruction.component.scss'],
})
export class AgentInstructionComponent implements OnInit, OnChanges {
  @Input() disableForm: boolean;
  @Input() signerList: Recipient[];
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Input() envelopeCreated: boolean;
  @Input() errorOccuredWhileCreateEnvelope: boolean;
  @Input() errorOccuredToGetTransactionId: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  signingMethodSelected;

  gridInstances: GridInstance[];
  gridQuestionsObj = {};
  gridAnswers;
  mainFormGroup: FormGroup;
  gridSequences: any = [];
  tempGridAns = [];
  signerQuesId;

  agentInstructionData: Question[] = [];
  formValid = true;
  formHasNoErrors = true;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  alldropDownOptions = {};
  inpersonSignerList: Recipient[] = [];

  ELECTRONIC_SIGN_CONST = ElectronicSignatures;
  Message = Message;

  agentName: string;

  constructor(
    private fb: FormBuilder,
    private appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private defaultPageLoadService: DefaultPageload,
    private commonService: CommonService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.initializeInpersonSignerList();
      this.initializePageData();
      this.initializeGridInstance();
      this.agentName = (this.defaultPageLoadService.getAgentProfile().firstName
        .concat(' ', this.defaultPageLoadService.getAgentProfile().lastName));
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.mainFormGroup);
      this.defaultPageLoadService.disableFormIfLocked(this.mainFormGroup, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableForm?.previousValue === true && changes.disableForm?.currentValue === false) {
      this.enableForms();
    }
  }

  private initializeGridInstance(): void {
    for (const gridData of this.gridInstances) {
      this.gridQuestionsObj[gridData.id] = gridData;
      const gridDropDownOption = this.formgeneratorService.getdropdownOptions(
        gridData.gridQuestions
      );
      this.alldropDownOptions = {
        ...this.alldropDownOptions,
        ...gridDropDownOption,
      };
    }
    this.formgeneratorService.setGridQuestionsData(this.gridQuestionsObj);
    const form = {};
    form[this.gridInstances[0].id] = this.fb.array([]);
    this.mainFormGroup = this.fb.group(form);
    this.createFormsForInpersonSigner();
    this.valueChanges();
  }

  private createFormsForInpersonSigner(): void {
    const id = this.gridInstances[0].id;
    for (const savedAnswer of this.gridAnswers) {
      const updatedAnswerObject =
        this.formgeneratorService.updateGridAnswerArray(
          id,
          this.gridQuestionsObj,
          savedAnswer
        );
      const gridFormGroup = this.formgeneratorService.createGrid(
        id,
        this.gridQuestionsObj[id].gridQuestions,
        updatedAnswerObject
      );
      const formArray = this.mainFormGroup.get(id) as FormArray;
      const newControls = this.fb.group(gridFormGroup);

      formArray.push(newControls);
      const obj = {
        gridIndex: formArray.length - 1,
        gridId: id
      };
      this.gridSequences.push(obj);
    }
  }

  private initializePageData(): any {
    this.agentInstructionData = this.filterQuestionData(this.questionsData.pages[0].questions);
    this.gridInstances = this.questionsData.pages[0].gridInstances;
    this.updateGridAnswersWithSignerName();
    this.gridAnswers = this.questionsData.pages[0].gridAnswers;
  }

  private updateGridAnswersWithSignerName(): void {
    this.gridInstances[0].gridQuestions.forEach((ques) => {
      if (ques.questionText === '{signer}') {
        this.signerQuesId = ques.name;
      }
    });
    if (this.questionsData.pages[0].gridAnswers?.length === 0) {
      this.inpersonSignerList.forEach(signer => {
        this.tempGridAns.push({ [this.signerQuesId]: signer.displayRole + ' - ' + signer.fullName });
      });
      this.questionsData.pages[0].gridAnswers = this.tempGridAns;
    }
  }

  nestedQuestionCheckgridInfo(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else {
      return parent === childData.optionChoice;
    }
  }

  private valueChanges(): void {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.mainFormGroup.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  handleSelectionChange(data: Question, gridId: string, gridIndex: number): any {
    this.updatedAnswersOnHidden(data, gridId, gridIndex);
  }

  updatedAnswersOnHidden(data: Question, gridId: string, gridIndex: number): any {
    const value = this.mainFormGroup.getRawValue();
    const control = 'controls';
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.mainFormGroup.get(gridId)[control][gridIndex]?.get(key)?.markAsUntouched();
      }
      this.mainFormGroup.get(gridId)[control][gridIndex]?.patchValue(updatedval);
    }
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  checkIfQuestionDisplayed(gridForm: FormGroup, data): any {
    const answer = gridForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private filterQuestionData(questData: Question[]): Question[] {
    this.signingMethodSelected = this.inpersonSignerList.length > 0 ? 'inperson' : 'remote';
    return questData.filter(ques => {
      if (this.signingMethodSelected === 'inperson') {
        return ques.displayConditionXpath.indexOf('In Person') > -1;
      } else {
        return ques.displayConditionXpath.indexOf('Remote') > -1;
      }
    });
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  private checkFormStatus(): void {
    this.formValid = true;
    this.formHasNoErrors = true;
    const componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.mainFormGroup,
      data: {},
      gridData: {},
      gridDataWithNameTag: {},
    };
    this.updateGridAnswerAndFormValidity(componentData);
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'agentInstructionForm',
      isFormChange: this.mainFormGroup.dirty || (this.signingMethodSelected === 'remote' && !this.disableForm && !this.isTabVisited),
    };
    this.formStatus.emit(obj);
  }

  private updateGridAnswerAndFormValidity(componentData: any): void {
    for (const data of this.gridSequences) {
      if (!componentData.gridData.hasOwnProperty(data.gridId)) {
        componentData.form = this.mainFormGroup;
        componentData = this.formgeneratorService.checkGridStatusAndGetGridData(
          componentData,
          data.gridId
        );
        this.formValid = componentData.formValid;
        this.formHasNoErrors = componentData.formHasNoErrors;
      }
    }
    const gridAnswers = [];
    for (const data of this.gridSequences) {
      const signer = this.inpersonSignerList[data.gridIndex];
      gridAnswers.push(
        componentData.gridDataWithNameTag[data.gridId][data.gridIndex]
      );
      gridAnswers[data.gridIndex][this.signerQuesId] = signer.displayRole + ' - ' + signer.fullName;
      this.checkIfFormIsValid(data);
    }
    this.questions.pages[0].gridAnswers = gridAnswers;
  }

  private checkIfFormIsValid(gridData): any {
    const formGrid = ((this.mainFormGroup.get(gridData.gridId) as FormArray)?.at(gridData.gridIndex)) as FormGroup;
    const questData = this.gridInstances[0]?.gridQuestions;
    const componentData = {
      formValid: true
    };
    questData.forEach(ques => {
      this.updateAgentFormStatus(componentData, ques, formGrid);
    });
  }

  private updateAgentFormStatus(componentData: any, data: any, form: FormGroup, parentAnswer?: any): any {
    let inputVisible = true;
    if (typeof parentAnswer !== 'undefined') {
      const optionChoice = data.optionChoice || '';
      inputVisible = optionChoice.indexOf(parentAnswer) >= 0;
    }
    if (inputVisible && form.controls[data.fieldId]?.status === 'INVALID') {
      componentData.formValid = false;
    }
    if (data.hasReflexive === 'true') {
      for (const childData of data.reflexiveQuestionAL) {
        const answer = this.getAgentAnswer(form, data);
        componentData = this.updateAgentFormStatus(componentData, childData, form, answer);
      }
    }
    return componentData;
  }

  private getAgentAnswer(form: FormGroup, data: Question): string {
    let answer = form.get(data.fieldId).value;
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT && answer) {
      answer = answer.value;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      answer = answer === true ? 'Yes{47}Yes' : 'No{47}No';
    }
    return answer;
  }

  private initializeInpersonSignerList(): void {
    this.inpersonSignerList = this.signerList.filter(signer => {
      return signer.eSignatureMethod === 'inperson';
    });
  }

  private enableForms(): void {
    setTimeout(() => {
      this.mainFormGroup?.enable();
    }, 300);
  }
}
