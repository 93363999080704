import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { TAB, TabInformation } from 'src/config/sideNav.config';
import { ResponseObject, DeleteCaseIdsObject } from '../models/casePage.model';
import { ServiceInvoker } from './service-invoker';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  TabPageMap: Map<string, string> = new Map([
    [TAB.CASE_INFO, PAGE_GROUP_NAME.CASE_INFO_PAGE],
    [TAB.ATTESTATION, PAGE_GROUP_NAME.ATTESTATION_PAGE],
    [TAB.PRIMARY_INSURED, PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE],
    [TAB.COVERAGE_INFO, PAGE_GROUP_NAME.COVERAGE_INFO_PAGE],
    [TAB.OWNER_INFO, PAGE_GROUP_NAME.OWNER_INFO_PAGE],
    [TAB.TRUST_INFO, PAGE_GROUP_NAME.TRUST_INFO_PAGE],
    [TAB.RIDERS_INFO, PAGE_GROUP_NAME.RIDERS_INFO_PAGE],
    [TAB.TEMPORARY_LIFE, PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE],
    [TAB.EXISTING_INSURANCE, PAGE_GROUP_NAME.EXISTING_INSURANCE],
    [TAB.AGENT_INFO, PAGE_GROUP_NAME.AGENT_INFORMATION],
    [TAB.AGENT_INFO_CONT, PAGE_GROUP_NAME.AGENT_INFO_CONTINUED],
    [TAB.ADDITIONAL_INFO, PAGE_GROUP_NAME.ADDITIONAL_INFO],
    [TAB.APPLICATION_SUMMARY, PAGE_GROUP_NAME.SUMMARY_INFO],
    [TAB.VALIDATE_AND_LOCK, PAGE_GROUP_NAME.VALIDATE_AND_LOCK],
    [TAB.SIGNATURE_METHOD, PAGE_GROUP_NAME.SIGNATURE_METHOD],
    [TAB.VOICE_TERM_OF_USE, PAGE_GROUP_NAME.VOICE_TERM_OF_USE],
    [TAB.ELECTRONIC_FUND_TRANSFER, PAGE_GROUP_NAME.ELECTRONIC_FUND_TRANSFER],
    [TAB.AGENT_INSTRUCTION, PAGE_GROUP_NAME.AGENT_INSTRUCTION],
    [TAB.E_SIGNATURE_INSTRUCTION, PAGE_GROUP_NAME.E_SIGNATURE_INSTRUCTION],
    [TAB.FUND_ALLOCATION, PAGE_GROUP_NAME.FUND_ALLOCATION],
    [TAB.DOLLAR_COST_AVERAGING, PAGE_GROUP_NAME.DOLLAR_COST_AVERAGING],
    [TAB.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION, PAGE_GROUP_NAME.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION],
    [TAB.TRANSFER_ALLOCATION_CHARGES_AUTHORIZATION, PAGE_GROUP_NAME.TRANSFER_ALLOCATION_CHARGES_AUTHORIZATION],
    [TAB.SUITABILITY_REVIEW, PAGE_GROUP_NAME.SUITABILITY_REVIEW],
    [TAB.PROPOSED_INSURED_B, PAGE_GROUP_NAME.PROPOSED_INSURED_B],
    [TAB.EXISTING_INSURANCE_FOR_PROPOSED_B, PAGE_GROUP_NAME.EXISTING_INSURANCE_FOR_PROPOSED_B],
    [TAB.WA_REPLACEMENT, PAGE_GROUP_NAME.WA_REPLACEMENTS],
    [TAB.WA_REPLACEMENT_PROPOSED_B, PAGE_GROUP_NAME.WA_REPLACEMENTS_PROPOSED_B],
    [TAB.LTC_INSURANCE, PAGE_GROUP_NAME.LTC_INSURANCE],
    [TAB.ILLUSTRATION_INFORMATION, PAGE_GROUP_NAME.ILLUSTRATION_INFO],
    [TAB.VUL_FUND_ALLOCATION, PAGE_GROUP_NAME.VUL_FUND_ALLOCATION],
    [TAB.CONSENT_PAGE_AGENT, PAGE_GROUP_NAME.CONSENT_PAGE_AGENT],
    [TAB.CONSENT_PAGE_PI, PAGE_GROUP_NAME.CONSENT_PAGE_PI],
    [TAB.CONSENT_PAGE_INSURED_OWNER, PAGE_GROUP_NAME.CONSENT_PAGE_INSURED_OWNER],
    [TAB.CONSENT_PAGE_INSURED_B, PAGE_GROUP_NAME.CONSENT_PAGE_INSURED_B],
    [TAB.CONSENT_PAGE_ADDITIONAL_INSURED, PAGE_GROUP_NAME.CONSENT_PAGE_ADDITIONAL_INSURED],
    [TAB.CONSENT_PAGE_OWNER, PAGE_GROUP_NAME.CONSENT_PAGE_OWNER],
    [TAB.CONSENT_PAGE_COOWNER, PAGE_GROUP_NAME.CONSENT_PAGE_COOWNER],
    [TAB.CONSENT_PAGE_COOWNER2, PAGE_GROUP_NAME.CONSENT_PAGE_COOWNER2],
    [TAB.CONSENT_PAGE_COOWNER3, PAGE_GROUP_NAME.CONSENT_PAGE_COOWNER3],
    [TAB.ELECTRONIC_SIGNATURE, PAGE_GROUP_NAME.ELECTRONIC_SIGNATURE],
    [TAB.REVIEW_AND_SUBMIT, PAGE_GROUP_NAME.REVIEW_AND_SUBMIT],
    [TAB.VOICE_CONFIRM_THANK_YOU, PAGE_GROUP_NAME.VOICE_CONFIRM_THANK_YOU]
  ]);

  PageTabMap: Map<string, string> = new Map<string, string>();

  constructor(private serviceInvoker: ServiceInvoker) {
    this.TabPageMap.forEach((pageName: string, tabName: string) => {
      this.PageTabMap.set(pageName, tabName);
    });
  }

  getPageGroupByTabByTabName(tabName: string): string {
    const tabInfo = {
      name: tabName,
      isDefault: false,
      isEnable: false,
      status: undefined,
      analyticsPageName: undefined
    };
    return this.getPageGroupByTab(tabInfo);
  }

  getPageGroupByTab(tab: TabInformation): string {
    return this.TabPageMap.get(tab?.name);
  }

  getTabByPageGroupName(pageGroupName: string): string {
    return this.PageTabMap.get(pageGroupName);
  }

  getTableData(): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('GET_CASES');
  }

  deleteCases(deleteCaseIds: string[]): Observable<ResponseObject> {
    const payload: DeleteCaseIdsObject = {
      caseIds: deleteCaseIds
    };
    return this.serviceInvoker.invokeService('DELETE_CASES', null, payload);
  }

  cloneCase(caseId: string): Observable<ResponseObject> {
    return this.serviceInvoker.invokeService('CLONE_CASE', {caseId});
  }
}
