import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { Question } from 'src/app/shared/models/casePage.model';
import { CoverageQuestionConstant, ErrorMessage } from 'src/config/constants';
import { QuestionValidationService } from 'src/app/shared/services/question-validation.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { faceAmountValidator, modalAmountValidator, rateAndDobValidator, rateDurationAndDobValidator } from 'src/app/shared/validators/business-validator';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { ProductType, ProductCodes, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-coverage-info',
  templateUrl: './coverage-info.component.html',
  styleUrls: ['./coverage-info.component.scss'],
})
export class CoverageInfoComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() eftInfoUpdated: EventEmitter<string> = new EventEmitter();
  @Output() isIllustrationSelected: EventEmitter<string> = new EventEmitter();

  coverageInfoData: Question[] = [];
  coverageInfoForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;

  alldropDownOptions = {};

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  tabStatusOnInit: TabStatus;
  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private questionValidationService: QuestionValidationService,
    private defaultPageLoadService: DefaultPageload,
    private productService: ProductDetailsService,
    private userService: UserDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.coverageInfoForm);
      this.defaultPageLoadService.markFormFieldsTouched(this.coverageInfoForm, this.isTabVisited);
      this.defaultPageLoadService.disableFormIfLocked(this.coverageInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.checkForOptionalTabs();
    }
  }

  private buildFormData(): any {
    this.coverageInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.coverageInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.coverageInfoData
    );
    this.coverageInfoForm = this.fb.group(form);
    this.updateValidators();
    this.valueChanges();
    setTimeout(() => {
      this.updateSpecialDating();
    }, 100);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.updateValidator();
      this.checkFormStatus();
    }, 200);
    this.coverageInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.updateValidator();
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private updateValidator(): any {
    this.coverageInfoForm
      .get(this.getFieldIdByXmlTag(CoverageQuestionConstant.premiumModeQuestionId))
      ?.valueChanges.subscribe((value) => {
        this.emitEftInfoUpdated(value, true);
      });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.coverageInfoForm,
      data: null,
    };
    for (const data of this.coverageInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'coverageInfoForm',
      isFormChange: this.coverageInfoForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.coverageInfoForm),
    };
    this.formStatus.emit(obj);
  }

  coverageOnRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
    this.updateIllustrationInfo(data);
  }

  private updateIllustrationInfo(data: Question): void {
    if (data.xmlTag === CoverageQuestionConstant.illustrationQues) {
      this.emitIllustrationInfo(data.fieldId);
    }
  }

  coverageHandleSelectionChange(data, _ix?): any {
    this.updateRateClassValidator();
    this.updatePremiumAmountValidator();
    this.updatedAnswersOnHidden(data);
  }

  coverageAddCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.coverageInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.coverageInfoForm.get(key)?.markAsUntouched();
      }
      this.coverageInfoForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.coverageInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckCoverageInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  onCheckboxChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  getErrorMsg(data, formControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private updateSpecialDating(): void {
    if (this.productService.getProductName()?.toLowerCase().indexOf(ProductCodes.WEALTHACCELERATE2022) > -1) {
      this.coverageInfoForm.get(this.getFieldIdByXmlTag(CoverageQuestionConstant.specialDatingQues))?.disable();
    }
  }

  private updateValidators(): void {
    this.updateRateClassValidator();
    this.updateFaceAmountValidator();
    this.updatePremiumAmountValidator();
  }

  private updateRateClassValidator(): void {
    const durationFieldId = this.getFieldIdByXmlTag(CoverageQuestionConstant.durationQues);
    if (this.coverageInfoForm.get(durationFieldId)) {
      this.updateRateDurationAndDobValidator(durationFieldId);
    } else {
      this.updateRateAndDobValidator();
    }
  }

  private updatePremiumAmountValidator(): void {
    const premiumMode = this.getFieldIdByXmlTag(CoverageQuestionConstant.modalPremiumAmountQues);
    if (this.productService.getProductName()?.toLowerCase() === ProductCodes.WEALTHPRESERVESIUL2022) {
      this.coverageInfoForm.get(premiumMode)?.setValidators(
        [modalAmountValidator(this.coverageInfoForm.get(this.getFieldIdByXmlTag(
          CoverageQuestionConstant.premiumModeQuestionId))?.value?.value), Validators.required]
      );
      this.coverageInfoForm.get(premiumMode)?.updateValueAndValidity();
    }
  }

  private updateFaceAmountValidator(): void {
    const faceAmountId = this.getFieldIdByXmlTag(CoverageQuestionConstant.faceAmountQues);
    this.coverageInfoForm.get(faceAmountId)?.setValidators(
      [faceAmountValidator(this.productService.getProductCondition()), Validators.required]
    );
    this.coverageInfoForm.get(faceAmountId)?.updateValueAndValidity();
  }

  private updateRateDurationAndDobValidator(durationFieldId: string): void {
    const rateClassId = this.getFieldIdByXmlTag(CoverageQuestionConstant.rateClassQues);
    this.coverageInfoForm.get(rateClassId)?.setValidators(
      [rateDurationAndDobValidator(this.productService.getProductCondition()?.ageConditions,
        this.coverageInfoForm.get(durationFieldId)?.value?.label,
        this.userService.getInsuredAge()), Validators.required]);
    this.coverageInfoForm.get(rateClassId)?.updateValueAndValidity();
  }

  private updateRateAndDobValidator(): void {
    const rateClassId = this.getFieldIdByXmlTag(CoverageQuestionConstant.rateClassQues);
    this.coverageInfoForm.get(rateClassId)?.setValidators(
      [rateAndDobValidator(this.productService.getProductCondition()?.ageConditions,
        this.userService.getInsuredState(),
        this.userService.getInsuredAge()),
      Validators.required]);
    this.coverageInfoForm.get(rateClassId)?.updateValueAndValidity();
  }

  private checkForOptionalTabs(): void {
    const illustrationFieldId = this.getFieldIdByXmlTag(CoverageQuestionConstant.illustrationQues);
    const premiumModeFieldId = this.getFieldIdByXmlTag(CoverageQuestionConstant.premiumModeQuestionId);
    this.emitIllustrationInfo(illustrationFieldId);
    this.emitEftInfoUpdated(this.coverageInfoForm.get(premiumModeFieldId)?.value, false);
  }

  private emitIllustrationInfo(fieldId: string): void {
    if (this.productService.getProductType()?.toLowerCase() === ProductType.IUL) {
      if (this.coverageInfoForm.get(fieldId)?.value === 'no{47}no') {
        this.isIllustrationSelected.emit('yes');
      } else {
        this.isIllustrationSelected.emit('no');
      }
    }
  }

  private emitEftInfoUpdated(premiumModeValue: any, isDropdown: boolean): void {
    const premiumModeAns = premiumModeValue?.value;
    if (premiumModeAns && this.questionValidationService.checkIfEFTQuestionRequired(this.utilsService.getCodeFromValue(premiumModeAns))
      && this.productService.getProductType()?.toLowerCase() !== ProductType.TERM) {
      this.eftInfoUpdated.emit('yes');
    } else {
      this.eftInfoUpdated.emit('no');
    }
  }
}
