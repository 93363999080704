export const PAGE_GROUP_NAME = {
  CASE_INFO_PAGE: 'LFG - eTicket - Case Information',
  ATTESTATION_PAGE: 'LFG - eTicket - Attestation by Product Info',
  PRIMARY_INSURED_PAGE: 'LFG - eTicket - Primary Insured Info',
  COVERAGE_INFO_PAGE: 'LFG - eTicket - Term Coverage Info',
  OWNER_INFO_PAGE: 'LFG - eTicket - Owner Info',
  RIDERS_INFO_PAGE: 'LFG - eTicket - Rider Info',
  TEMPORARY_LIFE_INSURANCE_PAGE: 'LFG - eTicket - Temporary Life Insurance Agreement',
  EXISTING_INSURANCE: 'LFG - eTicket - Existing Insurance',
  AGENT_INFORMATION: 'LFG - eTicket - Agent Information',
  AGENT_INFO_CONTINUED: 'LFG - eTicket - Agent Information Continued',
  ADDITIONAL_INFO: 'LFG - eTicket - Additional Information',
  SUMMARY_INFO: 'LFG - eTicket - Application Summary',
  VALIDATE_AND_LOCK: 'LFG - eTicket - Validate And Lock',
  SIGNATURE_METHOD: 'LFG - eTicket - Signature Method',
  VOICE_TERM_OF_USE: 'LFG - eTicket - Voice Term of Use',
  ELECTRONIC_FUND_TRANSFER: 'LFG - eTicket - EFT Info',
  AGENT_INSTRUCTION: 'LFG - eTicket - Agent Instruction',
  E_SIGNATURE_INSTRUCTION: 'LFG - eTicket - eSignature Instructions',
  FUND_ALLOCATION: 'LFG - eTicket - Fund Allocation',
  DOLLAR_COST_AVERAGING: 'LFG - eTicket - DCA Funds',
  MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION: 'LFG - eTicket - Maturing Funds',
  TRANSFER_ALLOCATION_CHARGES_AUTHORIZATION: 'LFG - eTicket - Trans Alloc Charges Auth',
  SUITABILITY_REVIEW: 'LFG - eTicket - Suitability Review',
  TRUST_INFO_PAGE: 'LFG - eTicket - Trust Information',
  PROPOSED_INSURED_B: 'LFG - eTicket - Proposed Insured B Info',
  EXISTING_INSURANCE_FOR_PROPOSED_B: 'LFG - eTicket - Existing Insurance Insured B',
  WA_REPLACEMENTS: 'LFG - eTicket - WA Replacement',
  WA_REPLACEMENTS_PROPOSED_B: 'LFG - eTicket - WA Replacement Insured B',
  LTC_INSURANCE: 'LFG - eTicket - LTC Existing Insurance',
  ILLUSTRATION_INFO: 'LFG - eTicket - Illustration',
  VUL_FUND_ALLOCATION: 'LFG - eTicket - Fund Allocation SVUL',
  CONSENT_PAGE_AGENT: 'LFG - eTicket - Consent Agent',
  CONSENT_PAGE_PI: 'LFG - eTicket - Consent Primary Insured',
  CONSENT_PAGE_INSURED_OWNER: 'LFG - eTicket - Consent Insured Owner',
  CONSENT_PAGE_OWNER: 'LFG - eTicket - Consent Owner',
  CONSENT_PAGE_COOWNER: 'LFG - eTicket - Consent Co-Owner',
  CONSENT_PAGE_COOWNER2: 'LFG - eTicket - Consent Co-Owner 2',
  CONSENT_PAGE_COOWNER3: 'LFG - eTicket - Consent Co-Owner 3',
  CONSENT_PAGE_INSURED_B: 'LFG - eTicket - Consent Proposed B',
  CONSENT_PAGE_ADDITIONAL_INSURED: 'LFG - eTicket - Consent Additional Insured',
  ELECTRONIC_SIGNATURE: 'LFG - eTicket - Electronic Signature',
  REVIEW_AND_SUBMIT: 'LFG - eTicket - Review and submit',
  VOICE_CONFIRM_THANK_YOU: 'LFG - eTicket - Voice thank you',

  POLICY_INFO_MG: 'LFG - MGeAPP - Policy Information'
};
