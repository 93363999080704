export class EticketDashboardTableConfig {
    tableData = {
      config: {
        tableId: 'eticket-dbResult-table',
        tableClass: 'eticket-dbResult-table',
        sorting: true,
        pagination: true,
        rowsPerPage: 10,
        currentPage: 0,
        defaultSortOrder: 'desc',
        clickSortOrder: 'asc',
        tableFoot: false,
        handleSubDetailRow: false,
        noRecordsText: 'No records found'
      },
      column: [
        {
          displayText: '<span aria-label="No value" class="hiddenTh">checkbox</span>',
          columnKey: 'checkbox',
          columnFlag: 'checkbox',
          isAction: 'noaction',
          pipeName: null,
          sorting: false,
          align: 'center',
          classNameTh: '',
          type: 'htmlText',
          classNameTd: 'lfg-checkbox eticket-dbResult-table__checkbox'
        },
        {
          displayText: 'INSURED',
          columnKey: 'insuredName',
          columnFlag: 'insuredName',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          isAction: 'noaction',
          classNameTh: 'eticket-dbResult-table-th_clntName',
          classNameTd: 'result-table-td_clntName'
        },
        {
          displayText: '<i class="far fa-question-circle table-th_status_icon status-help-icon" (click)="openModal($event)"></i> STATUS',
          columnKey: 'status',
          columnFlag: 'status',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          sortBy: 'status',
          align: 'left',
          dataType: 'string',
          classNameTh: 'eticket-dbResult-table-th_policy',
          classNameTd: 'result-table-td_policy'
        },
        {
          displayText: 'PRODUCT TYPE',
          columnKey: 'productName',
          columnFlag: 'productName',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          sortBy: 'productName',
          align: 'left',
          dataType: 'string',
          classNameTh: 'eticket-dbResult-table-th_prod',
          classNameTd: 'result-table-td_prod'
        },
        {
          displayText: 'LAST ACTIVITY DATE',
          columnKey: 'lastActivityDate',
          columnFlag: 'lastActivityDate',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: true,
          align: 'left',
          classNameTh: 'eticket-dbResult-table-th_writingAgent',
          classNameTd: 'result-table-td_writingAgent'
        },
        {
          displayText: 'DETAILS',
          columnKey: 'caseDetails',
          columnFlag: 'caseDetails',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          align: 'left',
          dataType: 'string',
          classNameTh: 'eticket-dbResult-table-th_acct',
          classNameTd: 'result-table-td_acct'
        },
        {
          displayText: 'POLICY NUMBER',
          columnKey: 'policyNumber',
          columnFlag: 'policyNumber',
          isAction: 'noaction',
          pipeName: null,
          type: 'htmlText',
          sorting: false,
          align: 'left',
          dataType: 'string',
          classNameTh: 'eticket-dbResult-table-th_acct',
          classNameTd: 'result-table-td_acct'
        },
      ],
      data: [ ]
    };
  }
