import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, FundAllocationConst } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-fund-allocation-vul',
  templateUrl: './fund-allocation-vul.component.html',
  styleUrls: ['./fund-allocation-vul.component.scss']
})
export class FundAllocationVulComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  tabStatusOnInit: TabStatus;

  vulOneFundAllocationData: Question[] = [];
  vulOneFundAllocationData2: Question[] = [];
  vulOneFundAllocationData3: Question[] = [];
  vulOneFundAllocationData4: Question[] = [];
  vulOneFundAllocationCombined: Question[] = [];
  fundAllocationData: any[] = [];
  isResetClicked = false;
  isfundsValid = true;

  vulOneFundAllocationForm: FormGroup;
  optionIndex = -1;

  formValid = true;
  formHasNoErrors = true;
  placeholders = {};

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  ErrMessage = ErrorMessage;

  totalFundPercentage = 0;
  openAccordion = [false, false, false];

  selectedPortfolio: string;
  selectedAllocation: string;

  activePortFolioDataList = [];
  hybridPortFolioDataList = [];
  currentlySelectedPortFolioDataList = [];
  activePortFolioQuesId: string;
  tabIndexSelected = 0;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.vulOneFundAllocationForm);
      this.defaultPageLoadService.disableFormIfLocked(this.vulOneFundAllocationForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
      this.getPortFolioFundValues();
      this.initializePortfolio();
      this.calculatetotalFundPercentage();
    }
  }

  private buildFormData(): any {
    this.vulOneFundAllocationData = this.questionsData.pages[0].questions;
    this.vulOneFundAllocationData2 = this.questionsData.pages[1]?.questions;
    this.vulOneFundAllocationData3 = this.questionsData.pages[2]?.questions;
    this.vulOneFundAllocationData4 = this.questionsData.pages[3]?.questions;
    this.vulOneFundAllocationCombined = this.vulOneFundAllocationData.concat(
      this.vulOneFundAllocationData2, this.vulOneFundAllocationData3, this.vulOneFundAllocationData4);
    const form = this.formgeneratorService.createFormControls(this.vulOneFundAllocationCombined);
    this.vulOneFundAllocationForm = this.fb.group(form);
    this.fundAllocationData = [this.vulOneFundAllocationData2, this.vulOneFundAllocationData3, this.vulOneFundAllocationData4];
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.calculatetotalFundPercentage();
      this.checkFormStatus();
    }, 200);
    this.vulOneFundAllocationForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.calculatetotalFundPercentage();
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid && (this.totalFundPercentage === 100 || this.selectedAllocation?.length > 0)
        && ((!this.selectedAllocation && this.isfundsValid) || (this.selectedAllocation?.length > 0)),
      formHasNoErrors: this.formHasNoErrors,
      form: this.vulOneFundAllocationForm,
      data: null,
    };
    for (const data of this.vulOneFundAllocationCombined) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }


  addFundCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }


  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  emitStatus(): void {
    const vulOneFundAllocationObj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'vulOneFundAllocationForm',
      gridForm: true,
      isFormChange: this.vulOneFundAllocationForm.dirty || this.isResetClicked || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.vulOneFundAllocationForm),
      fundList: this.selectedAllocation ? this.currentlySelectedPortFolioDataList : [],
      allocationType: this.selectedAllocation,
    };
    this.isResetClicked = false;
    this.formStatus.emit(vulOneFundAllocationObj);
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  resetForm(quesText: string): void {
    this.isResetClicked = true;
    if (quesText?.toLowerCase().indexOf('fund') > -1) {
      this.resetFundFields();
    } else {
      this.resetVulOneForm();
    }
    this.defaultPageLoadService.logButtonClick('reset all selections');
  }

  private calculatetotalFundPercentage(): void {
    this.totalFundPercentage = 0;
    if (!this.selectedAllocation) {
      Object.keys(this.vulOneFundAllocationForm.controls).forEach(key => {
        if (key.indexOf(FundAllocationConst.fundQuesXmlTag) > -1) {
          this.updatePercentage(key);
          this.totalFundPercentage = this.totalFundPercentage + +(this.vulOneFundAllocationForm.get(key).value);
        }
      });
    } else {
      this.totalFundPercentage = 100;
    }

  }

  private updatePercentage(key: string): void {
    if (+(this.vulOneFundAllocationForm.get(key)?.value > 100)) {
      this.vulOneFundAllocationForm.get(key).setValue('100');
    }
  }

  toggleAccordionStatus(index: number): void {
    this.openAccordion.forEach((stat, i) => {
      if (i !== index) {
        this.openAccordion[i] = false;
      } else {
        this.openAccordion[index] = !this.openAccordion[index];
      }
    });
  }

  onPortFolioSelection(selectedPortfolio: string, isMobileFirstLoad?: boolean): void {
    if (!isMobileFirstLoad) {
      this.vulOneFundAllocationForm.get(this.activePortFolioQuesId)?.reset();
    }
    this.totalFundPercentage = 0;
    this.isfundsValid = true;
    this.selectedPortfolio = selectedPortfolio;
    this.updatePortFolioTableDataList();
    this.reinitializeModalPortfolioVal();
    this.disableIndividualFundSelectOption();
  }

  onAllocationRadioChange(selectedAllocation: string): void {
    this.selectedAllocation = selectedAllocation;
    this.setOptionIndexForPortfolioTable();
  }

  onAllocationRadioChangeMobile(selectedAllocation: string): void {
    if (!this.selectedPortfolio) {
      this.onPortFolioSelection(selectedAllocation.indexOf('hybrid') > -1 ? 'hybrid' : 'active', true);
    }
    this.onAllocationRadioChange(selectedAllocation);
  }

  private getPortFolioFundValues(): void {
    this.vulOneFundAllocationData.forEach(ques => {
      if (ques.subText === 'PORTFOLIO_TABLE') {
        const portfolio = ques.questionText.split(';');
        this.activePortFolioDataList = this.getPortFolioDataList(portfolio[0]);
        this.hybridPortFolioDataList = this.getPortFolioDataList(portfolio[1]);
      }
    });
    this.populateFundLabels();
  }

  private getPortFolioDataList(portFolioData: string): any[] {
    const tempList = [];
    const portFolioDataRawList = portFolioData.split(',');
    portFolioDataRawList.forEach(obj => {
      const portFolioDataRaw = obj.split('=');
      const portFolioDataRawPercent = portFolioDataRaw[1].split(':');
      tempList.push({
        fundCode: portFolioDataRaw[0],
        fifty: portFolioDataRawPercent[0],
        sixty: portFolioDataRawPercent[1],
        seventy: portFolioDataRawPercent[2],
      });
    });
    return tempList;
  }

  handleTabChange(event: any): void {
    if (!this.isResetClicked) {
      this.tabIndexSelected = event.tabIndex;
      const selectedPortfolio = this.tabIndexSelected === 1 ? 'hybrid' : 'active';
      this.onPortFolioSelection(selectedPortfolio);
    }
  }

  private populateFundLabels(): void {
    this.populateDataFor(this.activePortFolioDataList);
    this.populateDataFor(this.hybridPortFolioDataList);
    this.currentlySelectedPortFolioDataList = [...this.activePortFolioDataList];
  }

  private populateDataFor(portFolioDataList: any[]): void {
    portFolioDataList.forEach(fund => {
      this.populateFundLabelFromQuesData(this.vulOneFundAllocationCombined, fund);
    });
  }

  private populateFundLabelFromQuesData(quesData: Question[], fund): void {
    quesData.forEach(ques => {
      if (ques.notes === 'FundSelect-' + fund.fundCode) {
        fund.fundLabel = ques.questionText;
      }
    });
  }

  private initializePortfolio(): void {
    this.activePortFolioQuesId = this.vulOneFundAllocationData.filter(ques => {
      return ques.subText === 'PORTFOLIO_SELECTION';
    })[0].fieldId;
    this.selectedAllocation = this.vulOneFundAllocationForm.get(this.activePortFolioQuesId)?.value;
    if (this.selectedAllocation?.indexOf('active') > -1) {
      this.selectedPortfolio = 'active';
      this.tabIndexSelected = 0;
    } else if (this.selectedAllocation?.indexOf('hybrid') > -1) {
      this.selectedPortfolio = 'hybrid';
      this.tabIndexSelected = 1;
    }
    this.setOptionIndexForPortfolioTable();
    this.updatePortFolioTableDataList();
    if (this.selectedPortfolio) {
      this.disableIndividualFundSelectOption();
    }
    this.checkIfFundAssignedIsValid();
  }

  private setOptionIndexForPortfolioTable(): void {
    if (this.selectedAllocation.indexOf('50') > -1) {
      this.optionIndex = 1;
    } else if (this.selectedAllocation.indexOf('60') > -1) {
      this.optionIndex = 2;
    } else if (this.selectedAllocation.indexOf('70') > -1) {
      this.optionIndex = 3;
    } else {
      this.optionIndex = -1;
    }
  }

  private updatePortFolioTableDataList(): void {
    this.currentlySelectedPortFolioDataList = this.selectedPortfolio === 'hybrid'
      ? [...this.hybridPortFolioDataList] : [...this.activePortFolioDataList];
  }

  private resetVulOneForm(): void {
    // reset model portfolio section
    this.selectedPortfolio = '';
    this.reinitializeModalPortfolioVal();
    this.tabIndexSelected = 0;
    this.currentlySelectedPortFolioDataList = [...this.activePortFolioDataList];
    this.vulOneFundAllocationForm.get(this.getFieldIdByXmlTag(FundAllocationConst.portfolioSelection))?.reset();
    this.updateFundSelectionFieldStatus(false);
  }

  private disableIndividualFundSelectOption(): void {
    this.updateFundSelectionFieldStatus(true);
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private resetFundFields(): void {
    this.updateFundSelectionFieldStatus(false, true);
    this.isfundsValid = true;
  }

  private updateFundSelectionFieldStatus(isDisable: boolean, isResetRequired?: boolean): void {
    Object.keys(this.vulOneFundAllocationForm.controls).forEach(key => {
      if (key.indexOf(FundAllocationConst.fundQuesXmlTag) > -1) {
        if (isResetRequired) {
          this.vulOneFundAllocationForm.get(key).reset();
        } else {
          if (isDisable) {
            this.vulOneFundAllocationForm.get(key).reset();
            this.vulOneFundAllocationForm.get(key).disable();
          } else {
            this.vulOneFundAllocationForm.get(key).enable();
          }
        }
      }
    });
  }

  getTotalForFunds(quesList: Question[]): number {
    let total = 0;
    quesList.forEach(ques => {
      const val = this.vulOneFundAllocationForm.get(ques.fieldId)?.value;
      total = total + +(val ?? 0);
    });
    return total;
  }

  private checkIfFundAssignedIsValid(): void {
    const tier3Total = this.getTotalForFunds(this.vulOneFundAllocationData4);
    if (tier3Total > 0) {
      if (this.getTotalForFunds(this.vulOneFundAllocationData2) < 25) {
        this.isfundsValid = false;
      } else {
        this.isfundsValid = true;
      }
    } else {
      this.isfundsValid = true;
    }
  }

  fundUpdated(): void {
    this.checkIfFundAssignedIsValid();
  }

  private reinitializeModalPortfolioVal(): void {
    this.optionIndex = -1;
    this.selectedAllocation = undefined;
  }
}
