import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';

import { LfgButtonTrayModule } from '@ng/lfg-button-tray';
import { LfgCommonUtilitiesModule } from '@ng/lfg-common-utilities';
import { LfgFormWidgetsModule } from '@ng/lfg-form-widgets';
import { LfgModalPopupModule } from '@ng/lfg-modal-popup';
import { LfgTabsModule } from '@ng/lfg-tabs';
import { LfgDirectivesModule } from '@ng/lfg-directives';
import { LfgCalendarModule } from '@ng/lfg-calendar';
import { LfgTooltipModule } from '@ng/lfg-tooltip';
import { LfgAccordionModule } from '@ng/lfg-accordion';
import { LfgTablesModule } from '@ng/lfg-tables';
import { LfgOverlayModule } from '@ng/lfg-overlay';
import { ServiceInvoker } from './services/service-invoker';
import { ServiceConfig } from '../../config/service-config';
import { CombinePipe } from './pipes/combine.pipe';
import { RouterModule } from '@angular/router';
import { SearchPipe } from './pipes/search.pipe';
import { CurrencyFormatDirective } from './directives/currency-format.directive';
import { NoSpecialSymbolsDirective } from './directives/no-special-symbols.directive';
import { ValidateOnBlurDirective } from './directives/validate-on-blur.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { PercentageFormatDirective } from './directives/percentage-format.directive';
import { ProgressOverlayComponent } from './components/progress-overlay/progress-overlay.component';
import { HelpHintComponent } from './components/help-hint/help-hint.component';
import { DocusignThankYouComponent } from './components/docusign-thank-you/docusign-thank-you.component';
import { LfgLoaderModule } from '@ng/lfg-loader';
import { UnauthorizedErrorComponent } from './components/unauthorized-error/unauthorized-error.component';
import { SystemMessageComponent } from './components/system-message/system-message.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    LfgButtonTrayModule,
    LfgCommonUtilitiesModule,
    LfgFormWidgetsModule,
    LfgModalPopupModule,
    LfgTabsModule,
    LfgDirectivesModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    NgxMaskModule.forRoot(),
    LfgCalendarModule,
    LfgTooltipModule,
    LfgAccordionModule,
    LfgTablesModule,
    LfgOverlayModule,
    LfgLoaderModule
  ],
  declarations: [
    CombinePipe,
    SearchPipe,
    CurrencyFormatDirective,
    PercentageFormatDirective,
    NoSpecialSymbolsDirective,
    ValidateOnBlurDirective,
    TruncatePipe,
    NumberFormatPipe,
    ProgressOverlayComponent,
    HelpHintComponent,
    DocusignThankYouComponent,
    UnauthorizedErrorComponent,
    SystemMessageComponent,
    ConfirmationModalComponent
  ],
  exports: [
    RouterModule,
    CommonModule,
    LfgButtonTrayModule,
    LfgCommonUtilitiesModule,
    LfgFormWidgetsModule,
    LfgModalPopupModule,
    LfgTabsModule,
    LfgDirectivesModule,
    NgxMaskModule,
    ReactiveFormsModule,
    LfgAccordionModule,
    LfgCalendarModule,
    LfgTooltipModule,
    LfgTablesModule,
    LfgOverlayModule,
    CombinePipe,
    SearchPipe,
    TruncatePipe,
    NumberFormatPipe,
    CurrencyFormatDirective,
    PercentageFormatDirective,
    NoSpecialSymbolsDirective,
    ValidateOnBlurDirective,
    ProgressOverlayComponent,
    HelpHintComponent,
    LfgLoaderModule,
    SystemMessageComponent,
    ConfirmationModalComponent
  ],
  providers: [ServiceInvoker, ServiceConfig, SearchPipe],
})
export class SharedModule {}
