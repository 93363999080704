<section class="fund-allocation-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Fund Allocation
    </h1>

    <ng-container *ngIf="!disableForm">
      <lfg-alert-message *ngIf="isTabVisited && (!formValid || totalFundPercentage != 100)" type="error">{{errorMessage}}</lfg-alert-message>
      <lfg-alert-message *ngIf="totalFundPercentage != 100 && totalFundPercentage != 0" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
      <lfg-alert-message *ngIf="totalFundPercentage == 100 && showDollarCostFundError" type="error">{{ErrMessage.DCA_FUND_ERROR}}</lfg-alert-message>
    </ng-container>
    <section>
      <form class="form-group" [formGroup]="fundAllocationForm" id="fundAllocationForm" method="post" *ngIf="fundAllocationData && fundAllocationData.length > 0">

        <ng-container class="form-control-parent" *ngFor="let data of fundAllocationData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Header' && data.subText != 'Sub-Header' && data.subText != 'table-header' && data.subText != 'table-subheader' && data.subText != 'table-label' && data.subText != 'PORTFOLIO_TABLE'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && (data.subText == 'Header' || data.subText == 'Sub-Header')" [ngClass]="{'header-container': data.subText == 'Header' || data.subText == 'Sub-Header'}">
            <div>
              <p><i class="fas fa-list-alt" aria-hidden="true" *ngIf="data.subText == 'Header'"></i><span>{{ data.questionText | uppercase}}</span></p>
            </div>
            <div class="btn-reset-container" *ngIf="data.subText == 'Sub-Header'"> <a class="btn-link btn-reset" [ngClass]="{'btn--link-disabled': disableForm}" id="resetBtn" type="button" (click)="resetForm(data.questionText)">
                <i class="fas fa-undo reset-icon" aria-hidden="true"></i> Reset all selections </a></div>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Element for portfolio selection desktop-->
          <div class="portfolio-selection-container display-in-desktop" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.subText == 'PORTFOLIO_SELECTION' && data.questionText === 'Passive Portfolio'">
            <div class="portfolio-sect-holder">
              <div class="portfolio-section portfolio-section-a" [ngClass]="{'selected-section' : selectedPortfolio === 'passive'}">
                <div class="portfolio-header-container">
                  <p class="portfolio-header">Passive portfolio</p><a class="portfolio-link" (click)="onPortFolioSelection('passive')" *ngIf="selectedPortfolio !== 'passive'" [ngClass]="{'btn--link-disabled': disableForm}">View</a>
                </div>
                <div class="inline-radio-btns">
                  <label class="radio-input-label lfg-radio" *ngFor="let option of data.questionOption" [ngClass]="{'radio-button-checked': selectedAllocation === option.value}">
                    <input [attr.aria-label]="option.value" tabindex="0" [id]="option.value" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" [value]="option.value" (change)="onAllocationRadioChange(option.value)" [attr.disabled]="selectedPortfolio !== 'passive' ?  true: null" />
                    <label [attr.for]="option.value" [ngClass]="{'not-selected' : selectedPortfolio !== 'passive'}">{{option.description}}</label>
                  </label>
                </div>
              </div>
              <div [ngClass]="{'selection-down-arrow' : selectedPortfolio === 'passive', 'arrowDiv': selectedPortfolio !== 'passive'}"></div>
            </div>
          </div>

          <!-- Table for portfolio selection -->
          <div class="portfolio-table-container" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'PORTFOLIO_TABLE'">
            <p id="portfolio_table"></p>
            <table aria-describedby="portfolio_table">
              <caption class="visually-hidden"></caption>
              <tr class="portfolio-table-head display-in-desktop">
                <th [ngClass]="{'col-selected-prev': optionIndex - 1 === 0 }" id="col1">{{(selectedPortfolio ? selectedPortfolio : 'passive') | uppercase}} {{' Portfolios fund' | uppercase}}</th>
                <th class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 1, 'col-selected col-head': optionIndex === 1 }" id="col2">CONSERVATIVE</th>
                <th class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 2, 'col-selected col-head': optionIndex === 2 }" id="col3">MODERATE</th>
                <th class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 3, 'col-selected col-head': optionIndex === 3 }" id="col4">GROWTH</th>
                <th class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 4, 'col-selected col-head': optionIndex === 4 }" id="col5">AGGRESSIVE GROWTH</th>
              </tr>
              <tr *ngFor="let portfolio of passivePortFolioDataList; let ind=index" class="portfolio-table-row">
                <td [ngClass]="{'col-selected-prev': optionIndex - 1 === 0 }">{{portfolio.fundLabel}}</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 1, 'col-selected': optionIndex === 1 }">{{portfolio.conservative}}%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 2, 'col-selected': optionIndex === 2 }">{{portfolio.moderate}}%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 3, 'col-selected': optionIndex === 3 }">{{portfolio.growth}}%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 4, 'col-selected': optionIndex === 4 }">{{portfolio.aggressiveGrowth}}%</td>
              </tr>
              <tr>
                <td [ngClass]="{'col-selected-prev': optionIndex - 1 === 0}">Total</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 1, 'col-selected col-last': optionIndex === 1 }">100%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 2, 'col-selected col-last': optionIndex === 2 }">100%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 3, 'col-selected col-last': optionIndex === 3 }">100%</td>
                <td class="align-center" [ngClass]="{'col-selected-prev': optionIndex - 1 === 4, 'col-selected col-last': optionIndex === 4 }">100%</td>
              </tr>
            </table>

          </div>

          <!-- Funds table -->
          <div class="funds-table-container" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-header'">
            <div class="total-percent">
              <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
            </div>
            <div class="funds-table funds-table-header">
              <div class="table-column col-1">{{data.questionText | uppercase}} </div>
              <div class="table-column col-2">INPUT %</div>
            </div>
          </div>
          <div class="funds-table funds-table-subheader" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-subheader'">
            <div class="table-column">{{data.questionText | uppercase}} </div>
          </div>
          <div class="funds-table funds-table-subheader fund-table-label" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.HDR && data.subText == 'table-label'">
            <div class="table-column">{{data.questionText}} </div>
          </div>
          <div class="funds-table funds-table-row" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes && data.notes.indexOf('FundSelect') > -1">
            <div class="table-column col-1">{{data.questionText}} </div>
            <div class="table-column col-2">
              <label for="{{data.fieldId}}" id="fund-field-label-{{data.fieldId}}">
                <span class="visually-hidden">Label for the fund input</span>
                <input lfgInput name="{{ data.questionText }}" id="{{data.fieldId}}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data.required == 'true'">
              </label>
            </div>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('FundSelect') == -1)">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addFundCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, fundAllocationForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.subText !== 'PORTFOLIO_SELECTION'" [ngClass]="{'radio--err': (fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText }}</p>
              <div class="radio-btn-rect custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onFundRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onFundRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data, fundAllocationForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <!-- Question Info for DCA -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.notes == 'ADD_INFO'">
            <lfg-alert-message type="info">{{ Message.DCA_INFO_MESSAGE }}</lfg-alert-message>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addFundCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleFundSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err':(fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid)}">
            </lfg-dropdown>
            <lfg-error *ngIf="fundAllocationForm.get(data.fieldId).touched && fundAllocationForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
          </div>

          <!-- Question Info -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Question Alert -->
          <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Alert'">
            <lfg-alert-message type="error">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && fundAllocationForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let fundAllocationChild1 of data.reflexiveQuestionAL; index as fundAllocationChild1ix">
              <ng-container *ngIf="nestedQuestionCheckFundInfo(data, fundAllocationChild1, parentAnswer)">

                <!-- Question Info -->
                <div class="question-info" aria-live="assertive" *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.HDR && fundAllocationChild1.subText == 'Information'">
                  <lfg-alert-message type="info">{{ fundAllocationChild1.questionText }}</lfg-alert-message>
                </div>

                <!-- Question alert -->
                <div class="question-info" aria-live="assertive" *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.HDR && fundAllocationChild1.subText == 'Alert'">
                  <lfg-alert-message type="warning">{{ fundAllocationChild1.questionText }}</lfg-alert-message>
                </div>

                <!-- Form element for text  -->
                <div class="form--control" aria-live="assertive" *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(fundAllocationChild1) }}" placeholder="{{ addFundCorrectPlaceHolder(fundAllocationChild1, 'placeHolder') }}" type='text' formControlName='{{ fundAllocationChild1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="fundAllocationChild1.maxLength" [required]="fundAllocationChild1.required == 'true'">
                    <lfg-error *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).errors?.percentError" message="Split must be equal to 100%."></lfg-error>
                    <lfg-error *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).errors?.textError" message="Please enter a valid {{fundAllocationChild1.questionText | lowercase}}."></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" aria-live="assertive" *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.SELECT " [ngClass]="{'drop-down-medium': fundAllocationChild1.questionText.length > 20}">
                  <lfg-dropdown formControlName="{{ fundAllocationChild1.fieldId }}" id="{{displayIdCreation(fundAllocationChild1)}}_{{fundAllocationChild1ix}}" [options]="alldropDownOptions[fundAllocationChild1.fieldId]" placeholder="{{ addFundCorrectPlaceHolder(fundAllocationChild1, 'placeHolder') }}" (selectionChanged)="handleFundSelectionChange(fundAllocationChild1, fundAllocationChild1ix)" [required]="fundAllocationChild1.required == 'true'" [ngClass]="{'dropdown--err':(fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid" message="Please select a valid option."></lfg-error>
                </div>

                <!-- Form element for radio question -->
                <div aria-live="assertive" class="lfg-customRadio-field radio-btn-custom" *ngIf="fundAllocationChild1.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid)}">
                  <span class="lfg-radio-section">
                    <p class="question-radio_text">{{ fundAllocationChild1.questionText }}</p>
                    <div class="radio-btn-rect custom-radio-button">
                      <input [attr.aria-label]="fundAllocationChild1.questionOption[0].description" tabindex="0" id="yes_{{ fundAllocationChild1.fieldId }}" type="radio" formControlName="{{ fundAllocationChild1.fieldId }}" name="{{ fundAllocationChild1.fieldId }}" value="{{ fundAllocationChild1.questionOption[0].value }}" required="{{fundAllocationChild1.required}}" (change)="onFundRadioChange(fundAllocationChild1)" />
                      <label for="yes_{{ fundAllocationChild1.fieldId }}">{{ fundAllocationChild1.questionOption[0].description }}</label>
                      <input [attr.aria-label]="fundAllocationChild1.questionOption[1].description" tabindex="0" id="no_{{ fundAllocationChild1.fieldId }}" type="radio" formControlName="{{ fundAllocationChild1.fieldId }}" name="{{ fundAllocationChild1.fieldId }}" value="{{ fundAllocationChild1.questionOption[1].value }}" required="{{fundAllocationChild1.required}}" (change)="onFundRadioChange(fundAllocationChild1)">
                      <label for="no_{{ fundAllocationChild1.fieldId }}">{{ fundAllocationChild1.questionOption[1].description }}</label>
                    </div>
                  </span>
                  <lfg-error *ngIf="fundAllocationForm.get(fundAllocationChild1.fieldId).touched && fundAllocationForm.get(fundAllocationChild1.fieldId).invalid " message="{{ getErrorMsg(fundAllocationChild1, fundAllocationForm.get(fundAllocationChild1.fieldId)) }}"></lfg-error>
                </div>

                <!-- Nestion question Level 2 -->
                <div *ngIf="fundAllocationChild1.hasReflexive == 'true' && fundAllocationForm.get(fundAllocationChild1.fieldId).value as parentAnswer" class="nested-ques-level">
                  <ng-container *ngFor="let fundAllocationChild2 of fundAllocationChild1.reflexiveQuestionAL; index as fundAllocationChild2ix">
                    <ng-container *ngIf="nestedQuestionCheckFundInfo(fundAllocationChild1, fundAllocationChild2, parentAnswer)">

                      <!-- Form element for Drop Down -->
                      <div class="form--control form-dropDown" aria-live="assertive" *ngIf="fundAllocationChild2.controlTypeDesc == appConfig.fieldTypes.SELECT " [ngClass]="{'drop-down-medium': fundAllocationChild2.questionText.length > 24}">
                        <lfg-dropdown formControlName="{{ fundAllocationChild2.fieldId }}" id="{{displayIdCreation(fundAllocationChild2)}}_{{fundAllocationChild2ix}}" [options]="alldropDownOptions[fundAllocationChild2.fieldId]" placeholder="{{ addFundCorrectPlaceHolder(fundAllocationChild2, 'placeHolder') }}" (selectionChanged)="handleFundSelectionChange(fundAllocationChild2, fundAllocationChild2ix)" [required]="fundAllocationChild2.required == 'true'" [ngClass]="{'dropdown--err':(fundAllocationForm.get(fundAllocationChild2.fieldId).touched && fundAllocationForm.get(fundAllocationChild2.fieldId).invalid)}">
                        </lfg-dropdown>
                        <lfg-error *ngIf="fundAllocationForm.get(fundAllocationChild2.fieldId).touched && fundAllocationForm.get(fundAllocationChild2.fieldId).invalid" message="Please select a valid option."></lfg-error>
                      </div>

                    </ng-container>
                  </ng-container>
                </div>

              </ng-container>
            </ng-container>
          </div>

        </ng-container>

        <div class="additional-funds-section" (click)="toggleAccordionStatus()">
          <span class="accrd-header">
            Expand to display additional funds
          </span>
          <span class="icon-container">
            <i class="fa arrow-button" [ngClass]="{'fa-chevron-down' : !openAccordion , 'fa-chevron-up' : openAccordion}" aria-label="Expand to display additional funds" tabindex="0"></i>
          </span>
        </div>

        <div *ngIf="openAccordion" class="accordion-content">
          <ng-container class="form-control-parent" *ngFor="let data2 of fundAllocationData2; let ind = index;">
            <!-- Funds table -->
            <div class="funds-table funds-table-header" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-header'">
              <div class="table-column col-1">{{data2.questionText | uppercase}} </div>
              <div class="col-2 table-column">INPUT %</div>
            </div>
            <div class="funds-table-subheader funds-table" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-subheader'">
              <div class="table-column">{{data2.questionText | uppercase}} </div>
            </div>
            <div class="funds-table-subheader funds-table fund-table-label" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.HDR && data2.subText == 'table-label'">
              <div class="table-column">{{data2.questionText}} </div>
            </div>
            <div class="funds-table funds-table-row" *ngIf="data2.controlTypeDesc === appConfig.fieldTypes.TEXT && data2.notes && data2.notes.indexOf('FundSelect') > -1">
              <div class="col-1 table-column">{{data2.questionText}} </div>
              <div class="table-column col-2">
                <label for="{{data2.fieldId}}" id="fund-field-label-{{data2.fieldId}}">
                  <span class="visually-hidden">Label for the fund input</span>
                  <input lfgInput name="{{ data2.questionText }}" aria-live="off" id="{{data2.fieldId}}" type='text' formControlName='{{ data2.fieldId }}' autocomplete="off" appPercentageFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" [maxlength]="3" [required]="data2.required == 'true'">
                </label>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="total-percent percent-bottom">
          <span>TOTAL: <span [ngClass]="totalFundPercentage === 100 ? 'success-color' : 'error-color'"><strong>{{totalFundPercentage}}%</strong></span></span>
        </div>

      </form>
    </section>
    <lfg-alert-message *ngIf="totalFundPercentage != 100 && totalFundPercentage != 0" type="error">{{ErrMessage.FUND_PERCENTAGE_ERROR}}</lfg-alert-message>
    <lfg-alert-message *ngIf="totalFundPercentage == 100 && showDollarCostFundError" type="error">{{ErrMessage.DCA_FUND_ERROR}}</lfg-alert-message>
  </div>
</section>
