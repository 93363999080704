import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LfgLoaderService } from '@ng/lfg-loader';
import { FileDetails, Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { AdditionalInfoConstant, ErrorMessage, Message } from 'src/config/constants';
import { TAB, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent implements OnInit {
  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  questionsData;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  alldropDownOptions = {};
  additionalInfoData: Question[] = [];
  additionalInfoForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  setTimeFocus = null;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  fileInfoMessage = Message.FILE_INFO;
  modalConfig: any;
  showLoader = false;

  openAccordion = {}; // for opening and closing accordion
  files = {}; // contains files uploaded by user (valid/invalid)
  fileName = {}; // contains files names uploaded by user (valid/invalid)
  isQuestionValid = {}; // contains true/false value depending upon the file status
  fileFromRepo = {}; // contains filename loaded from db
  hasFileError = {}; // contains files having error

  activeAccordionId: string;
  activeData: Question;
  unselectCheckbox = false;
  removeFileFormControl: FormControl;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private pageLoadService: DefaultPageload,
    private fileUploadService: FileUploadService,
    private loaderService: LfgLoaderService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private tabService: TabDetailsService,
    private userService: UserDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.disableVoidedCheckBasedOnCondition();
      this.isTabVisited = this.pageLoadService.updateFormErrors(this.additionalInfoForm);
      this.updateFileInfo();
      this.defaultPageLoadService.disableFormIfLocked(this.additionalInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
    this.modalConfig = {
      header: true,
      state: false,
      footer: true
    };
  }

  viewFile(): void {
    if (this.files[this.activeAccordionId]) {
      this.utilsService.toBase64(this.files[this.activeAccordionId]).then(pdfData => {
        this.utilsService.openPdf(pdfData);
      });
    } else if (this.fileFromRepo[this.activeAccordionId]) {
      this.getFile(this.activeAccordionId);
    }
    this.defaultPageLoadService.logLinkClick('view file');
  }

  private updateFiles(file: File, id: string): void {
    this.files[id] = file;
    this.fileName[id] = file.name;
    delete this.hasFileError[id];
  }

  onFileSelected(event, formControl: FormControl, id: string, data: Question): void {
    const file: File = event.target.files[0];
    if (file) {
      if (file?.size > 6 * 1024 * 1024) {
        formControl.setErrors({ fileError: true });
      }
      if (formControl.valid) {
        this.addFileDetails(file, id);
        this.defaultPageLoadService.logButtonClick('upload file');
      } else {
        this.removeFileDetails(data, id);
      }
    } else {
      this.removeFileDetails(data, id);
    }

  }

  closeModalPopup(): any {
    this.modalConfig.state = false;
    this.defaultPageLoadService.logButtonClick('remove attachment cancel');
  }

  private buildFormData(): any {
    this.additionalInfoData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.additionalInfoData
    );
    this.additionalInfoForm = this.fb.group(form);
    this.valueChanges();
  }

  valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.additionalInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus(true);
      }, 200);
    });
  }

  checkFormStatus(fileChanged?: boolean): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.additionalInfoForm,
      data: null,
    };
    for (const data of this.additionalInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid && this.checkIfAllCheckBoxValid(),
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'additionalInfoForm',
      // added isTabRevisit in condition as this tab is valid even without entering any answer
      isFormChange: this.additionalInfoForm.dirty || (fileChanged ? true : false) || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.additionalInfoForm),
    };
    this.formStatus.emit(obj);
  }


  removeAttachment(): void {
    if (this.unselectCheckbox) {
      this.unselectTheCheckbox();
    }
    this.modalConfig.state = false;
    delete this.files[this.activeData.xmlTag];
    delete this.fileName[this.activeData.xmlTag];
    delete this.isQuestionValid[this.activeData.xmlTag];
    delete this.fileFromRepo[this.activeData.xmlTag];
    delete this.hasFileError[this.activeData.xmlTag];
    this.removeFile(this.activeData.xmlTag);
    if (this.removeFileFormControl) {
      this.removeFileFormControl.reset();
      this.removeFileFormControl = undefined;
    }
    this.defaultPageLoadService.logButtonClick('remove attachment confirm');
  }

  onCheckboxChange(data: Question): any {
    this.updatedAnswersOnHidden(data);
    if (this.additionalInfoForm.get(data.fieldId).value) {
      this.updateAccordionStatus(data.xmlTag, true);
      this.isQuestionValid[data.xmlTag] = false;
    } else {
      this.updateAccordionStatus(data.xmlTag, false);
      this.isQuestionValid[data.xmlTag] = true;
    }
  }

  onCheckBoxClick(event: any, data: Question): void {
    if (this.files[data.xmlTag] || this.fileFromRepo[data.xmlTag]) {
      event.preventDefault();
      event.stopPropagation();
      this.unselectCheckbox = true;
      this.openModal(event, data);
    } else {
      delete this.hasFileError[data.xmlTag];
    }
  }

  updatedAnswersOnHidden(data): any {
    const value = this.additionalInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(data, value);
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.additionalInfoForm.get(key)?.markAsUntouched();
      }
      this.additionalInfoForm.patchValue(updatedval);
    }
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.additionalInfoForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  nestedQuestionCheckAdditionalInfo(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData.optionChoice === 'Yes{47}Yes') {
        return parent === true;
      } else if (childData.optionChoice === 'No{47}No') {
        return parent === false;
      }
    } else {
      return parent === childData.optionChoice;
    }
  }

  openModal(event: any, data: Question, isRemove?: boolean): void {
    event.preventDefault();
    event.stopPropagation();
    if (isRemove) {
      this.getFormControlForFile(data);
    }
    if (this.files[data.xmlTag] || this.fileFromRepo[data.xmlTag]) {
      this.modalConfig.state = true;
      this.activeData = data;
    }
    this.defaultPageLoadService.logButtonClick('remove attachment');
  }

  private getFormControlForFile(data: Question): void {
    data.reflexiveQuestionAL.forEach(question => {
      if (question.subText === 'File') {
        this.removeFileFormControl = this.additionalInfoForm.get(question.fieldId) as FormControl;
      }
    });
  }

  private updateAccordionStatus(quesId: string, isOpen: boolean): void {
    if (isOpen) {
      this.closeAllAccordion();
      this.activeAccordionId = quesId;
    }
    this.openAccordion[quesId] = isOpen;
  }

  toggleAccordionStatus(quesId: string): void {
    this.updateAccordionStatus(quesId, !this.openAccordion[quesId]);
  }

  private closeAllAccordion(): void {
    Object.keys(this.openAccordion).forEach((key) => {
      this.openAccordion[key] = false;
    });
  }

  private unselectTheCheckbox(): void {
    this.additionalInfoForm.get(this.activeData.fieldId).patchValue(false);
    this.unselectCheckbox = false;
    this.updatedAnswersOnHidden(this.activeData.fieldId);
    this.updateAccordionStatus(this.activeData.xmlTag, false);
    this.isQuestionValid[this.activeData.xmlTag] = true;

  }

  private uploadFile(file: File, xmlTag: string): void {
    this.loaderService.show();
    this.showLoader = true;
    this.utilsService.toBase64(file).then(pdfData => {
      const fileDetails: FileDetails = {
        caseId: this.pageLoadService.getCaseId(),
        fileName: file.name,
        fileSize: file.size.toString(),
        fileType: this.getFileTypeByXmlTag(xmlTag),
        fileData: pdfData
      };
      this.fileUploadService.uploadFile(fileDetails).subscribe((response) => {
        if (response.responseStatus === 'SUCCESS' && response.data) {
          this.isQuestionValid[xmlTag] = true;
          this.updateAnswerFromResponse(response.data, xmlTag);
          this.updateFiles(file, xmlTag);
          console.log('file uploaded successfully');
          this.checkFormStatus(true);
        } else {
          this.hasFileError[xmlTag] = Message.FILE_UPLOAD_ERR;
          console.log('file upload failed');
        }
        this.loaderService.hide();
        this.showLoader = false;
      },
        (_err) => {
          this.hasFileError[xmlTag] = Message.FILE_UPLOAD_ERR;
          this.loaderService.hide();
          this.showLoader = false;
        });
    });
  }

  private removeFile(xmlTag: string): void {
    this.fileUploadService.deleteFile(this.getFileTypeByXmlTag(xmlTag),
      this.pageLoadService.getCaseId()).subscribe((response) => {
        if (response.responseStatus === 'SUCCESS' && response.data) {
          console.log('file deleted successfully');
          this.updateFileName('', xmlTag);
          this.checkFormStatus(true);
        } else {
          console.log('file delete failed');
        }
      });
  }

  private getFile(xmlTag: string): void {
    this.fileUploadService.getFile(this.getFileTypeByXmlTag(xmlTag),
      this.pageLoadService.getCaseId()).subscribe((response) => {
        if (response.responseStatus === 'SUCCESS' && response.data) {
          this.utilsService.openPdf(response.data);
          console.log('Got file from service');
        } else {
          console.log('get file failed');
        }
      });
  }

  private getFileTypeByXmlTag(xmlTag: string): string {
    return this.additionalInfoData.filter((ques) => {
      return ques.xmlTag === xmlTag;
    })[0]?.subText;
  }

  private updateAnswerFromResponse(responseData: any, xmlTag: string): void {
    if (responseData?.fileName) {
      this.updateFileName(responseData.fileName, xmlTag);
    } else {
      this.updateFileName('', xmlTag);
    }
  }

  private updateFileName(fileName: string, xmlTag: string): void {
    this.additionalInfoData.forEach(ques => {
      if (ques.xmlTag === xmlTag) {
        ques.reflexiveQuestionAL.forEach(refQues => {
          if (refQues.subText === 'File') {
            refQues.question_answer = fileName;
          }
        });
      }
    });
  }

  private updateFileInfo(): void {
    this.additionalInfoData.forEach(ques => {
      if (ques.question_answer) {
        ques.reflexiveQuestionAL.forEach(refQues => {
          if (refQues.subText === 'File') {
            if (refQues.question_answer && refQues.question_answer !== '') {
              this.fileName[ques.xmlTag] = refQues.question_answer;
              this.isQuestionValid[ques.xmlTag] = true;
              this.additionalInfoForm.get(refQues.fieldId).markAsUntouched();
              this.additionalInfoForm.get(refQues.fieldId).setErrors(null);
              this.fileFromRepo[ques.xmlTag] = ques.questionText;
              delete this.hasFileError[ques.xmlTag];
            } else {
              this.hasFileError[ques.xmlTag] = Message.FILE_FORMAT_ERR;
            }
          }
        });
      }
    });
  }

  private removeFileDetails(data: Question, id: string): void {
    this.isQuestionValid[id] = false;
    this.activeData = data;
    this.removeAttachment();
    this.hasFileError[id] = Message.FILE_FORMAT_ERR;
  }

  private addFileDetails(file: File, id: string): void {
    this.uploadFile(file, id);
  }

  private checkIfAllCheckBoxValid(): boolean {
    let allFieldsValid = true;
    this.additionalInfoData.forEach(ques => {
      if (ques.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX && ques.question_answer && !this.fileName[ques.xmlTag]) {
        allFieldsValid = false;
      }
    });
    return allFieldsValid;
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private disableVoidedCheckBasedOnCondition(): void {
    if (this.userService.getIsTIASubmitted() || this.tabService.isTabActive(TAB.ELECTRONIC_FUND_TRANSFER)) {
      this.additionalInfoForm.get(this.getFieldIdByXmlTag(AdditionalInfoConstant.voidedCheck))?.enable();
    } else {
      this.additionalInfoForm.get(this.getFieldIdByXmlTag(AdditionalInfoConstant.voidedCheck))?.reset();
      this.additionalInfoForm.get(this.getFieldIdByXmlTag(AdditionalInfoConstant.voidedCheck))?.disable();
    }
  }
}

