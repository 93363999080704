import { Injectable } from '@angular/core';
import { Condition, Product } from '../models/casePage.model';
import { ProductType } from 'src/config/sideNav.config';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

  private productType: string;
  private productName: string;
  private productCondition: Condition;
  private listOfProduct: Product[];
  private marketName: string;
  private duration: string;

  constructor() {
    // write code here
  }

  getProductName(): string {
    return this.productName;
  }

  getProductType(): string {
    return this.productType;
  }

  setProductName(productName: string): void {
    this.productName = productName;
  }

  getDuration(): string {
    return this.duration;
  }

  setDuration(duration: string): void {
    this.duration = duration;
  }

  setProductType(productType: string): void {
    this.productType = productType;
  }

  setProductCondition(): void {
    this.listOfProduct?.forEach(product => {
      if (product.productName === this.productName) {
        this.productCondition = product.productDetail.condition;
        if (this.productType?.toLowerCase() !== ProductType.TERM) {
          this.marketName = product.productDetail.mktName;
          this.duration = '';
        } else if (this.productType?.toLowerCase() === ProductType.TERM) {
          this.updateProductDetailsForTermProducts(this.duration);
        }
      }
    });
  }

  getProductCondition(): Condition {
    return this.productCondition;
  }

  setListOfProduct(products: Product[]): void {
    this.listOfProduct = products;
  }

  getListOfProduct(): Product[] {
    return this.listOfProduct;
  }

  getMarketName(): string {
    return this.marketName;
  }

  setMarketName(marketName: string): void {
    this.marketName = marketName;
  }

  updateProductDetailsForTermProducts(duration: string): void {
    this.duration = duration;
    if (duration && +duration > 0) {
      this.productCondition.ageConditions.forEach(ageCond => {
        if (ageCond.durationList?.includes(+duration)) {
          this.marketName = ageCond.termMktName;
        }
      });
    } else {
      this.marketName = '';
    }
  }

  clearProductDetails(): void {
    this.productType = this.productName = undefined;
    this.productCondition = this.marketName = undefined;
    this.listOfProduct = [];
  }
}
