import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LfgLoaderService } from '@ng/lfg-loader';
import {
  GridInstance,
  Question,
  QuestionOption,
} from 'src/app/shared/models/casePage.model';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { attestValidator } from 'src/app/shared/validators/business-validator';
import { ApplicationConfig } from 'src/config/app.config';
import {
  AgentInfoConstant,
  AgentValidationMessage,
  ErrorMessage,
  Message,
} from 'src/config/constants';
import { ProductType, TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-agent-info',
  templateUrl: './agent-info.component.html',
  styleUrls: ['./agent-info.component.scss'],
})
export class AgentInfoComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  agentInfoData: Question[] = [];
  agentInfoForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  formHasNoErrors = true;
  gridHasNoErrors = false;
  isGridFormValid = true;
  isGridFormChange = false;
  placeholders = {};
  alldropDownOptions = {};
  showAgentAccordion = false;
  isTerm = true;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  AgentValMsg = AgentValidationMessage;
  agentSSNValid = false;
  agentLastNameValid = false;
  disableFirmDropDown = true;
  AgentConst = AgentInfoConstant;
  firmOptions: any;
  totalSplitPercentage = 100;
  selectedFirm: any;
  showAttestRadioButton = false;
  isAgentFormValid = false;
  phoneInfo = Message.PHONE_INFO;

  isTermPolicy: boolean;
  messageForDisplay: string[];
  action: {};
  ssnListFromGridAns: string[] = [];
  firmUpdatedForSSN: string[] = [];
  showLoader = false;

  maxNoOfAgent: number;
  invalidAgents: any[] = [];
  agentList: any[] = [];
  isAgentValidationCalled = false;
  agentValidationErrorOccurred = false;
  duplicateSSNExists = false;

  agentNpn: string;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
    private agentService: AgentAndFirmService,
    private loaderService: LfgLoaderService,
    private userDetailsService: UserDetailsService,
    private productService: ProductDetailsService,
    private tabService: TabDetailsService
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.filterResultsBasedOnBusinessRules();
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.updateFirmListForSecAgents();
      this.buildFormData();
      this.updateAgentInfo();
      this.checkIfMoreAgentExists();
      this.updateFirmRelatedValues();
      this.checkToShowAttestRadioButton();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.agentInfoForm);
      this.updateAgentValidationInd();
      this.defaultPageLoadService.disableFormIfLocked(this.agentInfoForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.agentInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.agentInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.agentInfoData
    );
    this.agentInfoForm = this.fb.group(form);
    this.updateAgentValidationStatus(true);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.agentInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkIfMoreAgentExists();
        this.updateAgentValidationStatus(false);
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  private checkFormStatus(): any {
    this.checkForPercentage();
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.agentInfoForm,
      data: null,
    };
    for (const data of this.agentInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  onAgentRadioChange(data): any {
    this.updatedAgentAnswersOnHidden(data);
  }

  handleAgentSelectionChange(data, _ix?): any {
    this.updateagentNPNValue(data);
    this.updatedAgentAnswersOnHidden(data);
  }

  addAgentCorrectPlaceHolder(data, type): any {
    this.updateBusinessRules(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAgentAnswersOnHidden(data): any {
    const value = this.agentInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.agentInfoForm.get(key)?.markAsUntouched();
      }
      this.agentInfoForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.agentInfoForm.get(data.fieldId)?.value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckAgentInfo(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  validateAgents(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.isAgentValidationCalled = true;
    this.invalidAgents = [];
    const clientState = this.userDetailsService.getInsuredState()?.toUpperCase();
    this.isTermPolicy = this.productService.getProductType()?.toLowerCase() === ProductType.TERM;
    this.messageForDisplay = this.isTermPolicy
      ? [AgentValidationMessage.TERM_AGENTS_FAILED_MESSAGE.replace('${selectedState}', clientState),
      AgentValidationMessage.AGENT_PROCEED_MESSAGE]
      : [AgentValidationMessage.NON_TERM_AGENT_FAILED_MESSAGE.replace('${selectedState}', clientState),
      AgentValidationMessage.AGENT_PROCEED_MESSAGE];
    this.checkIfAgentsValid();
    this.defaultPageLoadService.logButtonClick('validate license and appointment');
  }

  updateModalAction(event): void {
    if (event) {
      this.action = 'closeModal';
    }
  }

  gridformStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    if (this.isGridFormChange && !this.disableForm) {
      this.agentService.setIsAgentsValidated(false);
    }
    this.checkFormStatus();
  }

  emitStatus(): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    this.isAgentFormValid = this.formValid && ((this.showAgentAccordion && this.isGridFormValid) || !this.showAgentAccordion);
    const agentInfoObj = {
      formValid: this.isAgentFormValid && this.agentService.getIsAgentValidated(),
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: 'agentInfoForm',
      gridForm: true,
      isFormChange: this.agentInfoForm.dirty || this.isGridFormChange || this.isAgentValidationCalled || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.agentInfoForm),
    };
    this.isAgentValidationCalled = false;
    this.formStatus.emit(agentInfoObj);
  }

  getAgentValidityStatus(): boolean {
    return this.agentService.getIsAgentValidated();
  }

  checkIfMoreAgentExists(): void {
    const isMoreAgentQuesId = this.getFieldIdByXmlTag(AgentInfoConstant.isMoreAgentQuesId);
    this.showAgentAccordion =
      this.agentInfoForm.get(isMoreAgentQuesId)?.value === 'yes{47}yes';
    if (this.agentInfoForm.get(isMoreAgentQuesId)?.value === 'no{47}no') {
      this.gridAnswers = [];
    }
  }

  checkForPercentage(): void {
    let gridPercent = 0;
    this.gridAnswers.forEach((gridAns) => {
      gridPercent = gridPercent + +gridAns[AgentInfoConstant.secSplitQuesName];
    });
    this.totalSplitPercentage =
      +this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.splitMainAgentQuesId))?.value +
      gridPercent;
    this.checkForErrorInSplit();
  }

  checkIfSSNIsValidAndResetValues(xmlTag: string): void {
    if (xmlTag === AgentInfoConstant.agentSSNQuesId || xmlTag === AgentInfoConstant.agentLastNameQuesId) {
      this.resetFirmList();
      this.checkIfSSNIsValid();
      this.checkIfLastNameValid();
    }
  }

  private checkIfLastNameValid(): void {
    this.agentLastNameValid = this.checkIfFieldValid(AgentInfoConstant.agentLastNameQuesId);
  }

  private checkIfSSNIsValid(): void {
    this.agentSSNValid = this.checkIfFieldValid(AgentInfoConstant.agentSSNQuesId);
  }

  private checkIfFieldValid(xmlTag: string): boolean {
    const quesFieldId = this.getFieldIdByXmlTag(xmlTag);
    return this.agentInfoForm?.get(quesFieldId)?.value &&
      this.agentInfoForm?.get(quesFieldId)?.status ===
      'VALID';
  }

  private checkForErrorInSplit(): void {
    const splitMainAgentQuesId = this.getFieldIdByXmlTag(AgentInfoConstant.splitMainAgentQuesId);
    if (+this.agentInfoForm.get(splitMainAgentQuesId)?.value < 1) {
      this.agentInfoForm.get(splitMainAgentQuesId)?.setErrors({ textError: true });
    } else if (this.totalSplitPercentage !== 100) {
      this.agentInfoForm
        .get(splitMainAgentQuesId)
        ?.setErrors({ percentError: true });
    } else {
      this.agentInfoForm
        .get(splitMainAgentQuesId)
        ?.setErrors(null);
    }
  }

  private resetFirmList(): void {
    this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.firmNameQuesId))?.reset();
    this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentNPNQuesId))?.reset();
    this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentOtherFirmName))?.reset();
    this.updateQuestionDataWithFirm([]);
    this.firmOptions = [];
    this.disableFirmDropDown = true;
  }

  loadFirmDetails(_data: Question): void {
    this.updateFirmList();
    this.defaultPageLoadService.logButtonClick('search');
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  private updateFirmList(): void {
    if (this.agentSSNValid && this.agentLastNameValid) {
      this.getFirmDropdownOptions();
    }
  }


  private getFirmDropdownOptions(): void {
    this.showLoader = true;
    this.loaderService.show();
    const value = 'OTH';
    const description = 'Other';
    const label = 'Other';
    const quesOptions: QuestionOption[] = [];
    this.agentService
      .getFirmListBySsn(
        this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value,
        this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId))?.value
      )
      .subscribe(
        (response) => {
          this.agentService.createDropDownFromFirmList(response, quesOptions);
          quesOptions.push({ value, description, label });
          this.firmOptions = this.utilsService.formatOptions(quesOptions);
          this.updateQuestionDataWithFirm(quesOptions);
          this.agentNpn = response?.data?.agentNpn;
          this.agentService.saveAgentNpnResp(
            this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value,
            this.agentNpn);
          this.disableFirmDropDown = false;
          this.loaderService.hide();
          this.showLoader = false;
        },
        (_error) => {
          console.log('error occured');
          quesOptions.push({ value, description, label });
          this.firmOptions = this.utilsService.formatOptions(quesOptions);
          this.updateQuestionDataWithFirm(quesOptions);
          this.agentNpn = '';
          this.disableFirmDropDown = false;
          this.loaderService.hide();
          this.showLoader = false;
        }
      );
  }

  private updateQuestionDataWithFirm(quesOptions: QuestionOption[]): void {
    this.agentInfoData.forEach((question) => {
      if (question.xmlTag === AgentInfoConstant.firmNameQuesId) {
        question.questionOption = quesOptions;
      }
    });
  }

  private updateFirmRelatedValues(): void {
    this.checkIfSSNIsValid();
    this.checkIfLastNameValid();
    this.agentInfoData.forEach((question) => {
      if (question.xmlTag === AgentInfoConstant.firmNameQuesId &&
        question.question_answer) {
        this.firmOptions = this.utilsService.formatOptions(
          question.questionOption
        );
        this.disableFirmDropDown = false;
      }
    });
  }

  private updateAgentInfo(): void {
    const agentProfile = this.agentService.getAgentProfile();
    const agentFirstNameFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentFirstNameQuesId);
    const agentLastNameFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId);
    const agentEmailFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentEmailQuesId);
    if (!this.agentInfoForm.get(agentFirstNameFieldId)?.value) {
      this.agentInfoForm.get(agentFirstNameFieldId)?.setValue(this.utilsService.getCamelCase(agentProfile?.firstName));
    }
    if (!this.agentInfoForm.get(agentLastNameFieldId)?.value) {
      this.agentInfoForm.get(agentLastNameFieldId)?.setValue(this.utilsService.getCamelCase(agentProfile?.lastName));
    }
    if (!this.agentInfoForm.get(agentEmailFieldId)?.value) {
      this.agentInfoForm.get(agentEmailFieldId)?.setValue(agentProfile?.email);
    }
  }

  private updateBusinessRules(data: Question): void {
    if (data.xmlTag === AgentInfoConstant.agentNumberQuesId && !this.agentService.getAgentProfile().allStateFlag) {
      data.required = 'false';
    }
  }

  checkToShowAttestRadioButton(data?: Question): void {
    const isCaseManagerIdSame =
      this.agentService.getCaseManagerEmail()?.toLowerCase() ===
      this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentEmailQuesId))?.value?.toLowerCase();
    if (!data || data.xmlTag === AgentInfoConstant.agentEmailQuesId) {
      if (isCaseManagerIdSame) {
        this.updateRequiredField(AgentInfoConstant.doYouattestTag, 'true');
        this.showAttestRadioButton = true;
      } else {
        this.updateRequiredField(AgentInfoConstant.doYouattestTag, 'false');
        this.showAttestRadioButton = false;
        this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.doYouattestTag))?.reset();
      }
    }
  }

  private updateRequiredField(xmlTag: string, isRequired: string): void {
    this.agentInfoData.forEach(question => {
      if (question.xmlTag === xmlTag) {
        question.required = isRequired;
      }
      // update isattest Ans
      this.updateDoYouAttestValue(question, xmlTag, isRequired);
    });
  }

  private updateDoYouAttestValue(question: Question, xmlTag: string, isRequired: string): void {
    if (question.xmlTag === AgentInfoConstant.doYouattestTag && xmlTag === AgentInfoConstant.doYouattestTag) {
      if (isRequired === 'true') {
        this.agentInfoForm.get(question.fieldId)?.setValue(this.userDetailsService.getIsAttestedForSameEmail());
        this.agentInfoForm.get(question.fieldId)?.setValidators([attestValidator(), Validators.required]);
      } else {
        this.agentInfoForm.get(question.fieldId)?.reset();
        this.agentInfoForm.get(question.fieldId)?.setValidators([]);
      }
      this.agentInfoForm.get(question.fieldId)?.updateValueAndValidity();
    }
  }

  private filterResultsBasedOnBusinessRules(): void {
    if (!this.agentService.getAgentProfile()?.allStateFlag) {
      const filteredQuesList = this.questionsData?.pages[0]?.gridInstances[0].gridQuestions.filter(ques => {
        return ques.notes !== 'ITES_FILTER_ALL_STATE';
      });
      this.questionsData.pages[0].gridInstances[0].gridQuestions = filteredQuesList;
    }
    this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
    this.maxNoOfAgent = +(this.gridInstance[0]?.maxGridIndex);
  }

  private updateFirmListForSecAgents(): void {
    this.gridAnswers.forEach((gridAns) => {
      if (gridAns[AgentInfoConstant.secAgentFirmQuesName]) {
        this.ssnListFromGridAns.push(gridAns[AgentInfoConstant.secAgentSSNQuesName] + ':' + gridAns[AgentInfoConstant.secAgentLNQuesName]);
      }
    });
    this.ssnListFromGridAns.forEach(ssnLN => {
      const [ssn, lastName] = ssnLN.split(':');
      if (ssn && lastName) {
        if (!this.agentService.getFirmResponse(ssnLN)) {
          this.agentService.getFirmListBySsn(ssn, lastName).subscribe((res) => {
            const quesOptions = [];
            this.agentService.createDropDownFromFirmList(res, quesOptions);
            quesOptions.push({ value: 'OTH', description: 'Other', label: 'Other' });
            this.agentService.saveFirmResponse(ssnLN, this.utilsService.formatOptions(quesOptions));
            this.agentService.saveAgentNpnResp(ssn, res?.data?.agentNpn);
            this.firmUpdatedForSSN.push(ssn);
          });
        } else {
          this.firmUpdatedForSSN.push(ssn);
        }
      }
    });
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private getNameByXmlTag(xmlTag: string): string {
    return this.utilsService.getNameByXmlTag(this.gridInstance[0].gridQuestions, xmlTag);
  }

  private checkIfAgentsValid(): void {
    const payload = this.createPayloadForValidateAgent();
    this.duplicateSSNExists = false;
    this.showLoader = true;
    this.loaderService.show();
    this.defaultPageLoadService.validateAgent(payload).subscribe((res) => {
      if (res?.error?.errorCode === 'E203') {
        this.duplicateSSNExists = true;
      } else if (res?.data) {
        this.action = 'openModal';
        if (res.data.length > 0) {
          this.updateAgentListWithResponse(res.data);
          this.invalidAgents = this.agentList.filter(agent => agent.agentValid === false);
        } else {
          this.invalidAgents = this.agentList.filter(agent => agent.agentSSN);
        }
        this.isAgentValidationCompleted();
        this.agentValidationErrorOccurred = false;
        this.emitStatus();
      } else if (res.error) {
        this.agentValidationErrorOccurred = true;
      }
    }, (_error) => {
      this.agentValidationErrorOccurred = true;
    }).add(() => {
      this.showLoader = false;
      this.loaderService.hide();
    });
  }

  private createPayloadForValidateAgent(): any {
    const clientState = this.userDetailsService.getInsuredState();
    return {
      solicitationState: clientState,
      clientState,
      productId: this.productService.getMarketName(),
      agentList: this.getAgentListToValidate(),
    };
  }

  private getAgentListToValidate(): any[] {
    this.agentList = [];
    this.updatePrimaryAgentData();
    this.updateSecAgentData();
    return this.agentList;
  }

  private isAgentValidationCompleted(): void {
    // implement hard stop for PA if the agent validation failed - only for eApp, not for eticket
    this.agentService.setIsAgentsValidated(true);
  }

  private updatePrimaryAgentData(): void {
    const agentId = 1;
    const primaryAgentData = {
      agentId: agentId.toString(),
      agentFirstName: this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentFirstNameQuesId))?.value,
      agentLastName: this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentLastNameQuesId))?.value,
      agentSSN: this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value,
      firmId: this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.firmNameQuesId))?.value?.value,
    };
    this.agentList.push(primaryAgentData);
  }

  private updateSecAgentData(): void {
    let agentId = 2;
    if (this.showAgentAccordion) {
      this.gridAnswers.forEach(ans => {
        const secAgentData = {
          agentId: agentId.toString(),
          agentFirstName: ans[this.getNameByXmlTag(AgentInfoConstant.secAgentFirstName)],
          agentLastName: ans[this.getNameByXmlTag(AgentInfoConstant.secAgentLastName)],
          agentSSN: ans[this.getNameByXmlTag(AgentInfoConstant.secAgentSSNQuesId)],
          firmId: ans[this.getNameByXmlTag(AgentInfoConstant.secAgentFirmId)]
        };
        this.agentList.push(secAgentData);
        agentId++;
      });
    }
  }

  private updateAgentListWithResponse(agentValidationRes: any[]): void {
    this.agentList.forEach((agent) => {
      const temp = agentValidationRes.find(element => element.agentId === agent.agentId);
      agent.agentValid = temp?.agentValid ? true : false;
    });
  }

  private updateAgentValidationStatus(isOnInit: boolean): void {
    if (!this.disableForm) {
      if (this.agentInfoForm.dirty && !isOnInit) {
        this.agentService.setIsAgentsValidated(false);
      }
      if (isOnInit && this.getAgentValidityStatus() === undefined) {
        if (this.tabService.getActiveTab()?.status?.errorExists === false) {
          this.agentService.setIsAgentsValidated(true);
        } else {
          this.agentService.setIsAgentsValidated(false);
        }
      }
    } else {
      this.agentService.setIsAgentsValidated(true);
    }
  }

  private updateagentNPNValue(data?: Question): void {
    if (!data || data?.xmlTag === AgentInfoConstant.firmNameQuesId) {
      const agentNpnFieldId = this.getFieldIdByXmlTag(AgentInfoConstant.agentNPNQuesId);
      const firmVal = this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.firmNameQuesId))?.value?.label;
      if (!this.agentNpn && this.agentInfoForm.get(this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value) {
        this.agentNpn = this.agentService.getAgentNpnResp(this.agentInfoForm.get(
          this.getFieldIdByXmlTag(AgentInfoConstant.agentSSNQuesId))?.value);
      }
      if (this.agentNpn && firmVal !== 'Other') {
        this.agentInfoForm.get(agentNpnFieldId)?.setValue(this.agentNpn);
      } else {
        this.agentInfoForm.get(agentNpnFieldId)?.reset();
      }
    }
  }

  // this is added to make Agent validation mandatory in case of state or product update
  private updateAgentValidationInd(): void {
    if (!this.isTabVisited) {
      this.agentService.setIsAgentsValidated(false);
    }
  }
}
