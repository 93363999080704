import { Component, OnDestroy, OnInit } from '@angular/core';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { ProductCodes, ProductType, TAB, TabInformation } from 'src/config/sideNav.config';

import { LfgLoaderService } from '@ng/lfg-loader';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { take, takeUntil, mergeMap, map } from 'rxjs/operators';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { PageService } from 'src/app/shared/services/page.service';
import {
  CaseInfoConstant, CaseStatus, Message, SignatureMethodConfirmationModalMessages,
  TABS_AFTER_LOCK_SCREEN,
  TABS_ALWAYS_IN_GOOD_ORDER, TABS_EXCLUDED_FROM_GET_QUESTIONS
} from 'src/config/constants';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { CaseAndPageStatus, MessageType, PackageInfo, Recipient } from 'src/app/shared/models/casePage.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalSize } from '@ng/lfg-modal-popup';
import { CaseUtilsService } from 'src/app/shared/services/case-utils.service';
import { UpdateCaseService } from 'src/app/shared/services/update-case.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { DelegationService } from 'src/app/shared/services/delegation.service';
import { AccessibilityConfig, FunctionalityConfig } from 'src/config/delegationFunctionality.config';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
  providers: [MessageService]
})
export class BasePageComponent implements OnInit, OnDestroy {

  systemMessage: any;
  MessageType = MessageType;
  ConfirmationModalMessage = SignatureMethodConfirmationModalMessages;

  windowRef: any = window;
  activeTab: TabInformation;
  eticketQuestions: Map<string, any> = new Map<string, any>();
  showLoader = 0;
  caseId: string;
  tabStatusChanged = new BehaviorSubject(null);
  casePageStatusRes: CaseAndPageStatus;
  private unSubscribeSub = new Subject();
  readonly TAB = TAB;
  buttonStatus = {
    nextDisable: true,
    backDisable: false,
  };
  latestEmittedData = null;
  currentFormId: string;
  onLoadExistingCase = true;
  caseInfoUpdate = false;

  tabsAfterLock = TABS_AFTER_LOCK_SCREEN;

  // indicators of the existing case loaded properly with case info page and all tabs
  allTabsLoaded = false;
  caseInfoPageLoaded = false;

  signerList: Recipient[] = [];
  agentSignerInfo: Recipient;

  signerInfo;
  isSignatureFormChange = false;
  selectedSignatureMethod: string;
  ownerAddress: string;
  isSameInsuredAddress: any;
  ownerCity: any;
  ownerState: any;
  ownerZip: any;
  labelFirstName: any;
  labelLastName: any;
  ownerQuesWithSameAddr: any;
  policyCreationError: any;

  displayProgressModal: boolean;
  displayHelpData = false;
  closeHelpHint = true;

  fundListSelected = [];
  allocationType: undefined;
  TABS_ALWAYS_IN_GOOD_ORDER = TABS_ALWAYS_IN_GOOD_ORDER;
  consentTabList = [];
  hasRemoteSigners: boolean;

  // variables for create envelope - in-person
  errorOccuredGettingTransactionId = false;
  errorOccuredCreateEnvelope = false;
  progressPercentage = 0;
  envelopeCreated = false;
  displayProgressModalForESign = false;
  noOfTimesTransactionStatusApiCalled = 0;
  timerSubscription;
  activePacket: PackageInfo;

  // variables for create envelope - remote
  errorOccuredGettingTransactionIdRemote = false;
  errorOccuredCreateEnvelopeRemote = false;
  envelopeCreatedRemote = false;

  isPolicySubmitted = false;
  signingCompleted = false;
  isAllSignerRemote = false;

  showConfirmationModal = false;
  isSaveAndExitClicked = false;

  areYouSureModalConfig = {
    header: true,
    state: false,
    footer: true,
    size: ModalSize.small,
  };

  signatureMethodConfirmationModal = {
    header: true,
    state: false,
    footer: true,
    size: ModalSize.medium,
  };

  // for product change
  prevTabList = [];
  Message = Message;
  isFormChanged = false;

  redirectConfirmationSubj = new Subject<boolean>();

  userAccessDetails: FunctionalityConfig;
  userFunctionalityConfig: AccessibilityConfig;

  constructor(
    private tabDetailsService: TabDetailsService,
    private lfgLoaderService: LfgLoaderService,
    private pageLoadService: DefaultPageload,
    private utilsService: UtilsService,
    private pageService: PageService,
    private userService: UserDetailsService,
    private appStateService: AppStateService,
    private caseUtilsService: CaseUtilsService,
    private productService: ProductDetailsService,
    private agentService: AgentAndFirmService,
    private route: ActivatedRoute,
    private router: Router,
    private updateCaseService: UpdateCaseService,
    private messageService: MessageService,
    private delegationService: DelegationService,
  ) { }

  ngOnInit(): void {
    this.subscribeForMessage();
    this.fetchAgentProfileAndPageData();
    this.pageLoadService.fetchCompanyNames();
    this.appStateService.setSaveConfirmationMsgFlag(false);
  }

  openRedirectConfirmationModal(): void {
    this.showConfirmationModal = true;
  }

  confirmationModalResponse($event: boolean): void {
    this.showConfirmationModal = false;
    this.redirectConfirmationSubj.next($event);
  }

  private getCaseIdForExistingCase(): void {
    this.route?.queryParams?.subscribe(params => {
      this.caseId = params?.caseId;
      if (this.caseId) {
        this.pageLoadService.setCaseId(this.caseId);
      }
      this.tabDetailsService.initializeTabService();
      this.updatePageStatus();
    });
  }

  private getDataForActiveTab(): void {
    this.tabDetailsService.activeTabSubject.pipe(takeUntil(this.unSubscribeSub)).subscribe(
      (activeTab: TabInformation) => {
        if (this.activeTab?.name !== activeTab?.name) {
          this.isFormChanged = false;
          this.closeSlide(false);
          this.saveDataAndGetDataForNextPage(activeTab);
        }
      }
    );
  }

  saveAndExit(_params?): void {
    this.isSaveAndExitClicked = true;
    console.log('save exit called');
    this.pageLoadService.logButtonClick('save & exit');
    this.saveAnswers().subscribe((res) => {
      if (res) {
        if (this.activeTab.name === TAB.SIGNATURE_METHOD) {
          this.saveRecipientInfo();
          setTimeout(() => this.router.navigate(['/dashboard']), 500);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    },
      () => {
        this.router.navigate(['/dashboard']);
      });
    this.appStateService.setSaveConfirmationMsgFlag(true);
  }

  getHelpData(): void {
    this.displayHelpData = true;
    this.closeHelpHint = false;
  }

  rdToDashboard(): void {
    this.pageLoadService.logButtonClick('back to dashboard');
    this.router.navigate(['/dashboard']);
  }

  closeSlide(closeSlide: boolean): void {
    if (closeSlide) {
      this.closeHelpHint = true;
      setTimeout(() => {
        this.displayHelpData = false;
      }, 500);
    } else {
      this.closeHelpHint = true;
      this.displayHelpData = false;
    }
  }

  formStatus(event): any {
    this.closeSlide(true);
    this.currentFormId = event.formId ? event.formId : this.currentFormId;
    this.latestEmittedData = event;
    this.buttonStatus.nextDisable = !event.formValid;
    this.updateFormChangeStatus(event);
    if (!event.formValid) {
      console.log('Form not valid');
    }
    if (this.activeTab) {
      this.tabDetailsService.updateTabStatusMap(
        this.activeTab.name,
        event.formValid
      );
    }
    this.updateUserInfo(event);
    this.updateVoiceThankYouTab(event);
    this.updateForFundList(event);
    this.signerInfo = event.signatureFormData;
    this.isSignatureFormChange = event.isSignatureFormChange;
    console.log('From base form status :' + event.formId + ' Form is :'
      + event.formValid + ' Form is changed :' + event.isFormChange);
  }

  private updateFormChangeStatus(event: any): void {
    // this is only applicable for form based tabs
    // check for agent instruction page as for remote users there is no form
    this.isFormChanged = (event.isFormChange && this.activeTab.isFormBasedTab) &&
      (this.activeTab.name !== TAB.AGENT_INSTRUCTION || !this.hasRemoteSigners) &&
      this.userAccessDetails?.editCase;
  }

  close(): any {
    this.pageLoadService.logButtonClick('close');
    this.router.navigate(['/dashboard']);
  }

  caseInfoUpdated(_event): any {
    const caseInfo = this.eticketQuestions.get(TAB.CASE_INFO);
    this.eticketQuestions = new Map<string, any>();
    // to make sure prevTabList only set if the case is created and not reset after productSelection
    if (this.prevTabList?.length === 0 && this.pageLoadService.getCaseId()) {
      this.prevTabList = [...this.tabDetailsService.tabInfoList];
    }
    this.eticketQuestions.set(TAB.CASE_INFO, caseInfo);
    this.tabDetailsService.initializeTabService();
    this.caseInfoUpdate = true;
    this.userService.clearUserDetailsExceptCaseInfo();
  }

  insuredProfileUpdated(_event): any {
    this.pageLoadService.setIsInsuredProfileUpdated(true);
    this.updateTabNotVisited([TAB.PRIMARY_INSURED, TAB.ELECTRONIC_FUND_TRANSFER,
    TAB.EXISTING_INSURANCE, TAB.TEMPORARY_LIFE, TAB.EXISTING_INSURANCE_FOR_PROPOSED_B]);
  }

  private updateTabNotVisited(tabList: string[]): any {
    return this.tabDetailsService.updateTabNotVisited(tabList);
  }

  continue(): void {
    const productType = this.productService.getProductType();
    if (productType === ProductType.LONG_TERM_CARE) {
      // MGeAPP check
      this.createCaseDetails();
      return;
    }
    this.createCaseDetails();
    this.tabDetailsService.updateTabList(TAB.ATTESTATION, true);
    this.tabDetailsService.updateTabList(
      this.tabDetailsService.activeTab.name,
      undefined,
      { visited: true, errorExists: false, isLocked: false }
    );
    this.pageLoadService.logButtonClick('continue');
  }

  proceedToSign(_event: any): void {
    this.tabDetailsService.updateTabList(TAB.SIGNATURE_METHOD, true);
    this.tabDetailsService.next();
  }

  signingStatusUpdated(event: any): void {
    if (event === 'yes') {
      this.saveAnswers().subscribe();
    }
  }

  lockStatusUpdated(event: any): void {
    if (event === 'yes') {
      this.delegationService.resetAgentConfCheckbox();
      this.updateAccessDetails();
      this.saveAnswers().subscribe();
    }
  }

  recipientListUpdated(recipientList: Recipient[]): void {
    if (recipientList?.length > 0) {
      this.signerList = this.caseUtilsService.sortListBySignerRole(recipientList, true);
      this.agentSignerInfo = this.caseUtilsService.getSignerByRole(recipientList, 'Agent');
      this.hasRemoteSigners = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList);
    }
  }

  consentDeclined(event: any): void {
    if (event === 'yes') {
      this.activePacket = undefined;
      this.removeTabsAfterSignatureMethod();
      this.clearAgentInstructionData();
    }
  }

  packetCancelled(event: any): void {
    this.consentDeclined(event);
  }

  policySubmitted(event: any): void {
    if (event === 'yes') {
      this.tabDetailsService.updateTabStatusMap(TAB.REVIEW_AND_SUBMIT, true);
      this.tabDetailsService.lockAllTabs(true, [TAB.REVIEW_AND_SUBMIT]);
      this.isPolicySubmitted = true;
    }
  }

  activePacketUpdated(event: PackageInfo): void {
    this.activePacket = event;
  }

  caseUnlocked(event: any): void {
    if (event === 'yes') {
      this.voidEnvelope('CASE_UNLOCKED');
    }
  }

  nextTab(): void {
    this.pageLoadService.logButtonClick('next');
    if (this.activeTab.name === TAB.ATTESTATION) {
      this.tabDetailsService.updateTabListbyConfig();
    } else if (this.activeTab.name === TAB.VOICE_TERM_OF_USE) {
      this.createPolicyForVoiceSign();
    } else if (this.activeTab.name === TAB.SIGNATURE_METHOD && this.selectedSignatureMethod === 'voice') {
      this.tabDetailsService.updateTabList(TAB.VOICE_TERM_OF_USE, true, { isLocked: false, errorExists: false, visited: false });
      this.tabDetailsService.updateTabStatusMap(TAB.VOICE_TERM_OF_USE, false);
    } else if (this.activeTab.name === TAB.SIGNATURE_METHOD && this.selectedSignatureMethod === 'electronic') {
      this.updateSignatureMethodModal(true);
    } else {
      this.updateTabsForElectronicSignature();
    }
    // no need to move to next screen for below conditions
    // voice term of use as it submit data and then decides to move to next screen
    // in agent instruction screen for all in person signer it calls create envelope
    // if it has remote signers then go to next screen
    if ((this.activeTab.name !== TAB.VOICE_TERM_OF_USE) && (this.activeTab.name !== TAB.E_SIGNATURE_INSTRUCTION) &&
      ((this.activeTab.name === TAB.AGENT_INSTRUCTION && this.hasRemoteSigners) || this.activeTab.name !== TAB.AGENT_INSTRUCTION)
      && !(this.activeTab.name === TAB.SIGNATURE_METHOD && this.selectedSignatureMethod === 'electronic')) {
      this.tabDetailsService.next();
    }
  }

  continueOnSignatureMethodModal(): void {
    this.updateTabsForElectronicSignature();
    this.tabDetailsService.next();
    this.updateSignatureMethodModal(false);
  }

  updateSignatureMethodModal(isOpen: boolean): void {
    this.signatureMethodConfirmationModal.state = isOpen;
  }

  private updateTabsForElectronicSignature(): void {
    if (this.activeTab.name === TAB.SIGNATURE_METHOD && this.selectedSignatureMethod === 'electronic') {
      this.updateTabList(TAB.AGENT_INSTRUCTION);
    } else if (this.activeTab.name === TAB.AGENT_INSTRUCTION) {
      this.hasRemoteSigners ? this.updateTabsAfterAgentInstruction() : this.createEnvelope('inperson');
    } else if (this.activeTab.name === TAB.E_SIGNATURE_INSTRUCTION) {
      this.createEnvelope('remote');
    } else if (!this.hasRemoteSigners && this.activeTab.name === this.consentTabList[this.consentTabList.length - 1]
      && this.allConsentSigned()) {
      this.updateTabList(TAB.CONSENT_PAGE_AGENT);
    }
    else if (this.activeTab.name === TAB.CONSENT_PAGE_AGENT && (this.allConsentSigned()
      || (this.activeTab.status?.visited && !this.activeTab.status?.errorExists))) {
      this.updateTab(true, TAB.REVIEW_AND_SUBMIT);
      this.tabDetailsService.updateTabStatusMap(TAB.REVIEW_AND_SUBMIT, true);
      // wait for few moment to update the tab status of previous page
      setTimeout(() => {
        this.tabDetailsService.lockAllTabs(true, [TAB.REVIEW_AND_SUBMIT]);
        this.signingCompleted = true;
      }, 0);
    }
  }

  private addConsentPageForInPersonSignature(isTabUpdateReq: boolean): void {
    this.consentTabList = this.caseUtilsService.addConsentPageForInPersonSignature(this.signerList, isTabUpdateReq);
  }

  getSignerbyTab(tabname: string): Recipient {
    return this.caseUtilsService.getSignerbyTab(tabname, this.signerList, this.agentSignerInfo);
  }

  private updateTabList(tab: string): void {
    const isTabValid = TABS_ALWAYS_IN_GOOD_ORDER.includes(tab);
    this.tabDetailsService.updateTabList(tab, true, { isLocked: false, errorExists: !isTabValid, visited: false });
    this.tabDetailsService.updateTabStatusMap(tab, isTabValid);
  }

  previousTab(): void {
    this.pageLoadService.logButtonClick('back');
    this.tabDetailsService.previous();
  }

  isTabsLocked(): boolean {
    return this.tabDetailsService.isTabsLocked();
  }

  hasNext(): boolean {
    return this.tabDetailsService.hasNextTab();
  }

  hasPrevious(): boolean {
    return this.tabDetailsService.hasPreviousTab();
  }

  focusOff(event): any {
    const element = event.target || event.srcElement || event.currentTarget;
    if (element) {
      element.blur();
    }
  }

  checkIfActiveTabLocked(): boolean {
    return this.tabDetailsService.getTabInfoByTabName(this.activeTab.name)?.status?.isLocked
      || !this.userAccessDetails?.editCase
      || (this.isTabAfterLockPage() && this.userAccessDetails?.readOnlyPostLock);
  }

  private isTabAfterLockPage(): boolean {
    return TABS_AFTER_LOCK_SCREEN.includes(this.activeTab.name);
  }

  signatureMethodUpdated(event: any): void {
    if (event?.signatureMethod === 'electronic') {
      this.selectedSignatureMethod = 'electronic';
    } else if (event?.signatureMethod === 'voice') {
      this.selectedSignatureMethod = 'voice';
    }
    if (event?.isChanged === 'yes') {
      this.deleteListOfTabs(this.tabDetailsService.getTabsAvailableAfter(this.activeTab.name));
      this.clearAgentInstructionData();
    }
  }

  ownerInfoUpdated(event: any): void {
    event === 'yes' ? this.updateTab(true, TAB.TRUST_INFO) : this.updateTab(false, TAB.TRUST_INFO);
  }

  replacementPolicyAdded(event: any): void {
    event === 'yes' ? this.updateTab(true, TAB.WA_REPLACEMENT) : this.updateTab(false, TAB.WA_REPLACEMENT);
  }

  replacementPolicyAddedProposedB(event: any): void {
    event === 'yes' ? this.updateTab(true, TAB.WA_REPLACEMENT_PROPOSED_B) : this.updateTab(false, TAB.WA_REPLACEMENT_PROPOSED_B);
  }

  isIllustrationSelected(event: any): void {
    event === 'yes' ? this.updateTab(true, TAB.ILLUSTRATION_INFORMATION) : this.updateTab(false, TAB.ILLUSTRATION_INFORMATION);
  }

  isLTCSelected(event: any): void {
    event === 'yes' ? this.updateTab(true, TAB.LTC_INSURANCE) : this.updateTab(false, TAB.LTC_INSURANCE);
  }

  isOtherRiderSelected(event: any): void {
    if (event === 'yes') {
      this.updateTab(true, TAB.PROPOSED_INSURED_B);
      this.updateTab(true, TAB.EXISTING_INSURANCE_FOR_PROPOSED_B);
    } else {
      this.updateTab(false, TAB.PROPOSED_INSURED_B);
      this.updateTab(false, TAB.EXISTING_INSURANCE_FOR_PROPOSED_B);
    }
  }

  caseInfoLoaded(event: any): void {
    if (event === 'yes') {
      this.caseInfoPageLoaded = true;
      this.accessLastVisitedPage();
    }
  }

  ownerInfoNeeded(event: any): void {
    if (event === 'yes') {
      this.updateTab(true, TAB.OWNER_INFO);
    } else {
      this.updateTab(false, TAB.OWNER_INFO);
      this.updateTab(false, TAB.TRUST_INFO);
    }
  }

  updateEftTab(event: any): void {
    if (event === 'yes') {
      this.updateTab(true, TAB.ELECTRONIC_FUND_TRANSFER);
    } else {
      this.updateTab(false, TAB.ELECTRONIC_FUND_TRANSFER);
      this.userService.clearEftData();
    }
  }

  updateDCATab(event: any): void {
    event === 'yes' ? this.updateTab(true, TAB.DOLLAR_COST_AVERAGING) : this.updateTab(false, TAB.DOLLAR_COST_AVERAGING);
  }

  isMIRequired(event: any): void {
    if (event === 'yes') {
      if (!this.tabDetailsService.getTabInfoByTabName(TAB.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION)?.isEnable) {
        this.updateTab(true, TAB.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION);
      }
    } else {
      this.updateTab(false, TAB.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION);
    }
  }

  private updateTab(add: boolean, tab: string): void {
    const tabsToDelete = this.caseUtilsService.updateTab(add, tab);
    this.deleteDataFromEticketQuestionMap(tabsToDelete);
  }

  deleteTabsAfterUnlock(tabList: string[]): void {
    this.deleteDataFromEticketQuestionMap(tabList);
    this.caseUtilsService.deleteTabsAfterUnlock(tabList);
    // clear signer list
    this.signerList = [];
    this.hasRemoteSigners = undefined;
  }

  private deleteDataFromEticketQuestionMap(tabList: string[]): void {
    tabList.forEach(tab => {
      this.eticketQuestions.delete(tab);
    });
  }

  private updateUserInfo(event: any): void {
    let firstName;
    let lastName;
    let middleName;
    let suffix;
    let age;
    let dob;
    let state;
    let productType;
    let productName;
    if ((event?.formValid || this.caseId) && this.activeTab.name === TAB.CASE_INFO) {
      event?.questions?.pages[0]?.questions.forEach((question) => {
        if (question.xmlTag === CaseInfoConstant.firstNameId) {
          firstName = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.lastNameId) {
          lastName = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.suffixId) {
          suffix = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.middleNameId) {
          middleName = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.dobQuestionId) {
          dob = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.ageQuestId) {
          age = question.question_answer;
        } else if (question.xmlTag === CaseInfoConstant.genderId) {
          this.userService.setInsuredGender(question.question_answer);
        } else if (question.xmlTag === CaseInfoConstant.stateId) {
          state = this.utilsService.getCodeFromValue(question.question_answer);
        } else if (question.xmlTag === CaseInfoConstant.productTypeQuestId) {
          productType = this.utilsService.getCodeFromValue(question.question_answer);
        } else if (question.questionText.toLowerCase() === 'display product name') {
          productName = this.utilsService.getCodeFromValue(question.question_answer);
        }
      });
      this.userService.setInsuredName(firstName, middleName, lastName, suffix);
      this.userService.setInsuredDOBInfo(dob, age);
      this.userService.setInsuredState(state);
      this.productService.setProductName(productName);
      this.productService.setProductType(productType);
      this.productService.setProductCondition();
      this.checkForProductAvailability();
      this.updateTabStatusIfExistingCase();
    }
  }

  private checkForProductAvailability(): void {
    if (!this.activeTab?.status?.isLocked && [ProductCodes.SVULONE2021, ProductCodes.VULONE2021].includes(this.productService.getProductName().toLowerCase()) ) {
      this.tabDetailsService.updateTabList(TAB.CASE_INFO, true, { isLocked: false, errorExists: true, visited: false });
      this.tabDetailsService.updateTabStatusMap(TAB.CASE_INFO, false);
    }
  }

  /**
   * get page status and update case info tab and attestation tab status
   * then wait for case info to load, so that we will have nex=cessary data to
   * determine the next tabs
   */
  private updatePageStatus(): void {
    if (this.caseId) {
      this.pageLoadService.getPageStatus(this.caseId).pipe(take(1))
        .subscribe(response => {
          if (response.data) {
            this.casePageStatusRes = {
              pageStatusList: response.data.pageStatusList ? this.caseUtilsService.filterOutInactiveTabs(response.data.pageStatusList) : [],
              caseStatus: response.data.caseStatus ? response.data.caseStatus : {}
            };
            this.updateAdditionalInfoForExistingCase(response.data);
            this.pageLoadService.updateTabInfoForExistingCase(response.data);
            this.updateAccessibilityForUser();
            this.isPolicySubmitted = response.data?.caseStatus?.status === CaseStatus.APPLICATION_ESUBMITTED;
            this.tabStatusChanged.next(true);
          }
        });
    }
  }

  private updateAccessibilityForUser(): void {
    this.delegationService.updateAccessForCase(this.casePageStatusRes.pageStatusList,
      this.casePageStatusRes.caseStatus?.isCreatedByDelegate,
      this.casePageStatusRes.caseStatus.isLockedByDelegate, this.userFunctionalityConfig);
    this.userAccessDetails = this.userFunctionalityConfig?.functionalityAccess;
  }

  /**
   * once case info details is loaded, based on attestation page status load the remaining tabs
   * first load tabs based on the product, state and product type (helps to add tabs which were not visited)
   * then load tabs whose status is fetched by service and lock tabs based on isPageLocked status
   * then update other tabs is page locked or policy created
   * if any consent page is available then lock signature method and agent instruction page for esignature
   */

  private updateTabStatusIfExistingCase(): void {
    if (this.onLoadExistingCase) {
      this.tabStatusChanged.pipe(take(1)).subscribe((tabStatusUpdated) => {
        if (tabStatusUpdated && this.caseId && this.activeTab.name === TAB.CASE_INFO && this.onLoadExistingCase) {
          const attestationTab = this.tabDetailsService.getTabByTabName(TAB.ATTESTATION);
          if (attestationTab?.isEnable && attestationTab.status.visited) {
            if (!attestationTab.status.errorExists) {
              this.tabDetailsService.updateTabListbyConfig();
            }
            this.pageLoadService.updateTabStatusBasedOnValueFetchedFromService(this.casePageStatusRes);
            this.pageLoadService.addAdditionalTabsIfPageLockedOrPolicyCreated(this.casePageStatusRes);
            this.pageLoadService.updatePageStatusForESignature(this.casePageStatusRes);
            this.addReviewAndSubmitIfApplicable(this.casePageStatusRes);
            this.allTabsLoaded = true;
            this.accessLastVisitedPage();
            this.removeConsentPagesIfReq(this.casePageStatusRes);
          }
          this.onLoadExistingCase = false;
        }
      });
    }
  }

  private removeConsentPagesIfReq(casePageStatusRes: CaseAndPageStatus): void {
    const envelopeDeclinedStatuses = [CaseStatus.CUSTOMER_DECLINE_TO_ESIGN, CaseStatus.PRINCIPAL_AGENT_DECLINED];
    if (casePageStatusRes.caseStatus?.status && envelopeDeclinedStatuses.includes(casePageStatusRes.caseStatus.status)) {
      // this condition is written for the revisit scenario when case has both in-person and remote signers
      // and in person signer has signed and remote signer has declined to sign
      // then on revisit delete all the pages after signature method page
      if (this.tabDetailsService.hasConsentTab() && this.tabDetailsService.isTabActive(TAB.ELECTRONIC_SIGNATURE)) {
        this.removeTabsAfterSignatureMethod();
      }
    }
  }

  private accessLastVisitedPage(): void {
    // checking these two values to make sure case info page values are initialized and
    // all tabs are initialized and also lock locked if required
    if (this.caseInfoPageLoaded && this.allTabsLoaded) {
      if (this.userAccessDetails?.caseRelatedAccess?.redirectToLastAccessedPage) {
        this.pageLoadService.goToLastAccessedTab(this.casePageStatusRes);
      }
      // for transact access - land on attestation
      else {
        const attestationTab = this.tabDetailsService.getTabByTabName(TAB.ATTESTATION);
        if (attestationTab.isEnable) {
          this.tabDetailsService.selectTab(attestationTab);
        }
      }
    }
  }

  private removeTabsAfterSignatureMethod(): void {
    this.tabDetailsService.selectTab(this.tabDetailsService.getTabInfoByTabName(TAB.SIGNATURE_METHOD));
    this.tabDetailsService.updateTabList(TAB.SIGNATURE_METHOD, true, { isLocked: false, errorExists: false, visited: true });
    this.deleteListOfTabs(this.tabDetailsService.getTabsAvailableAfter(TAB.SIGNATURE_METHOD));
  }

  private addReviewAndSubmitIfApplicable(casePageStatusRes: CaseAndPageStatus): void {
    // checking if it is a eSignature policy not a voice one
    if (casePageStatusRes?.caseStatus?.status === CaseStatus.READY_TO_SUBMIT
      || casePageStatusRes?.caseStatus?.status === CaseStatus.APPLICATION_ESUBMITTED) {
      const isEsignature = casePageStatusRes.pageStatusList.filter((page) => {
        return this.pageService.getTabByPageGroupName(page.pageGroupName) === TAB.ELECTRONIC_SIGNATURE ||
          page.pageGroupName?.indexOf('LFG - eTicket - Consent') > -1;
      })?.length > 0;
      if (isEsignature) {
        this.updateTab(true, TAB.REVIEW_AND_SUBMIT);
        this.tabDetailsService.updateTabStatusMap(TAB.REVIEW_AND_SUBMIT, true);
        this.tabDetailsService.lockAllTabs(true, [TAB.REVIEW_AND_SUBMIT]);
        this.signingCompleted = true;
      }
    }
  }

  private clearDataIfExists(): void {
    this.eticketQuestions.delete(TAB.APPLICATION_SUMMARY);
  }

  private saveDataAndGetDataForNextPage(activeTab: TabInformation): void {
    if (this.isDataSaveRequired()) {
      const subscrp = this.saveAnswers().subscribe((pageGroupName) => {
        if (pageGroupName) {
          this.saveRecipientInfo();
          this.activeTab = activeTab;
          this.clearDataIfExists();
          if (!TABS_EXCLUDED_FROM_GET_QUESTIONS.includes(this.activeTab.name)) {
            this.getQuestions();
          }
          window.scroll(0, 0);
          subscrp.unsubscribe();
        }
      });
    } else {
      this.activeTab = activeTab;
      this.clearDataIfExists();
      if (!TABS_EXCLUDED_FROM_GET_QUESTIONS.includes(this.activeTab.name)) {
        this.getQuestions();
      }
      if (this.activeTab.name === TAB.AGENT_INSTRUCTION || this.activeTab.name === TAB.E_SIGNATURE_INSTRUCTION) {
        this.getRecipientList();
      }
      window.scroll(0, 0);
    }
  }

  private getQuestions(): any {
    const pageGroupName = this.pageService.getPageGroupByTab(this.activeTab);
    if (!this.eticketQuestions.get(this.activeTab.name)) {
      this.getQuestionByPageGroup(pageGroupName);
      setTimeout(() => {
        if (this.activeTab.name === TAB.SIGNATURE_METHOD) {
          this.getRecipientList();
        }
        if (this.tabDetailsService.isConsentTab(this.activeTab.name)) {
          this.getRecipientList();
          this.getActivePacketDetails();
        }
      }, 200);
    }
  }

  private getQuestionByPageGroup(pageGroupName: string): void {
    const product = this.productService.getProductName();
    const stateCode = this.userService.getInsuredState();
    const productType = this.productService.getProductType();
    const insuredAge = this.userService.getInsuredAge()?.toString();
    const caseId = this.pageLoadService.getCaseId();
    this.showPageLoader(true);
    this.pageLoadService
      .getCaseInfoPageQuestions({ pageGroupName, product, stateCode, productType, insuredAge, caseId })
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe(
        (result) => {
          this.pageLoadService.filterResultsBasedOnBusinessRules(pageGroupName, result.data);
          this.eticketQuestions.set(this.activeTab.name, result.data);
          this.pageLoadService.updateQuestionMap(result.data);
          this.showPageLoader(false);
        },
        (error) => {
          this.showPageLoader(false);
          this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
          console.log(error);
        }
      );
  }

  private saveAnswers(): BehaviorSubject<any> {
    const saveCompletionObservable = new BehaviorSubject(null);
    this.pageLoadService.updateGridSequenceForGridData(this.latestEmittedData.questions);
    this.pageLoadService.updateFundDetailsIfExist(this.latestEmittedData.questions, this.fundListSelected, this.allocationType);
    this.pageLoadService.updateAgentInformation(this.latestEmittedData.questions);
    this.pageLoadService.updateOtherInformation(this.latestEmittedData.questions);
    this.showPageLoader(true);
    this.pageLoadService.updateCaseDetails(
      this.latestEmittedData.questions,
      !this.buttonStatus?.nextDisable
    ).pipe(takeUntil(this.unSubscribeSub))
      .subscribe().add(() => {
        this.showPageLoader(false);
        this.pageLoadService.setIsInsuredProfileUpdated(false);
        saveCompletionObservable.next(this.latestEmittedData.questions.page_group_name);
      });
    return saveCompletionObservable;
  }

  private createCaseDetails(): any {
    this.showPageLoader(true);
    // to make sure deletePage called only if the case is created
    if (this.caseInfoUpdate && this.pageLoadService.getCaseId()) {
      this.deletePagesAfterCaseInfoUpdate();
    }
    this.pageLoadService
      .saveCaseDetails(this.latestEmittedData.questions, !this.buttonStatus?.nextDisable, this.caseInfoUpdate)
      .pipe(takeUntil(this.unSubscribeSub))
      .subscribe(
        (result) => {
          this.caseInfoUpdate = false;
          this.prevTabList = [];
          this.pageLoadService.setIsInsuredProfileUpdated(false);
          if (result.data?.caseId) {
            this.pageLoadService.setCaseId(result.data?.caseId);
            this.redirectToMGeAPP();
            this.latestEmittedData.isSaved = true;
            this.tabDetailsService.next();
            this.showPageLoader(false);
          }
        },
        (error) => {
          this.showPageLoader(false);
          console.log('error response' + error);
        }
      );
  }

  private redirectToMGeAPP(): void {
    const productType = this.productService.getProductType();
    if (productType === ProductType.LONG_TERM_CARE) {
      // redirect to MGeAPP
      const caseId = this.pageLoadService.getCaseId();
      const url = window.location.href;
      const mgeappURL = url?.replace('lincolnlifeeticketportal', 'lincolnlifeeappportal') + '?caseId=' + caseId;
      this.windowRef.location.href = encodeURI(mgeappURL);
    }
  }

  private updateVoiceThankYouTab(event): void {
    if (!event.formValid && (this.activeTab.name === TAB.VOICE_TERM_OF_USE || this.activeTab.name === TAB.SIGNATURE_METHOD)) {
      if (this.tabDetailsService.getTabInfoByTabName(TAB.VOICE_CONFIRM_THANK_YOU)?.isEnable) {
        this.tabDetailsService.updateTabList(TAB.VOICE_CONFIRM_THANK_YOU, false);
      }
    }
  }

  private showPageLoader(show: boolean): void {
    if (show) {
      this.showLoader++;
      if (this.showLoader === 1) {
        this.lfgLoaderService.show();
      }
    } else {
      this.showLoader--;
      if (this.showLoader === 0) {
        this.lfgLoaderService.hide();
      }
    }
  }

  private getRecipientList(): void {
    if (this.signerList?.length === 0) {
      this.showPageLoader(true);
      this.pageLoadService.getRecipientsList().subscribe(response => {
        if (response?.data) {
          this.signerList = this.caseUtilsService.sortListBySignerRole(response.data, true);
          this.agentSignerInfo = this.caseUtilsService.getSignerByRole(response.data, 'Agent');
          this.hasRemoteSigners = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList);
          this.addConsentPageForInPersonSignature(false);
          this.showPageLoader(false);
        }
      });
    }
  }

  private updateSignerListForSave(): void {
    this.signerList?.forEach((signer, index) => {
      signer.eSignatureMethod = this.signerInfo ? this.signerInfo.get(signer.roleName + index)?.value : '';
    });
    this.hasRemoteSigners = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList);
  }

  private saveRecipientInfo(): void {
    if (this.activeTab.name === TAB.SIGNATURE_METHOD && this.isSignatureFormChange) {
      this.updateSignerListForSave();
      this.agentSignerInfo.eSignatureMethod = this.caseUtilsService.hasElectronicSignerAvailable(this.signerList) ? 'remote' : 'inperson';
      this.pageLoadService.saveRecipientInfo(this.signerList, this.agentSignerInfo).subscribe();
      this.isSignatureFormChange = false;
    }
  }

  private isDataSaveRequired(): boolean {
    return (this.latestEmittedData?.isFormChange &&
      // save only if tab is not locked except Agent instruction and e Signature instruction
      (!this.activeTab.status.isLocked || this.activeTab.name === TAB.AGENT_INSTRUCTION
        || this.activeTab.name === TAB.E_SIGNATURE_INSTRUCTION)
      // and the data is not already saved
      && !this.latestEmittedData.isSaved
      // and the active tab is the page getting saved
      && this.activeTab.name === this.pageService.getTabByPageGroupName(this.latestEmittedData.questions.page_group_name))
      // and the tab is not voice term of use page
      && (this.activeTab.name !== TAB.VOICE_TERM_OF_USE)
      // tab is not consent page, and if it is then check save Required or not as consent page
      // is saved already while user accepts the consent
      && ((this.tabDetailsService.isConsentTab(this.activeTab.name) && this.latestEmittedData.saveRequired)
        || !this.tabDetailsService.isConsentTab(this.activeTab.name))
      // and logged in user allowed to save answers
      && this.userAccessDetails?.editCase;
  }

  private createPolicyForVoiceSign(): void {
    this.displayProgressModal = true;
    this.pageLoadService.updateCaseDetails(this.latestEmittedData.questions,
      !this.buttonStatus?.nextDisable).pipe(mergeMap(res => {
        if (res.responseStatus === 'SUCCESS') {
          return this.pageLoadService.createPolicy(true);
        }
      })).subscribe((response) => {
        this.policyCreationError = '';
        if (response?.data) {
          this.updateTab(true, TAB.VOICE_CONFIRM_THANK_YOU);
          this.tabDetailsService.updateTabStatusMap(TAB.VOICE_CONFIRM_THANK_YOU, true);
          this.tabDetailsService.next();
          this.tabDetailsService.lockAllTabs(true, [TAB.VOICE_CONFIRM_THANK_YOU]);
        } else {
          window.scroll(0, 0);
          this.policyCreationError = Message.GENERIC_ERROR;
        }
      },
        (_error) => {
          window.scroll(0, 0);
          this.policyCreationError = Message.GENERIC_ERROR;
        }).add(() => {
          this.displayProgressModal = false;
        });
  }

  private fetchAgentProfileAndPageData(): void {
    this.showPageLoader(true);
    this.subscribeForProfileChangeAndInitPageData();
    this.agentService.fetchAgentProfile();
  }

  private subscribeForProfileChangeAndInitPageData(): void {
    this.agentService.fetchAgentProfileCompletionSubj.pipe(take(1)).subscribe(res => {
      if (res === 'success') {
        this.getCaseIdForExistingCase();
        this.getDataForActiveTab();
        this.updateAccessDetails();
        this.showPageLoader(false);
      } else if (res === '500') {
        this.messageService.setMessage(Message.APPLICATION_DOWN, MessageType.OUTAGE);
        this.showPageLoader(false);
      } else if (res === '403') {
        this.messageService.setMessage(Message.UNAUTHORIZED_USER_ERROR, MessageType.OUTAGE);
        this.showPageLoader(false);
      }
    });
  }

  private subscribeForMessage(): void {
    this.messageService.getMessageSubscription().subscribe(message => {
      if (message) {
        this.systemMessage = message;
      }
    });
  }

  private updateForFundList(event): void {
    if (!event.fundList) {
      this.fundListSelected = [];
      this.allocationType = undefined;
    } else {
      this.fundListSelected = event.fundList;
      this.allocationType = event.allocationType;
    }
  }

  private deletePagesAfterCaseInfoUpdate(): void {
    this.caseUtilsService.deletePagesAfterCaseInfoUpdate(this.prevTabList);
    this.prevTabList = [];
  }

  private deleteListOfTabs(tabsToDelete: TabInformation[]): void {
    const deletedTabs = this.caseUtilsService.deleteListOfTabs(tabsToDelete);
    this.deleteDataFromEticketQuestionMap(deletedTabs);
  }

  private allConsentSigned(): boolean {
    return this.caseUtilsService.allConsentSigned(this.consentTabList, this.activeTab);
  }

  private updateAdditionalInfoForExistingCase(data: any): void {
    if (data?.additionalInfo) {
      this.updateCaseService.setSavedData(data.additionalInfo);
    }
  }

  private createEnvelope(signerType: string): void {
    this.progressPercentage = 0;
    this.updateEnvelopeFlag(false, false, false, signerType);
    this.showPageLoader(true);
    this.pageLoadService.createEnvelope().subscribe((response) => {
      if (response.data) {
        const transactionId = response.data.transaction_id;
        this.isAllSignerRemote = this.caseUtilsService.getInPersonSigners(this.signerList)?.length === 0;
        this.displayProgressModalForESign = true;
        this.getTransactionStatusInIntervals(transactionId, signerType);
      } else {
        this.updateEnvelopeFlag(true, undefined, undefined, signerType);
      }
    }, (_err) => {
      this.updateEnvelopeFlag(true, undefined, undefined, signerType);
    }).add(() => {
      this.showPageLoader(false);
    });
  }

  private getTransactionStatusInIntervals(transactionId: string, signerType: string): void {
    this.updateEnvelopeFlag(undefined, false, undefined, signerType);
    this.noOfTimesTransactionStatusApiCalled = 0;
    this.timerSubscription = timer(1000, 7000).pipe(
      map(() => {
        this.noOfTimesTransactionStatusApiCalled++;
        this.getTransactionStatus(transactionId, signerType);
      })
    ).subscribe();
  }

  private getTransactionStatus(transactionId: string, signerType: string): void {
    this.pageLoadService.getTransactionStatus(transactionId).subscribe((response) => {
      if (response.data) {
        this.progressPercentage = this.caseUtilsService.getProgressPercentageByStatus(response.data.status);
        if (this.progressPercentage === 100) {
          this.updateEnvelopeFlag(undefined, undefined, true, signerType);
          this.closeProgressOverlay();
          this.updateNextTabsAfterEnvelopeCreation(true);
        }
        else if (this.progressPercentage === -1 || this.noOfTimesTransactionStatusApiCalled === 43) {
          this.updateEnvelopeFlag(undefined, true, undefined, signerType);
          this.closeProgressOverlay();
        }
      }
    });
  }

  private closeProgressOverlay(): void {
    this.displayProgressModalForESign = false;
    this.timerSubscription.unsubscribe();
  }

  private updateNextTabsAfterEnvelopeCreation(shouldMoveNext: boolean): void {
    this.addConsentPageForInPersonSignature(true);
    this.tabDetailsService.updateTabLockStatus(TAB.SIGNATURE_METHOD, true);
    this.tabDetailsService.updateTabLockStatus(TAB.AGENT_INSTRUCTION, true);
    if (this.activeTab.name === TAB.E_SIGNATURE_INSTRUCTION) {
      this.tabDetailsService.updateTabLockStatus(this.activeTab.name, true);
      this.updateTab(true, TAB.ELECTRONIC_SIGNATURE);
      this.tabDetailsService.updateTabStatusMap(TAB.ELECTRONIC_SIGNATURE, true);
    }
    this.tabDetailsService.next();
  }

  private updateTabsAfterAgentInstruction(): void {
    this.updateTab(true, TAB.E_SIGNATURE_INSTRUCTION);
    this.tabDetailsService.updateTabLockStatus(TAB.SIGNATURE_METHOD, true);
    this.tabDetailsService.updateTabLockStatus(this.activeTab.name, true);
  }

  unlockPageAndVoidEnvelope(): void {
    this.deleteListOfTabs(this.tabDetailsService.getTabsAvailableAfter(this.activeTab.name));
    this.tabDetailsService.updateTabLockStatus(this.activeTab.name, false);
    if (this.activeTab.name === TAB.AGENT_INSTRUCTION) {
      this.tabDetailsService.updateTabLockStatus(TAB.SIGNATURE_METHOD, false);
    }
    this.clearAgentInstructionData();
    this.updateAreYouSureModalPopup(false);
    window.scroll(0, 0);
    // call service to void envelope and delete the data from getSigner
    this.voidEnvelope();
  }

  private voidEnvelope(reason?: string): void {
    if (this.activePacket) {
      this.voidEnvelopeCall(reason);
    } else {
      this.getActivePacketDetails(true, reason);
    }
  }

  private voidEnvelopeCall(reason?: string): void {
    if (this.activePacket?.envelopeId) {
      this.showPageLoader(true);
      this.pageLoadService.voidEnvelope(this.activePacket?.envelopeId, reason ?? 'PAGE_UNLOCKED').subscribe((res) => {
        if (res?.responseStatus === 'SUCCESS' && res?.data?.voided) {
          this.activePacket = undefined;
        }
      }).add(() => {
        this.showPageLoader(false);
      });
    }
  }

  updateAreYouSureModalPopup(isOpen: boolean): void {
    this.areYouSureModalConfig.state = isOpen;
  }

  private getActivePacketDetails(voidEnvelope?: boolean, reason?: string): any {
    if (!this.activePacket) {
      this.showPageLoader(true);
      this.pageLoadService.getPackageDetails().subscribe((response) => {
        if (response.data?.activePacket) {
          this.activePacket = response.data.activePacket;
          if (voidEnvelope) {
            this.voidEnvelopeCall(reason);
          }
        }
      }).add(() => {
        this.showPageLoader(false);
      });
    }
  }

  private updateEnvelopeFlag(
    errorInTransaction: boolean, errorInEnvelopeCreation: boolean,
    envelopeCreated: boolean, signerType: string): void {
    if (signerType === 'inperson') {
      this.errorOccuredGettingTransactionId = errorInTransaction ?? this.errorOccuredGettingTransactionId;
      this.errorOccuredCreateEnvelope = errorInEnvelopeCreation ?? this.errorOccuredCreateEnvelope;
      this.envelopeCreated = envelopeCreated ?? this.envelopeCreated;
    } else if (signerType === 'remote') {
      this.errorOccuredGettingTransactionIdRemote = errorInTransaction ?? this.errorOccuredGettingTransactionIdRemote;
      this.errorOccuredCreateEnvelopeRemote = errorInEnvelopeCreation ?? this.errorOccuredCreateEnvelopeRemote;
      this.envelopeCreatedRemote = envelopeCreated ?? this.envelopeCreatedRemote;
    }
  }

  checkIfReviewAndSubmitAvailable(): boolean {
    let nextTabAvailable = false;
    this.tabDetailsService.tabInfoList.forEach((tab) => {
      if (tab.name === TAB.REVIEW_AND_SUBMIT && tab.isEnable) {
        nextTabAvailable = true;
      }
    });
    return nextTabAvailable;
  }

  private updateAccessDetails(): void {
    this.userFunctionalityConfig = this.delegationService.getUserAccessibilityConfig();
    this.userAccessDetails = this.userFunctionalityConfig?.functionalityAccess;
  }

  existingInsMainQuesValueUpdate(_event: any): void {
    this.updatePageStatusAsNotVisited([PAGE_GROUP_NAME.AGENT_INFO_CONTINUED]);
  }

  private updatePageStatusAsNotVisited(pageGroupList: string[]): void {
    const tabList = [];
    const pageListToUpdate = [];
    pageGroupList.forEach(page => {
      const tab = this.pageService.getTabByPageGroupName(page);
      if (this.tabDetailsService.getTabInfoByTabName(tab).status?.visited) {
        tabList.push(tab);
        pageListToUpdate.push(page);
      }
    });
    if (pageListToUpdate.length > 0) {
      this.updateTabNotVisited(tabList);
      this.pageLoadService.updatePageStatus(pageListToUpdate).subscribe();
    }
  }

  private clearAgentInstructionData(): void {
    this.updateEnvelopeFlag(false, false, false, 'inperson');
    this.updateEnvelopeFlag(false, false, false, 'remote');
    this.progressPercentage = 0;
    this.displayProgressModalForESign = false;
    this.noOfTimesTransactionStatusApiCalled = 0;
    this.timerSubscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unSubscribeSub.next();
    this.unSubscribeSub.complete();
    this.productService.clearProductDetails();
    this.utilsService.clearQuestionMap();
    this.pageLoadService.setCaseId(undefined);
    this.agentService.clearSavedData();
    this.userService.clearUserDetails();
    this.utilsService.clearPdfData();
    this.delegationService.resetUserAccessForCase();
  }
}
