<section class="case-info-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Case Information
    </h1>
    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>

    <div *ngIf="showLoader">
      <lfg-loader></lfg-loader>
    </div>
    <section>
      <form class="form-group" [formGroup]="caseInfoForm" id="caseInfoForm" method="post" *ngIf="caseInfoData && caseInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of caseInfoData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText === 'Header'">
            <h2>{{ data.questionText }}</h2>
          </div>
          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText !== 'Line' && data.subText !== 'Header' && data.subText !== 'Button' && data.notes !== 'Hidden'">
            {{ data.questionText }}
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>
          <!-- Form element for text  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT">
            <lfg-input-wrap>
              <input lfgInput id="{{data.fieldId}}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ data.fieldId }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <div aria-live="assertive" *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid">
                <lfg-error message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </div>
            </lfg-input-wrap>
          </div>

          <!-- Form Element for date  -->
          <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
            <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="caseInfoForm.get(data.fieldId)" [config]="dateConfig" [class.calendar--err]="(caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
              <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid && !caseInfoForm.get(data.fieldId).errors?.dobLimitError" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid && caseInfoForm.get(data.fieldId).errors?.dobLimitError" message="{{appConfig.errorMessages.BASIC_DOB_ERROR}}"></lfg-error>
            </lfg-calendar>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" id="{{data.fieldId}}" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxLength" [required]="data.required == 'true'">
              <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange($event, data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid) }">
            </lfg-dropdown>
            <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>

          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.notes !== 'Hidden'" [ngClass]="{'radio--err': (caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid)}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="caseInfoForm.get(data.fieldId).touched && caseInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form element for Button -->
          <div class="form--control form--length-btn find-btn" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Button'">
            <button id="{{displayIdCreation(data)}}_{{ind}}" (click)="loadAvailableProduct(true); focusOff($event);" class="btn focusOffBtn" [disabled]="!selectedProductType || !selectedStateCode || disableForm">{{data.questionText}}</button>
          </div>

        </ng-container>

        <!-- product listing componnent -->
        <div class="refresh-btn-container">
          <a class="btn-link btn-refresh" [ngClass]="{'btn--link-disabled': disableForm}" id="refreshBtn" type="button" (click)="refresh()">
            <i class="fas fa-redo refresh-icon" aria-hidden="true"></i> Refresh </a>
        </div>

        <div class="section-divider"></div>
        <div *ngIf="productList?.length > 0">
          <div class="product-list-content">
            <app-product-list (selectedProduct)="updateSelectedProduct($event)" [productList]="productList" [selectedProductCode]="selectedProductCode" [disableForm]="disableForm"></app-product-list>
          </div>
        </div>
        <div *ngIf="productList?.length == 0 && !noProductAvailable">
          <span class="productlist-noproduct"> Select options above to view available products.</span>
        </div>
        <div *ngIf="noProductAvailable && productList?.length == 0">
          <span class="productlist-noproduct"> No product available for selected state and product type.</span>
        </div>
      </form>
    </section>

  </div>


</section>