import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { LfgLoaderService } from '@ng/lfg-loader';
import { timer } from 'rxjs';
import { ServiceInvoker } from './shared/services/service-invoker';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'lfg-eticket-ui';
  systemoutage;
  showLoader = true;

  constructor(
    private authService: AuthService,
    private loaderService: LfgLoaderService,
    private serviceInvoker: ServiceInvoker
  ) { }

  ngOnInit(): void {
    this.loaderService.show();
    this.authService.error$.subscribe((_error) => {
      this.authService.loginWithRedirect();
    });
  }

  isSystemDown(event: any): void {
    this.systemoutage = event === 'yes' ? 'yes' : 'no';
    this.loaderService.hide();
    this.showLoader = false;
    this.pingServer();
  }

  private pingServer(): void {
    timer(0, 3 * 60 * 1000).subscribe(() => {
      this.serviceInvoker.pingServer();
    });
  }

}
