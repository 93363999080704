import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Product } from 'src/app/shared/models/casePage.model';
import { FormGroup, FormControl } from '@angular/forms';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { ProductCodes, ProductType } from 'src/config/sideNav.config';
import { ErrorMessage } from 'src/config/constants';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  @Input() disableForm: boolean;
  @Input()
  productList: Product[];

  @Input()
  selectedProductCode: string;

  @Output() selectedProduct: EventEmitter<Product> = new EventEmitter();

  productInfoForm: FormGroup;
  showProductUnavailableMessage = false;
  VULSVULUnavailableMessage = ErrorMessage.VUL_SVUL_21_UNAVAILABLE;

  constructor(private defaultPageLoadService: DefaultPageload) {
    // This is intentional
  }

  ngOnInit(): void {
    this.productInfoForm = new FormGroup({});
    this.productInfoForm.addControl('product', new FormControl());
    if (this.selectedProductCode) {
      this.productInfoForm.get('product').setValue(this.selectedProductCode);
    }
    this.filterProductList();
    this.defaultPageLoadService.disableFormIfLocked(this.productInfoForm, this.disableForm);
  }

  onProductSelect(product: Product): void {
    this.selectedProduct.emit(product);
  }

  private filterProductList(): void {
    this.productList?.forEach(product => {
      if ((!this.disableForm && this.checkForVULOrSVULProduct(product.productName))
        || ((this.disableForm && this.checkForVULOrSVULProduct(product.productName)
          && (this.selectedProductCode.toLowerCase() !== ProductCodes.VULONE2021 && this.selectedProductCode.toLowerCase() !== ProductCodes.SVULONE2021)))) {
        product.hideProduct = true;
      }
    });
    if (!this.disableForm && this.checkForVULOrSVULProduct(this.selectedProductCode)) {
      this.showProductUnavailableMessage = true;
      this.selectedProduct.emit(undefined);
    }
  }

  private checkForVULOrSVULProduct(productName: string) {
    return productName?.toLowerCase() === ProductCodes.VULONE2021 || productName?.toLowerCase() === ProductCodes.SVULONE2021
  }
}
