<section class="summary-info-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color"> Summary Information </h1>
  </div>
  <ng-container>

    <!-- Proposed insured information -->
    <div class="questions-header">
      <h2>{{ 'PROPOSED INSURED INFORMATION' | uppercase }}</h2>
    </div>

    <div class="info-section">
      <div class="column-section column-section-first">
        <ng-container *ngFor="let data of dataMap.get('col1') ">
          <!-- show name -->
          <div *ngIf="data.subText == 'Name'">
            <strong>{{data.question_answer}}</strong>
          </div>

          <!-- show address -->
          <div *ngIf="data.subText == 'Address1'"><span class="break-word">{{data.question_answer}}</span></div>
          <div *ngIf="data.subText == 'Address2'">
            <span class="break-word">{{data.question_answer}}</span>
          </div>

          <!-- show other informations -->
          <div *ngIf="data.subText == 'Info'">
            {{data.questionText}}&nbsp;<span class="break-word"><strong>{{data.question_answer}}</strong></span>
          </div>

        </ng-container>
      </div>

      <div class="column-section column-section-next">
        <ng-container *ngFor="let data of dataMap.get('col2')">
          <!-- show age details -->
          <div *ngIf="data.subText == 'Age'">
            <span [innerHTML]="data.question_answer"></span>
          </div>

          <div *ngIf="data.subText == 'Info'">
            {{data.questionText}}&nbsp;<span class="break-word"><strong>{{data.question_answer}}</strong></span>
          </div>

        </ng-container>
      </div>
    </div>

    <div class="section-divider"></div>

    <!-- Contact information -->
    <div class="questions-header">
      <h2>{{ 'CONTACT INFORMATION' | uppercase }}</h2>
    </div>

    <div class="info-section">
      <div class="column-section column-section-first">
        <ng-container *ngFor="let data of dataMap.get('col3')">
          <div *ngIf="data.subText == 'Info' && data.questionText.indexOf('Phone') === -1">
            {{data.questionText}}&nbsp;<span class="break-word"><strong>{{data.question_answer}}</strong></span>
          </div>
          <div *ngIf="data.subText == 'Info' && data.questionText.indexOf('Phone') > -1">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer | phoneNumber }}</strong>
          </div>
        </ng-container>
      </div>

      <div class="column-section column-section-next">
        <ng-container *ngFor="let data of dataMap.get('col4')">
          <div *ngIf="data.subText == 'Info'">
            {{data.questionText}}&nbsp;<span class="break-word"><strong>{{data.question_answer}}</strong></span>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="section-divider"></div>

    <!-- Contract information -->
    <div class="questions-header">
      <h2>{{ 'CONTRACT INFORMATION' | uppercase }}</h2>
    </div>

    <div class="info-section">
      <div class="column-section column-section-first">
        <ng-container *ngFor="let data of dataMap.get('col5')">
          <div *ngIf="data.subText == 'Info' && data.questionText?.toLowerCase()?.indexOf('amount') === -1">
            {{data.questionText}}&nbsp;<span class="break-by-word"><strong>{{data.question_answer}}</strong></span>
          </div>
          <div *ngIf="data.subText == 'Info' && data.questionText?.toLowerCase()?.indexOf('amount') > -1">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer | currency}}</strong>
          </div>
        </ng-container>
      </div>

      <div class="column-section column-section-next">
        <ng-container *ngFor="let data of dataMap.get('col6')">
          <div *ngIf="data.subText == 'Info' && data.questionText?.toLowerCase()?.indexOf('amount') === -1">
            {{data.questionText}}&nbsp;<span class="break-by-word"><strong>{{data.question_answer}}</strong></span>
          </div>
          <div *ngIf="data.subText == 'Info' && data.questionText?.toLowerCase()?.indexOf('amount') > -1">
            {{data.questionText}}&nbsp;<strong>{{data.question_answer | currency}}</strong>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="section-divider"></div>

    <!-- Agent information -->
    <div class="questions-header">
      <h2>{{ 'AGENT INFORMATION' | uppercase }}</h2>
    </div>

    <lfg-primary-table [tableData]="tableData" id="case-info-dbtable" *ngIf="tableDataLoaded">
      <ng-template #tableCelContentTemplate let-column="column" let-row="row">
        <div tabindex="0" *ngIf="column.columnFlag === 'name'">
          {{ row.agentName }}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'phone'">
          {{ row.agentPhone | phoneNumber}}
        </div>
        <div tabindex="0" *ngIf="column.columnFlag === 'email'">
          {{ row.agentEmail }}
        </div>
      </ng-template>
    </lfg-primary-table>

  </ng-container>
</section>
