import { NgModule } from '@angular/core';

import { DashboardComponent } from './dashboard.component';
import { EticketDashboardTableComponent } from './eticket-dashboard-table/eticket-dashboard-table.component';
import { EticketSearchComponent } from './eticket-search/eticket-search.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    DashboardComponent,
    EticketSearchComponent,
    EticketDashboardTableComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [ ],
  bootstrap: [DashboardComponent]
})
export class DashboardModule { }
