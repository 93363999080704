export class Product {
  productName: string;
  productDetail?: ProductDetail;
  hideProduct?: boolean;
}

export class Company {
  companyName: string;
  companyId: number;
  internFlag: boolean;
}

export class Case {
  caseId?: string;
  insuredFirstName?: string;
  insuredLastName?: string;
  status?: string;
  productName?: string;
  createdDate?: string;
  updatedDate?: string;
  nextPageItem?: string;
  additionalInfo?: any;
  noOfDaysPassed?: number;
}

export class ProductDetail {
  mktName: string;
  condition: Condition;
}

export class Condition {
  minFaceAmount: number;
  maxFaceAmount: number;
  termMinAge: number;
  termMaxAge: number;
  ageConditions: AgeCondition[];
}

export class AgeCondition {
  durationList: number[];
  termProductName?: string;
  minAge: number;
  maxAge: number;
  termMktName?: string;
  tabaccoStatusList: string[];
  stateList: string[];
  excludedStateList: string[];
}
export interface ResponseObject {
  responseStatus: string;
  data: any;
  error: any;
}

export interface UpdateCaseDataRequestObject {
  caseInfo: Case;
  pageGroup: PageJsonObject;
  pageStatus: string;
  isProductUpdated?: boolean;
  isProfileUpdated?: boolean;
  stateCode?: string;
  productType?: string;
  insuredAge?: string;
}

export interface DeleteCaseIdsObject {
  caseIds: string[];
}

export const PageStatusValue = {
  VALID: 'valid',
  INVALID: 'invalid',
  NOTVISITED: 'notVisited',
};

export interface GetCaseDataRequestObject {
  pageGroupName: string;
  product?: string;
  stateCode?: string;
  caseId?: string;
  productType?: string;
  insuredAge?: string;
}

export interface CaseAndPageStatus {
  pageStatusList: PageStatus[];
  caseStatus: CaseStatus;
  additionalInfo?: any;
}

export interface CaseStatus {
  isLocked: boolean;
  status?: string;
  lastVisitedPage?: string;
  isCreatedByDelegate?: boolean;
  isLockedByDelegate?: boolean;
}

export interface PageStatus {
  pageGroupName: string;
  pageStatus: string;
}

export interface PageJsonObject {
  page_group_name: string;
  page_group_id?: string;
  pages?: Page[];
}

export interface AgentFirmResp {
  agentNPN: string;
  firmList: [];
}

export interface Page {
  sequence: string;
  name: string;
  startRuleGroupName: string;
  endRuleGroupName: string;
  questions: Question[];
  gridInstances?: GridInstance[];
  gridAnswers?: any;
  insuredAsOwner?: any[];
  ownerAsBene?: any[];
}

export interface GridInstance {
  id: string;
  sequence: string;
  gridName: string;
  minGridIndex: string;
  maxGridIndex: string;
  repeatingBlockID: string;
  gridQuestions: Question[];
  answersBlock: any[];
}

export interface Question {
  id: string;
  sequence: string;
  name: string;
  display?: boolean;
  displayConditionXpath?: string;
  changeConditionXpath?: string;
  choiceID?: string;
  required: string;
  minLength: string;
  maxLength: string;
  answerTextType: string;
  controlType: string;
  ruleGroupName?: string;
  reEvaluateRule: string;
  repostPage?: string;
  tagDataType?: string;
  translateTCDtoVal_Desc?: boolean;
  subText?: string;
  tooltip?: string;
  gridId?: string;
  optionChoice?: string;
  option_condition_count?: number;
  hasReflexive: string;
  topLevelQuestionName?: string;
  decimalPlacesAllowed: string;
  questionText: string;
  question_answer: string;
  questionOption: QuestionOption[];
  reflexiveQuestionAL: ReflexiveQuestionAL[];
  controlTypeDesc: string;
  notes?: string;
  xmlTag: string;
  fieldId: string;
  change: boolean;
}

export interface ReflexiveQuestionAL {
  id: string;
  sequence: string;
  name: string;
  required: string;
  minLength: string;
  maxLength: string;
  display: boolean;
  answerTextType: string;
  controlType: string;
  ruleGroupName?: string;
  reEvaluateRule: string;
  hasReflexive: string;
  topLevelQuestionName: string;
  decimalPlacesAllowed: string;
  questionText: string;
  question_answer: string;
  questionOption: QuestionOption[];
  reflexiveQuestionAL: ReflexiveQuestionAL[];
  controlTypeDesc: string;
  optionChoice: string;
  subText?: string;
  fieldId: string;
  notes?: string;
  xmlTag?: string;
}

export interface QuestionOption {
  value?: string;
  description?: string;
  label?: string;
}
export interface DropdownOption {
  value?: string;
  checked?: boolean;
  label?: string;
}

export interface AgentProfile {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  allStateFlag: boolean;
  proxyGuid?: string;
  roleCodes?: string;
  c360Id?: string;
  delegatee?: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
  };
  isAgentFlag?: boolean;
  limitedAccess?: boolean;
  fullaccess?: boolean;
  isHOuser?: boolean;
}

export interface FileDetails {
  caseId?: string;
  fileName?: string;
  fileSize?: string;
  fileData?: string;
  fileType?: string;
  userDefinedName?: string;
  comment?: string;
}

export interface Recipient {
  id: string;
  signerGuid: string;
  roleId: string;
  roleName: string;
  type: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  routingOrder: string;
  captiveRecipient: string;
  captiveInfo: any;
  eSignatureMethod?: string;
  displayRole?: string;
  docusignStatus?: string;
  statusUpdateDt: string;
  sMSAuthentication?: {
    senderProvidedNumbers?: {
      senderProvidedNumber?: string[];
    }
  };
  address?: AddressState;
  displayDocuSignStatus?: string;
  displayIconClass?: string;
}

export interface AddressState {
  state: string;
}

export interface PackageInfo {
  envelopeCreatedDate: string;
  envelopeId: string;
  envelopeStatus: string;
  recipients: Recipient[];
}

export interface UserDetails {
  SSN?: string;
  dob?: string;
  email_address: string;
  name: string;
  phone_number: string;
  signatureMethod: string;
  status: string;
  type: string;
}

export interface PackageDetails {
  cancelledPackets: PackageInfo[];
  activePacket: PackageInfo;
}

export const MessageType = {
  OUTAGE: 'outage',
  INFO: 'info',
  WISH: 'wish',
  WARNING: 'warning'
};
