import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GridInstance, Question, QuestionOption } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, WAReplacementConst, WAReplacementProposedBConst } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-wa-replacement',
  templateUrl: './wa-replacement.component.html',
  styleUrls: ['./wa-replacement.component.scss']
})
export class WaReplacementComponent implements OnInit {

  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Input() forProposedInsured: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  waReplacementData: Question[] = [];
  waReplacementForm: FormGroup;
  gridInstance: GridInstance[];
  gridAnswers: any[];
  formValid = true;
  formHasNoErrors = true;
  gridHasNoErrors = false;
  isGridFormValid = true;
  isGridFormChange = false;

  maxNoOfPolicy: number;
  insurerOptions: any;

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.gridInstance = this.questionsData?.pages[0]?.gridInstances;
      this.gridAnswers = this.questionsData?.pages[0]?.gridAnswers;
      this.maxNoOfPolicy = +(this.gridInstance[0]?.maxGridIndex);
      this.createExistingInsuranceDropDown();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.waReplacementForm);
      this.defaultPageLoadService.disableFormIfLocked(this.waReplacementForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  private buildFormData(): any {
    this.waReplacementData = this.questionsData.pages[0].questions;
    const form = this.formgeneratorService.createFormControls(
      this.waReplacementData
    );
    this.waReplacementForm = this.fb.group(form);
    this.valueChanges();
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.waReplacementForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.waReplacementForm,
      data: null,
    };
    for (const data of this.waReplacementData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.emitStatus();
  }

  waReplacementOnRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  waReplacementHandleSelectionChange(data, _ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  waReplacementAddCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.waReplacementForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.waReplacementForm.get(key)?.markAsUntouched();
      }
      this.waReplacementForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.waReplacementForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckwaReplacement(data, childData, parent): any {
    return data.controlTypeDesc === this.appConfig.fieldTypes.SELECT
      ? parent && parent.value === childData.optionChoice
      : parent === childData.optionChoice;
  }

  onCheckboxChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  getErrorMsg(data, formControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  gridformStatus(event): void {
    this.isGridFormValid = event?.formValid;
    this.gridHasNoErrors = event.formHasNoErrors;
    this.gridAnswers = event.gridAnswers;
    this.isGridFormChange = event.isGridFormChange;
    this.emitStatus();
  }

  emitStatus(): void {
    this.questions.pages[0].gridAnswers = this.gridAnswers;
    const existingInsuranceInfoObj = {
      formValid: this.formValid && this.isGridFormValid,
      formHasNoErrors: this.formHasNoErrors && this.gridHasNoErrors,
      questions: this.questions,
      formId: this.forProposedInsured ? 'waReplacementFormProposedB' : 'waReplacementForm',
      gridForm: true,
      isFormChange: this.waReplacementForm.dirty || this.isGridFormChange || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.waReplacementForm),
    };
    this.formStatus.emit(existingInsuranceInfoObj);
  }

  private createExistingInsuranceDropDown(): void {
    const value = 'Company Not Listed';
    const description = 'Company Not Listed';
    const label = 'Company Not Listed';
    const quesOptions: QuestionOption[] = [];
    const companyList = this.defaultPageLoadService.getExistingInsuranceCompanyList();
    if (companyList?.length > 0) {
      this.utilsService.createDropDownFromList(companyList, quesOptions);
    }
    quesOptions.push({ value, description, label });
    this.insurerOptions = this.utilsService.formatOptions(quesOptions);
    this.updateQuestionDataWithFirm(quesOptions);
  }

  private updateQuestionDataWithFirm(quesOptions: QuestionOption[]): void {
    this.gridInstance[0].gridQuestions.forEach((question) => {
      if (question.xmlTag === WAReplacementConst.companyDropdown
        || question.xmlTag === WAReplacementProposedBConst.companyDropdown) {
        question.questionOption = quesOptions;
      }
    });
  }
}
