<!-- Main content -->
<main role="main" class="no-margin-top">
  <div *ngIf="showLoader">
    <lfg-loader></lfg-loader>
  </div>

  <!-- Breadcrumbs -->
  <!-- Banner Component -->
  <div class="u-global-container">
    <article class="main-content main-content--full" data-event-type="mclink">
      <section class="content-area">
        <app-system-message (hasError)="isSystemDown($event)"></app-system-message>
        <ng-container *ngIf="systemoutage === 'no'">
          <router-outlet></router-outlet>
        </ng-container>
      </section>
    </article>
  </div>
</main>
