<app-base-page-welcome></app-base-page-welcome>
<div class="base-link-block">
  <a (click)="rdToDashboard()">
    <i class="far fa-arrow-alt-circle-right"></i>
    <span>Dashboard</span>
  </a>
</div>
<ng-container *ngIf="activeTab && activeTab.name !== TAB.CASE_INFO">
  <app-base-page-header></app-base-page-header>
</ng-container>
<section class="main-container">
  <aside role="Sidebar Navigation" class="progressBar-tabs__container">
    <app-side-nav></app-side-nav>
  </aside>
  <section class="progressBar-content">
    <div class="progressBar-content__header">
      APPLICATION
      <a (click)="getHelpData()">
        <span class="help-icon"><i class="fas fa-question-circle help-icon-image"></i>Help</span>
      </a>
      <div class="help-wrapper" id="wrapperdiv" aria-live="assertive" *ngIf="displayHelpData">
        <app-help-hint (closeSlide)="closeSlide($event)" [activeTab]="activeTab?.name" [ngClass]="closeHelpHint ? 'help-slide-right' : 'help-slide-left'"></app-help-hint>
      </div>
    </div>
    <div *ngIf="showLoader > 0">
      <lfg-loader></lfg-loader>
    </div>
    <ng-container *ngIf="displayProgressModal">
      <app-progress-overlay [modalStateInput]="displayProgressModal" [messageToshow]="Message.POLICY_SUBMISSION_LOADER_MESSAGE"></app-progress-overlay>
    </ng-container>
    <ng-container *ngIf="displayProgressModalForESign">
      <app-progress-overlay [modalStateInput]="displayProgressModalForESign" [messageToshow]="isAllSignerRemote ? Message.ENVELOPE_CREATION_LOADER_MESSAGE : Message.BOTH_ENVELOPE_CREATION_LOADER_MESSAGE" [hasProgressPercentage]="true" [progressPercent]="progressPercentage"></app-progress-overlay>
    </ng-container>

    <lfg-modal-popup *ngIf="areYouSureModalConfig" [modalConfig]="areYouSureModalConfig" (closeModalPopup)="updateAreYouSureModalPopup(false)">
      <div modal-header>
        <h1>Are you sure?</h1>
      </div>
      <div modal-body>
        Unlocking the page will delete all completed signatures and start the process again.
      </div>
      <div modal-footer>
        <lfg-left-button-tray>
          <button class='btn' lfgDefaultButton aria-label="Unlock page" (click)="unlockPageAndVoidEnvelope()">YES</button>
          <button class='btn' lfgDefaultButton aria-label="Cancel Unlock" (click)="updateAreYouSureModalPopup(false)">CANCEL</button>
        </lfg-left-button-tray>
      </div>
    </lfg-modal-popup>

    <lfg-modal-popup *ngIf="signatureMethodConfirmationModal" [modalConfig]="signatureMethodConfirmationModal" (closeModalPopup)="updateSignatureMethodModal(false)">
      <div modal-header>
        <h1>{{ConfirmationModalMessage.MODAL_HEADING}}</h1>
      </div>
      <div modal-body>
        {{ConfirmationModalMessage.INFO_TEXT_1}}
        <div class="modal-popup-list">
          <ul>
            <li>{{ConfirmationModalMessage.INFO_TEXT_2}}</li>
            <li>{{ConfirmationModalMessage.INFO_TEXT_3}}</li>
            <li>{{ConfirmationModalMessage.INFO_TEXT_4}}</li>
          </ul>
        </div>
      </div>
      <div modal-footer>
        <lfg-left-button-tray>
          <button class='btn' lfgDefaultButton aria-label="Continue" (click)="continueOnSignatureMethodModal()">Continue</button>
          <button class='btn' lfgLinkButton aria-label="Cancel" (click)="updateSignatureMethodModal(false)">Cancel</button>
        </lfg-left-button-tray>
      </div>
    </lfg-modal-popup>

    <ng-container *ngIf="systemMessage?.type === MessageType.OUTAGE">
      <div class="application-unavailable-container">
        <lfg-alert-message type="error">{{systemMessage?.message}}</lfg-alert-message>
      </div>
    </ng-container>
    <ng-container *ngIf="activeTab && systemMessage?.type !== MessageType.OUTAGE">
      <div class="progressBar-content__pageContainer">
        <div class="progressBar-content__subheader">
          <div class="page-wrapper">
            <ng-container *ngIf="activeTab.name === TAB.CASE_INFO && eticketQuestions.get(activeTab.name)">
              <app-case-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" (caseInfoUpdated)="caseInfoUpdated($event)" (insuredProfileUpdated)="insuredProfileUpdated($event)" (caseInfoLoaded)="caseInfoLoaded($event)" [disableForm]="checkIfActiveTabLocked()"></app-case-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.ATTESTATION && eticketQuestions.get(activeTab.name)">
              <app-attestation [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails"></app-attestation>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.PRIMARY_INSURED && eticketQuestions.get(activeTab.name)">
              <app-primary-insured [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (ownerInfoNeeded)="ownerInfoNeeded($event)"></app-primary-insured>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.PROPOSED_INSURED_B && eticketQuestions.get(activeTab.name)">
              <app-proposed-insured-b [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-proposed-insured-b>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.COVERAGE_INFO && eticketQuestions.get(activeTab.name)">
              <app-coverage-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event) " [disableForm]="checkIfActiveTabLocked()" (isIllustrationSelected)="isIllustrationSelected($event)" (eftInfoUpdated)="updateEftTab($event)"></app-coverage-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.RIDERS_INFO && eticketQuestions.get(activeTab.name)">
              <app-riders-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (isLTCSelected)="isLTCSelected($event)" (otherRiderSelected)="isOtherRiderSelected($event)" (levOrSveeRiderSelected)="riderValueUpdate($event)"></app-riders-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.OWNER_INFO && eticketQuestions.get(activeTab.name)">
              <app-owner-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (ownerInfoUpdated)="ownerInfoUpdated($event)"></app-owner-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.ILLUSTRATION_INFORMATION && eticketQuestions.get(activeTab.name)">
              <app-illustration-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-illustration-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.TRUST_INFO && eticketQuestions.get(activeTab.name)">
              <app-trust-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-trust-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.ELECTRONIC_FUND_TRANSFER && eticketQuestions.get(activeTab.name)">
              <app-electronic-fund-transfer [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()">
              </app-electronic-fund-transfer>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.WA_REPLACEMENT && eticketQuestions.get(activeTab.name)">
              <app-wa-replacement [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [forProposedInsured]="false"></app-wa-replacement>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.WA_REPLACEMENT_PROPOSED_B && eticketQuestions.get(activeTab.name)">
              <app-wa-replacement [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [forProposedInsured]="true"></app-wa-replacement>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.TEMPORARY_LIFE && eticketQuestions.get(activeTab.name)">
              <app-temporary-life-insurance [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()">
              </app-temporary-life-insurance>
            </ng-container>
            <ng-conainer *ngIf="activeTab.name === TAB.TRANSFER_ALLOCATION_CHARGES_AUTHORIZATION && eticketQuestions.get(activeTab.name)">
              <app-transfer-allocation-charges-authorization [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()">
              </app-transfer-allocation-charges-authorization>
            </ng-conainer>
            <ng-container *ngIf="activeTab.name === TAB.EXISTING_INSURANCE && eticketQuestions.get(activeTab.name)">
              <app-existing-insurance [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (replacementPolicyAdded)="replacementPolicyAdded($event)" (existingInsMainQuesValueUpdate)="existingInsMainQuesValueUpdate($event)" [forProposedInsured]="false"></app-existing-insurance>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.EXISTING_INSURANCE_FOR_PROPOSED_B && eticketQuestions.get(activeTab.name)">
              <app-existing-insurance [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (replacementPolicyAdded)="replacementPolicyAddedProposedB($event)" (existingInsMainQuesValueUpdate)="existingInsMainQuesValueUpdate($event)" [forProposedInsured]="true"></app-existing-insurance>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.LTC_INSURANCE && eticketQuestions.get(activeTab.name)">
              <app-ltc-existing-insurance [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-ltc-existing-insurance>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.SUITABILITY_REVIEW && eticketQuestions.get(activeTab.name)">
              <app-suitability-review [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-suitability-review>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.AGENT_INFO && eticketQuestions.get(activeTab.name)">
              <app-agent-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-agent-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.AGENT_INFO_CONT && eticketQuestions.get(activeTab.name)">
              <app-agent-info-cont [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-agent-info-cont>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.ADDITIONAL_INFO && eticketQuestions.get(activeTab.name)">
              <app-additional-info [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-additional-info>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.APPLICATION_SUMMARY && eticketQuestions.get(activeTab.name)">
              <app-application-summary [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)"></app-application-summary>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.VALIDATE_AND_LOCK && eticketQuestions.get(activeTab.name)">
              <app-validate-and-lock [questions]="eticketQuestions.get(activeTab.name)"  (formStatus)="formStatus($event)" (proceedToSigning)="proceedToSign($event)" (deleteTabs)="deleteTabsAfterUnlock($event)" (recipientList)="recipientListUpdated($event)" [disableForm]="checkIfActiveTabLocked()" (caseUnlocked)="caseUnlocked($event)" [userAccessDetails]="userAccessDetails" (lockStatusUpdated)="lockStatusUpdated($event)">
              </app-validate-and-lock>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.SIGNATURE_METHOD && eticketQuestions.get(activeTab.name) && signerList?.length > 0">
              <app-signature-method [questions]="eticketQuestions.get(activeTab.name)" [signerList]="signerList" [agentSignerInfo]="agentSignerInfo" (formStatus)="formStatus($event)" (signatureMethodUpdated)="signatureMethodUpdated($event)" [disableForm]="checkIfActiveTabLocked()"></app-signature-method>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.VOICE_CONFIRM_THANK_YOU">
              <app-voice-confirm-thank-you></app-voice-confirm-thank-you>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.FUND_ALLOCATION && eticketQuestions.get(activeTab.name)">
              <app-fund-allocation [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" (isMIRequired)="isMIRequired($event)" (dcaInfoUpdated)="updateDCATab($event)"></app-fund-allocation>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.VUL_FUND_ALLOCATION && eticketQuestions.get(activeTab.name)">
              <app-fund-allocation-vul [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-fund-allocation-vul>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.DOLLAR_COST_AVERAGING && eticketQuestions.get(activeTab.name)">
              <app-dollar-cost-averaging [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-dollar-cost-averaging>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.MATURING_INDEXED_ACCOUNT_SEGMENT_ALLOCATION && eticketQuestions.get(activeTab.name)">
              <app-maturing-indexed-account-segment-allocation [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()"></app-maturing-indexed-account-segment-allocation>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.VOICE_TERM_OF_USE && eticketQuestions.get(activeTab.name)">
              <app-term-of-use [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [disableForm]="checkIfActiveTabLocked()" [policyCreationError]="policyCreationError"></app-term-of-use>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.ELECTRONIC_SIGNATURE">
              <app-electronic-signature [disableForm]="checkIfActiveTabLocked()" (activePacketUpdated)="activePacketUpdated($event)" (packetCancelled)="packetCancelled($event)"></app-electronic-signature>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.AGENT_INSTRUCTION && eticketQuestions.get(activeTab.name) && signerList?.length > 0">
              <app-agent-instruction [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signerList]="signerList" [errorOccuredToGetTransactionId]="errorOccuredGettingTransactionId" [errorOccuredWhileCreateEnvelope]="errorOccuredCreateEnvelope" [envelopeCreated]="envelopeCreated" [disableForm]="checkIfActiveTabLocked()"></app-agent-instruction>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.E_SIGNATURE_INSTRUCTION && eticketQuestions.get(activeTab.name) && signerList.length > 0">
              <app-esignature-instruction [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signerList]="signerList" [errorOccuredToGetTransactionId]="errorOccuredGettingTransactionIdRemote" [errorOccuredWhileCreateEnvelope]="errorOccuredCreateEnvelopeRemote" [envelopeCreated]="envelopeCreatedRemote" [disableForm]="checkIfActiveTabLocked()"></app-esignature-instruction>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_PI && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_INSURED_OWNER && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_INSURED_B && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_ADDITIONAL_INSURED && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_AGENT && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_OWNER && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_COOWNER && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_COOWNER2 && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.CONSENT_PAGE_COOWNER3 && eticketQuestions.get(activeTab.name) && activePacket && signerList.length > 0">
              <app-consent-page [questions]="eticketQuestions.get(activeTab.name)" (formStatus)="formStatus($event)" [signer]="getSignerbyTab(activeTab.name)" [activePacket]="activePacket" (signingStatusUpdated)="signingStatusUpdated($event)" (consentDeclined)="consentDeclined($event)" [disableForm]="checkIfActiveTabLocked()" [userAccessDetails]="userAccessDetails" [userFunctionalityConfig]="userFunctionalityConfig"></app-consent-page>
            </ng-container>
            <ng-container *ngIf="activeTab.name === TAB.REVIEW_AND_SUBMIT">
              <app-thankyou-page [thankyouPageType]="'reviewAndSubmit'" (policySubmitted)="policySubmitted($event)" [isPolicySubmitted]="isPolicySubmitted" [userAccessDetails]="userAccessDetails"></app-thankyou-page>
            </ng-container>
          </div>
        </div>

      </div>

      <ng-container *ngIf="(eticketQuestions.get(activeTab.name) || activeTab.name == TAB.ELECTRONIC_SIGNATURE) && activeTab.name !== TAB.VALIDATE_AND_LOCK">
        <div class="footer-section-divider" *ngIf="(activeTab.name !== TAB.TEMPORARY_LIFE && activeTab.name !== TAB.REVIEW_AND_SUBMIT)"></div>
        <section class="button-container" *ngIf="activeTab.name === TAB.CASE_INFO; else buttonTray">
          <div class="caseInfo--continue__btncntr">
            <lfg-left-button-tray>
              <button lfgDefaultButton type="submit" class="continue" id="continue" tabindex="0" aria-label="Continue" [disabled]="buttonStatus.nextDisable || checkIfActiveTabLocked()" (click)="continue(); focusOff($event);">CONTINUE</button>
            </lfg-left-button-tray>
          </div>
        </section>
        <ng-template #buttonTray>
          <section class="button-container" *ngIf="(activeTab.name !== TAB.VOICE_CONFIRM_THANK_YOU) && (activeTab.name !== TAB.REVIEW_AND_SUBMIT)">
            <lfg-left-button-tray *ngIf="!buttonStatus.backDisable">
              <button class="btn" [ngClass]="{'btn--whitebg-border': !checkIfActiveTabLocked()}" type="button" id="back" tabindex="0" aria-label="Back" [disabled]="buttonStatus.backDisable || checkIfActiveTabLocked()" (click)="previousTab()">Back</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="activeTab.name !== TAB.VOICE_TERM_OF_USE && !TABS_ALWAYS_IN_GOOD_ORDER.includes(activeTab.name)">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Next" [disabled]="buttonStatus.nextDisable || checkIfActiveTabLocked()" (click)="nextTab(); focusOff($event);">NEXT</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="activeTab.name == TAB.ELECTRONIC_SIGNATURE">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Next" (click)="nextTab(); focusOff($event);" [disabled]="!checkIfReviewAndSubmitAvailable() || checkIfActiveTabLocked()">NEXT</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="activeTab.name == TAB.VOICE_TERM_OF_USE">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Submit" [disabled]="buttonStatus.nextDisable || checkIfActiveTabLocked()" (click)="nextTab(); focusOff($event);">SUBMIT</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="(activeTab.name == TAB.SIGNATURE_METHOD || activeTab.name == TAB.AGENT_INSTRUCTION || activeTab.name == TAB.E_SIGNATURE_INSTRUCTION ) && checkIfActiveTabLocked()">
              <button lfgDefaultButton type="submit" id="next" tabindex="0" aria-label="Unlock page" (click)="updateAreYouSureModalPopup(true); focusOff($event);" [disabled]="isPolicySubmitted || signingCompleted || !userAccessDetails.caseRelatedAccess.unlockCase">UNLOCK</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="!tabsAfterLock.includes(activeTab.name)">
              <button lfgLinkButton type="button" id="saveAndExit" tabindex="0" aria-label="Save and exit" (click)="saveAndExit()" [disabled]="!userAccessDetails.editCase">Save &amp; exit</button>
            </lfg-left-button-tray>
            <lfg-left-button-tray *ngIf="tabsAfterLock.includes(activeTab.name)">
              <button lfgLinkButton type="button" id="saveAndExit" tabindex="0" aria-label="Save and exit" (click)="saveAndExit()" [disabled]="!userAccessDetails.editCase || userAccessDetails.readOnlyPostLock">Save &amp; exit</button>
            </lfg-left-button-tray>
          </section>
          <section class="button-container" *ngIf="activeTab.name == TAB.VOICE_CONFIRM_THANK_YOU">
            <lfg-left-button-tray>
              <button lfgDefaultButton type="button" id="close" tabindex="0" aria-label="Close" (click)="close()">Close</button>
            </lfg-left-button-tray>
          </section>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showConfirmationModal">
      <app-confirmation-modal (redirectionConfirmation)="confirmationModalResponse($event)"></app-confirmation-modal>
    </ng-container>


  </section>
</section>