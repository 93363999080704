<section class="thank-you-page page-content-container">
    <div class="questions--container">
        <h1 class="lfg-color">
            Voice Signature Confirmation
        </h1>
        <section class="msg-contain">
            <lfg-alert-message type="success">{{voiceConfirmMsg.SUCCESS_MSG}}</lfg-alert-message>
            <p>{{voiceConfirmMsg.YOUR_SIGNATURE_MSG}}</p>
            <p>{{voiceConfirmMsg.ESIGN_APP_MSG}}</p>
            <p>{{voiceConfirmMsg.THANK_YOU}}</p>
        </section>
    </div>
</section>
