import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DropdownOption, Question } from 'src/app/shared/models/casePage.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { EFTConstant, ErrorMessage, OwnerInfoConstant, PrimaryInsuredConstant } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-electronic-fund-transfer',
  templateUrl: './electronic-fund-transfer.component.html',
  styleUrls: ['./electronic-fund-transfer.component.scss']
})
export class ElectronicFundTransferComponent implements OnInit {

  @Input() disableForm: boolean;
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  questionsData;
  eftInfoForm: FormGroup;
  isTabVisited: boolean;
  ownerDetails: any;
  firstNameFieldId;
  lastNameFieldId;
  addressFieldId: string;
  entityFieldId: string;
  ownerDetailsWithAddr: any;
  selectedOptionDetails: any;
  cityFieldId: string;
  stateFieldId: string;
  zipFieldId: string;
  accountHolderFieldId: string;
  stateOBJ: any;
  insuredFirstName: string;
  insuredLastName: string;
  STATE: any;
  phoneNoFieldId: string;
  emailFieldId: string;
  phoneNoTypeFieldId: string;
  trustDetails: any;
  trustDetailsWithAddr: any;

  @Input()
  set questions(parentData: any) {
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }

  eftInfoData: Question[] = [];
  alldropDownOptions = {};
  formValid = true;
  formHasNoErrors = true;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  isBusinessEnhanceRiderSelected: boolean;

  formReset = false;
  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    public utilsService: UtilsService,
    private commonService: CommonService,
    private defaultPageLoadService: DefaultPageload,
    private userDetailsService: UserDetailsService,
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.isBusinessEnhanceRiderSelected = this.userDetailsService.getBusinessEnhanceRiderSeleted();
      this.buildFormData();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.eftInfoForm);
      this.defaultPageLoadService.logPageLoad();
      this.checkAccountHolderValue();
      this.defaultPageLoadService.disableFormIfLocked(this.eftInfoForm, this.disableForm);
    }
  }

  private checkAccountHolderValue(): void {
    const value = this.eftInfoForm
      .get(this.getFieldIdFromXMLTag(EFTConstant.AccountHolderName))?.value;
    if (value?.value?.toLowerCase().indexOf('other-individual') > -1) {
      return;
    } else if (value.value?.toLowerCase().indexOf('other-entity') > -1) {
      this.disableFirstLastName();
      return;
    } else {
      if (value && value !== '') {
        this.updateFields(value);
      }
    }
  }

  private updateFields(value: any): void {
    this.userDetailsService.setSelectedAccHolder(value);
    if (this.trustDetailsWithAddr && (value.value.toLowerCase().indexOf('trustinfo') > -1)) {
      this.selectedOptionDetails = this.trustDetailsWithAddr.filter((details) => {
        return ((details.label === value.label));
      });
      this.updateSelectedTrustDropdownValues(this.selectedOptionDetails);
    } else {
      if (this.ownerDetailsWithAddr) {
        this.selectedOptionDetails = this.ownerDetailsWithAddr.filter((details) => {
          return ((details.label === value.label));
        });
      } else {
        this.selectedOptionDetails = null;
      }
      this.updateSelectedDropdownValues(this.selectedOptionDetails);
    }
  }

  private buildFormData(): any {
    this.insuredFirstName = this.userDetailsService.getInsuredFirstName();
    this.insuredLastName = this.userDetailsService.getInsuredLastName();
    this.eftInfoData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.eftInfoData
    );
    const form = this.formgeneratorService.createFormControls(
      this.eftInfoData
    );
    this.eftInfoForm = this.fb.group(form);
    this.ownerDetails = this.userDetailsService.getOwnerDetails();
    this.ownerDetailsWithAddr = this.userDetailsService.getOwnerDetailsWithAddress();
    this.trustDetails = this.userDetailsService.getTrustDetails();
    this.trustDetailsWithAddr = this.userDetailsService.getTrustDetailsWithAddress();
    this.setFieldIds();
    this.valueChanges();
    this.updateDropdownListByOwners(this.alldropDownOptions);
    this.updateSelectedAccountHolder();
  }

  private updateSelectedAccountHolder(): void {
    this.accountHolderFieldId = this.getFieldIdFromXMLTag(EFTConstant.AccountHolderName);
    const selectedAccountHolder = this.userDetailsService.getSelectedAccHolder();
    if (selectedAccountHolder) {
      this.eftInfoForm.get(this.accountHolderFieldId)?.setValue(selectedAccountHolder);
      if ((selectedAccountHolder.value.toLowerCase().indexOf('trust') > -1) ||
        (selectedAccountHolder.value.toLowerCase().indexOf('person') > -1) ||
        (selectedAccountHolder.value.toLowerCase().indexOf('insuredname') > -1) ||
        (selectedAccountHolder.value?.toLowerCase().indexOf('other-individual') > -1)) {
        if (selectedAccountHolder.value.toLowerCase().indexOf('insuredname') > -1) {
          this.eftInfoForm.get(this.accountHolderFieldId)?.setValue(this.getInsuredValueFromDropdown());
        }
        this.setEntityNameEmptyDisable();
      }
    } else {
      this.eftInfoForm.get(this.accountHolderFieldId)?.setValue(this.getInsuredValueFromDropdown());
      this.setEntityNameEmptyDisable();
    }
  }

  private getInsuredValueFromDropdown(): any {
    return {
      checked: false,
      label: this.insuredFirstName + ' ' + this.insuredLastName,
      value: this.insuredFirstName + '{ insuredName }' + this.insuredLastName
    };
  }

  private setFieldIds(): void {
    this.firstNameFieldId = this.getFieldIdFromXMLTag(EFTConstant.firstNameXML);
    this.accountHolderFieldId = this.getFieldIdFromXMLTag(EFTConstant.AccountHolderName);
    this.lastNameFieldId = this.getFieldIdFromXMLTag(EFTConstant.lastNameXML);
    this.addressFieldId = this.getFieldIdFromXMLTag(EFTConstant.addressXML);
    this.entityFieldId = this.getFieldIdFromXMLTag(EFTConstant.EFTEntityNameXML);
    this.cityFieldId = this.getFieldIdFromXMLTag(OwnerInfoConstant.OWNER_CITY);
    this.stateFieldId = this.getFieldIdFromXMLTag(OwnerInfoConstant.EFT_STATE);
    this.zipFieldId = this.getFieldIdFromXMLTag(OwnerInfoConstant.OWNER_ZIP);
    this.phoneNoFieldId = this.getFieldIdFromXMLTag(PrimaryInsuredConstant.phoneNoXmlTag);
    this.emailFieldId = this.getFieldIdFromXMLTag(EFTConstant.emailAddressXML);
    this.phoneNoTypeFieldId = this.getFieldIdFromXMLTag(EFTConstant.primaryPhoneTypeXML);
  }

  private updateDropdownListByOwners(allDropdowns): any {
    const dropDownObj = [
      {
        checked: false,
        label: 'Other - Individual',
        value: 'Other-Individual{47}Other-Individual'
      },
      {
        checked: false,
        label: 'Other - Entity',
        value: 'Other-Entity{47}Other-Entity'
      },
      {
        checked: false,
        label: this.insuredFirstName + ' ' + this.insuredLastName,
        value: this.insuredFirstName + '{ insuredName }' + this.insuredLastName
      }
    ];
    let updatedDropDownArray;
    if (this.ownerDetails && !this.trustDetails) {
      updatedDropDownArray = [...dropDownObj, ...this.ownerDetails];
    } else if (!this.ownerDetails && this.trustDetails) {
      updatedDropDownArray = [...dropDownObj, ...this.trustDetails];
    } else if (this.ownerDetails && this.trustDetails) {
      updatedDropDownArray = [...dropDownObj, ...this.trustDetails, ...this.ownerDetails];
    } else {
      updatedDropDownArray = [...dropDownObj];
    }
    const accountHolderFieldIdForTerm = this.getFieldIdByXmlTag(this.eftInfoData, EFTConstant.AccountHolderName, true);
    const accountHolderFieldIdForLifeEle = this.getFieldIdByXmlTag(this.eftInfoData, EFTConstant.AccountHolderName, false);

    for (const item of Object.keys(allDropdowns)) { // Updating dropdown
      if ((item === accountHolderFieldIdForTerm) || (item === accountHolderFieldIdForLifeEle)) {
        allDropdowns[item] = updatedDropDownArray;
      }
    }
  }

  private getFieldIdByXmlTag(EFTQuestions, XMLTag, required?): string {
    if (required) {
      return EFTQuestions.filter(ques => {
        return (ques.xmlTag === XMLTag && (ques.required.toLowerCase() === 'true'));
      })[0]?.fieldId;
    } else {
      return EFTQuestions.filter(ques => {
        return ques.xmlTag === XMLTag && (ques.required.toLowerCase() === 'false');
      })[0]?.fieldId;
    }
  }

  private populateDataOnDropdownSelection(): void {
    this.eftInfoForm
      .get(this.getFieldIdFromXMLTag(EFTConstant.AccountHolderName))
      ?.valueChanges.pipe(take(1)).subscribe((value) => {
        this.userDetailsService.setSelectedAccHolder(value);
        if (this.trustDetailsWithAddr && (value.value.toLowerCase().indexOf('trustinfo') > -1)) {
          this.selectedOptionDetails = this.trustDetailsWithAddr.filter((details) => {
            return ((details.label === value.label) && (details.value === value.value));
          });
          this.updateSelectedTrustDropdownValues(this.selectedOptionDetails);
        } else {
          if (this.ownerDetailsWithAddr) {
            this.selectedOptionDetails = this.ownerDetailsWithAddr.filter((details) => {
              return ((details.label === value.label) && (details.value === value.value));
            });
          } else {
            this.selectedOptionDetails = null;
          }
          this.updateSelectedDropdownValues(this.selectedOptionDetails);
        }
      });
  }

  private updateSelectedTrustDropdownValues(seletedValue: DropdownOption): any {
    const value = seletedValue ? seletedValue[0] : undefined;
    this.userDetailsService.setSelectedAccHolder(value);
    this.STATE = null;
    const firstName = this.getEFTDetails(value, 'firstName');
    const lastName = this.getEFTDetails(value, 'lastName');
    const address = this.getEftAddress(value, 'streetAddress');
    const city = this.getEftAddress(value, 'city');
    this.getEftAddressState(value, 'state');
    const zipCode = this.getEftAddress(value, 'zip');
    const phone = this.getEFTDetails(value, 'phone');
    const phoneType = this.getEFTDetails(value, 'phoneType');
    const email = this.getEFTDetails(value, 'email');
    this.eftInfoForm.get(this.entityFieldId)?.enable();
    const obj = {
      checked: false,
      label: value?.label,
      value: value?.value,
    };
    this.eftInfoForm
      .get(this.getFieldIdFromXMLTag(EFTConstant.AccountHolderName)).setValue(obj);
    this.eftInfoForm.get(this.firstNameFieldId)?.setValue(firstName);
    this.eftInfoForm.get(this.lastNameFieldId)?.setValue(lastName);
    this.eftInfoForm.get(this.addressFieldId)?.setValue(address);
    this.eftInfoForm.get(this.cityFieldId)?.setValue(city);
    this.eftInfoForm.get(this.stateFieldId)?.setValue({ checked: false, label: this.STATE?.description, value: this.STATE?.value });
    this.eftInfoForm.get(this.zipFieldId)?.setValue(zipCode);
    this.eftInfoForm.get(this.phoneNoFieldId)?.setValue(phone);
    this.eftInfoForm.get(this.emailFieldId)?.setValue(email);
    if (this.STATE === null) {
      this.eftInfoForm.get(this.stateFieldId)?.setValue(null);
    }
    this.patchPhoneType(phoneType);
    this.setEntityNameEmptyDisable(); // Make Entity disable, empty
    this.disabledEftFields();
    this.eftInfoForm.get(this.stateFieldId)?.disable();
    if (value === undefined) {
      this.eftInfoForm
        .get(this.getFieldIdFromXMLTag(EFTConstant.AccountHolderName)).setValue(null);
      this.checkFormStatus();
      this.enableEFTFields();
    }
  }

  private disabledEftFields(): void {
    const fields: string[] = [this.entityFieldId, this.firstNameFieldId, this.lastNameFieldId, this.addressFieldId, this.cityFieldId,
    this.stateFieldId, this.zipFieldId, this.phoneNoFieldId, this.emailFieldId, this.phoneNoTypeFieldId];
    fields.forEach((key) => {
      this.eftInfoForm.get(key)?.disable();
    });
    setTimeout(() => {
      this.eftInfoForm.get(this.stateFieldId)?.disable();
    }, 500);
  }

  private enableEFTFields(): void {
    const fields: string[] = [this.firstNameFieldId, this.lastNameFieldId, this.addressFieldId, this.cityFieldId,
    this.stateFieldId, this.zipFieldId, this.phoneNoFieldId, this.phoneNoTypeFieldId, this.emailFieldId];
    fields.forEach((key) => {
      this.eftInfoForm.get(key)?.enable();
    });
    setTimeout(() => {
      this.eftInfoForm.get(this.stateFieldId)?.enable();
    }, 500);
  }

  private patchPhoneType(phoneType: any): void {
    if (phoneType !== null) {
      if (phoneType === 'C' || (phoneType?.toLowerCase().indexOf('cell') > -1)) {
        this.eftInfoForm.get(this.phoneNoTypeFieldId)?.setValue('cell');
      } else if (phoneType === 'L' || (phoneType?.toLowerCase().indexOf('landline') > -1)) {
        this.eftInfoForm.get(this.phoneNoTypeFieldId)?.setValue('landline');
      } else {
        this.eftInfoForm.get(this.phoneNoTypeFieldId)?.reset();
      }
    }
  }

  private updateSelectedDropdownValues(seletedValue): void {
    const value = seletedValue ? seletedValue[0] : undefined;
    const selectedAccnHolder = this.userDetailsService.getSelectedAccHolder();
    const insuredAddress = this.userDetailsService.getInsuredAddress();
    this.STATE = null;
    const firstName = this.getEFTDetails(value, 'firstName');
    const lastName = this.getEFTDetails(value, 'lastName');
    const city = this.getEftAddress(value, 'city');
    const address = this.getEftAddress(value, 'streetAddress');
    this.getEftAddressState(value, 'state');
    const zipCode = this.getEftAddress(value, 'zip');
    const phoneType = this.getEFTDetails(value, 'phoneType');
    const email = this.getEFTDetails(value, 'email');
    const phone = this.getEFTDetails(value, 'phone');
    this.eftInfoForm.get(this.entityFieldId)?.enable();

    if (this.checkOwnerType(value, 'trust')) {
      const trustName = this.getTrustName(value);
      if (trustName) {
        this.eftInfoForm.get(this.firstNameFieldId)?.setValue(trustName[0]);
        this.eftInfoForm.get(this.lastNameFieldId)?.setValue((trustName.length - 1) !== 0 ? trustName[trustName.length - 1] : '');
      }
      this.setEntityNameEmptyDisable(); // Make Entity disable, empty
    } else if (this.checkOwnerType(value, 'entity')) {
      const entityName = this.getEntityDetails(value, 'entityName');
      const entityFirstName = this.getEntityDetails(value, 'entityFirstName');
      const entityLastName = this.getEntityDetails(value, 'entityLastName');
      const entityPrimaryPhone = this.getEntityDetails(value, 'phone');
      this.eftInfoForm.get(this.entityFieldId)?.setValidators([Validators.required]);
      this.eftInfoForm.get(this.entityFieldId)?.updateValueAndValidity();
      this.eftInfoForm.get(this.entityFieldId)?.setValue(entityName);
      this.eftInfoForm.get(this.firstNameFieldId)?.setValue(entityFirstName);
      this.eftInfoForm.get(this.lastNameFieldId)?.setValue(entityLastName);
      this.eftInfoForm.get(this.phoneNoFieldId)?.setValue(entityPrimaryPhone);
      this.eftInfoForm.get(this.emailFieldId)?.setValue(email);
      this.patchPhoneType(phoneType);
      this.enableEFTFields();
      this.disabledEftFields();
    } else if (this.checkOwnerType(value, 'person')) {
      this.eftInfoForm.get(this.firstNameFieldId)?.setValue(firstName);
      this.eftInfoForm.get(this.lastNameFieldId).setValue(lastName);
      this.eftInfoForm.get(this.phoneNoFieldId).setValue(phone);
      this.eftInfoForm.get(this.emailFieldId).setValue(email);
      this.patchPhoneType(phoneType);
      this.setEntityNameEmptyDisable(); // Make Entity disable, empty
      this.disabledEftFields();
    } else if (this.checkOwnerIsInsured(selectedAccnHolder)) {
      this.eftInfoForm.get(this.firstNameFieldId)?.setValue(this.userDetailsService.getInsuredFirstName());
      this.eftInfoForm.get(this.lastNameFieldId).setValue(this.userDetailsService.getInsuredLastName());
      this.eftInfoForm.get(this.phoneNoFieldId).setValue(this.userDetailsService.getInsuredPhoneNo());
      this.eftInfoForm.get(this.phoneNoTypeFieldId).setValue(this.userDetailsService.getInsuredPhoneNoType());
      this.eftInfoForm.get(this.emailFieldId).setValue(this.userDetailsService.getInsuredEmail());
      this.setEntityNameEmptyDisable(); // Make Entity disable, empty
      this.disabledEftFields();
    } else if (selectedAccnHolder?.value?.toLowerCase().indexOf('other-individual') > -1) {
      this.makeAllFieldsEmpty();
      this.setEntityNameEmptyDisable(); // Make Entity disable, empty
      this.enableEFTFields();
    } else if (selectedAccnHolder?.value?.toLowerCase().indexOf('other-entity') > -1) {
      this.makeAllFieldsEmpty();
      this.enableEFTFields();
      this.disableFirstLastName();
      this.eftInfoForm.get(this.entityFieldId)?.reset();
      this.eftInfoForm.get(this.entityFieldId)?.setValidators([Validators.required]);
      this.eftInfoForm.get(this.entityFieldId)?.updateValueAndValidity();
    } else {
      // Make all fields empty when none of the person, entity, trust
      this.makeAllFieldsEmpty();
      this.enableEFTFields();
      this.eftInfoForm.get(this.entityFieldId)?.setValidators([Validators.required]);
      this.eftInfoForm.get(this.entityFieldId)?.updateValueAndValidity();
      this.eftInfoForm.get(this.entityFieldId).reset();
      if (value === undefined) {
        this.eftInfoForm
          .get(this.getFieldIdFromXMLTag(EFTConstant.AccountHolderName)).reset();
        this.eftInfoForm.get(this.entityFieldId).reset();
        this.checkFormStatus();
        this.enableEFTFields();
        this.userDetailsService.setSelectedAccHolder(value);
      }
    }
    this.updateAddress(value, selectedAccnHolder, insuredAddress, address, city, zipCode);
  }

  private disableFirstLastName(): void {
    this.eftInfoForm.get(this.firstNameFieldId)?.disable();
    this.eftInfoForm.get(this.lastNameFieldId)?.disable();
  }

  private makeAllFieldsEmpty(): void {
    const exclude: string[] = [this.entityFieldId, this.accountHolderFieldId];
    Object.keys(this.eftInfoForm.controls).forEach(key => {
      if (exclude.findIndex(q => q === key) === -1) {
        this.eftInfoForm.get(key).reset();
      }
    });
    this.formReset = true;
    this.checkFormStatus();
  }

  private updateAddress(value: any, selectedAccnHolder: any, insuredAddress: any, address: any, city: any, zipCode: any): void {
    if (this.checkAddressDetails(value, 'isSameAsInsuredAddress', selectedAccnHolder)) {
      this.eftInfoForm.get(this.addressFieldId)?.setValue(this.checkInsuredAddr(insuredAddress, 'address1'));
      this.eftInfoForm.get(this.cityFieldId)?.setValue(this.checkInsuredAddr(insuredAddress, 'city'));
      this.getStateObjFromCode(this.checkInsuredAddr(insuredAddress, 'state'));
      this.eftInfoForm.get(this.stateFieldId)?.setValue({ checked: false, label: this.STATE?.description, value: this.STATE?.value });
      this.eftInfoForm.get(this.zipFieldId)?.setValue(this.checkInsuredAddr(insuredAddress, 'zipCode'));
    } else {
      this.eftInfoForm.get(this.addressFieldId)?.setValue(address);
      this.eftInfoForm.get(this.cityFieldId)?.setValue(city);
      this.eftInfoForm.get(this.stateFieldId)?.setValue(this.STATE ?
        { checked: false, label: this.STATE?.description, value: this.STATE?.value } : null);
      this.eftInfoForm.get(this.zipFieldId)?.setValue(zipCode);
    }
  }

  private checkInsuredAddr(insuredAddress, addr: string): any {
    return (insuredAddress !== undefined) && (insuredAddress[addr]) ? insuredAddress[addr] : '';
  }

  private getStateObjFromCode(stateCode: string): any {
    this.eftInfoData.forEach((ques) => {
      if (ques.xmlTag === OwnerInfoConstant.EFT_STATE) {
        this.getStateObj(ques.questionOption, stateCode);
      }
    });
  }

  private setEntityNameEmptyDisable(): void {
    this.eftInfoForm.get(this.entityFieldId)?.setValidators([]);
    this.eftInfoForm.get(this.entityFieldId)?.updateValueAndValidity();
    this.eftInfoForm.get(this.entityFieldId)?.reset();
    this.eftInfoForm.get(this.entityFieldId)?.disable();
  }

  private getStateObj(options, stateCode: string): void {
    options.filter((state) => {
      if (state.value === stateCode) {
        this.STATE = state;
      }
    });
  }

  private checkAddressDetails(value, isSameAsInsuredAddress, selectedAccnHolder): boolean {
    return (value !== undefined && value?.[isSameAsInsuredAddress] !== null) || (selectedAccnHolder !== undefined &&
      (selectedAccnHolder.value?.toLowerCase().indexOf('insuredname') > -1));
  }

  private checkOwnerIsInsured(selectedAccnHolder): boolean {
    return (selectedAccnHolder !== undefined && (selectedAccnHolder.value?.toLowerCase().indexOf('insuredname') > -1));
  }

  private getEFTDetails(value, name): any {
    return (value !== undefined && value?.[name] !== '') ? value[name] : null;
  }

  private getEftAddress(value, addr): void {
    return (value !== undefined && value?.address !== '' && value?.address[addr] !== '')
      ? value.address[addr] : null;
  }

  private getEftAddressState(value, state): void {
    if (value !== undefined && value?.address !== '' && value?.address[state] !== ''
      && value?.address[state] !== null) {
      this.getStateObjFromCode(value?.address[state]);
    }
  }

  private checkOwnerType(value, type): boolean {
    return value && value !== undefined && (value.ownerType?.toLowerCase().indexOf(type) > -1);
  }

  private getTrustName(value): any {
    return (value.trustName !== '') ? value.trustName.split(' ') : null;
  }

  private getEntityDetails(value, name): void {
    return (value[name] !== '') ? value[name] : null;
  }

  private getFieldIdFromXMLTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
      this.populateDataOnDropdownSelection();
    }, 200);
    this.eftInfoForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
        this.populateDataOnDropdownSelection();
      }, 200);
    });
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.eftInfoForm,
      data: null,
    };
    for (const data of this.eftInfoData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'eftInfoForm',
      isFormChange: this.eftInfoForm.dirty || !this.isTabVisited || this.formReset
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.eftInfoForm),
    };
    this.formReset = false;
    this.formStatus.emit(obj);
  }

  addCorrectPlaceHolder(data, type): any {
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  handleSelectionChange(data, _ix?): any {
    this.updatedAnswersOnHidden(data);
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.eftInfoForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckEFTInfo(data, childData, parent): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData.optionChoice;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      return parent === true;
    } else {
      return parent === childData.optionChoice;
    }
  }

  updatedAnswersOnHidden(data): any {
    const value = this.eftInfoForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.eftInfoForm.get(key)?.markAsUntouched();
      }
      this.eftInfoForm.patchValue(updatedval);
    }
  }

  eftOnRadioChange(data): any {
    this.updatedAnswersOnHidden(data);
  }

  getAnswerType(key: string): any {
    return this.utilsService.getAnswerType(key);
  }

  getErrorMsg(data, formControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

}
