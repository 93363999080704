import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Question } from 'src/app/shared/models/casePage.model';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ApplicationConfig } from 'src/config/app.config';
import { ErrorMessage, FundAllocationConst } from 'src/config/constants';
import { TabStatus } from 'src/config/sideNav.config';

@Component({
  selector: 'app-maturing-indexed-account-segment-allocation',
  templateUrl: './maturing-indexed-account-segment-allocation.component.html',
  styleUrls: ['./maturing-indexed-account-segment-allocation.component.scss']
})
export class MaturingIndexedAccountSegmentAllocationComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  maturingFundsData = [];
  maturingFundsData2 = [];
  maturingFundsForm: FormGroup;

  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  ErrMessage = ErrorMessage;

  formValid = true;
  formHasNoErrors = true;
  totalFundPercentage = 0;
  openAccordion = false;
  isFundsRequired = false;

  tabStatusOnInit: TabStatus;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private utilsService: UtilsService,
    private defaultPageLoadService: DefaultPageload,
  ) { }

  ngOnInit(): void {
    if (this.questionsData) {
      this.tabStatusOnInit = this.defaultPageLoadService.getActiveTabStatus();
      this.buildFormData();
      this.shouldShowFunds();
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.maturingFundsForm);
      this.defaultPageLoadService.disableFormIfLocked(this.maturingFundsForm, this.disableForm);
      this.defaultPageLoadService.logPageLoad();
    }
  }

  buildFormData(): any {
    this.maturingFundsData = this.questionsData.pages[0].questions;
    this.maturingFundsData2 = this.questionsData.pages[1]?.questions;
    const form = this.formgeneratorService.createFormControls(
      this.maturingFundsData.concat(this.maturingFundsData2)
    );
    this.maturingFundsForm = this.fb.group(form);
    this.valueChanges();
  }

  valueChanges(): any {
    let setTime = setTimeout(() => {
      this.calculatetotalFundPercentage();
      this.checkFormStatus();
    }, 200);
    this.maturingFundsForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.calculatetotalFundPercentage();
        this.checkFormStatus();
      }, 200);
    });
  }

  checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.maturingFundsForm,
      data: null,
    };
    for (const data of this.maturingFundsData.concat(this.maturingFundsData2)) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    const obj = {
      formValid: this.formValid && ((this.isFundsRequired && this.totalFundPercentage === 100) || !this.isFundsRequired),
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'maturingFundsForm',
      isFormChange: this.maturingFundsForm.dirty || !this.isTabVisited
        || this.defaultPageLoadService.isTabStatusUpdated(this.tabStatusOnInit, this.maturingFundsForm),
    };
    this.formStatus.emit(obj);
  }

  toggleAccordionStatus(): void {
    this.openAccordion = !this.openAccordion;
  }

  updateAnswersOnHidden(data: Question): any {
    const value = this.maturingFundsForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.maturingFundsForm.get(key)?.markAsUntouched();
      }
      this.maturingFundsForm.patchValue(updatedval);
    }
  }

  private calculatetotalFundPercentage(): void {
    this.totalFundPercentage = 0;
    Object.keys(this.maturingFundsForm.controls).forEach(key => {
      if (key.indexOf(FundAllocationConst.maturingIndexFundXmlTag) > -1) {
        this.updatePercentage(key);
        this.totalFundPercentage = this.totalFundPercentage + +(this.maturingFundsForm.get(key).value);
      }
    });
  }

  private updatePercentage(key: string): void {
    if (+(this.maturingFundsForm.get(key)?.value > 100)) {
      this.maturingFundsForm.get(key).setValue('100');
    }
  }

  resetForm(): void {
    Object.keys(this.maturingFundsForm.controls).forEach(key => {
      if (key.indexOf(FundAllocationConst.maturingIndexFundXmlTag) > -1) {
        this.maturingFundsForm.get(key).reset();
      }
    });
    this.defaultPageLoadService.logButtonClick('reset all selections');
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  onMIRadioChange(data: Question): void {
    this.shouldShowFunds();
    this.updateAnswersOnHidden(data);
  }

  private shouldShowFunds(): void {
    if (this.maturingFundsForm.get(this.getFieldIdByXmlTag(FundAllocationConst.MaturingIndexIndQues))?.value?.toLowerCase().indexOf('allocations') > -1) {
      this.isFundsRequired = true;
    } else {
      this.resetForm();
      this.isFundsRequired = false;
    }
  }
}
