<section class="coverageInfo-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Coverage Information
    </h1>

    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="coverageInfoForm" id="coverageInfoForm" method="post" *ngIf="coverageInfoData && coverageInfoData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of coverageInfoData; let ind = index;">

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line'">
            <h2>{{ data.questionText }}</h2>
          </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- Form element for currency  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes && data.notes.indexOf('ITES_CURRENCY_USD') > -1)">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ coverageAddCorrectPlaceHolder(data, 'placeHolder') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat appValidateOnBlur appNoSpecialSymbols customAttr="numbersWithPeriod" maxlength='11' [required]="data.required == 'true'">

              <lfg-error *ngIf="coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid && !coverageInfoForm.get(data.fieldId).errors?.premiumError" message="{{ getErrorMsg(data, coverageInfoForm.get(data.fieldId)) }}"></lfg-error>
              <lfg-error *ngIf="coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid && coverageInfoForm.get(data.fieldId).errors?.premiumError" message="{{ coverageInfoForm.get(data.fieldId).errors.premiumError?.errorMessage }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (!data.notes || data.notes.indexOf('ITES_CURRENCY_USD') == -1)">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ coverageAddCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required == 'true'">

              <lfg-error *ngIf="coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid), 'radio-single': data.questionText?.toLowerCase().indexOf('insured be the owner') > -1}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ coverageAddCorrectPlaceHolder(data, 'question') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="coverageOnRadioChange(data)" />
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="coverageOnRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT && data.display === true">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ coverageAddCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="coverageHandleSelectionChange(data, ind)" [required]="data.required == 'true'" [ngClass]="{'dropdown--err': (coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid) }">
            </lfg-dropdown>
            <lfg-error *ngIf="coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid" message=" {{ getErrorMsg(data, coverageInfoForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ coverageAddCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

              <lfg-error *ngIf="coverageInfoForm.get(data.fieldId).touched && coverageInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="data.hasReflexive == 'true' && data.reflexiveQuestionAL.length > 0 && coverageInfoForm.get(data.fieldId).value as parentAnswer">
            <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckCoverageInfo(data, primaryChildL1, parentAnswer)">
                <!-- Questions headers -->
                <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText != 'Information'">
                  <h2>{{ primaryChildL1.questionText }}</h2>
                </div>

                <div class="question-info" aria-live="assertive" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.subText == 'Information'">
                  <lfg-alert-message type="info">{{ primaryChildL1.questionText }}</lfg-alert-message>
              </div>

                <!-- Form element for text  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ coverageAddCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                    <lfg-error *ngIf="coverageInfoForm.get(primaryChildL1.fieldId).touched && coverageInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for alphanumeric  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ coverageAddCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                    <lfg-error *ngIf="coverageInfoForm.get(primaryChildL1.fieldId).touched && coverageInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT">
                  <span class="screen-reader-only">{{ coverageAddCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}</span>
                  <lfg-dropdown formControlName="{{ primaryChildL1.fieldId }}" id="{{displayIdCreation(primaryChildL1)}}_{{primaryChildL1ix}}" [options]="alldropDownOptions[primaryChildL1.fieldId]" placeholder="{{ coverageAddCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" (selectionChanged)="coverageHandleSelectionChange(primaryChildL1, primaryChildL1ix)" [required]="primaryChildL1.required == 'true'" [ngClass]="{'dropdown--err': (coverageInfoForm.get(primaryChildL1.fieldId).touched && coverageInfoForm.get(primaryChildL1.fieldId).invalid) }">
                  </lfg-dropdown>

                  <lfg-error *ngIf="coverageInfoForm.get(primaryChildL1.fieldId).touched && coverageInfoForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                </div>

                <!-- Form element for radio question -->
                <div class="lfg-customRadio-field" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO" [ngClass]="{'radio--err': (coverageInfoForm.get(primaryChildL1.fieldId).touched && coverageInfoForm.get(primaryChildL1.fieldId).invalid)}">
                  <span class="lfg-radio-section">
                    <p class="question-radio_text">{{ coverageAddCorrectPlaceHolder(primaryChildL1, 'question') }}</p>
                    <div class="custom-radio-button">
                      <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="coverageOnRadioChange(primaryChildL1)" />
                      <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                      <input [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="coverageOnRadioChange(primaryChildL1)">
                      <label for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                    </div>
                  </span>
                  <lfg-error *ngIf="coverageInfoForm.get(primaryChildL1.fieldId).touched && coverageInfoForm.get(primaryChildL1.fieldId).invalid " message="Please select a valid option."></lfg-error>
                </div>

              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </form>
    </section>
  </div>
</section>
