import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalSize } from '@ng/lfg-modal-popup';
import { RedirectConfirmationMessages } from 'src/config/constants';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  modalConfig = {
    header: true,
    state: false,
    footer: true,
    size: ModalSize.small,
  };

  Message = RedirectConfirmationMessages;

  constructor() {
    // write code here
  }

  @Output() redirectionConfirmation = new EventEmitter();

  ngOnInit(): void {
    this.modalConfig.state = true;
  }

  modalResponseHandler(value: boolean): void {
    this.modalConfig.state = false;
    this.redirectionConfirmation.emit(value);
  }

  closeModalPopup(): any {
    this.modalConfig.state = false;
    this.redirectionConfirmation.emit(false);
  }

}

