import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QuestionValidationService {
  constructor() {
    // write code here
  }

  premiumModeValues: string[] = [
    'eft - monthly',
    'eft - quarterly',
    'eft - semi annual',
    'eft - annual'
  ];

  checkIfEFTQuestionRequired(premiumMode: string): boolean {
    if (this.premiumModeValues.includes(premiumMode.toLowerCase())) {
      return true;
    }
    return false;
  }
}
