<section class="transfer-allocation-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      Transfer/Allocation/Charges Authorization
    </h1>
  </div>
  <ng-container *ngIf="!disableForm">
    <lfg-alert-message *ngIf="isTabVisited && (!formValid || !isDeductFundValid)" type="error">{{errorMessage}}</lfg-alert-message>
  </ng-container>

  <form class="form-group" [formGroup]="transferAllocationForm" id="transferAllocationForm" method="post" *ngIf="transferAllocationData && transferAllocationData.length > 0">
    <ng-container class="form-control-parent" *ngFor="let data of transferAllocationData; let ind = index;">
      <!-- Form element for check box  -->
      <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
        <div class="lfg-checkbox">
          <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data, $event)">
          <label class="wrap wrap-check" for="{{ data.fieldId }}" >{{data.questionText}}<span *ngIf="data.xmlTag === TransferAlloConst.TransferAuthQues"><br>{{data.subText}}{{data.notes}}</span></label>
        </div>
      </div>

      <!-- Questions label strong -->
      <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText != 'Information' && data.subText != 'Header' && data.questionText != 'Line' && data.subText != 'Label'">
        <h2>{{ data.questionText }}</h2>
      </div>

      <!-- Questions label normal-->
      <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Label'">
        {{ data.questionText }}
      </div>

      <!-- Section Header -->
      <div class="section-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Header'" [ngClass]="{'no-pad': data.notes?.toLowerCase() === 'allocation'}">
        <p>{{ data.questionText | uppercase}}</p>
      </div>

      <!-- Element for line -->
      <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
      </div>

      <!-- Nestion question Level 1 -->
      <div *ngIf="data.hasReflexive == 'true' && transferAllocationForm.get(data.fieldId).value as parentAnswer">
        <ng-container *ngFor="let transferAlloChild1 of data.reflexiveQuestionAL; index as transferAlloChild1ix">
          <ng-container *ngIf="nestedQuestionCheckTransAll(data, transferAlloChild1, parentAnswer)">

            <!-- Form element for check box  -->
            <div class="form--control form--control__full-width fund-list-container" *ngIf="transferAlloChild1.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && transferAlloChild1.xmlTag === FundConst.fundCodeQuesId">
              <ng-container *ngFor="let selectedFund of selectedFundList; index as i">
                <div class="lfg-checkbox fund-checkbox">
                  <input id="{{ selectedFund.value }}" type="checkbox" value="checked" [checked]="selectedFund.isChecked" name="{{ selectedFund.value }}" (change)="onFundSelect(selectedFund.value, $event)" [disabled]="disableForm">
                  <label class="wrap wrap-check" for="{{ selectedFund.value }}">{{selectedFund.desc}}</label>
                </div>
              </ng-container>
              <lfg-alert-message type="error" *ngIf="deductionFundList.length > 5 && !disableForm">{{ maxFundError }}</lfg-alert-message>
              <lfg-alert-message type="error" *ngIf="selectedFundList?.length < 1 && !disableForm">{{ fundMissingError }}</lfg-alert-message>

            </div>

            <div class="question-info" aria-live="assertive" *ngIf="transferAlloChild1.controlTypeDesc == appConfig.fieldTypes.HDR && transferAlloChild1.subText == 'Information'">
              <lfg-alert-message type="info">{{ transferAlloChild1.questionText }}</lfg-alert-message>
            </div>

          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </form>
</section>
