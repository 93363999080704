import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ModalConfig, ModalSize } from '@ng/lfg-modal-popup';
import { Message } from 'src/config/constants';

@Component({
  selector: 'app-progress-overlay',
  templateUrl: './progress-overlay.component.html',
  styleUrls: ['./progress-overlay.component.scss'],
})
export class ProgressOverlayComponent implements OnInit, OnChanges {
  @Input() modalStateInput;

  @Input() hasProgressPercentage;

  @Input() progressPercent;
  @Input() progressPercentArray: number[];

  @Input() messageToshow;

  @Input() showActionButton;
  @Input() formAvailable;

  @Output() openPdfForm = new EventEmitter<string>();
  @Output() closeProgressModal = new EventEmitter<string>();

  progressPercentage = 1;
  overlayModalConfiguration: ModalConfig;
  progressInterval: any;
  Message = Message;

  constructor() {
    // write code here
  }

  ngOnInit(): void {
    if (!this.progressPercentArray || this.progressPercentArray.length === 0) {
      this.progressPercentArray = [1, 10, 25, 42, 54, 78, 90];
    }
    // write code here
    if (!this.hasProgressPercentage) {
      this.updateProgressStatus();
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.modalStateInput) {
      this.overlayModalConfiguration = {
        header: true,
        state: this.modalStateInput,
        footer: true,
        effect: true,
        size: ModalSize.medium,
      };
    }
    if (this.hasProgressPercentage) {
      this.progressPercentage = this.progressPercent;
    }

    if (this.formAvailable) {
      setTimeout(() => {
        this.progressPercentage = 100;
      }, 100);
    }
  }

  private updateProgressStatus(): void {
    let i = 1;
    this.progressInterval = setInterval(() => {
      if (i < this.progressPercentArray.length && this.progressPercentage !== 100) {
        this.progressPercentage = this.progressPercentArray[i];
        i++;
      } else {
        clearInterval(this.progressInterval);
      }
    }, 3000);
  }

  closeModalPopup(): void {
    this.closeProgressModal.emit('yes');
  }

  showDownloadedForm(): void {
    this.openPdfForm.emit('yes');
  }
}
