<section class="signature-method-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      Signature Method
    </h1>
  </div>
  <lfg-alert-message *ngIf="isTabVisited && !disableForm && (!formValid || !signMethod)" type="error">{{errorMessage}}</lfg-alert-message>

  <lfg-alert-message *ngIf="isTrustAvailable" type="info">{{MESSAGES.POLICY_OWNER_TRUST_MESSAGE}}</lfg-alert-message>

  <form class="form-group" [formGroup]="signatureMethodForm" id="signatureMethodForm" method="post" *ngIf="signatureMethodData && signatureMethodData.length > 0">
    <ng-container class="form-control-parent" *ngFor="let data of signatureMethodData; let ind = index;">

      <!-- Form element for check box  -->
      <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.display">
        <div class="lfg-checkbox">
          <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
          <label class="wrap wrap-check" for="{{ data.fieldId }}" [innerHTML]="data.questionText"></label>
        </div>
      </div>

      <!-- Element for Informational Text -->
      <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
        <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
      </div>

      <!-- Element for label -->
      <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Link'">
        <h2>{{ data.questionText }}</h2>
      </div>

      <!-- Element for Line -->
      <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line' && data.display">
      </div>

      <!-- Nestion question Level 1 -->
      <div *ngIf="data.hasReflexive == 'true' && signatureMethodForm.get(data.fieldId).value as parentAnswer">
        <ng-container *ngFor="let signatureMethodChild of data.reflexiveQuestionAL; index as signatureMethodChildIx">
          <!-- Element for Sign Method Link -->
          <div class="signature-info-link-container" *ngIf="signatureMethodChild.controlTypeDesc == appConfig.fieldTypes.HDR && signatureMethodChild.subText == 'Link' && signMethod">
            <a class="btn-link" id="signMethodInfoBtn" type="button" *ngIf="signMethod === 'electronic'" (click)="openModal()"><i class="fas fa-file-signature file-icon" aria-hidden="true"></i>{{ signatureMethodChild.questionText }}</a>

            <!-- Element to show signature info -->
            <div class="signature-method-info" *ngIf="signMethod">
              <ng-container *ngIf="signMethod === 'voice'">
                <ng-container *ngFor="let info of voiceSignInfo">
                  <div [innerHTML]="info"></div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="signMethod === 'electronic'">
                <ng-container *ngFor="let info of electronicSignInfo">
                  <div [innerHTML]="info"></div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <!-- table for signer info -->

        <ng-container *ngIf="tableData?.data?.length > 0">
          <p class="signer-info-heading">SIGNER INFORMATION</p>
          <div class="section-divider"></div>
          <lfg-primary-table [tableData]="tableData" id="signer-info-dbtable">
            <ng-template #tableCelContentTemplate let-column="column" let-row="row">
              <div tabindex="0" *ngIf="column.columnFlag === 'name'" class="font-bold">{{ row.fullName | titlecase }} </div>
              <div tabindex="0" *ngIf="column.columnFlag === 'role'">{{ row.displayRole | titlecase}}<span *ngIf="isTrustAvailable && row.displayRole?.toLowerCase().indexOf('owner') > -1">{{" (Trustee)"}}</span></div>
              <div tabindex="0" *ngIf="column.columnFlag === 'phone'">{{ row.sMSAuthentication?.senderProvidedNumbers?.senderProvidedNumber[0] | phoneNumber}}</div>
              <div tabindex="0" *ngIf="column.columnFlag === 'email'">{{ row.email | lowercase}}</div>
              <div tabindex="0" *ngIf="column.columnFlag === 'last4digit'">{{ row.last4digitSSN }}</div>
            </ng-template>
          </lfg-primary-table>
        </ng-container>

        <!-- Form to select the signature method for all signers -->
        <ng-container *ngIf="signMethod === 'electronic' && signerList.length > 0 && eSignFormCreated">
          <form class="form-group signer-list-form" [formGroup]="esignMethodForm" id="esignMethodForm" method="post">

            <p class="sign-heading">{{ MESSAGES.SignatureListHeading }}</p>
            <div class="section-divider"></div>
            <ng-container class="form-control-parent signer-list-form" *ngFor="let signer of signerList; let signInx = index;">

              <!-- Form element for radio question -->
              <div class="lfg-customRadio-field radio-btn-custom" [ngClass]="{'radio--err': (esignMethodForm.get(signer.roleName+signInx)?.touched && esignMethodForm.get(signer.roleName+signInx)?.invalid)}">
                <span class="lfg-radio-section">
                  <span class="question-radio_text">{{ signer.fullName | titlecase }} - {{ signer.displayRole | titlecase }}
                    <span *ngIf="isTrustAvailable && signer.displayRole.indexOf('Owner') > -1">(Trustee)</span></span>
                  <div class="custom-radio-button">
                    <input [attr.aria-label]="signatureMethodOptions[0].description" tabindex="0" id="option1_{{ signer.roleName+signInx }}" type="radio" formControlName="{{ signer.roleName+signInx }}" name="{{ signer.roleName+signInx }}" value="{{ signatureMethodOptions[0].value }}" required="true" />
                    <label for="option1_{{ signer.roleName+signInx }}">{{ signatureMethodOptions[0].description }}</label>
                    <input [attr.aria-label]="signatureMethodOptions[1].description" tabindex="0" id="option2_{{ signer.roleName+signInx }}" type="radio" formControlName="{{ signer.roleName+signInx }}" name="{{ signer.roleName+signInx }}" value="{{ signatureMethodOptions[1].value }}" required="true" />
                    <label for="option2_{{ signer.roleName+signInx }}">{{ signatureMethodOptions[1].description }}</label>
                  </div>
                </span>
                <lfg-error *ngIf="esignMethodForm.get(signer.roleName+signInx)?.touched && esignMethodForm.get(signer.roleName+signInx)?.invalid " message="Please select a valid option."></lfg-error>
              </div>
              <div class="section-divider"></div>
            </ng-container>
          </form>
        </ng-container>
      </div>

      <!-- Nestion question Level 1 -->
      <div *ngIf="data.hasReflexive == 'true' && signatureMethodForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
        <ng-container *ngFor="let signatureMethodChild of data.reflexiveQuestionAL; index as signatureMethodChildIx">
          <ng-container *ngIf="nestedQuestionCheckSignMethodInfo(data, signatureMethodChild, parentAnswer)">

            <!-- Form element for Drop Down -->
            <div class="form--control form-dropDown" *ngIf="signatureMethodChild.controlTypeDesc == appConfig.fieldTypes.SELECT">
              <lfg-dropdown formControlName="{{ signatureMethodChild.fieldId }}" id="{{displayIdCreation(signatureMethodChild)}}_{{ind}}" [options]="alldropDownOptions[signatureMethodChild.fieldId]" placeholder="{{ addCorrectPlaceHolder(signatureMethodChild, 'placeHolder') }}" [required]="signatureMethodChild.required == 'true'" [ngClass]="{'dropdown--err': (signatureMethodForm.get(signatureMethodChild.fieldId).touched && signatureMethodForm.get(signatureMethodChild.fieldId).invalid)}">
              </lfg-dropdown>
              <lfg-error *ngIf="signatureMethodForm.get(signatureMethodChild.fieldId).touched && signatureMethodForm.get(signatureMethodChild.fieldId).invalid" message="{{ getErrorMsg(signatureMethodChild, signatureMethodForm.get(signatureMethodChild.fieldId)) }}"></lfg-error>
            </div>

            <!-- Form element for text  -->
            <div class="form--control" [ngClass]="{'form--control__medium-width': signatureMethodChild.questionText.length > 20}" *ngIf="signatureMethodChild.controlTypeDesc == appConfig.fieldTypes.TEXT">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(signatureMethodChild, 'placeHolder') }}" name="{{ displayTextLabel(signatureMethodChild) }}" aria-live="off" type='text' formControlName='{{ signatureMethodChild.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(signatureMethodChild.answerTextType)" [maxlength]="signatureMethodChild.maxLength" [required]="signatureMethodChild.required == 'true'">

                <lfg-error *ngIf="signatureMethodForm.get(signatureMethodChild.fieldId).touched && signatureMethodForm.get(signatureMethodChild.fieldId).invalid" message="Please enter a valid {{signatureMethodChild.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Element for informational text  -->
            <div class="nested-info" *ngIf="signatureMethodChild.controlTypeDesc == appConfig.fieldTypes.HDR && signatureMethodChild.subText == 'Information'">
              <lfg-alert-message type="info">{{ signatureMethodChild.questionText }}</lfg-alert-message>
            </div>

            <!-- Element for informational text  -->
            <div class="nested-info" *ngIf="signatureMethodChild.controlTypeDesc == appConfig.fieldTypes.SELECT && signatureMethodChild.xmlTag === SignMethodConst.SigningState && (!isInsuredStateMT && (isSignerFromMT || signatureMethodForm.get(signatureMethodChild.fieldId)?.value?.value?.toLowerCase() === 'mt{47}mt'))">
              <lfg-alert-message type="info">{{ CommMessage.SIGNER_STATE_MT_MESSAGE }}</lfg-alert-message>
            </div>

          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </form>


  <lfg-modal-popup *ngIf="modalConfig" [modalConfig]="modalConfig" (closeModalPopup)="closeModalPopup(modalConfig)">
    <div modal-header>
      <h1>Sample</h1>
    </div>
    <div modal-body [innerHtml]="MESSAGES.EmailTemplate">
      <p class="popup-message">
      </p>
    </div>
    <div modal-footer>
      <lfg-left-button-tray>
        <button class='btn' lfgBorderButton (click)="closeModalPopup(modalConfig)">CLOSE</button>
      </lfg-left-button-tray>
    </div>
  </lfg-modal-popup>
</section>
