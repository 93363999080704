<section class="term-of-use-page page-content-container">
  <div class="questions--container">
    <h1 class="lfg-color">
      Term of Use - Producers Acknowledgement
    </h1>

    <lfg-alert-message *ngIf="policyCreationError" type="error">{{policyCreationError}}</lfg-alert-message>

    <lfg-alert-message *ngIf="isTabVisited && (!formValid)" type="error">{{errorMessage}}</lfg-alert-message>

    <form class="form-group" [formGroup]="termOfUseForm" id="termOfUseForm" method="post" *ngIf="termOfUseData && termOfUseData.length > 0">
      <ng-container class="form-control-parent" *ngFor="let data of termOfUseData; let ind = index;">

        <!-- Form element for check box  -->
        <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.display">
          <div class="lfg-checkbox">
            <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
            <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
          </div>
        </div>

        <!-- Element for Informational Text -->
        <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
          <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
        </div>

        <!-- Element for showing text -->
        <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Information' && data.subText != 'Label' && data.subText != 'Instruction' && data.subText != 'Hidden'">
          <h2>{{ data.questionText }}</h2>
        </div>

        <!-- Element for label -->
        <div class="information-container" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Label'">
          <div class="information-header">
            <h3 class="info-heading">{{ data.questionText }}</h3>
            <a class="print-link" (click)="viewContent('term')" *ngIf="data.questionText?.toLowerCase()?.indexOf('term') > -1"><i class="fas fa-print print-icon" aria-hidden="true"></i>VIEW</a>
            <a class="print-link" (click)="viewContent('acknowledgement')" *ngIf="data.questionText?.toLowerCase()?.indexOf('acknowledgement') > -1"><i class="fas fa-print print-icon" aria-hidden="true"></i>VIEW/PRINT</a>
          </div>

          <ng-container *ngIf="data.questionText?.toLowerCase()?.indexOf('term') > -1">
            <div class="information-content" [innerHtml]="VoiceConst.TERM_OF_USE"></div>
          </ng-container>
          <ng-container *ngIf="data.questionText?.toLowerCase()?.indexOf('acknowledgement') > -1">
            <div class="information-content" [innerHtml]="VoiceConst.PRODUCER_ACKNOWLEDGEMENT"></div>
          </ng-container>
        </div>

        <!-- Element for instruction -->
        <div class="term-of-use-information" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Instruction'">
          <div [innerHTML]="voiceInstruction"></div>
        </div>

        <!-- Element for Line -->
        <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line' && data.display">
        </div>

        <!-- Nestion question Level 1 -->
        <div *ngIf="data.hasReflexive == 'true' && termOfUseForm.get(data.fieldId).value as parentAnswer">
          <ng-container *ngFor="let termOfUseChild of data.reflexiveQuestionAL; index as termOfUseChildIx">
            <ng-container *ngIf="nestedQuestionCheckTermOfUse(data, termOfUseChild, parentAnswer)">

              <!-- Form element for check box  -->
              <div class="form--control form--control__full-width" *ngIf="termOfUseChild.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && termOfUseChild.display">
                <div class="lfg-checkbox">
                  <input id="{{ termOfUseChild.fieldId }}" type="checkbox" value="checked" name="{{ termOfUseChild.fieldId }}" formControlName='{{ termOfUseChild.fieldId }}' [required]="termOfUseChild.required == 'true'" (change)="onCheckboxChange(termOfUseChild)">
                  <label class="wrap wrap-check" for="{{ termOfUseChild.fieldId }}">{{termOfUseChild.questionText}}</label>
                </div>
              </div>

            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>
</section>
