import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BasePageComponent } from './base-page/base-page.component';
import { CaseInfoComponent } from './case-info/case-info.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { ProductListComponent } from './product-list/product-list.component';
import { AttestationComponent } from './attestation/attestation.component';
import { PrimaryInsuredComponent } from './primary-insured/primary-insured.component';
import { OwnerInfoComponent } from './owner-info/owner-info.component';
import { CoverageInfoComponent } from './coverage-info/coverage-info.component';
import { BasePageHeaderComponent } from './base-page-header/base-page-header.component';
import { BasePageWelcomeComponent } from './base-page-welcome/base-page-welcome.component';
import { RidersInfoComponent } from './riders-info/riders-info.component';
import { TemporaryLifeInsuranceComponent } from './temporary-life-insurance/temporary-life-insurance.component';
import { ExistingInsuranceComponent } from './existing-insurance/existing-insurance.component';
import { GridStructureComponent } from './grid-structure/grid-structure.component';
import { AgentInfoComponent } from './agent-info/agent-info.component';
import { GridHeaderComponent } from './grid-header/grid-header.component';
import { AgentValidationPopupComponent } from './agent-validation-popup/agent-validation-popup.component';
import { AgentInfoContComponent } from './agent-info-cont/agent-info-cont.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { ApplicationSummaryComponent } from './application-summary/application-summary.component';
import { ValidateAndLockComponent } from './validate-and-lock/validate-and-lock.component';
import { VoiceConfirmThankYouComponent } from './voice-confirm-thank-you/voice-confirm-thank-you.component';
import { SignatureMethodComponent } from './signature-method/signature-method.component';
import { TermOfUseComponent } from './term-of-use/term-of-use.component';
import { ElectronicFundTransferComponent } from './electronic-fund-transfer/electronic-fund-transfer.component';
import { ElectronicSignatureComponent } from './electronic-signature/electronic-signature.component';
import { AgentInstructionComponent } from './agent-instruction/agent-instruction.component';
import { EsignatureInstructionComponent } from './esignature-instruction/esignature-instruction.component';
import { FundAllocationComponent } from './fund-allocation/fund-allocation.component';
import { DollarCostAveragingComponent } from './dollar-cost-averaging/dollar-cost-averaging.component';
import { MaturingIndexedAccountSegmentAllocationComponent } from './maturing-indexed-account-segment-allocation/maturing-indexed-account-segment-allocation.component';
import { TransferAllocationChargesAuthorizationComponent } from './transfer-allocation-charges-authorization/transfer-allocation-charges-authorization.component';
import { SuitabilityReviewComponent } from './suitability-review/suitability-review.component';
import { TrustInfoComponent } from './trust-info/trust-info.component';
import { ProposedInsuredBComponent } from './proposed-insured-b/proposed-insured-b.component';
import { WaReplacementComponent } from './wa-replacement/wa-replacement.component';
import { LtcExistingInsuranceComponent } from './ltc-existing-insurance/ltc-existing-insurance.component';
import { IllustrationInfoComponent } from './illustration-info/illustration-info.component';
import { FundAllocationVulComponent } from './fund-allocation-vul/fund-allocation-vul.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';
import { ConsentPageComponent } from './consent-page/consent-page.component';
import { DocusignIframeComponent } from './docusign-iframe/docusign-iframe.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    BasePageComponent,
    CaseInfoComponent,
    SideNavComponent,
    ProductListComponent,
    AttestationComponent,
    PrimaryInsuredComponent,
    CoverageInfoComponent,
    OwnerInfoComponent,
    BasePageHeaderComponent,
    BasePageWelcomeComponent,
    RidersInfoComponent,
    TemporaryLifeInsuranceComponent,
    ExistingInsuranceComponent,
    GridStructureComponent,
    AgentInfoComponent,
    GridHeaderComponent,
    AgentValidationPopupComponent,
    AgentInfoContComponent,
    AdditionalInfoComponent,
    ApplicationSummaryComponent,
    ValidateAndLockComponent,
    VoiceConfirmThankYouComponent,
    SignatureMethodComponent,
    TermOfUseComponent,
    ElectronicFundTransferComponent,
    ElectronicSignatureComponent,
    AgentInstructionComponent,
    EsignatureInstructionComponent,
    FundAllocationComponent,
    DollarCostAveragingComponent,
    MaturingIndexedAccountSegmentAllocationComponent,
    TransferAllocationChargesAuthorizationComponent,
    SuitabilityReviewComponent,
    TrustInfoComponent,
    ProposedInsuredBComponent,
    WaReplacementComponent,
    LtcExistingInsuranceComponent,
    IllustrationInfoComponent,
    FundAllocationVulComponent,
    ThankyouPageComponent,
    ConsentPageComponent,
    DocusignIframeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [
    DatePipe
  ]
})
export class CaseModule { }
