import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { alphaNumericValidator } from '../../shared/validators/validator';
import { Subscription } from 'rxjs';
import { AppAnalyticsService } from 'src/app/shared/services/app-analytics.service';

@Component({
  selector: 'app-eticket-search',
  templateUrl: './eticket-search.component.html',
  styleUrls: ['./eticket-search.component.scss'],
})
export class EticketSearchComponent implements OnInit, OnDestroy {
  eticketSearchForm: FormGroup;
  dynamicPlaceHolder: string;
  public isButtonDisabled: boolean;
  public formValueChangeSubscription: Subscription;

  @ViewChild('searchBtn') searchButton: ElementRef;
  @Output() searchValue = new EventEmitter<any>();
  @Output() clearSearchData = new EventEmitter<any>();
  constructor(private formbuilder: FormBuilder, private appAnalyticsService: AppAnalyticsService) {}

  ngOnInit(): any {
    const formGroupControls: any = {
      userInputField: ['', [Validators.minLength(3), alphaNumericValidator(true)]],
    };
    this.eticketSearchForm = this.formbuilder.group(formGroupControls);

    this.formValueChangeSubscription =
      this.eticketSearchForm.valueChanges.subscribe(() => {
        this.isButtonDisabled = Object.keys(
          this.eticketSearchForm.controls
        ).some((formKey) => this.eticketSearchForm.controls[formKey].value);
      });
  }

  ngOnDestroy(): void {
    this.formValueChangeSubscription.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get eticketSerchFormControls(): any {
    return this.eticketSearchForm.controls;
  }

  searchPolicies(): any {
    this.searchButton.nativeElement.blur();
    const searchText = this.eticketSearchForm.get('userInputField').value;
    const searchParams =
      searchText !== '' ? { insuredLastName: searchText.trim() } : {};
    this.searchValue.emit(searchParams);
    this.appAnalyticsService.logButtonEvents('search', 'eticket - dashboard');
  }

  clearSearch(): void {
    this.eticketSearchForm.get('userInputField').setValue('');
    this.clearSearchData.emit('clear');
    this.appAnalyticsService.logButtonEvents('clear', 'eticket - dashboard');
  }
}
