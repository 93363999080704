<section class="illustration-info-page page-content-container">

  <div class="questions--container">
    <h1 class="lfg-color">
      Illustration Information
    </h1>
    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>

    <section>
      <form class="form-group" [formGroup]="illustrationInfoForm" id="illustrationInfoForm" method="post" *ngIf="illustrationInfoData && illustrationInfoData.length > 0">

        <ng-container class="form-control-parent" *ngFor="let data of illustrationInfoData; let ind = index;">

          <ng-container *ngIf="data.notes !== 'EXISTING_INS_RELATED_QUES'">
            <!-- Informational text -->
            <div class="question-info" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information'">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Form element for radio question having longer desc -->
            <div aria-live="assertive" class="lfg-customRadio-field-long" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.questionOption[0].description?.length > 20" [ngClass]="{'radio--err': (illustrationInfoForm.get(data.fieldId).touched && illustrationInfoForm.get(data.fieldId).invalid)}">
              <span class="radio-question-container">
                <p class="question-radio_text">{{ data.questionText }}</p>
                <div class="radio-button-container">
                  <label class="radio-input-label" [ngClass]="{'radio-button-checked': illustrationInfoForm.get(data.fieldId).value === data.questionOption[0].value}">
                    <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="illustrationInfoForm.get(data.fieldId).value === data.questionOption[0].value"></i>
                    <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="illustrationInfoForm.get(data.fieldId).value !== data.questionOption[0].value"></i>
                    <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)" />
                    <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                  </label>
                  <label class="radio-input-label" [ngClass]="{'radio-button-checked': illustrationInfoForm.get(data.fieldId).value === data.questionOption[1].value}">
                    <i aria-hidden="true" class="fas fa-check-circle radio-icon radio-icon-checked" *ngIf="illustrationInfoForm.get(data.fieldId).value === data.questionOption[1].value"></i>
                    <i aria-hidden="true" class="far fa-circle radio-icon" *ngIf="illustrationInfoForm.get(data.fieldId).value !== data.questionOption[1].value"></i>
                    <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                    <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                  </label>
                </div>
              </span>
              <lfg-error *ngIf="illustrationInfoForm.get(data.fieldId).touched && illustrationInfoForm.get(data.fieldId).invalid " message="Please select a valid option."></lfg-error>
            </div>

            <!-- element for line -->
            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

            <!-- Form element for text  -->
            <div class="form--control form-control-medium" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes === 'ITES_PERCENTAGE'">
              <lfg-input-wrap>
                <input lfgInput appPercentageFormat placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                <lfg-error *ngIf="illustrationInfoForm.get(data.fieldId).touched && illustrationInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>

            <div class="form--control form-control-medium" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes !== 'ITES_PERCENTAGE'">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="getAnswerType(data.answerTextType)" [maxlength]="data.maxLength" [required]="data.required == 'true'">
                <lfg-error *ngIf="illustrationInfoForm.get(data.fieldId).touched && illustrationInfoForm.get(data.fieldId).invalid" message="Please enter a valid {{data.questionText | lowercase}}."></lfg-error>
              </lfg-input-wrap>
            </div>
          </ng-container>

          <ng-container *ngIf="data.notes === 'EXISTING_INS_RELATED_QUES' && isStoppingPremium">

            <!-- Form element for text area -->
            <div class="form--control form--control__full-width reflexive-text-area" aria-live="assertive" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXTAREA">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="text" [maxlength]="data.maxLength" [required]="data.required">
                <span class="text-area-count">{{illustrationInfoForm.get(data.fieldId).value?.length}}/{{data.maxLength}}</span>
                <lfg-error *ngIf="illustrationInfoForm.get(data.fieldId).touched && illustrationInfoForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for checkbox  -->
            <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX">
              <div class="lfg-checkbox">
                <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}" formControlName='{{ data.fieldId }}' [required]="data.required == 'true'">
                <label class="wrap wrap-check" for="{{ data.fieldId }}">{{addCorrectPlaceHolder(data, 'placeHolder')}}</label>
                <lfg-error *ngIf="illustrationInfoForm.get(data.fieldId).touched && illustrationInfoForm.get(data.fieldId).invalid" message="Please select a valid option."></lfg-error>
              </div>
            </div>

            <!-- Form element for label  -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText != 'Line' && data.subText != 'Header' && data.subText != 'Information' && data.questionText != 'ACCORDION_INDIANA' && data.questionText != 'ACCORDION_INDIANA2'">
              <p class="font-bold">{{ data.questionText }}</p>
            </div>

            <!-- element for line -->
            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

          </ng-container>
        </ng-container>
      </form>
    </section>

  </div>
</section>