<div class="product-container">
  <div>
    <p>Primary insured: <strong>{{primaryInsuredName}}</strong></p>
  </div>
  <div class="divider">
    |
  </div>
  <div>
    <p>Product: <strong>{{productName}}</strong></p>
  </div>
</div>