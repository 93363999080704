import { Component, OnInit } from '@angular/core';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { voiceConfirmationMessage } from 'src/config/constants';
@Component({
  selector: 'app-voice-confirm-thank-you',
  templateUrl: './voice-confirm-thank-you.component.html',
  styleUrls: ['./voice-confirm-thank-you.component.scss']
})
export class VoiceConfirmThankYouComponent implements OnInit {

  voiceConfirmMsg = voiceConfirmationMessage;
  constructor(private defaultPageLoadService: DefaultPageload) {
    // write code here
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.defaultPageLoadService.logPageLoad();
  }

}
