<ng-container *ngIf="type == 'POLICY_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span class="accordion-info" *ngIf="!isStateDE">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(EI_CONST.insurerQuesId)?.value">Company: <strong>{{gridForm.get(EI_CONST.otherInsurerNameId)?.value ? gridForm.get(EI_CONST.otherInsurerNameId)?.value : getValue(gridForm.get(EI_CONST.insurerQuesId)?.value.value)}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.insurerQuesId)?.value && gridForm.get(EI_CONST.contractNumberFieldId)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.contractNumberFieldId)?.value">Contract Number: <strong>{{gridForm.get(EI_CONST.contractNumberFieldId).value}}</strong></span>
      <span class="divider" *ngIf="(gridForm.get(EI_CONST.insurerQuesId)?.value || gridForm.get(EI_CONST.contractNumberFieldId)?.value) && gridForm.get(EI_CONST.typeQuesId)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.typeQuesId)?.value">Type: <strong>{{getValue(gridForm.get(EI_CONST.typeQuesId).value.value)}} </strong></span>
    </span>
    <span class="accordion-info" *ngIf="isStateDE">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(EI_CONST.companyNameQuesId)?.value">Company: <strong>{{gridForm.get(EI_CONST.otherCompanyNameQuesId)?.value ? gridForm.get(EI_CONST.otherCompanyNameQuesId)?.value : getValue(gridForm.get(EI_CONST.companyNameQuesId)?.value.value)}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.companyNameQuesId)?.value && gridForm.get(EI_CONST.policyNumberFieldId)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.policyNumberFieldId)?.value">Policy Number: <strong>{{gridForm.get(EI_CONST.policyNumberFieldId).value}}</strong></span>
      <span class="divider" *ngIf="(gridForm.get(EI_CONST.companyNameQuesId)?.value || gridForm.get(EI_CONST.policyNumberFieldId)?.value) && gridForm.get(EI_CONST.insuredNameQuesId)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.insuredNameQuesId)?.value">Insured: <strong>{{gridForm.get(EI_CONST.insuredNameQuesId).value}}</strong></span>
    </span>
    <span class="accordion-info" *ngIf="isStateMN && subType === 'POLICY_GRID_2'">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value">Company: <strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value && insuredName">|</span>
      <span class="acrdn-info" *ngIf="insuredName">Insured: <strong>{{insuredName}}</strong></span>
      <span class="divider" *ngIf="insuredName && (gridForm.get(EI_CONST.policyOrContractNoId)?.value || insuredDOB)">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.policyOrContractNoId)?.value">Contract Number: <strong>{{gridForm.get(EI_CONST.policyOrContractNoId).value}}</strong></span>
      <span class="divider" *ngIf="(gridForm.get(EI_CONST.insurerNameMIStateId)?.value || gridForm.get(EI_CONST.policyOrContractNoId)?.value) && insuredDOB">|</span>
      <span class="acrdn-info" *ngIf="insuredDOB">Insured Birthdate: <strong>{{insuredDOB}}</strong></span>
    </span>
    <span class="accordion-info" *ngIf="isStateMI && subType === 'POLICY_GRID_2'">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value">Company: <strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value && gridForm.get(EI_CONST.policyNumberMI)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.policyNumberMI)?.value">Contract Number: <strong>{{gridForm.get(EI_CONST.policyNumberMI).value}}</strong></span>
      <span class="divider" *ngIf="(gridForm.get(EI_CONST.insurerNameMIStateId)?.value || gridForm.get(EI_CONST.policyNumberMI)?.value) && gridForm.get(EI_CONST.dateOfIssueMNStateId)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.dateOfIssueMNStateId)?.value">Issue Date: <strong>{{gridForm.get(EI_CONST.dateOfIssueMNStateId).value}}</strong></span>
    </span>
    <span class="accordion-info" *ngIf="isStateMA && subType === 'POLICY_GRID_2'">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value">Company: <strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value && gridForm.get(EI_CONST.contractNumberMaId)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.contractNumberMaId)?.value">Contract Number: <strong>{{gridForm.get(EI_CONST.contractNumberMaId).value}}</strong></span>
    </span>
    <span class="accordion-info" *ngIf="isStateIN && subType === 'POLICY_GRID_2'">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value">Company: <strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value && gridForm.get(EI_CONST.indianaTypeOfPolicy1)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.indianaTypeOfPolicy1)?.value">Policy Type: <strong>{{gridForm.get(EI_CONST.indianaTypeOfPolicy1).value}}</strong></span>
      <span class="acrdn-info" *ngIf="(existingInsAnswers[EI_CONST.quitExistingPolicyQuesId] || existingInsAnswers[EI_CONST.existingFundsQuesId])">
        <span class="divider" *ngIf="gridForm.get(EI_CONST.indianaTypeOfPolicy1)?.value && gridForm.get(EI_CONST.faceAmountIN1)?.value">|</span>
        <span *ngIf="gridForm.get(EI_CONST.faceAmountIN1)?.value">Face Amount: <strong>{{gridForm.get(EI_CONST.faceAmountIN1).value}}</strong></span>
      </span>
      <span *ngIf="!existingInsAnswers || !(existingInsAnswers[EI_CONST.quitExistingPolicyQuesId] || existingInsAnswers[EI_CONST.existingFundsQuesId])">
        <span class="divider" *ngIf="gridForm.get(EI_CONST.insurerNameMIStateId)?.value && gridForm.get(EI_CONST.indianaPolicyNo1)?.value">|</span>
        <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.indianaPolicyNo1)?.value">Policy Number: <strong>{{gridForm.get(EI_CONST.indianaPolicyNo1).value}}</strong></span>
        <span class="divider" *ngIf="(gridForm.get(EI_CONST.insurerNameMIStateId)?.value || gridForm.get(EI_CONST.indianaPolicyNo1)?.value) && gridForm.get(EI_CONST.indianaDateOfIssue1)?.value">|</span>
        <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.indianaDateOfIssue1)?.value">Issue Date: <strong>{{gridForm.get(EI_CONST.indianaDateOfIssue1).value}}</strong></span>
      </span>

    </span>

    <span class="accordion-info" *ngIf="isStateIN && subType === 'POLICY_GRID_3'">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(EI_CONST.companyNameIN2)?.value">Company: <strong>{{gridForm.get(EI_CONST.otherCompanyNameIN2)?.value ? gridForm.get(EI_CONST.otherCompanyNameIN2)?.value : getValue(gridForm.get(EI_CONST.companyNameIN2)?.value.value)}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.companyNameIN2)?.value && gridForm.get(EI_CONST.typeOfPolicyIN2)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.typeOfPolicyIN2)?.value">Policy Type: <strong>{{gridForm.get(EI_CONST.typeOfPolicyIN2).value}}</strong></span>
      <span class="divider" *ngIf="(gridForm.get(EI_CONST.companyNameIN2)?.value || gridForm.get(EI_CONST.typeOfPolicyIN2)?.value) && gridForm.get(EI_CONST.faceAmountIN2)?.value">|</span>
      <span class="acrdn-info" *ngIf="gridForm.get(EI_CONST.faceAmountIN2)?.value">Face Amount: <strong>{{gridForm.get(EI_CONST.faceAmountIN2).value}}</strong></span>
    </span>
  </span>
</ng-container>

<ng-container *ngIf="type == 'POLICY_MOBILE_ICON' || type == 'POLICY_DESKTOP_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'POLICY_MOBILE_INFO' && !isStateDE">
  <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && gridForm?.get(EI_CONST.insurerQuesId)?.value"><strong>{{gridForm.get(EI_CONST.otherInsurerNameId)?.value ? gridForm.get(EI_CONST.otherInsurerNameId)?.value : getValue(gridForm?.get(EI_CONST.insurerQuesId)?.value.value)}}</strong></div>
  <div *ngIf="grid.formLoadCompleted">
    <span *ngIf="gridForm?.get(EI_CONST.contractNumberFieldId)?.value">{{gridForm?.get(EI_CONST.contractNumberFieldId).value}}</span>
    <span class="divider" *ngIf="gridForm.get(EI_CONST.contractNumberFieldId)?.value && gridForm.get(EI_CONST.typeQuesId)?.value">|</span>
    <span *ngIf="gridForm?.get(EI_CONST.typeQuesId)?.value">{{getValue(gridForm?.get(EI_CONST.typeQuesId).value.value)}} </span>
  </div>
</ng-container>

<ng-container *ngIf="type == 'POLICY_MOBILE_INFO'">
  <ng-container *ngIf="isStateDE">
    <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && gridForm?.get(EI_CONST.companyNameQuesId)?.value"><strong>{{gridForm.get(EI_CONST.otherCompanyNameQuesId)?.value ? gridForm.get(EI_CONST.otherCompanyNameQuesId)?.value : getValue(gridForm?.get(EI_CONST.companyNameQuesId)?.value.value)}}</strong></div>
    <div *ngIf="grid.formLoadCompleted">
      <span *ngIf="gridForm?.get(EI_CONST.policyNumberFieldId)?.value">{{gridForm?.get(EI_CONST.policyNumberFieldId).value}}</span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.policyNumberFieldId)?.value && gridForm.get(EI_CONST.insuredNameQuesId)?.value">|</span>
      <span *ngIf="gridForm?.get(EI_CONST.insuredNameQuesId)?.value">{{gridForm?.get(EI_CONST.insuredNameQuesId).value}} </span>
    </div>
  </ng-container>
  <ng-container *ngIf="isStateMI && subType === 'POLICY_GRID_2'">
    <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && gridForm?.get(EI_CONST.insurerNameMIStateId)?.value"><strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm?.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></div>
    <div *ngIf="grid.formLoadCompleted">
      <span *ngIf="gridForm.get(EI_CONST.policyNumberMI)?.value">{{gridForm.get(EI_CONST.policyNumberMI).value}}</span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.dateOfIssueMNStateId)?.value && gridForm.get(EI_CONST.policyNumberMI)?.value">|</span>
      <span *ngIf="gridForm.get(EI_CONST.dateOfIssueMNStateId)?.value">{{gridForm.get(EI_CONST.dateOfIssueMNStateId).value}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="isStateMN && subType === 'POLICY_GRID_2'">
    <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && gridForm?.get(EI_CONST.insurerNameMIStateId)?.value"><strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm?.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></div>
    <div *ngIf="grid.formLoadCompleted">
      <span *ngIf="insuredDOB">{{insuredName}}</span>
      <span class="divider" *ngIf="insuredName && (gridForm.get(EI_CONST.policyOrContractNoId)?.value || insuredDOB)">|</span>
      <span *ngIf="gridForm.get(EI_CONST.policyOrContractNoId)?.value">{{gridForm.get(EI_CONST.policyOrContractNoId).value}}</span>
      <span class="divider" *ngIf="insuredDOB && gridForm.get(EI_CONST.policyOrContractNoId)?.value">|</span>
      <span *ngIf="insuredDOB">{{insuredDOB}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="isStateMA && subType === 'POLICY_GRID_2'">
    <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && gridForm?.get(EI_CONST.insurerNameMIStateId)?.value"><strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm?.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></div>
    <div *ngIf="grid.formLoadCompleted">
      <span *ngIf="gridForm.get(EI_CONST.contractNumberMaId)?.value">{{gridForm.get(EI_CONST.contractNumberMaId).value}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="isStateIN && subType === 'POLICY_GRID_2'">
    <div class="acrdn-sub-header" *ngIf="grid.formLoadCompleted && gridForm.get(EI_CONST.insurerNameMIStateId)?.value"><strong>{{gridForm.get(EI_CONST.companyNameMI)?.value ? gridForm.get(EI_CONST.companyNameMI)?.value : getValue(gridForm.get(EI_CONST.insurerNameMIStateId)?.value.value)}}</strong></div>
    <div *ngIf="grid.formLoadCompleted">
      <span *ngIf="gridForm.get(EI_CONST.indianaTypeOfPolicy1)?.value">{{gridForm.get(EI_CONST.indianaTypeOfPolicy1).value}}</span>
      <span *ngIf="!existingInsAnswers || !(existingInsAnswers[EI_CONST.quitExistingPolicyQuesId] || existingInsAnswers[EI_CONST.existingFundsQuesId])">
        <span class="divider" *ngIf="gridForm.get(EI_CONST.indianaTypeOfPolicy1)?.value && gridForm.get(EI_CONST.indianaPolicyNo1)?.value">|</span>
        <span *ngIf="gridForm.get(EI_CONST.indianaPolicyNo1)?.value">{{gridForm.get(EI_CONST.indianaPolicyNo1).value}}</span>
        <span class="divider" *ngIf="(gridForm.get(EI_CONST.indianaPolicyNo1)?.value || gridForm.get(EI_CONST.indianaTypeOfPolicy1)?.value) && gridForm.get(EI_CONST.indianaDateOfIssue1)?.value">|</span>
        <span *ngIf="gridForm.get(EI_CONST.indianaDateOfIssue1)?.value">{{gridForm.get(EI_CONST.indianaDateOfIssue1).value}}</span>
      </span>
      <span *ngIf="(existingInsAnswers[EI_CONST.quitExistingPolicyQuesId] || existingInsAnswers[EI_CONST.existingFundsQuesId])">
        <span class="divider" *ngIf="gridForm.get(EI_CONST.indianaTypeOfPolicy1)?.value && gridForm.get(EI_CONST.faceAmountIN1)?.value">|</span>
        <span *ngIf="gridForm.get(EI_CONST.faceAmountIN1)?.value">{{gridForm.get(EI_CONST.faceAmountIN1).value}}</span>
      </span>
    </div>
  </ng-container>

  <ng-container *ngIf="isStateIN && subType === 'POLICY_GRID_3'">
    <div class="acrdn-sub-header" *ngIf="grid.formLoadCompleted && gridForm.get(EI_CONST.companyNameIN2)?.value"><strong>{{gridForm.get(EI_CONST.otherCompanyNameIN2)?.value ? gridForm.get(EI_CONST.otherCompanyNameIN2)?.value : getValue(gridForm.get(EI_CONST.companyNameIN2)?.value.value)}}</strong></div>
    <div *ngIf="grid.formLoadCompleted">
      <span *ngIf="gridForm.get(EI_CONST.typeOfPolicyIN2)?.value">{{gridForm.get(EI_CONST.typeOfPolicyIN2).value}}</span>
      <span class="divider" *ngIf="gridForm.get(EI_CONST.typeOfPolicyIN2)?.value && gridForm.get(EI_CONST.faceAmountIN2)?.value">|</span>
      <span *ngIf="gridForm.get(EI_CONST.faceAmountIN2)?.value">{{gridForm.get(EI_CONST.faceAmountIN2).value}}</span>
    </div>
  </ng-container>

</ng-container>

<ng-container *ngIf="type == 'AGENT_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value || gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value">
        <strong>{{gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value}} {{gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value}}</strong></span>
      <span class="divider" *ngIf="(gridForm.get(AI_CONST.secAgentNoQuesId)?.value || gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value) && (gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value ||
        gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value)">|</span>
      <span *ngIf="gridForm.get(AI_CONST.secAgentNoQuesId)?.value || gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value">
        {{gridForm.get(AI_CONST.secAgentNoQuesId)?.value}}{{gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value}}</span>
    </span>
  </span>
  <span class="right-header-container" [ngClass]="{'btn--link-disabled':disableForm}">
    <i class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
    <span class="percent-container" *ngIf="gridForm.get(AI_CONST.secSplitQuesId)?.value">{{gridForm.get(AI_CONST.secSplitQuesId)?.value}}%</span>
  </span>
</ng-container>

<ng-container *ngIf="type == 'AGENT_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="percent-container" *ngIf="gridForm.get(AI_CONST.secSplitQuesId)?.value">{{gridForm.get(AI_CONST.secSplitQuesId)?.value}}%</span>
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'AGENT_MOBILE_INFO'">
  <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && (gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value || gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value)">
    <strong>{{gridForm.get(AI_CONST.secAgentFirstNameQuesId)?.value}} {{gridForm.get(AI_CONST.secAgentLastNameQuesId)?.value}}</strong>
  </div>
  <div *ngIf="grid.formLoadCompleted">
    <span *ngIf="gridForm.get(AI_CONST.secAgentNoQuesId)?.value || gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value">
      {{gridForm.get(AI_CONST.secAgentNoQuesId)?.value}}{{gridForm.get(AI_CONST.secAgentNoQuesIdNotReq)?.value}}</span>
  </div>
</ng-container>

<ng-container *ngIf="type == 'EXISTING_INS_DESKTOP'">
  <span class="accordion__title-info existing-ins-acrd" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header">
        <span class="acrdn-sub-header" *ngIf="gridForm.get(POLICY_CONST.otherCompanyName)?.value || gridForm.get(POLICY_CONST.companyDropDropName)?.value?.label">
          <strong *ngIf="gridForm.get(POLICY_CONST.otherCompanyName)?.value">{{gridForm.get(POLICY_CONST.otherCompanyName)?.value}}</strong>
          <strong *ngIf="!gridForm.get(POLICY_CONST.otherCompanyName)?.value">{{gridForm.get(POLICY_CONST.companyDropDropName)?.value?.label}}</strong>
        </span>
        <span class="divider" *ngIf="(gridForm.get(POLICY_CONST.otherCompanyName)?.value || gridForm.get(POLICY_CONST.companyDropDropName)?.value?.label) && (gridForm.get(POLICY_CONST.contractName)?.value)">|</span>
        <span *ngIf="gridForm.get(POLICY_CONST.contractName)?.value">
          {{gridForm.get(POLICY_CONST.contractName)?.value}}</span>
      </span>
    </span>
    <span class="right-header-container">
      <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
    </span>
  </span>
</ng-container>

<ng-container *ngIf="type == 'EXISTING_INS_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'EXISTING_INS_MOBILE_INFO'">
  <div class="acrdn-sub-header-mobile" *ngIf="gridForm.get(POLICY_CONST.otherCompanyName)?.value || gridForm.get(POLICY_CONST.companyDropDropName)?.value?.label">
    <strong *ngIf="gridForm.get(POLICY_CONST.otherCompanyName)?.value">{{gridForm.get(POLICY_CONST.otherCompanyName)?.value}}</strong>
    <strong *ngIf="!gridForm.get(POLICY_CONST.otherCompanyName)?.value">{{gridForm.get(POLICY_CONST.companyDropDropName)?.value?.label}}</strong>
  </div>
  <div *ngIf="grid.formLoadCompleted">
    <span *ngIf="gridForm.get(POLICY_CONST.contractName)?.value">
      {{gridForm.get(POLICY_CONST.contractName)?.value}}</span>
  </div>
</ng-container>

<ng-container *ngIf="type == 'OWNER_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header">
        <strong>{{gridForm.get(OWNER_CONST.ownerFirstName)?.value}}&nbsp;{{gridForm.get(OWNER_CONST.ownerLastName)?.value}}
          {{gridForm.get(OWNER_CONST.entityName)?.value}}{{gridForm.get(OWNER_CONST.trustName)?.value}}
        </strong>
      </span>
    </span>
  </span>
  <span class="right-header-container">
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'OWNER_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'OWNER_MOBILE_INFO'">
  <div class="acrdn-sub-header-mobile" *ngIf="gridForm.get(OWNER_CONST.ownerFirstName)?.value || gridForm.get(OWNER_CONST.ownerLastName)?.value
  || gridForm.get(OWNER_CONST.entityName)?.value || gridForm.get(OWNER_CONST.trustName)?.value ">
    <strong>{{gridForm.get(OWNER_CONST.ownerFirstName)?.value}}&nbsp;{{gridForm.get(OWNER_CONST.ownerLastName)?.value}}
      {{gridForm.get(OWNER_CONST.entityName)?.value}}{{gridForm.get(OWNER_CONST.trustName)?.value}}
    </strong>
  </div>
</ng-container>

<ng-container *ngIf="type == 'INSURANCE_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header" *ngIf="gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value">
        <strong>{{gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value}}</strong></span>
      <span class="divider" *ngIf="gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value && gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value">|</span>
      <span class="acrdn-sub-header" *ngIf="gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value">
        <strong>{{gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value}}</strong></span>
    </span>
  </span>
  <span class="right-header-container">
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'INSURANCE_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'INSURANCE_MOBILE_INFO'">
  <div class="acrdn-sub-header-mobile" *ngIf="grid.formLoadCompleted && gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value">
    <strong>{{gridForm.get(AIC_CONST.nameOFBusinessQuesId)?.value}}</strong>
  </div>
  <div *ngIf="gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value">
    <span>{{gridForm.get(AIC_CONST.businessInsuranceTitleId)?.value}}</span>
  </div>
</ng-container>

<ng-container *ngIf="type == 'TRUST_DESKTOP'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <span class="accordion-info">
      <span class="acrdn-sub-header">
        <strong>{{gridForm.get(TRUST_CONST.trustFirstName)?.value}}&nbsp;{{gridForm.get(TRUST_CONST.trustLastName)?.value}}
        </strong>
      </span>
    </span>
  </span>
  <span class="right-header-container">
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'TRUST_MOBILE_ICON'">
  <span class="accordion__title-info" *ngIf="gridForm && grid.formLoadCompleted">
    <span *ngIf="!isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-exclamation-triangle accordion-status-icon attention" aria-hidden="true"></i></span>
    <span *ngIf="isGridValid" [ngClass]="{'hide-icon': readAccessOnly}"><i class="fas fa-check-circle accordion-status-icon complete" aria-hidden="true"></i></span>
    <i [ngClass]="{'btn--link-disabled':disableForm}" class="fas fa-trash-alt delete-can" (click)="removeGridInstance(grid, index, true)" *ngIf="gridSequences.length > 1" aria-hidden="true"></i>
  </span>
</ng-container>

<ng-container *ngIf="type == 'TRUST_MOBILE_INFO'">
  <div class="acrdn-sub-header-mobile" *ngIf="gridForm.get(TRUST_CONST.trustFirstName)?.value || gridForm.get(TRUST_CONST.trustLastName)?.value">
    <strong>{{gridForm.get(TRUST_CONST.trustFirstName)?.value}}&nbsp;{{gridForm.get(TRUST_CONST.trustLastName)?.value}}
    </strong>
  </div>
</ng-container>