import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { AgentThankYou, CaseCompletionThankYou, ConsumerThankYou, DeclineToSign, ErrorMessage, Message, ReviewAndSubmit } from 'src/config/constants';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';
import { ProductCodes } from 'src/config/sideNav.config';

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.scss']
})
export class ThankyouPageComponent implements OnInit {

  @Input() thankyouPageType: string;
  @Input() isPolicySubmitted: boolean;
  @Input() userAccessDetails: FunctionalityConfig;
  @Output() policySubmitted: EventEmitter<any> = new EventEmitter();

  errorInPolicyCreation = false;
  displayProgressOverlay = false;
  CONST = Message;
  isSubmitDisabled = false;
  productUnavailableMessage = ErrorMessage.VUL_SVUL_21_UNAVAILABLE;

  submitMessages = CaseCompletionThankYou;

  messages: any;
  constructor(private pageLoadService: DefaultPageload, private router: Router, private productService: ProductDetailsService) {
    // add code here
  }

  ngOnInit(): void {
    this.checkThankyouPageType();
    this.isSubmitDisabled = this.isProductDecommissioned();
    this.pageLoadService.logPageLoad();
  }

  private isProductDecommissioned(): boolean {
    return [ProductCodes.SVULONE2021, ProductCodes.VULONE2021].includes(this.productService.getProductName()?.toLowerCase());
  }

  private checkThankyouPageType(): void {
    switch (this.thankyouPageType) {
      case 'agentThankyouPage':
        this.messages = AgentThankYou;
        break;
      case 'consumerThankyouPage':
        this.messages = ConsumerThankYou;
        break;
      case 'declineToSign':
        this.messages = DeclineToSign;
        break;
      case 'reviewAndSubmit':
        this.messages = ReviewAndSubmit;
        break;
    }
  }

  submitCase(): void {
    this.pageLoadService.logButtonClick('submit case');
    this.displayProgressOverlay = true;
    this.errorInPolicyCreation = false;
    this.pageLoadService.createPolicy().subscribe((response) => {
      if (response.responseStatus === 'SUCCESS' && response.data) {
        this.policySubmitted.emit('yes');
        this.isPolicySubmitted = true;
      } else {
        this.errorInPolicyCreation = true;
      }
    }, (_err) => {
      this.errorInPolicyCreation = true;
    }).add(() => {
      this.displayProgressOverlay = false;
    });
  }

  close(): any {
    this.pageLoadService.logButtonClick('close');
    this.router.navigate(['/dashboard']);
  }

}
